import '../App.scss';
import '../css/invoice.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {notifyEvent, serverFetch, serverPatch, serverPost} from '../helpers/server';
import { BaseContext, IntegrationContext } from '../helpers/common';
import BaseForm from './BaseForm';
import Link from "./Link";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import MapleTable from "./MapleTable";
import SubmitButton from "./common/buttons/SubmitButton";
import Notification from "./Notification";
const _ = require('lodash');

function TaxCalcProductIDMappingComponent(props) {
    const { company, getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);

    const [products, setProducts] = useState([]);
    const [taxCalcProducts, setTaxCalcProducts] = useState([]);
    const [taxCalcProductsLoading, setTaxCalcProductsLoading] = useState(true);
    const initialFields = useMemo(() => {
        if (!_.isEmpty(integration.tax_data)) {
            const initialData = {
                product_id_map: {}
            };
            _.each(integration.tax_data.product_tax_mapping, (taxProduct, productID) => {
                initialData.product_id_map[productID] = taxProduct.product_id;
            })
            return initialData
        }
        return {}
    }, [integration]);

    const fetchData = (skipCache=false) => {
        const pLimit = 200
        const pParams = {
            company_id: company.id,
            pagination: {
                limit: pLimit
            },
            query: {}
        }
        serverPost(getApiUrl("/products/find"), pParams,{ skipCache }).then((res) => {
            if (res) {
                const results = res.results || [];
                setProducts(results);
            }
        });
        setTaxCalcProductsLoading(true);

        serverFetch(getApiUrl(`/integrations/${integration.id}/tax_products`), { skipCache: true }).then((res) => {
            if (res) {
                setTaxCalcProducts(res.products);
            }
        })

        setTaxCalcProductsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [])

    const refreshTaxCalcProductIds = async () => {
        setTaxCalcProductsLoading(true);
        serverFetch(getApiUrl(`/integrations/${integration.id}/tax_products`), { skipCache: true }).then((res) => {
            if (res) {
                setTaxCalcProducts(res.products);
            }
        })

        Notification.Success(`${integration.name} products refreshed`)
        setTaxCalcProductsLoading(false);
    }

    const onTaxCalcProductIdsMap = (data) => {
        const keys = Object.keys(data.product_id_map);
        const mappingData = {};
        _.each(keys, (key, i) => {
            if (data.product_id_map[key]) {
                const taxProduct = _.find(taxCalcProducts, (p) => String(p.product_id) === String(data.product_id_map[key]));
                if (taxProduct) {
                    mappingData[key] = taxProduct;
                }
            }
        })
        const updateData = {
            feature_data: {
                tax_data: {
                    product_tax_mapping: mappingData,
                }
            }
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            Notification.Success(`${integration.name} product ID mapping saved`)
            notifyEvent('integration_settings');
            if (props.onUpdate) {
                props.onUpdate();
            }
        })
    }

    const taxCalcProductOptions = _.map(taxCalcProducts, (pid) => {
        return {
            value: pid.product_id,
            label: pid.name
        }
    })
    taxCalcProductOptions.unshift({ value: null, label: "None" })

    return (
        <div>
            <p className="text-base font-semibold text-gray-700">Map the Products between Maple and { integration.name }.</p>
            <p className="text-sm font-normal text-gray-500">In order to ensure that the right set of taxes are calculated, we have to have an accurate mapping of Maple products with { integration.name } products.</p>
            <div className="flex flex-row justify-end items-center mt-2">
                {
                    taxCalcProductsLoading ?
                        <div className="h-4 w-4 spinner-border text-secondary"/>
                        : <Link className="text-sm flex flex-row gap-1 items-center" onClick={() => refreshTaxCalcProductIds()}><ArrowPathIcon className="h-4 w-4"/> Refresh { integration.name } Products</Link>
                }
            </div>
            <BaseForm className="mt-0" initialFormFields={initialFields} onSubmit={onTaxCalcProductIdsMap}>
                <MapleTable>
                    <MapleTable.Content overflow>
                        <thead>
                        <tr>
                            <MapleTable.TH>Maple Product</MapleTable.TH>
                            <MapleTable.TH>{ integration.name } Product</MapleTable.TH>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(products, (product, i) =>
                                <tr key={i}>
                                    <td width="50%">
                                        <span className="text-gray-700 font-semibold">{ product.name }</span><br/>
                                        {
                                            product.description &&
                                            <span className="text-gray-500">{ product.description }</span>
                                        }
                                    </td>
                                    <td>
                                        <BaseForm.Input name={`product_id_map.${product.id}`} type="select" label={"Product ID"} hideLabel formClassName="mb-0" options={taxCalcProductOptions} showSearch={taxCalcProductOptions.length > 6} />
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
                <div className="text-sm text-gray-500 my-3 italic">*Note: You can update these mappings later as well.</div>
                <SubmitButton>Update Mapping</SubmitButton>
            </BaseForm>
        </div>
    );
}

export default TaxCalcProductIDMappingComponent;
