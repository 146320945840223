import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useParams} from 'react-router-dom';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import {serverFetch, serverPost} from '../../helpers/server';
import {BaseContext, currencyFormatFromPrice, getLabelForCommissionReportStatus} from '../../helpers/common';
import moment from 'moment/moment';
import Columns from "../../components/Columns";
import CommissionEventLogs from './CommissionEventLogs';
import SectionNav from '../../components/SectionNav';
import Link from '../../components/Link';
import _ from "lodash";
import KeyValueDisplay2 from "../../components/KeyValueDisplay2";
import ContentBox from "../../components/ContentBox";
import {Button} from "react-bootstrap";
import SimpleModal from "../../components/modals/SimpleModal";
import Notification from "../../components/Notification";
import BaseOverlayTrigger from "../../components/BaseOverlayTrigger";
import Share01 from "@untitled-ui/icons-react/build/esm/Share01";
import CommissionDeals from "./CommissionDeals";

function CommissionReport() {
	const { id } = useParams();
	const [report, setReport] = useState(null);
	const [loading, setLoading] = useState(true);
	const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
	const { t } = useTranslation();
	const [details, setDetails] = useState({});
	const [sectionTitle, setSectionTitle] = useState('');
	const [showConfirmApproveModal, setShowConfirmApproveModal] = useState(false);

	useEffect(() => {
		setPageTitle('Commission Report Details');
		loadReport();
	}, [id]);

	const handleApprove = () => {
		const params = {
			report_ids: [id]
		};
		serverPost(getApiUrl('/commission/reports/approve'), params).then((res) => {
			if (res) {
				setShowConfirmApproveModal(false)
				Notification.Success("Successfully approved reports")
				loadReport();
			} else {
				Notification.Danger("Error approving reports")
			}
		});
	}

	const loadReport = () => {
		serverFetch(getApiUrl(`/commission/reports/${id}`)).then((res) => {
			if (res) {
				setReport(res);
				const data = {
					'Sales User': (
						<Link href={getCompanySpecificUrl(`/commissions/sales_user/${res.sales_user_id}`)}>
							{res.sales_user.company_user.user.name}
						</Link>
					),
					'Start Date': moment(res.start_date).format('MMM D, YYYY'),
					'End Date': moment(res.end_date).format('MMM D, YYYY'),
					'Commissions Earned': currencyFormatFromPrice(res.commission_achieved),
					'Quota Achieved': currencyFormatFromPrice(res.quota_achieved),
				};
				setDetails(data);

				const title = `Commission Report - ${res.sales_user.company_user.user.name} (${moment(res.start_date).format('MMM D, YYYY')} - ${moment(res.end_date).format('MMM D, YYYY')})`;
				setSectionTitle(title);
			}
			setLoading(false);
		});
	};

	return (
		<BaseContainer>
			<ContentContainer>
				<Section
					title={sectionTitle}
					variant={'page'}
					titleRight={
						<div className='text-nowrap'>{report && getLabelForCommissionReportStatus(report.status)}</div>
					}
					right={report && _.includes(['AWAITING_APPROVAL, PARTIALLY_APPROVED'], report.status) &&
						<div className='flex justify-between leading-8'>
							<div className='flex gap-[12px]'>
								<Button variant={'primary'} onClick={() => setShowConfirmApproveModal(true)}>
									Approve
								</Button>
							</div>
						</div>
					}
				/>
				<Section>
					<KeyValueDisplay2 title={"Overview"} items={details} />
				</Section>
				<ContentBox>
					<ContentBox.Title>Deals</ContentBox.Title>
					<ContentBox.Body>
						<div className='flex flex-row'>
						<div className='grow'/>
						<BaseOverlayTrigger content={'Export'}>
							<Button variant='text-outline' hasIconOnly>
								<Share01 width={20} height={20} />
							</Button>
						</BaseOverlayTrigger>
						</div>
						<CommissionDeals reportIDs={[id]} isComponent />
					</ContentBox.Body>
				</ContentBox>
			</ContentContainer>
			<SimpleModal
				show={showConfirmApproveModal}
				onClose={() => setShowConfirmApproveModal(false)}
				title='Confirm Approval'
				buttonTitle='Approve'
				buttonVariant='primary'
				onConfirm={handleApprove}
				body={
					<div>
						<span>Are you sure you want to approve this report?</span>
						<br />
						<br />
					</div>
				}
			/>
		</BaseContainer>
	);
}

export default CommissionReport;