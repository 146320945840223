import '../App.scss';
import React, { useState, useEffect, createRef, useCallback } from 'react';
import BaseForm from './BaseForm';
import Button from './common/buttons/Button';
import classnames from 'classnames';
import { renderChildren } from '../helpers/common';
import BaseOverlayTrigger from './BaseOverlayTrigger';
import FilterModal from './filters/FilterModal';
import FilterLines from '@untitled-ui/icons-react/build/esm/FilterLines';
import Share01 from '@untitled-ui/icons-react/build/esm/Share01';
import FilterTag from './filters/FilterTag';
import DropdownMenu from "./DropdownMenu";
const _ = require('lodash');

const MapleTableHeaderWithActions = (props) => {
	const [initialFields, setInitialFields] = useState({});
	const [filters, setFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState([]);
	const [appliedFilters, setAppliedFilters] = useState(null);
	const [showFilterOptions, setShowFilterOptions] = useState(false);
	const formRef = createRef();

	useEffect(() => {
		setFilters(props.filters);
	}, [props.filters]);

	useEffect(() => {
		const defaultFilters = { ...props.defaultSelectedFilters };
		delete defaultFilters['sort'];
		let newFilters = defaultFilters;
		if (props.cachedSelectedFilters) {
			newFilters = props.cachedSelectedFilters;
		}
		setSelectedFilters(newFilters);
		setAppliedFilters(newFilters);
		setInitialFields((prevFields) => {
			return { ...newFilters, ...defaultFilters };
		});
	}, [props.defaultSelectedFilters, props.cachedSelectedFilters]);

	const getFilterCount = useCallback((filters) => {
		if (filters === null) return 0;
		let count = 0;
		_.filter(filters, (value, key) => {
			if (key === 'search') {
				// Don't count search in filter count.
			} else if (!_.isNil(value) && !_.isUndefined(value) && (!Array.isArray(value) || !_.isEmpty(value))) {
				count += 1;
			}
		});
		return count;
	}, []);

	const onFieldChange = (name, value) => {
		setSelectedFilters((prevFilters) => {
			const newFilters = { ...prevFilters };
			newFilters[name] = value;
			return newFilters;
		});
		if (name === 'search') {
			onParamsChange(name, value);
		} else if (name === 'sort') {
			onParamsChange(name, value);
		} else {
		}
	};

	const onToggleFilterOptions = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setShowFilterOptions(!showFilterOptions);
	};

	const onSubmit = () => {
		document.body.click();
		if (props.onParamsChange) {
			props.onParamsChange(selectedFilters);
		}

		setAppliedFilters(selectedFilters);
		setShowFilterOptions(false);
	};

	const onParamsChange = (name, value) => {
		let formData = { ...selectedFilters };
		formData[name] = value;
		if (props.onParamsChange) {
			props.onParamsChange(formData);
		}
	};

	const onResetFilters = () => {
		const formData = formRef.current.getFormData();
		setSelectedFilters({ ...props.defaultSelectedFilters });
		setAppliedFilters({ ...props.defaultSelectedFilters });

		const basicData = {
			sort: formData.sort,
			search: formData.search,
		};
		let resetData = {};
		_.each(filters, (f) => {
			resetData[f.name] = null;
		});
		resetData = { ...resetData, ...props.defaultSelectedFilters };
		formRef.current.onResetFields(resetData);
		setInitialFields(basicData);
		if (props.onParamsChange) {
			props.onParamsChange(resetData);
		}
		setShowFilterOptions(false);
	};

	const renderFilters = () => {
		return (
			<FilterModal
				{...props}
				show={showFilterOptions}
				onClose={() => setShowFilterOptions(false)}
				onFieldChange={onFieldChange}
				onSubmit={onSubmit}
				onResetFilters={onResetFilters}
				selectedFilters={selectedFilters}
			/>
		);
	};

	const removeFilter = (filterName) => {
		const updatedFilters = { ...appliedFilters };
		updatedFilters[filterName] = null;

		formRef.current.onResetFields(updatedFilters);
		if (props.onParamsChange) {
			props.onParamsChange(updatedFilters);
		}
		setAppliedFilters(updatedFilters);
		setSelectedFilters(updatedFilters);
	};

	return (
		<>
			<BaseForm
				ref={formRef}
				initialFormFields={initialFields}
				onFieldChange={onFieldChange}
				onSubmit={onSubmit}
				className='mb-1'
			>
				<div
					className={classnames(
						{ 'mb-[12px]': getFilterCount(appliedFilters) > 0 },
						'flex flex-row gap-2 items-center',
					)}
				>
					{props.showSearch && (
						<div className='grow max-w-md'>
							<div className='w-full'>
								<BaseForm.TextGroup
									name='search'
									icon='search-icon'
									placeholder={props.searchPlaceholder || 'Search Items'}
									formClassName='inline'
									debounce={400}
								/>
							</div>
						</div>
					)}
					{props.showFilters &&
						!_.isEmpty(filters) && (
							<Button
								variant={getFilterCount(appliedFilters) > 0 ? 'outline-primary' : 'text-outline'}
								onClick={onToggleFilterOptions}
								className={classnames(
									{ '!border-primary3': getFilterCount(appliedFilters) > 0 },
									'!h-[38px]',
								)}
							>
								<div className='flex'>
									<FilterLines width={20} height={20} className='inline' />
									<span className='ml-1'>Filters&nbsp;</span>
									{getFilterCount(appliedFilters) > 0 && (
										<span>({getFilterCount(appliedFilters)})</span>
									)}
								</div>
							</Button>
						)
					}
					{renderChildren(props)}
					<div className='grow' />
					<div className={'flex flex-row gap-2 items-center justify-end'}>
						{props.meta && (
							<div className='body1 text-gray-500'>{props.meta && props.meta.total} results</div>
						)}
					</div>
					{props.showExport && (
						<BaseOverlayTrigger content={'Export'}>
							<Button variant="text-outline" hasIconOnly onClick={props.onExport}>
								<Share01 width={20} height={20}/>
							</Button>
						</BaseOverlayTrigger>
					)}
					{props.showExportDropdown && (
						<DropdownMenu items={props.exportOptions} onClick={props.onExportSelected} hideCaret>
							<BaseOverlayTrigger content={'Export'}>
								<Button variant="text-outline" hasIconOnly onClick={props.onExport}>
									<Share01 width={20} height={20}/>
								</Button>
							</BaseOverlayTrigger>
						</DropdownMenu>
					)}
				</div>
				{getFilterCount(appliedFilters) > 0 && (
					<div className='flex flex-wrap gap-2'>
						{Object.keys(appliedFilters).map((filterName, i) => {
							const filter = filters?.find((title) => title.name === filterName);
							return (
								<FilterTag
									key={i}
									filterTitle={filter?.label || filter?.title}
									filterType={filter?.type}
									filterKey={filterName}
									filterValue={appliedFilters[filterName]}
									filterOptions={filter?.options}
									removeFilter={() => removeFilter(filterName)}
								/>
							);
						})}
					</div>
				)}
				{renderFilters()}
			</BaseForm>
		</>
	);
};

/*
    <div className={"table-filter-menu-item-body"}>
        <BaseForm.Input type={item.type} name={item.name} options={item.options} label={item.label} showSearch={false} />
    </div>
 */

export default MapleTableHeaderWithActions;
