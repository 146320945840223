import '../../App.scss';
import '../../css/contract.scss';
import $ from "jquery";
import React, { useEffect, useState, useContext } from 'react';
import { serverPost, notifyEvent } from '../../helpers/server';
import { BaseContext, downloadBlob, ContractContext } from '../../helpers/common';
import DeclineContractModal from '../../components/modals/DeclineContractModal';
import ContentContainer from '../../components/ContentContainer';
import SubmitButton from '../../components/common/buttons/SubmitButton';
import BaseForm from '../../components/BaseForm';
import Loader from '../../components/Loader';
import {Row, Col, Container, Spinner} from 'react-bootstrap';
import classnames from 'classnames';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HelloSign from "hellosign-embedded";
import moment from 'moment';
import DropdownMenu from "../../components/DropdownMenu";
import Notification from '../../components/Notification';
import CustomerContractExpired from '../../components/CustomerContractExpired';
import Button from '../../components/common/buttons/Button';
import Download02 from '@untitled-ui/icons-react/build/esm/Download02'
import DotsVertical from '@untitled-ui/icons-react/build/esm/DotsVertical'
import PoweredByMapleFooter from '../../components/PoweredByMapleFooter';
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import MapleTable from "../../components/MapleTable";
const _ = require('lodash');
const sha1 = require('js-sha1');

class DropboxSignManager {
    constructor() {
        this.client = new HelloSign({
            clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID
        });
        const that = this;

        this.client.on('open', (data) => {
            console.log("The document opened " + JSON.stringify(data));
            if (that.loadedCallback) {
                that.loadedCallback();
            }
        });

        this.client.on('cancel', (data) => {
            console.log("Cancelled " + JSON.stringify(data));
        });

        this.client.on('finish', (data) => {
            console.log("Finished " + JSON.stringify(data));
        });

        this.client.on('message', (data) => {
            console.log("Received message " + JSON.stringify(data));
            if (data.type === "hellosign:userSignRequest") {
                console.log("Document signed");
                if (that.signedCallback) {
                    that.signedCallback();
                }
            }
        });
        this.client.on('close', (data) => {
            console.log("Closed " + JSON.stringify(data));
        });
        this.client.on('error', (data) => {
            console.log("Errored " + JSON.stringify(data));
        });
    }

    registerLoadedCallback(callback) {
        this.loadedCallback = callback;
    }

    registerSignedCallback(callback) {
        this.signedCallback = callback;
    }

    openSignatureUrl(signatureUrl, liveMode) {
        this.client.open(signatureUrl, {
            testMode: liveMode,
            container: document.getElementById('contract-container'),
            allowCancel: false,
            hideHeader: true
        });
    }
}

class XodoSignManager {
    constructor() {
        /* global eversign */
        this.client = eversign;
    }

    registerSignedCallback(callback) {
        this.signedCallback = callback;
        console.log("The signed callback is set " + this.signedCallback);
    }

    testButton() {
        console.log("The test callback is " + this.signedCallback);
    }

    openSignatureUrl(signatureUrl, liveMode) {
        const that = this;
        this.client.open({
            url: signatureUrl,
            containerID: "contract-container",
            width: "100%",
            height: "100%",
            events: {
                loaded: function () {
                    console.log("loaded Callback");
                },
                signed: function () {
                    console.log("signed Callback " + that.signedCallback);
                    if (that.signedCallback) {
                        that.signedCallback();
                    }
                },
                declined: function () {
                    console.log("declined Callback");
                },
                error: function () {
                    console.log("error Callback");
                }
            }
        });
    }
}

function ContractAccept() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid, sid } = useParams();
    const [value, setValue] = useState("");
    const [ searchParams ] = useSearchParams();
    let token = searchParams.get('token');

    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { contract, settings } = useContext(ContractContext);
    const [showDeclineContractModal, setShowDeclineContractModal] = useState(false);
    const [customerToken, setCustomerToken] = useState(null);
    const [askForCode, setAskForCode] = useState(false);
    const [signatureLoading, setSignatureLoading] = useState(true);
    const [signatory, setSignatory] = useState(null);
    const [signatureUrl, setSignatureUrl] = useState(null);
    const [documentLoaded, setDocumentLoaded] = useState(false);
    const [initialFields, setInitialFields] = useState({});
    const [esignManager, setESignManager] = useState(new DropboxSignManager());
    const [isCounterSignatory, setIsCounterSignatory] = useState(false);
    const [contractAttachments, setContractAttachments] = useState([]);
    const [shouldShowAttachmentsButton, setShouldShowAttachmentsButton] = useState(false);
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        esignManager.registerSignedCallback(executeContract);
        esignManager.registerLoadedCallback(onDocumentLoaded);
    }, [esignManager])

    useEffect(() => {
        const title = _.isNil(contract) ? "" : contract.title;
        setPageTitle(`Contract - ${title} - Accept`);
    }, [contract]);

    useEffect(() => {
        if (_.isNil(contract)) {
            return
        }
        if (contract.status === "EXECUTED" || contract.status === "AWAITING_MANUAL_ACTION" || contract.status === "COMPLETE") {
            navigateToNextStep(contract.status);
        }
        return
    }, [contract])

    useEffect(() => {
        if (contract) {

            contract.isExpired = contract.status === 'EXPIRED';
            let counterSignatory = false
            let signatory = _.find(contract.signatories, (s) => sha1(s.email.toLowerCase()).substring(0, 8) === sid);
            if (!signatory) {
                signatory = _.find(contract.counter_signatories, (s) => sha1(s.email.toLowerCase()).substring(0, 8) === sid);
                if (signatory) {
                    counterSignatory = true
                    setIsCounterSignatory(counterSignatory)
                }
            }
            if (signatory) {
                if (signatory.signed) {
                    navigateToNextStep(contract.status, counterSignatory);
                } else {
                    const token = localStorage.getItem(`proposal_${uuid}_customer_token`);
                    setCustomerToken(token);
                }
            }
            setSignatory(signatory);
            setInitialFields({
                owner: contract.owner && contract.owner.id,
                expiration_date: contract.expiration_date
            })
        } else {
            setSignatory(null);
        }
    }, [contract])

    useEffect(() => {
        if (_.isNil(contract) || _.isNil(signatory) || (signatory.signed)) {
            return;
        }

        const signatureData = {
            email: signatory.email
        }
        const signatureOptions = {
            suppressUnauthenticated: true,
            accessToken: customerToken
        }
        serverPost(getApiUrl(`/proposals/${uuid}/signature_url`), signatureData, signatureOptions).then((res) => {
            setSignatureLoading(false);
            if (res) {
                setSignatureUrl(res.signature_url);
                updateManagerForProvider(res.provider);
            } else {
                setSignatureUrl(null);
            }
        })
    }, [contract, signatory])

    useEffect(() => {
        if (_.isNil(contract) || _.isNil(signatureUrl) || contract.isExpired || contract.status === "DECLINED") {
            return;
        }

        $('#contract-container').html("<div />")
        esignManager.openSignatureUrl(signatureUrl, !settings.signature_provider_live_mode);
    }, [contract, signatureUrl])

    useEffect(() => {
        if (contract && !_.isEmpty(contract.contract_attachments)) {
            const attachmentOptions = _.map(contract.contract_attachments, (attachment) => {
                return {
                    id: attachment.id,
                    icon: "fa fa-download",
                    label: attachment.original_filename
                };
            })
            setContractAttachments(attachmentOptions)
        }
    }, [contract]);

    const updateManagerForProvider = (provider) => {
        if (provider === "dropbox_sign") {
            setESignManager(new DropboxSignManager());
        } else if (provider === "xodo_sign") {
            setESignManager(new XodoSignManager());
        }
    }

    const navigateToNextStep = (status, isCounterSignatory=false) => {
        if (status === "EXECUTED" || status === "COMPLETE") {
            navigate(getCompanySpecificUrl(`/contract/${uuid}/done`));
        } else if (status === "PENDING_SIGNATURES") {
            if (contract.require_payment_method && !contract.payment_provided_date && !isCounterSignatory) {
                navigate(getCompanySpecificUrl(`/contract/${uuid}/pay`));
            } else {
                navigate(getCompanySpecificUrl(`/contract/${uuid}/done`));
            }
        } else if (status === "PENDING_PAYMENT") {
            if (!isCounterSignatory) {
                navigate(getCompanySpecificUrl(`/contract/${uuid}/pay`));
            } else {
                navigate(getCompanySpecificUrl(`/contract/${uuid}/done`));
            }
        } else if (status === "AWAITING_MANUAL_ACTION") {
            navigate(getCompanySpecificUrl(`/contract/${uuid}/done`));
        } else if (status === "PENDING_COUNTER_SIGNATURES") {
            navigate(getCompanySpecificUrl(`/contract/${uuid}/done`));
        }
    }

    const renderExpirationDate = () => {
        if (_.isNil(contract.expiration_date)) {
            return null;
        }
        const daysFromExpiry = moment(contract.expiration_date).diff(moment(), "days");
        let color = "success-color";
        if (daysFromExpiry <= 0) {
            color = "danger-color";
        } else if (daysFromExpiry <= 3) {
            color = "warning-color";
        }
        return (
            <div className="self-start sm:self-center">
            {
                contract.expiration_date &&
                    <div className={classnames("body2", color)}>Expires { moment(contract.expiration_date).format("DD MMM h:mma") }</div>
            }
            </div>
        )
    }

    const onDocumentLoaded = () => {
        setDocumentLoaded(true);
    }

    const executeContract = () => {
        serverPost(getApiUrl(`/proposals/${uuid}/process_signature`)).then((res) => {
            notifyEvent("contract");
            if (res) {
                navigateToNextStep(res.status, isCounterSignatory);
            }
        })
    }

    const onActionSelected = (type) => {
        if (type === "pdf") {
            serverPost(getApiUrl(`/proposals/${contract.id}/preview`), {}, { noJson: true, accessToken: token }, downloadContractErrorHandler).then((res) => {
                if (res) {
                    downloadBlob(res, `Contract-${contract.title}`);
                }
            })
        } else if (type === "decline") {
            setShowDeclineContractModal(true);
        }
    }

    const downloadContractErrorHandler = (res) => {
        if (res.status === 401 || res.status === 403) {
            Notification.Danger("Download link has expired. Please request a new link from the sender.", 5000)
        } else {
            Notification.Danger("Document cannot be currently downloaded. Please try again later.", 5000)
        }
    }

    const onVerifyError = async (res) => {
        const errorMessage = await res.json();
        if (res.status === 400) {
            if (errorMessage.code_sent) {
                setAskForCode(true);
            }
        }
    };

    const verifyEmail = (data) => {
        const verifyData = {
            email: data.email,
            code: data.code
        }
        serverPost(getApiUrl(`/proposals/${contract.id}/verify_email`), verifyData, {}, onVerifyError).then((res) => {
            if (res) {
                setCustomerToken(res.token);
                localStorage.setItem(`proposal_${uuid}_customer_token`, res.token);
                notifyEvent("contract");
            }
        })
    }

    const actionOptions = [
        { id: "decline", label: "Decline" },
    ];

    const downloadAttachment = async (attachmentId) => {
        setDownloading(true);
        const res = await serverPost(getApiUrl(`/proposals/${contract.id}/preview_attachment/${attachmentId}`), {}, {accessToken: token}, downloadContractErrorHandler)
        if (res) {
            fetch(res.url, {headers: res.headers}).then((res2) => {
                res2.blob().then(value => {
                    downloadBlob(value, res.filename)
                })
            })
        }
        setDownloading(false);
    }

    useEffect(() => {
        const checkIsScreenSmall = () => {
            setShouldShowAttachmentsButton(window.innerWidth <= 1023);
        };

        checkIsScreenSmall()
        window.addEventListener("resize", checkIsScreenSmall);
        return () => {
            window.removeEventListener("resize", checkIsScreenSmall);
        }
    }, []);

    const renderContract = () => {
        const parts = _.filter([ _.isNil(contract.owner) ? null: contract.owner.user.name || contract.owner.user.email, company.name]).join(", ");
        const renderActions = () => (
            <>
                <Button variant="text-outline" className="flex items-center w-full justify-center" onClick={() => onActionSelected("pdf")}>
                    <Download02 width={20} height={20} className='inline' />&nbsp;
                    <span className="inline md:hidden">Download</span>
                </Button>
                {!_.isEmpty(contractAttachments) && shouldShowAttachmentsButton &&
                    <Button variant="primary" className="flex items-center w-full justify-center" onClick={() => scrollToAttachments()}>
                    <span className="flex items-center gap-1">
                        <i className={"fa fa fa-paperclip"}/>
                        {`Attachments (${contractAttachments.length})`}
                    </span>
                    </Button>
                }
            </>
        )
        const renderHeader = () => {
            return (
                <div className="relative top-0 flex border-b border-gray2 w-full bg-white z-50 px-4 py-[12px] justify-center">
                    <div className=" flex w-[calc(min(100%,1000px))] gap-3 justify-between flex-col">
                        <div className="flex flex-row">
                            <div className="flex flex-col sm:flex-row justify-between w-full gap-2">
                                <div className="flex flex-col w-full">
                                    <h3 className="text-dark-gray text-base sm:text-lg font-semibold">{ contract.title }</h3>
                                    <span className="text-sm sm:text-base text-[#414651]">From { parts }</span>
                                </div>
                                { renderExpirationDate() }
                            </div>
                            {
                                documentLoaded &&
                                    <div className="flex flex-grow-0 self-center gap-[12px] ml-3">
                                        <div className="hidden md:flex gap-[12px]">
                                            {renderActions()}
                                        </div>
                                        <DropdownMenu hideCaret items={actionOptions} onClick={onActionSelected}>
                                            <Button variant="text-outline" className="flex items-center">
                                                <DotsVertical width={20} height={20}/>
                                            </Button>
                                        </DropdownMenu>
                                    </div>
                            }
                        </div>
                        <div className="block md:hidden">
                            {documentLoaded &&
                                <div className="flex flex-grow-0 self-center gap-[12px]">
                                    {renderActions()}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        };
        const renderFooter = () => {
            return (
                <div className="bg-[#FAF8F4] bottom-0 flex pb-3 items-center justify-center relative">
                    <PoweredByMapleFooter />
                </div>
            );
        };
        const hasAttachments = !_.isEmpty(contractAttachments);
        return (
            <div className="h-full flex flex-col">
                {renderHeader()}
                <div className={`contract-layout ${hasAttachments ? 'with-attachments' : ''}`}>
                    <div className="contract-main">
                    <div id="contract-container" className="contract-container shadow-contract">
                        <Loader loading={signatureLoading}>
                        {
                            () => {
                                if (!signatureUrl) {
                                    return (
                                        <div className="d-flex flex-column justify-content-center align-items-center gap-3" style={{ margin: "30px 0px" }}>
                                            <Container><Row><Col md={{ span:6, offset: 3 }}>
                                            <p>Welcome! We have a contract for you to review. We need to quickly confirm your identity before we get there.</p>
                                            <p>We will send you a code to verify your email.</p>
                                            <BaseForm initialFormFields={signatory} onSubmit={verifyEmail}>
                                                <BaseForm.Input type="text" name="email" label={t('common.email')} disabled/>
                                                {
                                                    askForCode &&
                                                        <BaseForm.Input type="text" name="code" label={t('common.code')}
                                                            description="We have sent a verification code to your email. Please enter the code here."/>
                                                }
                                                <SubmitButton>{ askForCode ? "Verify": "Send Code" }</SubmitButton>
                                            </BaseForm>
                                            </Col></Row></Container>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div/>
                                    )
                                }
                            }
                        }
                        </Loader>
                    </div>
                    </div>
                    {hasAttachments && (
                        <div id="attachments-section" className="attachments-section">
                            <div className="attachments-container shadow-contract">
                                <h3 className="text-dark-gray text-lg font-semibold mb-3"><i className={'fa fa fa-paperclip'} />Attachments</h3>
                                <MapleTable>
                                    <MapleTable.Content>
                                        <tbody className={""}>
                                        {
                                            _.map(contract.contract_attachments, (ufile, i) =>
                                                <tr key={i} onClick={async () => {
                                                    setDownloading(true);
                                                    await downloadAttachment(ufile.id)}
                                                } className={"cursor-pointer hover:bg-gray-50"}>
                                                    <td>{ ufile.original_filename }</td>
                                                    <td className="text-end">
                                                        <div className="flex flex-row gap-2 justify-end items-center">
                                                            {downloading ?
                                                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true'/> :
                                                                <ArrowDownTrayIcon className="cursor-pointer h-4 w-4"/>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                            </div>
                        </div>
                    )}
                </div>
                {renderFooter()}
            </div>
        );
    }

    const scrollToAttachments = () => {
        const attachmentsSection = document.getElementById('attachments-section');
        if (attachmentsSection) {
            attachmentsSection.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <>
            <Notification/>
            <ContentContainer className="full" bodyClassName="full">
                {
                    contract.isExpired ?
                        <CustomerContractExpired/>
                        : renderContract()
                }
                <DeclineContractModal show={showDeclineContractModal} onClose={setShowDeclineContractModal}
                                      contract={contract} signatory={signatory}/>
            </ContentContainer>
        </>
    )
}

export default ContractAccept;
