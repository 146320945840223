import React, { useState, useEffect, useContext } from 'react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import _ from 'lodash';
import { BaseFormContext } from '../../components/BaseForm';
import { Controller } from 'react-hook-form';
import { Button } from 'react-bootstrap';

function TierEditor(props) {
	const [errors, setErrors] = useState({});
	const [tiers, setTiers] = useState([
		{
			start: 0,
			end: null,
			commission_rate: '',
			is_retroactive: false,
		},
	]);

	useEffect(() => {
		if (props.isAccelerator) {
			setTiers([]);
		} else {
			setTiers([
				{
					start: 0,
					end: null,
					commission_rate: '',
					is_retroactive: false,
				},
			]);
		}
	}, [props.isAccelerator]);

	useEffect(() => {
		if (props.value?.length > 0) {
			const extractedTiers = props.value.map((rule) => ({
				start: rule.rule.comparator.type === 'AND' ? parseFloat(rule.rule.comparator.left_item.comparator.right_item.value) : parseFloat(rule.rule.comparator.right_item.value)  ,
				end:
					rule.rule.comparator.type === 'AND'
						? parseFloat(rule.rule.comparator.right_item.comparator.right_item.value)
						: null,
				commission_rate: rule.commission_rate,
				is_retroactive: rule.is_retroactive,
			}));
			setTiers(extractedTiers);
		}
	}, [props.value]);

	const validateTier = (tier, index) => {
		const newErrors = {};

		if (tier.end !== null && tier.end <= tier.start) {
			newErrors.end = `End value must be greater than ${tier.start}%`;
		}

		if (!tier.end && index < tiers.length - 1) {
			newErrors.end = `Only the final tier can have an end value of No Limit`;
		}

		return newErrors;
	};

	const getBaseQuotaRuleFromTier = (tier) => {
		if (!tier.end) {
			return {
				comparator: {
					type: 'GREATER_THAN_EQUAL',
					left_item: {
						item_type: 'PROPERTY',
						property: 'QUOTA_ACHIEVED',
					},
					right_item: {
						item_type: 'VALUE',
						value: tier.start.toString(),
					},
				},
			};
		} else {
			return {
				comparator: {
					type: 'AND',
					left_item: {
						item_type: 'COMPARATOR',
						comparator: {
							type: 'GREATER_THAN_EQUAL',
							left_item: {
								item_type: 'PROPERTY',
								property: 'QUOTA_ACHIEVED',
							},
							right_item: {
								item_type: 'VALUE',
								value: tier.start.toString(),
							},
						},
					},
					right_item: {
						item_type: 'COMPARATOR',
						comparator: {
							type: 'LESS_THAN',
							left_item: {
								item_type: 'PROPERTY',
								property: 'QUOTA_ACHIEVED',
							},
							right_item: {
								item_type: 'VALUE',
								value: tier.end.toString(),
							},
						},
					},
				},
			};
		}
	};

	const constructRuleFromTier = (tier) => ({
		commission_rate: tier.commission_rate,
		is_retroactive: tier.is_retroactive,
		rule: getBaseQuotaRuleFromTier(tier),
	});

	const updateTiers = (newTiers) => {
		setTiers(newTiers);
		// Convert tiers to array of commission rule objects
		const rules = newTiers.map((tier, index) => constructRuleFromTier(tier));
		props.onChange(rules);
	};

	const addTier = () => {
		if (tiers.length === 0) {
			const newTier = {
				start: 0,
				end: null,
				commission_rate: '',
				is_retroactive: false,
			};
			updateTiers([newTier]);
		} else {
			const lastTier = tiers[tiers.length - 1];
			const newTier = {
				start: lastTier.end || 0,
				end: null,
				commission_rate: '',
				is_retroactive: false,
			};
			updateTiers([...tiers, newTier]);
		}
	};

	const removeTier = (index) => {
		const newTiers = tiers.filter((_, i) => i !== index);
		updateTiers(newTiers);
	};

	const updateTier = (index, field, value) => {
		const newTiers = [...tiers];
		newTiers[index] = { ...newTiers[index], [field]: value };

		// Update next tier's start value if we're updating an end value
		if (field === 'end' && index < tiers.length - 1) {
			newTiers[index + 1] = { ...newTiers[index + 1], start: value };
		}

		const tierErrors = validateTier(newTiers[index], index);
		setErrors(prev => ({
			...prev,
			[index]: tierErrors
		}));

		if (Object.keys(tierErrors).length === 0) {
			updateTiers(newTiers);
		}

		updateTiers(newTiers);
	};

	return (
		<>
			<div className='flex flex-col gap-2'>
				{tiers.map((tier, index) => (
					<div key={index} className='bg-slate-50 p-4 rounded-lg border border-slate-200'>
						<div className='flex justify-between items-center mb-3'>
							<span className='text-sm font-medium text-gray-700'>
								{props.isAccelerator ? `Accelerator ${index + 1}` : `Tier ${index + 1}`}
							</span>
							{index > 0 && (
								<button
									type='button'
									onClick={() => removeTier(index)}
									className='text-gray-400 hover:text-gray-600'
								>
									<XMarkIcon className='h-5 w-5' />
								</button>
							)}
						</div>
						<div className='grid grid-cols-12 gap-4 items-center'>
							<div className='col-span-4'>
								<div className='flex items-center'>
									<span className='mr-2'>≥</span>
									<input
										type='number'
										value={tier.start}
										disabled={true}
										className='w-full px-3 py-2 border border-gray-300 rounded-md disabled:bg-gray-100'
									/>
									<span className='ml-2'>%</span>
								</div>
							</div>
							<div className='col-span-1 flex items-center justify-center'>
								<span className='text-gray-500'>to</span>
							</div>
							<div className='col-span-4'>
								<div className='flex items-center'>
									<span className='mr-2'>&lt;</span>
									<input
										type='number'
										value={tier.end || ''}
										min={tier.start}
										onChange={(e) => updateTier(index, 'end', parseFloat(e.target.value))}
										placeholder='No Limit'
										className={classnames('w-full px-3 py-2 border rounded-md', errors[index]?.end ? 'border-red-500' : 'border-gray-300')}
									/>
									<span className='ml-2'>%</span>
								</div>
							</div>
							<div className='col-span-3'>
								<div className='flex items-center'>
									<span className='mr-2'>=</span>
									<input
										type='number'
										min={0}
										value={tier.commission_rate}
										onChange={(e) =>
											updateTier(index, 'commission_rate', parseFloat(e.target.value))
										}
										className='w-full px-3 py-2 border border-gray-300 rounded-md'
										placeholder={props.isAccelerator ? 'Accelerator Rate' : 'Rate'}
									/>
									<span className='ml-2'>{props.isAccelerator ? 'x Base Rate' : '%'}</span>
								</div>
							</div>
						</div>
						{index > 0 && !props.isAccelerator && (
							<div className='mt-2'>
								<label className='flex items-center space-x-2'>
									<input
										type='checkbox'
										checked={tier.is_retroactive}
										onChange={(e) => updateTier(index, 'is_retroactive', e.target.checked)}
										className='rounded border-gray-300 text-blue-600'
									/>
									<span className='text-sm text-gray-600'>
										If achieved, apply rate to all previous tiers
									</span>
								</label>
							</div>
						)}
						{errors[index]?.end && (
							<div className='mt-2'>
								<span className='text-red-500 text-sm'>{errors[index].end}</span>
							</div>
						)}
					</div>
				))}
			</div>
			<Button
				type='button'
				variant='outline-light'
				onClick={addTier}
				disabled={tiers.length > 0 && !tiers[tiers.length - 1].end}
				className='mt-2 flex items-center gap-2'
			>
				<PlusIcon className='h-5 w-5' />
				<span>Add Tier</span>
			</Button>
		</>
	);
}

function CommissionTierRules(props) {
	const { control, updateFormFields, registerName } = useContext(BaseFormContext);
	registerName(props.name, 'custom');

	return (
		<Controller
			name={props.name}
			control={control}
			defaultValue={[]}
			render={({ field }) => {
				return (
					<TierEditor
						value={field.value}
						onChange={(value) => {
							updateFormFields(props.name, value);
							field.onChange(value);
						}}
						isAccelerator={props.name === 'accelerators'}
					/>
				);
			}}
		></Controller>
	);
}

export default CommissionTierRules;
