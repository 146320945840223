import '../../../App.scss';
import React, { useState } from 'react';
import { Button as RBButton, Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import DropdownMenu from '../../DropdownMenu';
import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown';
import BaseOverlayTrigger from '../../BaseOverlayTrigger';

function Button({ hasIconOnly, dropdownOptions, dropdownOptionsSelected, isDropdownOptionsLoading, ...props }) {
	const pprops = { ...props };
	delete pprops['onClick'];
	const [isLoading, setIsLoading] = useState(false);

	const onClick = async (e) => {
		if (isLoading) return;
		
		setIsLoading(true);
		if (props.onClick) {
			await props.onClick(e);
		}
		setIsLoading(false);
	};

	const isDropdownButtonEnabled = Array.isArray(dropdownOptions);

	const renderButton = () => {
		const Wrapper = props.overlayContent ? BaseOverlayTrigger : React.Fragment;
		return (
			<Wrapper {...(props.overlayContent ? { content: props.overlayContent, delayShow: 1000 } : {})}>
				<RBButton
					{...pprops}
					onClick={onClick}
					style={{ ...props.style, ...(props.color ? { background: props.color } : {}) }}
					className={classnames(
						'whitespace-nowrap',
						props.className,
						{
							"p-2": hasIconOnly,
							"!rounded-r-none !pr-2": isDropdownButtonEnabled,
						}
					)}
				>
					<div className='d-flex flex-column align-items-center'>
						{isLoading ? (
							<Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' variant={props.variant}>
								&nbsp;
							</Spinner>
						) : (
							<span className='submit-button-content'>{props.children}</span>
						)}
					</div>
				</RBButton>
			</Wrapper>
		);
	};

	if (!isDropdownButtonEnabled) {
		return renderButton();
	} else {
		return (
			<div className="flex items-center">
				{renderButton()}
				<div className="inline-block w-[1px] bg-gray3 h-[36px]"></div>
				<DropdownMenu items={dropdownOptions} onClick={dropdownOptionsSelected} hideCaret>
					<Button variant={props.variant} className="flex items-center !pr-2 !pl-[6px] !rounded-l-none">
						{isDropdownOptionsLoading ? (
							<Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true'>
								&nbsp;
							</Spinner>
						) : (
							<ChevronDown className="inline" width={20} height={20} />
						)}
					</Button>
				</DropdownMenu>
			</div>
		);
	}
}

export default Button;
