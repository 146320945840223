import { Col, Row } from "react-bootstrap";
import BoxedSection from "../../../components/layout/BoxedSection";
import BaseForm from "../../../components/BaseForm";
import { useOutletContext } from "react-router-dom";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { getConfigActionTypeKey, getConfigForCardExpirySchedule, getConfigForInvoiceReminderSchedule, getConfigForPaymentRetrySchedule, renderCampaignConfigRow } from "../../../helpers/common";
import Link from "../../../components/Link";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/buttons/Button";
import EditScheduleModal from "../../../components/modals/EditScheduleModal";

const retryScheduleTitleMap = {
    "basic": "Basic",
    "lenient": "Lenient",
    "aggressive": "Persistent",
    "custom": "Custom"
};

const retryScheduleDescriptionMap = {
    "basic": "",
    "lenient": "",
    "aggressive": "",
    "custom": ""
};

const reminderScheduleTitleMap = {
    "basic": "Basic",
    "lenient": "Lenient",
    "aggressive": "Persistent",
    "custom": "Custom"
};

const reminderScheduleDescriptionMap = {
    "basic": "",
    "lenient": "",
    "aggressive": "",
    "custom": ""
};

const cardExpiryScheduleTitleMap = {
    "basic": "Basic",
    "lenient": "Lenient",
    "aggressive": "Persistent",
    "custom": "Custom"
};

const cardExpiryScheduleDescriptionMap = {
    "basic": "",
    "lenient": "",
    "aggressive": "",
    "custom": ""
};

const InvoiceSettingsNotifications = (props) => {
    const { t } = useTranslation('common');
    const outletContext = useOutletContext();
    const { settings, updateSettings } = props.settings && props.updateSettings ?
        props : outletContext;

    const [sendCustomerEmails, setSendCustomerEmails] = useState(false);
    const [autoChargeInvoicePaymentRetryEnabled, setAutoChargeInvoicePaymentRetryEnabled] = useState(false);
    const [autoChargeInvoicePaymentRetryEditing, setAutoChargeInvoicePaymentRetryEditing] = useState(false);
    const [showEditAutoChargeRetryModal, setShowEditAutoChargeRetryModal] = useState(false);
    const [nonAutoChargeInvoiceReminderEditing, setNonAutoChargeInvoiceReminderEditing] = useState(false);
    const [showEditNonAutoChargeScheduleModal, setShowEditNonAutoChargeScheduleModal] = useState(false);
    const [cardExpiryReminderEditing, setCardExpiryReminderEditing] = useState(false);
    const [showEditCardExpiryScheduleModal, setShowEditCardExpiryScheduleModal] = useState(false);
    const [nonAutoChargeInvoiceReminderEnabled, setNonAutoChargeInvoiceReminderEnabled] = useState(false);
    const [cardExpiryReminderEnabled, setCardExpiryReminderEnabled] = useState(false);
    const emailSettingsRef = createRef();

    const initialFields = useMemo(() => {
        setSendCustomerEmails(settings.send_customer_emails);
        const invoice_email_config = settings.invoice_email_config
        if (invoice_email_config) {
            delete invoice_email_config['send_customer_emails']
        }
        return {
            ..._.cloneDeep(settings),
            ...settings.invoice_email_config,
            cc_email_addresses: settings.invoice_email_config && _.join(settings.invoice_email_config?.cc_email_addresses, ",")
        }
    }, [settings]);

    useEffect(() => {
        setAutoChargeInvoicePaymentRetryEnabled(settings.auto_charge_invoice_payment_retry_enabled)
        setNonAutoChargeInvoiceReminderEnabled(settings.non_auto_charge_invoice_reminder_enabled)
        setCardExpiryReminderEnabled(settings.card_expiry_reminder_enabled)
        setSendCustomerEmails(settings.send_customer_emails)
    }, [settings]);

    const onFieldChange = async (name, value) => {
        if (name === "auto_charge_invoice_payment_retry_enabled") {
            setAutoChargeInvoicePaymentRetryEnabled(value);
            const data = {
                auto_charge_invoice_payment_retry_enabled: value
            }
            if (value && (!settings.auto_charge_invoice_payment_retry_config || settings.auto_charge_invoice_payment_retry_config.grouping_name === "")) {
                data["auto_charge_invoice_payment_retry_config"] = { grouping_name: "lenient" }
            }
            await updateSettings(data);
            if (value) {
                setAutoChargeInvoicePaymentRetryEditing(true);
            }
        } else if (name === "non_auto_charge_invoice_reminder_enabled") {
            setNonAutoChargeInvoiceReminderEnabled(value);
            const data = {
                non_auto_charge_invoice_reminder_enabled: value
            }
            if (value && (!settings.non_auto_charge_invoice_reminder_config || settings.non_auto_charge_invoice_reminder_config.grouping_name === "")) {
                data["non_auto_charge_invoice_reminder_config"] = { grouping_name: "lenient" }
            }
            await updateSettings(data);
            if (value) {
                setNonAutoChargeInvoiceReminderEditing(true);
            }
        } else if (name === "card_expiry_reminder_enabled") {
            setCardExpiryReminderEnabled(value);
            const data = {
                card_expiry_reminder_enabled: value
            }
            if (value && (!settings.card_expiry_reminder_config || settings.card_expiry_reminder_config.grouping_name === "")) {
                data["card_expiry_reminder_config"] = { grouping_name: "lenient" }
            }
            await updateSettings(data);
            if (value) {
                setCardExpiryReminderEditing(true);
            }
        } else if (name === "send_customer_emails") {
            setSendCustomerEmails(value);
            const data = {
                send_customer_emails: value
            }
            await updateSettings(data);
        } else if (_.includes(["send_invoice_payment_failure", "send_invoice_payment_success",
            "send_invoice_generation_email", "attach_pdf"], name)) {
            const data = {
                [name]: value
            }
            await updateSettings(data);
        }
    };

    const submitEmailSettingsForm = (event) => {
        if (emailSettingsRef.current) {
            emailSettingsRef.current.submitForm();
        }
    };

    const onRetryScheduleSelected = async (type) => {
        if (type !== "custom") {
            // Standard schedule picked, just change it in the settings.
            await updateSettings({
                auto_charge_invoice_payment_retry_config: {
                    grouping_name: type
                }
            });
            setAutoChargeInvoicePaymentRetryEditing(false);
        } else {
            setShowEditAutoChargeRetryModal(true);
        }
    };

    const onReminderScheduleSelected = async (type) => {
        if (type !== "custom") {
            // Standard schedule picked, just change it in the settings.
            await updateSettings({
                non_auto_charge_invoice_reminder_config: {
                    grouping_name: type
                }
            });
            setNonAutoChargeInvoiceReminderEditing(false);
        } else {
            setShowEditNonAutoChargeScheduleModal(true);
        }
    };

    const onCardExpiryScheduleSelected = async (type) => {
        if (type !== "custom") {
            // Standard schedule picked, just change it in the settings.
            await updateSettings({
                card_expiry_reminder_config: {
                    grouping_name: type
                }
            });
            setCardExpiryReminderEditing(false);
        } else {
            setShowEditCardExpiryScheduleModal(true);
        }
    };

    const onCustomAutoChargeRetryScheduleSelected = async (scheduleItems) => {
        await updateSettings({
            auto_charge_invoice_payment_retry_config: {
                grouping_name: "custom",
                config: {
                    items: scheduleItems
                }
            }
        });
        setShowEditAutoChargeRetryModal(false);
        setAutoChargeInvoicePaymentRetryEditing(false);
    };

    const onCustomNonAutoChargeReminderScheduleSelected = async (scheduleItems) => {
        await updateSettings({
            non_auto_charge_invoice_reminder_config: {
                grouping_name: "custom",
                config: {
                    items: scheduleItems
                }
            }
        });
        setNonAutoChargeInvoiceReminderEditing(false);
        setShowEditNonAutoChargeScheduleModal(false);
    };

    const onCustomCardExpiryReminderScheduleSelected = async (scheduleItems) => {
        await updateSettings({
            card_expiry_reminder_config: {
                grouping_name: "custom",
                config: {
                    items: scheduleItems
                }
            }
        });
        setCardExpiryReminderEditing(false);
        setShowEditCardExpiryScheduleModal(false);
    };

    const getConfigItemDescription = (row) => {
        let description = getConfigActionTypeKey(row.action_type)
        if (row.infinite_recurring) {
            return `Repeat every ${row.interval_from_date/86400} days (${t(description)})`
        } else {
            if (row.interval_from_date >= 0) {
                return `Day ${row.interval_from_date/86400} (${t(description)})`
            } else {
                return `${-1*row.interval_from_date/86400} day(s) before (${t(description)})`
            }
        }
    };

    const onModalClose = () => {
        setShowEditAutoChargeRetryModal(false);
        setShowEditNonAutoChargeScheduleModal(false);
        setShowEditCardExpiryScheduleModal(false);
    }

    const renderRetrySchedule = (type) => {
        const isSelected = settings.auto_charge_invoice_payment_retry_config && (settings.auto_charge_invoice_payment_retry_config.grouping_name === type);
        const config = (isSelected) ?
            settings.auto_charge_invoice_payment_retry_config && settings.auto_charge_invoice_payment_retry_config.config.items
            : getConfigForPaymentRetrySchedule(type);
        return renderSchedule(retryScheduleTitleMap[type], retryScheduleDescriptionMap[type], config, isSelected,
            type === "custom", () => onRetryScheduleSelected(type));
    };

    const renderReminderSchedule = (type) => {
        const isSelected = settings.non_auto_charge_invoice_reminder_config && (settings.non_auto_charge_invoice_reminder_config.grouping_name === type);
        const config = (isSelected) ?
            settings.non_auto_charge_invoice_reminder_config && settings.non_auto_charge_invoice_reminder_config.config.items
            : getConfigForInvoiceReminderSchedule(type);
        return renderSchedule(reminderScheduleTitleMap[type], reminderScheduleDescriptionMap[type], config, isSelected,
            type === "custom", () => onReminderScheduleSelected(type));
    };

    const renderCardExpirySchedule = (type) => {
        const isSelected = settings.card_expiry_reminder_config && (settings.card_expiry_reminder_config.grouping_name === type);
        const config = (isSelected) ?
            settings.card_expiry_reminder_config && settings.card_expiry_reminder_config.config.items
            : getConfigForCardExpirySchedule(type);
        return renderSchedule(cardExpiryScheduleTitleMap[type], cardExpiryScheduleDescriptionMap[type], config, isSelected,
            type === "custom", () => onCardExpiryScheduleSelected(type));
    };

    const renderSchedule = (title, description, items, isSelected, isCustom, onClick) => {
        const editable = isCustom && isSelected;
        const getButtonText = () => {
            if (isCustom && !isSelected) {
                return "Set your own";
            } else if (isCustom && isSelected) {
                return "Selected";
            } else if (!isCustom && !isSelected) {
                return "Select";
            } else if (!isCustom && isSelected) {
                return "Selected";
            }
        };
        return <div className="schedule-wrapper">
            <div className="schedule-top">
                <h4>{ title }</h4>
                <span className="body1 flex-grow-1">{ description }</span>
                {
                    editable &&
                        <Button variant="outline-primary" className="btn-complete" onClick={onClick}>
                            <i className="fa fa-edit"/> Edit
                        </Button>
                }
                <Button variant="primary" className="btn-complete" onClick={onClick}
                        disabled={isSelected}>
                    {getButtonText()}
                </Button>
            </div>
            {
                !_.isEmpty(items) &&
                    <div className="schedule-bottom">
                        <span className="body2">Schedule</span>
                        <div className="d-flex flex-column text-sm">
                            {
                                _.map(items, (c, i) =>
                                    <span key={i}>{ renderCampaignConfigRow(c) }</span>
                                )
                            }
                        </div>
                    </div>
            }
        </div>
    };

	return (
        <>
            <BoxedSection title="Customer Communication">
                <Row>
                    <Col md="8">
                        <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                            <Row>
                                <BaseForm.Input
                                    type="switch" colSpan="12" name="send_customer_emails" label="Send Customer Emails"
                                    description="Communicate with customers about their invoices and payments such as invoice reminder emails, payment success and failure emails."
                                />
                            </Row>
                        </BaseForm>
                    </Col>
                </Row>
            </BoxedSection>
            {
                sendCustomerEmails &&
                <BoxedSection title="Invoice Email Settings">
                    <Row>
                        <Col md="8">
                            <BaseForm ref={emailSettingsRef} initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={updateSettings}>
                                <Row>
                                    {!props.isOverride &&
                                        <BaseForm.Input
                                            type="text" colSpan="12" name="reply_to_email_address" label="Reply-to Address to All Email Responses"
                                            tooltip="Responses to the invoice emails from customers will be sent to this email address."
                                            placeholder="payments_admin@example.com"
                                            transformations={["lowercase", "trim"]} validations={{ validEmail: true }}
                                            onBlur={submitEmailSettingsForm}
                                        />
                                    }
                                    <BaseForm.Input
                                        type="text" colSpan="12" name="cc_email_addresses" label="Get CC'd on All Invoice Emails"
                                        tooltip="CC emails to add to all invoice emails (generation, reminders, success and failures). Feel free to add as many emails as you like. Separate each email with a comma such as 'one@company.com, two@company.com, three@company.com'"
                                        placeholder="one@company.com, two@company.com, three@company.com"
                                        transformations={["lowercase", "trim"]} validations={{ validEmails: true }}
                                        onBlur={submitEmailSettingsForm}
                                    />
                                    <BaseForm.Input type="switch" colSpan="12" name="attach_pdf" label="Attach PDF to Invoice Emails" />
                                    <BaseForm.Input
                                        type="switch" colSpan="12" name="send_invoice_generation_email"
                                        label="Email Invoice to Customer Automatically"
                                        tooltip="Send an email to the customer automatically when an invoice is generated, regardless of how it is expected to be paid."
                                    />
                                    <BaseForm.Input
                                        type="switch" colSpan="12" name="send_invoice_payment_success"
                                        label="Send Payment Success Email to Customer"
                                        tooltip="Send a payment success email to the customer when a payment is made against an invoice."
                                    />
                                    <BaseForm.Input
                                        type="switch" colSpan="12" name="send_invoice_payment_failure"
                                        label="Email Customer When Payment Fails"
                                        tooltip="Send a payment failure email to the customer when a payment attempt ends in a failure."
                                    />
                                </Row>
                            </BaseForm>
                        </Col>
                    </Row>
                </BoxedSection>
            }
            {
                sendCustomerEmails &&
                <BoxedSection title="Payment Retries">
                    <Row>
                        <Col md="12">
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <Row>
                                    <BaseForm.Input
                                        colSpan="5" type="switch" name="auto_charge_invoice_payment_retry_enabled"
                                        label="Enable Payment Retries and Retry Notifications"
                                    />
                                    {
                                        autoChargeInvoicePaymentRetryEnabled && !autoChargeInvoicePaymentRetryEditing &&
                                            <span className="body1">
                                                {
                                                    settings.auto_charge_invoice_payment_retry_config &&
                                                        <><span className="body2">{_.capitalize(settings.auto_charge_invoice_payment_retry_config.grouping_name)}</span> -&nbsp;</>
                                                }
                                                {
                                                    settings.auto_charge_invoice_payment_retry_config && _.map(settings.auto_charge_invoice_payment_retry_config.config.items, (item, i) =>
                                                        <React.Fragment key={i}>{ getConfigItemDescription(item) }{ i === settings.auto_charge_invoice_payment_retry_config.config.items.length-1 ? "": ", " }</React.Fragment>
                                                    )
                                                }
                                                <br/>
                                                <Link href="#" onClick={(event) => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    setAutoChargeInvoicePaymentRetryEditing(true)
                                                }}>Change</Link>
                                            </span>
                                    }
                                </Row>
                            </BaseForm>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            {
                                autoChargeInvoicePaymentRetryEnabled && autoChargeInvoicePaymentRetryEditing &&
                                    <Row>
                                        <Col lg="3">{renderRetrySchedule("basic")}</Col>
                                        <Col lg="3">{renderRetrySchedule("lenient")}</Col>
                                        <Col lg="3">{renderRetrySchedule("aggressive")}</Col>
                                        <Col lg="3">{renderRetrySchedule("custom")}</Col>
                                    </Row>
                            }
                        </Col>
                    </Row>
                </BoxedSection>
            }
            {
                sendCustomerEmails &&
                <BoxedSection title="Invoice Reminders">
                    <Row>
                        <Col md="12">
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <Row>
                                    <BaseForm.Input colSpan="5" type="switch" name="non_auto_charge_invoice_reminder_enabled" label="Enable and Configure Invoice Reminders" />
                                    {
                                        nonAutoChargeInvoiceReminderEnabled && !nonAutoChargeInvoiceReminderEditing &&
                                        <span className="body1">
                                            {
                                                settings.non_auto_charge_invoice_reminder_config &&
                                                    <><span className="body2">{_.capitalize(settings.non_auto_charge_invoice_reminder_config.grouping_name)}</span> -&nbsp;</>
                                            }
                                            {
                                                settings.non_auto_charge_invoice_reminder_config && _.map(settings.non_auto_charge_invoice_reminder_config.config.items, (item, i) =>
                                                    <React.Fragment key={i}>{ getConfigItemDescription(item) }{ i === settings.non_auto_charge_invoice_reminder_config.config.items.length-1 ? "": ", " }</React.Fragment>
                                                )
                                            }
                                            <br/>
                                            <Link href="#" onClick={(event) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                setNonAutoChargeInvoiceReminderEditing(true)
                                            }}>Change</Link>
                                        </span>
                                    }
                                </Row>
                            </BaseForm>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            {
                                nonAutoChargeInvoiceReminderEnabled && nonAutoChargeInvoiceReminderEditing &&
                                    <Row>
                                        <Col lg="3">{renderReminderSchedule("basic")}</Col>
                                        <Col lg="3">{renderReminderSchedule("lenient")}</Col>
                                        <Col lg="3">{renderReminderSchedule("aggressive")}</Col>
                                        <Col lg="3">{renderReminderSchedule("custom")}</Col>
                                    </Row>
                            }
                        </Col>
                    </Row>
                </BoxedSection>
            }
            {
                sendCustomerEmails && 
                <BoxedSection title="Card Expiry Reminders">
                    <Row>
                        <Col md="12">
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <Row>
                                    <BaseForm.Input colSpan="5" type="switch" name="card_expiry_reminder_enabled" label="Enable and Configure Card Expiry Reminders" />
                                    {
                                        cardExpiryReminderEnabled && !cardExpiryReminderEditing &&
                                        <span className="body1">
                                            {
                                                settings.card_expiry_reminder_config &&
                                                    <><span className="body2">{_.capitalize(settings.card_expiry_reminder_config.grouping_name)}</span> -&nbsp;</>
                                            }
                                            {
                                                settings.card_expiry_reminder_config && _.map(settings.card_expiry_reminder_config.config.items, (item, i) =>
                                                    <React.Fragment key={i}>{ getConfigItemDescription(item) }{ i === settings.card_expiry_reminder_config.config.items.length-1 ? "": ", " }</React.Fragment>
                                                )
                                            }
                                            <br/>
                                            <Link href="#" onClick={(event) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                setCardExpiryReminderEditing(true)
                                            }}>Change</Link>
                                        </span>
                                    }
                                </Row>
                            </BaseForm>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            {
                                cardExpiryReminderEnabled && cardExpiryReminderEditing &&
                                <Row>
                                    <Col lg="3">{renderCardExpirySchedule("basic")}</Col>
                                    <Col lg="3">{renderCardExpirySchedule("lenient")}</Col>
                                    <Col lg="3">{renderCardExpirySchedule("aggressive")}</Col>
                                    <Col lg="3">{renderCardExpirySchedule("custom")}</Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </BoxedSection>
            }
            <EditScheduleModal
                show={showEditAutoChargeRetryModal}
                actions={["payment_retry", "notification", "cancel_subscription", "unpaid_subscription", "admin_notification"]}
                onClose={onModalClose}
                onScheduleUpdated={onCustomAutoChargeRetryScheduleSelected}
                defaultItems={settings.auto_charge_invoice_payment_retry_config && settings.auto_charge_invoice_payment_retry_config.config.items} />
            <EditScheduleModal
                show={showEditNonAutoChargeScheduleModal}
                actions={["notification", "cancel_subscription", "unpaid_subscription", "admin_notification"]}
                onClose={onModalClose}
                onScheduleUpdated={onCustomNonAutoChargeReminderScheduleSelected}
                defaultItems={settings.non_auto_charge_invoice_reminder_config && settings.non_auto_charge_invoice_reminder_config.config.items} />
            <EditScheduleModal
                show={showEditCardExpiryScheduleModal}
                actions={["notification"]}
                onClose={onModalClose}
                onScheduleUpdated={onCustomCardExpiryReminderScheduleSelected}
                defaultItems={settings.card_expiry_reminder_config && settings.card_expiry_reminder_config.config.items} />
        </>
    );
};

export default InvoiceSettingsNotifications;
