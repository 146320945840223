import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import CopyableComponent from '../CopyableComponent';
import { useContext, useState, useEffect } from 'react';
import {BaseContext, getCustomerNameOrEmail} from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row } from 'react-bootstrap';
import Button from '../common/buttons/Button';
import Loader from "../Loader";
import Link from "../Link";
const _ = require("lodash");

function ShareCustomerPortalLinkModal(props) {
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [customer, setCustomer] = useState({});
    const [loading, setLoading] = useState(true);
    const [linkInfo, setLinkInfo] = useState(null);

    useEffect(() => {
        setCustomer(props.customer)
    }, [props.customer])

    useEffect(() => {
        setCustomer(props.customer);
        if (props.show) {
            serverPost(getApiUrl(`/customer_portals/${props.customer.id}/link`), {}).then(res => {
                setLoading(false);
                setLinkInfo(res);
            })
        }
    }, [props.show, props.customer])

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Customer Portal Link for { getCustomerNameOrEmail(customer) }</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <Loader loading={loading}>
                    <Row>
                        <p className="text-sm">To configure the information shown in the customer portal, please visit <Link href={getCompanySpecificUrl(`/settings/customer_portal`)}>customer portal settings page</Link>.</p>
                        <p className="text-sm mt-4 mb-2">This is a shareable customer portal link for { getCustomerNameOrEmail(customer) }. The link is valid for 24 hours.</p>
                        {
                            linkInfo &&
                                <CopyableComponent
                                    isButton
                                    value="Copy Link"
                                    copyableValue={linkInfo.link}
                                />
                        }
                    </Row>
                </Loader>
            </BaseModal.Body>
            <BaseModal.Footer>
                <div className="d-flex flex-row align-items-end">
                    <Button variant="primary" onClick={() => props.onClose(false)}>Close</Button>
                </div>
            </BaseModal.Footer>
        </BaseModal>
    );

}

export default ShareCustomerPortalLinkModal;
