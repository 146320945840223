import '../App.scss';
import React, { useEffect, useState } from 'react';
import {serverFetch, getUrl, subscribeToEvent} from "../helpers/server";
import { AppContext, UserContext } from "../helpers/common";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const _ = require('lodash');

function OptionalAuthorization(props) {
    const isLoggedIn = localStorage.getItem("auth_token") !== null;
    const navigate = useNavigate();
    const [loadingUserInfo, setLoadingUserInfo] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);
	const [isMobileNavBarVisible, setIsMobileNavBarVisible] = useState(window.innerWidth <= 1023);
    const allReady = !loadingUserInfo;

    useEffect(() => {
        fetchUserData();
        subscribeToEvent("user_settings", (data) => {
            fetchUserData(true)
        });
    }, []);

    useEffect(() => {
        if (!allReady) {
            return;
        }
    }, [allReady, navigate]);

    useEffect(() => {
		const checkMobileNavBarIsVisible = () => {
            // this is when the mobile header becomes visible
			setIsMobileNavBarVisible(window.innerWidth <= 1023);
		};

		window.addEventListener("resize", checkMobileNavBarIsVisible);
		return () => {
			window.removeEventListener("resize", checkMobileNavBarIsVisible);
		}
	}, []);

    const getNavCollapsedKey = (userId) => {
        return `${userId}_nav_collapsed`;
    };

    useEffect(() => {
        if (_.isNil(userInfo)) return;

        if (userInfo.id) {
            setIsNavCollapsed(!!JSON.parse(localStorage.getItem(getNavCollapsedKey(userInfo.id))));
        }
    }, [userInfo]);

    const openNav = () => {
        localStorage.setItem(getNavCollapsedKey(userInfo.id), "false");
        setIsNavCollapsed(false);
    };

    const closeNav = () => {
        localStorage.setItem(getNavCollapsedKey(userInfo.id), "true");
        setIsNavCollapsed(true);
    };

    const fetchUserData = (skipCache) => {
        const handleError = (error) => {
            setLoadingUserInfo(false);
        }

        const authToken = localStorage.getItem("auth_token");
        if (!authToken) {
            setLoadingUserInfo(false);
        } else {
            serverFetch(getUrl(`/users/current`), { skipCache }, handleError).then((res) => {
                if (res) {
                    setUserInfo(res);
                }
                setLoadingUserInfo(false);
            })
        }
    }

    let isSuperUser = false
    let isMapleUser = false
    if (!_.isNil(userInfo)) {
        if (userInfo.email && _.endsWith(userInfo.email, "@maplebilling.com") && userInfo.verified) {
            isMapleUser = true;
        }
        if (!_.isEmpty(userInfo) && userInfo.is_super_user) {
            isSuperUser = true;
            isMapleUser = true;
        }
    }

    return (
        <AppContext.Provider value={{ isMobileNavBarVisible, isNavCollapsed, openNav, closeNav }}>
            <UserContext.Provider value={{
                userInfo,
                isMapleUser: isMapleUser,
                isSuperUser: isSuperUser,
                isLoggedIn: !_.isEmpty(userInfo),
            }}>
            {
                allReady && <Outlet />
            }
            </UserContext.Provider>
        </AppContext.Provider>
    );
}

export default OptionalAuthorization;
