import '../../App.scss';
import React, {useEffect, useState, useContext, useRef} from 'react';
import {serverFetch, serverPatch, serverPost} from '../../helpers/server';
import { BaseContext, UserContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import BaseForm from '../../components/BaseForm';
import ContentContainer from '../../components/ContentContainer';
import {Col, Row} from 'react-bootstrap';
import Loader from "../../components/Loader";
import ContentBox from "../../components/ContentBox";
import SubmitButton from "../../components/common/buttons/SubmitButton";
import Notification from "../../components/Notification";
import CopyableComponent from "../../components/CopyableComponent";
import Link from '../../components/Link';
const _ = require('lodash');

function CustomerPortalSettings(props) {
    const { getApiUrl, company, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { isMapleUser } = useContext(UserContext);

    const [customerPortalEnabled, setCustomerPortalEnabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState({});
    const [selectedCustomerPortal, setSelectedCustomerPortal] = useState(null);
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);
    const [showSubscriptions, setShowSubscriptions] = useState(false);

    const customerPortalFormRef = useRef(null);

    useEffect(() => {
        setPageTitle(`Customer Portal - Manage`);
    }, []);

    const onSearch = () => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl(`/customer_portals/find`), params).then((res) => {
            if (res) {
                if (res.length > 0) {
                    const portal = _.find(res, (r) => !r.customer_id);
                    setSelectedCustomerPortal(portal);
                    setShowCustomerInfo(portal.options.customer_info.show)
                    setShowPaymentMethods(portal.options.payment_methods.show)
                    setShowSubscriptions(portal.options.subscription.show)
                }
            }
            setLoading(false);
        });
    }
    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            if (res) {
                setSettings(res)
            }
        })

        onSearch();
    }, [])

    useEffect(() => {
        setCustomerPortalEnabled(settings.customer_portal_enabled);
    }, [settings]);

    const onFieldChange = (name, value) => {
        if (name === "customer_portal_enabled") {
            updateCompanySettings({ customer_portal_enabled: value });
        } else if (name === "options.customer_info.show") {
            setShowCustomerInfo(value);
        } else if (name === "options.payment_methods.show") {
            setShowPaymentMethods(value);
        } else if (name === "options.subscription.show") {
            setShowSubscriptions(value);
        }

        if ([
            "customer_portal_enabled",
            "options.customer_info.show",
            "options.customer_info.editable",
            "options.payment_methods.show",
            "options.payment_methods.creatable",
            "options.subscription.show",
            "options.subscription.editable_plan",
            "options.subscription.cancellable",
        ].includes(name) && customerPortalFormRef.current) {
            customerPortalFormRef.current.submitForm();
        } 
    }

    const updateCompanySettings = async (data) => {
        const result = await serverPatch(getApiUrl(`/settings`), data);
        if (result) {
            if (data.customer_portal_enabled) {
                Notification.Success("Customer portal enabled");
            } else {
                Notification.Success("Customer portal disabled");
            }
            setSettings(result);
            if (data.customer_portal_enabled && _.isNil(selectedCustomerPortal)) {
                let portalResult = await serverPost(getApiUrl(`/customer_portals`), {});
                if (portalResult) {
                    setSelectedCustomerPortal(portalResult);
                    Notification.Success("Customer portal settings successfully updated");
                }
            }
        }
    }

    const updateCustomerPortalSettings = async (data, defaultErrorHandler) => {
        const result = serverPatch(getApiUrl(`/customer_portals/${selectedCustomerPortal.id}`), data, {}, defaultErrorHandler);
        if (result) {
            Notification.Success("Customer portal settings successfully updated");
        }
    }

    const relativeUrl = getCompanySpecificUrl("/portal/login")
    const baseUrl = process.env.REACT_APP_BASE_WEB_URL || "https://app.maplebilling.com";
    const portalLoginLink = `${baseUrl}${relativeUrl}`;
    return (
        <BaseContainer>
            <ContentContainer title="Customer Portals" variant="page">
                <Loader loading={loading}>
                    <ContentBox>
                        <ContentBox.Title>
                            Enable Customer Portal
                        </ContentBox.Title>
                        <ContentBox.Body>
                            <BaseForm initialFormFields={settings} onFieldChange={onFieldChange}>
                                <Row>
                                    <BaseForm.Input
                                        colSpan="9" name="customer_portal_enabled" label={"Enable Customer Portal"}
                                        tooltip="Manage a hosted page to allow customers to manage their invoices, subscriptions and billing information."
                                        type="switch"
                                    />
                                </Row>
                            </BaseForm>
                            {
                                customerPortalEnabled &&
                                    <div className="mt-2">
                                        <p className="text-sm font-semibold">Customer portal link</p>
                                        <p className="text-sm">Here is a shareable customer portal link that will allow your customers to view the portal with their email.</p>
                                        <CopyableComponent value={portalLoginLink}/>
                                        <p className="mt-1">For customer specific portal link, you can get one through the <Link newTab isExternalLink href="https://maplebilling.readme.io/reference/get_customer_portal_link">API</Link> or from the customer page in Maple.</p>
                                    </div>
                            }
                        </ContentBox.Body>
                    </ContentBox>
                    {customerPortalEnabled && (
                        <BaseForm ref={customerPortalFormRef} initialFormFields={selectedCustomerPortal} onFieldChange={onFieldChange} onSubmit={updateCustomerPortalSettings}>
                            <ContentBox>
                                <ContentBox.Title>
                                    Customer Info
                                </ContentBox.Title>
                                <ContentBox.Body>
                                    <Col md="7">
                                        <Row>
                                            <BaseForm.Input
                                                colSpan="12" type="switch" name="options.customer_info.show" label="Allow customers to view their billing information"
                                                formClassName="inline"
                                            />
                                        </Row>
                                        {
                                            showCustomerInfo &&
                                            <Row className="mt-2">
                                                <Col md="1"/>
                                                <BaseForm.Input
                                                    colSpan="11" type="switch" name="options.customer_info.editable" label="Allow customers to edit their billing information"
                                                    formClassName="inline"
                                                />
                                            </Row>
                                        }
                                    </Col>
                                </ContentBox.Body>
                            </ContentBox>
                            <ContentBox>
                                <ContentBox.Title>
                                    Subscriptions
                                </ContentBox.Title>
                                <ContentBox.Body>
                                    <Col md="7">
                                        <Row>
                                            <BaseForm.Input
                                                colSpan="12" type="switch" name="options.subscription.show" label="Allow customers to view their subscriptions"
                                                formClassName="inline"
                                            />
                                        </Row>
                                        {
                                            isMapleUser && showSubscriptions &&
                                            <Row className="mt-2">
                                                <Col md="1"/>
                                                <BaseForm.Input
                                                    colSpan="11"
                                                    type="switch"
                                                    name="options.subscription.editable_plan"
                                                    label="Allow customers to update their plan quantities"
                                                    tooltip="Increase in quantity will immediately charge a prorated amount. Decrease in quantity will only affect the quantity (no refund). The next invoice will charge the correct amount."
                                                    formClassName="inline"
                                                />
                                            </Row>
                                        }
                                        {
                                            showSubscriptions &&
                                            <Row className="mt-2">
                                                <Col md="1"/>
                                                <BaseForm.Input
                                                    colSpan="11"
                                                    type="switch"
                                                    name="options.subscription.cancellable"
                                                    label="Allow customers to cancel their subscriptions"
                                                    tooltip="Cancellation will happen at the end of their current billing period."
                                                    formClassName="inline"
                                                />
                                            </Row>
                                        }
                                    </Col>
                                </ContentBox.Body>
                            </ContentBox>
                            <ContentBox>
                                <ContentBox.Title>
                                    Payment Methods
                                </ContentBox.Title>
                                <ContentBox.Body>
                                    <Col md="7">
                                        <Row>
                                            <BaseForm.Input
                                                colSpan="12" type="switch" name="options.payment_methods.show" label="Allow customers to view their payment methods on file"
                                                formClassName="inline"
                                            />
                                        </Row>
                                        {
                                            showPaymentMethods &&
                                            <Row className="mt-2">
                                                <Col md="1"/>
                                                <BaseForm.Input
                                                    colSpan="11" type="switch" name="options.payment_methods.creatable"
                                                    label="Allow customers to add and remove their payment methods"
                                                    formClassName="inline"
                                                />
                                            </Row>
                                        }
                                    </Col>
                                </ContentBox.Body>
                            </ContentBox>
                            <ContentBox>
                                <ContentBox.Title>
                                    Miscellaneous
                                </ContentBox.Title>
                                <ContentBox.Body>
                                    <Col md="7">
                                        <Row>
                                            <BaseForm.Input
                                                colSpan="12" type="text" name="business_info.message" label="Message"
                                                tooltip="An optional title to be shown on the left panel."
                                                placeholder={`${company.name} partners with Maple for simplified billing.`}
                                            />
                                            <BaseForm.Input
                                                colSpan="12" type="text" name="business_info.terms_of_service_url" label="Terms of service (optional)"
                                                tooltip="An optional terms of service link to be shown."
                                            />
                                            <BaseForm.Input
                                                colSpan="12" type="text" name="business_info.privacy_policy_url" label="Privacy Policy (optional)"
                                                tooltip="An optional privacy policy link to be shown."
                                            />
                                            <BaseForm.Input
                                                colSpan="12" type="text" name="redirect_url" label="Redirect URL (optional)"
                                                tooltip="An optional url to redirect the customer to, when the back button is pressed."
                                                placeholder="https://www.google.com"
                                            />
                                        </Row>
                                        <SubmitButton errorOnRight>Update</SubmitButton>
                                    </Col>
                                </ContentBox.Body>
                            </ContentBox>
                        </BaseForm>
                    )}
                </Loader>
            </ContentContainer>
        </BaseContainer>
    );
}

export default CustomerPortalSettings;
