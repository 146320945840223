import React, { useEffect, useRef, useState, useContext, useMemo, createRef, forwardRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {serverDelete, serverFetch, serverPatch, serverPost} from '../helpers/server';
import {
    BaseContext,
    getCustomerNameOrEmail,
    getInvoicePaymentTermFromDueDateFromCreation,
    getInvoicePaymentTermOptions,
    findCustomVariables,
    UserContext,
    renderCustomVariableInput,
    findNumberOfCounterSignatories,
    findNumberOfSignatories,
    validateSignatories,
    currencyFormatFromPrice, getPlanLengthOptions,
    renderContractStatusLabel, isAddressEmpty, downloadBlob,
} from '../helpers/common';
import ContentContainer from './ContentContainer';
import ContentBox from './ContentBox';
import BaseForm, { BaseFormContext } from './BaseForm';
import ProductPricingSelection from './ProductPricingSelection';
import Loader from './Loader';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomerInput from "./CustomerInput";
import Link from "./Link";
import useGetMembers from "../helpers/hooks/api/useGetMembers";
import BundlePricingTable from "./BundlePricingTable";
import AddOrEditProductPricingModal2 from "./modals/AddOrEditProductPricingModal2";
import AddOrEditCustomerModal from "./modals/AddOrEditCustomerModal";
import SearchDealsModal from "./modals/SearchDealsModal";
import Button from "./common/buttons/Button";
import Section from './Section';
import _ from 'lodash';
import { isContractDraft } from '../helpers/contracts';
import Notification from './Notification';
import Plus from '@untitled-ui/icons-react/build/esm/Plus';
import X from '@untitled-ui/icons-react/build/esm/X';
import Alert from "./common/Alert";
import classnames from "classnames";
import UploadContractAttachmentModal from "./modals/UploadContractAttachmentModal";
import MapleTable from "./MapleTable";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import DeleteButton from "./DeleteButton";

function ContractCreateUpdate(props, formCreateUpdateRef) {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const [ searchParams ] = useSearchParams();
    let defaultCustomerId = searchParams.get('customer');
    let previousSubscriptionId = searchParams.get('ps');
    let dealID = searchParams.get('did');
    let integrationID = searchParams.get('iid');

    const {isMapleUser} = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const { userInfo } = useContext(UserContext);
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamMemberOptions, setTeamMemberOptions] = useState([]);
    const [counterSignatoryOptions, setCounterSignatoryOptions] = useState([]);
    const [settings, setSettings] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productPricings, setProductPricings] = useState([]);
    const [showPOField, setShowPOField] = useState(!!props.contractDetails?.po_number);
    const [error, setError] = useState(null);
    const pricingsRef = createRef();
    const [previousSubscription, setPreviousSubscription] = useState(null);
    const [defaultCustomer, setDefaultCustomer] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [addressEditCustomer, setAddressEditCustomer] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [isEditing, setIsEditing] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    const [showTrialDaysInput, setShowTrialDaysInput] = useState(false);
    const [initialFields, setInitialFields] = useState({});
    const [startDateType, setStartDateType] = useState("ON_COMPLETION");
    const [changeDateType, setChangeDateType] = useState("RENEWAL");
    const [invoicePaymentTerms, setInvoicePaymentTerms] = useState("IMMEDIATE");
    const [autoCharges, setAutoCharges] = useState(true);
    const [customVariables, setCustomVariables] = useState([]);
    const [availableCustomVariables, setAvailableCustomVariables] = useState([]);
    const [prefetchDealDetails, setPrefetchDealDetails] = useState(null);
    const [askForPhoneNumber, setAskForPhoneNumber] = useState(false);
    const [numberOfCounterSignatories, setNumberOfCounterSignatories] = useState(0);
    const [numberOfSignatories, setNumberOfSignatories] = useState(0);
    const [hasNonOneTimeItems, setHasNonOneTimeItems] = useState(false);
    const [signatoryError, setSignatoryError] = useState(null);
    const [showAddProductPricingModal, setShowAddProductPricingModal] = useState(false);
    const [showAddOrEditCustomerModal, setShowAddOrEditCustomerModal] = useState(false);
    const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
    const [canImportDealsFromIntegration, setCanImportDealsFromIntegration] = useState(false);
    const formRef = createRef();
    const signatoryNameUpdatedRef = useRef(false);
    const signatoryEmailUpdatedRef = useRef(false);
    const signatoryTitleUpdatedRef = useRef(false);
    const titleUpdatedRef = useRef(false);
    const [errorTriggered, setErrorTriggered] = useState(false);
    const [hasDetailsError, setHasDetailsError] = useState(false);
    const [hasSignatoriesError, setHasSignatoriesError] = useState(false);
    const [hasProductPricingError, setHasProductPricingError] = useState(false);
    const [collapsedStates, setCollapsedStates] = useState({
        details: false,
        signatories: false,
        productPricing: false,
    });
    const [customAddressErrorMessage, setCustomAddressErrorMessage] = useState(null);
    const [showUploadContractAttachmentModal, setShowUploadContractAttachmentModal] = useState(false);
    const [contractAttachmentFileIds, setContractAttachmentFileIds] = useState(null);
    const [newContractAttachments, setNewContractAttachments] = useState([]);
    const [formErrors, setFormErrors] = useState(null);
    const { members: nonPendingMembers, fetchMembers } = useGetMembers(false);
    const defaultFields = useMemo(() => {
        return {
            term: {
                frequency: "YEAR",
                count: 1
            },
            phases: [{
                relative_term: {
                    count: 1,
                    frequency: "YEAR"
                }
            }],
            start_date: moment().add(1, 'day').hour(11).minute(0).second(0),
            num_trial_days: props.initialContractData?.num_trial_days || 0,
        }
    }, [props.initialContractData]);
    const hasIntegrationsReadPermission = hasAccess("integrations", userInfo, "read");

    const isCreatingContract = useMemo(() => _.isNil(uuid), [uuid]);

    useEffect(() => {
        setPageTitle(`Contracts`);
    }, []);

    useEffect(() => {
        if (teamMemberOptions.length === 0) return;
        let formContractData = {};
        if (formCreateUpdateRef.current) {
            formContractData = formCreateUpdateRef.current.getFormData();
            delete formContractData["phases"];
        }
        setInitialFields((prevFields) => {
            return {
                ...prevFields,
                ...props.initialContractData,
                ...formContractData,
                template_id: props.initialContractData?.template_id,
            }
        });
        if (props.initialContractData) {
            setSelectedCustomer({
                ...(formContractData?.customer ? formContractData?.customer : props.initialContractData.customer)
            });
            setAutoCharges(prevAutoCharge => props.initialContractData.auto_charges ?? prevAutoCharge);
        }
    }, [props.initialContractData, teamMemberOptions]);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
            if (isCreatingContract) {
                setInvoicePaymentTerms(getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation))
                if (_.isNil(res.payment_config)) {
                    setInitialFields(prevFields => {
                        const newFields = {...prevFields};
                        newFields.auto_charges = false;
                        newFields.require_payment_method = false;
                        return newFields;
                    });
                    setAutoCharges(false);
                } else {
                    setInitialFields(prevFields => {
                        const newFields = {...prevFields};
                        newFields.auto_charges = true;
                        newFields.require_payment_method = res.contract_payment_method_required;
                        return newFields;
                    });
                }

                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.invoice_payment_terms = getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation);
                    newFields.invoice_due_date_from_creation = res.invoice_due_date_from_creation;

                    if (res.default_contract_template_id) {
                        newFields.template_id = res.default_contract_template_id;
                    }
                    if (res.default_contract_counter_signatory_id) {
                        newFields.counter_signatories = [res.default_contract_counter_signatory_id]
                    }
                    return newFields;
                });
            }
        });

        serverPost(getApiUrl("/proposals/variables/find"), {}).then((res) => {
            setAvailableCustomVariables(res);
        });

        if (hasIntegrationsReadPermission) {
            const integrationQuery = {
                enabled_features: ["CRM"],
                status: "ACTIVE"
            }
            serverPost(getApiUrl('/integrations/installed'), { query: integrationQuery }).then((res) => {
                if (res) {
                    const results = res.results || [];
                    const supportedIntegrations = _.filter(results, r => _.includes(["attio", "hubspot"], r.key))
                    setCanImportDealsFromIntegration(supportedIntegrations.length > 0);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (prefetchDealDetails) {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                if (prefetchDealDetails.customer) {
                    newFields.customer = prefetchDealDetails.customer;
                    newFields.signatories = [{
                        name: prefetchDealDetails.customer.name,
                        email: prefetchDealDetails.customer.email
                    }]
                    setSelectedCustomer(prefetchDealDetails.customer)
                }
                if (prefetchDealDetails.deal) {
                    newFields.title = prefetchDealDetails.deal.title;
                    if (prefetchDealDetails.deal.product_pricings) {
                        const prefetchConfig = {};
                        const prefetchDiscounts = {};
                        _.each(prefetchDealDetails.deal.product_pricings, (dealPP) => {
                            const pp = _.find(productPricings, p => p.id === dealPP.product_pricing_id);
                            if (pp) {
                                let percentageDiscount = null;
                                if (dealPP.discounts && dealPP.discounts.length > 0) {
                                    if (dealPP.discounts[0].type === "DISCOUNT_PERCENT") {
                                        percentageDiscount = dealPP.discounts[0].percent * 100
                                    }
                                }
                                _.each(pp.product_metric_pricings, (pmp) => {
                                    prefetchConfig[pmp.id] = {
                                        num_licenses: dealPP.num_licenses
                                    }
                                    if (percentageDiscount) {
                                        prefetchDiscounts[pmp.item_pricing_id] = {
                                            percentage: percentageDiscount
                                        }
                                    }
                                })
                            }
                        })
                        newFields.phases = [{
                            product_pricing_ids: _.map(prefetchDealDetails.deal.product_pricings, pp => pp.product_pricing_id),
                            config: prefetchConfig,
                            discounts: prefetchDiscounts
                        }]
                        if (pricingsRef.current) {
                            pricingsRef.current.setInitialData(newFields);
                        }
                    }
                }
                return newFields;
            });
        }
    }, [prefetchDealDetails, productPricings])

    useEffect(() => {
        if (dealID && integrationID) {
            serverFetch(getApiUrl(`/proposals/prefetch/${integrationID}/${dealID}`)).then((res) => {
                if (res) {
                    setPrefetchDealDetails(res);
                }
            })
        }
    }, [dealID, integrationID]);

    useEffect(() => {
        if (defaultCustomerId) {
            serverFetch(getApiUrl(`/customers/${defaultCustomerId}`), { skipCache: true }).then((res) => {
                setDefaultCustomer(res);
                setSelectedCustomer(res);
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.customer = res;
                    newFields.signatories = [{
                        name: res.name,
                        title: res.title,
                        email: res.email,
                        position: 0
                    }]
                    return newFields;
                });
            });
        }
    }, [defaultCustomerId])

    useEffect(() => {
        if (previousSubscriptionId) {
            serverFetch(getApiUrl(`/subscriptions/${previousSubscriptionId}`), { skipCache: true }).then((res) => {
                setPreviousSubscription(res);
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.phases = [{
                        product_pricing_ids: res.product_pricing_ids,
                        config: _.keyBy(res.config_items, 'product_metric_pricing_id'),
                        discounts: _.mapValues(_.keyBy(res.discounts, "item_pricing_id"), (v) => {
                            return {
                                percentage: v.percent * 100,
                                amount: v.amount.value_in_cents / 100,
                                type: v.type,
                                expiration_date: v.expiration_date,
                            };
                        }),
                        minimum_spend: res.minimum_spend && res.minimum_spend.value_in_cents/100,
                    }]
                    newFields.title = `${res.customer.org_name || res.customer.name} - ${company.name} Renewal Contract`
                    newFields.customer = res.customer;
                    newFields.signatories = [{
                        name: res.customer.name,
                        title: res.customer.title,
                        email: res.customer.email,
                        position: 0
                    }]
                    newFields.term = res.term;
                    return newFields;
                })
            });
        }
    }, [previousSubscriptionId]);

    useEffect(() => {
        if (userInfo) {
            const currentUser = _.find(nonPendingMembers, (m) => String(m.user.id) === String(userInfo.id));
            if (currentUser) {
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.owner = currentUser.id;
                    return newFields;
                })
            }
        }

        const teamOptions = _.map(nonPendingMembers, (member) => {
            return {
                value: member.id,
                label: member.user.name || member.user.email
            }
        })
        teamOptions.sort((a, b) => {
            if (a.label < b.label) {
                return -1
            } else {
                return 1
            }
        })
        const counterSignOptions = teamOptions.map((option) => option)

        teamOptions.unshift({value: null, label: "No one"})
        counterSignOptions.unshift({value: null, label: "Skip counter sign"})

        setTeamMembers(nonPendingMembers)
        setTeamMemberOptions(teamOptions)
        setCounterSignatoryOptions(counterSignOptions)
    }, [nonPendingMembers])

    useEffect(() => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/product_pricings/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                setProductPricings(results);
            }
        });

        serverPost(getApiUrl("/proposals/templates/find"), {}).then((res) => {
            setLoading(false);
            setTemplates(res);
        });

        fetchMembers();
    }, [])

    useEffect(() => {
        if (!isCreatingContract) {
            setIsEditing(true);
        } else {
            setInitialFields(defaultFields);
            setIsEditing(false);
        }
    }, [uuid, isCreatingContract])

    useEffect(() => {
        if ((!error && !signatoryError) || !errorTriggered) return;

        // get the error element and scroll it into view
        const errorElement = document.getElementsByClassName("form-error-message")?.[0];
        if (errorElement) {
            errorElement.scrollIntoView({ block: "center" });
        }

        setErrorTriggered(false);
    }, [error, errorTriggered, signatoryError]);

    useEffect(() => {
        setCustomAddressErrorMessage(null);
        if (!_.isEmpty(selectedCustomer) && !_.isEmpty(settings)) {
            if (settings.tax_calc_enabled && isAddressEmpty(selectedCustomer.address)) {
                const customer = _.cloneDeep(selectedCustomer);

                if (selectedCustomer.integration_id) {
                    customer['import_id'] = selectedCustomer.id;
                    customer.id = null;

                    if (selectedCustomer.maple_customer_id) {
                        customer.id = selectedCustomer.maple_customer_id;
                    }
                    if (selectedCustomer.integration_address_error) {
                        setCustomAddressErrorMessage("Integration customer address is invalid and is required for tax calculation. This can be fixed now or after the contract is executed.")
                    }
                }
                setAddressEditCustomer(customer);
                props.setShowCustomerAddressError(true);
            } else {
                props.setShowCustomerAddressError(false);
                setAddressEditCustomer(null);
            }
        } else {
            props.setShowCustomerAddressError(false);
            setAddressEditCustomer(null);
        }

    }, [selectedCustomer, settings]);

    const contractErrorHandler = async (res, errorResponseJson, defaultErrorHandler) => {
        const errorJson = await res.json();
        console.log("The error json is " + JSON.stringify(errorJson))
        if (errorJson.error_message) {
            if (_.includes(errorJson.error_message, "Customer needs phone number")) {
                setAskForPhoneNumber(true);
            }
        }
        defaultErrorHandler(res, errorJson);
    }

    const createContract = async (data, errorHandler, params) => {
        setSignatoryError(null);
        if (askForPhoneNumber) {
            const customerUpdateData = {
                phone: data.customer_phone
            }
            const customerResult = await serverPatch(getApiUrl(`/customers/${data.customer.id}`), customerUpdateData);
            if (!customerResult) {
                console.log("The customer update didn't work.");
            }
        }

        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(data);
            if (!_.isNil(validationResult)) {
                setErrorTriggered(true);
                setError(validationResult);
                if (validationResult === "Please select at least one product price.") {
                    setHasProductPricingError(true);
                    setCollapsedStates((prevState) => {
                        const udpatedState = {...prevState};
                        udpatedState.productPricing = false;
                        return udpatedState;
                    });
                }
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(data);
        }
        setError(null);
        let isToday = false;
        let startDate = null;
        if (data.start_date_type === "SPECIFIC_DATE") {
            isToday = moment(0, "HH").diff(data.start_date, "days") == 0;
            if (isToday) {
                startDate = moment();
            } else {
                startDate = data.start_date;
            }
        }
        let require_payment_method = data.auto_charges;
        if (!_.isNil(data.require_payment_method)) {
            require_payment_method = data.require_payment_method
        }
        const isTrialEnabled = data.num_trial_days > 0;
        const contractData = {
            ...pricingData,
            company_id: data.company_id,
            status: "DRAFT",
            auto_charges: data.auto_charges,
            require_payment_method: require_payment_method,
            title: data.title,
            owner_id: data.owner,
            term: data.term ? {
                frequency: data.term.frequency,
                count: parseInt(data.term.count)
            }: {
                frequency: "YEAR",
                count: 1
            },
            start_date: startDate && startDate.format(),
            expiration_date: data.expiration_date || null,
            template_id: data.template_id,
            type: "CONTRACT",
            invoice_due_date_from_creation: data.invoice_due_date_from_creation,
            po_number: showPOField ? data.po_number : null,
            custom_variables: data.custom_variables,
            parent_billed: data.parent_billed || false,
            trial: isTrialEnabled,
            start_type: startDateType,
            finalize: params.finalize,
        }
        if (data.counter_signatories) {
            data.counter_signatories = Object.keys(data.counter_signatories).reduce((acc, key) => {
                if (data.counter_signatories[key] && data.counter_signatories[key].length > 0) {
                    acc[key] = data.counter_signatories[key]
                }
                return acc
            }, {})
            contractData.counter_signatories = Object.keys(data.counter_signatories).map(key => {
                return {
                    company_user_id: (data.counter_signatories[key]),
                    position: parseInt(key)
                }
            })
        }
        if (data.signatories) {
            data.signatories = Object.keys(data.signatories).reduce((acc, key) => {
                if (data.signatories[key] && (data.signatories[key].name || data.signatories[key].email)) {
                    acc[key] = data.signatories[key]
                }
                return acc
            }, {})
            const err = validateSignatories(data.signatories)
            if (!_.isNil(err)) {
                setErrorTriggered(true);
                setSignatoryError(err)
                return
            }
            contractData.signatories = Object.keys(data.signatories).map(key => {
                return {
                    name: (data.signatories[key].name),
                    title: data.signatories[key].title,
                    email: data.signatories[key].email,
                    position: parseInt(key)
                }
            })
        }
        if (newContractAttachments.length) {
            contractData.file_attachment_ids = _.map(newContractAttachments, attachment => {
                return attachment.id
            })
        }
        if (isTrialEnabled) {
            contractData['trial_term'] = {
                frequency: 'DAY',
                count: data.num_trial_days,
            }
        }
        if (!_.isNil(data.invoice_payment_terms)) {
            if (data.invoice_payment_terms !== "custom") {
                contractData.invoice_due_date_from_creation = parseInt(data.invoice_payment_terms);
            }
        }
        if (dealID && integrationID) {
            contractData['integration_deal'] = {
                integration_id: integrationID,
                remote_id: dealID
            };
        }
        if (data.customer.integration_id) {
            contractData['integration_customer'] = {
                import_id: data.customer.id,
                integration_id: data.customer.integration_id,
                integration_object_type: data.customer.integration_object_type
            }
        } else {
            contractData['customer_id'] = data.customer.id;
        }

        if (previousSubscription) {
            contractData['previous_subscription_id'] = previousSubscription.id
            contractData['change_timing'] = data.change_timing;
            if (data.change_timing === "CUSTOM") {
                contractData['change_custom_date'] = data.change_custom_date;
            }
            contractData['change_proration_type'] = data.change_proration_type || "NONE"
            contractData['change_reset_billing_anchor'] = !_.isNil(data.change_reset_billing_anchor) ? data.change_reset_billing_anchor: false
        }

        const contractResult = await serverPost(getApiUrl('/proposals'), contractData, {}, (r, j) => contractErrorHandler(r, j, errorHandler))
        if (window.parent) {
            window.parent.postMessage(JSON.stringify({"action": "DONE"}), "*");
        }
        if (contractResult) {
            navigate(getCompanySpecificUrl(`/contract/view/${contractResult.id}`), { replace: true })
            Notification.Success(`${contractResult.title} created`);
        }
    }

    const updateContract = async (data, defaultErrorHandler, params) => {
        let pricingData = {};
        setSignatoryError(null);
        data.customer = props.contractDetails.customer;
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(data);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            } else {
                setError(null);
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(data);
        }

        let isToday = false;
        let startDate = null;
        if (data.start_date_type === "SPECIFIC_DATE") {
            isToday = moment(0, "HH").diff(data.start_date, "days") === 0;
            if (isToday) {
                startDate = moment();
            } else {
                startDate = data.start_date;
            }
        }

        let require_payment_method = data.auto_charges;
        if (!_.isNil(data.require_payment_method)) {
            require_payment_method = data.require_payment_method
        }
        const isTrialEnabled = data.num_trial_days > 0;
        const contractData = {
            ...pricingData,
            bundle_pricing_id: null,
            owner_id: data.owner || null,
            start_date: startDate,
            title: data.title,
            expiration_date: data.expiration_date || null,
            term: data.term ? {
                frequency: data.term.frequency,
                count: parseInt(data.term.count)
            } : {
                frequency: "YEAR",
                count: 1
            },
            auto_charges: data.auto_charges,
            require_payment_method: require_payment_method,
            invoice_due_date_from_creation: data.invoice_due_date_from_creation,
            po_number: showPOField ? data.po_number : null,
            custom_variables: data.custom_variables,
            trial: isTrialEnabled,
            start_type: startDateType,
        }
        if (data.counter_signatories) {
            data.counter_signatories = Object.keys(data.counter_signatories).reduce((acc, key) => {
                if (data.counter_signatories[key] && data.counter_signatories[key].length > 0) {
                    acc[key] = data.counter_signatories[key]
                }
                return acc
            }, {})
            contractData.counter_signatories = Object.keys(data.counter_signatories).map(key => {
                return {
                    company_user_id: (data.counter_signatories[key]),
                    position: parseInt(key)
                }
            })
        }

        if (data.signatories) {
            data.signatories = Object.keys(data.signatories).reduce((acc, key) => {
                if (data.signatories[key] && (data.signatories[key].name || data.signatories[key].email)) {
                    acc[key] = data.signatories[key]
                }
                return acc
            }, {})

            const err = validateSignatories(data.signatories)
            if (!_.isNil(err)) {
                setSignatoryError(err)
                return
            }
            contractData.signatories = Object.keys(data.signatories).map(key => {
                return {
                    name: (data.signatories[key].name),
                    title: data.signatories[key].title,
                    email: data.signatories[key].email,
                    position: parseInt(key)
                }
            })
        }
        if (newContractAttachments.length) {
            contractData.file_attachment_ids = _.map(newContractAttachments, attachment => {
                return attachment.id
            })
        }
        if (isTrialEnabled) {
            contractData['trial_term'] = {
                frequency: 'DAY',
                count: data.num_trial_days,
            }
        }
        if (!_.isNil(data.invoice_payment_terms)) {
            if (data.invoice_payment_terms !== "custom") {
                contractData.invoice_due_date_from_creation = parseInt(data.invoice_payment_terms);
            }
        }
        if (data.customer.integration_id) {
            contractData['integration_customer'] = {
                import_id: data.customer.id,
                integration_id: data.customer.integration_id,
                integration_object_type: data.customer.integration_object_type
            }
        } else {
            contractData['customer_id'] = selectedCustomer.id;
        }
        if (params.finalize) {
            contractData.finalize = true;
        }
        const result = await serverPatch(getApiUrl(`/proposals/${uuid}`), contractData, {}, async (res) => {
            const resJson = await res.json();
            defaultErrorHandler(res, resJson);
            if (params?.finalize) {
                if (params.onFinalizeContractError) {
                    params.onFinalizeContractError(resJson);
                }
            }
        });
        if (result) {
            if (params?.showEmail) {
                props.showEmailModal();
            }
            if (params.showNotification) {
                Notification.Success(params?.finalize ? "Contract finalized" : `${props.contractDetails.title} updated`);
            }
            await props.fetchData(true);
            setNewContractAttachments([]);
        } else {
            Notification.Danger(params?.finalize ? "Error finalizing contract" : "Could not update contract");
        }
    }

    const onFieldChange = (name, value) => {
        if (name === "customer") {
            if (value.__new__) {
                setShowAddOrEditCustomerModal(true);
            } else {
                if (formCreateUpdateRef.current) {
                    const formData = formCreateUpdateRef.current.getFormData();
                    let dataToUpdate = {
                        ...formData,
                        phases: [...initialFields.phases],
                        signatories: formData.signatories,
                        title: formData.title,
                        customer: value,
                    };
                    if (!signatoryNameUpdatedRef.current) {
                        dataToUpdate.signatories[0].name = value.name;
                    }
                    if (!signatoryEmailUpdatedRef.current) {
                        dataToUpdate.signatories[0].email = value.email;
                    }
                    if (!signatoryTitleUpdatedRef.current) {
                        dataToUpdate.signatories[0].title = value.title;
                    }
                    if (!titleUpdatedRef.current) {
                        dataToUpdate.title = `${value.org_name || value.name} - ${company.name} Contract`;
                    }
                    setInitialFields(prevFields => {
                        return {...prevFields, ...dataToUpdate};
                    })
                }
                setSelectedCustomer(value);
            }
        } else if (name === "signatory.name") {
            signatoryNameUpdatedRef.current = true;
        } else if (name === "signatory.email") {
            signatoryEmailUpdatedRef.current = true;
        } else if (name === "signatory.title") {
            signatoryTitleUpdatedRef.current = true;
        } else if (name === "title") {
            titleUpdatedRef.current = true;
        } else if (name === "start_date_type") {
            setStartDateType(value);
        } else if (name === "change_timing") {
            setChangeDateType(value);
        } else if (name === "invoice_payment_terms") {
            setInvoicePaymentTerms(value);
        } else if (name === "auto_charges") {
            setAutoCharges(value);
        } else if (name === "template_id") {
            if (!isCreatingContract) return;
            const selectedTemplate = _.find(templates, (t) => t.id === value);
            if (selectedTemplate) {
                setCustomVariables(findCustomVariables(selectedTemplate.content));
                setNumberOfCounterSignatories(findNumberOfCounterSignatories(selectedTemplate.content));
                setNumberOfSignatories(findNumberOfSignatories(selectedTemplate.content));
            }
        } else if (name === "trial") {
            setIsTrial(value);
        }
        if (pricingsRef.current) {
            pricingsRef.current.onFieldChange(name, value);
        }
    }

    const onDealSelected = (deal) => {
        let urlParser = new URL(window.location.href);
        if (deal) {
            urlParser.searchParams.set("iid", deal.integration_id);
            urlParser.searchParams.set("did", deal.import_id);
        } else {
            urlParser.searchParams.delete("iid");
            urlParser.searchParams.delete("did");
        }
        navigate(urlParser.pathname + urlParser.search, { replace: true });
    }

    const onCustomerAdded = (customer) => {
        if (formCreateUpdateRef.current) {
            const formData = formCreateUpdateRef.current.getFormData();
            let dataToUpdate = {
                ...formData,
                signatories: formData.signatories,
                title: formData.title,
                customer: customer
            };
            if (!signatoryNameUpdatedRef.current) {
                dataToUpdate.signatories[0].name = customer.name;
            }
            if (!signatoryEmailUpdatedRef.current) {
                dataToUpdate.signatories[0].email = customer.email;
            }
            if (!titleUpdatedRef.current) {
                dataToUpdate.title = `${customer.org_name || customer.name} - ${company.name} Contract`;
            }
            setInitialFields(prevFields => {
                return {...prevFields, ...dataToUpdate};
            })
        }
        setSelectedCustomer(customer);
    }

    const onContractAttachmentUpload = async (data, errorHandler) => {
        if (!data || !data.file_id) {
            return
        }
        const res = await serverFetch(getApiUrl(`/files/${data.file_id}`))
        if (res) {
            setNewContractAttachments((prev) => {
                return [...prev, res];
            })
        }
        if (!contractAttachmentFileIds) {
            setContractAttachmentFileIds([data.file_id])
        } else {
            setContractAttachmentFileIds((prev) => {
                return [...prev, data.file_id];
            })
        }
        setShowUploadContractAttachmentModal(false);
    }

    const onProductPricingSelectionChange = (phase, selectedProductPricings) => {
        if (selectedProductPricings && selectedProductPricings.length > 0) {
            setHasProductPricingError(false);
        }
        if (selectedProductPricings && selectedProductPricings.length === 1 && selectedProductPricings[0].product_metric_pricings.length === 1) {
            const itemPricing = selectedProductPricings[0].product_metric_pricings[0].item_pricing;
            if (itemPricing.type === "SCHEDULED") {
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.term = {
                        count: itemPricing.term_count,
                        frequency: itemPricing.frequency
                    }
                    return newFields;
                })
            }
        }
        setHasNonOneTimeItems(_.some(selectedProductPricings, (spp) => {
            const flag = _.some(spp.product_metric_pricings, (pmp) => {
                return pmp.item.type !== "ONETIME_ITEM";
            })
            return flag
        }))
    }

    const onProductPricingCreated = (productPricing) => {
        if (pricingsRef.current) {
            pricingsRef.current.onProductPricingCreated(productPricing)
        }
    }

    const onModalClose = () => {
        setShowAddProductPricingModal(false);
        setShowAddOrEditCustomerModal(false);
        props.setShowSearchDealsModal(false);
    }

    const templateOptions = useMemo(() => _.map(templates, (template) => {
        return {
            value: template.id,
            label: template.name,
            content: template.content,
        }
    }), [templates]);

    useEffect(() => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            const templateValue = templateOptions.find(template => template.id === props.contractDetails?.template_id)?.value ?? null;
            if (templateValue) {
                newFields.template_id = templateValue
            }
            return newFields;
        });

        const getContractContentTemplateData = () => {
            if (!props.contractDetails?.content_template) return;
            setCustomVariables(findCustomVariables(props.contractDetails.content_template));
            setNumberOfCounterSignatories(findNumberOfCounterSignatories(props.contractDetails.content_template));
            setNumberOfSignatories(findNumberOfSignatories(props.contractDetails.content_template));
        };
        getContractContentTemplateData();

        const setPrevSubscription = () => {
            if (props.contractDetails) {
                setPreviousSubscription(props.contractDetails?.previous_subscription);
            }
        };
        setPrevSubscription();
    }, [templateOptions, props.contractDetails]);

    const paymentMechanismOptions = [
        { value: true, label: "Collect payment information and charge automatically.",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: false, label: "Invoice the customer" },
    ]

    const startDateTypeOptions = [
        { value: "ON_COMPLETION", label: 'When contract is completed' },
        { value: "SPECIFIC_DATE", label: 'On a specific date' },
        { value: "MANUAL_ACTION", label: 'On manual confirmation'}
    ]

    const changeDateOptions = [
        { value: "RENEWAL", label: `At renewal - ${ previousSubscription ? moment(previousSubscription.renewal_date).format("MMM D, YYYY h:mm:ssa"): ""}` },
        { value: "IMMEDIATE", label: "When contract is completed"},
        isMapleUser && { value: "CUSTOM", label: 'Custom Date' },
    ]

    const changeProrateOptions = [
        { value: "NONE", label: `Do not prorate any charges` },
        { value: "NEXT", label: "Charge proration in next invoice" },
        { value: "IMMEDIATE", label: "Charge proration immediately" }
    ]

    const parentBilledOptions = [
        selectedCustomer && selectedCustomer.parent_customer && { value: true, label: `Parent - ${getCustomerNameOrEmail(selectedCustomer.parent_customer)}` },
        selectedCustomer && { value: false, label: `Customer - ${getCustomerNameOrEmail(selectedCustomer)}` },
    ]

    const prefetchedExistingCustomer =  prefetchDealDetails && prefetchDealDetails.customer && !_.isNil(prefetchDealDetails.customer.maple_customer_id);

    const getCounterSignatoryInputRows = (num) => {
        return (
            <Row>
                <div className="body2">Counter Signatory</div>
                <div className="body1 mb-3">{`Please choose up to ${num} company user(s) to counter sign if necessary.`}</div>
                {_.map(_.range(num), (_, i) => (
                    <BaseForm.Input key={i} colSpan="4" name={`counter_signatories.${i}`} type="select" options={counterSignatoryOptions} showSearch={counterSignatoryOptions.length > 5}/>
                ))}
            </Row>
        )
    }

    const getSignatoryInputRows = (num) => {
        return (
            <Row>
                {_.map(_.range(num), (_, i) => (
                    <React.Fragment key={i}>
                        <BaseForm.Input colSpan="4" name={`signatories.${i}.name`} label={num > 1 ? `Signatory ${i+1} Name` : "Name"}
                                        type="text" required={num === 1}/>
                        <BaseForm.Input colSpan="4" name={`signatories.${i}.title`} label={"Title"} type="text"/>
                        <BaseForm.Input colSpan="4" name={`signatories.${i}.email`} label={t('common.email')}
                                        type="text" transformations={["lowercase", "trim"]} required={num === 1}
                                        validations={{validEmail: true}}/>
                    </React.Fragment>
                ))}
            </Row>
        )
    }

    const extraActionBarActions = useMemo(() => {
        const actions = [];

        const hasPrefetchDeal = !_.isNil(dealID) && !_.isNil(integrationID);
        if (!hasPrefetchDeal && canImportDealsFromIntegration && isCreatingContract) {
            actions.push({
                id: "import_from_crm",
                icon: <Plus width={20} height={20} className="inline" />,
                label: "Import from CRM",
                variant: "text-outline",
                onClick: () => props.setShowSearchDealsModal(true),
            });
        }

        return actions; 
    }, [dealID, integrationID, canImportDealsFromIntegration, props, isCreatingContract]);

    const CustomErrorMessages = () => {
        const { customErrorFields } = useContext(BaseFormContext);
        const hasError =
            !_.isNil(customErrorFields) &&
            !_.isUndefined(customErrorFields) &&
            !_.isNil(customErrorFields.error_message) &&
            !_.isEmpty(customErrorFields.error_message);

        return (hasError ? 
            <div className='form-error-message text-right mb-3'>{customErrorFields.error_message}</div>
            : null
        );
    };

    useEffect(() => {
        const checkValidationErrors = () => {
            const errorKeys = Object.keys(formErrors || {});
            if (errorKeys.includes("customer", "title")) {
                setHasDetailsError(true);
                setCollapsedStates((prevState) => {
                    const udpatedState = {...prevState};
                    udpatedState.details = false;
                    return udpatedState;
                });
            } else {
                setHasDetailsError(false);
            }
            if (errorKeys.includes("signatories") || signatoryError) {
                setHasSignatoriesError(true);
                setCollapsedStates((prevState) => {
                    const udpatedState = {...prevState};
                    udpatedState.signatories = false;
                    return udpatedState;
                });
            } else {
                setHasSignatoriesError(false);
            }
            if (errorKeys.includes("phases", "term")) {
                setHasProductPricingError(true);
                setCollapsedStates((prevState) => {
                    const udpatedState = {...prevState};
                    udpatedState.productPricing = false;
                    return udpatedState;
                });
            } else {
                setHasProductPricingError(false);
            }
        };
        checkValidationErrors();
    }, [formErrors, signatoryError]);

    const hasUploadedDocuments = () => {
        return !_.isEmpty(newContractAttachments) || !_.isEmpty(props.contractDetails?.contract_attachments)
    }

    const downloadFile = (fileId) => {
        serverFetch(getApiUrl(`/files/download/${fileId}`), {}).then((res) => {
            if (res) {
                fetch(res.url, {headers: res.headers}).then((res2) => {
                    res2.blob().then(value => {
                        downloadBlob(value, res.filename)
                    })
                })
            }
        })
    }

    const deleteContractAttachment = (uploadedFile) => {
        if (!props.contractDetails) {
            return
        }
        serverDelete(getApiUrl(`/proposals/${props.contractDetails.id}/attachments/${uploadedFile.id}`)).then(async (res) => {
            if (res) {
                Notification.Success("Successfully deleted file");
                await props.fetchData(true);
            }
        })
    }

    const deleteUnsavedContractAttachment = (file) => {
        const newArray = newContractAttachments.filter((item) => {
            return item.id !== file.id
        })
        setNewContractAttachments(newArray);
    }

    const renderContractCreation = () => {
        const getSignatorySubtitle = () => {
            const contextText = isCreatingContract ? "The selected template" : "This contract";
            const signatoriesText = `${numberOfSignatories} signature${numberOfSignatories > 1 ? "s": ""}`;
            const counterSignatoriesText = numberOfCounterSignatories > 0 ? ` and ${numberOfCounterSignatories} counter signature${numberOfCounterSignatories > 1 ? "s": ""}.` : ".";
            const orderingSignatoriesText = " The order of signatories here will determine where they are displayed on the template."
            return `${contextText} supports ${signatoriesText}${counterSignatoriesText}${numberOfSignatories > 1 ? orderingSignatoriesText : ""}`;
        };
        return (
            <BaseForm ref={formCreateUpdateRef} initialFormFields={initialFields} onSubmit={!isCreatingContract ? updateContract : createContract} onFieldChange={onFieldChange} scrollToErrors validateBeforeSubmit setFormErrors={setFormErrors}>
                <CustomErrorMessages />
                {
                    error &&
                        <div className="form-error-message text-right mb-3">{ error }</div>
                }
                {props.showCustomerAddressError && (
                    <Alert variant={'warning'} showActionButton={true} buttonVariant={'text-primary'} actionButtonLabel={'Fix Now'} onActionButtonClick={() => setShowEditCustomerModal(true)}>
                        <div>
                            <span className={'font-normal text-sm'}>
                                {customAddressErrorMessage || "Customer address is required for tax calculation. This can be fixed now or after the contract is executed."}
                            </span>
                        </div>
                    </Alert>
                )}
                {
                    isCreatingContract && previousSubscription &&
                        <div className="mb-3">
                            <div>
                                <p className="text-sm font-semibold">Changing existing subscription</p>
                                <div className="body1">Current Plan Details</div>
                                {
                                    previousSubscription.minimum_spend.value_in_cents > 0 &&
                                    <div className="body1">Minimum Spend: { currencyFormatFromPrice(previousSubscription.minimum_spend) }</div>
                                }
                                <BundlePricingTable
                                    bundlePricing={previousSubscription.bundle_pricing}
                                    configItems={previousSubscription.config_items}
                                    discounts={previousSubscription.discounts}
                                    showUsage={false}
                                    showPricingLinks={false}
                                    subscription={previousSubscription}
                                    hideDiscountAdditionalDetails={true}
                                    displayDiscountExpiration={true}
                                />
                            </div>
                            <p className="-mt-2">
                                Please enter the contract details and the new plan details. Once the contract is complete, the
                                existing subscription will be changed to the new plan.
                            </p>
                        </div>
                }
                {
                    props.contractDetails?.previous_subscription &&
                        <Alert variant="warning mb-3">
                            <div className="body2">Changing existing subscription</div>
                            <div className="body1">This is a renewal contract for an existing <Link href={getCompanySpecificUrl(`/subscription/${props.contractDetails.previous_subscription.id}`)}>subscription</Link>.</div>
                        </Alert>
                }
                <ContentBox
                    isCollapsible={true}
                    hasError={hasDetailsError}
                    collapsed={collapsedStates.details}
                    setCollapsed={(isCollapsed) => setCollapsedStates({...collapsedStates, details: isCollapsed})}
                >
                    <ContentBox.Title>
                        Contract Details
                    </ContentBox.Title>
                    <ContentBox.Body>
                        <Row>
                            <CustomerInput
                                colSpan="6" allowLeads required
                                defaultCustomer={prefetchedExistingCustomer ? prefetchDealDetails.customer: null}
                                allowCreation
                            />
                            <BaseForm.Input colSpan="6" name="owner" label={"Owner"} type="select" options={teamMemberOptions}/>
                            <Col md="6">
                                <BaseForm.Input colSpan="12" name="title" label="Title" type="text" required />
                                {
                                    !showPOField &&
                                    <div className="mb-2">
                                        <Link onClick={() => setShowPOField(true)}>Add PO Number</Link>
                                    </div>
                                }
                                {
                                    showPOField &&
                                    <Row>
                                        <Col xs="11" sm="5">
                                            <BaseForm.Input name="po_number" label="PO Number" type="text" />
                                        </Col>
                                        <Col xs="1" sm="1" className="p-0 mt-[25px]">
                                            <Button variant="text" className="px-0" onClick={() => setShowPOField(false)}>
                                                <X width={20} height={20} />
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                            <Col md="6">
                                <BaseForm.Input colSpan="12" name="template_id" label={"Template"} type="select"
                                                options={templateOptions.map(template => ({ label: template.label, value: template.value }))} showSearch={false} disabled={isContractDraft(props.contractDetails)} />
                            </Col>

                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="6" name="expiration_date" label={"Expiration Date"} type="date" includeTime={true} minDate={moment()} />
                            <BaseForm.Input colSpan="6" name="auto_charges" label={t('subscriptions.payment_mechanism')} type="select"
                                            options={paymentMechanismOptions} showSearch={false}/>
                            {
                                !autoCharges ?
                                    <>
                                        <Col md="6"></Col>
                                        <BaseForm.Input type="select" colSpan="3" name="invoice_payment_terms" label="Invoice Payment Terms" options={getInvoicePaymentTermOptions()} showSearch={false} />
                                        {
                                            invoicePaymentTerms === "custom" &&
                                            <BaseForm.Input type="number" colSpan="3" name="invoice_due_date_from_creation" label="Due Date (in days)" step="1" min="0" />
                                        }
                                    </>
                                    : <>
                                        <Col md="6"></Col>
                                        <BaseForm.Input type="switch" colSpan="6" name="require_payment_method" label="Require payment method before execution" />
                                    </>
                            }
                        </Row>
                        <br/>
                        {
                            !_.isEmpty(customVariables) &&
                            <div className="mt-2">
                                <hr className="mb-2"/>
                                <div className="body2">Custom Variables</div>
                                <div className="body1">Fill out the values for the custom variables to be used in the contract.</div>
                                <Row className="mt-3">
                                    {
                                        _.map(customVariables, (cv, i) => renderCustomVariableInput(cv, i, availableCustomVariables))
                                    }
                                </Row>
                            </div>
                        }
                    </ContentBox.Body>
                </ContentBox>
                <ContentBox
                    isCollapsible={true}
                    hasError={hasSignatoriesError}
                    collapsed={collapsedStates.signatories}
                    setCollapsed={(isCollapsed) => setCollapsedStates({...collapsedStates, signatories: isCollapsed})}
                >
                    <ContentBox.Title subtitle={getSignatorySubtitle()}>
                        Signatories
                    </ContentBox.Title>
                    <ContentBox.Body>
                        <div className="flex flex-col gap-3">
                            {numberOfSignatories > 0 && getSignatoryInputRows(numberOfSignatories)}
                            {signatoryError && <div className="form-error-message">{signatoryError}</div>}
                            {numberOfCounterSignatories > 0 && getCounterSignatoryInputRows(numberOfCounterSignatories)}
                        </div>
                    </ContentBox.Body>
                </ContentBox>
                <ContentBox>
                    <ContentBox.Title right={<Button variant={"text-primary"} onClick={() => setShowUploadContractAttachmentModal(true)}>
                    <span>
                        <i className={classnames('fa', "fa-edit")}/>
                        &nbsp;
                    </span>
                        Upload
                    </Button>} subtitle={!hasUploadedDocuments() ? "No uploaded documents" : "Attached documents are visible to anyone with the contract signature link"}>
                        Documents
                    </ContentBox.Title>
                    <ContentBox.Body>
                        {hasUploadedDocuments() &&
                            <MapleTable>
                                <MapleTable.Content removeMargin removePadding>
                                    <thead>
                                        <tr>
                                            <MapleTable.TH>Document</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {props.contractDetails &&
                                        _.map(props.contractDetails.contract_attachments, (ufile, i) =>
                                            <tr key={i}>
                                                <td>{ ufile.original_filename }</td>
                                                <td className="text-end">
                                                    <div className="flex flex-row gap-2 justify-end items-center">
                                                        <ArrowDownTrayIcon className="cursor-pointer h-4 w-4" onClick={() => downloadFile(ufile.file_id)}/>
                                                        <DeleteButton size="sm" onDelete={() => deleteContractAttachment(ufile)}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {newContractAttachments &&
                                        _.map(newContractAttachments, (ufile, i) =>
                                            <tr key={i}>
                                                <td>{ ufile.original_filename }</td>
                                                <td className="text-end">
                                                    <div className="flex flex-row gap-2 justify-end items-center">
                                                        <ArrowDownTrayIcon className="cursor-pointer h-4 w-4" onClick={() => downloadFile(ufile.id)}/>
                                                        <DeleteButton size="sm" onDelete={() => deleteUnsavedContractAttachment(ufile)}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        }
                    </ContentBox.Body>
                </ContentBox>
                <ContentBox
                    hasError={hasProductPricingError}
                    isCollapsible={true}
                    collapsed={collapsedStates.productPricing}
                    setCollapsed={(isCollapsed) => setCollapsedStates({...collapsedStates, productPricing: isCollapsed})}
                >
                    {!previousSubscription && (
                        <ContentBox.Title>
                            Plan Details
                        </ContentBox.Title>
                    )}
                    <ContentBox.Body>
                        <Row>
                            <Col md="8">
                                {previousSubscription ? (
                                    <div className="mb-3">
                                        <Row className="gx-2">
                                            <Col md="6">
                                                <BaseForm.Input
                                                    name="change_timing" label={"Change Date"} type="select"
                                                    options={changeDateOptions} showSearch={false}/>
                                            </Col>
                                            {
                                                changeDateType === "CUSTOM" &&
                                                    <Col md="6">
                                                        <BaseForm.Input
                                                            type="date" name="change_custom_date"
                                                            label="Change Date" minDate={moment()} includeTime={true}
                                                            required
                                                        />
                                                    </Col>
                                            }
                                        </Row>
                                        {
                                            _.includes(["IMMEDIATE", "CUSTOM"], changeDateType) &&
                                                <Row className="gx-2">
                                                    <Col md="6" className="flex justify-stretch items-end">
                                                        <BaseForm.Input
                                                            outerInputClassName="flex-1"
                                                            type="switch" name="change_reset_billing_anchor" label="Reset Billing Anchor?"
                                                        />
                                                    </Col>
                                                    <Col md="6">
                                                        <BaseForm.Input
                                                            name="change_proration_type" label={"Change Proration"} type="select"
                                                            options={changeProrateOptions} showSearch={false}/>
                                                    </Col>
                                                </Row>
                                        }
                                    </div>
                                ) : (
                                    <Row className="gx-2">
                                        <Col md="6">
                                            <BaseForm.Input name="start_date_type" label={t('subscriptions.start_date')} type="select"
                                                            options={startDateTypeOptions} showSearch={false}/>
                                        </Col>
                                        <Col md="6">
                                            {
                                                startDateType === "SPECIFIC_DATE" &&
                                                <BaseForm.Input
                                                    name="start_date" label={'Specific Date'} type="date" required includeTime />
                                            }
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                            <Col md="8">
                                <Row className="gx-2">
                                    {hasNonOneTimeItems && (
                                        <Col md="6">
                                            <BaseForm.InputGroup label="Term">
                                                <BaseForm.Number name="term.count" label="Term" hideLabel  min="1" validations={{ required: true, min: 1 }}/>
                                                <BaseForm.Divider />
                                                <BaseForm.SingleSelect
                                                    name="term.frequency" options={getPlanLengthOptions()} showSearch={false} fullWidth
                                                />
                                            </BaseForm.InputGroup>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                            {hasNonOneTimeItems && (
                                <Col md="4">
                                    <Row>
                                        <Col md={{ offset: 5, span: 7 }}>
                                            {showTrialDaysInput || props.contractDetails?.trial ? (
                                                <BaseForm.Input type="number" name="num_trial_days" label="Free Trial Days" step="1" min="0" validations={{ min: 0 }} defaultValue={0} placeholder="0"/>
                                            ) : (
                                                <div className="flex justify-end mt-[25px]">
                                                    <Button variant="text-outline" onClick={() => setShowTrialDaysInput(true)}>Add Free Trial Days</Button>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        <ProductPricingSelection
                            ref={pricingsRef} productPricings={productPricings} allowDiscounts={true}
                            initialFields={initialFields} setInitialFields={setInitialFields}
                            initialSelectionData={props.initialContractData}
                            allowMinimumSpend menuPlacement={"top"} allowPhases
                            onSelectionChange={onProductPricingSelectionChange} allowCreation
                            onCreateProductPricing={() => setShowAddProductPricingModal(true)}
                            updated
                        />
                        {
                            askForPhoneNumber &&
                                <Row>
                                    <div className="body2">Additional Information</div>
                                    <p className="my-2 text-sm text-gray-500">A phone number for the customer is required to process any recurring payments with Razorpay. Please enter a phone number for the customer before proceeding.</p>
                                    <BaseForm.Input colSpan="5" type="text" name="customer_phone" label="Phone Number" required />
                                </Row>
                        }
                    </ContentBox.Body>
                </ContentBox>
            </BaseForm>
        );
    }

    const renderEmpty = () => {
        return (
            <ContentBox>
                <ContentBox.Body>
                    <div className="d-flex flex-column">
                        <div className="align-items-center justify-content-center text-center" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col md={{ span: 8, offset: 2 }}>
                                    <div className="body2">Hi there!</div>
                                    <br/>
                                    <p>Before we create a contract, we will need to create at least one contract template.
                                    You can get <Link href={getCompanySpecificUrl("/settings/contracts")}>started here</Link></p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </ContentBox.Body>
            </ContentBox>
        )
    };

    return (
        <ContentContainer>
            <Section 
                variant="page"
                title={props.contractDetails?.title ?? "New Contract"}
                right={props.renderActionBar(extraActionBarActions)}
                titleRight={<div className="text-nowrap">{props.contractDetails && renderContractStatusLabel(props.contractDetails)}</div>}
                className="mb-[20px]"
            />
            <Loader loading={loading}>
            {
                () => {
                    if (_.isEmpty(templates)) {
                        return renderEmpty();
                    } else {
                        return renderContractCreation();
                    }
                }
            }
            </Loader>
            <AddOrEditProductPricingModal2
                show={showAddProductPricingModal}
                onProductPricingCreated={onProductPricingCreated}
                onClose={onModalClose}
                updated
            />
            <AddOrEditCustomerModal
                show={showAddOrEditCustomerModal}
                onAdded={onCustomerAdded}
                onClose={(created) => {
                    setShowAddOrEditCustomerModal(false);
                }}
            />
            <AddOrEditCustomerModal
                show={showEditCustomerModal}
                itemToEdit={!addressEditCustomer?.id ? null : addressEditCustomer}
                onAdded={onCustomerAdded}
                onUpdated={onCustomerAdded}
                initialFormFields={!addressEditCustomer?.id ? addressEditCustomer : null}
                onClose={(updated) => {
                    setShowEditCustomerModal(false);
                }}
                fixTaxAddress
            />
            <SearchDealsModal
                show={props.showSearchDealsModal}
                onDealSelected={onDealSelected}
                onClose={onModalClose}
            />
            <UploadContractAttachmentModal
                show={showUploadContractAttachmentModal}
                onClose={() => setShowUploadContractAttachmentModal(false)}
                onSubmit={onContractAttachmentUpload}
            />
        </ContentContainer>
    )
}

export default forwardRef(ContractCreateUpdate);
