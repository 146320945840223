import '../App.scss';
import React, {useState, useEffect, useContext} from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Button from './common/buttons/Button';
import { getValueFromEvent } from '../helpers/input';
import SingleSelectDropdown from './SingleSelectDropdown';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import classnames from 'classnames';
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import DropdownMenu from "./DropdownMenu";
import MultiSelectDropdown from "./MultiSelectDropdown";
import {serverPost} from "../helpers/server";
import {BaseContext} from "../helpers/common";
const _ = require("lodash");

function Rule(props) {
    const { getApiUrl } = useContext(BaseContext)
    const [value, setValue] = useState(null);
    const [propertyFields, setPropertyFields] = useState([]);

    const defaultValue = null;
    const defaultComparator = {
        comparator: {
            type: "EQUAL",
            left_item: {
                item_type: "PROPERTY",
                property: ""
            },
            right_item: {
                item_type: "VALUE",
                value: ""
            }
        }
    }

    useEffect(() => {
        const newValue = !_.isEmpty(props.value) ? props.value : defaultValue;
        if (!_.isEqual(value, newValue)) {
            setValue(newValue);
        }
    }, [props.value])

    useEffect(() => {
        setPropertyFields(props.propertyFields);
    }, [props.propertyFields])

    useEffect(() => {
        props.onChange(value);
    }, [value]);

    const comparatorOptions = [
        { id: "OR", label: "OR" },
        { id: "AND", label: "AND" },
    ]

    const isLeafComparatorType = (type) => {
        const leafComparators = ["AND", "OR", "XOR", "NOR"];
        const isLeafComparator = !_.includes(leafComparators, type);
        return isLeafComparator;
    }

    const getTypeForKey = (key) => {
        const pField = _.find(propertyFields, (pf) => pf.Key === key);
        if (pField) {
            return pField.Type;
        } else {
            return null;
        }
    }

    const getValueForKey = (key) => {
        const parts = key.split(".");
        let v = value.comparator;
        for (let i = 1; i < parts.length; i++) {
            if (parts[i] === "left") {
                v = v.left_item;
            } else if (parts[i] === "right") {
                v = v.right_item;
            } else if (parts[i] === "c") {
                v = v.comparator;
            }

            if (i === parts.length - 1) {
                if (parts[i] === "property") {
                    return v.property;
                } else if (parts[i] === "value") {
                    return v.value;
                } else if (parts[i] === "type") {
                    return v.type;
                }
            }
        }
        return null;
    }

    const updateValueForKey = (key, val) => {
        const parts = key.split(".");
        setValue(prevValue => {
            const nextValue = {...prevValue};
            let v = nextValue.comparator;
            for (let i = 1; i < parts.length; i++) {
                if (parts[i] === "left") {
                    v = v.left_item;
                } else if (parts[i] === "right") {
                    v = v.right_item;
                } else if (parts[i] === "c") {
                    v = v.comparator;
                }

                if (i === parts.length - 1) {
                    if (parts[i] === "property") {
                        v.property = val;
                    } else if (parts[i] === "value") {
                        v.value = val;
                    } else if (parts[i] === "type") {
                        v.type = val;
                    }
                }
            }
            return nextValue;
        })
    }

    const onComparatorSelected = (key, value) => {
        const parts = key.split(".");
        setValue(prevValue => {
            const nextValue = {...prevValue};
            let v = nextValue;
            for (let i = 0; i < parts.length - 1; i++) {
                if (parts[i] === "main") {
                    v = v.comparator;
                } else if (parts[i] === "left") {
                    v = v.left_item;
                } else if (parts[i] === "right") {
                    v = v.right_item;
                } else if (parts[i] === "c") {
                    v = v.comparator;
                }
            }

            if (parts[parts.length - 1] === "c" || parts[parts.length - 1] === "main") {
                const existingComparator = { ...v.comparator };
                v.comparator = {
                    type: value,
                    left_item: {
                        item_type: "COMPARATOR",
                        comparator: existingComparator,
                    },
                    right_item: {
                        item_type: "COMPARATOR",
                        comparator: {
                            type: "EQUAL",
                            left_item: {
                                item_type: "PROPERTY",
                                property: ""
                            },
                            right_item: {
                                item_type: "VALUE",
                                value: ""
                            }
                        }
                    }
                };
            }
            return nextValue;
        })
    }

    const onComparatorDelete = (key) => {
        const parts = key.split(".");
        setValue((prevValue) => {
            let nextValue = {...prevValue};
            let v = nextValue;
            for (let i = 0; i < parts.length - 3; i++) {
                if (parts[i] === "main") {
                    v = v.comparator;
                } else if (parts[i] === "left") {
                    v = v.left_item;
                } else if (parts[i] === "right") {
                    v = v.right_item;
                } else if (parts[i] === "c") {
                    v = v.comparator;
                }
            }

            if (parts[parts.length - 1] === "main") {
                // Deleting the only item so go back to a default empty one
                nextValue = defaultValue;
            } else if (parts[parts.length - 1] === "c") {
                if (parts[parts.length - 2] === "left") {
                    v.comparator = v.comparator.right_item.comparator;
                } else if (parts[parts.length - 2] === "right") {
                    v.comparator = v.comparator.left_item.comparator;
                }
            }
            return nextValue;
        })
    }

    const getOperationsForType = (type) => {
        if (type === "Text") {
            return [
                { value: "EQUAL", label: "eq" },
                { value: "HAS_SUBSTRING", label: "like" },
                { value: "NOT_HAS_SUBSTRING", label: "not like" },
            ]
        } else if (type === "Number") {
            return [
                { value: "EQUAL", label: "=" },
                { value: "GREATER_THAN", label: ">" },
                { value: "GREATER_THAN_EQUAL", label: ">=" },
                { value: "LESS_THAN", label: "<" },
                { value: "LESS_THAN_EQUAL", label: "<=" },
            ]
        } else if (type === "Boolean") {
            return [
                { value: "EQUAL", label: "eq" },
            ]
        } else if (type === "ProductArray" || type === "ProductPricingArray") {
            return [
                { value: "INTERSECTS", label: "includes any of" },
                { value: "NOT_INTERSECTS", label: "excludes all of" },
            ]
        }
        return [
            { value: "EQUAL", label: "=" },
            { value: "NOT_EQUAL", label: "!=" },
            { value: "GREATER_THAN", label: ">" },
            { value: "GREATER_THAN_EQUAL", label: ">=" },
            { value: "LESS_THAN", label: "<" },
            { value: "LESS_THAN_EQUAL", label: "<=" },
            { value: "HAS_SUBSTRING", label: "like" },
            { value: "NOT_HAS_SUBSTRING", label: "not like" },
        ]
    }

    const renderProperty = (p, key) => {
        if (props.propertyValues) {
            return (
                <BaseForm.InputGroup className={classnames("form-select")}>
                    <SingleSelectDropdown
                        items={props.propertyValues} selectedId={getValueForKey(key)}
                        showAll={false} idField="value" showSearch={false} alignDropdown={"left"}
                        onSelect={(value) => {
                            updateValueForKey(key, value)
                        }} />
                </BaseForm.InputGroup>
            )
        } else {
            return (
                <BaseForm.InputGroup>
                    <Form.Control
                        value={getValueForKey(key)}
                        onChange={(event) => {
                            updateValueForKey(key, getValueFromEvent(event));
                        }}>
                        { props.children }
                    </Form.Control>
                </BaseForm.InputGroup>
            )
        }
    }

    const loadProducts = async (query) => {
        const productsPromise = serverPost(getApiUrl('/products/find'), {
            query: {
                search: query || null,
            },
            sort_key: 'createdAtDesc',
            pagination: { limit: 20 },
        });
        const plansResult = await productsPromise;
        return (plansResult.results || []).map(p => {
            return {
                id: p.id,
                label: p.name
            }
        });
    }

    const loadProductPricings = async (query) => {
        const productsPromise = serverPost(getApiUrl('/product_pricings/find'), {
            query: {
                search: query || null,
            },
            sort_key: 'createdAtDesc',
            pagination: { limit: 20 },
        });
        const plansResult = await productsPromise;
        return (plansResult.results || []).map(p => {
            return {
                id: p.id,
                label: p.name
            }
        });
    }

    const renderValue = (p, key, parentComparatorType=null) => {
        if (parentComparatorType === "Boolean") {
            return (
                <BaseForm.InputGroup className={classnames("form-select")}>
                    <SingleSelectDropdown
                        items={booleanOptions} selectedId={getValueForKey(key)}
                        showAll={false} idField="value" showSearch={false} fullWidth
                        onSelect={(value) => {
                            updateValueForKey(key, value)
                        }}
                        disabled={props.disabled}
                    />
                </BaseForm.InputGroup>
            )
        }

        if (parentComparatorType === "ProductArray") {
            return (
                <BaseForm.InputGroup className={classnames("form-select")}>
                    <MultiSelectDropdown
                        fullWidth
                        type={'product'}
                        drop={'up'}
                        popover
                        loadOptions={loadProducts}
                        onItemsChange={(items) => {
                            updateValueForKey(key, items.join("|"))
                        }}
                    />
                </BaseForm.InputGroup>
            );
        }

        if (parentComparatorType === "ProductPricingArray") {
            return (
                <BaseForm.InputGroup className={classnames("form-select")}>
                    <MultiSelectDropdown
                        fullWidth
                        type={'productPricing'}
                        drop={'up'}
                        popover
                        loadOptions={loadProductPricings}
                        onItemsChange={(items) => {
                            updateValueForKey(key, items.join("|"))
                        }}
                    />
                </BaseForm.InputGroup>
            );
        }

        return (
            <BaseForm.InputGroup className="rule-value">
                <Form.Control
                    value={getValueForKey(key)}
                    onChange={(event) => {
                        updateValueForKey(key, getValueFromEvent(event));
                    }}>
                    { props.children }
                </Form.Control>
            </BaseForm.InputGroup>
        )
    }

    const renderLeafNode = (n, key, parentComparatorType=null) => {
        if (n.item_type === "PROPERTY") {
            return renderProperty(n, key + ".property");
        } else if (n.item_type === "VALUE") {
            return renderValue(n, key + ".value", parentComparatorType);
        } else {
            return (
                <div>Not expected to be here.</div>
            )
        }
    }

    const booleanOptions = [
        { value: "true", label: "True" },
        { value: "false", label: "False" }
    ]

    const renderLeafComparator = (v, key, showActions=true, isColored=false) => {
        let propertyKey = null;
        if (v.left_item.item_type === "PROPERTY") {
            propertyKey = v.left_item.property;
        } else if (v.right_item.item_type === "PROPERTY") {
            propertyKey = v.right_item.property;
        }
        let type = getTypeForKey(propertyKey);
        return (
            <Row className="rule-leaf-comparator">
                <Col md="4">
                    { renderLeafNode(v.left_item, key + ".left") }
                </Col>
                <Col md="3">
                    <BaseForm.InputGroup className={classnames("form-select")}>
                        <SingleSelectDropdown
                            items={getOperationsForType(type)} selectedId={getValueForKey(key + ".type")}
                            showAll={false} idField="value" showSearch={false} fullWidth
                            onSelect={(value) => {
                                updateValueForKey(key + ".type", value)
                            }}
                            menuPlacement={props.menuPlacement}
                            disabled={props.disabled}
                        />
                    </BaseForm.InputGroup>
                </Col>
                <Col md="4">
                    { renderLeafNode(v.right_item, key + ".right", type) }
                </Col>
                <Col md="1" className="d-flex flex-row justify-content-center">
                    <DeleteButton variant="text-danger" onDelete={() => onComparatorDelete(key)}></DeleteButton>
                </Col>
            </Row>
        )
    }

    const addOptions = [
        { id: "OR", label: "OR" },
        { id: "AND", label: "AND" },
    ]
    const renderComparator = (c, key, showActions=true, isColored=false) => {
        const isLeafComparator = isLeafComparatorType(c.type);
        if (isLeafComparator) {
            return (
                <>
                    { renderLeafComparator(c, key, showActions, isColored) }
                    {
                        showActions &&
                        <>
                            <DropdownMenu
                                className="p-2" items={addOptions} alignDropdown={"left"}
                                onClick={(value) => onComparatorSelected(key, value)}>
                                <div className="flex flex-row gap-1 items-center">
                                    <span>Add</span>
                                </div>
                            </DropdownMenu>
                        </>
                    }
                </>
            )
        } else {
            if (c.type === "AND") {
                return renderAND(c, key, showActions, isColored);
            } else if (c.type === "OR") {
                return renderOR(c, key, showActions, isColored);
            }
        }
    }

    const renderAND = (v, key, showActions=true, isColored=false) => {
        return (
            <div className="rule-and">
                <div className="rule-left">{ renderComparator(v.left_item.comparator, key + ".left.c", false, isColored) }</div>
                <div className="body2">AND</div>
                <div className="rule-right">{ renderComparator(v.right_item.comparator, key + ".right.c", false, isColored) }</div>
                <div>
                {
                    showActions &&
                        <DropdownMenu
                            className="p-2" items={comparatorOptions} alignDropdown={"left"}
                            onClick={(value) => onComparatorSelected(key, value)}>
                            <div className="flex flex-row gap-1 items-center">
                                <span>Add</span>
                            </div>
                        </DropdownMenu>
                }
                </div>
            </div>
        )
    }

    const renderOR = (v, key, showActions=true, isColored=false) => {
        return (
            <div className="rule-or">
                <div className={classnames("rule-left", isColored ? "alt": "")}>{ renderComparator(v.left_item.comparator, key + ".left.c", true, !isColored) }</div>
                <div className="body2">OR</div>
                <div className={classnames("rule-right", isColored ? "alt": "")}>{ renderComparator(v.right_item.comparator, key + ".right.c", true, !isColored) }</div>
                {
                    showActions &&
                        <Row>
                            <Col md="1">
                                <div className="dropdown">
                                    <SingleSelectDropdown className="dropdown" toggleLabel="Add" items={comparatorOptions} onSelect={(value) => onComparatorSelected(key, value)} align="end" menuOnly/>
                                </div>
                            </Col>
                        </Row>
                }
            </div>
        )
    }

    const onAddFilter = () => {
        setValue({...defaultComparator});
    }

    return (
        <div className={classnames("rule", props.isColored ? "alt": "")}>
            {
                !_.isNil(value) ?
                    <>
                        { value.comparator && renderComparator(value.comparator, "main", true, !props.isColored) }
                    </>
                : <>
                    <Button onClick={onAddFilter}>Add filter</Button>
                </>
            }
        </div>
    );
}

export default Rule;
