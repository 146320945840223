import '../App.scss';
import '../js/forms.js';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseContext, UserContext } from '../helpers/common';
import BaseLeftNav from '../components/BaseLeftNav';

// icons
import BarChartSquare02 from '@untitled-ui/icons-react/build/esm/BarChartSquare02';
import Users01 from '@untitled-ui/icons-react/build/esm/Users01';
import CurrencyDollarCircle from '@untitled-ui/icons-react/build/esm/CurrencyDollarCircle';
import FileAttachment02 from '@untitled-ui/icons-react/build/esm/FileAttachment02';
import ReceiptCheck from '@untitled-ui/icons-react/build/esm/ReceiptCheck';
import BookOpen02 from '@untitled-ui/icons-react/build/esm/BookOpen02';
import File04 from '@untitled-ui/icons-react/build/esm/File04';
import Cube01 from '@untitled-ui/icons-react/build/esm/Cube01';
import PieChart03 from '@untitled-ui/icons-react/build/esm/PieChart03';
import PuzzlePiece01 from '@untitled-ui/icons-react/build/esm/PuzzlePiece01';
import Settings01 from '@untitled-ui/icons-react/build/esm/Settings01';
import CodeSquare01 from '@untitled-ui/icons-react/build/esm/CodeSquare01';
import ShieldDollar from '@untitled-ui/icons-react/build/esm/ShieldDollar';

const iconSize = {
	width: 20,
	height: 20,
};

function LeftNav(props) {
	const { userInfo, isMapleUser } = useContext(UserContext);
	const { getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
	const { t } = useTranslation('common');

	const items = [
		hasAccess('reports', userInfo, 'read') && {
			label: t('common.dashboard'),
			link: getCompanySpecificUrl('/'),
			icon: <BarChartSquare02 {...iconSize} />,
		},
		hasAccess('customers', userInfo, 'read') && {
			label: t('common.customers'),
			link: getCompanySpecificUrl('/customers'),
			icon: <Users01 {...iconSize} />,
		},
		hasAccess('subscriptions', userInfo, 'read') && {
			label: t('common.subscriptions'),
			link: getCompanySpecificUrl('/subscriptions'),
			icon: <CurrencyDollarCircle {...iconSize} />,
		},
		hasAccess('proposal', userInfo, 'read') && {
			label: t('common.contracts'),
			link: getCompanySpecificUrl('/contracts'),
			icon: <FileAttachment02 {...iconSize} />,
		},
		hasAccess('invoices', userInfo, 'read') && {
			label: t('common.invoices'),
			link: getCompanySpecificUrl('/invoices'),
			icon: <ReceiptCheck {...iconSize} />,
		},
		hasAccess('payments', userInfo, 'read') && {
			label: t('common.payments'),
			link: getCompanySpecificUrl('/payments'),
			icon: <BookOpen02 {...iconSize} />,
			hasSubMenu: true,
			items: [
				{ label: t('tabs.payments.overview'), link: getCompanySpecificUrl('/payments') },
				{ label: t('tabs.payments.recovery'), link: getCompanySpecificUrl('/campaigns') },
			],
		},
		isMapleUser && {
			label: "Commissions",
			link: getCompanySpecificUrl('/commissions'),
			icon: <ShieldDollar {...iconSize} />,
			hasSubMenu: true,
			items: [
				{ label: "Sales Team", link: getCompanySpecificUrl('/commissions/sales_overview') },
				{ label: "Commission Plans", link: getCompanySpecificUrl('/commissions/plans') },
				{ label: "Reports", link: getCompanySpecificUrl('/commissions/reports') },
				{ label: "Settings", link: getCompanySpecificUrl('/commissions/settings') },
			],
		},
		hasAccess('pricing', userInfo, 'read') && {
			label: t('tabs.pricing'),
			link: getCompanySpecificUrl('/billables'),
			hasSubMenu: true,
			icon: <File04 {...iconSize} />,
			items: [
				{ label: t('tabs.pricing.items'), link: getCompanySpecificUrl('/billable_items') },
				//{ label: t('tabs.pricing.pricings'), link: getCompanySpecificUrl("/billable_item_pricings") },
				{ label: t('tabs.pricing.metrics'), link: getCompanySpecificUrl('/billable_metrics') },
				{ label: t('tabs.pricing.objects'), link: getCompanySpecificUrl('/objects') },
			],
		},
		hasAccess('pricing', userInfo, 'read') && {
			label: t('common.products'),
			link: getCompanySpecificUrl('/products'),
			icon: <Cube01 {...iconSize} />,
			hasSubMenu: true,
			items: [
				{ label: t('tabs.products.overview'), link: getCompanySpecificUrl('/products') },
				{ label: t('tabs.products.bundles'), link: getCompanySpecificUrl('/bundles') },
				{ label: t('common.coupons'), link: getCompanySpecificUrl('/coupons') },
				{ label: t('tabs.products.payment_links'), link: getCompanySpecificUrl('/payment_links') },
				{ label: t('tabs.entitlements'), link: getCompanySpecificUrl('/entitlements') },
			],
		},
		hasAccess('reports', userInfo, 'read') && {
			label: t('common.reports'),
			link: getCompanySpecificUrl('/metrics'),
			icon: <PieChart03 {...iconSize} />,
			hasSubMenu: true,
			items: [
				{ label: t('tabs.reports.analytics'), link: getCompanySpecificUrl('/metrics') },
				{ label: t('tabs.reports.mrr_log'), link: getCompanySpecificUrl('/mrr_logs') },
				{ label: t('tabs.reports.credit_logs'), link: getCompanySpecificUrl('/credits_log') },
				{ label: t('tabs.reports.financials'), link: getCompanySpecificUrl('/reports') },
			],
		},
		false &&
			hasAccess('reports', userInfo, 'read') && {
				label: t('common.activity'),
				link: getCompanySpecificUrl('/activity'),
				icon: <PieChart03 {...iconSize} />,
			},
		hasAccess('integrations', userInfo, 'read') && {
			label: t('common.integrations'),
			link: getCompanySpecificUrl('/integrations'),
			icon: <PuzzlePiece01 {...iconSize} />,
		},
		hasAccess('settings', userInfo, 'read') && {
			label: t('common.settings'),
			link: getCompanySpecificUrl('/settings'),
			icon: <Settings01 {...iconSize} />,
			hasSubMenu: true,
			items: [
				{ label: t('tabs.settings.overview'), link: getCompanySpecificUrl('/settings') },
				{ label: t('tabs.settings.invoices'), link: getCompanySpecificUrl('/settings/invoices') },
				{ label: t('tabs.settings.contracts'), link: getCompanySpecificUrl('/settings/contracts') },
				{ label: t('tabs.settings.customer_portal'), link: getCompanySpecificUrl('/settings/customer_portal') },
				// { label: t('tabs.settings.taxes'), link: getCompanySpecificUrl('/settings/taxes') },
				{ label: t('tabs.settings.team'), link: getCompanySpecificUrl('/settings/team') },
				{ label: t('tabs.settings.imports'), link: getCompanySpecificUrl('/settings/imports') },
			],
		},
		hasAccess('developers', userInfo, 'read') && {
			label: t('tabs.developers.title'),
			link: getCompanySpecificUrl('/developers'),
			hasSubMenu: true,
			icon: <CodeSquare01 {...iconSize} />,
			items: [
				{ label: t('tabs.developers.api_tokens'), link: getCompanySpecificUrl('/developers/api_tokens') },
				{ label: t('tabs.developers.webhooks'), link: getCompanySpecificUrl('/developers/webhooks') },
				{ label: t('tabs.developers.events'), link: getCompanySpecificUrl('/developers/events') },
				{ label: t('tabs.developers.requests'), link: getCompanySpecificUrl('/developers/requests') },
			],
		},
	];

	return <BaseLeftNav items={items} {...props} />;
}

export default LeftNav;
