import '../../App.scss';
import '../../css/modals.scss';
import { Modal, Alert } from 'react-bootstrap';
import React, {createContext, useContext, useEffect, useState} from 'react';
import { renderChildren } from '../../helpers/common';
import XClose from '@untitled-ui/icons-react/build/esm/XClose';
import classnames from 'classnames';

export const ModalContext = createContext();

const BaseModal = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = (event) => {
        if (event) {
            event.stopPropagation()
        }
        if (props.disableClose) {
            return
        }

        let shouldClose = true;
        if (props.onClose) {
            shouldClose = props.onClose(false);
        }
        if (shouldClose || shouldClose === undefined) {
            setShow(false);
        }
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <ModalContext.Provider value={{ handleClose }}>
            <Modal centered={props.centered} show={show} onHide={handleClose} size={props.size || "md"} className={classnames("max-w-full !inline-flex", props.className)} contentClassName={classnames(props.contentClassName)} dialogClassName={props.dialogClassName} backdrop={props.backdrop}>
                { show && renderChildren(props) }
            </Modal>
        </ModalContext.Provider>
    );
}

const Header = (props) => {
	const { handleClose } = useContext(ModalContext);
	return (
		<Modal.Header className='rounded-t-xl'>
			<div className='flex gap-3 w-full items-start'>
				<div className='grow flex flex-col items-start gap-1'>{props.children}</div>
				{!props.disableClose && (
					<div className='flex h-7 items-center'>
						<button
							type='button'
							className='relative text-[#A4A7AE] hover:text-gray5 transition'
							onClick={() => handleClose()}
						>
							<span className='absolute -inset-2.5' />
							<span className='sr-only'>Close panel</span>
							<XClose width={24} height={24} />
						</button>
					</div>
				)}
			</div>
		</Modal.Header>
	);
};
BaseModal.Header = Header;

const Body = (props) => {
	return (
		<Modal.Body className={props.className}>
			<div className='notification-modal ahide noprint hide'>
				<Alert variant='warning'>Warning message</Alert>
			</div>
			{props.children}
		</Modal.Body>
	);
};
BaseModal.Body = Body;

const Title = (props) => {
	return <Modal.Title className='text-dark-gray font-semibold text-xl'>{props.children}</Modal.Title>;
};
BaseModal.Title = Title;

const Subtitle = (props) => {
    return (
        <div className="text-subtitle text-sm leading-6">
            { props.children }
        </div>
    );
}
BaseModal.Subtitle = Subtitle;

const Footer = (props) => {
    return (
        <Modal.Footer className="rounded-b-xl">
            { props.children }
        </Modal.Footer>
    );
}
BaseModal.Footer = Footer;

export default BaseModal;