import '../../../App.scss';
import React, { useEffect, useContext, useState } from 'react';
import { notifyEvent, serverFetch, serverPatch } from '../../../helpers/server';
import { BaseContext, getCountryOptions, getCurrencyOptions } from '../../../helpers/common';
import BaseForm from '../../../components/BaseForm';
import BaseContainer from '../../../components/BaseContainer';
import SubmitButton from '../../../components/common/buttons/SubmitButton';
import Notification from '../../../components/Notification';
import ContentContainer from '../../../components/ContentContainer';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import BoxedSection from '../../../components/layout/BoxedSection';

function Settings() {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        setPageTitle(`Settings`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, [getApiUrl]);

    const updateCompany = async (data, errorHandler) => {
        delete data['slug'];
        delete data['country'];
        const result = await serverPatch(getApiUrl(""), data, {}, errorHandler);
        if (result) {
            notifyEvent('settings');
            Notification.Success("Successfully updated");
        }
    }

    const updateSettings = async (data, errorHandler) => {
        if (!data.support_email) {
            data.support_email = null;
        }
        const result = await serverPatch(getApiUrl("/settings"), data, {}, errorHandler)
        if (result) {
            Notification.Success("Successfully updated");
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <BoxedSection title="Company Details">
                    <BaseForm initialFormFields={company} onSubmit={updateCompany} >
                        <Row>
                            <BaseForm.Input colSpan="5" name="name" type="text" label={t('common.name')} required />
                            <BaseForm.Input colSpan="5" name="slug" type="text" label={t('common.slug')} disabled={true} />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="5" name="legal_name" type="text" label={t('common.legal_name')} placeholder={"If different from Name"} />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="5" name="email"  type="text" label={t('common.email')} description={"Used for notifications and reminders on billing and usage."} />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="5" name="address.country" type="select" label={"Country"} options={getCountryOptions()} disabled />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="5" name="address" type="address" label={"Address"} />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="5" name="preferred_currency" type="select" label={"Preferred Currency"}
                                description={"Used for metrics and reporting. Does not affect the currency on actual invoices."}
                                options={getCurrencyOptions()}/>
                        </Row>
                        <SubmitButton errorOnRight>Update</SubmitButton>
                    </BaseForm>
                </BoxedSection>
                <BoxedSection
                    title="Brand Settings"
                    subtitle="These settings are used to customize emails and experiences for your customers driven by Maple on your behalf."
                >
                    <BaseForm initialFormFields={settings} onSubmit={updateSettings} >
                        <Row>
                            <BaseForm.Input type="file" colSpan="6" name="branding.logo_url" label="Logo" fileType="invoiceLogo" accept={"image/png,image/jpeg"} />
                        </Row>
                        {/*<Row className="hide">
                            <BaseForm.Input type="file" colSpan="6" name="branding.icon_url" label="Icon" fileType="invoiceLogo" />
                        </Row>*/}
                        <Row>
                            <BaseForm.Input colSpan="4" name="branding.color" type="color" label={"Color"} />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan="4" name="support_email" type="text" label={"Support Email"} transformations={["lowercase", "trim"]} validations={{ validEmail: true }}/>
                        </Row>
                        <SubmitButton>Update</SubmitButton>
                    </BaseForm>
                </BoxedSection>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Settings;
