import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import { serverPost, serverFetch, serverPatch } from '../../helpers/server';
import {
    BaseContext,
    renderProductPricing,
    getLabelForTaxCategory,
    UserContext,
    renderTags,
    getNameForProviderType, stopPropagations
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import AddOrEditProductModal from '../../components/modals/AddOrEditProductModal';
import AddOrEditProductPricingModal from '../../components/modals/AddOrEditProductPricingModal';
import ContentContainer from '../../components/ContentContainer';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import SectionNav from '../../components/SectionNav';
import Link from '../../components/Link';
import Button from '../../components/common/buttons/Button';
import CopyableComponent from "../../components/CopyableComponent";
import ChangeProductModal from "../../components/modals/ChangeProductModal";
import SelectEntitlements from "../../components/SelectEntitlements";
import MapleTable from "../../components/MapleTable";
import Label from "../../components/Label";
import DropdownMenu from "../../components/DropdownMenu";
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import SimpleModal from "../../components/modals/SimpleModal";
import MoreButton from "../../components/common/buttons/MoreButton";
import ArchiveModal from "../../components/modals/ArchiveModal";
import Loader from "../../components/Loader";
import ErrorComponent from "../../components/ErrorComponent";
import {getExternalProductReferenceURL} from "../../helpers/integrations";
import IntegrationReferenceEntitySummaryModal from "../../components/modals/IntegrationReferenceEntitySummary";
import Tags from "../../components/Tags";
import EditProductPricingModal from "../../components/modals/EditProductPricingModal";
import BoxedSection from '../../components/layout/BoxedSection';
import UnarchiveModal from "../../components/modals/UnarchiveModal";
import DotsVertical from '@untitled-ui/icons-react/build/esm/DotsVertical';
const _ = require('lodash');

function Product(props) {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { productId } = useParams();
    const { userInfo, isSuperUser, isMapleUser } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [product, setProduct] = useState({});
    const [pricings, setPricings] = useState([]);
    const [billableItems, setBillableItems] = useState([]);
    const [details, setDetails] = useState({});
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [isEditingEntitlements, setIsEditingEntitlements] = useState(false);
    const [showAddOrEditProductModal, setShowAddOrEditProductModal] = useState(false);
    const [showAddOrEditProductPricingModal, setShowAddOrEditProductPricingModal] = useState(false);
    const [showEditProductPricingModal, setShowEditProductPricingModal] = useState(false);
    const [showChangeProductModal, setShowChangeProductModal] = useState(false);
    const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState(false);
    const [showPricingArchiveModal, setShowPricingArchiveModal] = useState(false);
    const [showPricingUnarchiveModal, setShowPricingUnarchiveModal] = useState(false);
    const [showUnarchiveConfirmationModal, setShowUnarchiveConfirmationModal] = useState(false);
    const [showReferenceSummaryModal, setShowReferenceSummaryModal] = useState(false);
    const [pricingToArchive, setPricingToArchive] = useState(null);
    const [pricingToUnarchive, setPricingToUnarchive] = useState(null);
    const [pricingToEdit, setPricingToEdit] = useState(null);
    const [pricingToDuplicate, setPricingToDuplicate] = useState(null);
    const [filteredPricings, setFilteredPricings] = useState([]);
    const [hasArchivedPricings, setHasArchivedPricings] = useState(false);
    const [activeNav, setActiveNav] = useState("ACTIVE");
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState("createdAtDesc");

    useEffect(() => {
        setPageTitle(`Product - ${product.name}`);
    }, [product]);

    const hasWritePermission = hasAccess("pricing", userInfo, "write");

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/products/${productId}`), { skipCache }).then((res) => {
            if (res) {
                setProduct(res);
            }
            setLoading(false);
        });

        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                limit: limit
            },
            query: {
                product_id: productId
            }
        }
        serverPost(getApiUrl("/product_pricings/find"), params,{ skipCache }).then((res) => {
            if (res) {
                const results = res.results || [];
                setPricings(results);
            }
        });
    }

    useEffect(() => {
        setHasArchivedPricings(!_.isEmpty(_.filter(pricings, p => p.state === "ARCHIVED")));
        setFilteredPricings(_.filter(pricings, p => p.state === activeNav));
    }, [pricings, activeNav])

    useEffect(() => {
        fetchData();

        serverPost(getApiUrl("/billable/items/find"), {}).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
        });
    }, []);

    useEffect(() => {
        const data = {
            'Name': product.name,
            'Public Name': product.external_name,
            'Internal Description': product.description,
            'Tax Code': getLabelForTaxCategory(product.tax_category),
            'Tags': <Tags tags={product.tags} onUpdate={updateProductTags} tagsUrl={getApiUrl(`/products/tags`)} alignRight />
        };
        if (!_.isNil(product.imported_from)) {
            const externalReferenceUrl = getExternalProductReferenceURL(product);
            if (externalReferenceUrl) {
                data[`${getNameForProviderType(product.imported_from)} Reference`] =
                    <a href={externalReferenceUrl} target="_blank">{ product.import_ref }</a>;
            }
        }
        setDetails(data);
    }, [product]);

    const onModalClose = () => {
        setShowAddOrEditProductModal(false);
        setShowAddOrEditProductPricingModal(false);
        setShowEditProductPricingModal(false);
        setShowChangeProductModal(false);
        setShowArchiveConfirmationModal(false);
        setShowUnarchiveConfirmationModal(false);
        setShowPricingArchiveModal(false);
        setShowPricingUnarchiveModal(false);
        setShowReferenceSummaryModal(false);
        setPricingToEdit(null);
        setPricingToDuplicate(null);
        fetchData(true);
    }

    const updateProductTags = async (data) => {
        const result = await serverPatch(getApiUrl(`/products/${productId}`), data)
        if (result) {
            fetchData(true);
        }
        return result;
    }

    const editProduct = (product) => {
        setShowAddOrEditProductModal(true);
    }

    const addNewPricing = () => {
        // setShowAddOrEditProductPricingModal(true);
        navigate(getCompanySpecificUrl(`/pricing/create?product=${product.id}`))
    }

    const onEditPricing = (pricing) => {
        setShowEditProductPricingModal(true);
        setPricingToEdit(pricing);
    }

    const onChangeProduct = (pricing) => {
        setShowChangeProductModal(true);
        setPricingToEdit(pricing);
    }

    const onArchiveProductPricing = (pricing) => {
        serverPatch(getApiUrl(`/product_pricings/${pricing.id}`), { state: "ARCHIVED" }).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const onUnarchiveProductPricing = (pricing) => {
        serverPatch(getApiUrl(`/product_pricings/${pricing.id}`), { state: "ACTIVE" }).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const editMetadata = () => {
        setIsEditingMetadata(true);
    }

    const saveMetadata = (newMetadata) => {
        const processedData = {
            metadata: newMetadata
        };

        serverPatch(getApiUrl(`/products/${productId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingMetadata(false);
        });
    }

    const editEntitlements = () => {
        setIsEditingEntitlements(true);
    }

    const saveEntitlements = (newEntitlements) => {
        const processedData = {
            entitlements: newEntitlements
        };

        serverPatch(getApiUrl(`/products/${productId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingEntitlements(false);
        });
    }

    const onNavClick = (tabId) => {
        setActiveNav(tabId);
    }

    const isOneTimeProductPricing = (productPricing) => {
        const types = _.uniq(_.map(productPricing.product_metric_pricings, (pmp) => pmp.item.type));
        return types.length === 1 && types[0] === "ONETIME_ITEM";
    }

    const renderProductStatus = () => {
        let statusDescription = null;
        if (product.state === "ACTIVE") {
            statusDescription = <Label.Success className="d-flex align-items-center">Active</Label.Success>
        } else if (product.state === "ARCHIVED") {
            statusDescription = <Label.Info className="d-flex align-items-center">Archived</Label.Info>
        }
        return statusDescription;
    }

    const navigateToSubscriptionListForPricing = (event, pricing) => {
        event.preventDefault();
        event.stopPropagation();
        navigate(getCompanySpecificUrl(`/subscriptions?pp=${ pricing.id }&statuses=ACTIVE`));
    }

    const onConfirmArchive = () => {
        setShowArchiveConfirmationModal(false);
        serverPatch(getApiUrl(`/products/${product.id}`), { state: "ARCHIVED" }).then((res) => {
            if (res) {
                fetchData(true)
            }
        })
    }

    const onConfirmUnarchive = () => {
        setShowUnarchiveConfirmationModal(false);
        serverPatch(getApiUrl(`/products/${product.id}`), { state: "ACTIVE" }).then((res) => {
            if (res) {
                fetchData(true)
            }
        })
    }

    const onActionSelected = (action) => {
        if (action === "edit") {
            setShowAddOrEditProductModal(true);
        } else if (action === "archive") {
            setShowArchiveConfirmationModal(true);
        } else if (action === "unarchive") {
            setShowUnarchiveConfirmationModal(true);
        } else if (action === "references") {
            setShowReferenceSummaryModal(true);
        }
    }

    const renderActions = () => {
        if (!hasWritePermission) {
            return [];
        }

        const actionOptions = [
            { id: "edit", label: "Edit" },
            [{ divider: true }],
            ...(product.state !== "ARCHIVED" ? [{ id: "archive", label: "Archive" }] : []),
            ...(product.state === "ARCHIVED" ? [{ id: "unarchive", label: "Unarchive" }] : []),
        ];
        const actionOptionsFiltered = actionOptions.slice(2);
        return (!isMapleUser ? (
            <DropdownMenu className="p-2" items={actionOptions} onClick={onActionSelected}>
                <div className="flex flex-row gap-1 items-center">
                    <WrenchScrewdriverIcon className="h-4 w-4"/>
                    <span>Actions</span>
                </div>
            </DropdownMenu>
        ) : (
            <div className="flex gap-[12px] items-center">
                <Button onClick={() => onActionSelected("edit")}>Edit</Button>
                {actionOptionsFiltered.length > 0 && <DropdownMenu items={actionOptionsFiltered} onClick={onActionSelected} hideCaret>
                    <Button variant="text-outline" hasIconOnly>
                        <DotsVertical width={20} height={20} />
                    </Button>
                </DropdownMenu>}
            </div>
        ))
    }

    const tabItems = [{
        'label': 'Active',
        'id': 'ACTIVE',
        active: activeNav === "ACTIVE"
    }, {
        'label': 'Archived',
        'id': 'ARCHIVED',
        active: activeNav === "ARCHIVED"
    }]

    const getPricingActionOptions = (pricing) => {
        let canDuplicate = pricing.product_metric_pricings.length <= 1;
        if (canDuplicate) {
            let itemPricing = pricing.product_metric_pricings[0].item_pricing;
            if (itemPricing && itemPricing.type === "SCHEDULED") {
                canDuplicate = false;
            }
        }
        return [
            { id: "edit", label: "Edit" },
            canDuplicate && { id: "duplicate", label: "Duplicate" },
            isSuperUser && { id: "move_product", label: "Move Product" },
            { divider: true },
            { id: "archive", label: "Archive" },
            isSuperUser && { id: "references", label: "References" },
        ]
    }

    const getArchivedPricingActionOptions = (pricing) => {
        return [
            { id: "unarchive", label: "Unarchive" },
            isSuperUser && { id: "references", label: "References" },
        ]
    }

    const onPricingActionSelected = (action, pricing) => {
        if (action === "edit") {
            onEditPricing(pricing)
        } else if (action === "duplicate") {
            setShowAddOrEditProductPricingModal(true);
            setPricingToDuplicate(pricing);
        } else if (action === "move_product") {
            onChangeProduct(pricing)
        } else if (action === "archive") {
            setShowPricingArchiveModal(true);
            setPricingToArchive(pricing);
        } else if (action === "unarchive") {
            setShowPricingUnarchiveModal(true);
            setPricingToUnarchive(pricing);
        } else if (action === "references") {
            setPricingToEdit(pricing);
            setShowReferenceSummaryModal(true);
        }
    }

    const onConfirmPricingArchive = () => {
        onArchiveProductPricing(pricingToArchive);
    }

    const onConfirmPricingUnarchive = () => {
        onUnarchiveProductPricing(pricingToUnarchive)
    }

    const showTagsColumn = _.some(filteredPricings, (p) => !_.isEmpty(p.tags))

    return (
        <BaseContainer>
            <Loader loading={loading}>
                {
                    _.isEmpty(product) &&
                    <ErrorComponent
                        title={"Product Not Found"}
                        description={"Oops, we can't find the product you are looking for."}
                        primaryAction={{ href:getCompanySpecificUrl("/products"), label:"Show all products" }}
                        hideSupport
                    />
                }
                {
                    !_.isEmpty(product) &&
                        <ContentContainer variant="page" title={
                            <div className="d-flex flex-row gap-3">
                                <span>{ product.name }</span>
                                <div className="d-flex align-items-center gap-3">
                                    { renderProductStatus() }
                                </div>
                            </div>
                            } logo={product.image_url} customActions={renderActions()} enableStickyHeader={isMapleUser}>
                            <KeyValueDisplay2 title="Details" items={details} />
                            <BoxedSection title="Entitlements" className="mt-4" actions={!isEditingEntitlements && [
                                {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editEntitlements}
                            ]}>
                                <div className="max-w-3xl">
                                    <SelectEntitlements
                                        entitlements={product.entitlements}
                                        isEditing={isEditingEntitlements}
                                        onUpdateEntitlements={saveEntitlements}
                                        onCancelEditing={() => setIsEditingEntitlements(false)}
                                        removeTableSpacing
                                    />
                                </div>
                            </BoxedSection>
                            <BoxedSection title="Metadata" className="mt-4" actions={[
                                {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editMetadata}
                            ]} subtitle="These are fields that are stored on the product for internal reference.">
                                {
                                    (!isEditingMetadata && (_.isNil(product.metadata) || _.isEmpty(product.metadata))) ?
                                        <p className="text-gray-500">No metadata</p>
                                        : <KeyValueDisplay items={product.metadata} isEditing={isEditingMetadata}
                                                           onUpdateKeyValues={saveMetadata}
                                                           onCancelEditing={() => setIsEditingMetadata(false)}/>
                                }
                            </BoxedSection>
                            <BoxedSection title="Product Prices" className="mt-4 @container" actions={[
                                {variant: "primary", icon: "fa-plus", label: "Add New Product Pricing", onClick: addNewPricing}
                            ]}>
                                {
                                    hasArchivedPricings &&
                                        <>
                                            <SectionNav items={tabItems} onClick={onNavClick} />
                                            <br/>
                                        </>
                                }
                                <MapleTable>
                                    <MapleTable.Content removeMargin removePadding>
                                    <thead>
                                        <tr>
                                            <MapleTable.TH>Name</MapleTable.TH>
                                            <MapleTable.TH>ID</MapleTable.TH>
                                            {
                                                showTagsColumn &&
                                                    <MapleTable.TH>Tags</MapleTable.TH>
                                            }
                                            <MapleTable.TH>Price</MapleTable.TH>
                                            <MapleTable.TH className="hidden @3xl:table-cell">Subscriptions</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(filteredPricings, (pricing, i) =>
                                            <MapleTable.TR key={i} className="cursor-pointer" href={getCompanySpecificUrl(`/pricing/${pricing.id}`)}>
                                                <td>
                                                    <p>{ pricing.name }</p>
                                                    <p className="text-sm text-gray-500">{ pricing.description }</p>
                                                </td>
                                                <td>
                                                    <div className="min-w-[100px]">
                                                    <CopyableComponent value={ pricing.id }/>
                                                    </div>
                                                </td>
                                                {
                                                    showTagsColumn &&
                                                        <td>{ renderTags(pricing.tags) || <span className="gray2">None</span> }</td>
                                                }
                                                <td>
                                                    { renderProductPricing(pricing, billableItems) }
                                                </td>
                                                <td className="hidden @3xl:table-cell">
                                                {
                                                    isOneTimeProductPricing(pricing) ?
                                                        <span className="gray2">N/A</span>
                                                    : <Link href={getCompanySpecificUrl(`/subscriptions?pp=${ pricing.id }&statuses=ACTIVE`)}>{ pricing.num_subs } active</Link>
                                                }
                                                </td>
                                                <td className="text-end w-px whitespace-nowrap" onClick={stopPropagations}>
                                                {
                                                    pricing.state !== "ARCHIVED" ?
                                                        <div className="flex flex-row gap-1 items-center">
                                                            <Link href={getCompanySpecificUrl(`/payment_link/create?pricing=${pricing.id}`)}>
                                                                <BaseOverlayTrigger content={<span>Create Payment Link</span>}>
                                                                    <Button variant="text-primary" size="sm"><i className="fa fa-cart-shopping"/></Button>
                                                                </BaseOverlayTrigger>
                                                            </Link>
                                                            <MoreButton items={getPricingActionOptions(pricing)} onSelect={(action) => onPricingActionSelected(action, pricing)} popover />
                                                        </div>
                                                    : <div className="flex flex-row gap-1 items-center">
                                                        <MoreButton items={getArchivedPricingActionOptions(pricing)} onSelect={(action) => onPricingActionSelected(action, pricing)} />
                                                    </div>
                                                }
                                                </td>
                                            </MapleTable.TR>
                                        )
                                    }
                                    </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                            </BoxedSection>
                        </ContentContainer>
                }
            </Loader>
            <AddOrEditProductModal show={showAddOrEditProductModal} onClose={onModalClose} itemToEdit={product} />
            <AddOrEditProductPricingModal
                show={showAddOrEditProductPricingModal} onClose={onModalClose} itemToEdit={pricingToEdit}
                product={product} pricingToDuplicate={pricingToDuplicate} />
            <EditProductPricingModal show={showEditProductPricingModal} onClose={onModalClose} itemToEdit={pricingToEdit}/>
            <ChangeProductModal show={showChangeProductModal} onClose={onModalClose} pricing={pricingToEdit} />
            <SimpleModal show={showArchiveConfirmationModal} onClose={onModalClose} title="Confirm Archive"
                         buttonTitle="Archive" buttonVariant="danger" onConfirm={onConfirmArchive}
                         body={<span>Are you sure you want to archive this product?</span>}
            />
            <ArchiveModal show={showPricingArchiveModal} onConfirm={onConfirmPricingArchive} onClose={onModalClose} />
            <UnarchiveModal show={showPricingUnarchiveModal} onConfirm={onConfirmPricingUnarchive} onClose={onModalClose} />
            <UnarchiveModal show={showUnarchiveConfirmationModal} onConfirm={onConfirmUnarchive} onClose={onModalClose} />
            <IntegrationReferenceEntitySummaryModal
                show={showReferenceSummaryModal} onClose={onModalClose} entityKey={"PRODUCT_PRICING"}
                referenceID={pricingToEdit && pricingToEdit.id}
            />
        </BaseContainer>
    );
}

export default Product;
