import '../../App.scss';
import '../../css/modals.scss';
import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle} from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import { Row, Col } from 'react-bootstrap';
import { serverFetch, serverPost } from '../../helpers/server';
import { BaseContext, currencyFormatFromPrice } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import Link from "../Link";
import moment from 'moment';
const _ = require('lodash');

const StripeInvoicePaymentModal = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        setErrorFields(errors) {
            setErrorFields(errors);
        },
    }));

    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [errorFields, setErrorFields] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [setupIntent, setSetupIntent] = useState(null);
    const [isSetupIntent, setIsSetupIntent] = useState(true);

    useEffect(() => {
        if (props.show) {
            setInvoice(props.invoice);
        } else {
            setInvoice(null);
            setSetupIntent(null);
        }

        if (!props.show) {
            return;
        }

        const whitelistedCompanyIds = ["cmp_2IdT7WoPug6lU8xRugeF0It1cKd", "cmp_2rkO6wbUp4XrAo6vP7Eb2ixDkZu",
            "cmp_2rkOBJZOxwFRzg87a5cz5hjepaY", "cmp_2jhYw3c5BwlV8vOltsZBZCdpbM6"]
        const usePaymentIntent = !props.invoice.subscription_id && _.includes(whitelistedCompanyIds, company.id);
        if (!usePaymentIntent) {
            // This is an invoice with recurring fees
            const setupData = {
                currency: props.invoice.currency
            }
            serverFetch(getApiUrl(`/invoices/${props.invoice.uuid}/setup_intent`), setupData).then((res) => {
                if (res) {
                    setSetupIntent(res);
                }
            })
            setIsSetupIntent(true);
        } else {
            // This is an invoice with one time charges only
            serverFetch(getApiUrl(`/invoices/${props.invoice.uuid}/payment_intent`), {skipCache: true}).then((res) => {
                if (res) {
                    setSetupIntent(res);
                }
            })
            setIsSetupIntent(false);
        }
    }, [props.show, props.invoice])

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, []);

    const onPay = async (paymentMethod, paymentAmountInCents) => {
        const paymentMethodData = {
            stripe: {
                payment_method_id: paymentMethod,
            }
        }
        if (!isSetupIntent) {
            paymentMethodData.stripe.payment_intent_id = setupIntent.id
        }
        if (props.onPay) {
            await props.onPay(paymentMethodData, paymentAmountInCents);
        }
    }

    if (!props.show) {
        return
    }

    const publishableKey = settings.payment_provider_live_mode ?
        process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY
        : process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY;
    let stripeAccount = setupIntent && (setupIntent.provider_account_id || setupIntent.provider_customer_id || (setupIntent.stripe && setupIntent.stripe.account_id))
    const stripePromise = setupIntent && loadStripe(publishableKey, {
        stripeAccount: stripeAccount
    });
    const options = setupIntent && {
        clientSecret: setupIntent.intent_secret,
        appearance: {/*...*/},
    };

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{t('invoice.payment.title')}</BaseModal.Title>
            </BaseModal.Header>
            {
                (_.isNil(invoice) || _.isNil(setupIntent)) ?
                    <BaseModal.Body>
                        <div className="spinner-border text-secondary"/>
                    </BaseModal.Body>
                :   <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm
                            invoice={invoice} settings={settings} onPay={onPay} errorFields={errorFields}
                            isSetupIntent={isSetupIntent}
                        />
                    </Elements>
            }
            {
                errorFields &&
                    <div className="form-error-message">{ errorFields.error_message }</div>
            }
        </BaseModal>
    );
})

function CheckoutForm(props) {
    const stripe = useStripe();
    const { company } = useContext(BaseContext);
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [initialFields, setInitialFields] = useState({});
    const [invoice, setInvoice] = useState(null);
    const [nextAction, setNextAction] = useState(null);
    const [settings, setSettings] = useState(props.settings);

    useEffect(() => {
        if (_.isNil(props.invoice)) {
            setRedirectUrl(null);
            return;
        }
        const company = props.invoice.company;
        const domain = window.location.origin;
        const url = `${domain}/c/${company.id}/invoice/view?uuid=${props.invoice.uuid}`
        setRedirectUrl(url);
        setInvoice(props.invoice);
        setInitialFields({
            amount: props.invoice.due.value_in_cents/100
        })
    }, [props.invoice])

    useEffect(() => {
        setSettings(props.settings)
    }, [props.settings])

    const onSubmit = async (data) => {
        if (!stripe || !elements) {
            return;
        }
        setErrorMessage(null);

        const {error: submitError} = await elements.submit();
        if (submitError) {
            setErrorMessage(submitError.message);
            return;
        }

        if (props.isSetupIntent) {
            const {error, setupIntent} = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: redirectUrl,
                },
                redirect: "if_required"
            });

            if (error) {
                setErrorMessage(error.message);
            } else if (setupIntent) {
                if (setupIntent.next_action) {
                    setNextAction(setupIntent.next_action);
                } else {
                    if (props.onPay) {
                        await props.onPay(setupIntent.payment_method, data.amount*100)
                    }
                }
            }
        } else {
            const {error, paymentIntent} = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: redirectUrl,
                },
                redirect: "if_required"
            });

            if (error) {
                setErrorMessage(error.message);
            } else if (paymentIntent) {
                if (paymentIntent.next_action) {
                    setNextAction(paymentIntent.next_action);
                } else {
                    if (props.onPay) {
                        await props.onPay(null, data.amount*100)
                    }
                }
            }
        }
    };

    const allowPartialPayments = (settings && settings.invoice_options && settings.invoice_options.allow_partial_payments) || false;
    return (
        <>
            <BaseForm initialFormFields={initialFields} onSubmit={onSubmit} errorFields={props.errorFields}>
                <BaseModal.Body>
                    <div className="body1">
                        Processing payment for invoice ({ props.invoice.number }) for the amount { currencyFormatFromPrice(props.invoice.due) }
                    </div>
                    {
                        allowPartialPayments &&
                            <BaseForm.Input
                                colSpan={6} name="amount" label="Amount" type="number" step="0.01" min="0.01"
                                max={invoice && invoice.due.value_in_cents/100} validations={{ required: true, min: 0.01, max: invoice && invoice.due.value_in_cents/100}} />
                    }
                    {
                        _.isNil(nextAction) &&
                            <div>
                                <PaymentElement />
                                {errorMessage && <div className="form-error-message">{errorMessage}</div>}
                            </div>
                    }
                    {
                        !_.isNil(nextAction) && nextAction.type === "verify_with_microdeposits" && nextAction.verify_with_microdeposits &&
                            <div className="mt-3 form-error-message">
                                The selected payment method requires verification via micro deposits. A deposit
                                should be hitting your account on { moment(new Date(nextAction.verify_with_microdeposits.arrival_date*1000)).format("MMM D, YYYY") }.
                                Once you receive the deposit, please visit
                                the <a target="_blank" href={nextAction.verify_with_microdeposits.hosted_verification_url}>verification link</a> to
                                complete the process.
                            </div>
                    }
                    {
                        !_.isNil(nextAction) && nextAction.type === "redirect_to_url" && nextAction.redirect_to_url &&
                        <div className="mt-3 form-error-message">
                            The selected payment method requires additional steps for authentication before it can be used
                            for payments. Please visit
                            the <a target="_blank" href={nextAction.redirect_to_url.url}>authentication link</a> to
                            complete the process.
                        </div>
                    }
                </BaseModal.Body>
                <BaseModal.Footer>
                    <SubmitButton variant="primary" disabled={!_.isNil(nextAction)}>Process Payment</SubmitButton>
                </BaseModal.Footer>
            </BaseForm>
        </>
    )
}

export default StripeInvoicePaymentModal;
