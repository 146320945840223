import React, { createRef, useContext, useEffect, useState } from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import BaseModal from '../../components/modals/BaseModal';
import BaseForm, { BaseFormContext } from '../../components/BaseForm';
import SubmitButton from '../../components/common/buttons/SubmitButton';
import BaseSideModal from '../../components/modals/BaseSideModal';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import ContentBox from '../../components/ContentBox';
import { Button, Col, Row } from 'react-bootstrap';
import Link from '../../components/Link';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import DeleteButton from '../../components/DeleteButton';
import Notification from '../../components/Notification';
import { useNavigate } from 'react-router-dom';
import CommissionTierRules from './CommissionTierRules';
import Section from '../../components/Section';
import CommissionTemplateModal from '../../components/modals/CommissionTemplateModal';
const _ = require('lodash');

function CommissionPlanCreate(props) {
	const navigate = useNavigate();
	const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
	const [planType, setPlanType] = useState('QUOTA_BASED');
	const [numValueRules, setNumValueRules] = useState(1);
	const [numAcceleratorValueRules, setNumAcceleratorValueRules] = useState(0);
	const formRef = createRef();
	const [acceleratorType, setAcceleratorType] = useState(null);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(true);
	const [numClawbackRules, setNumClawbackRules] = useState(0);

	useEffect(() => {
		setPageTitle(`Commission Plans`);
	}, []);

	const templateOptions = [
		{
			value: 'custom',
			label: 'Custom Plan',
		},
		{
			value: 'single_rate',
			label: 'Single Rate Commission',
			description: 'Simple plan that pays the same commission rate on all deals.',
			image: '/icons/commission_single_rate.svg',
		},
		{
			value: 'tiered',
			label: 'Tiered Commission',
			description:
				'A commission plan that pays a higher commission rate after a certain level of quota achievement.',
			image: '/icons/commission_tiered.svg',
		},
		{
			value: 'tiered_with_ramp',
			label: 'Multi-tiered Commission',
			description:
				'A commission plan that pays a lower rate at a low quota achievement, and a higher rate at a high quota achievement.',
			image: '/icons/commission_with_ramp.svg',
		},
		{
			value: 'tiered_with_cap',
			label: 'Tiered Commission With Cap',
			description: 'A commission plan that pays a 0% commission rate after a certain level of quota achievement.',
			image: '/icons/commission_with_cap.svg',
		},
		{
			value: 'single_rate_contract_length_multiplier',
			label: 'Single Rate Commission With Contract Length Multiplier',
			description: 'A single rate commission plan that applies multipliers for longer deals.',
			image: '/icons/commission_with_accelerator.svg',
		},
	];

	const templateDetails = {
		single_rate: {
			name: 'Single Rate Commission Plan',
			template: 'single_rate',
			payout_event: 'CONTRACT_EXECUTED',
			base_type: 'ACV',
			commission_rules: [
				{
					commission_rate: 10,
					is_retroactive: false,
					rule: {
						comparator: {
							type: 'GREATER_THAN_EQUAL',
							left_item: {
								item_type: 'PROPERTY',
								property: 'QUOTA_ACHIEVED',
							},
							right_item: {
								item_type: 'VALUE',
								value: '0',
							},
						},
					},
				},
			],
		},
		tiered: {
			name: 'Tiered Commission',
			template: 'tiered',
			payout_event: 'CONTRACT_EXECUTED',
			base_type: 'ACV',
			commission_rules: [
				{
					commission_rate: 10,
					is_retroactive: false,
					rule: {
						comparator: {
							type: 'AND',
							left_item: {
								item_type: 'COMPARATOR',
								comparator: {
									type: 'GREATER_THAN_EQUAL',
									left_item: {
										item_type: 'PROPERTY',
										property: 'QUOTA_ACHIEVED',
									},
									right_item: {
										item_type: 'VALUE',
										value: '0',
									},
								},
							},
							right_item: {
								item_type: 'COMPARATOR',
								comparator: {
									type: 'LESS_THAN',
									left_item: {
										item_type: 'PROPERTY',
										property: 'QUOTA_ACHIEVED',
									},
									right_item: {
										item_type: 'VALUE',
										value: '50',
									},
								},
							},
						},
					},
				},
				{
					commission_rate: 20,
					is_retroactive: false,
					rule: {
						comparator: {
							type: 'GREATER_THAN_EQUAL',
							left_item: {
								item_type: 'PROPERTY',
								property: 'QUOTA_ACHIEVED',
							},
							right_item: {
								item_type: 'VALUE',
								value: '50',
							},
						},
					},
				},
			],
		},
		tiered_with_ramp: {
			name: 'Multi-tiered Commission',
			template: 'tiered_with_ramp',
			payout_event: 'CONTRACT_EXECUTED',
			base_type: 'ACV',
			commission_rules: [
				{
					commission_rate: 5,
					is_retroactive: false,
					rule: {
						comparator: {
							type: 'AND',
							left_item: {
								item_type: 'COMPARATOR',
								comparator: {
									type: 'GREATER_THAN_EQUAL',
									left_item: {
										item_type: 'PROPERTY',
										property: 'QUOTA_ACHIEVED',
									},
									right_item: {
										item_type: 'VALUE',
										value: '0',
									},
								},
							},
							right_item: {
								item_type: 'COMPARATOR',
								comparator: {
									type: 'LESS_THAN',
									left_item: {
										item_type: 'PROPERTY',
										property: 'QUOTA_ACHIEVED',
									},
									right_item: {
										item_type: 'VALUE',
										value: '50',
									},
								},
							},
						},
					},
				},
				{
					commission_rate: 10,
					is_retroactive: false,
					rule: {
						comparator: {
							type: 'AND',
							left_item: {
								item_type: 'COMPARATOR',
								comparator: {
									type: 'GREATER_THAN_EQUAL',
									left_item: {
										item_type: 'PROPERTY',
										property: 'QUOTA_ACHIEVED',
									},
									right_item: {
										item_type: 'VALUE',
										value: '50',
									},
								},
							},
							right_item: {
								item_type: 'COMPARATOR',
								comparator: {
									type: 'LESS_THAN',
									left_item: {
										item_type: 'PROPERTY',
										property: 'QUOTA_ACHIEVED',
									},
									right_item: {
										item_type: 'VALUE',
										value: '100',
									},
								},
							},
						},
					},
				},
				{
					commission_rate: 20,
					is_retroactive: false,
					rule: {
						comparator: {
							type: 'GREATER_THAN_EQUAL',
							left_item: {
								item_type: 'PROPERTY',
								property: 'QUOTA_ACHIEVED',
							},
							right_item: {
								item_type: 'VALUE',
								value: '100',
							},
						},
					},
				},
			],
		},
		tiered_with_cap: {
			name: 'Tiered Commission With Cap',
			template: 'tiered_with_cap',
			payout_event: 'CONTRACT_EXECUTED',
			base_type: 'ACV',
			commission_rules: [
				{
					commission_rate: 10,
					is_retroactive: false,
					rule: {
						comparator: {
							type: 'AND',
							left_item: {
								item_type: 'COMPARATOR',
								comparator: {
									type: 'GREATER_THAN_EQUAL',
									left_item: {
										item_type: 'PROPERTY',
										property: 'QUOTA_ACHIEVED',
									},
									right_item: {
										item_type: 'VALUE',
										value: '0',
									},
								},
							},
							right_item: {
								item_type: 'COMPARATOR',
								comparator: {
									type: 'LESS_THAN',
									left_item: {
										item_type: 'PROPERTY',
										property: 'QUOTA_ACHIEVED',
									},
									right_item: {
										item_type: 'VALUE',
										value: '50',
									},
								},
							},
						},
					},
				},
				{
					commission_rate: 20,
					is_retroactive: false,
					rule: {
						comparator: {
							type: 'AND',
							left_item: {
								item_type: 'COMPARATOR',
								comparator: {
									type: 'GREATER_THAN_EQUAL',
									left_item: {
										item_type: 'PROPERTY',
										property: 'QUOTA_ACHIEVED',
									},
									right_item: {
										item_type: 'VALUE',
										value: '50',
									},
								},
							},
							right_item: {
								item_type: 'COMPARATOR',
								comparator: {
									type: 'LESS_THAN',
									left_item: {
										item_type: 'PROPERTY',
										property: 'QUOTA_ACHIEVED',
									},
									right_item: {
										item_type: 'VALUE',
										value: '100',
									},
								},
							},
						},
					},
				},
				{
					commission_rate: 0,
					is_retroactive: false,
					rule: {
						comparator: {
							type: 'GREATER_THAN_EQUAL',
							left_item: {
								item_type: 'PROPERTY',
								property: 'QUOTA_ACHIEVED',
							},
							right_item: {
								item_type: 'VALUE',
								value: '100',
							},
						},
					},
				},
			],
		},
		single_rate_contract_length_multiplier: {
			name: 'Single Rate Commission With Contract Length Multiplier',
			template: 'single_rate_contract_length_multiplier',
			payout_event: 'CONTRACT_EXECUTED',
			base_type: 'ACV',
			commission_rules: [
				{
					commission_rate: 10,
					is_retroactive: false,
					rule: {
						comparator: {
							type: 'GREATER_THAN_EQUAL',
							left_item: {
								item_type: 'PROPERTY',
								property: 'QUOTA_ACHIEVED',
							},
							right_item: {
								item_type: 'VALUE',
								value: '0',
							},
						},
					},
				},
			],
			accelerator_type: 'MULTIPLICATIVE',
			value_accelerators: [
				{
					accelerator_rate: 1.2,
					rule: {
						comparator: {
							type: 'GREATER_THAN_EQUAL',
							left_item: {
								item_type: 'PROPERTY',
								property: 'CONTRACT_LENGTH',
							},
							right_item: {
								item_type: 'VALUE',
								value: '2',
							},
						},
					},
				},
			],
		},
	};

	const planTypeOptions = [
		{ value: 'QUOTA_BASED', label: 'Quota Based' },
		{ value: 'VALUE_BASED', label: 'Value Based' },
	];
	const payoutEventOptions = [
		{ value: 'CONTRACT_EXECUTED', label: 'On Contract Executed' },
		{ value: 'FIRST_INVOICE_PAID', label: 'On First Invoice Paid' },
	];
	const baseTypeOptions = [
		{ value: 'ACV', label: 'Contract ACV' },
		{ value: 'TCV', label: 'Contract TCV' },
	];
	const acceleratorTypeOptions = [
		{ value: 'ADDITIVE', label: 'Add' },
		{ value: 'MULTIPLICATIVE', label: 'Multiply' },
	];

	const onSubmit = async (data) => {
		const payload = {
			name: data.name,
			plan_type: planType,
			payout_event: data.payout_event,
			base_type: data.base_type,
		};

		if (planType === 'QUOTA_BASED' && data.commission_rules) {
			payload.rules = Object.keys(data.commission_rules).map((key) => {
				return {
					commission_rate: data.commission_rules[key].commission_rate,
					rule: data.commission_rules[key].rule,
					is_retroactive: data.commission_rules[key].is_retroactive,
				};
			});
		}

		if (planType === 'VALUE_BASED' && data.value_commission_rules) {
			payload.rules = Object.keys(data.value_commission_rules).map((key) => {
				return {
					commission_rate: data.value_commission_rules[key].commission_rate,
					rule: data.value_commission_rules[key].rule,
					is_retroactive: data.value_commission_rules[key].is_retroactive,
				};
			});
		}

		if (planType === 'QUOTA_BASED' && data.value_accelerators && acceleratorType) {
			payload.accelerators = Object.keys(data.value_accelerators).map((key) => {
				return {
					accelerator_rate: data.value_accelerators[key].accelerator_rate,
					rule: data.value_accelerators[key].rule,
					type: acceleratorType,
				};
			});
		}

		if (planType === 'VALUE_BASED' && data.accelerators && acceleratorType) {
			payload.accelerators = Object.keys(data.accelerators).map((key) => {
				return {
					accelerator_rate: data.value_accelerators[key].accelerator_rate,
					rule: data.value_accelerators[key].rule,
					type: acceleratorType,
				};
			});
		}

		if (data.clawbacks) {
			payload.clawbacks = Object.keys(data.clawbacks).map((key) => {
				return {
					clawback_percentage: data.clawbacks[key].clawback_percentage,
					rule: data.clawbacks[key].rule,
					affects_quota: data.clawbacks[key].affects_quota || false,
				};
			});
		}

		serverPost(getApiUrl('/commission/plan'), payload).then((res) => {
			if (res) {
				Notification.Success('Successfully created commission plan');
				navigate(getCompanySpecificUrl('/commissions/plans'));
			} else {
				Notification.Danger('Error creating commission plan');
			}
		});
	};

	const onFieldChange = (name, value) => {
		if (name === 'plan_type') {
			setPlanType(value);
		} else if (name === 'accelerator_type') {
			setAcceleratorType(value);
		} else if (name === 'template') {
			setSelectedTemplate(value);
			const details = templateDetails[value];

			if (details && details.value_accelerators && details.value_accelerators.length > 0) {
				setAcceleratorType(details.accelerator_type);
				setNumAcceleratorValueRules(details.value_accelerators.length);
			} else {
				setNumAcceleratorValueRules(0);
			}
		}
	};

	const valueRulePropertyKeys = [
		{ Key: 'CONTRACT_ACV', Type: 'Number' },
		{ Key: 'CONTRACT_TCV', Type: 'Number' },
		{ Key: 'CONTRACT_LENGTH', Type: 'Number' },
		{ Key: 'PRODUCT_IDS', Type: 'ProductArray' },
		{ Key: 'PRODUCT_PRICING_IDS', Type: 'ProductPricingArray' },
	];
	const valueRulePropertyValues = [
		{ value: 'CONTRACT_ACV', label: 'Contract ACV' },
		{ value: 'CONTRACT_TCV', label: 'Contract TCV' },
		{ value: 'CONTRACT_LENGTH', label: 'Contract Length (Years)' },
		{ value: 'PRODUCT_IDS', label: 'Products' },
		{ value: 'PRODUCT_PRICING_IDS', label: 'Product Pricings' },
	];

	const renderValueRuleInputRows = () => {
		return (
			<div className='flex flex-col gap-3'>
				{_.map(_.range(numValueRules), (i) => (
					<div key={i} className='bg-slate-50 p-4 rounded-lg border border-slate-200'>
						<div className='flex justify-between items-center mb-3'>
							<span className='text-sm font-medium text-gray-700'>Rule {i + 1}</span>
							{i > 0 && i === numValueRules - 1 && (
								<button
									type='button'
									onClick={() => setNumValueRules((prev) => prev - 1)}
									className='text-gray-400 hover:text-gray-600'
								>
									<XMarkIcon className='h-5 w-5' />
								</button>
							)}
						</div>
						<div className='grid grid-cols-12 gap-4 items-start'>
							<div className='col-span-3'>
								<div className='flex items-center'>
									<BaseForm.Input
										name={`value_commission_rules.${i}.commission_rate`}
										label='Commission Rate'
										type='number'
										step='0.01'
										min='0'
										max='100'
										required
										className='w-full'
									/>
									<span className='ml-2 mt-6'>%</span>
								</div>
							</div>
							<div className='col-span-9'>
								<BaseForm.Input
									name={`value_commission_rules.${i}.rule`}
									label='Condition'
									type='rule'
									isColored={true}
									propertyValues={valueRulePropertyValues}
									propertyFields={valueRulePropertyKeys}
									required
								/>
							</div>
						</div>
					</div>
				))}
				{/*<button*/}
				{/*	type="button"*/}
				{/*	onClick={() => setNumValueRules(prev => prev + 1)}*/}
				{/*	className="mt-2 flex items-center gap-2 text-blue-600 hover:text-blue-700"*/}
				{/*>*/}
				{/*	<PlusIcon className="h-5 w-5" />*/}
				{/*	<span>Add Rule</span>*/}
				{/*</button>*/}
			</div>
		);
	};

	const renderValueAccelerators = () => {
		return (
			<div className='flex flex-col gap-3'>
				{_.map(_.range(numAcceleratorValueRules), (i) => (
					<div key={i} className='p-4 rounded-lg border-2 border-gray1'>
						<div className='flex justify-between items-center mb-3'>
							<span className='text-sm font-medium text-gray-700'>Accelerator {i + 1}</span>
							{i === numAcceleratorValueRules - 1 && (
								<button
									type='button'
									onClick={() => {
										if (formRef.current) {
											const fields = {};
											const key = `value_accelerators.${i}`;
											fields[key] = null;
											formRef.current.onResetFields(fields);
										}
										setNumAcceleratorValueRules((prev) => prev - 1);
									}}
									className='text-gray-400 hover:text-gray-600'
								>
									<XMarkIcon className='h-5 w-5' />
								</button>
							)}
						</div>
						<div className='grid grid-cols-12 gap-4 items-start'>
							<div className='col-span-3'>
								<div className='flex items-center'>
									<BaseForm.Input
										name={`value_accelerators.${i}.accelerator_rate`}
										label='Accelerator Rate'
										type='number'
										step='0.01'
										min='0'
										max='100'
										required
										className='w-full'
									/>
									<span className='ml-2 mt-6'>
										{acceleratorType === 'MULTIPLICATIVE' ? 'x Base Rate' : '% +Base Rate'}
									</span>
								</div>
							</div>
							<div className='col-span-9'>
								<BaseForm.Input
									name={`value_accelerators.${i}.rule`}
									label='Condition'
									type='rule'
									isColored={false}
									propertyValues={valueRulePropertyValues}
									propertyFields={valueRulePropertyKeys}
									required
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	};

	const clawbackRulePropertyKeys = [
		{ Key: 'DAYS_SINCE_SUB_START', Type: 'Number' },
	];

	const clawbackRulePropertyValues = [
		{ value: 'DAYS_SINCE_SUB_START', label: 'Days Since Subscription Start' },
	]

	const renderClawbackRules = () => {
		return (
			<div className='flex flex-col gap-3'>
				{_.map(_.range(numClawbackRules), (i) => (
					<div key={i} className='p-4 rounded-lg border-2 border-gray1'>
						<div className='flex justify-between items-center mb-3'>
							<span className='text-sm font-medium text-gray-700'>Clawback Rule {i + 1}</span>
							{i === numClawbackRules - 1 && (
								<button
									type='button'
									onClick={() => {
										if (formRef.current) {
											const fields = {};
											const key = `clawbacks.${i}`;
											fields[key] = null;
											formRef.current.onResetFields(fields);
										}
										setNumClawbackRules((prev) => prev - 1);
									}}
									className='text-gray-400 hover:text-gray-600'
								>
									<XMarkIcon className='h-5 w-5' />
								</button>
							)}
						</div>
						<div className='grid grid-cols-12 gap-4 items-start'>
							<div className='col-span-3'>
								<div className='flex flex-col gap-4'>
									<div className='flex items-center'>
										<BaseForm.Input
											name={`clawbacks.${i}.clawback_percentage`}
											label='Clawback Percentage'
											type='number'
											step='0.01'
											min='0'
											max='100'
											required
											className='w-full'
										/>
										<span className='ml-2 mt-6'>%</span>
									</div>
									<div className='flex items-center gap-2'>
										<span className='text-sm text-gray-700 font-semibold mb-2.5'>Affects Quota</span>
										<BaseForm.Input
											name={`clawbacks.${i}.affects_quota`}
											type='switch'
											label=''
										/>
									</div>
								</div>
							</div>
							<div className='col-span-9'>
								<BaseForm.Input
									name={`clawbacks.${i}.rule`}
									label='Condition'
									type='rule'
									isColored={false}
									propertyValues={clawbackRulePropertyValues}
									propertyFields={clawbackRulePropertyKeys}
									required
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	};

	const onTemplateSelect = (template) => {
		setSelectedTemplate(template.value);

		const details = templateDetails[template.value];

		if (details && details.value_accelerators && details.value_accelerators.length > 0) {
			setAcceleratorType(details.accelerator_type);
			setNumAcceleratorValueRules(details.value_accelerators.length);
		}
		setShowSelectTemplateModal(false);
	};

	return (
		<BaseContainer>
			<ContentContainer>
				<Section variant='page' title={'Create Commission Plan'} />
				<BaseForm
					onSubmit={onSubmit}
					onFieldChange={onFieldChange}
					ref={formRef}
					initialFormFields={templateDetails[selectedTemplate]}
				>
					<ContentBox>
						<ContentBox.Title>Template Selection</ContentBox.Title>
						<ContentBox.Body>
							<Row>
								<BaseForm.Input
									name='template'
									label='Plan Template'
									type='select'
									options={templateOptions}
									showSearch={false}
									colSpan='12'
									required
								/>
							</Row>
						</ContentBox.Body>
					</ContentBox>

					<ContentBox>
						<ContentBox.Title>Plan Details</ContentBox.Title>
						<ContentBox.Body>
							<Row>
								<BaseForm.Input name='name' label='Plan Name' type='text' colSpan='6' required />
								<BaseForm.Input
									name='payout_event'
									label='Payout Event'
									type='select'
									showSearch={false}
									options={payoutEventOptions}
									colSpan='6'
									required
								/>
							</Row>
							<Row>
								<BaseForm.Input
									name='base_type'
									label='Commission Base'
									type='select'
									showSearch={false}
									options={baseTypeOptions}
									colSpan='6'
									required
								/>
							</Row>
							<br />
							{planType === 'QUOTA_BASED' && (
								<div className='mb-4'>
									<span className='body2'>Quota-based commissions detail</span>
									<div className='mt-2 flex flex-col gap-3'>
										<div>
											<CommissionTierRules name={`commission_rules`} />
										</div>
									</div>
								</div>
							)}
							{planType === 'VALUE_BASED' && (
								<div className='mb-4'>
									<span className='body2'>Value-based commissions detail</span>
									<div className='mt-2 flex flex-col gap-3'>{renderValueRuleInputRows()}</div>
									<Button
										type='button'
										variant='outline-light'
										onClick={() => setNumValueRules((prev) => prev + 1)}
										className='mt-2 flex items-center gap-2'
									>
										<PlusIcon className='h-5 w-5' />
										<span>Add Rule</span>
									</Button>
									{/*<div className="mt-2 mb-4 bg-slate-50 p-3 rounded-md border-1 border-slate-200">*/}
									{/*	<div className="flex flex-row items-center">*/}
									{/*		<Link onClick={() => setNumValueRules(prev => prev+1)}>*/}
									{/*			<div className="text-sm flex flex-row gap-1"><PlusIcon*/}
									{/*				className="w-5 h-5"/><span>Add Rule</span></div>*/}
									{/*		</Link>*/}
									{/*	</div>*/}
									{/*</div>*/}
								</div>
							)}
						</ContentBox.Body>
					</ContentBox>

					<ContentBox>
						<ContentBox.Title>Accelerators (Optional)</ContentBox.Title>
						<ContentBox.Body>
							{planType && (
								<div className='mb-4'>
									<div className='mt-2'>
										<BaseForm.Input
											name='accelerator_type'
											label='Accelerator Type'
											type='select'
											options={acceleratorTypeOptions}
											showSearch={false}
											colSpan='6'
										/>
									</div>
									{acceleratorType && (
										<div className='mt-4'>
											{planType === 'QUOTA_BASED' ? (
												<div>
													{renderValueAccelerators()}
													<Button
														type='button'
														variant='outline-light'
														onClick={() => setNumAcceleratorValueRules((prev) => prev + 1)}
														className='mt-2 flex items-center gap-2'
													>
														<PlusIcon className='h-5 w-5' />
														<span>Add Accelerator</span>
													</Button>
												</div>
											) : (
												<CommissionTierRules name='accelerators' />
											)}
										</div>
									)}
								</div>
							)}
						</ContentBox.Body>
					</ContentBox>

					<ContentBox>
						<ContentBox.Title>Clawbacks (Optional)</ContentBox.Title>
						<ContentBox.Body>
							{renderClawbackRules()}
							<Button
								type='button'
								variant='outline-light'
								onClick={() => setNumClawbackRules((prev) => prev + 1)}
								className='mt-2 flex items-center gap-2'
							>
								<PlusIcon className='h-5 w-5' />
								<span>Add Clawback Rule</span>
							</Button>
							<br/>
							<SubmitButton errorOnRight>Create Plan</SubmitButton>
						</ContentBox.Body>
					</ContentBox>
				</BaseForm>
				<CommissionTemplateModal
					show={showSelectTemplateModal}
					title={'Select starting commission template'}
					onClose={() => setShowSelectTemplateModal(false)}
					templates={templateOptions}
					onTemplateSelect={onTemplateSelect}
				/>
			</ContentContainer>
		</BaseContainer>
	);
}

export default CommissionPlanCreate;
