import React, {useContext, useEffect, useState} from "react";
import {
    BaseContext,
    getConfigForContractReminderSchedule,
    renderCampaignConfigRow,
} from "../../../helpers/common";
import {serverFetch, serverPatch, serverPost} from "../../../helpers/server";
import { Col, Row } from "react-bootstrap";
import Button from '../../../components/common/buttons/Button';
import BaseForm from "../../../components/BaseForm";
import ContentContainer from "../../../components/ContentContainer";
import Notification from "../../../components/Notification";
import EditScheduleModal from "../../../components/modals/EditScheduleModal";
import SimpleModal from "../../../components/modals/SimpleModal";
import SubmitButton from "../../../components/common/buttons/SubmitButton";
import BoxedSection from "../../../components/layout/BoxedSection";
const _ = require('lodash');

function ContractGeneralSettings() {
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [contractRemindersEnabled, setContractRemindersEnabled] = useState(false);
    const [showEditCustomReminderModal, setShowEditCustomReminderModal] = useState(false);
    const [showDisableRemindersModal, setShowDisableRemindersModal] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        setPageTitle(`Contract Settings`);
    }, []);

    const initialFields = () => {
        setContractRemindersEnabled(settings.proposal_sign_reminder_enabled);
        return {
            ...settings,
            contract_complete_cc_emails: _.join(settings.contract_complete_cc_emails, ",")
        }
    }

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl("/settings"), { skipCache: skipCache }).then((res) => {
            processSettings(res)
        });

        serverPost(getApiUrl("/proposals/templates/find"), {}).then((res) => {
            setTemplates(res);
        });

        serverFetch(getApiUrl("/users")).then((res) => {
            const nonPendingMembers = _.filter(res, (r) => !r.pending);
            setTeamMembers(nonPendingMembers);
        });
    }

    const processSettings = (res) => {
        setSettings(res);
        setContractRemindersEnabled(res.proposal_sign_reminder_enabled)
    }

    useEffect(() => {
        fetchData()
    }, []);

    const updateSettings = async(data) => {
        const res = await serverPatch(getApiUrl("/settings"), data);
        if (res) {
            processSettings(res)
            Notification.Success("Successfully updated");
        }
    }

    const updateContractSettings = async(data) => {
        let contractCompleteEmails = _.split(data.contract_complete_cc_emails, ",");
        contractCompleteEmails = _.map(contractCompleteEmails, (em) => {
            return _.trim(em);
        })
        contractCompleteEmails = _.filter(contractCompleteEmails, (em) => em);
        const res = await serverPatch(getApiUrl("/settings"), {contract_complete_cc_emails: contractCompleteEmails});
        if (res) {
            processSettings(res)
            Notification.Success("Successfully updated");
        }
    }

    const onFieldChange = async(name, value) => {
        if (name === "proposal_sign_reminder_enabled") {
            if (value === false) {
                setShowDisableRemindersModal(true)
            } else {
                setContractRemindersEnabled(value)
                const data = {
                    proposal_sign_reminder_enabled: value
                }
                await updateSettings(data);
            }
        }
        if (name === "contract_payment_method_required") {
            const data = {
                contract_payment_method_required: value
            }
            await updateSettings(data);
        }
        if (name === "default_contract_template_id") {
            const data = {
                default_contract_template_id: value
            }
            await updateSettings(data);
        }
        if (name === "default_contract_counter_signatory_id") {
            const data = {
                default_contract_counter_signatory_id: value
            }
            await updateSettings(data);
        }
    }

    const disableContractReminders = async() => {
        setContractRemindersEnabled(false)
        const data = {
            proposal_sign_reminder_enabled: false
        }
        await updateSettings(data);
    }

    const renderReminderSchedule = (type) => {
        const isSelected = settings.proposal_sign_reminder_config && (settings.proposal_sign_reminder_config.grouping_name === type);
        const config = (isSelected) ?
            settings.proposal_sign_reminder_config.config.items
            : getConfigForContractReminderSchedule(type);
        return renderSchedule(reminderScheduleTitleMap[type], reminderScheduleDescriptionMap[type], config, isSelected,
            type === "custom" && isSelected, () => onReminderScheduleSelected(type));
    }

    const onReminderScheduleSelected = async(type) => {
        if (type !== "custom") {
            await updateSettings({
                proposal_sign_reminder_config: {
                    grouping_name: type
                }
            });
        } else {
            setShowEditCustomReminderModal(true)
        }
    }

    const renderSchedule = (title, description, items, isSelected, editable, onClick) => {
        return <div className="schedule-wrapper">
            <div className="schedule-top">
                <h4>{ title }</h4>
                <span className="body1 flex-grow-1">{ description }</span>
                {
                    editable &&
                    <Button variant="outline-primary" className="w-full" onClick={onClick}>
                        <i className="fa fa-edit"/> Edit
                    </Button>
                }
                <Button variant="primary" className="w-full" onClick={onClick}
                        disabled={isSelected}>
                    { isSelected ? "Selected" : "Select" }
                </Button>
            </div>
            {
                !_.isEmpty(items) &&
                <div className="schedule-bottom">
                    <span className="body2">Schedule</span>
                    <div className="d-flex flex-column">
                        {
                            _.map(items, (c, i) =>
                                <span key={i}>{ renderCampaignConfigRow(c) }</span>
                            )
                        }
                    </div>
                </div>
            }
        </div>
    }

    const onCustomContractReminderScheduleSelected = async (scheduleItems) => {
        await updateSettings({
            proposal_sign_reminder_config: {
                grouping_name: "custom",
                config: {
                    items: scheduleItems
                }
            }
        });
        setShowEditCustomReminderModal(false);
    }

    const reminderScheduleTitleMap = {
        "basic": "Basic",
        "lenient": "Lenient",
        "aggressive": "Persistent",
        "custom": "Custom"
    }

    const reminderScheduleDescriptionMap = {
        "basic": "",
        "lenient": "",
        "aggressive": "",
        "custom": ""
    }

    const templateOptions = _.map(templates, (template) => {
        return {
            value: template.id,
            label: template.name
        }
    })
    templateOptions.unshift({value: "", label: "Not selected"})

    const counterSignOptions = _.map(teamMembers, (member) => {
        return {
            value: member.id,
            label: member.user.name || member.user.email
        }
    })
    counterSignOptions.sort((a, b) => {
        if (a.label < b.label) {
            return -1
        } else {
            return 1
        }
    })
    counterSignOptions.unshift({value: "", label: "Not selected"})

    return (
        <>
            <ContentContainer>
                <BoxedSection title="Contract Payments" className={"mt-4"}>
                    <Row>
                        <Col md="8">
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <Row>
                                    <BaseForm.Input
                                        type="switch" colSpan="12" name="contract_payment_method_required" label="Require Payment Method By Default"
                                        description="Require a payment method before a contract is executed. This can be overriden per contract."
                                    />
                                </Row>
                            </BaseForm>
                        </Col>
                    </Row>
                </BoxedSection>
                <BoxedSection title="Contract Emails">
                    <Row>
                        <Col md="8">
                            <BaseForm initialFormFields={initialFields} onSubmit={updateContractSettings}>
                                <Row>
                                    <BaseForm.Input
                                        type="text" colSpan="12" name="contract_complete_cc_emails" label="Contract Complete Emails"
                                        description="These email addresses will be cc'd when contracts are complete."
                                        transformations={["lowercase", "trim"]} validations={{ validEmails: true }}
                                    />
                                </Row>
                                <SubmitButton>Update</SubmitButton>
                            </BaseForm>
                        </Col>
                    </Row>
                </BoxedSection>
                <BoxedSection title="Contract Reminders">
                    <Row>
                        <Col md="8">
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <Row>
                                    <BaseForm.Input
                                        type="switch" colSpan="12" name="proposal_sign_reminder_enabled" label="Send Customer Contract Reminders"
                                        description="Remind customers about their unsigned and unpaid contracts."
                                    />
                                </Row>
                            </BaseForm>
                        </Col>
                    </Row>
                </BoxedSection>
                {
                    contractRemindersEnabled &&
                    <BoxedSection title="Reminder Schedule">
                        <Row>
                            <Col md="8">
                                <div className="text-sm font-semibold">Configure Reminder Schedule</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col lg="3">{renderReminderSchedule("basic")}</Col>
                                    <Col lg="3">{renderReminderSchedule("lenient")}</Col>
                                    <Col lg="3">{renderReminderSchedule("aggressive")}</Col>
                                    <Col lg="3">{renderReminderSchedule("custom")}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </BoxedSection>
                }
                <BoxedSection title="Contract Defaults">
                    <Row>
                        <Col md="8">
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <Row>
                                    <BaseForm.Input
                                        type="select" colSpan="8" name="default_contract_template_id" label="Default Contract Template"
                                        options={templateOptions}
                                        showSearch={false}
                                    />
                                </Row>
                                <br/>
                                <Row>
                                    <BaseForm.Input
                                        type="select" colSpan="8" name="default_contract_counter_signatory_id" label="Default Counter Signatory"
                                        options={counterSignOptions}
                                        showSearch={false}
                                    />
                                </Row>
                            </BaseForm>
                        </Col>
                    </Row>
                </BoxedSection>
                <EditScheduleModal
                    show={showEditCustomReminderModal}
                    actions={["notification"]}
                    onClose={setShowEditCustomReminderModal}
                    onScheduleUpdated={onCustomContractReminderScheduleSelected}
                    defaultItems={settings.proposal_sign_reminder_config && settings.proposal_sign_reminder_config.config.items}
                    isContractSchedule={true}
                />
            </ContentContainer>
            <SimpleModal
                title={"Confirm Disable Contract Reminders"}
                body={
                    <div>
                        <span>Are you sure you want to disable contract reminders? All active contract reminders will be stopped.</span>
                    </div>
                }
                buttonVariant={"danger"}
                buttonTitle={"Disable Reminders"}
                show={showDisableRemindersModal}
                onClose={setShowDisableRemindersModal}
                onConfirm={() => disableContractReminders()}
            />
        </>
    )
}
export default ContractGeneralSettings;