import '../App.scss';
import React, { useState, useEffect, forwardRef } from 'react';
import MapleTable from "./MapleTable";
import Button from './common/buttons/Button';
import classnames from 'classnames';
import ContentBox from './ContentBox';
const _ = require("lodash");

function KeyValueDisplay2(props, ref) {
    const [items, setItems] = useState([]);
    const [key, setKey] = useState("key");
    const [value, setValue] = useState("value");
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (!_.isNil(props.keyField)) {
            setKey(props.keyField);
        } else {
            setKey("key");
        }
    }, [props.keyField]);

    useEffect(() => {
        if (!_.isNil(props.valueField)) {
            setValue(props.valueField);
        } else {
            setValue("value");
        }
    }, [props.valueField]);

    useEffect(() => {
        if (_.isNil(props.isEditing)) {
            setIsEditing(false);
        } else {
            if (props.isEditing && items.length === 0) {
                // Set up one item so there is at least one to edit.
                setItems([{}]);
            }
            setIsEditing(props.isEditing)
        }
    }, [props.isEditing])

    const processItemsProps = () => {
        if (Array.isArray(props.items)) {
            setItems(props.items);
        } else {
            const keys = _.keys(props.items);
            const items = [];
            _.each(keys, (akey, i) => {
                const item = {};
                item[key] = akey;
                item[value] = props.items[akey];
                items.push(item)
            });
            setItems(items);
        }
    }

    useEffect(() => {
        if (props.isEditing) {
            return;
        }
        processItemsProps();
    }, [props.items, key, value]);

    const renderList = () => (
        <div className={classnames("max-w-3xl", props.className)}>
            <MapleTable>
                <MapleTable.Content 
                    overflow 
                    tableContainerClassName="bg-white !border-none"
                    tableClassName="shadow-none"
                    removePadding
                    removeMargin
                > 
                    <tbody className="divide-y divide-dashed divide-gray3 !border-t-0">
                    {
                        _.map(items, (item, i) =>
                            <tr key={i} className="[&>td]:!px-0 [&>td]:!py-[10px]">
                                <td className="w-[200px]">
                                    <span className="text-sm font-medium leading-6 text-gray6">{ item[key] }</span>
                                </td>
                                <td className="text-right">
                                    <span className="flex-grow text-dark-gray font-semibold flex flex-col items-end">{ (!_.isNil(item[props.valueField || "value"]) && !_.isEmpty(item[props.valueField || "value"])) ? item[props.valueField || "value"] : <span className="gray2">None</span> }</span>
                                    <span className="ml-0 flex-shrink-0 hide">
                                        <Button type="button" variant="text-primary">
                                            Update
                                        </Button>
                                    </span>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
        </div>
    );

    const renderWrapperList = () => (
        <ContentBox ref={ref} className={classnames("max-w-2xl w-full", props.contentBoxClassName)}>
            {props.title && <ContentBox.Title>
                {props.title}
            </ContentBox.Title>}
            <ContentBox.Body className={classnames("!pb-2", props.title ? "pt-0" : "pt-2")}>
                {renderList()}
            </ContentBox.Body>
        </ContentBox>
    );

    return renderWrapperList();
}

export default forwardRef(KeyValueDisplay2);
