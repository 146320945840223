import '../App.scss';
import React from "react";
import { renderChildren } from '../helpers/common';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

const MapleTable = (props) => {
    return (
        <div className={classnames(props.className)}>
            { renderChildren(props) }
        </div>
    );
}

const Header = (props) => {
    return (
        <div className="flex flex-row gap-2">
            { renderChildren(props) }
        </div>
    );
}
MapleTable.Header = Header;

const Actions = (props) => {
    return (
        <div className="flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className={classnames("inline-block py-2 align-middle sm:px-6 lg:px-8", props.notfull ? "":  "min-w-full")}>
                    { renderChildren(props) }
                </div>
            </div>
        </div>
    );
}
MapleTable.Actions = Actions;

const Content = (props) => {
    return (
        <div className={classnames("mt-0 flow-root table-content", { "mb-2": !props.removeMargin })}>
            <div className={classnames("my-0", { "py-2": !props.removePadding, "mb-2": !props.removeMargin })}>
                <div className={classnames(
              "inline-block align-middle max-w-full border rounded-xl border-gray2 w-full" ,
                    props.notfull ? "":  "min-w-full",
                    props.overflow ? "overflow-visible": "overflow-auto",
                    props.tableContainerClassName)}>
                    <table className={classnames(
                        "rounded-xl shadow-input divide-gray2 gray divide-y max-w-full bg-white",
                        props.tableClassName,
                        props.notfull ? "":  "min-w-full",
                    )}>
                        { renderChildren(props) }
                    </table>
                </div>
            </div>
        </div>
    );
}
MapleTable.Content = Content;

const Head = (props) => {
    return (
        <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block py-2 align-middle sm:px-6 lg:px-8">
                    <table className=" divide-y divide-gray-300">
                        { renderChildren(props) }
                    </table>
                </div>
            </div>
        </div>
    );
}
MapleTable.Head = Head;

const Body = (props) => {
    return (
        <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        { renderChildren(props) }
                    </table>
                </div>
            </div>
        </div>
    );
}
MapleTable.Body = Body;

const TH = (props) => {
    return (
        <th scope="col" {...props} className={classnames('first:rounded-tl-xl last:rounded-tr-xl !text-gray5 bg-gray0 !text-xs', props.className)}>
            { props.children }
        </th>
    )
}
MapleTable.TH = TH;

const TR = (props) => {
    const navigate = useNavigate();

    const handleClick = (event) => {
        let openInNewTab = false;
        if (event.metaKey || event.ctrlKey) {
            openInNewTab = true;
        }
        if (props.onClick) {
            props.onClick(event);
        } else if (props.href) {
            if (openInNewTab) {
                const url = window.location.origin + props.href;
                window.open(url, '_blank', 'noopener,noreferrer');
            } else {
                navigate(props.href)
            }
        }
    }
    return (
        <tr {...props} className={props.className} onClick={handleClick}>
            { props.children }
        </tr>
    )
}
MapleTable.TR = TR;

const LeftActions = (props) => {
    return (
        <div className="grow">
            { renderChildren(props) }
        </div>
    );
}
MapleTable.LeftActions = LeftActions;

const MiddleActions = (props) => {
    return (
        <div className={classnames("flex flex-row gap-2 items-center justify-end", "thin")}>
            { renderChildren(props) }
        </div>
    );
}
MapleTable.MiddleActions = MiddleActions;

const RightActions = (props) => {
    if (props.children) {
        return (
            <div className={classnames("align-items-end")}>
                <div className="d-flex flex-row gap-3 align-items-center">
                    { renderChildren(props) }
                </div>
            </div>
        )
    } else {
        return null;
    }
}
MapleTable.RightActions = RightActions;

export default MapleTable;
