import '../App.scss';
import React, { createContext, useState, useContext, forwardRef } from 'react';
import { renderChildren } from '../helpers/common';
import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronUp from '@untitled-ui/icons-react/build/esm/ChevronUp';
import classnames from 'classnames';

const ContentBoxContext = createContext();

const ContentBox = forwardRef(({ style, noBottomMargin, overflowHidden, hasError, isCollapsible, collapsed, setCollapsed, ...props }, ref) => {
	const [collapsedInternal, setCollapsedInternal] = useState(false);

	return (
		<ContentBoxContext.Provider value={{
			isCollapsible: isCollapsible,
			collapsed: !!collapsed || collapsedInternal,
			setCollapsed: setCollapsed || setCollapsedInternal
		}}>
			<div
				ref={ref}
				style={style}
				{...props}
				className={classnames(
					{
						'mb-[20px]': !noBottomMargin,
						'overflow-hidden': overflowHidden,
					},
					hasError ? "!border-red-base bg-[#FFFBFA]" : "bg-white border-gray2",
					'border shadow-card rounded-xl',
					props.className,
				)}
			>
				{renderChildren(props)}
			</div>
		</ContentBoxContext.Provider>
	);
});

const Body = (props) => {
	const contextBoxContext = useContext(ContentBoxContext);
	const pprops = {...props};
	delete pprops['contentBoxHasTitle'];
	
	return (
		<div 
			{...pprops}
			className={
				classnames(
					'px-[20px] py-3',
					{ "!pt-2": props.contentBoxHasTitle },
					contextBoxContext.collapsed && contextBoxContext.isCollapsible ? "hidden" : "",
					props.className)}>
			{renderChildren(props)}
		</div>
	);
};
ContentBox.Body = Body;

const Title = ({ onExpand, onCollapse, ...props }) => {
	const contextBoxContext = useContext(ContentBoxContext);

	const getTextSize = (size) => {
		switch (size) {
			case 'lg':
				return 'text-lg';
			default:
				return 'text-xl';
		};
	};
	return (
		<div {...props} className={
			classnames(
				'px-[20px] pt-[12px] text-dark-gray font-semibold flex justify-between items-center',
				contextBoxContext.collapsed ? "pb-[12px]" : "pb-[8px]",
				getTextSize(props.size),
				props.className,
			)
		}>
			<div>
				{renderChildren(props)}
				<div className="text-sm font-normal text-gray6 mt-1">
					{props.subtitle}
				</div>
			</div>
			<div className="text-sm">
				{props.right}
				{contextBoxContext.isCollapsible && (
					<div 
						className="cursor-pointer" 
						onClick={() => {
							let stop = false;
							if (contextBoxContext.collapsed && props.onExpand) {
								onExpand(() => {
									stop = true;
								});
							}
							if (!contextBoxContext.collapsed && props.onCollapse) {
								onCollapse(() => {
									stop = true;
								});
							}
							if (stop) return;

							contextBoxContext.setCollapsed(!contextBoxContext.collapsed)
						}}
					>
						<span className="flex items-center gap-1 text-subtitle">
							{contextBoxContext.collapsed 
								? (
									<>
										Expand
										<ChevronDown className="inline" height={20} width={20}/>
									</> 
								) : (
									<>
										Collapse
										<ChevronUp className="inline" height={20} width={20}/>
									</> 
								)}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};
ContentBox.Title = Title;

export default ContentBox;
