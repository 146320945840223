import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React, {useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from '../modals/BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import Button from '../common/buttons/Button';
import { useTranslation } from 'react-i18next';
import {renderAddress, updateFormField} from "../../helpers/common";
const _ = require('lodash');

function AddOrEditCustomerModal(props) {
    const { t } = useTranslation('common');
    const [hasAddressValidationError, setHasAddressValidationError] = useState(false);
    const [recommendedAddress, setRecommendedAddress] = useState(null);
    const [usingRecommendedAddress, setUsingRecommendedAddress] = useState(false);
    const [initialFields, setInitialFields] = useState(null);
    const [keyChanged, setKeyChanged] = useState(false);
    const [integrationCustomer, setIntegrationCustomer] = useState(null);

    useEffect(() => {
        if (props.itemToEdit) {
            setInitialFields({
                ...props.itemToEdit,
                billing_emails: _.join(props.itemToEdit.billing_emails, ",")
            })
            setKeyChanged(true);
        } else if (props.initialFormFields) {
            setInitialFields({
                name: props.initialFormFields.name,
                email: props.initialFormFields.email,
                phone: props.initialFormFields.phone,
                title: props.initialFormFields.title,
                org_name: props.initialFormFields.org_name,
                identifier: props.initialFormFields.identifier || props.initialFormFields.email,
            });
            setKeyChanged(true);

            if (props.initialFormFields.integration_id) {
                setIntegrationCustomer({
                    integration_id: props.initialFormFields.integration_id,
                    import_id: props.initialFormFields.import_id,
                    integration_object_type: props.initialFormFields.integration_object_type,
                })
            }
        } else {
            setInitialFields(null)
            setKeyChanged(false);
        }
    }, [props.itemToEdit, props.show, props.initialFormFields]);

    const cleanupFields = (fields) => {
        let billingEmails = _.split(fields.billing_emails, ",");
        billingEmails = _.map(billingEmails, (em) => {
            return _.trim(em);
        })
        billingEmails = _.filter(billingEmails, (em) => em);
        const processedFields = {
            ...fields,
            identifier: (fields.identifier && fields.identifier.trim()) || fields.email,
            billing_emails: billingEmails
        };
        if (Object.values(fields.address).every(addressField => !addressField)) {
            processedFields.address = null;
        }
        if (usingRecommendedAddress) {
            processedFields.address = recommendedAddress
        }
        if (integrationCustomer) {
            processedFields['integration_customer'] = integrationCustomer
        }
        return processedFields;
    }

    const errorHandler = async (defaultErrorHandler, errorRes) => {
        const errorResponse = await errorRes.json();
        if (!_.isEmpty(errorResponse) && !_.isEmpty(errorResponse.error_body) && !_.isEmpty(errorResponse.error_body.address_validation)) {
            setHasAddressValidationError(true);
            setRecommendedAddress(errorResponse.error_body.address_validation.recommended_address);
        }
        defaultErrorHandler(errorRes, errorResponse);
    }

    const onFieldChange = (name, value) => {
        if (name === "email") {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                if (!keyChanged) {
                    newFields.identifier = value;
                }
                newFields[name] = value;
                return newFields;
            })
        } else {
            if (name === "identifier") {
                setKeyChanged(true);
            }
            setInitialFields(prevFields => {
                return updateFormField({...prevFields}, name, value)
            })
        }
    }

    useEffect(() => {
        if (!props.show) {
            setInitialFields(null);
        }
    }, [props.show])

    return (
        <BaseAddOrEditItemModal
            {...props}
            isEditing={!_.isNil(props.itemToEdit)}
            itemToEdit={initialFields}
            onFieldChange={onFieldChange}
            errorHandlerWrapper={errorHandler}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            onAdded={props.onAdded}
            onUpdated={props.onUpdated}
            itemLabel={t('customers.customer')}
            itemBaseUrl="/customers"
        >
            <Row>
                <BaseForm.Input colSpan="12" type="text" name="name" label="Contact Name" />
                <BaseForm.Input colSpan="12" type="text" name="email" label="Email" transformations={["lowercase", "trim"]} validations={{ required: true, validEmail: true }} />
                <BaseForm.Input
                    colSpan="12" type="text" name="billing_emails" label="Billing Emails"
                    description="Additional billing emails, to be cc'd in emails about invoices. You can add multiple emails by comma separating them."
                    transformations={["lowercase", "trim"]} validations={{ validEmails: true }}
                />
                <BaseForm.Input colSpan="12" type="text" name="title" label="Title (optional)" />
                <BaseForm.Input colSpan="12" type="text" name="org_name" label="Organization Name (optional)" />
                <BaseForm.Input colSpan="12" type="text" name="phone" label="Phone Number (optional)" />
                <BaseForm.Input colSpan="12" type="address" name="address" label={props.fixTaxAddress ? "Address" : "Address (optional)"} required={props.fixTaxAddress} />
                <BaseForm.Input colSpan="12" type="text" name="identifier" label="External Identifier (optional)" />
                {
                    hasAddressValidationError && recommendedAddress &&
                        <div>
                            <span className="text-sm font-semibold">Recommended Address</span>
                            <div className="flex flex-row gap-1 items-center mt-2 text-sm rounded-md shadow-md ring-1 ring-black ring-opacity-5 p-3">
                                <div className="grow">{ renderAddress(recommendedAddress) }</div>
                                {
                                    usingRecommendedAddress ?
                                        <Button onClick={() => setUsingRecommendedAddress(false)} variant="primary">Using</Button>
                                    : <Button onClick={() => setUsingRecommendedAddress(true)} variant="primary">Use</Button>
                                }
                            </div>
                        </div>
                }
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditCustomerModal;
