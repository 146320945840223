import '../App.scss';
import React, { createContext, useContext, useState } from 'react';
import Header from './layout/header/Header';
import LeftNav from './LeftNav';
import Notification from './Notification';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';
import { AppContext, UserContext } from '../helpers/common';
import _ from 'lodash';

export const BaseContainerContext = createContext();

function BaseContainer(props) {
	const [contextHeader, setContextHeader] = useState(null);
	const { isNavCollapsed } = useContext(AppContext);
	const userContext = useContext(UserContext);
	const { isMapleUser } = !_.isNil(userContext) ? userContext : { isMapleUser: false };

	const renderHeader = () => {
		if (props.header) {
			return props.header;
		} else if (contextHeader) {
			return contextHeader;
		} else {
			return null
		}
	};

	return (
		<BaseContainerContext.Provider value={{ setHeader: setContextHeader }}>
			<Container fluid className={classnames('page-wrapper', props.containerClassName)}>
				<Notification />
				<LeftNav />
				<div className={classnames(
					'right-wrapper relative',
					{
						"updated-nav": isMapleUser,
						"right-wrapper-nav-collapsed": isMapleUser && isNavCollapsed
					},
					props.rightWrapperClassName
				)}>
					<Header /> 
					{renderHeader()}
					<div className='content-wrapper' id='content-wrapper'>
						{props.children}
					</div>
				</div>
			</Container>
		</BaseContainerContext.Provider>
	);
}

function BaseContainerCustomHeader({ show, children }) {
	return (
		<AnimateHeight
			id="base-container-custom-header"
			height={show ? "auto" : 0}
			duration={200}
			className="px-8 bg-white absolute left-0 right-0 top-[49px] lg:top-0 z-20 border-b border-b-gray2 shadow-card"
		>
			<div className={classnames("py-2 transition-opacity duration-200 hidden non-mobile:block", show ? "opacity-100" : "opacity-0" )}>
				{children}
			</div>
		</AnimateHeight>
	);
}

BaseContainer.CustomHeader = BaseContainerCustomHeader;

export default BaseContainer;
