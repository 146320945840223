import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { BaseContext, getDescriptionForRule } from '../../helpers/common';
import { serverFetch } from '../../helpers/server';
import moment from 'moment';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import ContentBox from '../../components/ContentBox';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import MapleTable from '../../components/MapleTable';
import Loader from "../../components/Loader";
import Label from "../../components/Label";

function CommissionPlanNew() {
	const { id } = useParams();
	const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
	const [plan, setPlan] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setPageTitle('Commission Plan Details');
		fetchCommissionPlanData();
	}, []);

	const fetchCommissionPlanData = () => {
		serverFetch(getApiUrl(`/commission/plan/${id}`)).then((res) => {
			if (res) {
				setPlan(res);
			}
			setLoading(false);
		});
	};

	const getPlanTypeLabel = (type) => {
		const types = {
			FLAT_PERCENTAGE: 'Flat Percentage',
			QUOTA_BASED: 'Quota Based',
			VALUE_BASED: 'Value Based',
		};
		return types[type] || type;
	};

	const getPayoutEventLabel = (event) => {
		const events = {
			CONTRACT_EXECUTED: 'On Contract Execution',
			FIRST_INVOICE_PAID: 'On First Invoice Paid',
			INVOICE_PAID: 'On Invoice Paid',
		};
		return events[event] || event;
	};

	const renderOverview = () => {
		const details = {
			'Plan Name': plan.name,
			'Created Date': moment(plan.created_at).format('MMM D, YYYY'),
			'Plan Type': getPlanTypeLabel(plan.plan_type),
			'Payout Event': getPayoutEventLabel(plan.payout_event),
		};

		return (
			<Section>
				<KeyValueDisplay2 title={"Overview"} items={details} />
			</Section>
		)
	};

	const rulePropertyMap = {
		'QUOTA_ACHIEVED': 'Quota Achieved %',
		'PRODUCT_PRICING_IDS': 'Product Pricings'
	}

	const renderRules = () => {
		if (!plan.rules?.length) return null;

		return (
			<ContentBox>
				<ContentBox.Title>Commission Rules</ContentBox.Title>
				<ContentBox.Body>
					<MapleTable>
						<MapleTable.Content>
							<thead>
								<tr>
									<MapleTable.TH>Condition</MapleTable.TH>
									<MapleTable.TH>Commission Rate</MapleTable.TH>
									<MapleTable.TH></MapleTable.TH>
								</tr>
							</thead>
							<tbody className='divide-y divide-gray-200'>
								{plan.rules.map((rule, index) => (
									<tr key={index}>
										<td>{getDescriptionForRule(rule.rule, rulePropertyMap)}</td>
										<td>{(rule.commission_rate * 100).toFixed(2)}%</td>
										<td>{rule.is_retroactive ? <Label.Warning>Retroactively applies to previous tiers</Label.Warning> : ''}</td>
									</tr>
								))}
							</tbody>
						</MapleTable.Content>
					</MapleTable>
				</ContentBox.Body>
			</ContentBox>
		);
	};

	const renderAccelerators = () => {
		if (!plan.accelerators?.length) return null;

		const getAcceleratorTypeLabel = (type) => {
			const types = {
				ADDITIVE: 'Additive',
				MULTIPLICATIVE: 'Multiplicative',
			};
			return types[type] || type;
		};

		return (
			<ContentBox>
				<ContentBox.Title>
					Accelerators ({getAcceleratorTypeLabel(plan.accelerators[0]?.accelerator_type)})
				</ContentBox.Title>
				<ContentBox.Body>
					<MapleTable>
						<MapleTable.Content>
							<thead>
								<tr>
									<MapleTable.TH>Condition</MapleTable.TH>
									<MapleTable.TH>Rate</MapleTable.TH>
									<MapleTable.TH>Type</MapleTable.TH>
								</tr>
							</thead>
							<tbody className='divide-y divide-gray-200'>
								{plan.accelerators.map((accelerator, index) => (
									<tr key={index}>
										<td>{getDescriptionForRule(accelerator.rule, rulePropertyMap)}</td>
										<td>
											{accelerator.accelerator_type === 'ADDITIVE'
												? `${(accelerator.accelerator_rate * 100).toFixed(2)}%`
												: `${Number(accelerator.accelerator_rate).toFixed(2)}x`}
										</td>
										<td className='capitalize'>{accelerator.accelerator_type.toLowerCase()}</td>
									</tr>
								))}
							</tbody>
						</MapleTable.Content>
					</MapleTable>
				</ContentBox.Body>
			</ContentBox>
		);
	};

	const renderClawbacks = () => {
		if (!plan.clawbacks?.length) return null;

		return (
			<ContentBox>
				<ContentBox.Title>Clawbacks</ContentBox.Title>
				<ContentBox.Body>
					<MapleTable>
						<MapleTable.Content>
							<thead>
							<tr>
								<MapleTable.TH>Condition</MapleTable.TH>
								<MapleTable.TH>Clawback Rate</MapleTable.TH>
								<MapleTable.TH>Affects Quota</MapleTable.TH>
							</tr>
							</thead>
							<tbody className='divide-y divide-gray-200'>
							{plan.clawbacks.map((clawback, index) => (
								<tr key={index}>
									<td>{getDescriptionForRule(clawback.rule, rulePropertyMap)}</td>
									<td>{(clawback.clawback_percentage * 100).toFixed(2)}%</td>
									<td>
										{clawback.affects_quota ? (
											<Label.Info>Yes</Label.Info>
										) : (
											<Label.Secondary>No</Label.Secondary>
										)}
									</td>
								</tr>
							))}
							</tbody>
						</MapleTable.Content>
					</MapleTable>
				</ContentBox.Body>
			</ContentBox>
		)
	}

	return (
		<BaseContainer>
			<ContentContainer title="Commission Plan Details">
				<Loader loading={loading}>
					<div className="space-y-6">
						{renderOverview()}
						{renderRules()}
						{renderAccelerators()}
						{renderClawbacks()}
					</div>
				</Loader>
			</ContentContainer>
		</BaseContainer>
	);
}

export default CommissionPlanNew;
