import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {serverFetch, serverPatch, serverPost} from '../../helpers/server';
import {
    currencyFormatFromPrice,
    BaseContext,
    UserContext,
    getFormattedAddressForAddress,
    getNameForProviderType
} from '../../helpers/common';
import AddOrEditCustomerModal from '../../components/modals/AddOrEditCustomerModal';
import Columns from '../../components/Columns';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import BaseForm from '../../components/BaseForm';
import { Table } from 'react-bootstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import SortableTableHeader from "../../components/SortableTableHeader";
import ConfirmationButton from "../../components/common/buttons/ConfirmationButton";
const _ = require('lodash');

function Migration() {
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [customers, setCustomers] = useState([]);
    const [settings, setSettings] = useState({});
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [initialFields, setInitialFields] = useState({ sort: "createdAtDesc" })
    const [query, setQuery] = useState(null)

    const hasReadPermissions = hasAccess("customers", userInfo, "read");
    const hasWritePermissions = hasAccess("customers", userInfo, "write");

    useEffect(() => {
        setPageTitle(`Customers`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
    }, []);

    const onSearch = (restart = true, overrideKey = null) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: overrideKey || fromKey,
                limit: limit
            },
            query: {
                search: query,
            }
        }
        serverPost(getApiUrl("/customers/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                const filteredResults = _.filter(results, (r) => r.imported_from);
                if (restart) {
                    setCustomers(filteredResults);
                } else {
                    setCustomers(_.concat(customers, filteredResults));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
                if (filteredResults.length === 0 && results.length === limit) {
                    setTimeout(() => {
                        onSearch(false, res.pagination.from_key);
                    }, 30);
                }
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [sort, query]);

    const manageCustomer = async (customer) => {
        const data = {
            managed_externally: false
        };
        await serverPatch(getApiUrl(`/customers/${customer.id}`), data).then((res) => {
            if (res) {
                onSearch(true);
            }
        });
    }

    const manageAllCustomers = () => {
        console.log("Manage all customers");
    }

    const onFieldChange = (name, value) => {
        if (name === "sort") {
            setSort(value);
        } else if (name === "query") {
            setQuery(value);
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={customers.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="Customer Migration" actions={[{
                        variant: "primary",
                        label: "Start Managing Everyone",
                        onClick: () => manageAllCustomers()
                    }]}>
                        <p>Here is a list of imported customers. Each of these customers are either managed by Stripe or
                            Maple. If a customer is managed by Stripe, we will pill in all the changes as they happen
                            in Stripe. If a customer is managed by Maple, any changes from Stripe will not be monitored.
                            Any new customers created in Maple will automatically be managed in Maple.</p>
                        <MapleTable>
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <MapleTable.Header>
                                    <MapleTable.LeftActions>
                                        <BaseForm.TextGroup name="query" icon="search-icon" placeholder="Search Customers" />
                                    </MapleTable.LeftActions>
                                    <MapleTable.MiddleActions />
                                    <MapleTable.RightActions>
                                    </MapleTable.RightActions>
                                </MapleTable.Header>
                            </BaseForm>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <SortableTableHeader
                                            onSortChange={setSort} sortKeyUp="orgAToZ" sortKeyDown="orgZToA"
                                            currentSort={sort}>
                                            Name
                                        </SortableTableHeader>
                                        <th>Managed By</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    _.map(customers, (row, i) =>
                                         <MapleTable.TR key={i} className="cursor-pointer" href={getCompanySpecificUrl(`/customer/${row.id}`)}>
                                            <td>
                                                <Columns.CustomerName customer={row} />
                                            </td>
                                            <td>{ row.managed_externally ? "Stripe": "Maple" }</td>
                                            <td className="text-end">
                                                {
                                                    row.managed_externally &&
                                                        <ConfirmationButton
                                                            body={<>
                                                                <p>Are you sure you want to proceed?</p>
                                                                <span className="caption">Note: Once the customer is being managed in Maple,
                                                                    changes in { getNameForProviderType(row.imported_from) } will not be
                                                                    copied over here. Please make sure all changes are made in Maple going
                                                                    forward.
                                                                </span>
                                                            </>}
                                                            onConfirm={async () => await manageCustomer(row)}
                                                        >
                                                            Start Managing
                                                        </ConfirmationButton>
                                                }
                                            </td>
                                         </MapleTable.TR>
                                    )
                                }
                                {
                                    hasMore &&
                                        <tr>
                                            <td colSpan="10" className="text-center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </Table>
                        </MapleTable>
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Migration;
