import '../../App.scss';
import '../../css/contract.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch, serverPost } from '../../helpers/server';
import {BaseContext, convertVariablesIntoHtml, downloadBlob} from '../../helpers/common';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import ContentContainer from '../../components/ContentContainer';
import Loader from '../../components/Loader';
import ContentBox from '../../components/ContentBox';
import classnames from 'classnames';
import {useParams, Link, useNavigate, useSearchParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import variables from "../../App.scss";
import PoweredByMapleFooter from '../../components/PoweredByMapleFooter';
import Button from "../../components/common/buttons/Button";
import Download02 from "@untitled-ui/icons-react/build/esm/Download02";
import DropdownMenu from "../../components/DropdownMenu";
import DotsVertical from "@untitled-ui/icons-react/build/esm/DotsVertical";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import {Spinner} from "react-bootstrap";
import MapleTable from "../../components/MapleTable";
import Notification from '../../components/Notification';
import contract from "./Contract";
const _ = require('lodash');

function ContractView() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid, sid } = useParams();

    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [contractDetails, setContractDetails] = useState(null);
    const [contractLoading, setContractLoading] = useState(true);
    const [initialFields, setInitialFields] = useState({});
    const [settings, setSettings] = useState({});
    const [contractAttachments, setContractAttachments] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [shouldShowAttachmentsButton, setShouldShowAttachmentsButton] = useState(false);
    const [ searchParams ] = useSearchParams();
    let token = searchParams.get('token');

    useEffect(() => {
        const title = _.isNil(contractDetails) ? "" : contractDetails.title;
        setPageTitle(`Contract - ${title} - Accept`);
    }, [contractDetails]);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            fetchData();
        }
    }, [uuid])

    const downloadContract = () => {
        const filename = `Contract.pdf`;
        serverPost(getApiUrl(`/proposals/${uuid}/preview`), {}, { noJson: true }).then((res) => {
            downloadBlob(res, filename);
        })
    }

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });

        serverFetch(getApiUrl(`/proposals/${uuid}`), { suppressUnauthenticated: true, skipCache: skipCache }).then((res) => {
            setContractLoading(false);
            if (res) {
                if (res.expiration_date) {
                    res.isExpired = moment(res.expiration_date).isBefore(moment());
                }
                setContractDetails(res);
                setInitialFields({
                    owner: res.owner && res.owner.id,
                    expiration_date: res.expiration_date
                })
                setContractAttachments(res.contract_attachments)
            } else {
                setContractDetails(null);
            }
        })
    }

    useEffect(() => {
        const checkIsScreenSmall = () => {
            setShouldShowAttachmentsButton(window.innerWidth <= 1023);
        };

        checkIsScreenSmall();
        window.addEventListener("resize", checkIsScreenSmall);
        return () => {
            window.removeEventListener("resize", checkIsScreenSmall);
        }
    }, []);

    const downloadAttachment = async (attachmentId) => {
        setDownloading(true);
        const res = await serverPost(getApiUrl(`/proposals/${uuid}/preview_attachment/${attachmentId}`), {}, {accessToken: token}, downloadContractErrorHandler);
        if (res) {
            fetch(res.url, {headers: res.headers}).then((res2) => {
                res2.blob().then(value => {
                    downloadBlob(value, res.filename);
                });
            });
        }
        setDownloading(false);
    };

    const downloadContractErrorHandler = (res) => {
        if (res.status === 401 || res.status === 403) {
            Notification.Danger("Download link has expired. Please request a new link from the sender.", 5000);
        } else {
            Notification.Danger("Document cannot be currently downloaded. Please try again later.", 5000);
        }
    };

    const scrollToAttachments = () => {
        const attachmentsSection = document.getElementById('attachments-section');
        if (attachmentsSection) {
            attachmentsSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const renderExpirationDate = () => {
        if (_.isNil(contractDetails.expiration_date)) {
            return null;
        }
        const daysFromExpiry = moment(contractDetails.expiration_date).diff(moment(), "days");
        let color = "success-color";
        if (daysFromExpiry <= 0) {
            color = "danger-color";
        } else if (daysFromExpiry <= 3) {
            color = "warning-color";
        }
        return (
            <div className="self-start sm:self-center">
            {
                contractDetails.expiration_date &&
                    <div className={classnames("body2", color)}>Expires { moment(contractDetails.expiration_date).format("DD MMM h:mma") }</div>
            }
            </div>
        )
    }

    const onActionSelected = (type) => {
        if (type === "pdf") {
            serverPost(getApiUrl(`/proposals/${contractDetails.id}/preview`), {}, { noJson: true, accessToken: token }, downloadContractErrorHandler).then((res) => {
				if (res) {
					downloadBlob(res, `Contract-${contractDetails.title}`);
				}
            })
        }
    }

    const actionOptions = [
        { id: "pdf", label: "Download" },
    ];

    const renderContract = () => {
		const parts = _.filter([
			_.isNil(contractDetails.owner) ? null : contractDetails.owner.user.name || contractDetails.owner.user.email,
			company.name,
		]).join(', ');
		const hasAttachments = !_.isEmpty(contractDetails.contract_attachments);

		const renderHeader = () => {
			return (
				<div className='top-0 flex border-b border-gray2 w-full bg-white z-50 px-4 py-[12px] justify-center'>
					<div className='flex w-[calc(min(100%,1000px))]'>
						<div className='flex flex-col sm:flex-row w-full gap-2'>
							<div className="flex flex-col justify-between w-full">
								<h3 className='text-dark-gray text-base sm:text-lg font-semibold'>{contractDetails.title}</h3>
								<span className='text-sm sm:text-base text-[#414651]'>From {parts}</span>
							</div>
							{renderExpirationDate()}
						</div>
						{
							<div className='flex flex-grow-0 align-self-center gap-[12px] ml-3'>
								<Button
									variant='text-outline'
									className='flex items-center'
									onClick={() => onActionSelected('pdf')}
								>
									<Download02 width={20} height={20} className='inline' />
								</Button>
								{!_.isEmpty(contractAttachments) && shouldShowAttachmentsButton && (
									<Button
										variant='primary'
										className='flex items-center'
										onClick={() => scrollToAttachments()}
									>
										<span className='flex items-center gap-1'>
											<i className={'fa fa fa-paperclip'} />{' '}
											{`Attachments (${contractAttachments.length})`}
										</span>
									</Button>
								)}
							</div>
						}
					</div>
				</div>
			);
		};
		const renderFooter = () => {
			return (
				<div className='bg-[#FAF8F4] bottom-0 flex pb-3 items-center justify-center relative'>
					<PoweredByMapleFooter />
				</div>
			);
		};
		return (
			<div className='h-full flex flex-col'>
				{renderHeader()}
				<div className={`contract-layout ${hasAttachments ? 'with-attachments' : ''}`}>
					<div className='contract-main'>
						<div id='contract-container' className='contract-container shadow-contract'>
								<div
									className={`contract-content max-h-[580px] ${hasAttachments ? 'with-attachments' : ''} mx-2`}
									dangerouslySetInnerHTML={{
										__html: convertVariablesIntoHtml(
											contractDetails.content,
											contractDetails.counter_signatories,
										),
									}}
								/>
						</div>
					</div>
					{hasAttachments && (
						<div id='attachments-section' className='attachments-section'>
							<div className='attachments-container shadow-contract'>
								<h3 className='text-dark-gray text-lg font-semibold mb-3'>
									<i className={'fa fa fa-paperclip'} /> Attachments
								</h3>
								<MapleTable>
									<MapleTable.Content>
										<tbody className={''}>
											{_.map(contractDetails.contract_attachments, (ufile, i) => (
												<tr
													key={i}
													onClick={async () => {
														setDownloading(true);
														await downloadAttachment(ufile.id);
													}}
													className={'cursor-pointer hover:bg-gray-50'}
												>
													<td>{ufile.original_filename}</td>
													<td className='text-end'>
														<div className='flex flex-row gap-2 justify-end items-center'>
															{downloading ? (
																<Spinner
																	as='span'
																	animation='border'
																	size='sm'
																	role='status'
																	aria-hidden='true'
																/>
															) : (
																<ArrowDownTrayIcon className='cursor-pointer h-4 w-4' />
															)}
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</MapleTable.Content>
								</MapleTable>
							</div>
						</div>
					)}
				</div>
				{renderFooter()}
			</div>
		);
	};

	const renderExpired = () => {
		return (
			<div className='d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3'>
				<i className='fa fa-thin fa-file-contract danger-color fa-4x' />
				<h3 className='gray4'>Contract expired</h3>
				<div className='gray3 body1 text-center' style={{ maxWidth: '400px' }}>
					Oops, this contract has expired. Please reach out to the person who sent you the contract for
					further details.
				</div>
			</div>
		);
	};

	const renderNotFound = () => {
		return (
			<div className='d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3'>
				<i className='fa fa-thin fa-file-contract danger-color fa-8x' />
				<h3 className='gray4'>Contract not found</h3>
				<div className='gray3 body1 text-center' style={{ maxWidth: '400px' }}>
					Oops, we can't find the contract you are looking for. Please reach out to the person who sent you
					the contract.
				</div>
			</div>
		);
	};

	const renderComplete = () => {
		let brandColor = variables.primaryColor;
		if (settings.branding) {
			brandColor = settings.branding.color || variables.primaryColor;
		}
		return (
			<div className='d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3'>
				<i className='fa fa-thin fa-file-contract fa-6x' style={{ color: brandColor }} />
				<h3 className='gray4'>{contractDetails.title}</h3>
				<div className='body1 gray4'>Contract has been signed by all participants</div>
				<div className='body1 gray3'>Please reach out to the sender for a copy of the contract.</div>
				<div className='body1 gray3'>
					If you are a signatory of this contract, a PDF copy of the contract will be emailed to you once it
					is fully executed.
				</div>
			</div>
		);
	};

    return (
		<>
			<Notification/>
			<ContentContainer className="full" bodyClassName="full">
				<Loader loading={contractLoading}>
					{
						() => {
							if (_.isNil(contractDetails)) {
								return renderNotFound();
							} else if (!_.isNil(contractDetails.signed_date) || contractDetails.signed_offline) {
								return renderComplete();
							} else if (contractDetails.isExpired) {
								return renderExpired();
							} else {
								return renderContract();
							}
						}
					}
				</Loader>
			</ContentContainer>
		</>
    )
}

export default ContractView;