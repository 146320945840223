import '../../App.scss';
import React, {useState, useEffect, useContext, useMemo, createRef} from 'react';
import BaseContainerWithoutNav from '../../components/BaseContainerWithoutNav';
import { useTranslation } from 'react-i18next';
import {BaseContext, hasAccessForCompany, UserContext} from "../../helpers/common";
import BaseForm from "../../components/BaseForm";
import SubmitButton from "../../components/common/buttons/SubmitButton";
import Notification from "../../components/Notification";
import {UserIcon} from "@heroicons/react/24/solid";
import {baseGetApiUrl, getUrl, serverPatch} from "../../helpers/server";
import Link from "../../components/Link";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";
const _ = require('lodash');

function UserProfile() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const [passwordErrorFields, setPasswordErrorFields] = useState(null)
    const initialUserFields = useMemo(() => {
        return {...userInfo};
    }, [userInfo])
    const passwordChangeFormRef = createRef();

    const onUpdateUser = (data) => {
        serverPatch(getUrl(`/users/${userInfo.id}`), data).then(res => {
            if (res) {
                Notification.Success("User information updated");
            }
        });
    }

    const onUpdatePassword = (data, defaultErrorHandler) => {
        if (data.password !== data.confirm_password) {
            setPasswordErrorFields({
                confirm_password: "Passwords don't match",
            })
            return;
        }
        setPasswordErrorFields(null);
        const updateData = {
            password: data.password
        }
        serverPatch(getUrl(`/users/${userInfo.id}`), updateData, {}, defaultErrorHandler).then(res => {
            if (res) {
                if (passwordChangeFormRef.current) {
                    passwordChangeFormRef.current.onResetFields({
                        password: "",
                        confirm_password: ""
                    })
                }
                Notification.Success("Password changed successfully");
            }
        });
    }

    const updateProfilePhoto = (companyUser, data, defaultErrorHandler) => {
        serverPatch(getUrl(`/companies/${companyUser.company_id}/users/${companyUser.id}`), data, {}, defaultErrorHandler).then(res => {
            if (res) {
                Notification.Success("Profile photo changed successfully");
            }
        });
    }

    return (
        <BaseContainerWithoutNav>
            <Link className="px-4 py-2 flex flex-row gap-1 text-sm" onClick={() => navigate(-1)}>
                <ArrowLeftIcon className="h-5 w-5"/> Back
            </Link>
            <main>
                <div className="divide-y divide-gray-200">
                    <div className="text-xl font-semibold leading-6 text-gray-900 px-4 py-4 sm:px-6 lg:px-8 flex flex-row gap-1 items-center">
                        <UserIcon className="w-7 h-7"/> User Profile
                    </div>
                    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-8 sm:px-6 md:grid-cols-3 lg:px-8">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                Your personal information that is shared between all your companies.
                            </p>
                        </div>

                        <div className="md:col-span-2">
                            <BaseForm initialFormFields={initialUserFields} onSubmit={onUpdateUser}>
                                <BaseForm.Input colSpan="6" type="text" name="name" label="Name"/>
                                <BaseForm.Input colSpan="6" type="text" name="email" label="Email" disabled/>
                                <br/>
                                <SubmitButton>Update</SubmitButton>
                            </BaseForm>
                        </div>
                    </div>

                    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-8 sm:px-6 md:grid-cols-3 lg:px-8">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Change password</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-500">
                                Update your password associated with your account.
                            </p>
                        </div>

                        <div className="md:col-span-2">
                            <BaseForm ref={passwordChangeFormRef} onSubmit={onUpdatePassword} errorFields={passwordErrorFields}>
                                <BaseForm.Input colSpan="6" type="password" name="password" label="New Password" validations={{ required: true, minLength: 8 }} />
                                <BaseForm.Input colSpan="6" type="password" name="confirm_password" label="Confirm Password" validations={{ required: true, minLength: 8 }} />
                                <br/>
                                <SubmitButton>Update</SubmitButton>
                            </BaseForm>
                        </div>
                    </div>

                    {
                        _.map(userInfo.companies, (companyUser, i) =>
                            <div key={i} className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-8 sm:px-6 md:grid-cols-3 lg:px-8">
                                <div>
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Profile Photo</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-500">
                                        Update your profile photo associated with { companyUser.company_name }.
                                    </p>
                                </div>

                                <div className="md:col-span-2">
                                    <BaseContext.Provider value={{
                                        isLoggedIn: true,
                                        getApiUrl: (...args) => baseGetApiUrl(companyUser.company_id, ...args),
                                        hasAccess: (...args) => hasAccessForCompany(companyUser.company_id, ...args),
                                    }}>
                                        <BaseForm initialFormFields={companyUser} onSubmit={(data, errorHandler) => updateProfilePhoto(companyUser, data, errorHandler)} errorFields={passwordErrorFields}>
                                            <BaseForm.Input
                                                colSpan="6" type="file" name="image_url" label="Profile Photo"
                                                fileType="profilePhoto"
                                            />
                                            <SubmitButton>Update</SubmitButton>
                                        </BaseForm>
                                    </BaseContext.Provider>
                                </div>
                            </div>
                        )
                    }
                </div>
            </main>
        </BaseContainerWithoutNav>
    );
}

export default UserProfile;