import '../../App.scss';
import React, {useEffect, useState, useContext, createRef, useRef} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { serverFetch, serverPost } from '../../helpers/server';
import {
    BaseContext,
    getCustomerNameOrEmail,
    getInvoicePaymentTermFromDueDateFromCreation,
    getInvoicePaymentTermOptions,
    getPaymentMethodDescription,
    getPlanLengthOptions,
    isAddressEmpty,
    removeHtmlTags,
    updateFormField,
    UserContext,
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import BaseForm from '../../components/BaseForm';
import ContentContainer from '../../components/ContentContainer';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import ProductPricingSelection from "../../components/ProductPricingSelection";
import Loader from "../../components/Loader";
import ContentBox from "../../components/ContentBox";
import CustomerInput from "../../components/CustomerInput";
import Link from "../../components/Link";
import classnames from 'classnames';
import AddOrEditProductPricingModal2 from "../../components/modals/AddOrEditProductPricingModal2";
import AddOrEditCustomerModal from "../../components/modals/AddOrEditCustomerModal";
import Alert from "../../components/common/Alert";
import Button from '../../components/common/buttons/Button';
const _ = require('lodash');

function SubscriptionCreate(props) {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { getApiUrl, getCompanySpecificUrl, company, setPageTitle } = useContext(BaseContext);
    const {isMapleUser} = useContext(UserContext);
    const [ searchParams ] = useSearchParams();
    let defaultCustomerId = searchParams.get('customer');
    let dealID = searchParams.get('did');
    let integrationID = searchParams.get('iid');

    const [showAdvanced, setShowAdvanced] = useState(false);
    const [productPricings, setProductPricings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [coupons, setCoupons] = useState([]);
    const [settings, setSettings] = useState({});
    const [error, setError] = useState(null);
    const [isInPast, setIsInPast] = useState(false);
    const [defaultCustomer, setDefaultCustomer] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [autoCharges, setAutoCharges] = useState(true);
    const [invoicePaymentTerms, setInvoicePaymentTerms] = useState(null);
    const [prefetchDealDetails, setPrefetchDealDetails] = useState(null);
    const [showTrialDaysInput, setShowTrialDaysInput] = useState(false);
    const [initialFields, setInitialFields] = useState({
        start_date: moment(),
        term: {
            count: 1,
            frequency: "YEAR"
        },
        payment_method_id: null,
        phases: [{}],
    });
    const [subscriptionPaymentSettingEnabled, setSubscriptionPaymentSettingEnabled] = useState(false);
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([{
        value: null,
        label: "Use default customer payment method"
    }]);
    const [showAddProductPricingModal, setShowAddProductPricingModal] = useState(false);
    const [showAddOrEditCustomerModal, setShowAddOrEditCustomerModal] = useState(false);
    const [showCustomerAddressError, setShowCustomerAddressError] = useState(false);
    const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
    const [selectedBilledCustomer, setSelectedBilledCustomer] = useState(null);
    const [isParentBilled, setIsParentBilled] = useState(false);
    const [addressEditCustomer, setAddressEditCustomer] = useState(null);
    const [customAddressErrorMessage, setCustomAddressErrorMessage] = useState(null);

    const pricingsRef = useRef();
    const subscriptionRef = useRef();

    useEffect(() => {
        setPageTitle(`Subscription - Create`);
    }, []);

    useEffect(() => {
        if (defaultCustomerId) {
            serverFetch(getApiUrl(`/customers/${defaultCustomerId}`), { skipCache: true }).then((res) => {
                setDefaultCustomer(res);
                setSelectedCustomer(res);
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.customer = res;
                    return newFields;
                });
            });
            serverFetch(getApiUrl(`/customers/${defaultCustomerId}/payment_methods`), {}).then((res) => {
                if (res) {
                    setPaymentMethods(res.methods);
                }
            });
        }
    }, [defaultCustomerId])

    useEffect(() => {
        if (dealID && integrationID) {
            serverFetch(getApiUrl(`/proposals/prefetch/${integrationID}/${dealID}`)).then((res) => {
                if (res) {
                    setPrefetchDealDetails(res);
                }

                if (res.deal) {
                    const ppIds = _.map(res.deal.product_pricings, pp => pp.product_pricing_id)
                    serverPost(getApiUrl(`/product_pricings/batch`), {ids: ppIds}).then((res) => {
                        if (res) {
                            setProductPricings(res)
                        }
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                }
            })
        } else {
            const limit = 100
            const params = {
                company_id: company.id,
                sort_key: "createdAtDesc",
                pagination: {limit: limit},
            }
            serverPost(getApiUrl("/product_pricings/find"), params).then(async (res) => {
                if (res) {
                    const results = res.results || [];
                    setProductPricings(results);
                }
                setLoading(false);
            });
        }
    }, [dealID, integrationID]);

    useEffect(() => {
        if (prefetchDealDetails) {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                if (prefetchDealDetails.customer) {
                    newFields.customer = prefetchDealDetails.customer;
                }
                if (prefetchDealDetails.deal) {
                    if (prefetchDealDetails.deal.product_pricings) {
                        const prefetchConfig = {};
                        const prefetchDiscounts = {};
                        _.each(prefetchDealDetails.deal.product_pricings, (dealPP) => {
                            const pp = _.find(productPricings, p => p.id === dealPP.product_pricing_id);
                            if (pp) {
                                let percentageDiscount = null;
                                if (dealPP.discounts && dealPP.discounts.length > 0) {
                                    if (dealPP.discounts[0].type === "DISCOUNT_PERCENT") {
                                        percentageDiscount = dealPP.discounts[0].percent * 100
                                    }
                                }
                                _.each(pp.product_metric_pricings, (pmp) => {
                                    prefetchConfig[pmp.id] = {
                                        num_licenses: dealPP.num_licenses
                                    }
                                    if (percentageDiscount) {
                                        prefetchDiscounts[pmp.item_pricing_id] = {
                                            percentage: percentageDiscount
                                        }
                                    }
                                })
                            }
                        })
                        newFields.phases = [{
                            product_pricing_ids: _.map(prefetchDealDetails.deal.product_pricings, pp => pp.product_pricing_id),
                            config: prefetchConfig,
                            discounts: prefetchDiscounts,
                        }]
                        if (pricingsRef.current) {
                            pricingsRef.current.setInitialData(newFields);
                        }
                    }
                }
                return newFields;
            });
        }
    }, [prefetchDealDetails, productPricings])

    const setPaymentMethods = (paymentMethods) => {
        const options = _.map(paymentMethods, (pm) => {
            return {
                value: pm.id,
                label: getPaymentMethodDescription(t, pm)
            }
        })
        options.unshift({
            value: null,
            label: "Use default customer payment method"
        })
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields.payment_method_id = null;
            return newFields;
        });
        setPaymentMethodOptions(options)
    }

    useEffect(() => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            newFields.start_date = moment();
            return newFields;
        });

        serverPost(getApiUrl("/coupons/find"), { companyId: company.id }).then((res) => {
            setCoupons(res);
        });
    }, [company, getApiUrl])

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
            if (_.isNil(res.payment_config)) {
                setAutoCharges(false);
                setInvoicePaymentTerms(getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation));
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.auto_charges = false;
                    newFields.invoice_payment_terms = getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation);
                    newFields.invoice_due_date_from_creation = res.invoice_due_date_from_creation;
                    newFields.invoice_event_grace_period = res.invoice_event_grace_period;
                    return newFields;
                });
            } else {
                setAutoCharges(res.auto_charges);
                let invoicePaymentTerms = getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation);
                setInvoicePaymentTerms(invoicePaymentTerms)
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.auto_charges = res.auto_charges;
                    newFields.invoice_payment_terms = invoicePaymentTerms;
                    newFields.invoice_due_date_from_creation = res.invoice_due_date_from_creation;
                    newFields.invoice_event_grace_period = res.invoice_event_grace_period;
                    return newFields;
                });
            }
        });
    }, []);

    useEffect(() => {
        setCustomAddressErrorMessage(null);
        if (selectedBilledCustomer && !_.isEmpty(settings)) {
            if (settings.tax_calc_enabled && isAddressEmpty(selectedBilledCustomer.address)) {
                const customer = selectedBilledCustomer;

                if (selectedBilledCustomer.integration_id) {
                    customer['import_id'] = selectedBilledCustomer.id;
                    customer.id = null;

                    if (selectedBilledCustomer.maple_customer_id) {
                        customer.id = selectedBilledCustomer.maple_customer_id;
                    }
                    if (selectedBilledCustomer.integration_address_error) {
                        setCustomAddressErrorMessage("Integration customer address is invalid and is required for tax calculation. This can be fixed now or after the subscription is created.")
                    }
                }
                setAddressEditCustomer(customer)
                setShowCustomerAddressError(true);
            } else {
                setAddressEditCustomer(null)
                setShowCustomerAddressError(false);
            }
        } else {
            setAddressEditCustomer(null)
            setShowCustomerAddressError(false);
        }
    }, [selectedBilledCustomer, settings]);

    const onFieldChange = async (name, value) => {
        if (name === "start_date") {
            setIsInPast(moment(value).utc().isBefore(moment().utc().startOf('day')));
        } else if (name === "invoice_payment_terms") {
            setInvoicePaymentTerms(value);
        } else if (name === "customer") {
            if (value.__new__) {
                setShowAddOrEditCustomerModal(true);
            } else {
                setSelectedCustomer(value);
                const res = await serverFetch(getApiUrl(`/customers/${value.id}/payment_methods`), {})
                if (res) {
                    setPaymentMethods(res.methods);
                } else {
                    setPaymentMethods([]);
                }
                if (value.parent_customer && isParentBilled) {
                    setSelectedBilledCustomer(value.parent_customer);
                } else {
                    setSelectedBilledCustomer(value)
                }
            }
        } else if (name === "auto_charges") {
            setAutoCharges(value)
        } else if (name === "invoice_payment_instructions_enabled") {
            setSubscriptionPaymentSettingEnabled(value)
        } else if (name === "parent_billed") {
            if (value === true) {
                setSelectedBilledCustomer(selectedCustomer.parent_customer);
            } else {
                setSelectedBilledCustomer(selectedCustomer);
            }
            setIsParentBilled(value)
        }
        pricingsRef.current?.onFieldChange(name, value);
        setInitialFields(prevFields => {
            return updateFormField(prevFields, name, value);
        })
    }

    const onCustomerAdded = async (customer) => {
        setSelectedCustomer(customer);
        const res = await serverFetch(getApiUrl(`/customers/${customer.id}/payment_methods`), {})
        if (res) {
            setPaymentMethods(res.methods);
        } else {
            setPaymentMethods([]);
        }
        setInitialFields(prevFields => {
            return updateFormField(prevFields, "customer", customer);
        })
    }

    const onBillingCustomerUpdated = async (customer) => {
        setSelectedBilledCustomer(customer);
        if (customer.id === selectedBilledCustomer.id) {
            if (customer.id === selectedCustomer.id) {
                await onCustomerAdded(customer)
            } else {
                setSelectedCustomer(prev => {
                    return {
                        ...prev,
                        parent_customer: customer,
                    }
                })
            }
        } else {
            await onCustomerAdded(customer);
        }
    }

    const toggleShowAdvanced = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setShowAdvanced(!showAdvanced);
    }

    const onModalClose = () => {
        setShowAddProductPricingModal(false);
        setShowAddOrEditCustomerModal(false);
    }

    const onProductPricingSelectionChange = (phase, selectedProductPricings) => {
        if (selectedProductPricings && selectedProductPricings.length === 1 && selectedProductPricings[0].product_metric_pricings.length === 1) {
            const itemPricing = selectedProductPricings[0].product_metric_pricings[0].item_pricing;
            if (itemPricing.type === "SCHEDULED") {
                setInitialFields(prevFields => {
                    const newFields = {...prevFields};
                    newFields.term = {
                        count: itemPricing.term_count,
                        frequency: itemPricing.frequency
                    }
                    return newFields;
                })
            }
        }
    }

    const onProductPricingCreated = (productPricing) => {
        if (pricingsRef.current) {
            pricingsRef.current.onProductPricingCreated(productPricing)
        }
    }

    const addSubscription = async (subscriptionFields, defaultErrorHandler) => {
        let pricingData = {};
        if (pricingsRef.current) {
            const validationResult = pricingsRef.current.validate(subscriptionFields);
            if (!_.isNil(validationResult)) {
                setError(validationResult)
                return;
            }

            pricingData = pricingsRef.current.getPricingSelectionFields(subscriptionFields);
        }
        setError(null);
        const startOfToday = moment().startOf('day');
        const startOfStartDate = moment(subscriptionFields.start_date).startOf('day');
        const isToday = startOfToday.isSame(startOfStartDate)
        const startDate = isToday ? moment(): moment(subscriptionFields.start_date)
        let billingAnchorDate = subscriptionFields.billing_anchor_date;
        if (billingAnchorDate) {
            billingAnchorDate = moment(billingAnchorDate).hour(startDate.hour()).minute(startDate.minute()).second(startDate.second()).format();
        }
        const isTrialEnabled = subscriptionFields.num_trial_days > 0;
        const data = {
            ...pricingData,
            company_id: subscriptionFields.company_id,
            auto_renews: _.isEmpty(subscriptionFields.end_date),
            auto_charges: subscriptionFields.auto_charges,
            term: {
                frequency: subscriptionFields.term.frequency,
                count: parseInt(subscriptionFields.term.count)
            },
            start_date: startDate.format(),
            billing_anchor_date: billingAnchorDate,
            end_date: _.isEmpty(subscriptionFields.end_date) ? null : subscriptionFields.end_date,
            renewal_date: _.isEmpty(subscriptionFields.renewal_date) ? null : subscriptionFields.renewal_date,
            invoice_due_date_from_creation: subscriptionFields.invoice_due_date_from_creation,
            generate_past_invoice: subscriptionFields.generate_past_invoice,
            trial: isTrialEnabled,
            invoice_event_grace_period: subscriptionFields.invoice_event_grace_period || 0,
        }
        if (isTrialEnabled) {
            data['trial_term'] = {
                frequency: 'DAY',
                count: subscriptionFields.num_trial_days,
            }
        }
        if (subscriptionFields.customer.integration_id) {
            data['integration_customer'] = {
                import_id: subscriptionFields.customer.id,
                integration_id: subscriptionFields.customer.integration_id,
                integration_object_type: subscriptionFields.customer.integration_object_type
            }
        } else {
            data['customer_id'] = subscriptionFields.customer.id;
            data['parent_billed'] = subscriptionFields.parent_billed;
        }
        if (!_.isNil(subscriptionFields.invoice_payment_terms)) {
            if (subscriptionFields.invoice_payment_terms !== "custom") {
                data.invoice_due_date_from_creation = parseInt(subscriptionFields.invoice_payment_terms);
            }
        }
        if (subscriptionFields.payment_method_id) {
            data['payment_method_id'] = subscriptionFields.payment_method_id
        }
        if (subscriptionFields.invoice_payment_instructions_enabled) {
            data['invoice_payment_instructions'] = removeHtmlTags(subscriptionFields.invoice_payment_instructions).trim().length ? subscriptionFields.invoice_payment_instructions : "";
        }
        const subscriptionResult = await serverPost(getApiUrl('/subscriptions'), data, {}, defaultErrorHandler);
        if (subscriptionResult) {
            navigate(getCompanySpecificUrl(`/subscription/${subscriptionResult.id}`), { replace: true })
        }
    }

    const paymentMechanismOptions = [
        { value: true, label: "Automatically charge a payment method on file",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: false, label: "Invoice the customer" },
    ]

    const parentBilledOptions = [
        selectedCustomer && selectedCustomer.parent_customer && { value: true, label: `Parent - ${getCustomerNameOrEmail(selectedCustomer.parent_customer)}` },
        selectedCustomer && { value: false, label: `Customer - ${getCustomerNameOrEmail(selectedCustomer)}` },
    ]

    return (
        <BaseContainer>
            <ContentContainer
                title="New Subscription"
                variant="page"
                customActions={
                    <Button onClick={async () => {
                        if (subscriptionRef.current) {
                            await subscriptionRef.current.submitForm();
                        }
                    }}>
                        Create
                    </Button>
                }
                enableStickyHeader={isMapleUser}
            >
                <Loader loading={loading || (defaultCustomerId && !defaultCustomer)}>
                {
                    _.isEmpty(productPricings) &&
                    <div className="d-flex flex-column">
                        <div className="align-items-center justify-content-center text-center" style={{ marginTop: "30px" }}>
                            <Row>
                                <Col md={{ span: 8, offset: 2 }}>
                                    <div className="body2">Hi there!</div>
                                    <br/>
                                    <p>Before we create a subscription, we will need to create at least one pricing for a
                                        product. You can get <Link href={getCompanySpecificUrl("/products")}>started here</Link></p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                {
                    !_.isEmpty(productPricings) &&
                    <div>
                        <BaseForm ref={subscriptionRef} initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={addSubscription}>
                            {showCustomerAddressError &&
                                <Alert variant={'warning'} showActionButton={true} buttonVariant={'text-primary'} actionButtonLabel={'Fix Now'} onActionButtonClick={() => setShowEditCustomerModal(true)}>
                                    <div>
                                        <span className={'font-normal text-sm'}>
                                            {customAddressErrorMessage || "Customer address is required for tax calculation. This can be fixed now or after the subscription is created."}
                                        </span>
                                    </div>
                                </Alert>
                            }
                            {
                                error &&
                                <div className="form-error-message flex justify-end">{ error }</div>
                            }
                            {
                                isInPast &&
                                <Alert variant="warning p-3">
                                    <div className="d-flex flex-row gap-3">
                                        <div className="flex-grow-1">
                                            <p className="text-sm font-semibold">Subscription in the past</p>
                                            <div className="body1">This subscription starts in the past. Would you like to create an invoice for the start of the subscription?</div>
                                        </div>
                                        <div className="flex-shrink-0 d-flex align-items-center">
                                            <BaseForm.Input formClassName="inline" name="generate_past_invoice"
                                                            type="switch" label="Generate Invoice" />
                                        </div>
                                    </div>
                                </Alert>
                            }
                            <ContentBox>
                                <ContentBox.Title>Subscription Details</ContentBox.Title>
                                <ContentBox.Body>
                                    <Row>
                                        <CustomerInput
                                            colSpan="6" defaultCustomer={defaultCustomer} allowLeads required allowCreation />
                                        <BaseForm.Input colSpan="6" name="start_date" label={t('subscriptions.start_date') + " (local timezone)"} type="date"
                                                        includeTime={true} required />
                                    </Row>
                                    <Row>
                                        {
                                            selectedCustomer && selectedCustomer.parent_customer_id &&
                                                <BaseForm.Input
                                                    colSpan="6" name="parent_billed" label={"Bill To"}
                                                    type="select" options={parentBilledOptions} showSearch={false} />
                                        }
                                        <Link onClick={toggleShowAdvanced}>Advanced Options</Link>
                                    </Row>
                                    <Row className={classnames(showAdvanced ? "": "hide", "")}>
                                        <Col md="12">
                                            <div className="bg-gray-100 py-2 px-3 rounded-md">
                                            <Row>
                                                <BaseForm.Input
                                                    colSpan="6" name="billing_anchor_date" label={t('subscriptions.billing_anchor_date') + " (optional, local timezone)"}
                                                    type="date" />
                                                <BaseForm.Input colSpan="6" name="end_date" label={t('subscriptions.end_date') + " (optional, local timezone)"} type="date" minDate={moment()} includeTime />
                                                <BaseForm.Input colSpan="6" name="auto_charges" label={t('subscriptions.payment_mechanism')} type="select"
                                                                options={paymentMechanismOptions} showSearch={false} />
                                                <BaseForm.Input colSpan="6" type="select" name="payment_method_id" label={"Payment Method"} options={paymentMethodOptions} showSearch={false}/>
                                                <BaseForm.Input type="select" colSpan="3" name="invoice_payment_terms" label="Invoice Payment Terms" options={getInvoicePaymentTermOptions()} showSearch={false} />
                                                {
                                                    invoicePaymentTerms === "custom" &&
                                                    <BaseForm.Input type="number" colSpan="3" name="invoice_due_date_from_creation" label="Due Date (in days)" step="1" min="0" />
                                                }
                                            </Row>
                                            <Row>
                                                <BaseForm.Input
                                                    colSpan="6"
                                                    type="number" name="invoice_event_grace_period" label={"Invoice Grace Period (in seconds, optional)"}
                                                    description="How much delay should be applied before each invoice is generated for the period."
                                                    step={1} min={0}
                                                />
                                            </Row>
                                            <Row className="flex-col mt-2">
                                                <BaseForm.Input
                                                    colSpan="9"
                                                    type="switch"
                                                    label="Customize Payment Instructions"
                                                    name="invoice_payment_instructions_enabled"
                                                    required
                                                    description={
                                                        <span>
                                                            These instructions will override the payment instructions set at the company-level for all
                                                            invoices related to this subscription.
                                                        </span>
                                                    }
                                                />
                                                {subscriptionPaymentSettingEnabled && (
                                                    <BaseForm.Input
                                                        colSpan="9"
                                                        name="invoice_payment_instructions"
                                                        errorLabel="Payment instructions content"
                                                        type="editor"
                                                        height="300"
                                                    />
                                                )}
                                            </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <>
                                            <Col md="8">
                                                <Row className="gx-2">
                                                    <Col md="6">
                                                        <BaseForm.InputGroup label="Term">
                                                            <BaseForm.Number name="term.count" label="Term" hideLabel  min="1" validations={{ required: true, min: 1 }}/>
                                                            <BaseForm.Divider />
                                                            <BaseForm.SingleSelect
                                                                name="term.frequency" options={getPlanLengthOptions()} showSearch={false} fullWidth
                                                            />
                                                        </BaseForm.InputGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="4">
                                                <Row>
                                                    <Col md={{ offset: 5, span: 7 }}>
                                                        {showTrialDaysInput || props.contractDetails?.trial ? (
                                                            <BaseForm.Input type="number" name="num_trial_days" label="Free Trial Days" step="1" min="0" validations={{ min: 0 }} defaultValue={0} placeholder="0"/>
                                                        ) : (
                                                            <div className="flex justify-end mt-[25px]">
                                                                <Button variant="text-outline" onClick={() => setShowTrialDaysInput(true)}>Add Free Trial Days</Button>
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </>
                                    </Row>
                                    <ProductPricingSelection
                                        ref={pricingsRef} productPricings={productPricings} coupons={coupons} requireRecurring
                                        initialFields={initialFields} setInitialFields={setInitialFields}
                                        allowDiscounts={true} allowMinimumSpend allowCreation allowPhases
                                        onSelectionChange={onProductPricingSelectionChange}
                                        onCreateProductPricing={() => setShowAddProductPricingModal(true)}
                                        updated
                                    />
                                </ContentBox.Body>
                            </ContentBox>
                        </BaseForm>
                    </div>
                }
                </Loader>
                <AddOrEditProductPricingModal2
                    show={showAddProductPricingModal}
                    onProductPricingCreated={onProductPricingCreated}
                    onClose={onModalClose}
                />
                <AddOrEditCustomerModal
                    show={showAddOrEditCustomerModal}
                    onAdded={onCustomerAdded}
                    onClose={onModalClose}
                />
                <AddOrEditCustomerModal
                    show={showEditCustomerModal}
                    itemToEdit={!addressEditCustomer?.id ? null : addressEditCustomer}
                    onAdded={onBillingCustomerUpdated}
                    onUpdated={onBillingCustomerUpdated}
                    initialFormFields={!addressEditCustomer?.id ? addressEditCustomer : null}
                    onClose={(updated) => {
                        setShowEditCustomerModal(false);
                    }}
                    fixTaxAddress
                />
            </ContentContainer>
        </BaseContainer>
    );
}

export default SubscriptionCreate;
