import {useNavigate, useParams} from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import {
    BaseContext,
    currencyFormatFromPrice,
    getCommissionAdjustmentReason,
    getDescriptionForRule
} from '../../helpers/common';
import {serverFetch, serverPost} from '../../helpers/server';
import moment from 'moment';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import Link from "../../components/Link";
import ContentBox from "../../components/ContentBox";
import KeyValueDisplay2 from "../../components/KeyValueDisplay2";
import {Button} from "react-bootstrap";
import MapleTable from "../../components/MapleTable";
import Label from "../../components/Label";
import _ from "lodash";

function CommissionEventLog() {
    const { id } = useParams();
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [eventLog, setEventLog] = useState({});
    const [appliedRules, setAppliedRules] = useState(null);
    const [appliedAccelerators, setAppliedAccelerators] = useState(null);
    const [appliedClawbacks, setAppliedClawbacks] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setPageTitle('Commission Event Log Details');
    }, []);

    const fetchEventLogData = () => {
        serverFetch(getApiUrl(`/commission/event_log/${id}`)).then((res) => {
            if (res) {
                setEventLog(res);
                if (res.calculation_result.rule_calculations && res.calculation_result.rule_calculations.length > 0) {
                    const appliedRules = _.map(res.calculation_result.rule_calculations, (ruleCalc) => {
                        return ruleCalc.applied_rule
                    })
                    fetchAppliedRules(appliedRules)
                }
                if (res.calculation_result.applied_accelerators && res.calculation_result.applied_accelerators.length > 0) {
                    fetchAppliedAccelerators(res.calculation_result.applied_accelerators)
                }
                if (res.calculation_result.applied_clawbacks && res.calculation_result.applied_clawbacks.length > 0) {
                    fetchAppliedClawbacks(res.calculation_result.applied_clawbacks)
                }
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        fetchEventLogData();
    }, [id]);

    const fetchAppliedRules = (ruleIds) => {
        const params = {
            query: {
                rule_ids: {
                    condition: "OR",
                    values: ruleIds
                },
            },
        };
        serverPost(getApiUrl(`/commission/rule/find`), params).then((res) => {
            if (res) {
                setAppliedRules(res);
            }
        });
    };

    const fetchAppliedClawbacks = (clawbackIds) => {
        const params = {
            query: {
                clawback_ids: {
                    condition: "OR",
                    values: clawbackIds
                },
            },
        };
        serverPost(getApiUrl(`/commission/clawbacks/find`), params).then((res) => {
            if (res) {
                setAppliedClawbacks(res);
            }
        });
    };

    const fetchAppliedAccelerators = (acceleratorIds) => {
        const params = {
			query: {
				accelerator_ids: {
                    condition: "OR",
                    values: acceleratorIds
                },
			},
		};
        serverPost(getApiUrl(`/commission/accelerators/find`), params).then((res) => {
            if (res) {
                setAppliedAccelerators(res);
            }
        })
    }

    const getEventLabel = (event) => {
        const events = {
            CONTRACT_EXECUTED: 'Contract Executed',
            FIRST_INVOICE_PAID: 'First Invoice Paid',
            INVOICE_PAID: 'Invoice Paid',
            SUBSCRIPTION_CANCELLED: 'Subscription Cancelled',
        };
        return events[event] || event;
    };

    const renderSummarySection = () => {
        if (!eventLog.calculation_result) return null;
        const result = eventLog.calculation_result;

        const previousQuotaAchievement = {...result.total_quota_achievement}
        previousQuotaAchievement.value_in_cents = previousQuotaAchievement.value_in_cents - result.incremental_quota_achievement.value_in_cents;

        const summaryDetails = {
            'Event Type': getEventLabel(eventLog.event_type),
            ...(!eventLog.is_clawback && {'Commission Base': eventLog.event_value_base_type}),
            ...(eventLog.is_clawback
                    ? {'Original Commissions Paid': currencyFormatFromPrice(eventLog.commission_event_value)}
                    : {'Event Value': currencyFormatFromPrice(eventLog.commission_event_value)}
            ),
            ...(eventLog.is_clawback
                    ? {'Clawback Amount': currencyFormatFromPrice(eventLog.commission_amount)}
                    : {'Commission Amount': currencyFormatFromPrice(eventLog.commission_amount)}
            ),
            ...(eventLog.is_clawback
                    ? {'Clawback Rate': `${(result.commission_calculation.rate * 100).toFixed(2)}%`}
                    : {'Commission Rate': `${(result.commission_calculation.rate * 100).toFixed(2)}%`}
            ),
            'Previous Quota Achievement': currencyFormatFromPrice(previousQuotaAchievement),
            'Incremental Quota Achievement': currencyFormatFromPrice(result.incremental_quota_achievement),
            'New Total Quota Achievement': currencyFormatFromPrice(result.total_quota_achievement),
        };

        if (eventLog.commission_deal) {
            summaryDetails['Commission Deal'] = <Link href={getCompanySpecificUrl(`/contract/view/${eventLog.commission_deal.proposal_id}`)}>View Commission Deal</Link>
        }

        return (
            <Section>
                <KeyValueDisplay2 title={"Overview"} items={summaryDetails} />
            </Section>
        )

        return (
            <ContentBox>
                <ContentBox.Title
                    right={eventLog.commission_deal &&
                        <Link href={getCompanySpecificUrl(`/contract/view/${eventLog.commission_deal.proposal_id}`)}>
                            View Commission Deal
                        </Link>
                    }
                >
                    Summary
                </ContentBox.Title>
                <ContentBox.Body>
                    <KeyValueDisplay2 items={summaryDetails} />
                </ContentBox.Body>
            </ContentBox>
        );
    };

    const rulePropertyMap = {
        'QUOTA_ACHIEVED': 'Quota Achieved %',
        'PRODUCT_PRICING_IDS': 'Product Pricings'
    }

    const renderAppliedRule = () => {
        if (!appliedRules || appliedRules.length === 0) return null;

        const appliedRuleMap = _.keyBy(eventLog.calculation_result.rule_calculations, function(calc) {
            return calc.applied_rule
        })
        console.log(appliedRuleMap)
        return (
			<ContentBox>
				<ContentBox.Title>Applied Commission Rules</ContentBox.Title>
				<ContentBox.Body>
					<MapleTable>
						<MapleTable.Content>
							<thead>
								<tr>
									<MapleTable.TH>Condition</MapleTable.TH>
									<MapleTable.TH>Commission Rate</MapleTable.TH>
									<MapleTable.TH>Event Value</MapleTable.TH>
									<MapleTable.TH>Commission</MapleTable.TH>
									<MapleTable.TH></MapleTable.TH>
								</tr>
							</thead>
							<tbody className='divide-y divide-gray-200'>
								{appliedRules.map((rule, index) => (
									<tr key={index}>
										<td>{getDescriptionForRule(rule.rule, rulePropertyMap)}</td>
										<td>{(rule.commission_rate * 100).toFixed(2)}%</td>
										<td>{currencyFormatFromPrice(appliedRuleMap[rule.id].applied_amount)}</td>
										<td>{currencyFormatFromPrice(appliedRuleMap[rule.id].value)}</td>
										<td>
											{rule.is_retroactive && !eventLog.is_adjustment ? (
												<Label.Warning>Retroactively applies to previous tiers</Label.Warning>
											) : (
												''
											)}
										</td>
									</tr>
								))}
							</tbody>
						</MapleTable.Content>
					</MapleTable>
				</ContentBox.Body>
			</ContentBox>
		);
    };

    const renderAppliedAccelerators = () => {
        if (!appliedAccelerators || appliedAccelerators.length === 0) return null;

        return (
            <ContentBox>
                <ContentBox.Title>Applied Accelerators</ContentBox.Title>
                <ContentBox.Body>
                    <MapleTable>
                        <MapleTable.Content>
                            <thead>
                            <tr>
                                <MapleTable.TH>Type</MapleTable.TH>
                                <MapleTable.TH>Rate</MapleTable.TH>
                                <MapleTable.TH>Condition</MapleTable.TH>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {appliedAccelerators.map((accelerator, index) => (
                                <tr key={index}>
                                    <td className="capitalize">
                                        {accelerator.accelerator_type.toLowerCase()}
                                    </td>
                                    <td>
                                        {accelerator.accelerator_type === 'ADDITIVE'
                                            ? `${(accelerator.accelerator_rate * 100).toFixed(2)}%`
                                            : `${Number(accelerator.accelerator_rate).toFixed(2)}x`}
                                    </td>
                                    <td>{getDescriptionForRule(accelerator.rule)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </ContentBox.Body>
            </ContentBox>
        );
    }

    const renderAppliedClawbacks = () => {
        if (!appliedClawbacks || appliedClawbacks.length === 0) return null;

        return (
            <ContentBox>
                <ContentBox.Title>Applied Clawbacks</ContentBox.Title>
                <ContentBox.Body>
                    <MapleTable>
                        <MapleTable.Content>
                            <thead>
                            <tr>
                                <MapleTable.TH>Condition</MapleTable.TH>
                                <MapleTable.TH>Clawback Rate</MapleTable.TH>
                                <MapleTable.TH>Affects Quota</MapleTable.TH>
                            </tr>
                            </thead>
                            <tbody className='divide-y divide-gray-200'>
                            {appliedClawbacks.map((clawback, index) => (
                                <tr key={index}>
                                    <td>{getDescriptionForRule(clawback.rule, rulePropertyMap)}</td>
                                    <td>{(clawback.clawback_percentage*100).toFixed(2)}%</td>
                                    <td>
                                        {clawback.affects_quota ? (
                                            <Label.Info>Yes</Label.Info>
                                        ) : (
                                            <Label.Secondary>No</Label.Secondary>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </ContentBox.Body>
            </ContentBox>
        )
    }

    return (
        <BaseContainer>
            <ContentContainer title={"Event Log Detail"}>
                <div className="space-y-6">
                    {renderSummarySection()}
                    {/*{renderCalculationInputs()}*/}
                    {renderAppliedRule()}
                    {renderAppliedAccelerators()}
                    {renderAppliedClawbacks()}

                    {eventLog.is_adjustment && (
                        <ContentBox>
                            <ContentBox.Title>Adjustment Details</ContentBox.Title>
                            <ContentBox.Body>
                                <div className="text-sm text-gray-700">
                                    <p><strong>Reason: </strong>{getCommissionAdjustmentReason(eventLog.adjustment_reason)}</p>
                                    {eventLog.original_event_id && (
                                        <p className="mt-1">Original Event: <Link href={getCompanySpecificUrl(`/commissions/event_logs/${eventLog.original_event_id}`)}>{eventLog.original_event_id}</Link></p>
                                    )}
                                </div>
                            </ContentBox.Body>
                        </ContentBox>
                    )}
                </div>
            </ContentContainer>
        </BaseContainer>
    );
}

export default CommissionEventLog;
