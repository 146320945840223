import '../../App.scss';
import '../../css/invoice.scss';
import React, { useEffect, useState, useContext } from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import { serverFetch } from '../../helpers/server';
import {BaseContext, currencyFormatFromPrice, getCustomerNameOrEmail} from '../../helpers/common';
import Loader from '../../components/Loader';
import Section from "../../components/Section";
import KeyValueDisplay2 from "../../components/KeyValueDisplay2";
import MapleTable from "../../components/MapleTable";
import {getCreditNoteRefundTypeDescription} from "../../helpers/invoices";
import moment from "moment";
import CopyableComponent from "../../components/CopyableComponent";
const _ = require('lodash');

function CreditNotePublicView() {
    const [ searchParams ] = useSearchParams();
    let noteId = searchParams.get('id');
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);

    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [creditNoteDetails, setCreditNoteDetails] = useState({});
    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setPageTitle(`Credit Note - ${creditNoteDetails.invoice ? creditNoteDetails.invoice.number: ""}`);
    }, [creditNoteDetails]);

    const fetchData = async () => {
        const creditNoteRes = await serverFetch(getApiUrl(`/credit_notes/${noteId}`), { skipCache: true });
        if (creditNoteRes) {
            setCreditNoteDetails(creditNoteRes)
        } else {
            setLoading(false);
        }

        const invoiceRes = await serverFetch(getApiUrl(`/invoices/${creditNoteRes.invoice.uuid}`));
        if (invoiceRes) {
            setInvoiceDetails(invoiceRes)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(creditNoteDetails)) {
            return;
        }
        const data = {
            'Customer': getCustomerNameOrEmail(creditNoteDetails.customer),
            'ID': <CopyableComponent value={creditNoteDetails.id}></CopyableComponent>,
            'Date': moment(creditNoteDetails.effective_date).format("DD MMM, YYYY h:mm:ssa"),
            'Invoice': <Link to={getCompanySpecificUrl(`/invoice/view?uuid=${creditNoteDetails.invoice_uuid}`)}>{ creditNoteDetails.invoice.number }</Link>,
            'Credit Amount': currencyFormatFromPrice(creditNoteDetails.total),
            'Note': creditNoteDetails.note,
        };
        setDetails(data);
    }, [creditNoteDetails, invoiceDetails]);

    const renderCreditRefundOptions = () => {
        if (!creditNoteDetails.refund_options || _.isEmpty(creditNoteDetails.refund_options.items)) {
            return;
        }

        return (
            <div className="w-full">
                <span className="text-lg font-semibold text-gray-900">Credits Issued</span>
                <MapleTable className="w-full">
                    <MapleTable.Content>
                        <thead>
                        <tr>
                            <MapleTable.TH>Refund Type</MapleTable.TH>
                            <MapleTable.TH className="text-end">Amount</MapleTable.TH>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(creditNoteDetails.refund_options.items, (item, j) => {
                                return (
                                    <tr key={j}>
                                        <td>{getCreditNoteRefundTypeDescription(item.refund_type)}</td>
                                        <td className="text-end">{currencyFormatFromPrice(item.amount)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
            </div>
        )
    }

    return (
        <Loader loading={loading}>
            {
                () =>
                    <div className="flex flex-col items-center justify-content-start">
                        <div className="invoice-wrapper py-8">
                            <div className="w-full">
                            <div className="text-xl font-semibold">Credit Note for Invoice {invoiceDetails.number}</div>
                            <Section className="mt-4" title="Details" actions={[]}>
                                <KeyValueDisplay2 items={details} />
                            </Section>
                            <span className="text-lg font-semibold text-gray-900">Credit Line Items</span>
                            <MapleTable className="w-full">
                                <MapleTable.Content>
                                    <thead>
                                    <tr>
                                        <MapleTable.TH>Description</MapleTable.TH>
                                        <MapleTable.TH>Quantity</MapleTable.TH>
                                        <MapleTable.TH className="text-end">Price</MapleTable.TH>
                                        <MapleTable.TH className="text-end">Total Amount</MapleTable.TH>
                                        <MapleTable.TH className="text-end">Credit Amount</MapleTable.TH>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(creditNoteDetails.line_items, (item, i) => {
                                            const lineItem = _.find(invoiceDetails.line_items, (a) => a.id === item.line_item_id);
                                            return (
                                                <tr key={i}>
                                                    <td>{lineItem.description}</td>
                                                    <td>{lineItem.quantity}</td>
                                                    <td className="text-end">{currencyFormatFromPrice(lineItem.unit_price)}</td>
                                                    <td className="text-end">{currencyFormatFromPrice(lineItem.amount)}</td>
                                                    <td className="text-end">{currencyFormatFromPrice(item.amount)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                            { renderCreditRefundOptions() }
                            </div>
                        </div>
                    </div>
            }
        </Loader>
    );
}

export default CreditNotePublicView;
