import '../App.scss';
import '../css/invoice.scss';
import React, { useContext, useState } from 'react';
import { BaseContext, IntegrationContext } from '../helpers/common';
import BaseForm from './BaseForm';
import SubmitButton from './common/buttons/SubmitButton';
import { notifyEvent, serverPatch } from '../helpers/server';
import Notification from './Notification';
import { Container, Row } from 'react-bootstrap';
import Button from './common/buttons/Button';
import Loader from './Loader';
import CopyableComponent from './CopyableComponent';

function EmailNotificationWebhook(props) {
	const { integration } = useContext(IntegrationContext);
	const { getApiUrl } = useContext(BaseContext);
	const [loading, setLoading] = useState(false);
	const [integrationData, setIntegrationData] = useState(
		integration && integration.api_key_data && integration.api_key_data,
	);

	const [isEditing, setIsEditing] = useState(integration && !integration.api_key_data);

	function onSubmit(data) {
		setLoading(true);
		console.log(data);
		const updateData = {
			authentication: {
				client_secret: data.client_secret,
			},
		};
		// TODO: HOW is error handling happening here
		serverPatch(
			getApiUrl(`/integrations/${integration.id}`, {}, (err) => {
				console.log('failed to update server error:', err);
				Notification.Danger(`${integration.name} failed to update`);
			}),
			updateData,
		).then((res) => {
			Notification.Success(`${integration.name} updated successfully`);
			notifyEvent('integration_settings');
			setIsEditing(false);
			setLoading(false);
			setIntegrationData(res.api_key_data);

			if (props.onUpdate) {
				props.onUpdate();
			}
		});
	}

	return (
		<div>
			<div className='d-flex flex-row'>
				<div className='flex-grow-1'>
					<h5>Email Tracking</h5>
				</div>
				<div className='flex-shrink-0 d-flex align-items-center'>
					{isEditing ? (
						<Button
							variant='text-danger'
							onClick={() => {
								setIsEditing(false);
								setLoading(false);
							}}
						>
							Cancel
						</Button>
					) : (
						<Button
							variant='text-primary'
							onClick={() => {
								setIsEditing(true);
								setLoading(false);
							}}
						>
							<i className='fa fa-edit' /> Edit
						</Button>
					)}
				</div>
			</div>
			{isEditing ? (
				<Container>
					<Row>
						<div className='flex flex-row gap-12 items-center max-w-2xl '>
							<Row className='grow'>
								<BaseForm initialFormFields={integrationData} onSubmit={onSubmit}>
									<BaseForm.Input
										colSpan={12}
										type='text'
										name='client_secret'
										label='Client Secret'
										required
									/>
									<Loader loading={loading}>
										<SubmitButton>Update</SubmitButton>
									</Loader>
								</BaseForm>
							</Row>
						</div>
					</Row>
				</Container>
			) : (
				<Container>
					<Row>
						<div className='flex flex-row'>
							<Row className='grow'>
								<div>
									In order to properly track email sends, opens, and clicks, you will need to setup a
									webhook endpoint in your email provider.
									<br />
									<CopyableComponent
										value={
											`https://api.maplebilling.com/web/api/v1/notifications/email/webhook/` +
											integration.id
										}
									></CopyableComponent>
								</div>
							</Row>
						</div>
					</Row>
					<Row>
						<div className='flex flex-row'>
							<Row className='grow'>
								<div>
									<span className='text-sm font-semibold text-gray-700'>Client Secret: </span>
									<span className='text-sm font-normal text-gray-500'>
										{integrationData && integrationData.client_secret}
									</span>
								</div>
							</Row>
						</div>
					</Row>
				</Container>
			)}
		</div>
	);
}

export default EmailNotificationWebhook;
