import '../App.scss';
import '../css/invoice.scss';
import React, { useEffect, useState, useContext, createRef } from 'react';
import { serverFetch, serverPatch, notifyEvent } from '../helpers/server';
import { BaseContext, IntegrationContext } from '../helpers/common';
import ContentBox from './ContentBox';
import BaseForm from './BaseForm';
import { Container, Row, Col } from 'react-bootstrap';
import Button from './common/buttons/Button';
import SubmitButton from "./common/buttons/SubmitButton";
import moment from "moment";
const _ = require('lodash');

function IntegrationSyncSchedule(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);

    const [isEditing, setIsEditing] = useState(false);
    const [automaticSyncEnabled, setAutomaticSyncEnabled] = useState(false);

    const onFieldChange = (name, value) => {
        if (name === "automatic_sync_enabled") {
            setAutomaticSyncEnabled(value);
        }
    }

    const updateSyncSchedule = (data) => {
        console.log("The data is " + JSON.stringify(data));
        // const updateData = {
        //     feature_data: {
        //         accounting_data: {
        //             selected_account: data.selected_account,
        //         }
        //     }
        // }
        // serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
        //     if (res) {
        //         setIsEditing(false);
        //         notifyEvent('integration_settings');
        //         if (props.onUpdate) {
        //             props.onUpdate();
        //         }
        //     }
        // })
    }

    const frequencyOptions = [
        { label: "Week", value: "WEEK" },
        { label: "Month", value: "MONTH" }
    ];

    let nextSync = moment().utc();
    nextSync.add(7 - nextSync.day(), 'day');
    nextSync.hour(12).minute(0).second(0).local()

    return (
        <ContentBox>
            <ContentBox.Body>
                <div className="d-flex flex-row gap-3">
                    <div className="flex-grow-1">
                        <h5>Automatic Sync to { integration.name }</h5>
                    </div>
                    <div className="flex-shrink-0 d-flex align-items-center">
                        {
                            isEditing ?
                                <Button variant="text-danger" onClick={() => setIsEditing(false)}>Cancel</Button>
                                : <Button disabled variant="text-primary" onClick={() => setIsEditing(true)}><i className="fa fa-edit" /> Edit</Button>
                        }
                    </div>
                </div>
                {
                    isEditing ?
                        <Container>
                            <Row>
                                <Col md="6">
                                    <div className="mt-2">
                                        <BaseForm initialFormFields={integration.accounting_data} onFieldChange={onFieldChange} onSubmit={updateSyncSchedule}>
                                            <BaseForm.Input name="automatic_sync_enabled" label="Enable Automatic Sync" type="switch"/>
                                            {
                                                automaticSyncEnabled &&
                                                    <BaseForm.Input name="frequency" label="Frequency" type="select" options={frequencyOptions} showSearch={false} />
                                            }
                                            <SubmitButton>Update</SubmitButton>
                                        </BaseForm>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        : <div className="flex flex-col gap-1 text-sm">
                            <div className="flex flex-row gap-1">
                                <span className="font-semibold text-gray-700">Automatic Sync:</span>
                                {
                                    integration.ready_for_sync ?
                                        <span className="font-normal text-gray-500">Enabled (weekly on Sunday)</span>
                                    : <span className="font-normal text-gray-500">Disabled</span>
                                }
                            </div>
                            {
                                integration.ready_for_sync &&
                                    <div className="flex flex-row gap-1">
                                        <span className="font-semibold text-gray-700">Next sync:</span>
                                        <span className="font-normal text-gray-500">{ nextSync.format("MMM D, YYYY h:mm:ssa") }</span>
                                    </div>
                            }
                        </div>
                }
            </ContentBox.Body>
        </ContentBox>
    );
}

export default IntegrationSyncSchedule;
