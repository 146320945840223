import { useState } from 'react';
import Check from '@untitled-ui/icons-react/build/esm/Check';
import Status from './common/Status';
import classnames from 'classnames';
import moment from 'moment';
import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronUp from '@untitled-ui/icons-react/build/esm/ChevronUp';

const SignatoryStatus = ({ title, status_message, status, actions }) => {
    const [isActionsCollapsed, setIsActionsCollapsed] = useState(false);

	const getStatusVariant = (status) => {
		switch (status) {
            case "PENDING":
                return "hourglass";
            case "FAILED":
                return "x";
            default: 
                return "check"
		}
	};

	const renderCheckmark = () => (
		<span className='rounded-full min-w-[24px] w-[24px] h-[24px] bg-primary-updated flex items-center justify-center'>
			<Check width={16} height={16} className='text-white mt-[1px]' />
		</span>
	);

    const renderBlankState = () => (
        <span className='rounded-full min-w-[24px] w-[24px] h-[24px] bg-gray2 flex items-center justify-center'>
            <span className='rounded-full w-[20px] h-[20px] bg-white flex items-center justify-center'>
                <span className='rounded-full w-[8px] h-[8px] bg-gray2'></span>
            </span>
        </span>
    );

    const renderChevron = () => {
        if (!Array.isArray(actions)) return null;

        if (isActionsCollapsed) {
            return <ChevronDown width={20} height={20} className="cursor-pointer -inset-2" onClick={() => setIsActionsCollapsed(false)} />
        } else {
            return <ChevronUp width={20} height={20} className="cursor-pointer -inset-2" onClick={() => setIsActionsCollapsed(true)} />
        }
    };

	return (
		<div className="group last:group">
			<div className='flex items-center mb-1'>
				{status === "SUCCESS" ? renderCheckmark() : renderBlankState()}
				<div className='ml-2 flex gap-2 items-center w-full'>
					<span className='text-gray6 font-semibold'>{title}</span>
					{status && status_message && <Status variant={getStatusVariant(status)}>{status_message}</Status>}
				</div>
                {renderChevron()}
			</div>
            {!isActionsCollapsed && <div className="flex">
                <div className="w-[24px] flex justify-center shrink-0">
                    <div className="w-[2px] bg-primary-updated rounded-full group-last:bg-transparent"></div>
                </div>
                <div className="flex-grow flex flex-col gap-2">
                    {Array.isArray(actions) && actions.map(action => (
                        <div key={action.title} className="ml-2 first:mt-2 last:mb-3">
                            <div className="flex border-gray3 border rounded-xl py-1 pl-2 pr-[12px] flex-grow items-center gap-2">
                                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-light-gray shrink-0">
                                    <i className={classnames("w-4 h-4 text-subtitle fa flex items-center justify-center", action.icon)}/>
                                </div>
                                <span className="flex-grow text-gray6 text-xs">{action.title}</span>
                                <span className="text-gray5 text-xs">{moment(action.timestamp).fromNow()}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
		</div>
	);
};

export default SignatoryStatus;
