import '../App.scss';
import '../css/invoice.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {notifyEvent, serverFetch, serverPatch, serverPost} from '../helpers/server';
import { BaseContext, getCurrencyOptions, IntegrationContext } from '../helpers/common';
import BaseForm from './BaseForm';
import Link from "./Link";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import MapleTable from "./MapleTable";
import SubmitButton from "./common/buttons/SubmitButton";
import Notification from "./Notification";
import Loader from "./Loader";
const _ = require('lodash');

const ALL_CURRENCY = "ALL_CURRENCY"

function AccountingProductIDMappingComponent(props) {
    const { company, getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);

    const [mapleProducts, setMapleProducts] = useState([]);
    const [integrationProducts, setIntegrationProducts] = useState([]);
    const [integrationProductsLoading, setIntegrationProductsLoading] = useState(true);
    const [useProductMappingByCurrency, setUseProductMappingByCurrency] = useState(null);
    const [loading, setLoading] = useState(true);

    const currencyOptions = getCurrencyOptions();

    const hasProductMappingByCurrency = useMemo(() => {
        return "product_mapping_by_currency" in integration.accounting_data || !("product_mapping" in integration.accounting_data);
    }, [integration]);

    const initialFields = useMemo(() => {
        if (hasProductMappingByCurrency) {
            const initialData = {
                product_id_map: {}
            };
            if (integration.accounting_data) {
                _.each(integration.accounting_data.product_mapping_by_currency, (productCurrencyMap, productID) => {
                    const productCurrencyMapKeys = Object.keys(productCurrencyMap);
                    initialData.product_id_map[productID] = {};
                    productCurrencyMapKeys.forEach((currency) => {
                        initialData.product_id_map[productID][currency] = productCurrencyMap[currency];
                    });
                })
            }
            return initialData
        }
        if (!_.isEmpty(integration.accounting_data)) {
            const initialData = {
                product_id_map: {}
            };
            if (integration.accounting_data) {
                _.each(integration.accounting_data.product_mapping, (integrationProductId, productID) => {
                    initialData.product_id_map[productID] = integrationProductId;
                })
            }
            return initialData
        }
        return {}
    }, [integration, hasProductMappingByCurrency]);

    useEffect(() => {
        if (mapleProducts.length === 0) return;
        setUseProductMappingByCurrency(mapleProducts.map((product) => {
            const productMappingsByCurrencies = integration.accounting_data.product_mapping_by_currency;
            const productCurrenciesMap = productMappingsByCurrencies?.[product.id];
            if (productCurrenciesMap && !(ALL_CURRENCY in productCurrenciesMap)) {
                return true;
            }
            return false;
        }));
    }, [mapleProducts, integration]);

    const fetchData = (skipCache=false) => {
        const pLimit = 200
        const pParams = {
            company_id: company.id,
            pagination: {
                limit: pLimit
            },
            query: {}
        }
        serverPost(getApiUrl("/products/find"), pParams,{ skipCache }).then((res) => {
            if (res) {
                const results = res.results || [];
                setMapleProducts(results);
            }
        });
        setIntegrationProductsLoading(true);

        serverFetch(getApiUrl(`/integrations/${integration.id}/accounting/products`), { skipCache: true }).then((res) => {
            if (res) {
                setIntegrationProducts(res.products);
            }
            setLoading(false);
        })

        setIntegrationProductsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [])

    const refreshIntegrationProductIds = async () => {
        setIntegrationProductsLoading(true);
        serverFetch(getApiUrl(`/integrations/${integration.id}/accounting/products`), { skipCache: true }).then((res) => {
            if (res) {
                setIntegrationProducts(res.products);
            }
        })

        Notification.Success(`${integration.name} products refreshed`)
        setIntegrationProductsLoading(false);
    }

    const onIntegrationProductIdsMap = (data) => {
        const keys = Object.keys(data.product_id_map);
        const mappingData = {};
        _.each(keys, (key, i) => {
            if (hasProductMappingByCurrency) {
                if (useProductMappingByCurrency[i]) {
                    currencyOptions.forEach((currency) => {
                        if (data.product_id_map[key]?.[currency.label]) {
                            mappingData[key] = {
                                ...mappingData[key],
                                [currency.label]: data.product_id_map[key][currency.label],
                            }
                        }
                    });
                } else {
                    if (data.product_id_map[key]?.[ALL_CURRENCY]) {
                        mappingData[key] = data.product_id_map[key];
                    }
                }
            } else {
                if (data.product_id_map[key]) {
                    mappingData[key] = data.product_id_map[key];
                }
            }
        })
        const updateData = {
            feature_data: {
                accounting_data: {
                    [hasProductMappingByCurrency ? "product_mapping_by_currency" : "product_mapping"]: mappingData,
                }
            }
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            Notification.Success(`${integration.name} product ID mapping saved`)
            notifyEvent('integration_settings');
            if (props.onUpdate) {
                props.onUpdate();
            }
        })
    }

    const integrationProductOptions = _.map(integrationProducts, (pid) => {
        return {
            value: pid.id,
            label: pid.name
        }
    })
    integrationProductOptions.unshift({ value: null, label: "None" })

    return (
        <div>
            <p className="text-base font-semibold text-gray-700">Map the Products between Maple and { integration.name }.</p>
            <p className="text-sm font-normal text-gray-500">In order to ensure that the right set of taxes are calculated, we have to have an accurate mapping of Maple products with { integration.name } products.</p>
            <div className="flex flex-row justify-end items-center mt-2">
                {
                    integrationProductsLoading ?
                        <div className="h-4 w-4 spinner-border text-secondary"/>
                        : <Link className="text-sm flex flex-row gap-1 items-center" onClick={() => refreshIntegrationProductIds()}><ArrowPathIcon className="h-4 w-4"/> Refresh { integration.name } Products</Link>
                }
            </div>
            <Loader loading={loading}>
            <BaseForm className="mt-0" initialFormFields={initialFields} onSubmit={onIntegrationProductIdsMap}>
                <MapleTable>
                    <MapleTable.Content overflow>
                        <thead>
                        <tr>
                            <MapleTable.TH>Maple Product</MapleTable.TH>
                            <MapleTable.TH>{ integration.name } Product</MapleTable.TH>
                            {hasProductMappingByCurrency && <MapleTable.TH>Use Currency Mapping</MapleTable.TH>}
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(mapleProducts, (product, i) => {
                                const shouldUseProductMappingByCurrency = hasProductMappingByCurrency && Array.isArray(useProductMappingByCurrency) && useProductMappingByCurrency[i];
                                return (
                                    <>
                                        <tr key={i}>
                                            <td width="50%">
                                                <span className="text-gray-700 font-semibold">{ product.name }</span><br/>
                                                {
                                                    product.description &&
                                                    <span className="text-gray-500">{ product.description }</span>
                                                }
                                            </td>
                                            <td>
                                                <BaseForm.Input name={hasProductMappingByCurrency ? `product_id_map.${product.id}.${ALL_CURRENCY}` : `product_id_map.${product.id}`} type="select" label={"Product ID"} hideLabel formClassName="mb-0" options={integrationProductOptions} showSearch={integrationProductOptions.length > 6} disabled={shouldUseProductMappingByCurrency} />
                                            </td>
                                            <td>
                                                {hasProductMappingByCurrency && Array.isArray(useProductMappingByCurrency) && <input
                                                    type='checkbox'
                                                    checked={useProductMappingByCurrency[i]}
                                                    onChange={(event) => setUseProductMappingByCurrency((prev) => {
                                                        const updated = [...prev];
                                                        updated[i] = event.target.checked;
                                                        return updated;
                                                    })}
                                                    className='border-gray3 text-primary-updated rounded'
                                                />}
                                            </td>
                                        </tr>
                                        <tr className="border-none">
                                            <td colSpan={3}>
                                                {shouldUseProductMappingByCurrency && (
                                                    <MapleTable>
                                                        <MapleTable.Content overflow>
                                                            <thead>
                                                            <tr>
                                                                <MapleTable.TH>Currency</MapleTable.TH>
                                                                <MapleTable.TH>{ integration.name } Product</MapleTable.TH>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200">
                                                            {
                                                                _.map(currencyOptions, (currency, i) =>
                                                                    <tr key={i}>
                                                                        <td width="50%">
                                                                            <span className="text-gray-700 font-semibold">{ currency.label }</span><br/>
                                                                        </td>
                                                                        <td>
                                                                            <BaseForm.Input name={`product_id_map.${product.id}.${currency.label}`} type="select" formClassName="mb-0" options={integrationProductOptions} showSearch={integrationProductOptions.length > 6} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            </tbody>
                                                        </MapleTable.Content>
                                                    </MapleTable>
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                );
                            })
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
                <div className="text-sm text-gray-500 my-3 italic">*Note: You can update these mappings later as well.</div>
                <SubmitButton>Update Mapping</SubmitButton>
            </BaseForm>
            </Loader>
        </div>
    );
}

export default AccountingProductIDMappingComponent;
