import { useParams, useSearchParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { BaseContext, ContractContext, downloadBlob } from '../../helpers/common';
import { isContractExecuted } from '../../helpers/contracts';
import FileCheck02 from '@untitled-ui/icons-react/build/esm/FileCheck02';
import Status from '../../components/common/Status';
import CustomerContractFooter from '../../components/CustomerContractFooter';
import ContentContainer from '../../components/ContentContainer';
import MapleTable from '../../components/MapleTable';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { serverFetch, serverPost } from '../../helpers/server';
import Notification from '../../components/Notification';
import _ from 'lodash';
import moment from 'moment';
import BaseModal from '../../components/modals/BaseModal';
import Button from '../../components/common/buttons/Button';

function ContractDocuments() {
	const { uuid } = useParams();

	const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
	const { contract } = useContext(ContractContext);
	const [searchParams] = useSearchParams();
	let token = searchParams.get('token');
	const [showExpirationModal, setShowExpirationModal] = useState(false);
	const [showRequestNewLinkSuccessModal, setShowRequestNewLinkSuccessModal] = useState(false);
	const [showValidateTokenErrorModal, setShowValidateTokenErrorModal] = useState(false);
	const [isTokenValidating, setIsTokenValidating] = useState(true);

	useEffect(() => {
		setPageTitle(`Contract - ${contract.title} - Documents`);
	}, [contract]);

	useEffect(() => {
		if (!token) {
			setIsTokenValidating(false);
			setShowValidateTokenErrorModal(true);
			return;
		}
		serverPost(getApiUrl(`/proposals/${contract.id}/verify_sign_token`), {}, { accessToken: token }).then((res) => {
			if (res) {
				if (!res.valid) {
					setShowExpirationModal(true);
				}
			} else {
				setShowValidateTokenErrorModal(true);
			}
		});
		setIsTokenValidating(false);
	}, [token]);

	const downloadContract = () => {
		serverPost(
			getApiUrl(`/proposals/${contract.id}/preview`),
			{},
			{ noJson: true, accessToken: token },
			downloadDocumentErrorHandler,
		).then((res) => {
			if (res) {
				downloadBlob(res, `Contract-${contract.title}`);
			}
		});
	};

	const downloadDocumentErrorHandler = (res) => {
		if (res.status === 401 || res.status === 403) {
			Notification.Danger('Download link has expired. Please request a new link from the sender.', 5000);
		} else {
			Notification.Danger('File cannot be currently downloaded. Please try again later.', 5000);
		}
	};

	const downloadAttachment = (attachmentId) => {
		serverPost(
			getApiUrl(`/proposals/${contract.id}/preview_attachment/${attachmentId}`),
			{},
			{ accessToken: token },
			downloadDocumentErrorHandler,
		).then((res) => {
			if (res) {
				fetch(res.url, { headers: res.headers }).then((res2) => {
					res2.blob().then((value) => {
						downloadBlob(value, res.filename);
					});
				});
			}
		});
	};

	const requestNewLink = async () => {
		const res = await serverPost(getApiUrl(`/proposals/${contract.id}/resend_documents_email`), {}, { accessToken: token })
		if (res) {
			setShowRequestNewLinkSuccessModal(true);
			setShowExpirationModal(false);
		} else {
			Notification.Danger('Error sending email, please try again later', 5000);
		}
	};

	const renderContract = () => {
		const numberOfSignedSignatures = contract.signatories.filter((signatory) => signatory.signed).length;
		const signaturesCompleted = isContractExecuted(contract);
		return (
			<div className='contract-wrapper'>
				<div className='flex flex-col flex-grow justify-center items-center gap-8'>
					<div className='flex flex-col justify-center items-center gap-3'>
						<h3 className='text-dark-gray text-3xl font-semibold'>{contract.title}</h3>
						<span className='text-dark-gray text-xl font-semibold'>Contract Documents</span>
						<MapleTable>
							<MapleTable.Content removeMargin removePadding>
								<thead>
									<tr>
										<MapleTable.TH>Document</MapleTable.TH>
										<MapleTable.TH></MapleTable.TH>
									</tr>
								</thead>
								<tbody className='divide-y divide-gray-200'>
									<tr
										onClick={() => downloadContract()}
										className={'cursor-pointer hover:bg-gray-50'}
									>
										<td>{`${contract.title}.pdf`}</td>
										<td className='text-end'>
											<div className='flex flex-row gap-2 justify-end items-center'>
												<ArrowDownTrayIcon className='cursor-pointer h-4 w-4' />
											</div>
										</td>
									</tr>
									{_.map(contract.contract_attachments, (ufile, i) => (
										<tr
											key={i}
											onClick={() => downloadAttachment(ufile.id)}
											className={'cursor-pointer hover:bg-gray-50'}
										>
											<td>{ufile.original_filename}</td>
											<td className='text-end'>
												<div className='flex flex-row gap-2 justify-end items-center'>
													<ArrowDownTrayIcon className='cursor-pointer h-4 w-4' />
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</MapleTable.Content>
						</MapleTable>
					</div>
					<Status variant={signaturesCompleted ? 'check' : 'hourglass'}>
						{signaturesCompleted
							? 'All signatures completed'
							: `${numberOfSignedSignatures} of ${contract.signatories.length} signatures completed`}
					</Status>
				</div>
				<CustomerContractFooter />
			</div>
		);
	};

	return (
		<ContentContainer className='full' bodyClassName='full'>
			{!isTokenValidating &&
				!showExpirationModal &&
				!showRequestNewLinkSuccessModal &&
				!showValidateTokenErrorModal &&
				renderContract()}
			<BaseModal show={showExpirationModal} size={'md'} disableClose>
				<BaseModal.Header disableClose>
					<BaseModal.Title>Link Has Expired</BaseModal.Title>
				</BaseModal.Header>
				<BaseModal.Body>
					<span>This link has expired. Please request a new link below.</span>
				</BaseModal.Body>
				<BaseModal.Footer>
					<Button variant={'primary'} onClick={() => requestNewLink()}>
						Request Link
					</Button>
				</BaseModal.Footer>
			</BaseModal>
			<BaseModal show={showRequestNewLinkSuccessModal} size={'md'} disableClose>
				<BaseModal.Header disableClose>
					<BaseModal.Title>New Link Sent!</BaseModal.Title>
				</BaseModal.Header>
				<BaseModal.Body>
					<span>A new link has been sent. Please check your inbox shortly.</span>
				</BaseModal.Body>
			</BaseModal>
			<BaseModal show={showValidateTokenErrorModal} size={'md'} disableClose>
				<BaseModal.Header disableClose>
					<BaseModal.Title>Invalid Link</BaseModal.Title>
				</BaseModal.Header>
				<BaseModal.Body>
					<span>
						This link is invalid. Please double check the link address or contact the person who sent it to
						you.
					</span>
				</BaseModal.Body>
			</BaseModal>
		</ContentContainer>
	);
}

export default ContractDocuments;
