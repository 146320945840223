import '../App.scss';
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useMemo } from 'react';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import { serverPost } from '../helpers/server';
import {
    getCurrencyOptions,
    BaseContext,
    getFrequencyOptions,
    getUnitOfTimeFromFrequency, getLabelForSubscriptionLength, getPlanLengthOptions
} from '../helpers/common';
import { Row, Table, Col } from 'react-bootstrap';
import Button from './common/buttons/Button';
import Link from './Link';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

const ItemPricingFields = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        processFields(data) {
            return processFields(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        },
        isDisabled() {
            return _.isEmpty(billableItems) && baseType === "no";
        },
        refreshBillableMetrics() {
            refreshBillableMetrics();
        },
        refreshBillableItems() {
            refreshBillableItems();
        }
    }));

    const { t } = useTranslation('common');
    const { company, getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);

    const [baseType, setBaseType] = useState("yes");
    const [type, setType] = useState(null);
    const [licenseItem, setLicenseItem] = useState(null);
    const [billableItems, setBillableItems] = useState([]);
    const [billableMetrics, setBillableMetrics] = useState([]);
    const [numberOfGradientRows, setNumberOfGradientRows] = useState(2);
    const [numberOfVolumeRows, setNumberOfVolumeRows] = useState(2);
    const [selectedItem, setSelectedItem] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [frequency, setFrequency] = useState("MONTH");
    const [termCount, setTermCount] = useState(12);
    const [hasMinimumSpend, setHasMinimumSpend] = useState(false);
    const [minimumSpendScheduleType, setMinimumSpendScheduleType] = useState("EVENLY");

    const defaultFields = useMemo(() => {
        return {
            frequency: "MONTH",
            custom_frequency: "MONTH",
            currency: company.preferred_currency,
            base_type: "yes",
            base_units: 0,
            base_price: 0,
            fixed_price: {
                price_per_unit: 0
            },
            step_price: {
                price_per_step: 0,
                step_size: 1
            },
            gradient_price: [
                { start: 0, end: 2, price_per_unit: 0, flat_price: 0 },
                { start: 3, end: 2147483648, price_per_unit: 0, flat_price: 0 },
            ],
            volume_price: [
                { total_units_start: 0, total_units_end: 2, price_per_unit: 0, flat_price: 0 },
                { total_units_start: 3, total_units_end: 2147483648, price_per_unit: 0, flat_price: 0 },
            ],
            minimum_spend: 0
        }
    }, []);

    useEffect(() => {
        if (_.isNil(props.itemToEdit)) {
            const ifields = { ...defaultFields, ...props.initialFields,  ...props.initialFormFields};
            props.setInitialFields(ifields);
            setBaseType(ifields.base_type);
            setType(ifields.type);
            setNumberOfVolumeRows(ifields.volume_price.length);
            setNumberOfGradientRows(ifields.gradient_price.length);
            setFrequency(ifields.frequency);
        } else {
            const item = _.cloneDeep({...props.initialFields, ...defaultFields, ...props.itemToEdit});
            const isBaseType = item.item.type === "LICENSE_ITEM" && item.base_price.value_in_cents === 0 && item.type === "FIXED";
            setBaseType(isBaseType ? "yes": "no");
            const initialData = item;
            initialData.company = null;
            initialData.currency = item.base_price.currency;
            initialData.base_type = isBaseType ? "yes": "no";
            if (isBaseType) {
                initialData.base_price = parseFloat(item.fixed_price.price_per_unit) / 100;
                initialData.fixed_price.price_per_unit = parseFloat(initialData.fixed_price.price_per_unit) / 100;
            } else {
                initialData.base_price = parseFloat(initialData.base_price.value_in_cents) / 100;
                if (item.type === "FIXED") {
                    initialData.fixed_price.price_per_unit = parseFloat(initialData.fixed_price.price_per_unit) / 100;
                } else if (item.type === "STEP") {
                    initialData.step_price.step_size = parseFloat(initialData.step_price.step_size);
                    initialData.step_price.price_per_step = parseFloat(initialData.step_price.price_per_step) / 100;
                } else if (item.type === "GRADIENT") {
                    _.each(initialData.gradient_price, vp => {
                        vp.price_per_unit = parseFloat(vp.price_per_unit) / 100
                        vp.flat_price = parseFloat(vp.flat_price) / 100
                    })
                    setNumberOfGradientRows(initialData.gradient_price.length)
                } else if (item.type === "VOLUME") {
                    _.each(initialData.volume_price, vp => {
                        vp.price_per_unit = parseFloat(vp.price_per_unit) / 100
                        vp.flat_price = parseFloat(vp.flat_price) / 100
                    })
                    setNumberOfVolumeRows(initialData.volume_price.length)
                }
            }
            props.setInitialFields(initialData)
        }
    }, [props.itemToEdit]);

    useEffect(() => {
        serverPost(getApiUrl("/billable/items/find"), {}).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
            setLicenseItem(_.find(res, (r) => r.type === "LICENSE_ITEM" && r.standard));
        });

        serverPost(getApiUrl("/billable/metrics/find"), {}).then((res) => {
            if (res) {
                setBillableMetrics(_.filter(res, (r) => !r.standard));
            }
        });
    }, []);

    const frequencyOptions = [
        ...getFrequencyOptions(),
        { value: "CUSTOM", label: "Custom" },
    ]

    const trueUpOptions = [
        { value: null, label: "Don't true up" },
        ...getFrequencyOptions()
    ]

    const baseTypeOptions = [
        { value: "yes", label: "Standard Pricing" },
        { value: "no", label: "Variable Pricing" },
    ]

    const prorationTypeOptions = [
        { value: "NONE", label: "No Proration" },
        { value: "PRORATE_ALL", label: "Prorate Partial Periods" },
    ]

    const typeOptions = [
        { value: "FIXED", label: "Fixed" },
        { value: "STEP", label: "Step" },
        { value: "GRADIENT", label: "Gradient" },
        { value: "VOLUME", label: "Volume" },
    ]
//        { value: "CUSTOM", label: "Custom" },

    const chargeTypeOptions = [
        { value: "PRE_POST", label: "Bill upfront, with overages at the end of period" },
        { value: "POST_ONLY", label: "Bill at the end of period" },
    ]

    const billableItemOptions = _.map(billableItems, (bi) => {
        return { value: bi.id, label: bi.name }
    })
    if (licenseItem) {
        billableItemOptions.unshift({ value: licenseItem.id, label: "License" })
    }

    const getMetricOptionsForItem = (item) => {
        if (!item) {
            return []
        }
        return _.map(_.filter(billableMetrics, (m) => m.item_id === item.id), (bm) => {
            return { value: bm.id, label: bm.name }
        })
    }

    const onCreateBillableItem = (event) => {
        event.preventDefault();
        if (props.onCreateBillableItem) {
            props.onCreateBillableItem();
        }
    }

    const onAddGradientRow = () => {
        const oldCount = numberOfGradientRows;
        setNumberOfGradientRows(numberOfGradientRows + 1);
        props.setInitialFields(prevFields => {
            const newFields = {...prevFields}
            const newRow = {
                start: newFields.gradient_price[oldCount - 1].start,
                end: parseInt(newFields.gradient_price[oldCount - 1].start) + 1,
                price_per_unit: newFields.gradient_price[oldCount - 1].price_per_unit,
                flat_price: newFields.gradient_price[oldCount - 1].flat_price
            }
            newFields.gradient_price.splice(numberOfGradientRows - 1, 0, newRow)
            newFields.gradient_price[oldCount].start = parseInt(newFields.gradient_price[oldCount - 1].end) + 1
            newFields.gradient_price[oldCount].price_per_unit = 0
            newFields.gradient_price[oldCount].flat_price = 0
            return newFields;
        });
    }

    const onAddVolumeRow = () => {
        const oldCount = numberOfVolumeRows;
        setNumberOfVolumeRows(numberOfVolumeRows + 1);
        props.setInitialFields(prevFields => {
            const newFields = {...prevFields}
            const newRow = {
                total_units_start: newFields.volume_price[oldCount - 1].total_units_start,
                total_units_end: parseInt(newFields.volume_price[oldCount - 1].total_units_start) + 1,
                price_per_unit: newFields.volume_price[oldCount - 1].price_per_unit,
                flat_price: newFields.volume_price[oldCount - 1].flat_price
            }
            newFields.volume_price.splice(numberOfVolumeRows - 1, 0, newRow)
            newFields.volume_price[oldCount].total_units_start = parseInt(newFields.volume_price[oldCount - 1].total_units_end) + 1
            newFields.volume_price[oldCount].price_per_unit = 0
            newFields.volume_price[oldCount].flat_price = 0
            return newFields;
        });
    }

    const onDeleteGradientRow = (i) => {
        setNumberOfGradientRows(numberOfGradientRows - 1);
        props.setInitialFields(prevFields => {
            const newFields = {...prevFields}
            newFields.gradient_price[i+1].start = newFields.gradient_price[i].start
            newFields.gradient_price.splice(i, 1)
            return newFields;
        });
    }

    const onDeleteVolumeRow = (i) => {
        setNumberOfVolumeRows(numberOfVolumeRows - 1);
        props.setInitialFields(prevFields => {
            const newFields = {...prevFields}
            newFields.volume_price[i+1].total_units_start = newFields.volume_price[i].total_units_start
            newFields.volume_price.splice(i, 1)
            return newFields;
        });
    }

    const refreshBillableMetrics = () => {
        // Re-fetch the billable metrics on any new metric addition
        serverPost(getApiUrl("/billable/metrics/find"), {}).then((res) => {
            if (res) {
                setBillableMetrics(_.filter(res, (r) => !r.standard));
            }
        });
    }

    const refreshBillableItems = () => {
        // Re-fetch the billable items on any new item addition
        serverPost(getApiUrl("/billable/items/find"), {}).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
            setLicenseItem(_.find(res, (r) => r.type === "LICENSE_ITEM" && r.standard));
        });
    }

    const onFieldChange = (key, value) => {
        if (key === "type") {
            setType(value);
        } else if (key === "name") {
            props.setInitialFields(prevFields => {
                prevFields.name = value;
                return prevFields;
            });
        } else if (key === "base_type") {
            setBaseType(value);
            if (value === "no") {
                if (type !== "FIXED") {
                    setType("FIXED")
                    props.setInitialFields(prevFields => {
                        const newFields = {...prevFields}
                        newFields.base_price = 0;
                        return newFields;
                    });
                }
            }
            props.setInitialFields(prevFields => {
                const newFields = {...prevFields}
                newFields.base_type = value;
                return newFields;
            });
        } else if (key.endsWith(".end")) {
            const index = parseInt(key.split(".")[1])
            props.setInitialFields(prevFields => {
                const newFields = {...prevFields}
                newFields.gradient_price[index + 1].start = parseInt(value) + 1;
                return newFields;
            });
        } else if (key.endsWith("total_units_end")) {
            const index = parseInt(key.split(".")[1])
            props.setInitialFields(prevFields => {
                const newFields = {...prevFields}
                newFields.volume_price[index + 1].total_units_start = parseInt(value) + 1;
                return newFields;
            });
        } else if (key.endsWith(".start")) {
            const index = parseInt(key.split(".")[1])
            props.setInitialFields(prevFields => {
                const newFields = {...prevFields}
                newFields.gradient_price[index - 1].end = parseInt(value) - 1;
                return newFields;
            });
        } else if (key.endsWith("total_units_start")) {
            const index = parseInt(key.split(".")[1])
            props.setInitialFields(prevFields => {
                const newFields = {...prevFields}
                newFields.volume_price[index - 1].total_units_end = parseInt(value) - 1;
                return newFields;
            });
        } else if (key === "item_id") {
            let existingItemId = null;
            if (props.initialFields) {
                existingItemId = props.initialFields.item_id;
            }
            setSelectedItem(_.find(billableItems, (b) => b.id === value));
            // If item_id changed, then reset the metric_id as well.
            if (existingItemId !== value) {
                props.setInitialFields(prevFields => {
                    const newFields = {...prevFields}
                    newFields.metric_id = null;
                    return newFields;
                });
            }
        } else if (key === "frequency") {
            setFrequency(value);
        } else if (key === "term_count") {
            setTermCount(value);
        } else if (key === "has_minimum_spend") {
            setHasMinimumSpend(value);
            props.setInitialFields(prevFields => {
                prevFields.has_minimum_spend = value;
                return prevFields;
            });
        } else if (key === "minimum_spend_schedule.minimum_spend_schedule_type") {
            setMinimumSpendScheduleType(value);
        }
    }

    const processFields = (fields) => {
        const newFields = _.cloneDeep({...fields});
        if (newFields.frequency === "CUSTOM") {
            newFields.frequency = newFields.custom_frequency;
        }
        const basePrice = newFields.base_price
        if (fields.base_type === "yes") {
            newFields.type = "FIXED";
            newFields.proration_type = "PRORATE_ALL";
            newFields.base_price = { value_in_cents: 0, currency: newFields.currency };
            newFields.base_units = newFields.base_units ? parseInt(newFields.base_units) : 0;
            newFields.item_id = licenseItem && licenseItem.id;
            newFields.fixed_price = { price_per_unit: parseFloat(basePrice) * 100 }
        } else {
            if (!selectedItem) {
                newFields.proration_type = "PRORATE_ALL"
            }
            newFields.item_id = newFields.item_id;
            newFields.base_price = { value_in_cents: parseFloat(basePrice) * 100, currency: newFields.currency };
            newFields.base_units = newFields.base_units ? parseInt(newFields.base_units) : 0;
            if (newFields.fixed_price) {
                newFields.fixed_price.price_per_unit = parseFloat(newFields.fixed_price.price_per_unit) * 100;
            } else if (newFields.step_price) {
                newFields.step_price.step_size = parseFloat(newFields.step_price.step_size);
                newFields.step_price.price_per_step = parseFloat(newFields.step_price.price_per_step)  * 100;
            } else if (newFields.gradient_price) {
                _.each(newFields.gradient_price, (p, i) => {
                    newFields.gradient_price[i].start = newFields.gradient_price[i].start ? parseInt(newFields.gradient_price[i].start) : 0
                    newFields.gradient_price[i].end = newFields.gradient_price[i].end ? parseInt(newFields.gradient_price[i].end): 2147483648
                    newFields.gradient_price[i].price_per_unit = parseFloat(newFields.gradient_price[i].price_per_unit) * 100
                    newFields.gradient_price[i].flat_price = parseFloat(newFields.gradient_price[i].flat_price) * 100
                })

                newFields.gradient_price = _.values(newFields.gradient_price);
            } else if (newFields.volume_price) {
                _.each(newFields.volume_price, (p, i) => {
                    newFields.volume_price[i].total_units_start = newFields.volume_price[i].total_units_start ? parseInt(newFields.volume_price[i].total_units_start) : 0
                    newFields.volume_price[i].total_units_end = newFields.volume_price[i].total_units_end ? parseInt(newFields.volume_price[i].total_units_end): 2147483648
                    newFields.volume_price[i].price_per_unit = parseFloat(newFields.volume_price[i].price_per_unit) * 100
                    newFields.volume_price[i].flat_price = parseFloat(newFields.volume_price[i].flat_price) * 100
                })

                newFields.volume_price = _.values(newFields.volume_price);
            }
        }
        if (!_.isNil(newFields.has_minimum_spend)) {
            delete newFields['has_minimum_spend'];
            newFields.minimum_spend = {
                value_in_cents: parseFloat(newFields.minimum_spend) * 100,
                currency: newFields.currency
            }
            if (newFields.minimum_spend_schedule.minimum_spend_schedule_type === "CUSTOM") {
                newFields.minimum_spend_schedule.amount_per_period = _.map(_.values(newFields.minimum_spend_schedule.amount_per_period), v => parseFloat(v)*100);
            }
        }
        return newFields;
    }

    const toggleShowAdvanced = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setShowAdvanced(!showAdvanced);
    }

    const metricOptions = getMetricOptionsForItem(selectedItem);

    const minimumSpendDistributionTypeOptions = [
        { value: "EVENLY", label: "Evenly" },
        { value: "CUSTOM", label: "Custom" },
    ]

    return (
        <>
            <Row>
                <BaseForm.Input colSpan="6" name="frequency" label="Billing Period" type="select" options={frequencyOptions} showSearch={false} />
                <BaseForm.Input colSpan="6" name="currency" label="Currency" type="select" options={getCurrencyOptions()} showSearch={false} />
            </Row>
            {
                frequency === "CUSTOM" &&
                    <Row>
                        <Col md="6">
                            <BaseForm.InputGroup>
                                <BaseForm.Number name="term_count" min="1" step="1"/>
                                <BaseForm.Divider />
                                <BaseForm.SingleSelect
                                    name="custom_frequency" options={getPlanLengthOptions()} showSearch={false}
                                    fullWidth
                                />
                            </BaseForm.InputGroup>
                        </Col>
                    </Row>
            }
            <Row>
                <BaseForm.Input colSpan="6" name="charge_type" label={t('billable_item_pricing.charge_type')} type="select" options={chargeTypeOptions} showSearch={false} />
            </Row>
            <Row>
                <Col md="6">
                    <BaseForm.Input colSpan="12" name="base_type" label="Pricing Type" type="select" options={baseTypeOptions} showSearch={false} />
                </Col>
                {
                    baseType === "yes" &&
                        <BaseForm.Input colSpan="6" name="base_price" label={t('billable_item_pricing.base_price')} type="number" step="0.000001" min="0" required />
                }
            </Row>
            {
                baseType === "no" &&
                    <>
                    {
                        _.isEmpty(billableItems) && false ?
                            <Row>
                                <div className="body1 error">In order to create a pricing with variable counting, we need to
                                create a billable item first. <Link onClick={onCreateBillableItem}>Get started here.</Link></div>
                            </Row>
                        : <>
                            <Row>
                                <div className="body2">Variable Pricing Details</div>
                                <BaseForm.Input colSpan="6" name="item_id" label={t('billable_item.item')} type="select"
                                    description="What item is being counted?"
                                    options={billableItemOptions} showSearch={false} hideLabel />
                                {
                                    props.shouldSelectMetric && _.isEmpty(billableItems) &&
                                        <Col md={"6"}>
                                            <div className="body1 flex flex-col justify-center h-full">
                                                <div>
                                                    <span>Interested in usage based billing? </span>
                                                    <span className="error">In order to create a pricing with variable counting, we need to
                                                    create a billable item first. <Link onClick={onCreateBillableItem}>Get started here.</Link>
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                }
                                {
                                    props.shouldSelectMetric && selectedItem && metricOptions.length > 0 &&
                                        <BaseForm.Input
                                            type="select" colSpan={"6"} label="Metric"
                                            description="Metric used to count the item"
                                            name={`metric_id`} showSearch={false}
                                            options={metricOptions} hideLabel />
                                }
                                {
                                    props.shouldSelectMetric && selectedItem && metricOptions.length === 0 &&
                                        <Col md={"6"} className="flex flex-col gap-1">
                                            <span className="text-sm text-gray-700">What metric should be used to count the item?</span>
                                            <span className="text-sm text-gray-400">No metrics found for the item. <Link onClick={props.onCreateBillableMetric}>Create one</Link></span>
                                        </Col>
                                }
                            </Row>
                            <Row>
                                <BaseForm.Input colSpan="6" name="base_price" label={t('billable_item_pricing.base_price')}
                                    description="Base price for the plan"
                                    type="number" step="0.01" min="0" hideLabel required/>
                                <BaseForm.Input colSpan="6" name="base_units" label={t('billable_item_pricing.base_units')}
                                    description="Number of units included in the plan"
                                    type="number" step="1" min="0" hideLabel required/>
                                <span className="body1">Pricing scheme to be used when calculating price based on usage</span>
                                <BaseForm.Input
                                    colSpan="6" name="type" label={t('billable_item_pricing.pricing_type')} type="select"
                                    options={typeOptions} showSearch={false} hideLabel />
                            </Row>
                            {
                                type === "FIXED" &&
                                    <>
                                        <span className="body1">{t('billable_item_pricing.fixed_price.price_per_unit')}</span>
                                        <Row>
                                            <BaseForm.Input colSpan="6" name="fixed_price.price_per_unit" label={t('billable_item_pricing.fixed_price.price_per_unit')} type="number" validations={{ required: true, min: 0 }} step="0.000001" min="0" leftContent="$" hideLabel />
                                        </Row>
                                    </>
                            }
                            {
                                type === "STEP" &&
                                    <>
                                        <span className="body1">{t('billable_item_pricing.step_price.price_per_step')}</span>
                                        <Row>
                                            <BaseForm.Input type="number" colSpan="4" name="step_price.price_per_step"
                                                label={t('billable_item_pricing.step_price.price_per_step')}
                                                validations={{ required: true, min: 0 }} step="0.000001" min="0" hideLabel />
                                            <Col md="1" className="d-flex align-items-center body1">
                                                <BaseForm.Label>per</BaseForm.Label>
                                            </Col>
                                            <BaseForm.Input type="number" colSpan="3" name="step_price.step_size"
                                                label={t('billable_item_pricing.step_price.step_size')}
                                                validations={{ required: true, min: 1 }} step="1" min="1" hideLabel />
                                            <Col md="2" className="d-flex align-items-center body1">
                                                <BaseForm.Label>unit(s)</BaseForm.Label>
                                            </Col>
                                        </Row>
                                    </>
                            }
                            {
                                type === "GRADIENT" &&
                                    <>
                                        <p className="text-xs">
                                            Gradient pricing is based on tiers. The pricing is based on the summation of prices for each tier that matches up to the volume. Price per unit and flat price are both dollar units.
                                        </p>
                                        <Table borderless>
                                            <thead>
                                                <tr className="thin thin-horizontal">
                                                    <th></th>
                                                    <th>First</th>
                                                    <th>Last</th>
                                                    <th>Price Per Unit</th>
                                                    <th>Flat Price</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                _.map(_.range(numberOfGradientRows), (r, i) =>
                                                    <tr key={i} className="thin thin-horizontal">
                                                        <td>
                                                        {
                                                            i === 0 ?
                                                                <span>For first</span>
                                                            : (
                                                                i === numberOfGradientRows - 1 ?
                                                                    <span>For last</span>
                                                                : <span>For next</span>
                                                            )
                                                        }
                                                        </td>
                                                        <td>
                                                        {
                                                            i === 0 ?
                                                                <p>0</p>
                                                            : <BaseForm.Input type="number" errorLabel={t('billable_item_pricing.gradiant_price.start')} 
                                                                name={`gradient_price.${r}.start`} step="1" disabled={i === 0} min="0" required/>
                                                        }
                                                        </td>
                                                        <td>
                                                        {
                                                            i === (numberOfGradientRows - 1) ?
                                                                <p>&#8734;</p>
                                                            : <BaseForm.Input type="number" errorLabel={t('billable_item_pricing.gradiant_price.end')} 
                                                                name={`gradient_price.${r}.end`} step="1" disabled={i === (numberOfGradientRows - 1)} min="0" required/>
                                                        }
                                                        </td>
                                                        <td><BaseForm.NumberGroup errorLabel={t('billable_item_pricing.gradiant_price.price_per_unit')} 
                                                                name={`gradient_price.${r}.price_per_unit`} step="0.000001" min="0" required/></td>
                                                        <td><BaseForm.NumberGroup errorLabel={t('billable_item_pricing.gradiant_price.flat_price')} 
                                                                name={`gradient_price.${r}.flat_price`} step="0.000001" min="0" required/></td>
                                                        <td>
                                                        {
                                                            i !== (numberOfGradientRows - 1) &&
                                                                <DeleteButton size="sm" onDelete={() => onDeleteGradientRow(i)}/>
                                                        }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                                <tr>
                                                    <td colSpan="2">
                                                        <Button variant="text-primary" className="skinny" onClick={onAddGradientRow}><i className="fa fa-plus"/> Add New</Button>
                                                    </td>
                                                    <td colSpan="4">
                                                        <BaseForm.Input colSpan={12} name={`gradient_price.${numberOfGradientRows-1}.is_custom_tier`} value="Mark last tier as custom pricing" type="check" formInputClassName="justify-end" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>
                            }
                            {
                                type === "VOLUME" &&
                                <>
                                    <p className="text-xs">
                                        Volume pricing is based on tiers. The entire pricing is based on the tier that matches the volume. Price per unit and flat price are both dollar units.
                                    </p>
                                    <Table borderless>
                                        <thead>
                                        <tr className="thin thin-horizontal">
                                            <th></th>
                                            <th>First</th>
                                            <th>Last</th>
                                            <th>Price Per Unit</th>
                                            <th>Flat Price</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            _.map(_.range(numberOfVolumeRows), (r, i) =>
                                                <tr key={i} className="thin thin-horizontal">
                                                    <td>
                                                        {
                                                            i === 0 ?
                                                                <span>For first</span>
                                                                : (
                                                                    i === numberOfVolumeRows - 1 ?
                                                                        <span>For last</span>
                                                                        : <span>For next</span>
                                                                )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            i === 0 ?
                                                                <p>0</p>
                                                                : <BaseForm.Input type="number" errorLabel={t('billable_item_pricing.volume_price.start')} 
                                                                     name={`volume_price.${r}.total_units_start`} step="1" disabled={i === 0} min="0" required/>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            i === (numberOfVolumeRows - 1) ?
                                                                <p>&#8734;</p>
                                                                : <BaseForm.Input type="number" errorLabel={t('billable_item_pricing.volume_price.end')} 
                                                                    name={`volume_price.${r}.total_units_end`} step="1" disabled={i === (numberOfVolumeRows - 1)} min="0" required/>
                                                        }
                                                    </td>
                                                    <td><BaseForm.NumberGroup errorLabel={t('billable_item_pricing.volume_price.price_per_unit')} 
                                                            name={`volume_price.${r}.price_per_unit`} step="0.000001" min="0" required/></td>
                                                    <td><BaseForm.NumberGroup errorLabel={t('billable_item_pricing.volume_price.flat_price')} 
                                                            name={`volume_price.${r}.flat_price`} step="0.000001" min="0" required/></td>
                                                    <td>
                                                        {
                                                            i !== (numberOfVolumeRows - 1) &&
                                                            <DeleteButton size="sm" onDelete={() => onDeleteVolumeRow(i)}/>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        <tr>
                                            <td colSpan="2">
                                                <Button variant="text-primary" className="skinny" onClick={onAddVolumeRow}><i className="fa fa-plus"/> Add New</Button>
                                            </td>
                                            <td colSpan="4">
                                                <BaseForm.Input colSpan={12} name={`volume_price.${numberOfVolumeRows-1}.is_custom_tier`} value="Mark last tier as custom pricing" type="check" formInputClassName="justify-end" />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </>
                            }
                            <Row>
                                <div className="body2">Proration details</div>
                                {
                                    !selectedItem &&
                                        <span className="text-sm text-gray-500 italic">* Prices will be prorated for partial periods.</span>
                                }
                                {
                                    selectedItem &&
                                        <>
                                            <BaseForm.Input
                                                colSpan="6" name="proration_type" label={t('billable_item_pricing.proration_type')}
                                                description="How should we prorate the price for partial periods?"
                                                type="select" options={prorationTypeOptions} showSearch={false} hideLabel />
                                        </>
                                }
                            </Row>
                            {
                                selectedItem && selectedItem.type === "CUSTOM_OBJ" &&
                                    <div className="mt-2">
                                        <Link className="text-sm" onClick={toggleShowAdvanced}>
                                            Advanced Options
                                        </Link>
                                        {
                                            showAdvanced &&
                                            <Row className="mt-2">
                                                <p className="text-sm font-medium">True up</p>
                                                <span className="text-sm font-base text-gray-700">True up frequency allows you to re-evaluate the number of billable objects at the specified interval. You can leave it blank if you don't want to true up throughout the billing period. Note: only applies to seat-based prices.</span><br/>
                                                <BaseForm.Input
                                                    colSpan="6" name="true_up_frequency" label={"True up frequency"}
                                                    type="select" options={trueUpOptions} showSearch={false}
                                                    menuPlacement={"top"}
                                                />
                                            </Row>
                                        }
                                    </div>
                            }
                            {
                                selectedItem && selectedItem.type === "CUSTOM_USAGE" &&
                                <div className="mt-2">
                                    <Link className="text-sm" onClick={toggleShowAdvanced}>
                                        Advanced Options
                                    </Link>
                                    {
                                        showAdvanced && frequency !== "CUSTOM" &&
                                        <Row className="mt-2">
                                            <p className="text-sm font-medium">Minimum Commitments</p>
                                            <span className="text-sm font-base text-gray-700">Do you have a minimum commitment for the plan? If the usage cost doesn't meet the minimum commitment in a period, the minimum amount will be charged. The commitment can be spread across multiple periods.</span><br/>
                                            <BaseForm.Input
                                                colSpan="6" name="has_minimum_spend" label={"Has Minimum Commitment?"}
                                                type="switch"
                                            />
                                        </Row>
                                    }
                                    {
                                        showAdvanced && hasMinimumSpend &&
                                        <Row className="mt-2">
                                            <BaseForm.Input colSpan="6" name="term_count" label={`Number of ${getUnitOfTimeFromFrequency(frequency)}`} type="number" />
                                            <BaseForm.Input colSpan="6" name="minimum_spend" label={"Total Minimum Commitment"} type="number" />
                                            <BaseForm.Input
                                                colSpan="6" name="minimum_spend_schedule.minimum_spend_schedule_type" label="How is commitment distributed?" type="select"
                                                options={minimumSpendDistributionTypeOptions} showSearch={false}
                                            />
                                            <BaseForm.Input
                                                colSpan="6" name="minimum_spend_schedule.allow_pulling_forward" label="Excess Charges" type="switch"
                                                description="Use excess charges towards reducing minimum commitments in future months."
                                            />
                                            {
                                                minimumSpendScheduleType === "CUSTOM" &&
                                                <Row>
                                                    {
                                                        _.map(_.range(termCount), (c, j) =>
                                                            <BaseForm.Input key={j} colSpan="3" name={`minimum_spend_schedule.amount_per_period.${j}`} label={`${getLabelForSubscriptionLength(frequency)} ${j+1}`} type="text" required />
                                                        )
                                                    }
                                                </Row>
                                            }
                                        </Row>
                                    }
                                </div>
                            }
                        </>
                    }
                    </>
            }
        </>
    );
})

const getDefaultFields = (itemToEdit) => {

}

export default ItemPricingFields;
