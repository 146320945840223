import '../../App.scss';
import '../../css/invoice.scss';
import React, { useEffect, useState, useContext } from 'react';
import {Link, useParams} from 'react-router-dom';
import {serverFetch} from '../../helpers/server';
import {
    BaseContext,
    currencyFormatFromPrice,
    getCustomerNameOrEmail,
    UserContext
} from '../../helpers/common';
import Loader from '../../components/Loader';
import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import MapleTable from "../../components/MapleTable";
import KeyValueDisplay2 from "../../components/KeyValueDisplay2";
import Section from "../../components/Section";
import moment from "moment/moment";
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import DropdownMenu from "../../components/DropdownMenu";
import EditCreditNoteModal from "../../components/modals/EditCreditNoteModal";
import {getCreditNoteReasonDescription, getCreditNoteRefundTypeDescription} from "../../helpers/invoices";
import CopyableComponent from "../../components/CopyableComponent";
const _ = require('lodash');

function InvoiceCreditNote() {
    const { uuid, noteId } = useParams();
    const { userInfo } = useContext(UserContext);
    const { getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);

    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [creditNoteDetails, setCreditNoteDetails] = useState({});
    const [details, setDetails] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);

    const hasWritePermissions = hasAccess("invoices", userInfo, "write");

    useEffect(() => {
        setPageTitle(`Credit Note - Invoice - ${invoiceDetails.number}`);
    }, [invoiceDetails]);

    useEffect(() => {
        if (_.isEmpty(creditNoteDetails)) {
            return;
        }
        const data = {
            'Customer': <Link to={getCompanySpecificUrl(`/customer/${creditNoteDetails.customer.id}`)}>{getCustomerNameOrEmail(creditNoteDetails.customer)}</Link>,
            'ID': <CopyableComponent value={creditNoteDetails.id}></CopyableComponent>,
            'Date': moment(creditNoteDetails.effective_date).format("DD MMM, YYYY h:mm:ssa"),
            'Invoice': <Link to={getCompanySpecificUrl(`/invoice/${creditNoteDetails.invoice_uuid}`)}>{ creditNoteDetails.invoice.number }</Link>,
            'Credit Amount': currencyFormatFromPrice(creditNoteDetails.total),
            'Internal Reason': getCreditNoteReasonDescription(creditNoteDetails.internal_reason),
            'Note': creditNoteDetails.note,
            'Internal Note': creditNoteDetails.internal_note,
        };
        setDetails(data);
    }, [creditNoteDetails]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/invoices/${uuid}`)).then((res) => {
            setInvoiceDetails(res)
        })

        serverFetch(getApiUrl(`/credit_notes/${noteId}`)).then((res) => {
            setCreditNoteDetails(res)
        })
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onModalClose = (didUpdate) => {
        setShowEditModal(false);
        if (didUpdate) {
            fetchData(true);
        }
    }

    const actionOptions = hasWritePermissions ? [
        { id: "edit", label: "Edit" },
    ]: [];

    const onActionSelected = (action) => {
        console.log("Action selected " + action);
        if (action === "edit") {
            setShowEditModal(true);
        }
    }

    const renderCreditRefundOptions = () => {
        if (!creditNoteDetails.refund_options || _.isEmpty(creditNoteDetails.refund_options.items)) {
            return;
        }

        return (
            <div>
                <span className="text-lg font-semibold text-gray-900">Credits Issued</span>
                <MapleTable className="w-full">
                    <MapleTable.Content>
                        <thead>
                        <tr>
                            <MapleTable.TH>Refund Type</MapleTable.TH>
                            <MapleTable.TH className="text-end">Amount</MapleTable.TH>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(creditNoteDetails.refund_options.items, (item, j) => {
                                return (
                                    <tr key={j}>
                                        <td>{getCreditNoteRefundTypeDescription(item.refund_type)}</td>
                                        <td className="text-end">{currencyFormatFromPrice(item.amount)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
            </div>
        )
    }

    const renderCustomActions = () => {
        return (
            <DropdownMenu className="p-2" items={actionOptions} onClick={onActionSelected}>
                <div className="flex flex-row gap-1 items-center">
                    <WrenchScrewdriverIcon className="h-4 w-4"/>
                    <span>Actions</span>
                </div>
            </DropdownMenu>
        )
    }

    return (
        <BaseContainer>
            <Loader loading={_.isEmpty(invoiceDetails)}>
                {
                    () =>
                        <ContentContainer title={`Credit Note for Invoice ${invoiceDetails.number}`} customActions={renderCustomActions}>
                            <Section className="mt-4" title="Details" actions={[]}>
                                <KeyValueDisplay2 items={details} />
                            </Section>
                            <span className="text-lg font-semibold text-gray-900">Credit Line Items</span>
                            <MapleTable className="w-full">
                                <MapleTable.Content>
                                    <thead>
                                    <tr>
                                        <MapleTable.TH>Description</MapleTable.TH>
                                        <MapleTable.TH>Quantity</MapleTable.TH>
                                        <MapleTable.TH className="text-end">Price</MapleTable.TH>
                                        <MapleTable.TH className="text-end">Total Amount</MapleTable.TH>
                                        <MapleTable.TH className="text-end">Credit Amount</MapleTable.TH>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(creditNoteDetails.line_items, (item, i) => {
                                            const lineItem = _.find(invoiceDetails.line_items, (a) => a.id === item.line_item_id);
                                            return (
                                                <tr key={i}>
                                                    <td>{lineItem.description}</td>
                                                    <td>{lineItem.quantity}</td>
                                                    <td className="text-end">{currencyFormatFromPrice(lineItem.unit_price)}</td>
                                                    <td className="text-end">{currencyFormatFromPrice(lineItem.amount)}</td>
                                                    <td className="text-end">{currencyFormatFromPrice(item.amount)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>

                            { renderCreditRefundOptions() }
                            <EditCreditNoteModal
                                show={showEditModal}
                                creditNote={creditNoteDetails}
                                onClose={onModalClose}
                            />
                        </ContentContainer>
                }
            </Loader>
        </BaseContainer>
    );
}

export default InvoiceCreditNote;
