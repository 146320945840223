import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch, serverPatch } from '../../../helpers/server';
import { BaseContext } from '../../../helpers/common';
import BaseContainer from '../../../components/BaseContainer';
import Notification from '../../../components/Notification';
import Section from "../../../components/Section";
import SectionNav from '../../../components/SectionNav';
import { Outlet } from 'react-router-dom';
const _ = require('lodash');

function InvoiceSettings() {
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        setPageTitle(`Invoice Settings`);
    }, []);

    useEffect(() => {
        fetchData(true);
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl("/settings"), { skipCache: skipCache }).then((res) => {
            if (res) {
                processSettings(res);
            }
        });
    }

    const processSettings = (res) => {
        setSettings(res);
    }

    const updateSettings = async (data) => {
        if (data.invoice_logo_url === "") {
            data.invoice_logo_url = null;
        }
        if (!_.isNil(data.invoice_payment_terms)) {
            if (data.invoice_payment_terms !== "custom") {
                data.invoice_due_date_from_creation = parseInt(data.invoice_payment_terms);
            }
        }
        if (data.cc_email_addresses) {
            data.cc_email_addresses = _.filter(_.map(data.cc_email_addresses.split(","), (e) => e.trim()), e => e);
        } else if (data.cc_email_addresses === "") {
            data.cc_email_addresses = [];
        }
        const result = await serverPatch(getApiUrl("/settings"), data);
        if (result) {
            processSettings(result);
            // fetchData(true);
            Notification.Success("Successfully updated");
        }
    }

    const updateAdditionalFields = (fieldsData) => {
        const data = {
            invoice_additional_display_fields: _.map(fieldsData.invoice_additional_display_fields, (d) => d)
        }
        updateSettings(data);
    }

    const updatePaymentInstructions = (fieldsData) => {
        updateSettings(fieldsData)
    }

    const tabItems = [
        {
            'label': 'General',
            'href': getCompanySpecificUrl("/settings/invoices"),
            'id': 'general',
        },
        {
            'label': 'Display Settings',
            'href': getCompanySpecificUrl("/settings/invoices/display"),
            'id': 'display',
        },
        {
            'label': 'Notifications',
            'href': getCompanySpecificUrl("/settings/invoices/notifications"),
            'id': 'notifications',
        },
    ];

    return (
        <BaseContainer>
            <Section title={"Invoice Settings"} variant="page">
                <SectionNav size="sm" items={tabItems} shouldAutoMatch={true} className="mt-2 mb-3"/>
                <Outlet context={{ settings, updateSettings, updatePaymentInstructions, updateAdditionalFields }} />
            </Section>
        </BaseContainer>
    );
}

export default InvoiceSettings;
