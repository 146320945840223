import '../App.scss';
import '../js/forms.js';
import Label from './Label';
import { Link } from 'react-router-dom';
import React, { useEffect, useState, useMemo, useCallback, useContext, memo } from 'react';
import classnames from 'classnames';
import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronUp from '@untitled-ui/icons-react/build/esm/ChevronUp';
import ChevronLeftDouble from '@untitled-ui/icons-react/build/esm/ChevronLeftDouble';
import ChevronRightDouble from '@untitled-ui/icons-react/build/esm/ChevronRightDouble';
import { UserContext, BaseContext, AppContext } from '../helpers/common';
import Profile from './layout/profile/Profile.js';
const _ = require('lodash');

function BaseLeftNav(props) {
	const userContext = useContext(UserContext);
	const baseContext = useContext(BaseContext);
	const { isMobileNavBarVisible, isNavCollapsed, openNav, closeNav } = useContext(AppContext);
	const { userInfo, isMapleUser } = !_.isNil(userContext) ? userContext : { userInfo: {} };
	const { company } = !_.isNil(baseContext) ? baseContext : { company: null };

	const [shouldAutoMatch, setShouldAutoMatch] = useState(true);
	const [isExpanded, setIsExpanded] = useState(false);
	const [handleOnMouseOver, setHandleOnMouseOver] = useState(() => () => {});

	const isItemActive = useCallback(
		(item) => {
			if (item.active) {
				return item.active;
			} else if (shouldAutoMatch) {
				if (item.matchPathnameOnly) {
					return item.link === window.location.pathname;
				} else {
					return item.link === window.location.pathname + window.location.search;
				}
			} else {
				return false;
			}
		},
		[shouldAutoMatch],
	);

	const hasActiveSubItem = useCallback(
		(item) => {
			return item.items.findIndex((si) => isItemActive(si));
		},
		[isItemActive],
	);

	const openedSubMenu = useMemo(() => {
		const openedSubMenuIndex = props.items.findIndex((item, i) => {
			if (!item) return false;
			if (!('items' in item)) return false;

			const activeSubMenu = hasActiveSubItem(item);
			if (activeSubMenu !== -1) {
				return true;
			}
			return false;
		});
		return openedSubMenuIndex;
	}, [hasActiveSubItem, props.items]);

	const [subMenuOpen, setSubMenuOpen] = useState(openedSubMenu !== -1 ? openedSubMenu : null);

	useEffect(() => {
		if (!_.isNil(props.shouldAutoMatch)) {
			setShouldAutoMatch(props.shouldAutoMatch);
		}
	}, [props.shouldAutoMatch]);

	useEffect(() => {
		// handles the mouse over 
		if (!isNavCollapsed) {
			setHandleOnMouseOver(() => (() => {}));
		} else {
			setTimeout(() => {
				setHandleOnMouseOver(() => (() => setIsExpanded(true)));
			}, 400); // transition duration
		}
	}, [isNavCollapsed, isExpanded]);

	const onParentMenuClick = useCallback((index) => {
		setSubMenuOpen(subMenuOpen === index ? null : index);
	}, [subMenuOpen]);

	const CollapseIcon = isNavCollapsed ? ChevronRightDouble : ChevronLeftDouble;

	return (
		<div
			id='left-nav'
			className={classnames(
				'left-nav noprint',
				{
					"fixed": isMapleUser,
					"left-nav-collapsed": isMapleUser && !isMobileNavBarVisible && isNavCollapsed,
					"left-nav-expanded": !isMobileNavBarVisible && isExpanded,
				},
				props.className
			)}
			{...(!isMobileNavBarVisible ? {
				onMouseOver: () => handleOnMouseOver(),
				onMouseLeave: () => setIsExpanded(false),
			} : {})}
		>
			<div>
				<div className='nav-header'>
					{isMapleUser ? (
						<div className="flex items-center justify-between w-full">
							<div className={classnames('nav-header-logo relative', { "nav-header-logo-collapsed": !isMobileNavBarVisible && !isExpanded && isNavCollapsed })}>
								<div className={classnames("hide-maple-block", { "hide-maple-block-collapsed": !isMobileNavBarVisible && !isExpanded && isNavCollapsed })}></div>
							</div>
							{!isMobileNavBarVisible && <CollapseIcon
								width={20}
								height={20}
								className="cursor-pointer"
								onClick={() => {
									if (isNavCollapsed) {
										openNav();
									} else {
										closeNav();
										setIsExpanded(false);
									}
								}}
							/>}
						</div>
					) : <div className='nav-header-logo' />}
				</div>
				<div className='nav-items'>
					{props.items.map((item, i) => {
						return (
							item && (
								<MenuItem
									key={i}
									itemIndex={i}
									label={item.label}
									icon={item.icon}
									link={item.hasSubMenu ? null : item.link}
									active={!item.hasSubMenu && isItemActive(item)}
									isSubmenuActive={openedSubMenu === i}
									showSubMenu={subMenuOpen === i}
									onParentMenuClick={onParentMenuClick}
									hasSubMenu={item.hasSubMenu}
									{...(!isMobileNavBarVisible ? {
										collapsed: isNavCollapsed,
										isExpanded: isExpanded,
										isMapleUser: isMapleUser,
									} : {})}
								>
									{isMapleUser ? (!isMobileNavBarVisible ? (isExpanded || !isNavCollapsed) : true) && item.hasSubMenu && (
										<div
											className={classnames(
												'sub-items',
												hasActiveSubItem(item) !== -1 ? 'show' : '',
											)}
										>
											{item.items.map((subItem, j) => {
												return (
													subItem && (
														<MenuItem
															key={j}
															label={subItem.label}
															link={subItem.link}
															active={isItemActive(subItem)}
															tag={subItem.tag}
														/>
													)
												);
											})}
										</div>
									) : (!isMapleUser && item.hasSubMenu) && (
										<div
											className={classnames(
												'sub-items',
												hasActiveSubItem(item) !== -1 ? 'show' : '',
											)}
										>
											{item.items.map((subItem, j) => {
												return (
													subItem && (
														<MenuItem
															key={j}
															label={subItem.label}
															link={subItem.link}
															active={isItemActive(subItem)}
															tag={subItem.tag}
														/>
													)
												);
											})}
										</div>
									)}
								</MenuItem>
							)
						);
					})}
				</div>
			</div>
			<div className={classnames('nav-footer', { 'nav-footer-collapsed': isMapleUser && !isMobileNavBarVisible ? (!isExpanded && isNavCollapsed) : false })}>
				<Profile
					userInfo={userInfo}
					company={company}
					isExpanded={isExpanded}
				/>
			</div>
		</div>
	);
}

const MenuItem = memo(({ isMapleUser, itemIndex, link, onParentMenuClick, ...props }) => {
	const MenuLink = useCallback(({ children }) => {
		return (
			<Link className='nav-items-link' to={link} onClick={(e) => e.stopPropagation()}>
				{children}
			</Link>
		);
	}, [link]);

	const ParentMenu = useCallback(({ children }) => {
		return (
			<div
				className='parent-menu'
				onClick={() => {
					onParentMenuClick(itemIndex);
				}}
			>
				{children}
			</div>
		);
	}, [onParentMenuClick, itemIndex]);

	const activeClass = props.active ? 'active' : '';
	const openClass = props.isSubmenuActive ? 'open' : '';

	const ParentComponent = props.hasSubMenu ? ParentMenu : MenuLink;

	return (
		<ParentComponent>
			<div className={classnames('nav-item-container', openClass, activeClass)}>
				<div className={classnames('nav-item')}>
					<div className='nav-item-logo'>{props.icon}</div>
					<div className={classnames(
						"nav-title",
						{ "nav-title-collapsed": isMapleUser && (!props.isExpanded && props.collapsed) },
					)}>
						<span className='text-base'>{props.label}</span>
						{props.tag && <Label.Info className='text-sm !py-0 ml-2 opacity-80'>{props.tag}</Label.Info>}
					</div>
					{!_.isEmpty(props.children) && (
						<div className='nav-item-chevron'>
							{props.showSubMenu ? (
								<ChevronUp width={16} height={16} />
							) : (
								<ChevronDown width={16} height={16} />
							)}
						</div>
					)}
				</div>
			</div>
			{props.showSubMenu && <>{props.children}</>}
		</ParentComponent>
	);
});

export default BaseLeftNav;
