import { createRef, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BaseForm, { getValue } from "../../../components/BaseForm";
import { getInvoicePaymentTermFromDueDateFromCreation, getInvoicePaymentTermOptions } from "../../../helpers/common";
import _ from "lodash";
import { useOutletContext } from "react-router-dom";
import ContentBox from "../../../components/ContentBox";
import SubmitButton from "../../../components/common/buttons/SubmitButton";

const paymentMechanismOptions = [
    { value: true, label: "Automatically charge a payment method on file"},
    { value: false, label: "Invoice the customer" },
];

const InvoiceSettingsGeneral = () => {
    const { settings, updateSettings } = useOutletContext();
    const [invoicePaymentTerms, setInvoicePaymentTerms] = useState(null);
    const invoiceSettingsRef = createRef();

    const initialFields = useMemo(() => {
        const ipt = getInvoicePaymentTermFromDueDateFromCreation(settings.invoice_due_date_from_creation)
        setInvoicePaymentTerms(ipt);
        return {
            ..._.cloneDeep(settings),
            invoice_payment_terms: ipt,
        }
    }, [settings]);

    const onFieldChange = async (name, value) => {
        if (name === "invoice_payment_terms") {
            setInvoicePaymentTerms(value);
        //     let oldValue = getInvoicePaymentTermFromDueDateFromCreation(settings.invoice_due_date_from_creation);
        //     if (oldValue !== value && value !== "custom") {
        //         if (invoiceSettingsRef.current) {
        //             invoiceSettingsRef.current.submitForm();
        //         }
        //     }
        // } else if (_.includes(["auto_charges"], name)) {
        //     let oldValue = getValue(settings, name, null);
        //     if (oldValue !== value) {
        //         if (invoiceSettingsRef.current) {
        //             invoiceSettingsRef.current.submitForm();
        //         }
        //     }
        // } else if (name === "invoice_logo_url") {
        //     if (invoiceSettingsRef.current) {
        //         invoiceSettingsRef.current.submitForm();
        //     }
        }
    };

    const submitInvoiceSettingsForm = (event) => {
        if (invoiceSettingsRef.current) {
            invoiceSettingsRef.current.submitForm();
        }
    }

	return (
        <ContentBox>
            <ContentBox.Title>General Settings</ContentBox.Title>
            <ContentBox.Body>
                <Row>
                    <Col md="8" xxl="6">
                        <BaseForm ref={invoiceSettingsRef} initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={updateSettings}>
                            <Row>
                                <BaseForm.Input type="text" colSpan="6" name="invoice_prefix" label="Invoice Number Prefix" />
                                <BaseForm.Input type="number" colSpan="6" name="invoice_next_seq_num" label="Invoice Number Next" />
                                <BaseForm.Input type="file" colSpan="12" name="invoice_logo_url" label="Invoice Logo" fileType="invoiceLogo" accept={"image/png,image/jpeg"} />
                                <BaseForm.Input type="select" colSpan="12" name="auto_charges" label="Billing Method" options={paymentMechanismOptions} showSearch={false} />
                                <BaseForm.Input type="select" colSpan="6" name="invoice_payment_terms" label="Invoice Payment Terms" options={getInvoicePaymentTermOptions()} showSearch={false} />
                                {
                                    invoicePaymentTerms === "custom" &&
                                        <BaseForm.Input type="number" colSpan="6" name="invoice_due_date_from_creation" label="Due Date (in days)" step="1" min="0" />
                                }
                            </Row>
                            <Row>
                                <BaseForm.Input
                                    type="number" colSpan="12" name="invoice_event_grace_period" label="Invoice Event Grace Period (in seconds)"
                                    description="Delay in invoice generation to ensure grace time to collect all related billable events."
                                />
                            </Row>
                            <SubmitButton>Update</SubmitButton>
                        </BaseForm>
                    </Col>
                </Row>
            </ContentBox.Body>
        </ContentBox>
    );
};

export default InvoiceSettingsGeneral;
