import classnames from 'classnames';

// icons
import Hourglass03 from '@untitled-ui/icons-react/build/esm/Hourglass03';
import Check from '@untitled-ui/icons-react/build/esm/Check';
import X from '@untitled-ui/icons-react/build/esm/X';

const variantStyles = {
    check: "!border-[#ABEFC6] bg-[#ECFDF3]",
    hourglass: "!border-[#FEDF89] bg-[#FFFAEB]",
    x: "!border-[#FECDCA] bg-[#FEF3F2]",
};

const variantTextStyles = {
    check: "text-[#067647]",
    hourglass: "text-[#B54708]",
    x: "text-red-dark",
}

const variantIcon = {
    check: <Check width={13} height={13} className="text-[#17B26A]"/>,
    hourglass: <Hourglass03 width={11} height={11} className="text-[#F79009]"/>,
    x: <X width={13} height={13} className="text-red-base"/>,
}

const Status = ({ className, variant, children }) => {
    return (
        <span className={classnames("border flex justify-center items-center py-1 px-[10px] gap-1 rounded-full text-xs", variantStyles[variant], className)}>
            {variantIcon[variant]}
            <span className={classnames("font-medium", variantTextStyles[variant])}>{children}</span>
        </span>
    );
};

export default Status;