import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import MapleTableHeaderWithActions from '../../components/MapleTableHeaderWithActions';
import { serverPost } from '../../helpers/server';
import {
	BaseContext,
	currencyFormatFromPrice,
	getCommissionAdjustmentReason,
	getLabelForCommissionEventLog
} from '../../helpers/common';
import InfiniteScroll from 'react-infinite-scroll-component';
import SortableTableHeader from '../../components/SortableTableHeader';
import moment from 'moment/moment';
import {useNavigate, useSearchParams} from "react-router-dom";
import Columns from "../../components/Columns";
const _ = require('lodash');

function CommissionEventLogs(props) {
	const navigate = useNavigate();
	const [events, setEvents] = useState([]);
	const [loading, setLoading] = useState(true);
	const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
	const [sort, setSort] = useState('createdAtDesc');
	const [fromKey, setFromKey] = useState(null);
	const [meta, setMeta] = useState(null);
	const [hasMore, setHasMore] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		setPageTitle('Commission Event Logs');
	}, []);

	const onSearch = (restart = true) => {
		const limit = 50;
		const params = {
			query: {
				quota_period_id: props.quotaPeriodId,
				commission_deal_id: props.dealId,
			},
			sort_key: sort || 'createdAtDesc',
			pagination: {
				from_key: restart ? null : fromKey,
				limit: limit,
			},
			include_meta: restart,
		};
		if (props.reportIDs) {
			params.query.report_period_ids = {
				condition: "OR",
				values: props.reportIDs
			}
		}
		serverPost(getApiUrl('/commission/event_logs/find'), params).then((res) => {
			if (res) {
				const results = res.results || [];
				if (restart) {
					setEvents(results);
				} else {
					setEvents(_.concat(events, results));
				}
				if (res.meta) {
					setMeta(res.meta);
				}

				setFromKey(res.pagination.from_key);
				setHasMore(results.length === limit);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		onSearch();
	}, [sort, props.reportIDs, props.events]);

	const getEventLabel = (event) => {
		const events = {
			CONTRACT_EXECUTED: 'Contract Executed',
			FIRST_INVOICE_PAID: 'First Invoice Paid',
			INVOICE_PAID: 'Invoice Paid',
			SUBSCRIPTION_CANCELLED: 'Subscription Cancelled'
		};
		return events[event] || event;
	};

	return (
		<ContentContainer className={props.isComponent && 'bg-white'}>
			<InfiniteScroll
				next={() => onSearch(false)}
				hasMore={hasMore}
				scrollableTarget='content-wrapper'
				dataLength={events.length}
			>
				<Section loading={loading} title={!props.isComponent && 'Commission Event Logs'}>
					<MapleTable>
						{!props.isComponent &&
							<MapleTableHeaderWithActions
								showExport={true}
							/>
						}
						<MapleTable.Content>
							<thead>
								<tr>
									<SortableTableHeader
										onSortChange={setSort}
										sortKeyUp='createdAtAsc'
										sortKeyDown='createdAtDesc'
										currentSort={sort}
									>
										Created Timestamp
									</SortableTableHeader>
									<MapleTable.TH>Event Type</MapleTable.TH>
									<MapleTable.TH className='text-end'>Event Value</MapleTable.TH>
									<MapleTable.TH className='text-end'>Commission Amount</MapleTable.TH>
									<MapleTable.TH className='text-end'>Quota Impact</MapleTable.TH>
									<MapleTable.TH></MapleTable.TH>
									{/*<MapleTable.TH>Is Adjustment</MapleTable.TH>*/}
									{/*<MapleTable.TH>Adjustment Reason</MapleTable.TH>*/}
								</tr>
							</thead>
							<tbody className='divide-y divide-gray-200'>
								{events.map((event, i) => (
									<MapleTable.TR
										key={i}
										onClick={() =>
											navigate(getCompanySpecificUrl(`/commissions/event_logs/${event.id}`))
										}
										className='cursor-pointer hover:bg-gray-50'
									>
										<td>{moment(event.created_at).format('MMM D, YYYY hh:mm')}</td>
										<td>{getEventLabel(event.event_type)}</td>
										<td className='text-end'>{currencyFormatFromPrice(event.commission_event_value)}</td>
										<td className='text-end'>{currencyFormatFromPrice(event.commission_amount)}</td>
										<td className='text-end'>{!event.is_adjustment ?
											currencyFormatFromPrice(event.calculation_result.incremental_quota_achievement) : "$0"}</td>
										<td>{getLabelForCommissionEventLog(event)}</td>

										{/*<td>{event.is_adjustment ? 'Yes' : 'No'}</td>*/}
										{/*<td>*/}
										{/*	{event.is_adjustment ? (*/}
										{/*		<span>{getCommissionAdjustmentReason(event.adjustment_reason)}</span>*/}
										{/*	) : (*/}
										{/*		<span className='text-gray-500'>-</span>*/}
										{/*	)}*/}
										{/*</td>*/}
									</MapleTable.TR>
								))}
							</tbody>
						</MapleTable.Content>
					</MapleTable>
				</Section>
			</InfiniteScroll>
		</ContentContainer>
	);
}

export default CommissionEventLogs;
