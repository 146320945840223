import '../../../App.scss';
import React, { useContext } from 'react';
import { BaseContext } from '../../../helpers/common';
import BaseContainer from '../../../components/BaseContainer';
import Section from '../../../components/Section';
import SectionNav from "../../../components/SectionNav";
import { Outlet, useOutletContext } from "react-router-dom";

function ContractSettingsContainer() {
    const { getCompanySpecificUrl } = useContext(BaseContext);
    const outletContext = useOutletContext();

    const tabItems = [{
        'label': 'Templates',
        'href': getCompanySpecificUrl("/settings/contracts"),
        'id': 'templates',
    }, {
        'label': 'Approval Rules',
        'href': getCompanySpecificUrl("/settings/contracts/approvals"),
        'id': 'approvals',
    }, {
        'label': 'Custom Variables',
        'href': getCompanySpecificUrl("/settings/contracts/variables"),
        'id': 'variables',
    }, {
        'label': 'General',
        'href': getCompanySpecificUrl("/settings/contracts/general"),
        'id': 'general',
    }]

    return (
        <BaseContainer>
            <Section title={"Contract Settings"} variant="page">
                <SectionNav size="sm" items={tabItems} shouldAutoMatch={true} className="mt-2"/>
                <Outlet context={outletContext} />
            </Section>
        </BaseContainer>
    );
}

export default ContractSettingsContainer;
