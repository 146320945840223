import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect, useContext, createRef } from 'react';
import { Link } from 'react-router-dom';
import Button from '../common/buttons/Button';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import { Row, Col } from 'react-bootstrap';
import StripeSetupPaymentMethod from '../StripeSetupPaymentMethod';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import AuthorizeNetPaymentInput from "../AuthorizeNetPaymentInput";
import Notification from "../Notification";
import {serverPost} from "../../helpers/server";
import variables from "../../App.scss";
const _ = require('lodash');

function SetupPaymentMethodModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext)
    const [paymentConfig, setPaymentConfig] = useState({});
    const [errorFields, setErrorFields] = useState(null);
    const setupRef = createRef();

    useEffect(() => {
        setPaymentConfig(props.paymentConfig);
    }, [props.paymentConfig])

    const renderPaymentProviderNotSetup = () => {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>Payment Provider not setup</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <p>No payment provider has been setup for your company. Please connect your existing payment provider to manage customer's payment methods.</p>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Link to={getCompanySpecificUrl("/integrations")}><Button variant="primary">Connect</Button></Link>
                </BaseModal.Footer>
            </BaseModal>
        )
    }

    const renderPaymentProviderNotSupported = (paymentConfig) => {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>{t('common.edit')} Provider not supported</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    The provider { paymentConfig.provider } is not supported. Please contact your company admin.
                </BaseModal.Body>
                <BaseModal.Footer>
                </BaseModal.Footer>
            </BaseModal>
        )
    }

    const errorHandler = async (res) => {
        const errorResponse = await res.json();
        setErrorFields({
            error: errorResponse.error_message
        });
    }

    const onSubmit = async (data) => {
        if (_.includes(["AUTHORIZE_NET", "authorize_net"], paymentConfig.provider)) {
            if (setupRef.current) {
                const { paymentMethodData, error } = await setupRef.current.getPaymentMethodData(data);
                if (error) {
                    setErrorFields(error);
                    return;
                }
                serverPost(getApiUrl(`/customers/${props.customer.id}/payment_methods`), paymentMethodData, {}, errorHandler).then((res) => {
                    if (res) {
                        Notification.Success("Successfully added payment method");
                        if (props.onClose) {
                            props.onClose(true);
                        }
                    }
                });
            }
        } else {
            if (setupRef.current) {
                await setupRef.current.onSubmit(data);
            }
        }
    }
    const domain = window.location.origin;
    const relativeUrl = getCompanySpecificUrl(`/customer/${props.customer.id}`)
    const redirectUrl = props.redirectUrl || `${domain}${relativeUrl}`

    if (!props.show) {
        return null;
    }

    if (_.isNil(paymentConfig) || _.isNil(paymentConfig.provider)) {
        return renderPaymentProviderNotSetup();
    } else {
        const isPaymentProviderSupported = _.includes(["STRIPE", "stripe", "AUTHORIZE_NET", "authorize_net"], paymentConfig.provider)
        if (!isPaymentProviderSupported) {
            return renderPaymentProviderNotSupported(paymentConfig)
        } else {
            return (
                <BaseModal {...props}>
                    <BaseModal.Header>
                        <BaseModal.Title>{t('payment_methods.add.title')}</BaseModal.Title>
                    </BaseModal.Header>
                    <BaseForm onSubmit={onSubmit} errorFields={errorFields}>
                        <BaseModal.Body>
                            <div className="body1">
                                Add payment method to { props.customer.name || props.customer.email }
                            </div>
                            {
                                _.includes(["STRIPE", "stripe"], paymentConfig.provider) &&
                                    <div className="payment_input mt-3">
                                        <StripeSetupPaymentMethod ref={setupRef} {...props} redirectUrl={redirectUrl} />
                                    </div>
                            }
                            {
                                _.includes(["AUTHORIZE_NET", "authorize_net"], paymentConfig.provider) &&
                                    <AuthorizeNetPaymentInput ref={setupRef} {...props} />
                            }
                        </BaseModal.Body>
                        <BaseModal.Footer>
                            <SubmitButton variant="primary" color={props.brandColor}>{t('payment_methods.add.action')}</SubmitButton>
                        </BaseModal.Footer>
                    </BaseForm>
                </BaseModal>
            );
        }
    }
}

export default SetupPaymentMethodModal;
