import '../App.scss';
import '../css/modals.scss';
import React, {useState, useEffect, useContext, createRef, forwardRef, useImperativeHandle, useRef} from 'react';
import { serverPost, serverFetch } from '../helpers/server';
import { BaseContext, CustomerContext } from '../helpers/common';
import { useTranslation } from 'react-i18next';
import variables from "../App.scss";
const _ = require('lodash');

const RazorpaySetupPaymentMethod = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data) {
            await onSubmit(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        },
        async getPaymentMethodData(data) {
            return getPaymentMethodData(data);
        },
    }));

    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const customerContext = useContext(CustomerContext);
    const customerAccessToken = customerContext ? customerContext.accessToken: null;
    const [customer, setCustomer] = useState(null);
    const [settings, setSettings] = useState({});
    const [paymentIntent, setPaymentIntent] = useState(null);
    const scriptPromise = useRef(null);
    const paymentIntentPromise = useRef(null);

    function loadScript(src) {
        scriptPromise.current = new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
        return scriptPromise.current;
    }

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }, []);

    const getPaymentMethodData = async (data) => {
        if (scriptPromise.current) {
            const res = await scriptPromise.current;
            if (!res) {
                alert("Razorpay SDK failed to load. Are you online?");
                return;
            }
        }

        let currentPaymentIntent = paymentIntent;
        if (!currentPaymentIntent && paymentIntentPromise.current) {
            currentPaymentIntent = await paymentIntentPromise.current;
        }
        if (!currentPaymentIntent) {
            return
        }

        let brandColor = variables.primaryColor;
        if (props.settings && props.settings.branding) {
            brandColor = props.settings.branding.color || variables.primaryColor;
        }

        return new Promise(function(resolve, reject) {
            const order_id = currentPaymentIntent.id;
            const options = {
                key: currentPaymentIntent.intent_secret, // Enter the Key ID generated from the Dashboard
                order_id: order_id,
                customer_id: currentPaymentIntent.provider_customer_id,
                recurring: currentPaymentIntent.recurring,
                save: 1,
                handler: async function (response) {
                    console.log("The response is " + JSON.stringify(response));
                    const data = {
                        payment_provider_key: "razorpay",
                        integration_id: currentPaymentIntent.integration_id,
                        razorpay: {
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature
                        },
                    };

                    resolve({ paymentMethodData: data, error: null });
                },
                theme: {
                    color: brandColor,
                },
                modal: {
                    "ondismiss": function () {
                        resolve({
                            paymentMethodData: null,
                            error: "Closed",
                        })
                    }
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response) {
                console.log("The failure response is " + JSON.stringify(response));
                resolve({
                    paymentMethodData: null,
                    error: response,
                });
            })
            paymentObject.open();
        });
    }

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, []);

    const fetchPaymentIntent = async (url, options) => {
        paymentIntentPromise.current = serverPost(url, {}, options);
        const intent = await paymentIntentPromise.current;
        if (intent) {
            setPaymentIntent(intent);
        }
    }

    useEffect(() => {
        if (props.setupUrl) {
            const setupOptions = {
                accessToken: props.accessToken || customerAccessToken
            }
            fetchPaymentIntent(props.setupUrl, setupOptions)
        } else if (props.customer) {
            if (_.isEmpty(props.customer)) {
                return;
            }
            const setupData = {
                accessToken: props.accessToken || customerAccessToken
            }
            fetchPaymentIntent(getApiUrl(`/customers/${props.customer.id}/payment_methods/setup`), setupData)
        } else {
            fetchPaymentIntent(getApiUrl(`/payment_methods/setup`), {});
        }
    }, [props.customer])

    const onFieldChange = (name, value) => {
    }

    const onSubmit = async (data) => {
        console.log("on submit " + JSON.stringify(data));
    }

    return (
        <div className="pt-1">
            <p className="text-sm text-gray-500">When you click on the button below, you will be taken to Razorpay interface to complete the processing.</p>
        </div>
    )
})

export default RazorpaySetupPaymentMethod;
