import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import {serverDelete, serverFetch} from '../../helpers/server';
import React, { useContext, useEffect, useState } from 'react';
import {BaseContext, currencyFormatFromPrice, UserContext} from '../../helpers/common';
import {useNavigate, useParams} from 'react-router-dom';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import Section from '../../components/Section';
import moment from 'moment';
import TopBarSummary from "../../components/TopBarSummary";
import ContentBox from "../../components/ContentBox";
import KeyValueDisplay from "../../components/KeyValueDisplay";
import {Button} from "react-bootstrap";
import MapleTable from "../../components/MapleTable";
import SortableTableHeader from "../../components/SortableTableHeader";
import Columns from "../../components/Columns";
import Link from "../../components/Link";
import DropdownMenu from "../../components/DropdownMenu";
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
const _ = require('lodash');

function SalesUser(props) {
	const { id } = useParams();
	const { isMapleUser } = useContext(UserContext);
	const { company, getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
	const [salesUserDetails, setSalesUserDetails] = useState({});
	const [currentReportPeriod, setCurrentReportPeriod] = useState(null);
	const [currentQuotaPeriod, setCurrentQuotaPeriod] = useState(null);
	const [details, setDetails] = useState({});
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const fetchSalesUserData = () => {
		serverFetch(getApiUrl(`/commission/sales_user/${id}`)).then((res) => {
			if (res) {
				setSalesUserDetails(res);
				setCurrentReportPeriod(res.current_report_period)
				setCurrentQuotaPeriod(res.selected_quota_period);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		fetchSalesUserData();
	}, []);

	useEffect(() => {
		if (_.isEmpty(salesUserDetails)) {
			return;
		}
		let data = {
			"ID": salesUserDetails.id,
			"Name": salesUserDetails.company_user.user.name,
			"Email": salesUserDetails.company_user.user.email,
			"Title": salesUserDetails.company_user.title,
			"Commission Plan": salesUserDetails.commission_plans.length > 0 ?
				<Link href={getCompanySpecificUrl(`/commissions/plan/${salesUserDetails.commission_plans[0].id}`)}>
					{salesUserDetails.commission_plans[0].name}
				</Link> : "No Plan Currently Applied"
			// 'Current Quota Period Start': moment(salesUserDetails.selected_quota_period.start_date).format(
			// 	'MMM D, YYYY',
			// ),
			// 'Current Quota Period End': moment(salesUserDetails.selected_quota_period.end_date).format('MMM D, YYYY'),
			// 'Quota Amount': currencyFormatFromPrice(salesUserDetails.selected_quota_period.quota_value),
		};
		setDetails(data);
	}, [salesUserDetails]);

	const handleReportRowClick = (reportPeriodId) => {
		navigate(getCompanySpecificUrl(`/commissions/reports/${reportPeriodId}`));
	};

	const handleQuotaPeriodRowClick = (quotaPeriodId) => {
		navigate(getCompanySpecificUrl(`/commissions/quota_periods/${quotaPeriodId}`));
	}

	const renderReportPeriodOverview = () => {
		if (!currentReportPeriod) {
			return null;
		}
		return (
			<ContentBox>
				<ContentBox.Title
				right={
					<Button variant='primary' onClick={handleViewAllReportsClick}>
						View All Reports
					</Button>
				}
				>
					Current Report Overview
				</ContentBox.Title>
				<ContentBox.Body>
					<MapleTable>
						<MapleTable.Content>
							<thead>
								<tr>
									<MapleTable.TH>Period</MapleTable.TH>
									<MapleTable.TH>Commissions Achieved</MapleTable.TH>
									<MapleTable.TH>Quota Achieved</MapleTable.TH>
									<MapleTable.TH></MapleTable.TH>
								</tr>
							</thead>
							<tbody className='divide-y divide-gray-200'>
								<tr
									// onClick={() => handleReportRowClick(currentReportPeriod.id)}
									// className='cursor-pointer hover:bg-gray-50'
								>
									<td>
										{moment(currentReportPeriod.start_date).format('MMM D, YYYY')} -{' '}
										{moment(currentReportPeriod.end_date).format('MMM D, YYYY')}
									</td>
									<td>{currencyFormatFromPrice(currentReportPeriod.commission_achieved)}</td>
									<td>{currencyFormatFromPrice(currentReportPeriod.quota_achieved)}</td>
									<td>
										<Link href={getCompanySpecificUrl(`/commissions/reports/${currentReportPeriod.id}`)}>
											View Details
										</Link>
									</td>
								</tr>
							</tbody>
						</MapleTable.Content>
					</MapleTable>
				</ContentBox.Body>
			</ContentBox>
		);
	};

	const renderQuotaPeriodOverview = () => {
		if (!currentQuotaPeriod) {
			return null;
		}
		return (
			<ContentBox>
				<ContentBox.Title>
					Current Quota Overview
				</ContentBox.Title>
				<ContentBox.Body>
					<MapleTable>
						<MapleTable.Content>
							<thead>
							<tr>
								<MapleTable.TH>Period</MapleTable.TH>
								<MapleTable.TH>Commissions Achieved</MapleTable.TH>
								<MapleTable.TH>Quota Achieved</MapleTable.TH>
								<MapleTable.TH></MapleTable.TH>
							</tr>
							</thead>
							<tbody className='divide-y divide-gray-200'>
							<tr
								// onClick={() => handleQuotaPeriodRowClick(currentQuotaPeriod.id)}
								// className='cursor-pointer hover:bg-gray-50'
							>
								<td>{moment(currentQuotaPeriod.start_date).format('MMM D, YYYY')} - {moment(currentQuotaPeriod.end_date).format('MMM D, YYYY')}</td>
								<td>{currencyFormatFromPrice(currentQuotaPeriod.commission_achieved)}</td>
								<td>{currencyFormatFromPrice(currentQuotaPeriod.quota_achieved)} / {currencyFormatFromPrice(currentQuotaPeriod.quota_value)}</td>
								<td><Link href={getCompanySpecificUrl(`/commissions/quota_periods/${currentQuotaPeriod.id}`)}>View Details</Link></td>
							</tr>
							</tbody>
						</MapleTable.Content>
					</MapleTable>
				</ContentBox.Body>
			</ContentBox>
		);
	}

	const handleViewAllReportsClick = () => {
		navigate(getCompanySpecificUrl(`/commissions/user_reports?sales_user_id=${id}`));
	};

	const actionOptions = _.compact([
		isMapleUser && {id: "delete_sales_user", label: "Delete Sales User"},
	])

	const onActionSelected = (type) => {
		if (type === "delete_sales_user") {
			serverDelete(getApiUrl(`/commission/sales_user/${id}/full_delete`)).then((res) => {
				if (res) {
					navigate(getCompanySpecificUrl(`/commissions/sales_overview`))
				}
			});
		}
	}

	return (
		<BaseContainer>
			<ContentContainer
				variant={'page'}
				title={!_.isNil(salesUserDetails.company_user) ? salesUserDetails.company_user.user.name : ''}
				customActions={actionOptions.length > 0 &&
					<DropdownMenu className='py-2 px-1' items={actionOptions} onClick={onActionSelected}>
						<div className='flex flex-row gap-1 items-center'>
							<WrenchScrewdriverIcon className='h-4 w-4' />
							<span>Actions</span>
						</div>
					</DropdownMenu>
				}>
				<div className='mt-2'>
					<Section actions={[]}>
						<KeyValueDisplay2 title={'Sales User Details'}  items={details}/>
					</Section>
				</div>
				{renderQuotaPeriodOverview()}
				{renderReportPeriodOverview()}
			</ContentContainer>
		</BaseContainer>
	);
}

export default SalesUser;
