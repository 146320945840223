import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row, Alert } from 'react-bootstrap';
import Button from '../common/buttons/Button';
import Send01 from '@untitled-ui/icons-react/build/esm/Send01';
import Notification from '../Notification';
const _ = require("lodash");

function SingleContractEmailModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});
    const [contract, setContract] = useState({});

    useEffect(() => {
        setInitialFields({
            email: props.contract && getEmails(),
            cc_email: ""
        })
        setContract(props.contract)
    }, [props.contract])

    const onSubmit = (data) => {
        const emails = _.filter(_.map(data.email.split(","), (e) => e.trim()), e => e);
        const ccEmails = _.filter(_.map(data.cc_email.split(","), (e) => e.trim()), e => e);
        const emailData = {
            emails: emails,
            cc_emails: ccEmails,
            message: data.message
        };
        serverPost(getApiUrl(`/proposals/${contract.id}/send_signature_emails`), emailData).then((res) => {
            props.onClose();
            Notification.Success(`${contract.title} shared successfully`);
        });
    }

    const getEmails = () => {
        let emails = ""
        if (props.contract.status === "PENDING_COUNTER_SIGNATURES" && !_.isEmpty(props.contract.counter_signatories)) {
            emails = _.join(_.map(props.contract.counter_signatories, s => s.company_user.user.email), ",")
        } else if (!_.isEmpty(props.contract.signatories)) {
            emails = _.join(_.map(props.contract.signatories, s => s.email), ",")
        }
        return emails
    }

    return (
        <BaseModal {...props}>
            <BaseForm initialFormFields={initialFields} onSubmit={onSubmit}>
                <BaseModal.Header>
                    <BaseModal.Title>{props.updated ? "Share contract with participants" : "Send Contract Email"}</BaseModal.Title>
                    <BaseModal.Subtitle>Send an email with the finalized contract to the other involved participants.</BaseModal.Subtitle>
                </BaseModal.Header>
                <BaseModal.Body>
                    <Alert variant="info">
                        Feel free to add as many emails as you like. Separate each email with a comma such as "one@company.com, two@company.com, three@company.com"
                    </Alert>
                    <Row>
                        {props.updated ? (
                            <div className="flex flex-col gap-1">
                                <BaseForm.Input colSpan={12} name="email" label="Recipient(s)" type="text" placeholder="Add recipients here" transformations={["lowercase"]} validations={{required: true, validEmails: true}} />
                                <BaseForm.Input colSpan={12} name="cc_email" description="The cc emails will be added to each of the emails sent to the addresses above" label="CC Email Addresses" type="text" placeholder="CC Email addresses" transformations={["lowercase"]} validations={{validEmails: true}} />
                                <BaseForm.Input colSpan={12} name="message" label="Customer Message (Optional)" type="textarea" placeholder="Write your custom message here" height="125px" />
                            </div>
                        ) : (
                            <>
                                <BaseForm.Input colSpan={12} name="email" label="Email Addresses" type="text" placeholder="Email addresses" transformations={["lowercase"]} validations={{required: true, validEmails: true}} />
                                <BaseForm.Input colSpan={12} name="cc_email" description="The cc emails will be added to each of the emails sent to the addresses above" label="CC Email Addresses" type="text" placeholder="CC Email addresses" transformations={["lowercase"]} validations={{validEmails: true}} />
                                <BaseForm.Input colSpan={12} name="message" label="Customer Message (Optional)" type="textarea" placeholder="Custom message to the customer" />
                            </>
                        )}
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    {props.updated ? (
                        <div className="flex justify-between w-full">
                            <Button onClick={() => props.onClose()} variant="text-outline">Later</Button>
                            <SubmitButton>
                                <span className="flex gap-1">
                                    Send email
                                    <Send01 className="inline" width={20} height={20} />
                                </span>
                            </SubmitButton>
                        </div>
                    ) : (
                        <div className="d-flex flex-row align-items-end">
                            <SubmitButton>Send</SubmitButton>
                        </div>
                    )}
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default SingleContractEmailModal;
