import '../App.scss';
import React from 'react';
import Button from './common/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { renderChildren, renderComponents } from '../helpers/common';
import classnames from 'classnames';
import Loader from './Loader';
import DropdownMenu from './DropdownMenu';
const _ = require('lodash');

function Section(props) {
	const navigate = useNavigate();

	const handleClick = (action) => {
		if (action.onClick) {
			action.onClick();
		} else if (action.link) {
			navigate(action.link);
		}
	};

	const getTitleVariantSize = (variant) => {
		switch (variant) {
			case 'page':
				return 'text-title';
			default:
				return 'text-xl';
		}
	};

	const renderSection = () => {
		return (
			<div className={classnames(props.variant === 'page' ? 'mb-[16px]' : 'mb-1', 'min-h-9')}>
				<div className='flex flex-row items-start gap-4 min-h-9 justify-end'>
					<div className='grow-0 flex items-start gap-3'>
						<div
							className={classnames(
								props.variant !== 'page' ? "leading-6" : "leading-9",
								getTitleVariantSize(props.variant),
								'font-semibold text-dark-gray grow-0 break-word hyphens-auto',
							)}
						>
							{props.title}
						</div>
						<div className={classnames("grow", props.variant !== 'page' ? "leading-6" : "leading-9")}>
							{props.titleRight}
						</div>
					</div>
					<div className={classnames('ml-0 mr-0 sm:mt-0 grow shrink-0 flex flex-row justify-end', props.variant !== 'page' ? "leading-6" : "leading-9")}>
						{props.right && renderComponents(props.right)}
						{props.actions && !props.isActionsDropdown && (
							<>
								{_.map(
									_.filter(props.actions, (a) => !!a),
									(action, i) => (
										<Button
											key={i}
											variant={action.variant}
											onClick={() => handleClick(action)}
											disabled={action.disabled}
											style={action.style}
											color={action.color}
										>
											{action.icon && (
												<span>
													<i className={classnames('fa', action.icon)} />
													&nbsp;
												</span>
											)}
											{action.label}
										</Button>
									),
								)}
							</>
						)}
						{props.actions && props.isActionsDropdown && (
							<DropdownMenu
								className='p-2'
								items={props.actions.options}
								onClick={props.actions.onClick}
								hideCaret
							>
								<div className='flex flex-row gap-1 items-center'>
									<Button variant={'primary'}>
										{props.actions.icon && (
											<span>
												<i className={classnames('fa', props.actions.icon)} />
												&nbsp;
											</span>
										)}
										{props.actions.label}
									</Button>
								</div>
							</DropdownMenu>
						)}
					</div>
				</div>
				{props.subtitle && (
					<div className={classnames({ 'mt-2 min-h-5': props.variant === 'page' }, 'text-sm text-gray-700')}>
						{props.subtitle}
					</div>
				)}
			</div>
		);
	};

	return (
		<div className={props.className}>
			{(props.title || !_.isEmpty(props.actions)) && renderSection()}
			<div className={classnames('content-section-content', props.contentClassName)}>
				<Loader loading={props.loading}>{renderChildren(props)}</Loader>
			</div>
		</div>
	);
}

export default Section;

Section.defaultProps = {
	loading: false,
};
