import '../App.scss';
import React, {useEffect, useState, forwardRef, useImperativeHandle, useContext, createRef} from 'react';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import { Row, Col } from 'react-bootstrap';
import {
    renderDescriptionForItemPricing,
    BaseContext,
    UserContext,
    formatTerm,
    currencyFormatFromPrice, getPlanLengthOptions
} from '../helpers/common';
import { useTranslation } from 'react-i18next';
import {serverPost} from "../helpers/server";
import Link from "./Link";
import ProductPricingInput from "./ProductPricingInput";
import {PlusIcon} from "@heroicons/react/20/solid";
import moment from 'moment';
import classnames from 'classnames';
import Button from './common/buttons/Button';
import Trash02 from '@untitled-ui/icons-react/build/esm/Trash02';
import CurrencyDollar from '@untitled-ui/icons-react/build/esm/CurrencyDollar';
import Percent02 from '@untitled-ui/icons-react/build/esm/Percent02';
import Plus from '@untitled-ui/icons-react/build/esm/Plus';
const _ = require('lodash');

const ProductPricingSelection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        validate(allFields) {
            return validate(allFields);
        },
        onFieldChange,
        getPricingSelectionFields(allFields) {
            return getPricingSelectionFields(allFields);
        },
        setInitialData(data) {
            return setInitialData(data);
        },
        onProductPricingCreated(productPricing) {
            return onProductPricingCreated(productPricing)
        }
    }));

    const { getApiUrl, company } = useContext(BaseContext);
    const {isMapleUser} = useContext(UserContext);
    const { t } = useTranslation('common');
    const [coupons, setCoupons] = useState([]);
    const [allProductPricings, setAllProductPricings] = useState([]);
    const [selectedProductPricingsList, setSelectedProductPricingsList] = useState([[]]);
    const [selectedProductPricingIdsList, setSelectedProductPricingIdsList] = useState([[]]);
    const [editable, setEditable] = useState(true);
    const [allowDiscounts, setAllowDiscounts] = useState(true);
    const [requireRecurring, setRequireRecurring] = useState(false);
    const [billableItems, setBillableItems] = useState([]);
    const [discountsTypeMap, setDiscountsTypeMap] = useState({});
    const [allowPhases, setAllowPhases] = useState(false);
    const [phaseStarts, setPhaseStarts] = useState([]);

    const [numberOfPhases, setNumberOfPhases] = useState(1);
    const [phaseRefs, setPhaseRefs] = useState([]);

    useEffect(() => {
        let refs = []; 
        for (let i = 0; i < numberOfPhases; i++) {
            refs.push(createRef());
        }
        setPhaseRefs(refs);
        setSelectedProductPricingsList(prevList => {
            const newList = [...prevList];
            if (newList.length < numberOfPhases) {
                newList.push([])
            }
            return newList.slice(0, numberOfPhases);
        })
        setPhaseStarts(prevList => {
            const newList = [...prevList];
            if (newList.length < numberOfPhases) {
                newList.push({
                    count: 1,
                    frequency: "YEAR"
                })
            }
            return newList.slice(0, numberOfPhases);
        })
    }, [numberOfPhases]);

    useEffect(() => {
        if (_.isNil(props.initialSelectionData) || _.isEmpty(props.initialSelectionData)) {
            return;
        }
        if (props.initialSelectionData.discounts) {
            setDiscountsTypeMap(_.mapValues(props.initialSelectionData.discounts, (d) => d.type));
        }
        if (props.initialSelectionData.phases) {
            setNumberOfPhases(props.initialSelectionData.phases.length);
            let pList = [];
            let pStarts = [];
            _.each(props.initialSelectionData.phases, (p, i) => {
                pStarts.push(p.relative_term)
                pList.push(p.product_pricing_ids);
            })
            setSelectedProductPricingIdsList(pList);
            setPhaseStarts(pStarts);
        }
    }, [props.initialSelectionData])

    useEffect(() => {
        if (!_.isNil(props.allowPhases)) {
            setAllowPhases(props.allowPhases);
        }
    }, [props.allowPhases]);

    useEffect(() => {
        setAllProductPricings(props.productPricings);
    }, [props.productPricings])

    useEffect(() => {
        setCoupons(props.coupons);
    }, [props.coupons])

    useEffect(() => {
        setAllowDiscounts(props.allowDiscounts);
    }, [props.allowDiscounts])

    useEffect(() => {
        if (_.isNil(props.editable)) {
            setEditable(true);
        } else {
            setEditable(props.editable);
        }
    }, [props.editable])

    useEffect(() => {
        setRequireRecurring(props.requireRecurring || false);
    }, [props.requireRecurring])

    useEffect(() => {
        const selectedIdsList = selectedProductPricingIdsList || [];
        let ppsList = [];
        _.each(selectedIdsList, selectedIds => {
            const selectPricings = []
            _.each(selectedIds, (id) => {
                const pp = _.find(allProductPricings, (p) => p.id === id);
                if (pp) {
                    selectPricings.push(pp);
                }
            })
            ppsList.push(selectPricings)
        })

        setSelectedProductPricingsList(ppsList);
    }, [allProductPricings, selectedProductPricingIdsList])

    useEffect(() => {
        serverPost(getApiUrl("/billable/items/find"), {}).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
        });
    }, [])

    const setInitialData = (data) => {
        if (data.discounts) {
            setDiscountsTypeMap(_.mapValues(data.discounts, (d) => d.type));
        }
        if (data.phases) {
            setNumberOfPhases(data.phases.length);
            let pList = [];
            _.each(data.phases, p => {
                pList.push(p.product_pricing_ids);
            })
            setSelectedProductPricingIdsList(pList);
        }
    }

    const onProductPricingCreated = (productPricing) => {
        if (phaseRefs[0].current) {
            phaseRefs[0].current.onProductPricingCreated(productPricing);
        }
    }

    const onFieldChange = (name, value) => {
        if (_.startsWith(name, "discounts.") && _.endsWith(name, ".type")) {
            setDiscountsTypeMap(prevMap => {
                const newMap = _.cloneDeep(prevMap)
                newMap[name.split(".")[1]] = value;
                return newMap;
            })
        } else if (_.startsWith(name, "phases.")) {
            const i = name.split(".")[1];
            if (_.endsWith(name, ".relative_term.count")) {
                setPhaseStarts(prevStarts => {
                    const newStarts = [...prevStarts];
                    newStarts[i].count = value;
                    return newStarts;
                });
            } else if (_.endsWith(name, ".relative_term.frequency")) {
                setPhaseStarts(prevStarts => {
                    const newStarts = [...prevStarts];
                    newStarts[i].frequency = value;
                    return newStarts;
                });
            }
            if (phaseRefs[i] && phaseRefs[i].current) {
                phaseRefs[i].current.onFieldChange(name, value);
            }
        }
    }

    const validate = (allFields) => {
        if (numberOfPhases > 0) {
            for (let i = 0; i < phaseRefs.length; i++) {
                if (phaseRefs[i].current) {
                    const validationError = phaseRefs[i].current.validate(allFields)
                    if (validationError) {
                        return validationError
                    }
                }
            }
        }
        return null;
    }

    const getPricingSelectionFields = (allFields) => {
        let baseConfig = {}
        let changes = [];
        for (let i = 0; i < phaseRefs.length; i++) {
            if (phaseRefs[i].current) {
                const selectionFields = phaseRefs[i].current.getPricingSelectionFields(allFields)
                if (i === 0) {
                    baseConfig = selectionFields;
                } else {
                    changes.push(selectionFields);
                }
            }
        }

        baseConfig.scheduled_changes = changes;
        return baseConfig
    }

    const onPhaseAdd = () => {
        setNumberOfPhases(prev => prev + 1);
        setPhaseStarts(prevList => {
            const newList = [...prevList];
            newList.push({
                count: 1,
                frequency: "YEAR"
            })
            return newList;
        })
        if (props.setInitialFields) {
            props.setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields.phases.push({
                    relative_term: {
                        count: 1,
                        frequency: "YEAR"
                    }
                })
                return newFields;
            })
        }
    }

    const onPhaseDelete = (index) => {
        setSelectedProductPricingsList(prevList => {
            const newList = [...prevList];
            newList.splice(index, 1);
            return newList;
        })
        setPhaseStarts(prevList => {
            const newList = [...prevList];
            newList.splice(index, 1);
            return newList;
        })
        // sets the relative term
        if (props.setInitialFields) {
            props.setInitialFields(prevFields => {
                const newFields = {...prevFields}
                const newPhases = [...prevFields.phases];
                const deletedPhase = newPhases.splice(index, 1);
                if (newPhases?.[index]) { 
                    newPhases[index].relative_term = deletedPhase[0].relative_term;
                }
                newFields.phases = newPhases
                return newFields;
            })
        }

        setNumberOfPhases(prev => prev - 1);
    }

    return (
        <div>
            <div className={classnames("flex flex-col gap-3 mt-2")}>
                {Array.isArray(selectedProductPricingsList) && 
                    _.map(_.range(numberOfPhases), (phase, i) =>
                        <ProductPricingSelectionPhase
                            ref={phaseRefs[i]}
                            key={i} prefix={phase}
                            billableItems={billableItems}
                            allowDiscounts={allowDiscounts}
                            initialFields={props.initialFields && props.initialFields.phases && props.initialFields.phases[i]}
                            allowMinimumSpend={props.allowMinimumSpend}
                            editable={editable}
                            requireRecurring={requireRecurring}
                            menuPlacement={props.menuPlacement}
                            showDuration={true}
                            canDeletePhase={i !== 0}
                            isStart={i === 0}
                            isEnd={i === numberOfPhases-1}
                            preselectedProductPricings={selectedProductPricingsList[phase]}
                            phaseStart={i === 0 ? {} : phaseStarts[i]}
                            onSelectionChange={(pps) => {
                                // setSelectedProductPricingsList(prevList => {
                                //     if (!prevList || !Array.isArray(prevList)) return;
                                //     prevList[i] = pps;
                                //     return prevList;
                                // });
                                if (props.onSelectionChange) {
                                    props.onSelectionChange(phase, pps);
                                }
                            }}
                            excludeBundlePricing={props.excludeBundlePricing}
                            extraQueryFilters={props.extraQueryFilters}
                            onPhaseDelete={() => onPhaseDelete(i)}
                            onCreateProductPricing={props.onCreateProductPricing}
                            allowCreation={props.allowCreation}
                            inSideModal={props.inSideModal}
                        />
                    )
                }
            </div>
            {
                allowPhases &&
                    <div className={classnames("mt-3 mb-4 inline-block h-[97px] w-full bg-white rounded-md border-2 border-gray3 border-dashed cursor-pointer")} onClick={() => onPhaseAdd()}>
                        <div className="flex flex-row justify-center items-center w-full h-full">
                            <div className="text-sm flex flex-row gap-1 font-semibold text-subtitle"><Plus width={20} height={20}/><span>Add Phase</span></div>
                        </div>
                    </div>
            }
        </div>
    )
})

const ProductPricingSelectionPhase = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        validate(allFields) {
            return validate(allFields);
        },
        onFieldChange,
        getPricingSelectionFields(allFields) {
            return getPricingSelectionFields(allFields);
        },
        onProductPricingCreated(productPricing) {
            return onProductPricingCreated(productPricing);
        }
    }));

    const { t } = useTranslation('common');
    const { getApiUrl, company } = useContext(BaseContext);
    const [selectedProductPricings, setSelectedProductPricings] = useState([]);
    const [allowDiscounts, setAllowDiscounts] = useState(props.allowDiscounts);
    const [editable, setEditable] = useState(props.editable);
    const [showAddMinimumSpendInput, setShowAddMinimumSpendInput] = useState(false);
    const [discountTypeMap, setDiscountTypeMap] = useState({});

    useEffect(() => {
        setAllowDiscounts(props.allowDiscounts)
    }, [props.allowDiscounts]);

    useEffect(() => {
        if (props.onSelectionChange) {
            props.onSelectionChange(selectedProductPricings);
        }
        if (_.isEmpty(selectedProductPricings)) {
            setShowAddMinimumSpendInput(false);
        }
    }, [selectedProductPricings]);

    useEffect(() => {
        setSelectedProductPricings(props.preselectedProductPricings)
    }, [props.preselectedProductPricings]);

    const onFieldChange = (name, value) => {
        if (_.startsWith(name, `phases.${props.prefix}.discounts.`) && _.endsWith(name, ".type")) {
            setDiscountTypeMap(prevMap => {
                const newMap = {...prevMap};
                newMap[name.split(".")[3]] = value;
                return newMap;
            })
        }
    }

    const validate = (allFields) => {
        if (selectedProductPricings.length === 0) {
            return "Please select at least one product price."
        }
        const uniqueCurrencies = _.uniq(_.map(selectedProductPricings, (pp) => pp.currency));
        if (uniqueCurrencies.length > 1) {
            return "Multiple currencies are not permitted together."
        }
        if (props.requireRecurring) {
            const recurringProductPricings = _.filter(selectedProductPricings, (pp) => pp.type !== "ONETIME_PRICING");
            if (recurringProductPricings.length === 0) {
                return "Please select at least one recurring price.";
            }
        }
        return null;
    }

    const getPricingSelectionFields = (allFields) => {
        let discounts = [];
        _.each(allFields.phases[props.prefix].discounts, (value, key) => {
            if (parseFloat(value.percentage) > 0) {
                discounts.push({
                    name: "Discount",
                    type: "DISCOUNT_PERCENT",
                    customer_id: allFields.customer.id,
                    percent: parseFloat(value.percentage)/100,
                    state:"ACTIVE",
                    item_pricing_id: key
                })
            } else if (parseFloat(value.amount) > 0) {
                discounts.push({
                    name: "Discount",
                    type: "DISCOUNT_AMOUNT",
                    customer_id: allFields.customer.id,
                    amount: {
                        value_in_cents: Math.round(parseFloat(value.amount) * 100),
                        currency: selectedProductPricings[0].currency
                    },
                    state:"ACTIVE",
                    item_pricing_id: key
                })
            }
        })
        let onetime_items = []
        let recurring_pmp_ids = [];
        let scheduled_pmp_ids = [];
        _.each(selectedProductPricings, (p, j) => {
            if (p.type !== "ONETIME_PRICING") {
                // Recurring price
                _.each(p.product_metric_pricings, pmp => {
                    recurring_pmp_ids.push(pmp.id)
                });
                _.each(p.product_metric_pricings, pmp => {
                    if (pmp.item_pricing.type === "SCHEDULED") {
                        scheduled_pmp_ids.push(pmp.id)
                    }
                });
                return;
            }
            const pmp_id = p.product_metric_pricings[0].id;
            onetime_items.push({
                product_pricing_id: p.id,
                aggregate: parseFloat(allFields.phases[props.prefix].config[String(pmp_id)].num_licenses)
            })
        })
        let configItems = [];
        _.each(allFields.phases[props.prefix].config, (v, k) => {
            if (_.includes(scheduled_pmp_ids, k)) {
                configItems.push({
                    ...v,
                    num_licenses: 1,
                    minimum_units: 0,
                    product_metric_pricing_id: k
                })
            } else if (_.includes(recurring_pmp_ids, k)) {
                configItems.push({
                    ...v,
                    num_licenses: parseFloat(v.num_licenses || "0"),
                    minimum_units: parseFloat(v.minimum_units || "0"),
                    product_metric_pricing_id: k
                })
            }
        })
        const phaseData = {
            currency: selectedProductPricings[0].currency,
            config_items: configItems,
            product_pricing_ids: _.map(_.filter(selectedProductPricings, (a) => a.type !== "ONETIME_PRICING"), (p) => p.id),
            onetime_items: onetime_items,
            discounts: discounts,
            minimum_spend: allFields.phases[props.prefix].minimum_spend ? {
                value_in_cents: parseFloat(allFields.phases[props.prefix].minimum_spend) * 100,
                currency: selectedProductPricings[0].currency
            } : { value_in_cents: 0, currency: selectedProductPricings[0].currency }
        }
        if (!props.isStart) {
            phaseData.timing = "RELATIVE";
            phaseData.relative_term = allFields.phases[props.prefix].relative_term
            phaseData.proration_type = "NONE"
        }
        return phaseData;
    }

    const onProductProductPricingDelete = (value) => {
        setSelectedProductPricings(prevSelectedProductPricings => {
            return _.filter(prevSelectedProductPricings, (p) => p.id !== value);
        })
    }

    const onProductPricingCreated = (productPricing) => {
        setSelectedProductPricings(prevSelectedProductPricings => {
            const newSelectedProductPricings = [...prevSelectedProductPricings];
            newSelectedProductPricings.push(productPricing);
            return newSelectedProductPricings;
        })
    }

    const renderDiscountDescription = (discount) => {
        let description = "";
        if (discount.type === "DISCOUNT_AMOUNT") {
            description = currencyFormatFromPrice(discount.amount);
        } else if (discount.type === "DISCOUNT_PERCENT") {
            description = `${discount.percent * 100 }%`;
        }
        let expirationDescription = null;
        if (discount.expiration_date) {
            expirationDescription = `till ${moment(discount.expiration_date).format("D MMM, YYYY")}`;
        }
        return <div className="flex flex-col grow gap-1">
            <span>{ discount.name }, {description}</span>
            {
                expirationDescription &&
                <span className="text-gray-500 caption">{ expirationDescription }</span>
            }
        </div>;
    }

    const discountTypeOptions = [
        { label: "%", value: "DISCOUNT_PERCENT" },
        { label: "$", value: "DISCOUNT_AMOUNT" },
    ]
    
    const discountTypeOptionsUpdated = [
        { label: <Percent02 width={24} height={20} />, value: "DISCOUNT_PERCENT" },
        { label: <CurrencyDollar width={24} height={20} />, value: "DISCOUNT_AMOUNT" },
    ]

    const showMinimumField = showAddMinimumSpendInput || (props.initialFields && props.initialFields.minimum_spend && props.initialFields.minimum_spend > 0) || false;
    return (
        <div className={classnames("px-3 border py-2 text-sm bg-gray0 rounded-xl border-gray3")}>
            {
                props.showDuration &&
                <div className={classnames("flex flex-row gap-2 min-h-[50px] text-md font-semibold text-gray-500 pb-[12px] items-end")}>
                    <div className="flex h-[38px] items-center">
                        <span className="font-semibold text-dark-gray text-base">
                            {(props.isStart && props.isEnd)
                                ? "Your plan:"
                                : `Phase ${props.prefix + 1}:`}
                            &nbsp;
                            {props.isStart
                                ? "From execution date"
                                : `When Phase ${props.prefix} is complete` 
                            }
                            &nbsp;
                            {!props.isEnd && "+"}
                            {!props.isStart && props.isEnd && "+ ∞"}
                                    
                        </span>
                    </div>
                    {
                        !props.isEnd &&
                            <Col md="3">
                                <BaseForm.InputGroup formClassName="inline">
                                    <BaseForm.Number name={`phases.${props.prefix+1}.relative_term.count`} min="1" />
                                    <BaseForm.Divider />
                                    <BaseForm.SingleSelect
                                        name={`phases.${props.prefix+1}.relative_term.frequency`} options={getPlanLengthOptions()} showSearch={false}
                                        fullWidth
                                    />
                                </BaseForm.InputGroup>
                            </Col>
                    }
                    <div className="grow"/>
                    {props.canDeletePhase && 
                        <Button variant="outline-danger-less" onClick={() => props.onPhaseDelete()} hasIconOnly>
                            <Trash02 width={20} height={20} />
                        </Button>}
                </div>
            }
            <div className="flex flex-col gap-3">
                {
                    _.map(selectedProductPricings, (pp, i) => {
                        const pmps = pp.product_metric_pricings;
                        return (
                            <div key={i} className="px-3 py-[12px] border border-gray0 bg-white rounded-xl shadow-product-card">
                                <div className="flex flex-col">
                                    <Row className="mb-2">
                                        <Col md="12" className="flex items-center justify-between w-full">
                                            <span className={classnames(props.updated ? "text-base text-dark-gray font-semibold" : "body2")}>{ pp.product.name }</span>
                                            <DeleteButton onDelete={() => onProductProductPricingDelete(pp.id)} className={classnames({ "p-0": props.updated })} />
                                        </Col>
                                    </Row>
                                    <div className="flex flex-col gap-4">
                                        {pmps.map((pmp, j) => {
                                            const rightSideComponents = [];
                                            if (pmp.metric.type === "LICENSE_METRIC" && pmp.item_pricing.type !== "SCHEDULED") {
                                                rightSideComponents.push(
                                                    <BaseForm.Input colSpan={allowDiscounts ? "5": "5"} name={`phases.${props.prefix}.config.${pmp.id}.num_licenses`} label={t('common.quantity')} type="number-stepper" step="1" min="0" defaultValue={1} validations={{ required: true, min: 0 }} placeholder="0" />
                                                );
                                            }
                                            if (pmp.metric.type === "LICENSE_METRIC" && pmp.item_pricing.type === "SCHEDULED") {
                                                rightSideComponents.push(
                                                    <Col md={allowDiscounts ? "5": "5"}>
                                                        <BaseForm.Hidden name={`phases.${props.prefix}.config.${pmp.id}.num_licenses`} required placeholder="0" />
                                                    </Col>
                                                );
                                            }
                                            if (pmp.metric.type === "ONETIME_METRIC") {
                                                rightSideComponents.push(
                                                    <BaseForm.Input colSpan={allowDiscounts ? "5": "5"} name={`phases.${props.prefix}.config.${pmp.id}.num_licenses`} label={t('common.quantity')} type="number-stepper" step="1" min="0" defaultValue={1} validations={{ required: true, min: 0 }} placeholder="0" />
                                                );
                                            }
                                            if (pmp.metric.type === "CUSTOM_METRIC") {
                                                rightSideComponents.push(
                                                    <Col xs={12} className="d-flex flex-column gap-1 mb-2">
                                                        <div className="body2">Metric</div>
                                                        { pmp.metric.name }
                                                    </Col>
                                                );
                                            }
                                            if (pmp.metric.type === "CUSTOM_METRIC") {
                                                rightSideComponents.push(
                                                    <BaseForm.Input colSpan={allowDiscounts ? "5": "5"} name={`phases.${props.prefix}.config.${pmp.id}.minimum_units`} label="Min. Quantity" type="number" step="1" min="0" validations={{ min: 0 }} placeholder="0" />
                                                );
                                            }
                                            if (allowDiscounts) {
                                                rightSideComponents.push(
                                                    <Col>
                                                        <BaseForm.InputGroup label={"Discount"} borderless hideErrorBg>
                                                            {
                                                                (!discountTypeMap[pmp.item_pricing.id] || discountTypeMap[pmp.item_pricing.id] === "DISCOUNT_PERCENT") &&
                                                                <BaseForm.Input
                                                                    name={`phases.${props.prefix}.discounts.${pmp.item_pricing.id}.percentage`}
                                                                    hideLabel
                                                                    hideErrorLabel
                                                                    label="Discount Value" type="number" min="0" step="1"
                                                                    className={"discount-select bg-red-100 inherit"}
                                                                    max="100"
                                                                    outerInputClassName="w-full"
                                                                    validations={{ min: 0, max: 100 }}
                                                                    formClassName="mb-0"
                                                                    placeholder="0"
                                                                />
                                                            }
                                                            {
                                                                discountTypeMap[pmp.item_pricing.id] === "DISCOUNT_AMOUNT" &&
                                                                <BaseForm.Input
                                                                    name={`phases.${props.prefix}.discounts.${pmp.item_pricing.id}.amount`}
                                                                    hideLabel
                                                                    hideErrorLabel
                                                                    label="Discount Amount" type="number" min="0" step="1"
                                                                    className={"discount-select bg-red-100 inherit"}
                                                                    max="1000000"
                                                                    outerInputClassName="w-full"
                                                                    validations={{ min: 0 }}
                                                                    formClassName="mb-0"
                                                                    placeholder="0"
                                                                />
                                                            }
                                                            <BaseForm.Input
                                                                type="toggle"
                                                                name={`phases.${props.prefix}.discounts.${pmp.item_pricing.id}.type`}
                                                                label="Type"
                                                                hideLabel
                                                                options={discountTypeOptionsUpdated}
                                                                showSearch={false}
                                                                formClassName="mb-0"
                                                            />
                                                        </BaseForm.InputGroup>
                                                    </Col>
                                                );
                                            }
                                            return (
                                                <div key={j} className="flex">
                                                    <div className="flex-grow pr-4">
                                                        {renderDescriptionForItemPricing(pmp.item_pricing, true, pmp.item, props.billableItems, true)}
                                                    </div>
                                                    <div className="flex items-center grow-0 shrink border-l border-gray3 pl-4">
                                                        <Row className={classnames("gap-y-3 gap-x-2 sm:w-[250px] md:w-[400px]", { "2xl:w-[600px]": !props.inSideModal })}>
                                                            {rightSideComponents.map((component, index) => (
                                                                <React.Fragment key={index}>
                                                                    {component}
                                                                </React.Fragment>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                </div>
                                            );
                                        })}   
                                    </div>                              
                                </div>
                            </div>
                        )
                    })
                }
                {
                    editable &&
                        <Row className="justify-between items-center gap-2">
                            <Col md="6">
                                <ProductPricingInput
                                    excludeBundlePricing={props.excludeBundlePricing}
                                    extraQueryFilters={props.extraQueryFilters}
                                    billableItems={props.billableItems}
                                    setSelectedProductPricings={setSelectedProductPricings}
                                    selectedProductPricings={selectedProductPricings}
                                    onCreateProductPricing={props.onCreateProductPricing}
                                    allowCreation={props.allowCreation}
                                    menuPlacement={props.menuPlacement}
                                />
                            </Col>
                            {!_.isEmpty(selectedProductPricings) && props.allowMinimumSpend &&
                                <Col md="3" className="flex justify-end">
                                    {showMinimumField 
                                        ? (
                                            <BaseForm.Input formClassName="inline"
                                                name={`phases.${props.prefix}.minimum_spend`} label={`Minimum Spend (in ${selectedProductPricings[0].currency})`} type="number" step="1" min="0" placeholder="0.00" validations={{ min: 0 }} />
                                        ) : (
                                            <Button variant="text-outline" onClick={() => setShowAddMinimumSpendInput(true)}>
                                                <span className="text-sm">Add Minimum Spend</span>
                                            </Button>
                                        )
                                    }
                                </Col>
                            }
                        </Row>
                }
            </div>
        </div>
    )
})

export default ProductPricingSelection;
