import '../App.scss';
import '../css/invoice.scss';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BaseContext, IntegrationContext } from '../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseForm from './BaseForm';
import SubmitButton from './common/buttons/SubmitButton';
import Toggle from './common/Toggle';
import { notifyEvent, serverPatch } from '../helpers/server';
import Notification from './Notification';
import { Col, Container, Row } from 'react-bootstrap';
import ContentBox from './ContentBox';
import Button from './common/buttons/Button';
import Loader from './Loader';

const _ = require('lodash');

function SnowflakeDestinationSetupComponent(props) {
	const { integration } = useContext(IntegrationContext);
	const { getApiUrl } = useContext(BaseContext);
	const [authMethod, setAuthMethod] = useState('PASSWORD');
	const [loading, setLoading] = useState(false);
	const [snowflakeData, setSnowflakeData] = useState(
		integration && integration.destination_data && integration.destination_data.snowflake,
	);

	const [isEditing, setIsEditing] = useState(integration && !integration.destination_data);

	function onSubmit(data) {
		setLoading(true);
		let host = new URL(data.host);
		data.host = host.host
		const updateData = {
			feature_data: {
				destination_data: {
					snowflake: data,
				},
			},
		};
		// TODO: HOW is error handling happening here
		serverPatch(
			getApiUrl(`/integrations/${integration.id}`, {}, (err) => {
				console.log('failed to update server error:', err);
				Notification.Danger(`${integration.name} failed to update`);
			}),
			updateData,
		).then((res) => {
			Notification.Success(`${integration.name} updated successfully`);
			notifyEvent('integration_settings');
			setIsEditing(false);
			setLoading(false);
			setSnowflakeData(res.destination_data.snowflake);

			if (props.onUpdate) {
				props.onUpdate();
			}
		});
	}

	return (
		<div>
			<div className='d-flex flex-row gap-3'>
				<div className='flex-grow-1'>
					<h5>{integration.name} Configuration</h5>
				</div>
				<div className='flex-shrink-0 d-flex align-items-center'>
					{isEditing ? (
						<Button
							variant='text-danger'
							onClick={() => {
								setIsEditing(false);
								setLoading(false);
							}}
						>
							Cancel
						</Button>
					) : (
						<Button
							variant='text-primary'
							onClick={() => {
								setIsEditing(true);
								setLoading(false);
							}}
						>
							<i className='fa fa-edit' /> Edit
						</Button>
					)}
				</div>
			</div>
			{isEditing ? (
				<Row>
					<div className='flex flex-row gap-12 items-center max-w-2xl '>
						<Row className='grow'>
							<BaseForm initialFormFields={snowflakeData} onSubmit={onSubmit}>
								<BaseForm.Input
									colSpan={12} type='text' name='host' label='Host'
									description="The host url for your snowflake instance. URL has to start with http:// or https://"
									validations={{ required: true, validURL: true }}
								/>
								<BaseForm.Input colSpan={12} type='text' name='role' label='Role' required />
								<BaseForm.Input
									colSpan={12}
									type='text'
									name='warehouse'
									label='Warehouse'
									required
								/>
								<BaseForm.Input
									colSpan={12}
									type='text'
									name='database'
									label='Database'
									required
								/>
								<BaseForm.Input
									colSpan={12}
									type='text'
									name='default_schema'
									label='Default Schema'
									required
								/>
								<BaseForm.Input
									colSpan={12}
									type='text'
									name='username'
									label='Username'
									required
								/>
								<Col md={6} className='pb-2'>
									<Toggle
										className='w-full'
										label='Authentication Method'
										toggleValues={[
											{
												value: 'PRIVATE_KEY_AUTHENTICATION',
												label: 'Private Key',
											},
											{ value: 'PASSWORD', label: 'Password' },
										]}
										value={authMethod}
										onClick={(value) => {
											setAuthMethod(value);
										}}
									/>
								</Col>
								{
									authMethod === 'PASSWORD' ?
										<BaseForm.Input type='password' name='password' label='Password' required />
									: authMethod === 'PRIVATE_KEY_AUTHENTICATION' ?
										<div>
											<BaseForm.Input
												type='textarea'
												name='private_key'
												label='Private Key'
												height="120px"
												required
											/>
											<BaseForm.Input
												type='text'
												name='private_key_passphrase'
												label='Private Key Password'
											/>
										</div>
									: <div/>
								}
								<br />
								<Loader loading={loading}>
									<SubmitButton>Update</SubmitButton>
								</Loader>
							</BaseForm>
						</Row>
					</div>
				</Row>
			) : (
				<Row>
					<div className='flex flex-row gap-12 items-center max-w-2xl '>
						<Row className='grow'>
							<div>
								<span className='text-sm font-semibold text-gray-700'>Host: </span>
								<span className='text-sm font-normal text-gray-500'>{snowflakeData && snowflakeData.host}</span>
							</div>
							<div>
								<span className='text-sm font-semibold text-gray-700'>Warehouse: </span>
								<span className='text-sm font-normal text-gray-500'>{snowflakeData && snowflakeData.warehouse}</span>
							</div>
							<div>
								<span className='text-sm font-semibold text-gray-700'>Role: </span>
								<span className='text-sm font-normal text-gray-500'>{snowflakeData && snowflakeData.role}</span>
							</div>
							<div>
								<span className='text-sm font-semibold text-gray-700'>Schema: </span>
								<span className='text-sm font-normal text-gray-500'>
									{snowflakeData && snowflakeData.default_schema}
								</span>
							</div>
							<div>
								<span className='text-sm font-semibold text-gray-700'>Username: </span>
								<span className='text-sm font-normal text-gray-500'>{snowflakeData && snowflakeData.username}</span>
							</div>

							{snowflakeData && snowflakeData.password && (
								<div>
									<span className='text-sm font-semibold text-gray-700'>Password: </span>
									<span className='text-sm font-normal text-gray-500'>
										{snowflakeData && snowflakeData.password}
									</span>
								</div>
							)}
							{snowflakeData && snowflakeData.private_key && (
								<div>
									<span className='text-sm font-semibold text-gray-700'>Private Key: </span>
									<span className='text-sm font-normal text-gray-500'>
										{snowflakeData && snowflakeData.private_key}
									</span>
								</div>
							)}
							{snowflakeData && snowflakeData.private_key_passphrase && (
								<div>
									<span className='text-sm font-semibold text-gray-700'>
										Private Key Passphrase:{' '}
									</span>
									<span className='text-sm font-normal text-gray-500'>
										{snowflakeData && snowflakeData.private_key_passphrase}
									</span>
								</div>
							)}
						</Row>
					</div>
				</Row>
			)}
		</div>
	);
}

export default SnowflakeDestinationSetupComponent;
