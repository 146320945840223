import '../App.scss';
import React from 'react';
import { renderChildren } from '../helpers/common';

function Loader(props) {
    if (props.loading) {
        return (
            <div className="flex flex-row justify-content-center">
                <div className="spinner-border text-secondary"/>
            </div>
        );
    } else {
        return renderChildren(props);
    }
}

export default Loader;
