import BaseOverlayTrigger from "../BaseOverlayTrigger";
import { InformationCircleIcon } from '@heroicons/react/24/outline';

const Tooltip = ({ children }) => {
    return (
        <BaseOverlayTrigger content={children}>
            <InformationCircleIcon className="h-[20px] w-[20px]" />
        </BaseOverlayTrigger>
    );
};

export default Tooltip;