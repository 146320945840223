import React, { useEffect, useContext, useMemo, useState } from "react";
import { BaseContext } from "../../helpers/common";
import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { isEqual } from "lodash";
import Section from "../../components/Section";
import Loader from "../../components/Loader";
import usePostFindCreditsApi from "../../helpers/hooks/api/usePostFindCreditsApi";
import CreditTable from "../../components/CreditTable";
import { serverDelete } from "../../helpers/server";
import { useTranslation } from "react-i18next";

function Credit() {
    const { t } = useTranslation("common");

    const { setPageTitle, company, getApiUrl } = useContext(BaseContext);

    const [queryParams] = useSearchParams();
    const customerId = queryParams.get("customer_id");
    const disableFilterCache = queryParams.get("disableFilterCache") === "true";

    const [creditLogsSortPref, setCreditLogsSortPref] =
        useState("createdAtDesc");
    const creditFilterOptions = useMemo(
        () => [
            {
                title: t("credits.table.filter.type.title"),
                type: "select",
                name: "type",
                options: [
                    {
                        value: null,
                        label: t("credits.table.filter.type.all"),
                    },
                    {
                        value: "AMOUNT",
                        label: t("credits.table.filter.type.amount"),
                    },
                    {
                        value: "UNITS",
                        label: t("credits.table.filter.type.units"),
                    },
                ],
            },
            {
                title: t("credits.table.filter.status.title"),
                type: "multi-select",
                name: "statuses",
                options: [
                    {
                        value: "ACTIVE",
                        label: t("credits.table.filter.status.active"),
                    },
                    {
                        value: "CONSUMED",
                        label: t("credits.table.filter.status.consumed"),
                    },
                    {
                        value: "REVOKED",
                        label: t("credits.table.filter.status.revoked"),
                    },
                    {
                        value: "EXPIRED",
                        label: t("credits.table.filter.status.expired"),
                    },
                ],
            },
        ],
        []
    );
    const defaultCreditFilters = useMemo(() => {
        return {
            type: null,
            statuses: ["ACTIVE", "CONSUMED", "REVOKED", "EXPIRED"],
            customer_id: customerId,
        };
    }, []);
    const findCreditsCacheKey = `${company.id}_filter_cache_find_credits`;
    const cachedCreditFilters = useMemo(
        () =>
            disableFilterCache
                ? null
                : JSON.parse(localStorage.getItem(findCreditsCacheKey)),
        [disableFilterCache, findCreditsCacheKey]
    );
    const [selectedCreditFilters, setSelectedCreditFilters] = useState(
        () => defaultCreditFilters
    );
    const onCreditFiltersChange = (newSelectedFilters) => {
        setSelectedCreditFilters(newSelectedFilters);
        if (!disableFilterCache) {
            localStorage.setItem(
                findCreditsCacheKey,
                JSON.stringify(newSelectedFilters)
            );
        }
    };

    const {
        credits,
        totalUnpaginatedCreditsCount,
        isCreditsLoading,
        hasMoreCredits,
        onCreditsSearch,
    } = usePostFindCreditsApi({
        limit: 30,
        query: selectedCreditFilters,
        sortKey: creditLogsSortPref,
    });

    const isCredsTableEmpty = useMemo(
        () =>
            !credits.length &&
            !isCreditsLoading &&
            isEqual(defaultCreditFilters, selectedCreditFilters),
        [credits.length, isCreditsLoading, selectedCreditFilters]
    );

    const onRevokeCredit = (creditID) => {
        serverDelete(getApiUrl(`/credits/${creditID}`)).then(() => {
            onCreditsSearch({ keepPreviousData: false });
        });
    };

    useEffect(() => {
        setPageTitle(t("credits.page_title"));
    }, []);

    useEffect(() => {
        onCreditsSearch({ keepPreviousData: false });
    }, [creditLogsSortPref, selectedCreditFilters]);

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title={t("credits.section_title")}>
                    <InfiniteScroll
                        dataLength={credits.length}
                        next={() => {
                            onCreditsSearch({ keepPreviousData: true });
                        }}
                        hasMore={hasMoreCredits}
                        scrollableTarget="content-wrapper"
                        loader={<Loader loading={true} />}
                    >
                        {isCredsTableEmpty ? (
                            <span className={"text-sm text-gray-500"}>
                                {t("credits.table.no_credits")}
                            </span>
                        ) : (
                            <CreditTable
                                showTableActions={true}
                                creditsData={{
                                    credits,
                                    totalUnpaginatedCount:
                                        totalUnpaginatedCreditsCount,
                                    isLoading: isCreditsLoading,
                                    hasMore: hasMoreCredits,
                                    onFiltersChange: onCreditFiltersChange,
                                    onRevoke: onRevokeCredit,
                                    onRefresh: () => {
                                        onCreditsSearch({ keepPreviousData: false });
                                    }
                                }}
                                filterData={{
                                    filterOptions: creditFilterOptions,
                                    defaultSelectedFilters:
                                        defaultCreditFilters,
                                    cachedSelectedFilters: cachedCreditFilters,
                                    onFiltersChange: onCreditFiltersChange,
                                }}
                                sortData={{
                                    sortPref: creditLogsSortPref,
                                    onSortChange: setCreditLogsSortPref,
                                }}
                            />
                        )}
                    </InfiniteScroll>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default Credit;
