import '../../../App.scss';
import React, {useEffect, useState, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {serverDelete, serverPatch, serverPost} from '../../../helpers/server';
import {BaseContext, currencyFormatFromPrice, IntegrationContext} from '../../../helpers/common';
import Label from "../../../components/Label";
import ContentContainer from '../../../components/ContentContainer';
import MapleTable from '../../../components/MapleTable';
import UpdateIntegrationReferenceModal from "../../../components/modals/UpdateIntegrationReferenceModal";
import Button from "../../../components/common/buttons/Button";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import MoreButton from "../../../components/common/buttons/MoreButton";
import SimpleModal from "../../../components/modals/SimpleModal";
import Link from "../../../components/Link";
const _ = require('lodash');

function IntegrationSyncPreviewDetails() {
    let { feature } = useParams();
    feature = _.replace(_.upperCase(feature), " ", "_");
    const { integration } = useContext(IntegrationContext);
    const { getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [referenceToEdit, setReferenceToEdit] = useState(null);
    const [showUpdateIntegrationReferenceModal, setShowUpdateIntegrationReferenceModal] = useState(false);
    const [showUnlinkConfirmationModal, setShowUnlinkConfirmationModal] = useState(false);
    const [referenceToUnlink, setReferenceToUnlink] = useState(null);
    const [loading, setLoading] = useState(true);
    const [ syncPreview, setSyncPreview ] = useState({});

    useEffect(() => {
        setPageTitle(`Next Sync Preview`);
    }, []);

    useEffect(() => {
        fetchSyncPreview(true);
    }, []);

    const fetchSyncPreview = (skipCache=false) => {
        const previewData = {
            feature: feature
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/syncs/preview`), previewData,{ skipCache }).then((res) => {
            if (res) {
                setSyncPreview(res[feature.toLowerCase()])
            }
            setLoading(false);
        })
    }

    const updateReference = (row) => {
        setReferenceToEdit(row);
        setShowUpdateIntegrationReferenceModal(true);
    }

    const unmatchReference = async (row) => {
        const mappingData = {
            integration_reference_id: row.id,
            remote_id: null
        }
        const result = await serverPatch(getApiUrl(`/integrations/${row.integration.id}/references/${row.id}`), mappingData)
        if (result) {
            Notification.Success("Unmatched successfully");
            fetchSyncPreview(true)
        }
    }

    const ignoreReference = async (row) => {
        const mappingData = {
            integration_reference_id: row.id,
            state: "IGNORED"
        }
        const result = await serverPatch(getApiUrl(`/integrations/${row.integration.id}/references/${row.id}`), mappingData)
        if (result) {
            Notification.Success("Unmatched successfully");
            fetchSyncPreview(true)
        }
    }

    const deleteReference = async (row) => {
        const result = await serverDelete(getApiUrl(`/integrations/${row.integration.id}/references/${row.id}`));
        if (result) {
            Notification.Success("Deleted successfully");
            fetchSyncPreview(true)
        }
    }

    const onModalClose = (didUpdate) => {
        setShowUpdateIntegrationReferenceModal(false);
        if (didUpdate) {
            fetchSyncPreview(true);
        }
    }

    const onMoreActionSelected = (action, ref) => {
        if (action === "unlink") {
            setShowUnlinkConfirmationModal(true);
            setReferenceToUnlink(ref);
        } else if (action === "match") {
            updateReference(ref);
        } else if (action === "ignore") {
            ignoreReference(ref);
        } else if (action === "delete") {
            deleteReference(ref);
        }
    }

    const renderActions = (row) => {
        // if (!_.includes(["CUSTOMER", "INVOICE"], entity)) {
        //     return;
        // }
        if (row.iref_state === "PENDING_REMOTE_CREATE" || row.iref_state === "NEEDS_MAPPING") {
            // If the integration is active, make pushing the primary action
            let actionOptions = [
                { id: "match", label: "Match", icon: "fa fa-handshake" },
                { id: "ignore", label: "Ignore", icon: "fa fa-link-slash" },
            ]
            return (
                <div className="flex flex-row justify-end items-center">
                    <Button variant="primary" onClick={() => pushIntegrationReference(row)}>Push</Button>
                    <MoreButton items={actionOptions} onSelect={(action) => onMoreActionSelected(action, row)} />
                </div>
            )
        } else if (row.iref_state === "NEEDS_UPDATE") {
            let actionOptions = [
                { id: "unlink", label: "Unlink", icon: "fa fa-link-slash" },
            ]
            return (
                <div className="flex flex-row justify-end items-center">
                    <Button variant="primary" onClick={() => pushIntegrationReference(row)}>Push</Button>
                    <MoreButton items={actionOptions} onSelect={(action) => onMoreActionSelected(action, row)} />
                </div>
            )
        }
    }

    const renderState = (row) => {
        if (row.iref_state === "PENDING_REMOTE_CREATE") {
            return <Label.Info>Missing in {integration.name}</Label.Info>
        } else if (row.iref_state === "ACTIVE") {
            return <Label.Success>Matched</Label.Success>
        } else if (row.iref_state === "CONFLICT") {
            return <Label.Danger>Conflict</Label.Danger>
        } else if (row.iref_state === "NEEDS_MAPPING") {
            return <Label.Info>Unmatched</Label.Info>
        } else if (row.iref_state === "NEEDS_UPDATE") {
            return <Label.Warning>Pending Update</Label.Warning>
        } else if (row.iref_state === "IGNORED") {
            return <Label.Info>Ignored</Label.Info>
        } else {
            return <Label.Info>{row.iref_state}</Label.Info>
        }
    }

    const pushIntegrationReference = async (ref) => {
        const data = {
            feature: feature,
            sync_type: "PUSH",
            entity: "INVOICE",
            entity_id: ref.invoice_id,
        }
        const result = await serverPost(getApiUrl(`/integrations/${integration.id}/trigger_sync`), data, {});
        if (result) {
            Notification.Success("Initiated a push.")
        }
    }

    const renderInvoice = (reference) => {
        return (
            <div className="flex flex-col">
                <Link href={getCompanySpecificUrl(`/invoice/${reference.invoice_uuid}`)}>{ reference.invoice_number }</Link>
                <span className="text-sm text-gray-400">{ currencyFormatFromPrice(reference.total) }</span>
            </div>
        )
    }

    const triggerSync = async () => {
        const params = {
            feature: feature,
            sync_type: "PUSH"
        }
        const res = await serverPost(getApiUrl(`/integrations/${integration.id}/trigger_sync`), params)
        if (res) {
            fetchSyncPreview(true);
        }
    }

    const allRows = syncPreview ? syncPreview.invoices: [];
    return (
        <ContentContainer>
            <div className="flex flex-row gap-2">
                <div className="grow">
                    <h5>Next Sync Preview</h5>
                    <p>Below is the summary of actions to be performed in the next sync.</p>
                </div>
                <div>
                    <Button variant="primary" onClick={triggerSync}>Start Sync</Button>
                </div>
            </div>
            <h5 className="mt-4">Invoices</h5>
            <Loader loading={loading}>
                <MapleTable className="mt-2">
                    <MapleTable.Content overflow>
                        <thead>
                        <tr>
                            <MapleTable.TH>Invoice</MapleTable.TH>
                            <MapleTable.TH>Action</MapleTable.TH>
                            <MapleTable.TH></MapleTable.TH>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(allRows, (row, i) =>
                                <tr key={i}>
                                    <td>{ renderInvoice(row.invoice_iref) }</td>
                                    <td>{ renderState(row.invoice_iref) }</td>
                                    <td className="w-px whitespace-nowrap">{ renderActions(row.invoice_iref) }</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
            </Loader>
            <UpdateIntegrationReferenceModal
                show={showUpdateIntegrationReferenceModal}
                onClose={onModalClose}
                feature={feature}
                reference_type={"INVOICE"}
                reference={referenceToEdit}
            />
            <SimpleModal
                show={showUnlinkConfirmationModal}
                title="Confirm Unlink"
                body={
                    <p>
                        <span>Are you sure you want to unlink the invoice?</span>
                        <br/><br/>
                        <span>Note: This will prevent any future updates for this invoice to be pushed to {integration.name}.</span>
                    </p>
                }
                buttonVariant="danger"
                buttonTitle="Confirm Unlink"
                onConfirm={() => {
                    setShowUnlinkConfirmationModal(false);
                    unmatchReference(referenceToUnlink);
                }}
                onClose={() => {
                    setShowUnlinkConfirmationModal(false);
                }}
            />
        </ContentContainer>
    );
}

export default IntegrationSyncPreviewDetails;
