import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect, useContext } from 'react';
import BaseSideModal from './BaseSideModal';
import SubmitButton from '../common/buttons/SubmitButton';
import Notification from '../Notification';
import { serverPost, serverPatch } from '../../helpers/server';
import {BaseContext, sleep} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function BaseAddOrEditItemModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl, company } = useContext(BaseContext);
    const [isEditing, setIsEditing] = useState(false);

    const addItem = async (itemFields, defaultErrorHandler) => {
        let errorHandler = defaultErrorHandler;
        if (props.errorHandlerWrapper) {
            errorHandler = (errorRes) => props.errorHandlerWrapper(defaultErrorHandler, errorRes);
        }

        const addFields = props.processAddFields({...itemFields, company_id: company.id});
        if (props.addItem) {
            props.addItem(addFields, errorHandler);
        } else {
            const addItemUrl = getApiUrl(props.itemBaseUrl);
            const result = await serverPost(addItemUrl, addFields, {}, errorHandler);
            if (result) {
                Notification.Success(`${props.itemLabel} created`);
                if (props.onAdded) {
                    props.onAdded(result);
                }
                props.onClose(true);
            }
        }
    }

    const updateItem = async (itemFields, defaultErrorHandler) => {
        let errorHandler = defaultErrorHandler;
        if (props.errorHandlerWrapper) {
            errorHandler = (errorRes) => props.errorHandlerWrapper(defaultErrorHandler, errorRes);
        }

        const updateFields = props.processUpdateFields({...itemFields, company_id: company.id})
        if (props.updateItem) {
            props.updateItem(updateFields, errorHandler);
        } else {
            const updateItemUrl = getApiUrl(`${props.itemBaseUrl}/${props.itemToEdit.id}`);
            const result = await serverPatch(updateItemUrl, updateFields, {}, errorHandler);
            if (result) {
                Notification.Success(`${props.itemLabel} updated`);
                if (props.onUpdated) {
                    props.onUpdated(result);
                }
                props.onClose(true);
            }
        }
    }

    useEffect(() => {
        if (_.isNil(props.isEditing)) {
            setIsEditing(!_.isNil(props.itemToEdit));
        } else {
            setIsEditing(props.isEditing);
        }
    }, [props.itemToEdit, props.isEditing]);

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form
                ref={props.formRef}
                initialFormFields={props.itemToEdit || props.initialFormFields}
                onFieldChange={props.onFieldChange}
                onSubmit={isEditing ? updateItem : addItem}
                errorFields={props.errorFields}
                className={props.formClassName}
            >
                <BaseSideModal.Header title={isEditing ? t('common.modal.edit', {itemLabel: props.itemLabel}) : t('common.modal.add', {itemLabel: props.itemLabel})} />
                <BaseSideModal.Body>
                    { props.children }
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton variant="primary" disabled={props.submitDisabled}>{isEditing ? t('common.modal.update', {itemLabel: props.itemLabel}) : t('common.modal.add', {itemLabel: props.itemLabel})}</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default BaseAddOrEditItemModal;

BaseAddOrEditItemModal.defaultProps = {
    processAddFields: (fields) => fields,
    processUpdateFields: (fields) => fields
};
