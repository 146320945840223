import { useMemo, memo, useRef, useEffect, useContext } from 'react';

// utils
import { storageLogout } from '../../../helpers/storage';
import { serverPost, getUrl } from '../../../helpers/server';
import _ from 'lodash';

// components
import DropdownMenu from '../../DropdownMenu';
import Columns from '../../Columns';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import ChevronSelectorVertical from '@untitled-ui/icons-react/build/esm/ChevronSelectorVertical';
import { AppContext } from '../../../helpers/common';

const iconSize = {
	width: 20,
	height: 20,
};

function Profile({ isHeader = false, userInfo, company, isExpanded }) {
	const { isNavCollapsed } = useContext(AppContext);
	const dropdownRef = useRef(null);
	const spoofToken = localStorage.getItem('spoof_auth_token');

	useEffect(() => {
		if (isNavCollapsed && !isExpanded && dropdownRef.current) {
			dropdownRef.current.close();
		}
	}, [isNavCollapsed, isExpanded]);

	const endSpoof = () => {
		localStorage.setItem('auth_token', localStorage.getItem('spoof_auth_token'));
		localStorage.removeItem('spoof_auth_token');
		window.location = '/internal/super/dashboard';
	};

	const logout = () => {
		serverPost(getUrl('/users/logout'), {}).then((res) => {
			storageLogout();
			window.location = '/login';
		});
	};

	const topItems = useMemo(
		() =>
			_.filter(
				[
					(userInfo.is_super_user || (userInfo.companies && userInfo.companies.length > 1)) && {
						link: '/user/home',
						icon: 'fa-arrows-rotate',
						label: 'Switch Company',
					},
					userInfo.is_super_user && {
						icon: 'fa-arrows-rotate',
						link: '/internal/super/dashboard',
						label: 'Super User',
					},
					spoofToken && {
						onClick: endSpoof,
						icon: 'fa-right-from-bracket',
						label: 'End Spoof',
					},
				],
				(i) => i,
			),
		[userInfo.is_super_user, userInfo.companies, spoofToken],
	);

	const UpdatedProfile = () => {
		return (
			<div className='w-full'>
				<DropdownMenu
					ref={dropdownRef}
					className='w-full'
					fullWidth
					buttonFullWidth
					alignTop
					hideCaret
					items={[
						...topItems,
						!_.isEmpty(topItems) && {
							divider: true,
						},
						{
							link: '/user/profile',
							icon: 'fa-user',
							label: 'Profile',
						},
						{
							onClick: logout,
							icon: 'fa-right-from-bracket',
							label: 'Logout',
						},
					]}
				>
					<div
						className={`profile-container flex grow gap-2 border rounded-xl cursor-pointer overflow-hidden`}
					>
						<Columns.CustomerAvatar2 customer={userInfo} className='profile-icon-size' />
						<div className='flex flex-col justify-start items-start w-full overflow-hidden'>
							<span className='profile-name-color font-medium text-sm text-left w-full truncate'>
								{userInfo.name || userInfo.email}
							</span>
							<span className='text-sm text-white text-left w-full truncate'>
								{company ? company.name : ''}
							</span>
						</div>
						<div className='flex justify-self-end justify-end items-start text-white'>
							<ChevronSelectorVertical {...iconSize} />
						</div>
					</div>
				</DropdownMenu>
			</div>
		);
	};

	if (!isHeader) {
		return <UpdatedProfile />;
	}
	return (
		<div className='flex items-center'>
			<DropdownMenu
				hideCaret
				items={[
					...topItems,
					!_.isEmpty(topItems) && {
						divider: true,
					},
					{
						link: '/user/profile',
						icon: 'fa-user',
						label: 'Profile',
					},
					{
						onClick: logout,
						icon: 'fa-right-from-bracket',
						label: 'Logout',
					},
				]}
			>
				<div className='flex flex-row items-center'>
					<Columns.CustomerAvatar2 customer={userInfo} />
					<div className='ml-2 text-start whitespace-nowrap'>
						<p className='text-sm font-medium text-gray-700 group-hover:text-gray-900'>
							{userInfo.name || userInfo.email}
						</p>
						<p className='text-xs font-medium text-gray-500 group-hover:text-gray-700'>
							{company ? company.name : ''}
						</p>
					</div>
					<EllipsisVerticalIcon className='h-7 w-7' aria-hidden='true' />
				</div>
			</DropdownMenu>
		</div>
	);
}

export default memo(Profile);
