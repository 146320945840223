import '../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {BaseContext, currencyFormatFromPrice} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import ContentBox from "../../components/ContentBox";
import MapleTable from "../../components/MapleTable";
import moment from "moment";
import BaseForm from "../../components/BaseForm";
import SubmitButton from "../../components/common/buttons/SubmitButton";
import Breadcrumb from "../../components/Breadcrumb";
import {serverPost} from "../../helpers/server";
import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import SectionNav from "../../components/SectionNav";
import Columns from "../../components/Columns";
import InfiniteScroll from "react-infinite-scroll-component";
import {renderJournalAccount} from "../../helpers/journalEntries";
const _ = require('lodash');

function RecognizedRevenueSummaries(props) {
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    let { selectedDate } = useParams();
    const navigate = useNavigate();

    const [activeNav, setActiveNav] = useState("summary");
    const [loading, setLoading] = useState(true);
    const [balances, setBalances] = useState({});
    const [fromKey, setFromKey] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [journalEntries, setJournalEntries] = useState([]);
    const [periodOptions, setPeriodOptions] = useState([]);
    const initialFields = useMemo(() => {
        return {
            period: selectedDate,
        }
    }, [selectedDate]);

    useEffect(() => {
        setPageTitle(`Financial Reports`);

        const start_date = moment(company.created_at).startOf('month');
        const end_date = moment().startOf('month').add(1, 'month');
        let options = [];
        let current = start_date
        while(current.isBefore(end_date)) {
            options.push({
                value: current.format('YYYY-MM-DD'),
                label: current.format('MMMM YYYY')
            })
            current.add(1, 'month')
        }

        setPeriodOptions(_.reverse(options))
    }, []);

    useEffect(() => {
        runReport(initialFields);
    }, [selectedDate])

    useEffect(() => {
        if (activeNav === "entries") {
            onSearch(true);
        }
    }, [activeNav]);

    const onSearch = (restart = true) => {
        console.log("on search " + restart + ", " + fromKey);
        const limit = 100;
        const reportData = {
            query: {
                entry_date: {
                    GTE: moment(selectedDate).startOf('month'),
                    LTE: moment(selectedDate).startOf('month').add(1, 'month')
                }
            },
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            sort_key: "entryTimeAsc"
        }
        serverPost(getApiUrl("/journal_entries/find"), reportData).then(res => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setJournalEntries(results);
                } else {
                    setJournalEntries(_.concat(journalEntries, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        })
    }

    const exportResults = async () => {

    }

    const runReport = async (data) => {
        setLoading(true);
        const params = {
            period: {
                start_date: moment(selectedDate).startOf('month'),
                end_date: moment(selectedDate).endOf('month')
            }
        }
        const result = await serverPost(getApiUrl(`/journal_accounts/balance_summaries`), params);
        if (result) {
            setBalances(result.journal_account_balance_summaries || []);
        }
        setLoading(false);
    };

    const onFieldChange = (name, value) => {
        if (name === "period") {
            navigate(getCompanySpecificUrl(`/reports/revenue_recognition/summaries/${value}`));
        }
    }

    let reportTitle = "Monthly Summary";

    const breadcrumbItems = [
        { name: 'Financial Reports', href: getCompanySpecificUrl(`/reports`), current: false },
        { name: 'Revenue Recognition', href: getCompanySpecificUrl(`/reports/revenue_recognition`), current: false },
        { name: reportTitle, current: true }
    ]

    const tabItems = [{
        'label': 'Ledger Summary',
        'id': 'summary',
        'active': activeNav === 'summary'
    }, {
        'label': 'Balance Sheet',
        'id': 'balance_sheet',
        'active': activeNav === 'balance_sheet'
    }, {
        'label': 'Journal Entries',
        'id': 'entries',
        'active': activeNav === 'entries'
    }]

    const renderSummary = () => {
        return (
            <MapleTable key={"summary"}>
                <div className="flex flex-row items-center">
                    <div className="grow">
                        <span className="text-base font-semibold text-gray-700">Journal Entries Monthly Summary</span><br/>
                        <span className="text-gray-500"></span>
                    </div>
                    <div className="inline-block cursor-pointer px-2.5 py-1.5 rounded-md bg-white border-1 border-slate-300" onClick={exportResults}>
                        <span className="text-slate-900">Export</span>
                    </div>
                </div>
                <MapleTable.Content>
                    <thead>
                    <tr>
                        <MapleTable.TH className="text-end border-s border-gray-200 bg-gray-50"></MapleTable.TH>
                        <MapleTable.TH className="text-end border-s border-gray-200 bg-gray-50">Debit</MapleTable.TH>
                        <MapleTable.TH className="text-end border-s border-gray-200 bg-gray-50">Credit</MapleTable.TH>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {
                        _.map(balances, (b, i) =>
                            <tr key={i}>
                                <td className="border-e border-gray-200">
                                    <span className="font-semibold">{ b.journal_account.name }</span>
                                </td>
                                <td className="text-end bg-gray-50 border-s border-gray-200">{ currencyFormatFromPrice(b.net_debit) }</td>
                                <td className="text-end bg-gray-50 border-s border-gray-200">{ currencyFormatFromPrice(b.net_credit) }</td>
                            </tr>
                        )
                    }
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
        )
    }

    const renderBalanceSheet = () => {
        return (
            <MapleTable key={"balance_sheet"} notfull>
                <div className="flex flex-row items-center">
                    <div className="grow">
                        <span className="text-base font-semibold text-gray-700">Balance Sheet</span><br/>
                        <span className="text-gray-500"></span>
                    </div>
                    <div className="inline-block cursor-pointer px-2.5 py-1.5 rounded-md bg-white border-1 border-slate-300" onClick={exportResults}>
                        <span className="text-slate-900">Export</span>
                    </div>
                </div>
                <MapleTable.Content>
                    <thead>
                    <tr>
                        <MapleTable.TH className="text-end border-s border-gray-200 bg-gray-50"></MapleTable.TH>
                        <MapleTable.TH className="text-end border-s border-gray-200 bg-gray-50">Ending Balance</MapleTable.TH>
                        <MapleTable.TH className="text-end border-s border-gray-200 bg-gray-50">Net Change</MapleTable.TH>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {
                        _.map(balances, (b, i) =>
                            <tr key={i}>
                                <td className="border-e border-gray-200">
                                    <span className="font-semibold">{ b.journal_account.name }</span>
                                </td>
                                <td className="text-end bg-gray-50 border-s border-gray-200">{ currencyFormatFromPrice(b.balance) }</td>
                                <td className="text-end bg-gray-50 border-s border-gray-200">{ currencyFormatFromPrice(b.net_movement) }</td>
                            </tr>
                        )
                    }
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
        )
    }

    const renderEntries = () => {
        const dateFormat = "MM/DD/YYYY";
        return (
            <>
                <div className="flex flex-row items-center">
                    <div className="grow">
                        <span className="text-base font-semibold text-gray-700">Journal Entries</span><br/>
                        <span className="text-gray-500"></span>
                    </div>
                    <div className="inline-block cursor-pointer px-2.5 py-1.5 rounded-md bg-white border-1 border-slate-300" onClick={exportResults}>
                        <span className="text-slate-900">Export</span>
                    </div>
                </div>
                <InfiniteScroll
                    dataLength={journalEntries.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                    key={"entries"}
                >
                    <Section loading={loading} title="">
                        <MapleTable className="min-w-full divide-y divide-gray-300">
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <MapleTable.TH>Customer</MapleTable.TH>
                                    <MapleTable.TH>Time</MapleTable.TH>
                                    <MapleTable.TH>Product</MapleTable.TH>
                                    <MapleTable.TH>Account</MapleTable.TH>
                                    <MapleTable.TH className="text-end">Debit</MapleTable.TH>
                                    <MapleTable.TH className="text-end">Credit</MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(journalEntries, (entry, i) =>
                                        <>
                                            {
                                                entry.destination_journal_account &&
                                                <tr key={i+"debit"}>
                                                    <td><Columns.CustomerName customer={entry.customer} /></td>
                                                    <td>{ moment(entry.entry_time).utc().format(dateFormat) }</td>
                                                    <td>{ entry.product && entry.product.name }</td>
                                                    <td>{ entry.destination_journal_account && renderJournalAccount(entry.destination_journal_account) }</td>
                                                    <td className="text-end">{ currencyFormatFromPrice(entry.amount) }</td>
                                                    <td className="text-end text-gray-400">-</td>
                                                </tr>
                                            }
                                            {
                                                entry.source_journal_account &&
                                                <tr key={i+"credit"}>
                                                    <td><Columns.CustomerName customer={entry.customer} /></td>
                                                    <td>{ moment(entry.entry_time).utc().format(dateFormat) }</td>
                                                    <td>{ entry.product && entry.product.name }</td>
                                                    <td>{ entry.source_journal_account && renderJournalAccount(entry.source_journal_account) }</td>
                                                    <td className="text-end text-gray-400">-</td>
                                                    <td className="text-end">{ currencyFormatFromPrice(entry.amount) }</td>
                                                </tr>
                                            }
                                        </>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    </Section>
                </InfiniteScroll>
            </>
        )
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb items={breadcrumbItems}/>
                <Section variant="page" title={`${reportTitle} Report`}>
                    <ContentBox>
                        <ContentBox.Body>
                            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                <Row>
                                    <BaseForm.Input
                                        colSpan="6" name="period" type="select" label="Month" options={periodOptions}
                                        showSearch={false}
                                    />
                                    <Col md="6" className="flex items-end justify-end pb-2 hide">
                                        <SubmitButton>Run</SubmitButton>
                                    </Col>
                                </Row>
                            </BaseForm>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <Section loading={loading} className="mt-4">
                    <SectionNav size="sm" items={tabItems} className="my-2" onClick={(i) => setActiveNav(i)}/>
                    { activeNav ===  "summary" && renderSummary() }
                    { activeNav ===  "balance_sheet" && renderBalanceSheet() }
                    { activeNav ===  "entries" && renderEntries() }
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default RecognizedRevenueSummaries;
