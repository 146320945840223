import '../../../App.scss';
import React from 'react';
import {EllipsisVerticalIcon} from "@heroicons/react/24/outline";
import DropdownMenu from "../../DropdownMenu";

function MoreButton(props) {

    return (
        <DropdownMenu className="px-1 py-2" items={props.items} onClick={props.onSelect} hideCaret popover={props.popover}>
            <EllipsisVerticalIcon className="text-gray-700 h-5 w-5"/>
        </DropdownMenu>
    )
}

export default MoreButton;
