import React, {useContext, useEffect, useState} from "react";
import {BaseContext, currencyFormatFromPrice, getLabelForCommissionReportStatus} from "../../helpers/common";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import {serverPost} from "../../helpers/server";
import _ from "lodash";
import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import InfiniteScroll from "react-infinite-scroll-component";
import Section from "../../components/Section";
import MapleTable from "../../components/MapleTable";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
import SortableTableHeader from "../../components/SortableTableHeader";
import Columns from "../../components/Columns";
import Label from '../../components/Label';
import moment from "moment";

function CommissionAggregatedReports() {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [sort, setSort] = useState('startDateDesc');
    const [fromKey, setFromKey] = useState(null);
    const [meta, setMeta] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();

    useEffect(() => {
        setPageTitle('Commission Reports');
    }, []);

    const onSearch = (restart = true) => {
        const params = {
            query: {},
        };
        serverPost(getApiUrl('/commission/aggregated_reports/find'), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setReports(res);
                } else {
                    setReports(_.concat(reports, results));
                }
                if (res.meta) {
                    setMeta(res.meta);
                }

                setLoading(false);
            }
        });
    };

    useEffect(() => {
        onSearch();
    }, [sort]);

    const handleRowClick = (report) => {
        navigate(getCompanySpecificUrl(`/commissions/reports/view?`) + new URLSearchParams({
            endDate: report.end_date
        }));
    };

    return (
		<BaseContainer>
			<ContentContainer>
				<InfiniteScroll
					next={() => onSearch(false)}
					hasMore={hasMore}
					scrollableTarget='content-wrapper'
					dataLength={reports.length}
				>
					<Section title='Commission Reports' loading={loading} variant='page'>
						<MapleTable>
							<MapleTableHeaderWithActions showSearch={false} meta={meta} />
							<MapleTable.Content>
								<thead>
									<tr>
										<MapleTable.TH>Period</MapleTable.TH>
										<MapleTable.TH>Status</MapleTable.TH>
										<MapleTable.TH># of Sales Members</MapleTable.TH>
										<MapleTable.TH className='text-end'>Total Commissions Achieved</MapleTable.TH>
										<MapleTable.TH className='text-end'>Total Quota Achieved</MapleTable.TH>
									</tr>
								</thead>
								<tbody className='divide-y divide-gray-200'>
									{reports.map((report, i) => (
										<tr
											key={i}
											onClick={() => handleRowClick(report)}
											className='cursor-pointer hover:bg-gray-50'
										>
											<td>
												{moment(report.start_date).format('MMM D, YYYY')} -{' '}
												{moment(report.end_date).format('MMM D, YYYY')}
											</td>
											<td>{getLabelForCommissionReportStatus(report.status)}</td>
											<td>{report.report_period_ids.length}</td>
											<td className='text-end'>
												{currencyFormatFromPrice(report.commission_achieved)}
											</td>
											<td className='text-end'>
												{currencyFormatFromPrice(report.quota_achieved)}
											</td>
										</tr>
									))}
								</tbody>
							</MapleTable.Content>
						</MapleTable>
					</Section>
				</InfiniteScroll>
			</ContentContainer>
		</BaseContainer>
	);
}

export default CommissionAggregatedReports;