import '../../App.scss';
import '../../css/modals.scss';
import React, {useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function AddOrEditEntitlementModal(props) {
    const { t } = useTranslation('common');
    const [initialFormFields, setInitialFormFields] = useState({});
    const [keyChanged, setKeyChanged] = useState(false);
    const [type, setType] = useState("BOOL");

    useEffect(() => {
        if (!props.show) {
            setInitialFormFields({});
            setKeyChanged(false);
        } else {
            setType(props.itemToEdit ? props.itemToEdit.type: "BOOL")
        }
    }, [props.show, props.itemToEdit])

    const onFieldChange = (name, value) => {
        if (name === "name") {
            setInitialFormFields(prevFields => {
                const newFields = {...prevFields};
                if (!keyChanged) {
                    newFields.key = value.trim().replace(/[\W]+/g,"-").toLowerCase();
                }
                newFields.name = value;
                return newFields;
            })
        } else if (name === "key") {
            setInitialFormFields(prevFields => {
                const newFields = {...prevFields};
                newFields.key = value;
                return newFields;
            })
            setKeyChanged(true);
        } else if (name === "type") {
            setType(value);
            setInitialFormFields(prevFields => {
                const newFields = {...prevFields};
                newFields.type = value;
                newFields.aggregator_type = null;
                return newFields;
            })
        } else if (name === "aggregator_type") {
            setInitialFormFields(prevFields => {
                const newFields = {...prevFields};
                newFields.aggregator_type = value;
                return newFields;
            })
        }
    }

    const typeOptions = [
        { value: "BOOL", label: "Boolean" },
        { value: "NUMBER", label: "Number" },
        { value: "TEXT", label: "Text" },
        { value: "ENUM", label: "Choice" },
    ]

    const aggregatorTypeOptions = {
        "BOOL": [
            {value: "OR", label: "Or"},
            {value: "AND", label: "And"},
            {value: "COALESCE", label: "Coalesce"},
        ],
        "NUMBER": [
            {value: "ADD", label: "Add"},
            {value: "MINIMUM", label: "Minimum"},
            {value: "MAXIMUM", label: "Maximum"},
        ],
        "TEXT": [
            {value: "COALESCE", label: "Coalesce"},
        ],
        "ENUM": [
            {value: "COALESCE", label: "Coalesce"},
        ]
    }

    const isEditing = !_.isNil(props.itemToEdit);
    return (
        <BaseAddOrEditItemModal
            {...props}
            onFieldChange={onFieldChange}
            initialFormFields={initialFormFields}
            itemLabel={"Entitlement"}
            itemBaseUrl="/entitlements"
        >
            <Row>
                <BaseForm.Input
                    colSpan="12" name="type" label={"Type"} type="select" options={typeOptions} required
                    disabled={isEditing} showSearch={false}
                />
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="12" name="key" label={"Key"} type="text" required disabled={isEditing} />
                <BaseForm.Input
                    colSpan="12" name="aggregator_type" label={"Aggregator Type"} type="select"
                    description="How should we combine entitlement values from multiple subscriptions for a customer?"
                    options={aggregatorTypeOptions[type]} required showSearch={false}
                />
                {
                    type === "ENUM" &&
                        <BaseForm.Input
                            colSpan="12" name="possible_values" label={"Choices"} type="selector"
                            isMulti={true} canCreate={true} formatCreateLabel={(inputValue) => `Add choice - ${inputValue}`}
                            isValidNewOption={(option) => _.trim(option).length !== 0 } />
                }
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditEntitlementModal;
