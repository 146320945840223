import '../App.scss';
import '../css/invoice.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {notifyEvent, serverFetch, serverPatch, serverPost} from '../helpers/server';
import { BaseContext, getCurrencyOptions, IntegrationContext } from '../helpers/common';
import BaseForm from './BaseForm';
import Link from "./Link";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import MapleTable from "./MapleTable";
import SubmitButton from "./common/buttons/SubmitButton";
import Notification from "./Notification";
import Loader from "./Loader";
const _ = require('lodash');

function AccountingDepositAccountingMappingComponent(props) {
    const { company, getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);

    const [currencies, setCurrencies] = useState(getCurrencyOptions());
    const [bankAccounts, setBankAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const initialFields = useMemo(() => {
        if (!_.isEmpty(integration.accounting_data)) {
            const initialData = {
                deposit_bank_account: {}
            };
            if (integration.accounting_data) {
                _.each(integration.accounting_data.deposit_bank_account, (bankAccountId, currency) => {
                    initialData.deposit_bank_account[currency] = bankAccountId;
                })
            }
            return initialData
        }
        return {}
    }, [integration]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/integrations/${integration.id}/accounting/bank_accounts`), { skipCache: true }).then((res) => {
            if (res) {
                setBankAccounts(res.accounts);
            }
            setLoading(false);
        })

        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [])

    const refreshBankAccounts = async () => {
        setLoading(true);
        serverFetch(getApiUrl(`/integrations/${integration.id}/accounting/bank_accounts`), { skipCache: true }).then((res) => {
            if (res) {
                setBankAccounts(res.accounts);
            }
        })

        Notification.Success(`${integration.name} bank accounts refreshed.`)
        setLoading(false);
    }

    const onIntegrationProductIdsMap = (data) => {
        console.log(data)
        const keys = Object.keys(data.deposit_bank_account);
        const mappingData = {};
        _.each(keys, (key, i) => {
            if (data.deposit_bank_account[key]) {
                mappingData[key] = data.deposit_bank_account[key];
            }
        })
        const updateData = {
            feature_data: {
                accounting_data: {
                    deposit_bank_account: mappingData,
                }
            }
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            Notification.Success(`${integration.name} product ID mapping saved.`)
            notifyEvent('integration_settings');
            if (props.onUpdate) {
                props.onUpdate();
            }
        })
    }
    const integrationProductOptions = _.map(bankAccounts, (pid) => {
        return {
            value: pid.id,
            label: pid.name
        }
    })
    integrationProductOptions.unshift({ value: null, label: "None" })
    return (
        <div>
            <p className="text-base font-semibold text-gray-700">Map the bank accounts between Maple and { integration.name }.</p>
            <p className="text-sm font-normal text-gray-500">In order to ensure that the payments are pushed to the right bank accounts, we have to have an accurate mapping of Maple currencies with { integration.name } bank accounts.</p>
            <div className="flex flex-row justify-end items-center mt-2">
                {
                    loading ?
                        <div className="h-4 w-4 spinner-border text-secondary"/>
                        : <Link className="text-sm flex flex-row gap-1 items-center" onClick={() => refreshBankAccounts()}><ArrowPathIcon className="h-4 w-4"/> Refresh { integration.name } Products</Link>
                }
            </div>
            <Loader loading={loading}>
            <BaseForm className="mt-0" initialFormFields={initialFields} onSubmit={onIntegrationProductIdsMap}>
                <MapleTable>
                    <MapleTable.Content overflow>
                        <thead>
                        <tr>
                            <MapleTable.TH>Currency</MapleTable.TH>
                            <MapleTable.TH>{ integration.name } Bank Account</MapleTable.TH>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {

                            _.map(currencies, (currency, i) =>
                                <tr key={i}>
                                    <td width="50%">
                                        <span className="text-gray-700 font-semibold">{ currency.label }</span><br/>
                                    </td>
                                    <td>
                                        <BaseForm.Input name={`deposit_bank_account.${currency.label}`} type="select" label={"Product ID"} hideLabel formClassName="mb-0" options={integrationProductOptions} showSearch={integrationProductOptions.length > 6} />
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
                <div className="text-sm text-gray-500 my-3 italic">*Note: You can update these mappings later as well.</div>
                <SubmitButton>Update Mapping</SubmitButton>
            </BaseForm>
            </Loader>
        </div>
    );
}

export default AccountingDepositAccountingMappingComponent;
