import BaseContainer from "../../components/BaseContainer";
import React from "react";
import CommissionReports from "./CommissionReports";
import {useSearchParams} from "react-router-dom";

function CommissionReportsWrapper() {
    const [ searchParams ] = useSearchParams();
    let salesUserId = searchParams.get('sales_user_id');

    return (
        <BaseContainer>
            <CommissionReports  salesUserId={salesUserId} />
        </BaseContainer>
    )
}

export default CommissionReportsWrapper;