import '../App.scss';
import '../css/invoice.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {serverPost, serverPatch} from '../helpers/server';
import {
    currencyFormatFromPrice,
    BaseContext,
    downloadBlob,
    UserContext,
} from '../helpers/common';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import Notification from './Notification';
import InvoicePaymentModal from './modals/InvoicePaymentModal';
import AdminInvoicePaymentModal from './modals/AdminInvoicePaymentModal';
import ContentContainer from './ContentContainer';
import CopyableComponent from './CopyableComponent';
import InvoiceDetailsComponent from './InvoiceDetailsComponent';
import SingleInvoiceEmailModal from './modals/SingleInvoiceEmailModal';
import MarkInvoiceAsPaidModal from './modals/MarkInvoiceAsPaidModal';
import SimpleModal from './modals/SimpleModal';
import { Alert } from 'react-bootstrap';
import Button from './common/buttons/Button';
import moment from 'moment';
import classnames from 'classnames';
import EditInvoiceModal from "./modals/EditInvoiceModal";
import DropdownMenu from "./DropdownMenu";
import { WrenchScrewdriverIcon, ShareIcon } from '@heroicons/react/20/solid'
import InvoiceTaxModal from "./modals/InvoiceTaxModal";
import {getShareLinkUrl, InvoiceManager, renderStatusLabel} from "../helpers/invoices";
import IntegrationReferenceEntitySummaryModal from "./modals/IntegrationReferenceEntitySummary";
import Link from "./Link";
import PoweredByMapleFooter from './PoweredByMapleFooter';
import DotsVertical from '@untitled-ui/icons-react/build/esm/DotsVertical';
import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown';
const _ = require('lodash');

function InvoiceComponent(props) {
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    let redirectStatus = searchParams.get('redirect_status');
    let setupIntent = searchParams.get('setup_intent');
    let amount = searchParams.get('amount');
    const { company, getApiUrl, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const { isSuperUser, isMapleUser, userInfo } = useContext(UserContext);

    const { t } = useTranslation('common');
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [settings, setSettings] = useState({});
    const [showInvoiceEmailModal, setShowInvoiceEmailModal] = useState(false);
    const [showInvoicePaidModal, setShowInvoicePaidModal] = useState(false);
    const [showInvoicePaymentModal, setShowInvoicePaymentModal] = useState(false);
    const [showAdminInvoicePaymentModal, setShowAdminInvoicePaymentModal] = useState(false);
    const [showShareLinkModal, setShowShareLinkModal] = useState(false);
    const [showVoidModal, setShowVoidModal] = useState(false);
    const [showFinalizeModal, setShowFinalizeModal] = useState(false);
    const [showEditInvoiceModal, setShowEditInvoiceModal] = useState(false);
    const [showInvoiceTaxModal, setShowInvoiceTaxModal] = useState(false);
    const [showReferenceSummaryModal, setShowReferenceSummaryModal] = useState(false);
    const [showForceGenerateModal, setShowForceGenerateModal] = useState(false);
    const [hideFooter, setHideFooter] = useState(false);
    const [canTriggerCampaign, setCanTriggerCampaign] = useState(false);
    const [showRestartCampaignToggle, setShowRestartCampaignToggle] = useState(false);

    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const [isImportedInvoice, setIsImportedInvoice] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const hasInvoicesWritePermission = hasAccess("invoices", userInfo, "write");

    useEffect(() => {
        setInvoiceDetails(props.invoice);
        setIsImportedInvoice(!_.isNil(props.invoice) && !_.isNil(props.invoice.imported_from));
    }, [props.invoice, props.readOnly])

    useEffect(() => {
        setSettings(props.settings);
    }, [props.settings])

    useEffect(() => {
        if (!_.isNil(props.hideFooter)) {
            setHideFooter(props.hideFooter);
        } else {
            setHideFooter(false)
        }
    }, [props.hideFooter])

    useEffect(() => {
        if (_.isNil(redirectStatus)) {
            return;
        }
        setTimeout(() => {
            let urlParser = new URL(window.location.href);
            urlParser.searchParams.delete('payment_intent');
            urlParser.searchParams.delete('payment_intent_client_secret');
            urlParser.searchParams.delete('redirect_status');
            navigate(urlParser.pathname + urlParser.search, { replace: true });
            window.location.reload();
        }, 1000);
    }, [redirectStatus])

    useEffect(() => {
        if (_.isNil(setupIntent)) {
            return;
        }
        processPayment(setupIntent, amount);
    }, [setupIntent])

    const onError = async (res) => {
        const errorMessage = await res.json();
        setErrorMessage(errorMessage)
    };

    const processPayment = async (setupIntentId, amount) => {
        const paymentData = {
            payment_data: {
                stripe: {
                    setup_intent_id: setupIntentId
                }
            }
        }
        if (!_.isNil(amount)) {
            paymentData.amount = {
                value_in_cents: parseInt(amount),
                currency: props.invoice.due.currency,
            }
        }
        serverPost(getApiUrl(`/invoices/${props.invoice.uuid}/pay`), paymentData, {}, onError).then(res => {
            if (res) {
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }

            setTimeout(() => {
                let urlParser = new URL(window.location.href);
                urlParser.searchParams.delete('setup_intent');
                urlParser.searchParams.delete('setup_intent_client_secret');
                urlParser.searchParams.delete('amount');
                navigate(urlParser.pathname + urlParser.search, { replace: true });
                window.location.reload();
            }, 2000);
        })
    }

    const onModalClose = (didUpdate) => {
        setShowInvoicePaymentModal(false);
        setShowAdminInvoicePaymentModal(false);
        setShowShareLinkModal(false);
        setShowInvoicePaidModal(false);
        setShowVoidModal(false);
        setShowFinalizeModal(false);
        setShowEditInvoiceModal(false);
        setShowReferenceSummaryModal(false);
        setShowRestartCampaignToggle(false);
        if (didUpdate) {
            if (props.onUpdate) {
                props.onUpdate();
            }
        }
    }

    const onPay = () => {
        setErrorMessage(null)
        setShowInvoicePaymentModal(true);
    }

    const onInvoiceEditClose = (updated) => {
        if (updated) {
            Notification.Success("Successfully updated invoice");
        }
        onModalClose(updated);
    }

    const onActionSelected = (type) => {
        if (type === "edit") {
            setShowEditInvoiceModal(true);
        } else if (type === "email") {
            setShowInvoiceEmailModal(true);
        } else if (type === "link") {
            setShowShareLinkModal(true);
        } else if (type === "pdf") {
            serverPost(getApiUrl(`/invoices/${invoiceDetails.uuid}/pdf`), {}, { noJson: true }).then((res) => {
                downloadBlob(res, `Invoice-${invoiceDetails.number}`);
            })
        } else if (type === "mark_paid") {
            setShowInvoicePaidModal(true);
        } else if (type === "mark_void") {
            setShowVoidModal(true);
        } else if (type === "trigger_campaign") {
            const data = {
                type: "invoice_reminder",
                invoice_uuid: invoiceDetails.uuid,
                customer_id: invoiceDetails.customer_id
            }
            serverPost(getApiUrl("/campaigns/trigger"), data).then((res) => {
                if (res) {
                    Notification.Success("Successfully started reminder schedule")
                    if (props.onUpdate) {
                        props.onUpdate();
                    }
                } else {
                    Notification.Danger("Could not start reminder schedule")
                }
            })
        } else if (type === "references") {
            setShowReferenceSummaryModal(true);
        } else if (type === "finalize") {
            setShowFinalizeModal(true);
        } else if (type === "chargeCustomerBalance") {
            onChargeCustomerBalance()
        } else if (type === "chargeACHTransfer") {
            onChargeACHTransfer()
        } else if (type === "force_generate") {
            setShowForceGenerateModal(true);
        }
    }


    const isPaymentProviderSetup = !_.isNil(settings.payment_config);
    const invoiceManager = new InvoiceManager(invoiceDetails, props.isAdmin, isMapleUser);
    const canRetryPayment = invoiceManager.canAdminAddPayment() && hasInvoicesWritePermission;

    useEffect(() => {
        const isPending = invoiceDetails.status === "PENDING";
        setCanTriggerCampaign(isPending && getCanTriggerCampaign());
    }, [invoiceDetails, settings]);

    const getCanTriggerCampaign = () => {
        const areEnabled =  settings.send_customer_emails &&
            settings.non_auto_charge_invoice_reminder_enabled
        const noActiveCampaign = invoiceDetails.campaigns &&
            (invoiceDetails.campaigns.length === 0 ||
                !invoiceDetails.campaigns.some(c => c.status === "ACTIVE"));

        return areEnabled && noActiveCampaign;
    }

    const showDivider = invoiceManager.canEdit() || invoiceManager.canIssueCreditNote() || invoiceManager.canAdminAddPayment();
    const actionOptions = _.filter([
        invoiceManager.canEdit() && { id: "edit", label: "Edit" },
        invoiceManager.canIssueCreditNote() && { id: "credit_note", label: "Issue Credit Note", link: getCompanySpecificUrl(`/invoice/${invoiceDetails.uuid}/credit_note`) },
        invoiceManager.canAdminAddPayment() && { id: "mark_paid", label: "Mark as Paid" },
        showDivider && { divider: true },
        invoiceManager.canVoid() && { id: "mark_void", label: "Mark as Void" },
        invoiceManager.canFinalize() && { id: "finalize", label: "Finalize Invoice" },
        canTriggerCampaign && { id: "trigger_campaign", label: "Start Reminder Schedule" },
        invoiceManager.canForceGenerate() && { id: "force_generate", label: "Generate Now" },
        invoiceManager.canViewReferences() && { id: "references", label: "References" },
        invoiceManager.canAdminAddPayment() && isSuperUser && { id: "chargeCustomerBalance", label: "Charge Customer Balance" },
        invoiceManager.canAdminAddPayment() && isSuperUser && { id: "chargeACHTransfer", label: "Charge ACH Transfer" },
    ], a => !!a);

    const shareOptions = invoiceManager.canShare() ? [
        { id: "link", label: "Share via Link" },
        { id: "email", label: "Share via Email" },
        { id: "pdf", label: "Download PDF" },
    ]: [];

    const onProcessPayment = () => {
        setShowAdminInvoicePaymentModal(true);
    }

    const voidInvoice = () => {
        serverPost(getApiUrl(`/invoices/${invoiceDetails.uuid}/void`), {}).then((res) => {
            if (res) {
                Notification.Success("Successfully voided invoice");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        });
    }

    const fetchGeneratedInvoice = async (currentTime) => {
        const params = {
            sort_key: "createdAtDesc",
            pagination: {
                from_key: null,
                limit: 50
            },
            query: {
                subscription_id: invoiceDetails.subscription_id,
                invoice_date: {
                    gte: currentTime
                }
            },
            include_meta: false
        }
        const res = await serverPost(getApiUrl("/invoices/find"), params);
        if (res) {
            const results = res.results || [];
            if (results.length > 0) {
                return results[0];
            }
        }
        return null;
    }

    const onConfirmForceGenerateInvoice = async () => {
        let currentTime = moment();
        await serverPost(getApiUrl(`/subscriptions/${invoiceDetails.subscription_id}/generate_next_invoice`), {})
        setTimeout(async () => {
            const invoice = await fetchGeneratedInvoice(currentTime);
            if (invoice) {
                navigate(getCompanySpecificUrl(`/invoice/${invoice.uuid}`), { replace: true })
            } else {
                navigate(getCompanySpecificUrl(`/subscription/${invoiceDetails.subscription_id}`), { replace: true })
            }
        }, 1000)
    }

    const finalizeInvoice = () => {
        serverPost(getApiUrl(`/invoices/${invoiceDetails.uuid}/finalize`), {}).then((res) => {
            if (res) {
                Notification.Success("Successfully finalized invoice");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        });
    }

    const onChargeCustomerBalance = () => {
        serverPost(getApiUrl(`/invoices/${invoiceDetails.uuid}/charge_stripe_customer_balance`), {}).then((res) => {
            if (res) {
                Notification.Success("Successfully paid invoice");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        });
    }
    const onChargeACHTransfer = () => {
        serverPost(getApiUrl(`/invoices/${invoiceDetails.uuid}/charge_ach_credit_transfer`), {}).then((res) => {
            if (res) {
                Notification.Success("Successfully paid invoice.");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        });
    }

    const recomputeTaxes = () => {
        const data = {
            'recompute_taxes': true
        }
        serverPatch(getApiUrl(`/invoices/${invoiceDetails.uuid}`), data).then((res) => {
            if (res) {
                Notification.Success("Successfully recomputed taxes");
                if (props.onUpdate) {
                    props.onUpdate();
                }
            }
        });
    }

    const getMostRecentCampaign = () => {
        if (!invoiceDetails.campaigns || invoiceDetails.campaigns.length === 0) {
            return null
        }
        const sortedCampaigns = invoiceDetails.campaigns.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        return sortedCampaigns[0]
    }

    const renderReminders = () => {
        if (!props.isAdmin) {
            return null;
        }
        if (invoiceDetails.campaigns && invoiceDetails.campaigns.length > 0) {
            const campaign = getMostRecentCampaign()
            if (!campaign || campaign.status !== "ACTIVE") {
                return null;
            }
            const doneSteps = campaign.current_step-1;
            let nextStepDescription = null;
            if (campaign.next_action_in) {
                if (campaign.next_action_type === "payment_retry") {
                    nextStepDescription = `Payment retry scheduled for ${moment(campaign.next_action_in).format("MMM D, YYYY h:mm:ssa")}`
                } else if (campaign.next_action_type === "notification") {
                    nextStepDescription = `Reminder email scheduled for ${moment(campaign.next_action_in).format("MMM D, YYYY h:mm:ssa")}`
                } else if (campaign.next_action_type === "cancel_subscription") {
                    nextStepDescription = `Subscription to be cancelled on ${moment(campaign.next_action_in).format("MMM D, YYYY h:mm:ssa")}`
                } else if (campaign.next_action_type === "unpaid_subscription") {
                    nextStepDescription = `Subscription to be marked unpaid on ${moment(campaign.next_action_in).format("MMM D, YYYY h:mm:ssa")}`
                }
            }
            return (
                <div className="w-full px-2 text-end text-gray-500 italic">
                    <div className="flex flex-row gap-1 justify-between sm:justify-end">
                        {/*{ doneSteps > 0 && <span>{ doneSteps } reminders sent.</span> }*/}
                        { nextStepDescription && <span className="text-left">{ nextStepDescription }.</span> }
                        <Link href={getCompanySpecificUrl(`/campaign/${campaign.id}`)}>View all reminders</Link>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    return (
        <>
            <Loader loading={_.isEmpty(invoiceDetails)}>
            {
                () =>
                    <ContentContainer
                        {...(isMapleUser ? {
                            enableStickyHeader: true,
                            title: (
                                <>
                                    {
                                        redirectStatus &&
                                            <div className="invoice-wrapper-header-updated">
                                            {
                                                redirectStatus === "succeeded" &&
                                                    <div className="flex-grow-1 text-center">
                                                        <Alert variant="success">Payment has been successfully processed.</Alert>
                                                    </div>
                                            }
                                            {
                                                redirectStatus === "failed" &&
                                                    <div className="flex-grow-1 text-center">
                                                        <Alert variant="danger">Payment processing has failed. Please try again later.</Alert>
                                                    </div>
                                            }
                                            </div>
                                    }
                                    {
                                        errorMessage &&
                                            <div className="invoice-wrapper-header-updated">
                                                <span className="form-error-message">{ errorMessage }</span>
                                            </div>
                                    }
                                    <div className="invoice-wrapper-header-updated">
                                        <div className="flex-grow-0 d-flex flex-row gap-3 align-items-center">
                                            {
                                                invoiceManager.canCustomerAddPayment() && isPaymentProviderSetup &&
                                                    <Button variant="primary" onClick={onPay}>Pay Now</Button>
                                            }
                                            {
                                                canRetryPayment &&
                                                    <Button variant="primary" onClick={() => onProcessPayment()}>Process Payment</Button>
                                            }
                                            { renderStatusLabel(invoiceDetails, getCompanySpecificUrl) }
                                            {
                                                invoiceManager.isPaid() && invoiceDetails.total.value_in_cents > 0 &&
                                                    <h4>{ currencyFormatFromPrice(invoiceDetails.total) }</h4>
                                            }
                                            {
                                                invoiceManager.shouldShowPaymentDueAmount() &&
                                                    <h4>{ currencyFormatFromPrice(invoiceDetails.due) }</h4>
                                            }
                                        </div>
                                    </div>
                                </>
                            ),
                            customActions: (
                                <div className="flex items-center gap-[12px]">
                                    {
                                        !isImportedInvoice && !_.isEmpty(shareOptions) &&
                                            <DropdownMenu className="hidden sm:block" items={shareOptions} onClick={onActionSelected} hideCaret>
                                                <Button>
                                                    <span className="flex items-center gap-1">
                                                        Share
                                                        <ChevronDown className="inline" width={20} height={20} />
                                                    </span>
                                                </Button>
                                            </DropdownMenu>
                                    }
                                    {
                                        !isImportedInvoice && !_.isEmpty(actionOptions) && hasInvoicesWritePermission &&
                                            <DropdownMenu className="hidden sm:block" items={actionOptions} onClick={onActionSelected} hideCaret>
                                                <Button variant="text-outline" hasIconOnly>
                                                    <DotsVertical width={20} height={20} />
                                                </Button>
                                            </DropdownMenu>
                                    }
                                    {
                                        !isImportedInvoice && !_.isEmpty(actionOptions) && !_.isEmpty(shareOptions) && hasInvoicesWritePermission &&
                                            <DropdownMenu className="block sm:hidden" items={[...shareOptions, ...(shareOptions.length > 0 ? [{ divider: true }] : []), ...actionOptions]} onClick={onActionSelected} hideCaret>
                                                <Button variant="text-outline" hasIconOnly>
                                                    <DotsVertical width={20} height={20} />
                                                </Button>
                                            </DropdownMenu>
                                    }
                                </div>
                            ),
                            headerClassName: classnames(
                                "invoice-wrapper flex justify-self-center",
                                props.wrapperClassName,
                                !props.wrapperClassName?.split(" ").includes("full") ? "!pt-5" : "!pt-0",
                            ),
                        } : {})}
                    >
                        <div className="d-flex justify-content-center">
                            <div className={classnames("invoice-wrapper", props.wrapperClassName)}>
                                {!isMapleUser && <>
                                    {
                                        redirectStatus &&
                                            <div className="invoice-wrapper-header">
                                            {
                                                redirectStatus === "succeeded" &&
                                                    <div className="flex-grow-1 text-center">
                                                        <Alert variant="success">Payment has been successfully processed.</Alert>
                                                    </div>
                                            }
                                            {
                                                redirectStatus === "failed" &&
                                                    <div className="flex-grow-1 text-center">
                                                        <Alert variant="danger">Payment processing has failed. Please try again later.</Alert>
                                                    </div>
                                            }
                                            </div>
                                    }
                                    {
                                        errorMessage &&
                                            <div className="invoice-wrapper-header">
                                                <span className="form-error-message">{ errorMessage }</span>
                                            </div>
                                    }
                                    <div className="invoice-wrapper-header">
                                        <div className="flex-grow-0 d-flex flex-row gap-3 align-items-center" >
                                        {
                                            invoiceManager.canCustomerAddPayment() && isPaymentProviderSetup &&
                                                <Button variant="primary" onClick={onPay}>Pay Now</Button>
                                        }
                                        {
                                            canRetryPayment &&
                                                <Button variant="primary" onClick={() => onProcessPayment()}>Process Payment</Button>
                                        }
                                        { renderStatusLabel(invoiceDetails, getCompanySpecificUrl) }
                                        {
                                            invoiceManager.isPaid() && invoiceDetails.total.value_in_cents > 0 &&
                                                <h4>{ currencyFormatFromPrice(invoiceDetails.total) }</h4>
                                        }
                                        {
                                            invoiceManager.shouldShowPaymentDueAmount() &&
                                                <h4>{ currencyFormatFromPrice(invoiceDetails.due) }</h4>
                                        }
                                        </div>
                                        <div className="flex-grow-1"/>
                                        <div className="flex-grow-0 d-flex gap-2">
                                            {
                                                !isImportedInvoice && !_.isEmpty(actionOptions) && hasInvoicesWritePermission &&
                                                    <DropdownMenu className="p-2" items={actionOptions} onClick={onActionSelected}>
                                                        <div className="flex flex-row gap-1 items-center">
                                                            <WrenchScrewdriverIcon className="h-4 w-4"/>
                                                            <span>Actions</span>
                                                        </div>
                                                    </DropdownMenu>
                                            }
                                            {
                                                !isImportedInvoice && !_.isEmpty(shareOptions) &&
                                                    <DropdownMenu className="p-2" items={shareOptions} onClick={onActionSelected}>
                                                        <div className="flex flex-row gap-1 items-center">
                                                            <ShareIcon className="h-4 w-4"/>
                                                            <span>Share</span>
                                                        </div>
                                                    </DropdownMenu>
                                            }
                                        </div>
                                    </div>
                                </>}
                                { renderReminders() }
                                <InvoiceDetailsComponent
                                    {...props}
                                    recomputeTaxes={recomputeTaxes}
                                    showTaxDetailsModal={() => setShowInvoiceTaxModal(true)}
                                    showTaxAsEstimate={props.showTaxAsEstimate}
                                    onChangeIncludeTaxes={props.onChangeIncludeTaxes} />
                                {
                                    !hideFooter &&
                                    <div className="invoice-wrapper-footer">
                                        <div className="flex-grow-1"/>
                                        <PoweredByMapleFooter />
                                        <div className="flex-grow-1"/>
                                    </div>
                                }
                            </div>
                        </div>
                    </ContentContainer>
            }
            </Loader>
            <SingleInvoiceEmailModal
                show={showInvoiceEmailModal} onClose={setShowInvoiceEmailModal} invoice={invoiceDetails}
                settings={settings}
            />
            {
                invoiceManager.canMarkAsPaid() &&
                    <MarkInvoiceAsPaidModal show={showInvoicePaidModal} onClose={onModalClose} invoice={invoiceDetails} />
            }
            <InvoicePaymentModal
                show={showInvoicePaymentModal} onClose={onModalClose} invoice={invoiceDetails}
                paymentConfig={settings.payment_config} settings={settings} />
            <AdminInvoicePaymentModal
                show={showAdminInvoicePaymentModal} onClose={onModalClose} invoice={invoiceDetails}
                settings={settings} paymentConfig={settings.payment_config}
            />
            <SimpleModal show={showShareLinkModal} onClose={onModalClose} title="Share Invoice" buttonTitle="Done"
                body={
                    <>
                    <p className="body1">Please feel free to share this link with any relevant party.</p>
                    <CopyableComponent
                        isButton
                        className="mt-2"
                        value="Copy Invoice Link"
                        copyableValue={getShareLinkUrl(invoiceDetails, getCompanySpecificUrl)}
                    />
                    </>
                } />
            <SimpleModal
                show={showVoidModal}
                onClose={onModalClose}
                title="Confirm"
                buttonTitle="Confirm"
                onConfirm={voidInvoice}
                body={
                    <>
                        <p>Are you sure you want to void the invoice?</p>
                        <span className="caption italic">Note: This action can not be reversed once completed.</span>
                    </>
                }
            />
            <SimpleModal
                show={showFinalizeModal}
                onClose={onModalClose}
                title="Confirm"
                buttonTitle="Confirm"
                onConfirm={finalizeInvoice}
                body={
                    <>
                        <p>Are you sure you want to finalize the invoice?</p>
                        <span className="caption italic">Note: This action can not be reversed once completed.</span>
                    </>
                }
            />
            <EditInvoiceModal
                show={showEditInvoiceModal}
                onClose={onInvoiceEditClose}
                invoice={invoiceDetails}
                reminderCampaignsEnabled={settings.non_auto_charge_invoice_reminder_enabled}
                showRestartCampaignToggle={showRestartCampaignToggle}
                setShowRestartCampaignToggle={setShowRestartCampaignToggle}
            />
            <InvoiceTaxModal
                show={showInvoiceTaxModal} onClose={setShowInvoiceTaxModal} invoice={props.invoice}
                recomputeTaxes={recomputeTaxes}
            />
            <IntegrationReferenceEntitySummaryModal
                show={showReferenceSummaryModal} onClose={onModalClose} entityKey={"INVOICE"}
                referenceID={invoiceDetails.id}
            />
            <SimpleModal
                show={showForceGenerateModal}
                onClose={setShowForceGenerateModal}
                title="Generate Invoice Now?"
                body={
                    <>
                        <p>Are you sure you want to generate this future invoice now?</p><br/>
                        <p>Note: This action can not be undone. Please review carefully before proceeding.</p>
                    </>
                }
                confirmationText="I UNDERSTAND"
                buttonTitle="Generate Now"
                onConfirm={onConfirmForceGenerateInvoice}
            />
        </>
    );
}

export default InvoiceComponent;
