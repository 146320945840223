import '../../App.scss';
import '../../css/modals.scss';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import BaseSideModal from './BaseSideModal';
import Notification from '../Notification';
import React, { useState, useEffect, useContext } from 'react';
import {
    BaseContext,
} from '../../helpers/common';
import {serverPatch} from '../../helpers/server';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function UpdateSubscriptionInvoiceGradePeriodModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState(null);
    const [initialFields, setInitialFields] = useState({});

    useEffect(() => {
        setSubscription(props.subscription)
        if (props.subscription && !_.isEmpty(props.subscription)) {
            setInitialFields({
                invoice_event_grace_period: props.subscription.invoice_event_grace_period,
            })
        }
    }, [props.subscription])

    const onSubmit = async (data) => {
        const updateData = {
            invoice_event_grace_period: data.invoice_event_grace_period
        }
        const result = await serverPatch(getApiUrl(`/subscriptions/${subscription.id}`), updateData);
        if (result) {
            props.onClose(true);
            Notification.Success("Successfully updated")
        }
    }

    if (!props.show) {
        return;
    }
    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onSubmit={onSubmit} >
                <BaseSideModal.Header title={"Change Invoice Grace Period"}/>
                <BaseSideModal.Body>
                    <BaseForm.Input
                        type="number" name="invoice_event_grace_period" label={"Invoice Grace Period (in seconds)"}
                        description="Determine how much delay should be applied before each invoice is generated for the period. Typically used to allow for some buffer to send usage data for the completed period before the invoice is generated."
                        step={1} min={0}
                    />
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>{t('common.update')}</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default UpdateSubscriptionInvoiceGradePeriodModal;
