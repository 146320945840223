import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {serverPost, serverDelete} from '../../../helpers/server';
import { BaseContext } from '../../../helpers/common';
import MoreButton from '../../../components/common/buttons/MoreButton';
import MapleTable from '../../../components/MapleTable';
import ArchiveButton from '../../../components/common/buttons/ArchiveButton';
import Notification from '../../../components/Notification';
import ContentContainer from '../../../components/ContentContainer';
import Button from '../../../components/common/buttons/Button';
import ExistingContractTemplates from "../../../components/ExistingContractTemplates";
import Link from "../../../components/Link";
import CopyableComponent from "../../../components/CopyableComponent";
import BoxedSection from '../../../components/layout/BoxedSection';
const _ = require('lodash');

function ContractTemplates() {
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        setPageTitle(`Contract Settings`);
    }, []);

    useEffect(() => {
        fetchData(true);
    }, []);

    const fetchData = (skipCache=false) => {
        serverPost(getApiUrl("/proposals/templates/find"), {}).then((res) => {
            setTemplates(res);
            setLoading(false);
        });
    }

    const deleteTemplate = async (template) => {
        await serverDelete(getApiUrl(`/proposals/templates/${template.id}`)).then((res) => {
            Notification.Success("Template successfully deleted");
            fetchData(true);
        })
    }

    const onTemplateActionSelected = (template) => {
        const templateData = {
            name: `${template.name} - Copy`,
            content: template.content
        }
        serverPost(getApiUrl('/proposals/templates'), templateData).then((res) => {
            if (res) {
                fetchData();
            }
        })
    }

    const templateActionOptions = [
        { id: "duplicate", label: "Duplicate" }
    ]

    return (
        <>
            <ContentContainer>
                <BoxedSection
                    title="Contract Templates"
                    className={"mt-4"}
                    loading={loading}
                    actions={[{
                        variant: "primary",
                        icon: "fa-plus",
                        label: "Create Template",
                        link: getCompanySpecificUrl("/contract/template/create")
                    }]}>
                    <MapleTable>
                        <MapleTable.Content removeMargin removePadding>
                            <thead>
                                <tr>
                                    <MapleTable.TH>Name</MapleTable.TH>
                                    <MapleTable.TH className="min-w-[100px]"></MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(templates, (row, i) =>
                                     <tr key={i}>
                                        <td>{ row.name }</td>
                                         <td><CopyableComponent value={row.id}/></td>
                                        <td className="flex flex-row items-center justify-end">
                                            <Link href={getCompanySpecificUrl(`/contract/template/edit/${row.id}`)}>
                                                <Button variant="text-primary" className="flex items-center" overlayContent="Edit"><i className="fa fa-edit text-base"/></Button>
                                            </Link>
                                            <ArchiveButton size="sm" onDelete={async () => await deleteTemplate(row)} overlayContent="Archive" className="flex items-center"></ArchiveButton>
                                            <MoreButton items={templateActionOptions} onSelect={() => onTemplateActionSelected(row)} popover />
                                        </td>
                                     </tr>
                                )
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </BoxedSection>
                <hr className="mt-4"/>
                <ExistingContractTemplates
                    className="mt-3"
                    title={"Or select an existing template"}
                    showAddNew={false}
                    templates={templates}
                />
            </ContentContainer>
        </>
    );
}

export default ContractTemplates;
