import '../App.scss';
import React, { useState, useEffect, useContext } from 'react';
import {
    BaseContext,
    UserContext,
    getCurrentCompanyUser
} from '../helpers/common';
import { serverPost } from '../helpers/server';
import Columns from './Columns';
import MapleTable from './MapleTable';
import Label from './Label';
import ConfirmationButton from "./common/buttons/ConfirmationButton";
import Button from './common/buttons/Button';
import Link from "./Link";
import RejectApprovalRequestModal from "./modals/RejectApprovalRequestModal";
import rejectApprovalRequestModal from "./modals/RejectApprovalRequestModal";
import ContentBox from './ContentBox';
const _ = require('lodash');

function ApprovalRequestListSection(props) {
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [includeCustomer, setIncludeCustomer] = useState(true);
    const [approvalRequests, setApprovalRequests] = useState([]);
    const [showRejectApprovalRequestModal, setShowRejectApprovalRequestModal] = useState(false);
    const [approvalRequestToReject, setApprovalRequestToReject] = useState(null);

    const hasReadPermissions = hasAccess("invoices", userInfo, "read");

    useEffect(() => {
        if (!_.isNil(props.approvalRequests)) {
            setApprovalRequests(props.approvalRequests);
        }
    }, [props.approvalRequests])

    useEffect(() => {
        if (!_.isNil(props.includeCustomer)) {
            setIncludeCustomer(props.includeCustomer);
        }
    }, [props.includeCustomer])

    if (!hasReadPermissions) {
        return null;
    }

    const approveRequest = async (req) => {
        if (req.proposal) {
            const data = {
                approval_request_ids: [req.id]
            }
            await serverPost(getApiUrl(`/proposals/${req.proposal.id}/approve`), data).then(async (res) => {
                if (res) {
                    if (props.onUpdate) {
                        await props.onUpdate();
                    }
                }
            })
        }
    }

    const rejectRequest = (req) => {
        setShowRejectApprovalRequestModal(true);
        setApprovalRequestToReject(req);
    }

    const renderApprovalRequestStatus = (request) => {
        if (request.status === "NEEDS_APPROVAL") {
            const currentCompanyUser = getCurrentCompanyUser(company.id, userInfo);
            if (currentCompanyUser && request.approver.company_user.id === currentCompanyUser.id) {
                if (props.redirectActions) {
                    return <Link href={getCompanySpecificUrl(`/contract/view/${request.proposal.id}`)}>
                        <Button variant="text-primary">Review</Button>
                    </Link>
                } else {
                    return <>
                        <Button variant="text-danger" onClick={() => rejectRequest(request)}>Reject</Button>
                        <ConfirmationButton
                            title="Confirm Approve"
                            onConfirm={async () => await approveRequest(request)} variant="primary"
                        >
                            Approve
                        </ConfirmationButton>
                    </>
                }
            } else {
                return <Label.Info>Waiting for Approval</Label.Info>
            }
        } else if (request.status === "APPROVED") {
            return <Label.Success>Approved</Label.Success>
        } else if (request.status === "REJECTED") {
            return <div className="flex flex-col gap-1 items-end">
                <Label.Danger>Rejected</Label.Danger>
                {
                    request.reason &&
                        <span className="italic text-xs">Reason: { request.reason }</span>
                }
            </div>
        }
    }

    const renderTable = () => {
        return (
            <>
                <MapleTable>
                    <MapleTable.Content>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(approvalRequests, (ar, k) =>
                                <tr key={k}>
                                    <td>
                                        <Columns.CustomerName customer={ar.proposal.customer} />
                                    </td>
                                    <td>
                                        <span className="font-semibold">Contract</span><br/>
                                        <span>{ ar.proposal.title }</span>
                                    </td>
                                    <td>{ ar.approval_rule.name }</td>
                                    <td><Columns.CustomerAvatar customer={ ar.approver.company_user.user } /></td>
                                    <td className="text-end w-px whitespace-nowrap">
                                        { renderApprovalRequestStatus(ar) }
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
                <RejectApprovalRequestModal
                    show={showRejectApprovalRequestModal} onClose={setShowRejectApprovalRequestModal}
                    approvalRequest={approvalRequestToReject} onUpdate={props.onUpdate}
                />
            </>
        );
    };

    if (_.isEmpty(approvalRequests)) {
        return;
    }

    return (
        <ContentBox>
            <ContentBox.Title>
                Approval Requests
            </ContentBox.Title>
            <ContentBox.Body className="!py-0">
                {renderTable()}
            </ContentBox.Body>
        </ContentBox>
    );
}

export default ApprovalRequestListSection;
