import '../../App.scss';
import React, {useEffect, useState, useContext, createRef, useRef, useCallback, useMemo, memo} from 'react';
import {serverFetch, serverPost, serverPatch} from '../../helpers/server';
import {ClockIcon, CheckCircleIcon} from "@heroicons/react/24/solid";
import {
    BaseContext,
    renderContractStatusLabel,
    downloadBlob,
    getCustomerNameOrEmail,
    getInvoicePaymentTermFromDueDateFromCreation,
    currencyFormatFromPrice,
    findCustomVariables,
    getInvoicePaymentTermDescription,
    UserContext,
    renderCampaignStatusLabel,
    getConfigActionTypeKey,
    getStatusForStep,
} from '../../helpers/common';
import { canEditContract, isContractDraft, isCampaignsEnabled, canPreviewContract } from '../../helpers/contracts';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import SectionNav from '../../components/SectionNav';
import Loader from '../../components/Loader';
import BundlePricingTable from '../../components/BundlePricingTable';
import KeyValueDisplay from '../../components/KeyValueDisplay2';
import SingleContractEmailModal from '../../components/modals/SingleContractEmailModal';
import ShareContractLinkModal from '../../components/modals/ShareContractLinkModal';
import ContentBox from '../../components/ContentBox';
import Link from '../../components/Link';
import {Alert as RBAlert} from 'react-bootstrap';
import {useLocation, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import Notification from "../../components/Notification";
import {ShareIcon, WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import DropdownMenu from "../../components/DropdownMenu";
import SimpleModal from "../../components/modals/SimpleModal";
import ApprovalRequestListSection from "../../components/ApprovalRequestListSection";
import ErrorComponent from "../../components/ErrorComponent";
import IntegrationReferenceEntitySummaryModal from "../../components/modals/IntegrationReferenceEntitySummary";
import MapleTable from "../../components/MapleTable";
import DeleteButton from "../../components/DeleteButton";
import ManualExecutionModal from "../../components/modals/ManualExecutionModal";
import SignOfflineModal from "../../components/modals/SignOfflineModal";
import Button from "../../components/common/buttons/Button";
import BaseOverlayTrigger from "../../components/BaseOverlayTrigger";
import useGetMembers from "../../helpers/hooks/api/useGetMembers";
import {getAllProductPricingIdsInContract, getProductPricingIdsInPhase} from "../../helpers/subscriptions";
import Eye from '@untitled-ui/icons-react/build/esm/Eye';
import Edit02 from '@untitled-ui/icons-react/build/esm/Edit02';
import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown';
import Check from '@untitled-ui/icons-react/build/esm/Check';
import File05 from '@untitled-ui/icons-react/build/esm/File05';
import ContractPreviewModal from '../../components/modals/ContractPreviewModal';
import DotsVertical from '@untitled-ui/icons-react/build/esm/DotsVertical';
import classnames from 'classnames';
import AddOrEditProductPricingModal2 from "../../components/modals/AddOrEditProductPricingModal2";
import ContractChangeTemplateModal from "../../components/modals/ContractChangeTemplateModal";
import ContractCreateUpdate from '../../components/ContractCreateUpdate';
import CopyableComponent from "../../components/CopyableComponent";
import SignatoryStatus from '../../components/SignatoryStatus';
import Alert from '../../components/common/Alert';
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import BaseSideModal from "../../components/modals/BaseSideModal";
import ChangeContractRequestPaymentModal from "../../components/modals/ChangeContractRequestPaymentModal";

const _ = require('lodash');

function Contract() {
    const {t} = useTranslation('common');
    const {uuid} = useParams();
    const location = useLocation();
    const {isSuperUser, isMapleUser, userInfo} = useContext(UserContext);
    const {getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess} = useContext(BaseContext);
    const [loadingContract, setLoadingContract] = useState(true);
    const [contractDetails, setContractDetails] = useState({});
    const [activeNav, setActiveNav] = useState("details");
    const [settings, setSettings] = useState({});
    const [productPricings, setProductPricings] = useState([]);
    const [preselectedProductPricingIds, setPreselectedProductPricingIds] = useState([]);
    const [initialContractData, setInitialContractData] = useState({});
    const [templateFields, setTemplateFields] = useState({});
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [showRevertConfirmationModal, setShowRevertConfirmationModal] = useState(false);
    const [showReferenceSummaryModal, setShowReferenceSummaryModal] = useState(false);
    const [details, setDetails] = useState([]);
    const [customVariableDetails, setCustomVariableDetails] = useState({});
    const [isEditingTemplate, setIsEditingTemplate] = useState(false);
    const [approvalRequests, setApprovalRequests] = useState([]);
    const [customVariables, setCustomVariables] = useState([]);
    const [availableCustomVariables, setAvailableCustomVariables] = useState([]);
    const pricingsRef = createRef();
    const [canTriggerCampaign, setCanTriggerCampaign] = useState(false);
    const [mostRecentCampaign, setMostRecentCampaign] = useState(null);
    const [showManualExecuteModal, setShowManualExecuteModal] = useState(false);
    const [showSignOfflineModal, setShowSignOfflineModal] = useState(false);
    const [showUploadSignedContractModal, setShowUploadSignedContractModal] = useState(false);
    const [showChangeTemplateModal, setShowChangeTemplateModal] = useState(false);
    const [hasAnyoneSigned, setHasAnyoneSigned] = useState(false);
    const [hasEveryoneSigned, setHasEveryoneSigned] = useState(true);
    const [isContractPreviewVisible, setIsContractPreviewVisible] = useState(null);
    const [showAddProductPricingModal, setShowAddProductPricingModal] = useState(false)
    const [createdProductPricing, setCreatedProductPricing] = useState(null)
    const [showSearchDealsModal, setShowSearchDealsModal] = useState(false);
    const [showCustomerAddressError, setShowCustomerAddressError] = useState(false);
    const [showStickyHeader, setShowStickyHeader] = useState(false);
    const [signatoryStatuses, setSignatoryStatuses] = useState(null);
    const [contractDetailsHeight, setContractDetailsHeight] = useState(null);
    const [showChangeRequestPaymentModal, setShowChangeRequestPaymentModal] = useState(false);
    const [isScreenSmall, setIsScreenSmall] = useState(false);
    const updateSuccessRef = useRef(false);
    const formRef = useRef(null);
    const formCreateUpdateRef = useRef(null);
    const isFinalizingRef = useRef(false);
    const actionBarRef = useRef(null);
    const contractDetailsRef = useRef(null);
    const { members, fetchMembers } = useGetMembers(false);

    const canViewContractUpdates = useCallback((details) => !_.isEmpty(details || contractDetails) && !isContractDraft(details || contractDetails), [contractDetails]);

    const isCreatingContract = useMemo(() => _.isNil(uuid), [uuid]);

    const isEditingOrCreatingContract = useMemo(() => {
        return isContractDraft(contractDetails) || isCreatingContract;
    }, [contractDetails, isCreatingContract]);

    const hasContractsWritePermission = hasAccess("proposal", userInfo, "write");

    useEffect(() => {
        if (contractDetails.title) {
            setPageTitle(`Contract - ${contractDetails.title}`);
        } else {
            setPageTitle(`Contracts`);
        }
    }, [contractDetails]);

    useEffect(() => {
        setMostRecentCampaign(getMostRecentCampaign())
    }, [contractDetails]);

    useEffect(() => {
        const isPending = contractDetails.status === 'PENDING_SIGNATURES' || contractDetails.status === 'PENDING_PAYMENT';
        setCanTriggerCampaign(isPending && getCanTriggerCampaign())
    }, [contractDetails, settings]);

    useEffect(() => {
        if (!isCreatingContract) {
            fetchData();
        }
    }, [isCreatingContract])

    useEffect(() => {
        const teamOptions = _.map(members, (member) => {
            return {
                value: member.id,
                label: member.user.name || member.user.email
            }
        })
        teamOptions.sort((a, b) => {
            if (a.label < b.label) {
                return -1
            } else {
                return 1
            }
        })
        const counterSignOptions = teamOptions.map((option) => option)

        teamOptions.unshift({value: null, label: "No one"})
        counterSignOptions.unshift({value: null, label: "Skip counter sign"})
    }, [members]);

    useEffect(() => {
        serverFetch(getApiUrl(`/proposals/templates/fields`)).then((res) => {
            if (res) {
                setTemplateFields(_.keyBy(res, 'key'))
            }
        })

        fetchMembers()

        serverPost(getApiUrl("/proposals/variables/find"), {}).then((res) => {
            setAvailableCustomVariables(res);
        });
    }, []);

    useEffect(() => {
        if (_.isEmpty(contractDetails)) {
            setApprovalRequests([]);
            return;
        }

        const settingsData = {
            customer_id: contractDetails.customer_id,
        }
        serverPost(getApiUrl(`/resolved_settings`), settingsData).then((res) => {
            if (res) {
                setSettings(res)
            }
        })

        const getApprovalRequests = () => {
            const requestData = {
                query: {
                    proposal_id: contractDetails.id
                }
            }
            serverPost(getApiUrl(`/approval_requests/find`), requestData).then((res) => {
                if (res) {
                    setApprovalRequests(res);
                }
            });
        };

        const getContractStatusSummary = async () => {
            if (isCreatingContract || isContractDraft(contractDetails)) return;

            const result = await serverFetch(getApiUrl(`/proposals/${uuid}/status_summary`));
            setSignatoryStatuses(result);
        };
        
        getApprovalRequests();
        getContractStatusSummary();
    }, [contractDetails]);

    useEffect(() => {
        if (!_.isNil(createdProductPricing)) {
            if (pricingsRef.current) {
                pricingsRef.current.onProductPricingCreated(createdProductPricing)
            }
            setCreatedProductPricing(null)
        }
    }, [createdProductPricing, showAddProductPricingModal])

    const onProductPricingCreated = (productPricing) => {
        setTimeout(() => {
            setCreatedProductPricing(productPricing);
        }, 100);
    }

    const onModalClose = () => {
        setShowAddProductPricingModal(false);
    }

    const updateConfigWithProductPricingInfo = (phase, pricings) => {
        if (phase.onetime_items) {
            _.each(phase.onetime_items, item => {
                const pp = _.find(pricings, r => r.id === item.product_pricing_id);
                if (pp && pp.product_metric_pricings.length > 0) {
                    phase.config[pp.product_metric_pricings[0].id] = {
                        num_licenses: item.aggregate
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (preselectedProductPricingIds.length === 0) {
            setProductPricings([]);
        } else {
            serverPost(getApiUrl(`/product_pricings/batch`), {ids: preselectedProductPricingIds}).then((res) => {
                if (res) {
                    setProductPricings(res);
                    setInitialContractData(prevData => {
                        const newData = {...prevData};
                        _.each(newData.phases, phase => {
                            updateConfigWithProductPricingInfo(phase, res);
                        })
                        return newData;
                    });
                }
            });
        }
    }, [preselectedProductPricingIds]);

    useEffect(() => {
        let newDetails = {};
        _.each(customVariables, cv => {
            const variable = _.find(availableCustomVariables, acv => acv.key === cv);
            if (variable) {
                newDetails[`${variable.name} (${variable.key})`] = contractDetails.custom_variables[variable.key];
            }
        })
        setCustomVariableDetails(newDetails)
    }, [customVariables]);

    const getCanTriggerCampaign = () => {
        const areEnabled = isCampaignsEnabled(settings);
        const noActiveCampaign = contractDetails.campaigns &&
            (contractDetails.campaigns.length === 0 ||
                !contractDetails.campaigns.some(c => c.status === "ACTIVE"));
        const hasFinalizedDate = contractDetails.finalized_date

        return areEnabled && noActiveCampaign && hasFinalizedDate;
    }

    const updateScheduledChangesTerm = (scheduledChanges) => {
        if (scheduledChanges.length < 2) return;
        
        scheduledChanges.forEach((sc, index) => {
            if (index >= (scheduledChanges.length - 1) || sc.term) return;

            sc.term = scheduledChanges[index + 1].relative_term;
        });
    };

    const fetchData = async (skipCache = false, formFieldUpdateList = []) => {
        return serverFetch(getApiUrl(`/proposals/${uuid}`), {skipCache: skipCache}).then((res) => {
            if (res) {
                res.isExpired = res.status === "EXPIRED";
                updateScheduledChangesTerm(res.scheduled_changes);
                setContractDetails(res);
                const dateType = res.start_type;
                const initialConfig = _.keyBy(res.config_items, 'product_metric_pricing_id');
                _.each(res.one_time_billables, (otb) => {
                    _.each(otb.product_pricing.product_metric_pricings, (pmp) => {
                        initialConfig[pmp.id] = {
                            num_licenses: otb.aggregate
                        };
                    })
                });
                let phases = [{
                    product_pricing_ids: getProductPricingIdsInPhase(res),
                    config: initialConfig,
                    discounts: _.mapValues(_.keyBy(res.discounts, "item_pricing_id"), (v) => {
                        return {
                            percentage: v.percent * 100,
                            amount: v.amount.value_in_cents / 100,
                            type: v.type,
                            expiration_date: v.expiration_date,
                        };
                    }),
                    minimum_spend: res.minimum_spend && res.minimum_spend.value_in_cents/100,
                }]
                if (res.scheduled_changes) {
                    _.each(res.scheduled_changes, sc => {
                        let phase = {
                            onetime_items: sc.onetime_items,
                            product_pricing_ids: getProductPricingIdsInPhase(sc),
                            config: _.keyBy(sc.config_items, 'product_metric_pricing_id'),
                            discounts: _.mapValues(_.keyBy(sc.discounts, "item_pricing_id"), (v) => {
                                return {
                                    percentage: v.percent * 100,
                                    amount: v.amount.value_in_cents / 100,
                                    type: v.type,
                                    expiration_date: v.expiration_date,
                                };
                            }),
                            minimum_spend: sc.minimum_spend && sc.minimum_spend.value_in_cents/100,
                            timing: sc.timing,
                            relative_term: sc.relative_term,
                            proration_type: sc.proration_type,
                        }
                        updateConfigWithProductPricingInfo(phase, productPricings);
                        phases.push(phase)
                    })
                }
                const ifields = {
                    owner: res.owner && res.owner.id,
                    expiration_date: res.expiration_date,
                    start_date: res.start_date,
                    customer: res.customer,
                    term: res.term,
                    title: res.title,
                    signatories: res.signatories.length > 1 ? _.keyBy(res.signatories, 'position') : {0 : res.signatories[0]},
                    counter_signatories: res.counter_signatories && res.counter_signatories.map((c) => c.company_user.id),
                    start_date_type: dateType,
                    require_payment_method: res.require_payment_method,
                    auto_charges: res.auto_charges,
                    phases: phases,
                    invoice_payment_terms: getInvoicePaymentTermFromDueDateFromCreation(res.invoice_due_date_from_creation),
                    invoice_due_date_from_creation: res.invoice_due_date_from_creation,
                    po_number: res.po_number,
                    custom_variables: res.custom_variables,
                    trial: res.trial,
                    num_trial_days: res.trial ? res.trial_term.count : (!isEditingOrCreatingContract ? null : 0),
                    change_timing: res.change_timing,
                    template_id: res.template_id,
                }
                const filteredInitialFields = formFieldUpdateList.length > 0 ? formFieldUpdateList.reduce((newInitialFields, formFieldName) => {
                    if (formFieldName in ifields) {
                        newInitialFields[formFieldName] = ifields[formFieldName];
                    }
                    return newInitialFields;
                }, {}) : ifields;
                setInitialContractData(filteredInitialFields);
                setPreselectedProductPricingIds(getAllProductPricingIdsInContract(res));
                setCustomVariables(findCustomVariables(res.content_template));
                setHasAnyoneSigned(checkIfAnySignatoryHasSigned(res.signatories, res.counter_signatories))

                const everyoneSigned = checkIfEveryoneHasSigned(res.signatories, res.counter_signatories)
                setHasEveryoneSigned(everyoneSigned)
                const data = {
                    'Customer': <Link
                        href={getCompanySpecificUrl(`/customer/${res.customer.id}`)}>{getCustomerNameOrEmail(res.customer)}</Link>,
                    'ID': <CopyableComponent value={res.id}></CopyableComponent>,
                }
                if (res.po_number) {
                    data['PO Number'] = res.po_number
                }
                data['Created At'] = moment(res.created_at).format("DD MMM, YYYY h:mma")
                data['Expiration Date'] = res.expiration_date ? moment(res.expiration_date).format("DD MMM, YYYY h:mma") : null
                data['Start Date'] = getStartDate(res)
                const paymentTermDescription = getInvoicePaymentTermDescription(res.invoice_due_date_from_creation);
                data['Payment Terms'] = <div className={classnames( !canViewContractUpdates(res) ? "d-flex flex-row gap-2 align-items-center justify-items-start" : "flex gap-2 items-center justify-end" )}>
                    <span key={0}>
                        {res.auto_charges ? "Automatic" : "Manual"}
                    </span>
                    {
                        !_.isNil(paymentTermDescription) &&
                        <span key={1} className="text-gray-400">({paymentTermDescription})</span>
                    }
                </div>;
                data['Require Payment Method'] =
                    <span>{res.require_payment_method ? "True" : "False"}</span>
                data['Owner'] = res.owner ?
                    <div>{res.owner.user.name} <span className="text-gray-500">({res.owner.user.email})</span>
                    </div> : null
                if (!isMapleUser) {
                    data['Signatory'] = getCustomerSignatoriesSummary(res.signatories, res.signed_offline, canViewContractUpdates(res))
                }
                if (res.counter_signatories && res.counter_signatories.length > 0) {
                    data['Counter Signatory'] = getCounterSignatoriesSummary(res.counter_signatories, res.signed_offline, canViewContractUpdates(res));
                }
                if (res.minimum_spend && res.minimum_spend.value_in_cents > 0) {
                    data['Minimum Spend'] = currencyFormatFromPrice(res.minimum_spend);
                }
                if (res.signed_offline) {
                    data['Signed Offline'] = <div>Yes</div>
                    if (res.signed_file_id && res.signed_file_id.length > 0) {
                        data['Signed Contract'] =
                            <Link onClick={() => downloadUploadedContract(res.signed_file_id)}>Download signed
                                contract</Link>
                    } else {
                        data['Signed Contract'] = <div className="flex flex-row gap-1">
                            <div className="grow"><span className="text-gray-500">Not Uploaded </span></div>
                            <Link onClick={() => setShowUploadSignedContractModal(true)}>Upload</Link>
                        </div>
                    }
                } else if (_.includes(["EXECUTED", "COMPLETE", "PENDING_PAYMENT", "AWAITING_MANUAL_ACTION"], res.status) && everyoneSigned) {
                    data['Signed Contract'] =
                        <Link onClick={() => downloadContract(res.title)}>Download signed contract</Link>
                }
                setDetails(data);
            }
            setLoadingContract(false);
        })
    }

    const getCounterSignatoriesSummary = (counter_signatories, signed_offline, canViewContractUpdates) => {
        return _.map(_.range(counter_signatories.length), (_, i) => (
            <div key={i} className={classnames("flex flex-row items-center gap-1", { "justify-end": canViewContractUpdates })}>
                {
                    counter_signatories[i].company_user.user.name
                }
                <span className="text-gray-500"> ({counter_signatories[i].company_user.user.email})</span>
                {!signed_offline && counter_signatories[i].signed &&
                    <BaseOverlayTrigger content={counter_signatories[i].signed_date ? `Signed on ${moment(counter_signatories[i].signed_date).format("DD MMM, YYYY h:mma")}` : "Signed"}>
                        <CheckCircleIcon className={"w-5 h-5 text-green-300"}/>
                    </BaseOverlayTrigger>}
                {!signed_offline && !counter_signatories[i].signed &&
                    <BaseOverlayTrigger content={"Pending signature"}>
                        <ClockIcon className={"w-5 h-5 text-orange-300"}/>
                    </BaseOverlayTrigger>}
            </div>
        ))
    }

    const getCustomerSignatoriesSummary = (signatories, signed_offline, canViewContractUpdates) => {
        return _.map(_.range(signatories.length), (_, i) => (
            <div key={i} className={classnames("flex flex-row items-center gap-1", { "justify-end": canViewContractUpdates })}>
                {
                    signatories[i].name
                }
                <span className="text-gray-500"> ({signatories[i].email})</span>
                {!signed_offline && signatories[i].signed &&
                    <BaseOverlayTrigger content={signatories[i].signed_date ? `Signed on ${moment(signatories[i].signed_date).format("DD MMM, YYYY h:mma")}` : "Signed"}>
                        <CheckCircleIcon className={"w-5 h-5 text-green-300"}/>
                    </BaseOverlayTrigger>}
                {!signed_offline && !signatories[i].signed &&
                    <BaseOverlayTrigger content={"Pending signature"}>
                        <ClockIcon className={"w-5 h-5 text-orange-300"}/>
                    </BaseOverlayTrigger>}
            </div>
        ))
    }

    const checkIfAnySignatoryHasSigned = (signatories, counter_signatories) => {
        for (let i = 0; i < signatories.length; i++) {
            if (signatories[i].signed) {
                return true
            }
        }
        if (counter_signatories) {
            for (let i = 0; i < counter_signatories.length; i++) {
                if (counter_signatories[i].signed) {
                    return true
                }
            }
        }
        return false
    }

    const checkIfEveryoneHasSigned = (signatories, counter_signatories) => {
        for (let i = 0; i < signatories.length; i++) {
            if (!signatories[i].signed) {
                return false
            }
        }
        if (counter_signatories) {
            for (let i = 0; i < counter_signatories.length; i++) {
                if (!counter_signatories[i].signed) {
                    return false
                }
            }
        }
        return true
    }
    
    const onFinalizeContractError = async (resJson) => {
        if (resJson.error_code === 'no_customer_address_with_tax_calc_enabled') {
            setShowCustomerAddressError(true)
        }
    }

    const saveContract = async (options) => {
        if (formCreateUpdateRef.current) {
            await formCreateUpdateRef.current.submitForm({ 
                finalize: options?.finalize ?? false,
                showNotification: options?.showNotification ?? true,
                onFinalizeContractError: options?.finalize ? onFinalizeContractError : () => {},
                showEmail: options?.showEmail,
            });
        }
    };

    const finalizeContract = async (options) => {
        isFinalizingRef.current = true;
        if (isEditingOrCreatingContract) {
            // new finalize
            await saveContract({ showNotification: true, finalize: true, onFinalizeContractError, showEmail: options?.showEmail });
            return;
        } 
        if (formRef.current) {
            // old finalize
            await formRef.current.submitForm()
        }
        if (activeNav === "content" || updateSuccessRef.current) {
            const result = await serverPatch(getApiUrl(`/proposals/${uuid}/finalize`), {})
            if (result) {
                setActiveNav("details");
                setIsEditingTemplate(false);
                fetchData(true);
            }
        }
    }

    const onNavClick = (itemId) => {
        setActiveNav(itemId);
    }

    const getStartDate = (contract) => {
        if (contract.start_type === "SPECIFIC_DATE") {
            return moment(contract.start_date).format("DD MMM, YYYY")
        } else if (contract.start_type === "ON_COMPLETION") {
            return contract.completed_date ? `${moment(contract.completed_date).format("DD MMM, YYYY h:mma")} (Completed Date)` : "When completed"
        } else if (contract.start_type === "MANUAL_ACTION") {
            return contract.start_date ? `${moment(contract.start_date).format("DD MMM, YYYY")} (Manually Executed Date)` : "When manually executed"
        }
    }

    const updateContractContentTemplate = async (data) => {
        await serverPatch(getApiUrl(`/proposals/${uuid}`), data).then((res) => {
            if (res) {
                Notification.Success("Successfully updated contract template");
                fetchData(true);
                setIsEditingTemplate(false);
            } else {
                Notification.Danger("Could not update contract template");
            }

        })
    }

    const downloadContract = (title) => {
        const filename = `${title || "Contract"}.pdf`;
        serverPost(getApiUrl(`/proposals/${uuid}/preview`), {}, {noJson: true}, downloadContractErrorHandler).then((res) => {
            if (res) {
                downloadBlob(res, filename);
            }
        })
    }

    const downloadContractErrorHandler = (res) => {
        Notification.Danger("Contract is being processed. This process might take a couple of minutes. Please try again later.", 5000)
    }

    const downloadUploadedContract = (file_id) => {
        serverFetch(getApiUrl(`/files/download/${file_id}`), {}).then((res) => {
            if (!res) {
                Notification.Danger("Unable to download signed contract");
            } else {
                const filename = `${res.filename}`;
                fetch(res.url, {headers: res.headers}).then((res2) => {
                    if (!res2.ok) {
                        Notification.Danger("Unable to download signed contract");
                    } else {
                        res2.blob().then(value => {
                            downloadBlob(value, filename)
                        })
                    }
                })
            }
        })
    }

    const tabItems = [{
        'label': 'Details',
        'id': 'details',
        active: activeNav === "details"
    }];
    if (_.includes(["PENDING_SIGNATURES", "DRAFT", "NEEDS_APPROVAL", "REJECTED"], contractDetails.status)) {
        if (!canViewContractUpdates()) {
            tabItems.push({
                'label': 'Preview',
                'id': 'content',
                active: activeNav === "content"
            });
        }
    }

    if (!canViewContractUpdates() && contractDetails.campaigns && contractDetails.campaigns.length > 0) {
        tabItems.push({
            'label': 'Reminders',
            'id': 'reminders',
            active: activeNav === "reminders"
        })
    }

    const getMostRecentCampaign = () => {
        if (!contractDetails.campaigns) {
            return null
        }
        const sortedCampaigns = contractDetails.campaigns.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        return sortedCampaigns[0]
    }

    const resizeHandler = () => {
        const isSmall = window.innerWidth < 768;
        setIsScreenSmall(isSmall);

        if (!isSmall) {
            setContractDetailsHeight(`${contractDetailsRef.current?.offsetHeight}px`);
        } else {
            setContractDetailsHeight("400px");
        }
    };

    useEffect(() => {
        resizeHandler();
        window.addEventListener("resize", resizeHandler);
        return () => window.removeEventListener("resize", resizeHandler);
    }, []);

    useEffect(() => {
        resizeHandler();
    }, [contractDetailsRef.current?.offsetHeight]);

    const downloadAttachment = (fileId) => {
        serverFetch(getApiUrl(`/files/download/${fileId}`), {}).then((res) => {
            if (res) {
                fetch(res.url, {headers: res.headers}).then((res2) => {
                    res2.blob().then(value => {
                        downloadBlob(value, res.filename)
                    })
                })
            } else {
                Notification.Danger("Could not download attachment");
            }
        })
    }

    const renderActivitySummaryContentBox = () => {
        return (
            <ContentBox
                {...{ style: !isScreenSmall ? { height: contractDetailsHeight } : { maxHeight: contractDetailsHeight }}}
                className="min-w-[275px] max-w-full grow-0 flex flex-col overflow-hidden w-full md:w-xl md:max-w-xl"
            >
                <ContentBox.Title>
                    Activity Summary
                </ContentBox.Title>
                <ContentBox.Body className="flex flex-col h-full overflow-auto">
                    {contractDetails.signed_offline && 
                        <Alert variant="success">
                            <span className="text-sm font-medium">This contract has been signed offline.</span>
                        </Alert>
                    }
                    <div className={classnames(
                        "flex flex-col gap-2 relative h-full overflow-auto",
                        { "opacity-50": contractDetails.signed_offline}
                    )}>
                        {Array.isArray(signatoryStatuses?.items) && signatoryStatuses.items.map((signatoryStatus) =>
                            <SignatoryStatus key={signatoryStatus.title} {...signatoryStatus} />
                        )}
                    </div>
                </ContentBox.Body>
            </ContentBox>
        );
    };
    
    const renderFinalizedDetails = () => {
        return (
            <>
                <Section className="mt-3 w-full" actions={[]}>
                    <div className="flex md:gap-4 flex-col md:flex-row items-start">
                        <KeyValueDisplay
                            ref={contractDetailsRef}
                            {...(canViewContractUpdates() ? { title: "Contract Details" } : {})}
                            items={details}
                            {...(isMapleUser ? { contentBoxClassName: "max-w-full", className: "max-w-full" } : "")}
                        />
                        {isMapleUser && renderActivitySummaryContentBox()}
                    </div>
                </Section>
                {
                    !_.isEmpty(customVariableDetails) &&
                    (!canViewContractUpdates() ? (<Section title="Custom Variables" className="mt-3 max-w-2xl" actions={[]}>
                        <KeyValueDisplay items={customVariableDetails}/>
                    </Section>) : (
                        <div className="flex md:gap-4 flex-col md:flex-row items-start">
                            <KeyValueDisplay title="Custom Variables" items={customVariableDetails} {...(isMapleUser ? { contentBoxClassName: "max-w-full", className: "max-w-full" } : "")} />
                            {isMapleUser && <div className="min-w-[275px] max-w-full grow-0 flex flex-col overflow-hidden w-full md:w-xl md:max-w-xl"></div>}
                        </div>
                    ))
                }
                {canViewContractUpdates() ? (<ContentBox>
                    <ContentBox.Title>Plan Details</ContentBox.Title>
                    <ContentBox.Body className="pb-0 pt-2">
                        <BundlePricingTable
                            bundlePricing={contractDetails.bundle_pricing}
                            oneTimeBillables={contractDetails.one_time_billables}
                            configItems={contractDetails.config_items}
                            discounts={contractDetails.discounts}
                            showPricingLinks={true}
                            term={contractDetails.term}
                            trialTerm={contractDetails.trial ? contractDetails.trial_term : undefined}
                            minimumSpend={contractDetails.minimum_spend}
                            displayDiscountExpiration={true}
                            scheduledChanges={contractDetails.scheduled_changes}
                            showPlanTitle
                        />
                    </ContentBox.Body>
                </ContentBox>) : (<Section title="Plan Details">
                    <div className="body1">Details about the plan and pricing.</div>
                    <div className="body1"><strong>Start
                        Date:</strong> {contractDetails.start_date ? moment(contractDetails.start_date).format("DD MMM, YYYY") : "When executed"}
                    </div>
                    <BundlePricingTable
                        bundlePricing={contractDetails.bundle_pricing}
                        oneTimeBillables={contractDetails.one_time_billables}
                        configItems={contractDetails.config_items}
                        discounts={contractDetails.discounts}
                        showPricingLinks={true}
                        term={contractDetails.term}
                        trialTerm={contractDetails.trial ? contractDetails.trial_term : undefined}
                        minimumSpend={contractDetails.minimum_spend}
                        displayDiscountExpiration={true}
                        scheduledChanges={contractDetails.scheduled_changes}
                    />
                </Section>)}
                {!_.isEmpty(contractDetails?.contract_attachments) &&
                    <ContentBox>
                        <ContentBox.Title>Documents</ContentBox.Title>
                        <ContentBox.Body>
                            <MapleTable>
                                <MapleTable.Content removeMargin removePadding>
                                    <thead>
                                        <tr>
                                            <MapleTable.TH>Document</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {contractDetails &&
                                        _.map(contractDetails.contract_attachments, (ufile, i) =>
                                            <tr key={i}>
                                                <td>{ ufile.original_filename }</td>
                                                <td className="text-end">
                                                    <div className="flex flex-row gap-2 justify-end items-center">
                                                        <ArrowDownTrayIcon className="cursor-pointer h-4 w-4" onClick={() => downloadAttachment(ufile.file_id)}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        </ContentBox.Body>
                    </ContentBox>
                }
            </>
        )
    }

    const renderDeclinedDetails = () => {
        return (
            <>
                {!canViewContractUpdates()
                    ? (
                        <Section title="Details" actions={[]}>
                            <div className="d-flex flex-row flex-wrap gap-3">
                                <KeyValueDisplay items={details} className="half-flex"/>
                                <div className="half-flex align-self-center text-center d-flex flex-column gap-3"
                                    style={{paddingTop: "50px", paddingBottom: "50px"}}>

                                </div>
                            </div>
                        </Section>
                    ) : (
                        <Section actions={[]} className="mt-3 max-w-5xl">
                            <div className="flex gap-2 flex-col md:flex-row md:gap-3 items-start">
                                <KeyValueDisplay ref={contractDetailsRef} title="Contract details" items={details} className="half-flex"/>
                                {isMapleUser && renderActivitySummaryContentBox()}
                            </div>
                        </Section>
                    )}
                {!canViewContractUpdates()
                    ? (<Section title="Plan Details">
                        <div className="body1">Details about the plan and pricing.</div>
                        <div className="body1"><strong>Start
                            Date:</strong> {moment(contractDetails.start_date).format("DD MMM, YYYY")}</div>
                        <br/>
                        <BundlePricingTable
                            bundlePricing={contractDetails.bundle_pricing}
                            oneTimeBillables={contractDetails.one_time_billables}
                            configItems={contractDetails.config_items}
                            discounts={contractDetails.discounts}
                            showPricingLinks={true}
                            term={contractDetails.term}
                            trialTerm={contractDetails.trial ? contractDetails.trial_term : undefined}
                            minimumSpend={contractDetails.minimum_spend}
                            displayDiscountExpiration={true}
                        />
                    </Section>) : (
                        <ContentBox>
                            <ContentBox.Title>
                                Plan Details
                            </ContentBox.Title>
                            <ContentBox.Body className="py-0" contentBoxHasTitle>
                                <BundlePricingTable
                                    bundlePricing={contractDetails.bundle_pricing}
                                    oneTimeBillables={contractDetails.one_time_billables}
                                    configItems={contractDetails.config_items}
                                    discounts={contractDetails.discounts}
                                    showPricingLinks={true}
                                    term={contractDetails.term}
                                    trialTerm={contractDetails.trial ? contractDetails.trial_term : undefined}
                                    minimumSpend={contractDetails.minimum_spend}
                                    displayDiscountExpiration={true}
                                    showPlanTitle
                                />
                            </ContentBox.Body>
                        </ContentBox>
                    )}
            </>
        )
    }

    const stopCampaign = (campaignId) => {
        serverPost(getApiUrl(`/campaigns/${campaignId}/stop`), {}).then(res => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const renderCampaignsTable = () => {
        return (
            <MapleTable>
                <MapleTable.Content removePadding removeMargin>
                    <thead>
                        <tr>
                            <MapleTable.TH>Reminder #</MapleTable.TH>
                            <MapleTable.TH>Scheduled date & time</MapleTable.TH>
                            <MapleTable.TH>Completed date & time</MapleTable.TH>
                            <MapleTable.TH>Action</MapleTable.TH>
                            <MapleTable.TH>Status</MapleTable.TH>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {Array.isArray(mostRecentCampaign.steps) && 
                        mostRecentCampaign.steps
                            .sort((stepA, stepB) => stepA.step_number - stepB.step_number)
                            .map((step, i) =>
                                <MapleTable.TR key={i} className="text-dark-gray">
                                    <td className="text-subtitle">{step.step_number + 1}</td>
                                    <td className="font-medium">{moment(step.enqueued_at).format("MMMM D, YYYY - h:mm A")}</td>
                                    <td className="font-medium">{step.completed_at !== "0001-01-01T00:00:00Z" ? moment(step.completed_at).format("MMMM D, YYYY - h:mm A") : ""}</td>
                                    <td>{t(getConfigActionTypeKey(step.action_type))}</td>
                                    <td>{getStatusForStep(step)}</td>
                                </MapleTable.TR>
                            )
                    }
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
        );
    };

    const renderCampaignInformation = () => {
        return (
            <Section title={`Campaign Summary`} className="mt-3"
                     right={
                         <div className="grow flex flex-row gap-3 items-center">
                             <div className="grow">
                                 {renderCampaignStatusLabel(mostRecentCampaign)}
                             </div>
                             <div className="grow-0 shrink-0 flex flex-row gap-3">
                                 {mostRecentCampaign.status === "ACTIVE" && (
                                    <DeleteButton 
                                        onDelete={() => stopCampaign(mostRecentCampaign.id)}
                                        title={"Confirm Stop Reminders"}
                                        body={"Are you sure you want to stop reminders? Any future steps will not be executed."}
                                    >
                                        Stop Reminders
                                    </DeleteButton>
                                 )} 
                                 {canTriggerCampaign && (
                                    <Button variant="text-primary" onClick={() => onActionSelected('trigger_campaign')}>
                                        Start Reminders
                                    </Button>
                                 )}
                             </div>
                         </div>}>
                {renderCampaignsTable()}
            </Section>
        )
    }

    const renderContractStatus = () => {
        if (contractDetails.status === "PENDING_SIGNATURES") {
            return <span>The contract is pending signatures.</span>
        } else if (contractDetails.status === "NEEDS_APPROVAL") {
            return <span>The contract is waiting to be approved.</span>
        } else if (contractDetails.status === "PENDING_PAYMENT") {
            return <span>The contract is signed, waiting for payment input.</span>
        } else if (contractDetails.status === "EXECUTED") {
            return <span>The contract is fully executed.</span>
        } else if (contractDetails.status === "DRAFT") {
            return <span>The contract is in draft. Please review and finalize before sending it out.</span>
        } else if (contractDetails.status === "REJECTED") {
            return <span>The contract is rejected by the company approver(s). Please revise and request approval again.</span>
        } else if (contractDetails.status === "COMPLETE") {
            return <span>The contract is signed and payment details have been entered. It is awaiting execution.</span>
        } else if (contractDetails.status === "AWAITING_MANUAL_ACTION") {
            return <span>The contract is pending manual confirmation to mark it as executed.</span>
        }
    }

    const revertContract = async (startEditingTemplate) => {
        const result = await serverPost(getApiUrl(`/proposals/${uuid}/revert`), {})
        if (result) {
            await fetchData(true);
            if (startEditingTemplate) {
                setIsEditingTemplate(true);
            }
        }
    }

    const revertContractV2 = async (startEditingTemplate) => {
        const result = await serverPost(getApiUrl(`/proposals/${uuid}/revert`), {});
        if (result) {
            await fetchData(true);
            if (startEditingTemplate) {
                setIsEditingTemplate(true);
            }
        }
    }

    const downloadPDFAction = () => {
        if (!contractDetails.signed_offline) {
            downloadContract(contractDetails.title)
        } else {
            downloadUploadedContract(contractDetails.signed_file_id)
        }
    }

    const onActionSelected = (type) => {
        if (type === "pdf") {
            downloadPDFAction();
        } else if (type === "email") {
            setShowEmailModal(true);
        } else if (type === "link") {
            setShowLinkModal(true);
        } else if (type === "edit") {
            setShowRevertConfirmationModal(true);
        } else if (type === "references") {
            setShowReferenceSummaryModal(true);
        } else if (type === "trigger_campaign") {
            const data = {
                type: "proposal_sign_reminder",
                proposal_id: contractDetails.id,
                customer_id: contractDetails.customer_id
            }
            serverPost(getApiUrl("/campaigns/trigger"), data).then((res) => {
                if (res) {
                    Notification.Success("Successfully started reminder schedule")
                    fetchData(true)
                } else {
                    Notification.Danger("Could not start reminder schedule")
                }
            })
        } else if (type === "manual_execute") {
            setShowManualExecuteModal(true);
        } else if (type === "sign_offline") {
            setShowSignOfflineModal(true)
        } else if (type === "preview") {
            setIsContractPreviewVisible(true);
        } else if (type === "change_template") {
            setShowChangeTemplateModal(true);
        } else if (type === "change_request_payment") {
            setShowChangeRequestPaymentModal(true);
        } else if (type === "import_from_crm") {
            setShowSearchDealsModal(true);
        }
    }

    const signOffline = (data, errorHandler) => {
        const postData = {}
        if (data && data.file_id) {
            postData.file_id = data.file_id;
        }
        serverPost(getApiUrl(`/proposals/${uuid}/sign_offline`), postData).then((res) => {
            if (res) {
                Notification.Success("Successfully marked contract as signed offline")
                fetchData(true)
            } else {
                Notification.Danger("Could not mark contract as signed offline")
            }
        })
        setShowSignOfflineModal(false)
    }

    const uploadContract = (data, errorHandler) => {
        const postData = {
            file_id: data.file_id
        }
        serverPost(getApiUrl(`/proposals/${uuid}/sign_offline`), postData).then((res) => {
            if (res) {
                Notification.Success("Successfully uploaded signed contract")
                fetchData(true)
            } else {
                Notification.Danger("Could not upload signed contract")
            }
        })
        setShowUploadSignedContractModal(false)
    }

    const actionOptions = _.compact([
        canEditContract(contractDetails.status) && {
            id: "edit",
            label: "Edit"
        },
        contractDetails.status === "DRAFT" && {id: "change_template", label: "Change Template"},
        !isCreatingContract && isSuperUser && {id: "references", label: "References"},
        canTriggerCampaign && {id: "trigger_campaign", label: "Start Reminder Schedule"},
        contractDetails.status === "AWAITING_MANUAL_ACTION" && {id: "manual_execute", label: "Mark as Executed"},
        contractDetails.status === "PENDING_PAYMENT" && contractDetails.require_payment_method && {id: "change_request_payment", label: "Skip Payment Requirement"},
        !contractDetails.signed_offline && _.includes(["PENDING_SIGNATURES", "PENDING_PAYMENT", "PENDING_COUNTER_SIGNATURES"], contractDetails.status) && !hasEveryoneSigned && {id: "sign_offline", label: "Mark as Signed Offline"}
    ]);
    const updatedActionOptions = actionOptions.filter(option => !['edit'].includes(option.id));
    const updatedActionOptionsMobile = [...actionOptions];
    if (canPreviewContract(contractDetails.status)) {
        updatedActionOptionsMobile.unshift({ id: 'preview', label: 'Preview'})
    }

    const isFinalizedOrLater = contractDetails.status !== "DRAFT" && contractDetails.status !== "EXPIRED";
    const isAwaitingManualActionOrLater = _.includes(["COMPLETE", "EXECUTED", "AWAITING_MANUAL_ACTION"], contractDetails.status);
    const showDownloadPDF = () => {
        let isFileAvailable = true
        if (contractDetails.signed_offline) {
            isFileAvailable = contractDetails.signed_file_id && contractDetails.signed_file_id.length > 0
        }
        return isFinalizedOrLater && isFileAvailable
    }
    const shareOptions = _.compact([
        isFinalizedOrLater && !isAwaitingManualActionOrLater && {id: "link", label: "Share via Link"},
        isFinalizedOrLater && !isAwaitingManualActionOrLater && {id: "email", label: "Share via Email"},
        showDownloadPDF() && {id: "pdf", label: "Download PDF"},
    ]);

    useEffect(() => {
        const showActionBarScrollHandler = () => {
            if (!actionBarRef.current) return;

            // if there's a value, we check the bottom of it if it is sitll in view
            const rect = actionBarRef.current.getBoundingClientRect();
            setShowStickyHeader(rect.bottom < -50);
        };
        const scrollContainer = document.getElementById("content-wrapper");
        scrollContainer.addEventListener("scroll", showActionBarScrollHandler);

        return () => {
            scrollContainer.removeEventListener("scroll", showActionBarScrollHandler);
        };
    }, []);

    useEffect(() => {
        if (location.pathname === getCompanySpecificUrl(`/contract/view/${uuid}`)) {
            setShowStickyHeader(false);
        }
    }, [location.pathname]);

    const ActionBar = memo(({ id, extraActions }) => {
        const [dropdownOptionsLoading, setDropdownOptionsLoading] = useState(false);
        const ActionBarContainer = ({ children: containerChildren }) => (
            <div id={`${id ? id + "-" : ""}contract-action-bar`} className="flex justify-between leading-8">
                <div className="flex gap-[12px]">
                    {Array.isArray(extraActions) && extraActions.map((button) => (
                        <Button className="hidden xl:block" variant={button.variant} onClick={button.onClick}>
                            <span className="flex items-center gap-1">
                                {button.icon}
                                {button.label}
                            </span>
                        </Button>
                    ))}
                    {containerChildren}
                </div>
            </div>
        );

        if (isEditingOrCreatingContract) {
            const createContractActions = actionOptions.filter(option => !['edit'].includes(option.id));
            const createContractActionsMobile = actionOptions.filter(option => !['edit'].includes(option.id));

            if (Array.isArray(extraActions)) {
                extraActions.forEach(action => {
                    createContractActionsMobile.unshift({
                        id: action.id,
                        label: action.label,
                    });
                });
            }
            if (canPreviewContract(contractDetails.status)) {
                createContractActionsMobile.unshift({ id: 'preview', label: 'Preview'});
            }
            return (
                <ActionBarContainer>
                    {isContractDraft(contractDetails) && (
                        <Button variant="text-outline" className="hidden xl:block" onClick={() => {
                            setIsContractPreviewVisible(true);
                        }}> 
                            <span className="flex items-center gap-1">
                                <Eye className="inline" width={20} height={20} />
                                Preview
                            </span>
                        </Button>
                    )}
                    <Button variant={!isCreatingContract ? "text-outline" : "primary"} onClick={() => saveContract()}>
                        <span className="flex items-center gap-1">
                            <File05 className="inline" width={20} height={20} />
                            Save draft
                        </span>
                    </Button>
                    {isContractDraft(contractDetails) && (
                        <Button 
                            variant="primary" 
                            onClick={async () => await finalizeContract({ showNotification: true })}
                            dropdownOptions={[
                                {
                                    id: 'finalize_and_send',
                                    label: 'Finalize and Send'
                                }
                            ]}
                            dropdownOptionsSelected={async (type) => {
                                if (type === 'finalize_and_send') {
                                    setDropdownOptionsLoading(true);
                                    await finalizeContract({ showEmail: true });
                                    setDropdownOptionsLoading(false);
                                }
                            }}
                            isDropdownOptionsLoading={dropdownOptionsLoading}
                        >
                            <span className="flex items-center gap-1">
                                <Check className="inline" width={20} height={20} />
                                Finalize
                            </span>
                        </Button>
                    )}
                    {!_.isEmpty(createContractActions) && (
                        <DropdownMenu className="hidden xl:block" items={createContractActions} onClick={onActionSelected} hideCaret>
                            <Button variant="text-outline" className="flex items-center" hasIconOnly>
                                <DotsVertical className="inline" width={20} height={20} />
                            </Button>
                        </DropdownMenu>
                    )}
                    {!_.isEmpty(createContractActionsMobile) && (
                        <DropdownMenu className="block xl:hidden" items={createContractActionsMobile} onClick={onActionSelected} hideCaret>
                            <Button variant="text-outline" className="flex items-center" hasIconOnly>
                                <DotsVertical className="inline" width={20} height={20} />
                            </Button>
                        </DropdownMenu>
                    )}
                </ActionBarContainer>
            );
        }

        if (!canViewContractUpdates()) return null;

        if (!isContractDraft(contractDetails)) { 
            return(
                <ActionBarContainer>
                    {canPreviewContract(contractDetails.status) && 
                        <Button variant="text-outline hidden md:block" onClick={() => {
                            setIsContractPreviewVisible(true);
                        }}> 
                            <span className="flex items-center gap-1">
                                <Eye className="inline" width={20} height={20} />
                                Preview
                            </span>
                        </Button>
                    }
                    {canEditContract(contractDetails.status) && 
                        <Button variant="text-outline hidden md:block" onClick={() => onActionSelected('edit')}>
                            <span className="flex items-center gap-1">
                                <Edit02 className="inline" width={20} height={20} />
                                Edit
                            </span>
                        </Button>
                    }
                    {!_.isEmpty(shareOptions) && (
                        <DropdownMenu items={shareOptions} onClick={onActionSelected} hideCaret>
                            <Button>
                                <span className="flex items-center gap-1">
                                    Share
                                    <ChevronDown className="inline" width={20} height={20} />
                                </span>
                            </Button>
                        </DropdownMenu>
                    )}
                    {!_.isEmpty(updatedActionOptions) && (
                        <>
                            <DropdownMenu className="hidden md:block" items={updatedActionOptions} onClick={onActionSelected} hideCaret>
                                <Button variant="text-outline" className="flex items-center" hasIconOnly>
                                    <DotsVertical className="inline" width={20} height={20} />
                                </Button>
                            </DropdownMenu>
                            <DropdownMenu className="block md:hidden" items={updatedActionOptionsMobile} onClick={onActionSelected} hideCaret>
                                <Button variant="text-outline" className="flex items-center" hasIconOnly>
                                    <DotsVertical className="inline" width={20} height={20} />
                                </Button>
                            </DropdownMenu>
                        </>
                    )}
                </ActionBarContainer>
            );
        }

        return null;
    });

    return (
        <BaseContainer 
            rightWrapperClassName="relative"
            header={
                <BaseContainer.CustomHeader show={showStickyHeader}>
                    <div className="flex justify-between items-center gap-2">
                        <div className="text-dark-gray text-xl font-semibold">{!isCreatingContract ? contractDetails.title : "New Contract"}</div>
                        <ActionBar id="header-action-bar"/>
                    </div>
                </BaseContainer.CustomHeader>
            }
        >
            {!isEditingOrCreatingContract ? (<Loader loading={loadingContract}>
                {
                    _.isEmpty(contractDetails) ?
                        <ErrorComponent
                            title={"Contract Not Found"}
                            description={"Oops, we can't find the contract you are looking for."}
                            primaryAction={{href: getCompanySpecificUrl("/contracts"), label: "Show all contracts"}}
                            hideSupport
                        />
                        : <>
                            <ContentContainer>
                                {canViewContractUpdates() ? (
                                    <>
                                        <Section
                                            variant="page"
                                            title={<div>{contractDetails.title}<div className="text-nowrap block non-mobile:hidden">{renderContractStatusLabel(contractDetails)}</div></div>}
                                            right={<div ref={actionBarRef}><ActionBar /></div>}
                                            titleRight={<div className="text-nowrap hidden non-mobile:block">{renderContractStatusLabel(contractDetails)}</div>}
                                            className="mb-[20px]"
                                        />
                                    </>
                                ) : (<Section
                                    variant="page" title={contractDetails.title} subtitle={renderContractStatus()}
                                    right={
                                        <div className="grow flex flex-row gap-3 items-center">
                                            <div className="grow">
                                                {renderContractStatusLabel(contractDetails)}
                                            </div>
                                            <div className="grow-0 shrink-0 flex flex-row gap-3">
                                                {
                                                    (!canViewContractUpdates()) && !_.isEmpty(actionOptions) &&
                                                    <DropdownMenu className="py-2 px-1" items={actionOptions}
                                                                  onClick={onActionSelected}>
                                                        <div className="flex flex-row gap-1 items-center">
                                                            <WrenchScrewdriverIcon className="h-4 w-4"/>
                                                            <span>Actions</span>
                                                        </div>
                                                    </DropdownMenu>
                                                }
                                                {
                                                    contractDetails.status === "DRAFT" &&
                                                    <Button variant="primary" onClick={finalizeContract}>Finalize</Button>
                                                }
                                                {
                                                    (!canViewContractUpdates()) && !_.isEmpty(shareOptions) &&
                                                    <DropdownMenu className="py-2 px-1" items={shareOptions}
                                                                  onClick={onActionSelected}>
                                                        <div className="flex flex-row gap-1 items-center">
                                                            <ShareIcon className="h-4 w-4"/>
                                                            <span>Share</span>
                                                        </div>
                                                    </DropdownMenu>
                                                }
                                            </div>
                                        </div>
                                    }
                                />)}
                                <ApprovalRequestListSection approvalRequests={approvalRequests}
                                                            onUpdate={async () => await fetchData(true)}/>
                                {
                                    contractDetails.previous_subscription &&
                                        <RBAlert variant="warning my-2">
                                            <div className="body2">Changing existing subscription</div>
                                            <div className="body1">This is a renewal contract for an existing <Link href={getCompanySpecificUrl(`/subscription/${contractDetails.previous_subscription.id}`)}>subscription</Link>.</div>
                                        </RBAlert>
                                }
                                {
                                    tabItems.length > 1 &&
                                    <SectionNav items={tabItems} onClick={onNavClick}/>
                                }
                                {
                                    activeNav === "details" &&
                                    <Loader loading={loadingContract}>
                                        {() => {
                                            if (contractDetails.status === "DECLINED") {
                                                return renderDeclinedDetails();
                                            } else {
                                                return renderFinalizedDetails();
                                            }
                                        }}
                                    </Loader>
                                }
                                {
                                    activeNav === "reminders" &&
                                    renderCampaignInformation()
                                }
                                {canViewContractUpdates() && isCampaignsEnabled(settings) && contractDetails.campaigns && contractDetails.campaigns.length > 0 && mostRecentCampaign && (
                                    <ContentBox id="reminders-table">
                                        <ContentBox.Title
                                            right={
                                                <div>
                                                    {mostRecentCampaign.status === "ACTIVE" && (
                                                        <DeleteButton 
                                                            onDelete={() => stopCampaign(mostRecentCampaign.id)}
                                                            title={"Confirm Stop Reminders"}
                                                            body={"Are you sure you want to stop reminders? Any future steps will not be executed."}
                                                        >
                                                            Stop Reminders
                                                        </DeleteButton>
                                                    )} 
                                                    {canTriggerCampaign && (
                                                        <Button variant="text-primary" onClick={() => onActionSelected('trigger_campaign')}>
                                                            Start Reminders
                                                        </Button>
                                                    )}
                                                </div>
                                            }
                                        >
                                            <span className="flex items-center">Reminders&nbsp;{renderCampaignStatusLabel(mostRecentCampaign)}</span>
                                        </ContentBox.Title>
                                        <ContentBox.Body contentBoxHasTitle>
                                            {renderCampaignsTable()}
                                        </ContentBox.Body>
                                    </ContentBox>
                                )}
                            </ContentContainer>
                            <SingleContractEmailModal show={showEmailModal} onClose={setShowEmailModal}
                                                      contract={contractDetails} {...(isEditingOrCreatingContract ? { updated: true } : {})} />
                            <ShareContractLinkModal show={showLinkModal} onClose={setShowLinkModal}
                                                    contract={contractDetails} hasEveryoneSigned={hasEveryoneSigned}/>
                            <SimpleModal
                                title={"Confirm Revert and Edit"}
                                body={
                                    <div>
                                        <span>In order to make edit to a finalized contract, we would have to move it back to <strong>Draft</strong>.</span><br/><br/>
                                        {!contractDetails.isExpired && hasAnyoneSigned && <div>
                                            <span>Are you sure you want to proceed?</span><br/><br/>
                                            <span>Some signatories have already signed. Their signatures will be invalidated, and they will be requested to sign the document again.</span>
                                        </div>}
                                        {!contractDetails.isExpired && !hasAnyoneSigned && <span>Are you sure you want to proceed? Any existing links to the contract will not work until the contract is finalized again.</span>}
                                    </div>
                                }
                                confirmationText={(hasAnyoneSigned && !contractDetails.isExpired) ? "CONFIRM REVERT" : null}
                                buttonVariant={"danger"}
                                buttonTitle={"Revert and Edit"}
                                show={showRevertConfirmationModal}
                                onClose={setShowRevertConfirmationModal} onConfirm={async () => await revertContract(false)}
                            />
                            <ManualExecutionModal
                                proposal_id={uuid}
                                show={showManualExecuteModal}
                                onClose={setShowManualExecuteModal}
                                onExecute={fetchData}
                            />
                            <SignOfflineModal
                                show={showSignOfflineModal}
                                proposal_id={uuid}
                                onClose={setShowSignOfflineModal}
                                onSubmit={signOffline}
                                required={false}
                                hasAnyoneSigned={hasAnyoneSigned}
                            />
                            <SignOfflineModal
                                show={showUploadSignedContractModal}
                                proposal_id={uuid}
                                onClose={setShowUploadSignedContractModal}
                                onSubmit={uploadContract}
                                required={true}
                                title={"Upload Signed Contract"}
                                submitLabel={"Upload"}
                            />
                            <AddOrEditProductPricingModal2
                                show={showAddProductPricingModal}
                                onProductPricingCreated={onProductPricingCreated}
                                onClose={onModalClose}
                            />
                        </>
                }
            </Loader>) : (
                <ContractCreateUpdate
                    ref={formCreateUpdateRef}
                    renderActionBar={(extraActions) => <div ref={actionBarRef}><ActionBar extraActions={extraActions} /></div>}
                    contractDetails={!_.isEmpty(contractDetails) ? contractDetails : null}
                    initialContractData={initialContractData}
                    fetchData={fetchData}
                    showSearchDealsModal={showSearchDealsModal}
                    setShowSearchDealsModal={setShowSearchDealsModal}
                    showCustomerAddressError={showCustomerAddressError}
                    setShowCustomerAddressError={setShowCustomerAddressError}
                    showEmailModal={() => setShowEmailModal(true)}
                />
            )}
            <IntegrationReferenceEntitySummaryModal
                show={showReferenceSummaryModal} onClose={setShowReferenceSummaryModal}
                entityKey={"CONTRACT"}
                referenceID={contractDetails.id}
            />
            <ContractPreviewModal 
                show={isContractPreviewVisible}
                onClose={() => setIsContractPreviewVisible(false)}
                hasAnyoneSigned={hasAnyoneSigned}
                contractDetails={contractDetails}
                templateFields={templateFields}
                isEditingTemplate={isEditingTemplate}
                setIsEditingTemplate={setIsEditingTemplate}
                onClickMoveToDraftAndEdit={revertContractV2}
                onClickUpdate={updateContractContentTemplate}
                updated={isEditingOrCreatingContract}
            />
            <ContractChangeTemplateModal
                proposal_id={uuid}
                show={showChangeTemplateModal}
                onClose={setShowChangeTemplateModal}
                onExecute={fetchData}
            />
            <ChangeContractRequestPaymentModal
                show={showChangeRequestPaymentModal}
                size={'sm'}
                onClose={() => setShowChangeRequestPaymentModal(false)}
                onExecute={fetchData}
                proposalID={uuid}
            />
        </BaseContainer>
    );
}

export default Contract;
