import '../App.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const BaseOverlayTrigger = (props) => {
	return (
		<OverlayTrigger
			trigger={props.content ? ['hover', 'focus'] : []}
			placement={props.placement || 'top'}
            delay={{ show: props.delayShow || 0, hide: props.delayHide || 0}}
			overlay={<Tooltip>{props.content}</Tooltip>}
			onToggle={props.onToggle}
			popperConfig={props.popperConfig}
		>
			<span>{props.children}</span>
		</OverlayTrigger>
	);
};

export default BaseOverlayTrigger;
