import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Link from '../../components/Link';
import { serverFetch } from '../../helpers/server';
import {
    BaseContext,
    currencyFormatFromPrice,
    getCustomerNameOrEmail,
    getNameForProviderType, renderPaymentMethod, UserContext,
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay from '../../components/KeyValueDisplay2';
import ContentContainer from '../../components/ContentContainer';
import Label from '../../components/Label';
import Loader from '../../components/Loader';
import RefundPaymentModal from '../../components/modals/RefundPaymentModal';
import moment from 'moment';
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import DropdownMenu from "../../components/DropdownMenu";
import ErrorComponent from "../../components/ErrorComponent";
import {renderTypeLabel} from "../../helpers/payments";
import IntegrationReferenceEntitySummaryModal from "../../components/modals/IntegrationReferenceEntitySummary";
import {getExternalPaymentReferenceURL} from "../../helpers/integrations";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
import CopyableComponent from "../../components/CopyableComponent";
import Button from '../../components/common/buttons/Button';
import DotsVertical from '@untitled-ui/icons-react/build/esm/DotsVertical';
const _ = require('lodash');

function Payment(props) {
    const { t } = useTranslation('common');
    const { isMapleUser } = useContext(UserContext);
    const { paymentId } = useParams();
    const { getApiUrl, getCompanySpecificUrl, company, setPageTitle } = useContext(BaseContext);
    const [showRefundPaymentModal, setShowRefundPaymentModal] = useState(false);
    const [showReferenceSummaryModal, setShowReferenceSummaryModal] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(true);
    const [payment, setPayment] = useState({});
    const [details, setDetails] = useState({});

    useEffect(() => {
        setPageTitle(`Payment`);
    }, [payment]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/payments/transactions/${paymentId}`), { skipCache }).then((res) => {
            if (res) {
                setPayment(res);
            }
            setLoadingPayment(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(payment)) {
            return;
        }
        const data = {
            'Customer': <Link to={getCompanySpecificUrl(`/customer/${payment.customer.id}`)}>{getCustomerNameOrEmail(payment.customer)}</Link>,
            'ID': <CopyableComponent value={payment.id}></CopyableComponent>,
            'Provider': <div className="flex flex-row gap-2 justify-end">
                <span>{ getNameForProviderType(payment.provider_type) }</span>
            </div>,
            [getNameForProviderType(payment.provider_type) + ' Transaction ID']: <Link target={"_blank"} isExternalLink href={getExternalPaymentReferenceURL(payment)}>{payment.provider_transaction_id}</Link>,
            'Type': renderTypeLabel(payment),
            'Status': renderPaymentStatus(),
            ...(payment.status_message ? {
                'Failure Reason': <div className="flex flex-col items-end">
                    <span>{ payment.status_message }</span>
                    <Link target={"_blank"} isExternalLink href={getExternalPaymentReferenceURL(payment)}>
                        <div className="flex flex-row gap-1 items-center">
                            <span>View more details in { getNameForProviderType(payment.provider_type) }</span><ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4"/>
                        </div>
                    </Link>
                </div>
            } : {}),
            'Date': moment(payment.created_at).format("DD MMM, YYYY h:mm:ssa"),
            'Invoice': <Link to={getCompanySpecificUrl(`/invoice/${payment.invoice_uuid}`)}>{ payment.invoice_number }</Link>,
            'Total Amount': currencyFormatFromPrice(payment.total_amount),
        };
        if (payment.status === "SUCCESS") {
            data['Fees'] = currencyFormatFromPrice(payment.stripe_fee_amount)
            data['Net Amount'] = currencyFormatFromPrice(payment.net_amount)
        }
        if (payment.connected_account_transaction) {
            data['Application Fees'] = currencyFormatFromPrice(payment.application_fee_amount)
        }
        data['Is Connected Account Transaction'] = payment.connected_account_transaction ? "Yes": "No"
        if (payment.payment_method) {
            data['Payment Method'] = <div className="[&_div]:justify-end">{renderPaymentMethod(t, payment.payment_method)}</div>;
        }
        data['Note'] = payment.note
        setDetails(data);
    }, [payment]);

    const onModalClose = (didUpdate=false) => {
        setShowRefundPaymentModal(false);
        setShowReferenceSummaryModal(false);
        fetchData(didUpdate);
    }

    const onActionSelected = (type) => {
        if (type === "refund") {
            setShowRefundPaymentModal(true);
        } else if (type === "references") {
            setShowReferenceSummaryModal(true);
        }
    }

    const canRefund = _.includes(["SUCCESS", "PARTIAL_REFUNDED"], payment.status) && payment.is_refundable;
    const actionOptions = [
        ...(canRefund ? [{ id: "refund", label: "Refund" }] : []),
        ...(isMapleUser ? [{ id: "references", label: "References" }] : []),
    ];

    const renderActions = () => {
        if (_.isEmpty(actionOptions)) {
            return null;
        }
        return (
            <DropdownMenu className={!isMapleUser ? "p-2" : ""} items={actionOptions} onClick={onActionSelected} {...(isMapleUser ? { hideCaret: true } : {})}>
                {!isMapleUser ? (
                    <div className="flex flex-row gap-1 items-center">
                        <WrenchScrewdriverIcon className="h-4 w-4"/>
                        <span>Actions</span>
                    </div>
                ) : (
                    <Button variant="text-outline" hasIconOnly>
                        <DotsVertical width={20} height={20} />
                    </Button>
                )}
            </DropdownMenu>
        )
    }

    const renderPaymentStatus = () => {
        if (payment.status === "SUCCESS") {
            return <Label.Success>Succeeded</Label.Success>
        } else if (payment.status === "PROCESSING") {
            return <Label.Warning>Processing</Label.Warning>
        } else if (_.includes(["PARTIAL_REFUNDED", "REFUNDED", "CANCELLED", "PROCESSING"], payment.status)) {
            const mmap = {
                "PARTIAL_REFUNDED": "Partially Refunded",
                "REFUNDED": "Refunded",
                "CANCELLED": "Cancelled",
                "PROCESSING": "Processing"
            }
            return <Label.Info>{ mmap[payment.status] }</Label.Info>
        } else if (payment.status === "FAILED") {
            return <Label.Danger>Failed</Label.Danger>
        }
        return <Label.Info>{ payment.status }</Label.Info>
    }

    return (
        <BaseContainer>
            <Loader loading={loadingPayment}>
            {
                _.isEmpty(payment) ?
                    <ErrorComponent
                        title={"Payment Not Found"}
                        description={"Oops, we can't find the payment you are looking for."}
                        primaryAction={{ href:getCompanySpecificUrl("/payments"), label:"Show all payments" }}
                        hideSupport
                    />
                : <ContentContainer variant="page" title={
                        <div className="d-flex flex-row gap-3">
                            <span>Payment for {payment.customer && (payment.customer.name || payment.customer.email)}</span>
                        </div>
                    }
                    customActions={ renderActions() }
                    {...(isMapleUser ? { enableStickyHeader: true } : {})}
                >
                    <KeyValueDisplay items={details} />
                </ContentContainer>
            }
            </Loader>
            <RefundPaymentModal show={showRefundPaymentModal} onClose={onModalClose} payment={payment} />
            <IntegrationReferenceEntitySummaryModal
                show={showReferenceSummaryModal} onClose={onModalClose} entityKey={payment.transaction_type}
                referenceID={paymentId}
            />
        </BaseContainer>
    );
}

export default Payment;
