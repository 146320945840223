import React, { useContext, useEffect, useState } from 'react';
import {BaseContext, currencyFormatFromPrice} from '../../helpers/common';
import { serverFetch, serverPost } from '../../helpers/server';
import _ from 'lodash';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import MapleTableHeaderWithActions from '../../components/MapleTableHeaderWithActions';
import SortableTableHeader from '../../components/SortableTableHeader';
import moment from 'moment';
import Columns from '../../components/Columns';
import classnames from 'classnames';
import Label from '../../components/Label';
import CreateSalesUserModal from '../../components/modals/CreateSalesUserModal';
import Notification from '../../components/Notification';
import InviteCompanyUserModal from '../../components/modals/InviteCompanyUserModal';

function SalesTeamOverview() {
	const [salesUsers, setSalesUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
	const [sort, setSort] = useState('createdAtDesc');
	const [fromKey, setFromKey] = useState(null);
	const [meta, setMeta] = useState(null);
	const [hasMore, setHasMore] = useState(true);
	const [showCreateSalesUserModal, setShowCreateSalesUserModal] = useState(false);
	const [settings, setSettings] = useState({});
	const [hasCommissionSettings, setHasCommissionSettings] = useState(false);
	const [showInviteModal, setShowInviteModal] = useState(false);
	const [filters, setFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [defaultSelectedFilters, setDefaultSelectedFilters] = useState({});

	useEffect(() => {
		setPageTitle(`Sales Team Overview`);
	}, []);

	const generatePeriodOptions = (config) => {
		const { period_type, anchor_date } = config;
		const options = [];

		const anchorMoment = moment(anchor_date);
		const now = moment();

		if (now.isBefore(anchorMoment)) {
			const periodEnd = anchorMoment.clone()
			periodEnd.add(getPeriodDuration(period_type)).add(-1, "day").endOf('day');
			options.push({
				value: periodEnd,
				label: `${anchorMoment.format('MMM DD, YYYY')} - ${periodEnd.format('MMM DD, YYYY')}`
			})
			return options;
		}

		// Find the current period's start date by moving forward from anchor date
		let currentPeriodStart = moment(anchor_date);
		while (currentPeriodStart.clone().add(getPeriodDuration(period_type)).isAfter(now)) {
			currentPeriodStart.subtract(getPeriodDuration(period_type));
		}
		while (currentPeriodStart.clone().add(getPeriodDuration(period_type)).isBefore(now)) {
			currentPeriodStart.add(getPeriodDuration(period_type));
		}

		// Generate the last 10 periods
		let periodStart = currentPeriodStart.clone();
		while (!periodStart.isBefore(anchorMoment)) {
			const periodEnd = periodStart.clone().add(getPeriodDuration(period_type)).add(-1, 'day').endOf('day');
			let label = `${periodStart.format('MMM DD, YYYY')} - ${periodEnd.format('MMM DD, YYYY')}`;

			options.push({
				value: periodEnd,
				label,
			});

			// Move to previous period
			periodStart.subtract(getPeriodDuration(period_type));
		}
		return options;
	};

	const getPeriodDuration = (periodType) => {
		switch (periodType) {
			case 'MONTHLY':
				return { months: 1 };
			case 'QUARTERLY':
				return { months: 3 };
			case 'YEARLY':
				return { years: 1 };
			default:
				return { months: 1 };
		}
	};

	const onSearch = (restart = true) => {
		setLoading(true)
		const limit = 50;
		const params = {
			query: {
				...(selectedFilters.quota_period && {
					quota_period_end_date: {
						eq: selectedFilters.quota_period
					}
				})
			},
			sort_key: sort || 'createdAtDesc',
			pagination: {
				from_key: restart ? null : fromKey,
				limit: limit,
			},
			include_meta: restart,
		};
		serverPost(getApiUrl('/commission/sales_user/find'), params).then((res) => {
			if (res) {
				const results = res.results || [];
				if (restart) {
					setSalesUsers(results);
				} else {
					setSalesUsers(_.concat(salesUsers, results));
				}
				if (res.meta) {
					setMeta(res.meta);
				}

				setFromKey(res.pagination.from_key);
				setHasMore(results.length === limit);
			}
		});
		setLoading(false)
	};

	const fetchSettings = () => {
		setLoading(true);
		serverFetch(getApiUrl('/settings')).then((res) => {
			if (res) {
				setSettings(res);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		onSearch();
	}, [selectedFilters]);

	useEffect(() => {
		fetchSettings();
	}, []);

	useEffect(() => {
		if (
			settings.commission_config &&
			settings.commission_config.commission_quota_period_config &&
			settings.commission_config.commission_report_period_config
		) {
			setHasCommissionSettings(true);
			const periodOptions = generatePeriodOptions(settings.commission_config.commission_quota_period_config);
			if (periodOptions && periodOptions.length > 0) {
				const currentPeriodEndDate = periodOptions[0].value;

				setFilters([
					{
						title: "Quota Period",
						type: "dropdown",
						name: "quota_period",
						options: [
							...periodOptions
						]
					}
				]);

				setDefaultSelectedFilters({
					quota_period: currentPeriodEndDate
				});
				setSelectedFilters({
					quota_period: currentPeriodEndDate
				})
			}
		}
	}, [settings]);

	const onCreateSuccess = () => {
		Notification.Success('Successfully added sales member');
		setShowCreateSalesUserModal(false);
		onSearch();
	};

	const onCreateFailure = () => {
		Notification.Danger('Error inviting sales member');
	};

	const onInviteNewCompanyUser = () => {
		setShowCreateSalesUserModal(false);
		setShowInviteModal(true);
	};

	const onParamsChange = (params) => {
		setSelectedFilters(params);
	};

	return (
		<BaseContainer>
			<ContentContainer>
				<InfiniteScroll
					next={() => onSearch(false)}
					hasMore={hasMore}
					scrollableTarget='content-wrapper'
					dataLength={salesUsers.length}
				>
					<Section
						title='Sales Team Overview'
						variant={'page'}
						loading={loading}
						actions={[
							{
								variant: 'primary',
								icon: 'fa-plus',
								label: 'Add Sales User',
								onClick: () => setShowCreateSalesUserModal(true),
							},
						]}
					>
						<MapleTable>
							<MapleTableHeaderWithActions showSearch={false} meta={meta} showFilters={true}
														 filters={filters}
														 defaultSelectedFilters={defaultSelectedFilters}
														 onParamsChange={onParamsChange}/>
							<MapleTable.Content>
								<thead>
									<tr>
										<MapleTable.TH>Sales Member</MapleTable.TH>
										<MapleTable.TH>Commission Plan</MapleTable.TH>
										<MapleTable.TH className="text-end">Quota Achieved</MapleTable.TH>
										<MapleTable.TH className="text-end">Commissions Achieved</MapleTable.TH>
									</tr>
								</thead>
								<tbody className='divide-y divide-gray-200'>
									{salesUsers.map((row, i) => (
										<MapleTable.TR
											key={i}
											className={classnames('cursor-pointer', 'hover:bg-gray-100')}
											href={getCompanySpecificUrl(`/commissions/sales_user/${row.id}`)}
										>
											<td>
												<Columns.CompanyUserName companyUser={row.company_user} />
											</td>
											<td>{row.commission_plans[0].name}</td>
											<td className={'text-end'}>
												{currencyFormatFromPrice(row.selected_quota_period.quota_achieved)} /{' '}
												{currencyFormatFromPrice(row.selected_quota_period.quota_value)}
											</td>
											<td className={'text-end'}>
												{currencyFormatFromPrice(row.selected_quota_period.commission_achieved)}
											</td>
										</MapleTable.TR>
									))}
								</tbody>
							</MapleTable.Content>
						</MapleTable>
					</Section>
				</InfiniteScroll>
			</ContentContainer>
			<CreateSalesUserModal
				show={showCreateSalesUserModal}
				onClose={() => setShowCreateSalesUserModal(false)}
				size={'sm'}
				onSuccess={onCreateSuccess}
				onFailure={onCreateFailure}
				salesUsers={salesUsers}
				hasCommissionSettings={hasCommissionSettings}
				onInviteNewCompanyUser={onInviteNewCompanyUser}
			/>
			<InviteCompanyUserModal
				show={showInviteModal}
				onClose={(success) => {
					setShowInviteModal(false);
					setShowCreateSalesUserModal(true);
				}}
			/>
		</BaseContainer>
	);
}

export default SalesTeamOverview;
