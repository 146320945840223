import '../App.scss';
import React, { useState, useContext, useRef, useEffect } from 'react';
import {serverPost} from '../helpers/server';
import {BaseContext, renderDescriptionForItemPricing, renderTags} from '../helpers/common';
import Select from "react-select/async";
import { components } from "react-select";
import classnames from "classnames";
import Button from './common/buttons/Button';
import Input from './common/Input';
import Award03 from '@untitled-ui/icons-react/build/esm/Award03';
import Plus from '@untitled-ui/icons-react/build/esm/Plus';
import { CircleStackIcon } from '@heroicons/react/20/solid';
const _ = require('lodash');

function ProductPricingInput({allowCreation, ...props}) {
    const { onCreateProductPricing } = props;
    const { company, getApiUrl } = useContext(BaseContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const selectRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const asyncLoadPrices = async (query) => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            search_query: query,
            query: props.extraQueryFilters && {
                ...props.extraQueryFilters
            },
            pagination: {
                limit: limit
            },
        }
        const [productPricingResult, bundlePricingResult] = await Promise.all([
            serverPost(getApiUrl("/product_pricings/autocomplete"), params),
            props.excludeBundlePricing ? null: serverPost(getApiUrl("/bundle_pricings/autocomplete"), params)
        ])
        let combinedResults = [];
        if (bundlePricingResult) {
            const allResults = bundlePricingResult.results || [];
            _.each(allResults, r => {
                r.isBundlePricing = true;
            })
            const groupedPrices = _.groupBy(allResults, (p) => "Bundles");
            const results = _.map(groupedPrices, (values, key) => {
                return {
                    label: key,
                    options: values
                };
            })
            combinedResults = results;
        }
        if (productPricingResult) {
            const allResults = productPricingResult.results || [];
            const groupedPrices = _.groupBy(allResults, (p) => p.product.name);
            const results = _.map(groupedPrices, (values, key) => {
                return {
                    label: key,
                    options: values
                };
            })
            combinedResults = _.concat(combinedResults, results);
        }
        return combinedResults;
    }

    const loadPrices = (query, callback) => {
        asyncLoadPrices(query).then(res => {
            callback(res);
        })
    };

    const renderPricingTypeIcon = (productMetricPricings) => {
        const size = { height: 16, width: 16 };
        const classes = "text-primary-updated product-pricing-option-disabled";
        if (productMetricPricings.length > 1) {
            return <i className={classnames("fa-solid fa-coins text-sm h-4", classes)} />
        } 

        const pmp = productMetricPricings[0];
        if (pmp.item_pricing.type === "SCHEDULED") {
            return <i className={classnames("fa-solid fa-sliders text-sm h-4", classes)} />
        } else if  (pmp.item.type === "LICENSE_ITEM") {
            return <CircleStackIcon className={classnames("h-4 w-4", classes)} />
        } else if (pmp.item.type === "ONETIME_ITEM" && pmp.item_pricing.credit_price) {
            return <Award03 {...size} className={classes} />
        } else if (pmp.item.type === "ONETIME_ITEM") {
            return <i className={classnames("fa-regular fa-money-bill-1 text-sm h-4", classes)} />
        } else if (pmp.item.type === "CUSTOM_OBJ" || pmp.item.type === "CUSTOM_USAGE") {
            return <CircleStackIcon className={classnames("h-4 w-4", classes)} />
        }
    };

    const pricingLabel = (pricing) => {
        if (pricing.__new__) {
            return (
                <div className="flex flex-col text-sm">
                    <span className="font-semibold text-primary-updated-dark">+ Create New Product Pricing</span>
                </div>
            )
        } else if (pricing.isBundlePricing) {
            return (
                <div className="flex flex-col text-sm">
                    <span>{ pricing.name }</span>
                    <span className="caption text-gray-500">{ pricing.bundle_product_pricings.length } prices</span>
                </div>
            )
        } else {
            const ppLabel = pricing.name || pricing.description || "";
            const tags = _.uniq(_.concat(pricing.tags, ...pricing.product.tags));
            return (
                <div className="flex gap-3">
                    <div>
                        { !_.isEmpty(ppLabel) &&
                        <div className="text-dark-gray font-medium mb-2 product-pricing-option-disabled flex items-center gap-2">
                            { ppLabel }
                            <span className="inline-block border p-1 bg-white rounded-md">
                                {renderPricingTypeIcon(pricing.product_metric_pricings)}
                            </span>
                        </div>
                        }
                        <div className="flex flex-col gap-2">
                            {
                                _.map(pricing.product_metric_pricings, (pmp, i) => {
                                    return <React.Fragment key={i}>
                                        <span key={i} className="">{renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item, props.billableItems)}</span>
                                        {
                                            tags && Array.isArray(tags) && tags.length > 0 &&
                                            <div className="mt-1">{ renderTags(tags) }</div>
                                        }
                                    </React.Fragment>
                                })
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }

    const groupLabel = (data) => ( 
        <div className="flex text-sm text-gray6" style={{ paddingTop: "8px", paddingBottom: "8px" }}>
            <span style={{ textTransform: "initial" }}><span style={{ fontWeight: 600 }}>[{data.label}]</span> - {data.options.length} Prices</span>
        </div>
    );

    const MenuList = ({ selectProps, ...props}) => {
        const  { inputValue, onInputChange } = selectProps;

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, [inputValue]);

        return (
            <div>
                <div className="flex flex-col px-3 py-3 gap-3 bg-light-gray border-b border-b-gray2">
                    <div className="text-dark-gray text-base font-semibold">Search existing products</div>
                    <div className="flex gap-3 items-center">
                        <Input 
                            ref={inputRef}
                            placeholder="Search products"
                            value={inputValue} 
                            onChange={(e) => onInputChange(e.currentTarget.value, { action: "input-change" })} 
                            onMouseDown={(e) => {
                                e.stopPropagation();
                                e.target.focus();
                            }} 
                            onKeyDown={(e) => {
                                e.stopPropagation();
                                e.target.focus();
                            }}
                        />
                        {allowCreation && (
                            <>
                                <span>or</span>
                                <Button onClick={() => {
                                    if (onCreateProductPricing) {
                                        onCreateProductPricing();
                                        selectProps.onMenuClose();
                                    }
                                }}>
                                    <span className="flex gap-1 items-center">
                                        <Plus className="inline" width={16} height={16} />
                                        New Pricing
                                    </span>
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <components.MenuList {...props} selectProps={selectProps} />
            </div>
        );
    };

    const ButtonSelect = ({ children, ...props}) => {
        const onClickHandler = () => {
            if (!props.selectProps.menuIsOpen) {
                props.selectProps.onMenuOpen();
            } else {
                props.selectProps.onMenuClose();
            }
        };
        return (
            <components.Control {...props} className={classnames(props.className, "!inline-block")}>
                <Button
                    variant="outline-primary" 
                    onClick={onClickHandler}
                    onTouchEnd={onClickHandler}
                >
                    <div className="flex gap-1">
                        <Plus className="inline" width={20} height={20} />
                        Add Product Pricing
                    </div>
                </Button>
            </components.Control>
        )
    };

    const selectedProductPricingIds = _.map(props.selectedProductPricings, (pp) => pp.id);
    return (
        <div className="form-group">
            <div className="mt-3" ref={selectRef}>
                <Select
                    menuIsOpen={isMenuOpen}
                    menuPlacement={props.menuPlacement || "top"}
                    components={{ 
                        DropdownIndicator: null, 
                        Control: ButtonSelect, MenuList: MenuList,
                    }}
                    isMulti={false}
                    className={classnames("select-container min-w-[300px] sm:min-w-[450px]", props.menuPlacement)}
                    classNames={{
                        menu: (state) => {
                            const classes = [
                                state.menuPlacement === 'top' ? "!bottom-dropdown-spacing-rs" : "!top-dropdown-spacing-rs",
                                "!rounded-lg !overflow-hidden z-0 min-h-[411px]",
                            ];
                            return classes.join(" ");
                        },
                        option: ({ isFocused, isDisabled }) => {
                            const classes = [
                                "hover:!bg-gray0 !border-b !border-b-gray2",
                                isFocused ? "!bg-gray0" : "",
                                isDisabled ? "product-pricing-disabled" : "",
                            ];
                            return classes.join(" ");
                        },
                        groupHeading: () => "!bg-gray1",
                    }}
                    loadOptions={loadPrices}
                    defaultOptions
                    classNamePrefix="select2"
                    getOptionLabel={pricingLabel}
                    getOptionValue={(c) => c.id}
                    value={null}
                    placeholder="Add a Product Pricing or a Bundle..."
                    onChange={(value) => {
                        if (value.__new__) {
                            if (props.onCreateProductPricing) {
                                props.onCreateProductPricing();
                            }
                        } else if (value.isBundlePricing) {
                            props.setSelectedProductPricings(prevSelectedProductPricings => {
                                const newSelectedProductPricings = [...prevSelectedProductPricings];
                                _.each(value.bundle_product_pricings, bpp => {
                                    newSelectedProductPricings.push(bpp.product_pricing);
                                });
                                return newSelectedProductPricings;
                            })
                        } else {
                            props.setSelectedProductPricings(prevSelectedProductPricings => {
                                const newSelectedProductPricings = [...prevSelectedProductPricings];
                                newSelectedProductPricings.push(value);
                                return newSelectedProductPricings;
                            })
                        }
                    }}
                    isOptionDisabled={(option) => {
                        return (
                            _.includes(selectedProductPricingIds, option.id) || 
                            (props.selectedProductPricings?.[0] ? option.currency !== props.selectedProductPricings?.[0].currency : false)
                        );
                    }}
                    isClearable={false}
                    noOptionsMessage={() => "No pricings found."}
                    formatGroupLabel={groupLabel}
                    onMenuClose={() => setIsMenuOpen(false)}
                    onMenuOpen={() => setIsMenuOpen(true)}
                    inputValue={searchTerm}
                    onInputChange={(value) => {
                        setSearchTerm(value);
                    }}
                />
            </div>
        </div>
    )
}

export default ProductPricingInput;
