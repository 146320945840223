import '../../App.scss';
import '../../css/invoice.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { serverFetch, serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import Loader from '../../components/Loader';
import InvoiceComponent from '../../components/InvoiceComponent';
const _ = require('lodash');

function InvoiceNext() {
    const { subscriptionId } = useParams();
    const { getApiUrl, setPageTitle } = useContext(BaseContext);

    const [subscription, setSubscription] = useState({});
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [settings, setSettings] = useState({});
    const [includeTaxes, setIncludeTaxes] = useState(false);

    useEffect(() => {
        setPageTitle(`Invoice`);
    }, []);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/subscriptions/${subscriptionId}`), { skipCache }).then((res) => {
            if (res) {
                setSubscription(res);
            }
        });

        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
    }

    useEffect(() => {
        if (_.isEmpty(subscription)) {
            return;
        }
        const data = {
            subscription_id: subscription.id,
            customer_id: subscription.customer.id,
            currency: subscription.bundle_pricing.currency,
            include_taxes: includeTaxes
        }
        serverPost(getApiUrl(`/invoices/next`), data).then((res) => {
            if (res) {
                setInvoiceDetails(res);
            }
        });
    }, [getApiUrl, subscription, includeTaxes])

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Loader loading={_.isEmpty(invoiceDetails) || _.isEmpty(settings)}>
        {
            () =>
                <InvoiceComponent
                    invoice={invoiceDetails} settings={settings}
                    showTaxAsEstimate={settings.tax_calc_enabled && !includeTaxes}
                    onChangeIncludeTaxes={setIncludeTaxes} />
        }
        </Loader>
    );
}

export default InvoiceNext;
