import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import MapleTableHeaderWithActions from '../../components/MapleTableHeaderWithActions';
import { serverPost } from '../../helpers/server';
import {BaseContext, currencyFormatFromPrice, getLabelForCommissionReportStatus} from '../../helpers/common';
import InfiniteScroll from 'react-infinite-scroll-component';
import SortableTableHeader from '../../components/SortableTableHeader';
import moment from 'moment/moment';
import Columns from "../../components/Columns";
const _ = require('lodash');

function CommissionReports(props) {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [sort, setSort] = useState('startDateDesc');
    const [fromKey, setFromKey] = useState(null);
    const [meta, setMeta] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        setPageTitle('Commission Reports');
    }, []);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            query: {},
            sort_key: sort || 'startDateDesc',
            pagination: {
                from_key: restart ? null : fromKey,
                limit: limit,
            },
            include_meta: restart,
        };
        if (props.salesUserId) {
            params.query.sales_user_ids = {
                condition: "OR",
                values: [props.salesUserId],
            }
        }
        if (props.endDate) {
            params.query.end_date = {
                eq: props.endDate
            }
        }
        serverPost(getApiUrl('/commission/reports/find'), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setReports(results);
                } else {
                    setReports(_.concat(reports, results));
                }
                if (res.meta) {
                    setMeta(res.meta);
                }

                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        onSearch();
    }, [sort]);

    const handleRowClick = (reportPeriodId) => {
        navigate(getCompanySpecificUrl(`/commissions/reports/${reportPeriodId}`));
    };

    return (
		<ContentContainer className={props.isComponent && 'bg-white'}>
			<InfiniteScroll
				next={() => onSearch(false)}
				hasMore={hasMore}
				scrollableTarget='content-wrapper'
				dataLength={reports.length}
			>
				<Section title={props.isComponent ? '' : 'Reports for Sales Member'} loading={loading}>
					<MapleTable>
						{!props.isComponent && <MapleTableHeaderWithActions showExport={true} />}
						<MapleTable.Content>
							<thead>
								<tr>
									<MapleTable.TH>Sales Member</MapleTable.TH>
									{!props.isComponent && (
										<SortableTableHeader
											onSortChange={setSort}
											sortKeyUp='startDateAsc'
											sortKeyDown='startDateDesc'
											currentSort={sort}
										>
											Start Date
										</SortableTableHeader>
									)}
									{!props.isComponent && (
										<SortableTableHeader
											onSortChange={setSort}
											sortKeyUp='endDateAsc'
											sortKeyDown='endDateDesc'
											currentSort={sort}
										>
											End Date
										</SortableTableHeader>
									)}
									<MapleTable.TH>Status</MapleTable.TH>
									<MapleTable.TH className='text-end'>Total Commissions Achieved</MapleTable.TH>
									<MapleTable.TH className='text-end'>Total Quota Achieved</MapleTable.TH>
								</tr>
							</thead>
							<tbody className='divide-y divide-gray-200'>
								{reports.map((report, i) => (
									<tr
										key={i}
										onClick={() => handleRowClick(report.id)}
										className='cursor-pointer hover:bg-gray-50'
									>
										<td>
											<Columns.CompanyUserName companyUser={report.sales_user.company_user} />
										</td>
										{!props.isComponent && (
											<td>{moment(report.start_date).format('MMM D, YYYY')}</td>
										)}
										{!props.isComponent && <td>{moment(report.end_date).format('MMM D, YYYY')}</td>}
										<td>{getLabelForCommissionReportStatus(report.status)}</td>
										<td className='text-end'>{currencyFormatFromPrice(report.commission_achieved)}</td>
										<td className='text-end'>{currencyFormatFromPrice(report.quota_achieved)}</td>
									</tr>
								))}
							</tbody>
						</MapleTable.Content>
					</MapleTable>
				</Section>
			</InfiniteScroll>
		</ContentContainer>
	);
}

export default CommissionReports;