import '../../App.scss';
import '../../css/modals.scss';
import React, {createRef, useState, useEffect, useContext } from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import {
    BaseContext,
    getCurrencyOptions, getDescriptionForMetric,
    renderDescriptionForItemPricing
} from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AddOrEditBillableItemPricingModal from "./AddOrEditBillableItemPricingModal";
import MapleTable from "../MapleTable";
import ItemPricingFields from "../ItemPricingFields";
import Link from '../Link';
const _ = require('lodash');

function AddOrEditProductPricingModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [showAddOrEditBillableItemPricingModal, setShowAddOrEditBillableItemPricingModal] = useState(false);
    const [billableItems, setBillableItems] = useState([]);
    const [billableMetrics, setBillableMetrics] = useState([]);
    const [originalBillableItemPricings, setOriginalBillableItemPricings] = useState([]);
    const [billableItemPricings, setBillableItemPricings] = useState([]);
    const [loadingItemPricings, setLoadingItemPricings] = useState(true);
    const [onetimeItem, setOnetimeItem] = useState(null);
    const [onetimeMetric, setOnetimeMetric] = useState(null);
    const [licenseMetric, setLicenseMetric] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [recurring, setRecurring] = useState("SUBS_PRICING");
    const [selectedMetricPricings, setSelectedMetricPricings] = useState([]);
    const [metricTypes, setMetricTypes] = useState({});
    const [error, setError] = useState(null);
    const [initialFields, setInitialFields] = useState(null);

    const [creditType, setCreditType] = useState("UNITS");

    const fieldsRef = createRef();

    useEffect(() => {
        setBillableItems(props.billableItems);
    }, [props.billableItems]);

    useEffect(() => {
        if (props.pricingToDuplicate && !_.isEmpty(props.pricingToDuplicate)) {
            const ifields = {
                currency: props.pricingToDuplicate.currency,
                credit_type: "UNITS",
                name: props.pricingToDuplicate.name,
                description: props.pricingToDuplicate.description,
                external_name: props.pricingToDuplicate.external_name,
                pp_type: props.pricingToDuplicate.type,
            }
            if (props.pricingToDuplicate.product_metric_pricings && props.pricingToDuplicate.product_metric_pricings.length > 0) {
                const itemPricingToDuplicate = props.pricingToDuplicate.product_metric_pricings[0].item_pricing;
                const itemToDuplicate = props.pricingToDuplicate.product_metric_pricings[0].item;
                if (itemPricingToDuplicate) {
                    ifields.charge_type = itemPricingToDuplicate.charge_type;
                    ifields.display_type = itemPricingToDuplicate.display_type;
                    ifields.frequency = itemPricingToDuplicate.frequency;
                    if (itemPricingToDuplicate.term_count > 1) {
                        ifields.frequency = "CUSTOM";
                        ifields.custom_frequency = itemPricingToDuplicate.frequency;
                        ifields.term_count = itemPricingToDuplicate.term_count;
                    }
                    ifields.item_id = itemToDuplicate.id;
                    ifields.base_units = itemPricingToDuplicate.base_units;
                    ifields.type = itemPricingToDuplicate.type;
                    ifields.metric_id = props.pricingToDuplicate.product_metric_pricings[0].metric_id;
                    if (ifields.type === "FIXED") {
                        ifields.fixed_price = {...itemPricingToDuplicate.fixed_price}
                        ifields.fixed_price.price_per_unit = ifields.fixed_price.price_per_unit/100;
                    } else if (ifields.type === "STEP") {
                        ifields.step_price = {...itemPricingToDuplicate.step_price}
                        ifields.step_price.price_per_step = ifields.step_price.price_per_step/100;
                    } else if (ifields.type === "GRADIENT") {
                        ifields.gradient_price = [...itemPricingToDuplicate.gradient_price]
                        _.each(ifields.gradient_price, vp => {
                            vp.price_per_unit = vp.price_per_unit/100
                            vp.flat_price = vp.flat_price/100
                        })
                    } else if (ifields.type === "VOLUME") {
                        ifields.volume_price = [...itemPricingToDuplicate.volume_price]
                        _.each(ifields.volume_price, vp => {
                            vp.price_per_unit = vp.price_per_unit/100
                            vp.flat_price = vp.flat_price/100
                        })
                    }
                    const isBaseType = itemToDuplicate.type === "LICENSE_ITEM" && itemPricingToDuplicate.base_price.value_in_cents === 0 && itemPricingToDuplicate.type === "FIXED";
                    ifields.base_type = isBaseType ? "yes" : "no";
                    if (isBaseType) {
                        ifields.base_price = itemPricingToDuplicate.fixed_price.price_per_unit / 100;
                    } else {
                        ifields.base_price = itemPricingToDuplicate.base_price.value_in_cents / 100;
                    }
                }
            }
            if (props.pricingToDuplicate.type === "ONETIME_PRICING") {
                const pmp = props.pricingToDuplicate.product_metric_pricings[0];
                if (pmp.item_pricing) {
                    ifields.onetime_price = pmp.item_pricing.fixed_price.price_per_unit/100;
                    ifields.currency = pmp.item_pricing.base_price.currency;
                }
                if (pmp.item_pricing.credit_price) {
                    const cp = pmp.item_pricing.credit_price;
                    setCreditType(cp.type);
                    ifields.credit_type = cp.type;
                    ifields.credit_value = cp.amount.value_in_cents/100;
                    ifields.credit_currency = cp.amount.currency;
                    ifields.credit_units = cp.units;
                    ifields.credit_item_id = cp.item_id;
                    ifields.type = "ONETIME_PRICING_WITH_CREDITS";
                }
            } else {
                ifields.product_metric_pricings = _.map(props.pricingToDuplicate.product_metric_pricings, (pmp) => {
                    return { metric_id: pmp.metric_id };
                })
                setSelectedMetricPricings(_.map(props.pricingToDuplicate.product_metric_pricings, (pmp, i) => {
                    return {
                        item_pricing: pmp.item_pricing,
                        item: pmp.item,
                    }
                }))
            }
            setRecurring(ifields.pp_type);
            setInitialFields(ifields);
        } else {
            setInitialFields({
                currency: company.preferred_currency,
                credit_type: "UNITS"
            })
        }
    }, [props.pricingToDuplicate])

    useEffect(() => {
        if (props.show) {
            serverPost(getApiUrl("/billable/items/find"), { companyId: company.id }).then((res) => {
                if (res) {
                    setOnetimeItem(_.find(res, (r) => r.type === "ONETIME_ITEM"));
                    setBillableItems(res);
                }
            });

            serverPost(getApiUrl("/billable/metrics/find"), { companyId: company.id }).then((res) => {
                if (res) {
                    setOnetimeMetric(_.find(res, (r) => r.type === "ONETIME_METRIC"))
                    setLicenseMetric(_.find(res, (r) => r.type === "LICENSE_METRIC"))
                    setBillableMetrics(_.filter(res, (r) => !r.standard));
                }
            });

            serverPost(getApiUrl("/item_pricings/find"), { companyId: company.id }).then((res) => {
                setOriginalBillableItemPricings(res || []);
            });
        } else {
            setSelectedMetricPricings([]);
        }
    }, [props.show, company, getApiUrl])

    useEffect(() => {
        if (!props.show) {
            setRecurring("SUBS_PRICING");
            setInitialFields({
                currency: company.preferred_currency,
                credit_type: "UNITS"
            })
        }
    }, [props.show])

    useEffect(() => {
        setError(null);
    }, [selectedMetricPricings]);

    useEffect(() => {
        if (!billableItems) {
            return;
        }
        _.each(originalBillableItemPricings, (ip) => {
            ip.item = _.find(billableItems, (i) => i.id === ip.item_id);
        })
        setBillableItemPricings(originalBillableItemPricings);
        // if (originalBillableItemPricings.length > 0) {
        setLoadingItemPricings(false);
        // }
    }, [billableItems, originalBillableItemPricings])

    useEffect(() => {
        if (_.isNil(props.isEditing)) {
            setIsEditing(!_.isNil(props.itemToEdit));
        } else {
            setIsEditing(props.isEditing);
        }
    }, [props.itemToEdit])

    const onDeleteRow = (index) => {
        setSelectedMetricPricings(prevSelectedMetricPricings => {
            const newSelectedMetricPricings = [...prevSelectedMetricPricings];
            newSelectedMetricPricings.splice(index, 1)
            return newSelectedMetricPricings;
        });
    }

    const onFieldChange = (key, value) => {
        setError(null);
        if (key.endsWith("metric_id")) {
            setMetricTypes(prevTypes => {
                const newTypes = {...prevTypes};
                newTypes[key] = value
                return newTypes;
            })
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields[key] = value;
                return newFields
            })
        } else if (key === "pp_type") {
            setRecurring(value);
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields[key] = value;
                return newFields
            })
        } else if (key === "credit_type") {
            setCreditType(value);
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields[key] = value;
                return newFields
            })
        } else if (_.includes(["name", "description", "external_name", "onetime_price", "currency", "credit_value", "credit_currency", "credit_units", "credit_item_id"], key)) {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields[key] = value;
                return newFields
            })
        } else {
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields[key] = value;
                return newFields
            })
        }
        if (fieldsRef.current) {
            fieldsRef.current.onFieldChange(key, value);
        }
    }

    const handleError = async (error) => {
        const message = await error.json();
        setError(message.error_message || message.error)
    }

    const addItem = async (itemFields, errorHandler) => {
        setError(null);
        itemFields.product_id = props.product.id;
        if (itemFields.pp_type === "ONETIME_PRICING" || itemFields.pp_type === "ONETIME_PRICING_WITH_CREDITS") {
            let creditPrice = null;
            if (itemFields.pp_type === "ONETIME_PRICING_WITH_CREDITS") {
                creditPrice = {
                    type: itemFields.credit_type,
                    amount: {
                        value_in_cents: parseFloat(itemFields.credit_value) * 100,
                        currency: itemFields.credit_currency
                    },
                    units: itemFields.credit_units,
                    item_id: itemFields.credit_item_id
                }
            }
            const onetimeData = {
                item_id: onetimeItem.id,
                name: itemFields.name,
                description: itemFields.description,
                frequency: "MONTH",
                base_price: {
                    value_in_cents: 0,
                    currency: itemFields.currency
                },
                type: "FIXED",
                proration_type: "NONE",
                charge_type: "PRE_POST",
                fixed_price: { price_per_unit: parseFloat(itemFields.onetime_price) * 100 },
                credit_price: creditPrice
            }
            const onetimePricing = await serverPost(getApiUrl("/item_pricings"), onetimeData);
            if (!onetimePricing) {
                setError("Unable to create pricing.")
                return;
            }

            const productPricingData = {
                product_id: props.product.id,
                name: itemFields.name,
                external_name: itemFields.external_name,
                description: itemFields.description,
                type: "ONETIME_PRICING",
                currency: itemFields.currency,
                product_metric_pricings: [
                    { item_id: onetimeItem.id, metric_id: onetimeMetric && onetimeMetric.id, item_pricing_id: onetimePricing.id }
                ]
            }
            serverPost(getApiUrl('/product_pricings'), productPricingData, {}, handleError).then((res) => {
                if (res) {
                    props.onClose();
                }
            })
        } else if (false) {
            if (selectedMetricPricings.length === 0) {
                setError("Please select at least one price");
                return
            }
            const currencies = _.map(selectedMetricPricings, (mp) => {
                return mp.item_pricing.base_price.currency
            })
            const uniqueCurrencies = _.uniq(currencies);
            if (uniqueCurrencies.length > 1) {
                setError("All prices should be of the same currency");
                return
            }

            itemFields.product_metric_pricings = _.map(selectedMetricPricings, (mp, i) => {
                let metric_id = null;
                if (mp.item.type === "LICENSE_ITEM") {
                    metric_id = licenseMetric && licenseMetric.id;
                } else if (mp.item.type === "ONETIME_ITEM") {
                    metric_id = onetimeMetric && onetimeMetric.id;
                } else if (_.has(itemFields.product_metric_pricings, String(i))) {
                    metric_id = itemFields.product_metric_pricings[String(i)].metric_id;
                }
                return {
                    item_id: mp.item_pricing.item_id,
                    metric_id: metric_id,
                    item_pricing_id: mp.item_pricing.id,
                    currency: mp.item_pricing.base_price.currency
                }
            })
            const allGood = _.every(itemFields.product_metric_pricings, (pmp) => !_.isNil(pmp.metric_id))
            if (!allGood) {
                setError("Please select a valid metric for the price.");
                return
            }

            itemFields.currency = itemFields.product_metric_pricings[0].currency;
            serverPost(getApiUrl('/product_pricings'), itemFields, {}, handleError).then((res) => {
                if (res) {
                    props.onClose();
                }
            })
        } else {
            let newFields = null;
            if (itemFields.type === "GRADIENT") {
                itemFields.gradient_price = initialFields.gradient_price;
            }
            if (itemFields.type === "VOLUME") {
                itemFields.volume_price = initialFields.volume_price;
            }
            if (_.isNil(itemFields.display_type)) {
                itemFields.display_type = initialFields.display_type;
            }
            if (_.isNil(itemFields.charge_type)) {
                itemFields.charge_type = initialFields.charge_type;
            }
            if (fieldsRef.current) {
                newFields = fieldsRef.current.processFields(itemFields);
            } else {
                newFields = {...itemFields};
            }
            // const itemPricingResult = const addItemUrl = getApiUrl(props.itemBaseUrl);
            const itemPricingResult = await serverPost(getApiUrl("/item_pricings"), newFields, {}, errorHandler)
            if (!itemPricingResult) {
                console.log("Error creating item pricing")
                return;
            }

            let metric_id = itemFields.metric_id;
            if (!metric_id) {
                // If metric id is not provided, then it is a license metric
                metric_id = licenseMetric && licenseMetric.id
            }
            const productPricingData = {
                product_id: props.product.id,
                name: itemFields.name,
                external_name: itemFields.external_name,
                description: itemFields.description,
                type: "SUBS_PRICING",
                currency: newFields.base_price.currency,
                product_metric_pricings: [
                    { item_id: itemPricingResult.item_id, metric_id: metric_id, item_pricing_id: itemPricingResult.id }
                ]
            }
            serverPost(getApiUrl('/product_pricings'), productPricingData, {}, handleError).then((res) => {
                if (res) {
                    props.onClose();
                }
            })
        }
    }

    const loadItemPrices = (query, callback) => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            search_query: query,
            query: {
                ...props.extraQueryFilters,
                is_recurring: recurring === "SUBS_PRICING"
            },
            pagination: {
                limit: limit
            },
        }
        serverPost(getApiUrl("/item_pricings/autocomplete"), params).then(async (res) => {
            if (res) {
                const results = res.results || [];
                results.unshift({ value: "new", label: <div><span className="text-sm font-semibold">Create New Price</span></div> })
                callback(results);
            }
        });
    }

    const pricingLabel = (pricing) => {
        if (pricing.value === "new") {
            return pricing.label
        } else {
            return renderDescriptionForItemPricing(pricing, true, _.find(billableItems, (b) => b.id === pricing.item_id))
        }
    }

    const getMetricOptionsForItemPricing = (itemPricing) => {
        return _.map(_.filter(billableMetrics, (m) => m.item_id === itemPricing.item_id), (bm) => {
            return { value: bm.id, label: bm.name }
        })
    }

    const creditTypeOptions = [
        { value: "AMOUNT", label: "Amount" },
        { value: "UNITS", label: "Units" },
    ]

    const recurringOptions = [
        { value: "SUBS_PRICING", label: "Recurring" },
        { value: "ONETIME_PRICING", label: "One-time" },
        { value: "ONETIME_PRICING_WITH_CREDITS", label: "One-time (credits)" },
    ]

    const billableItemOptions = _.map(_.filter(billableItems, (r) => !r.standard), (bi) => {
        return { value: bi.id, label: bi.name }
    })

    const getDescriptionForType = (type) => {
        if (type === "SUBS_PRICING") {
            return "Recurring";
        } else if (type === "ONETIME_PRICING" || type === "ONETIME_PRICING_WITH_CREDITS") {
            return "One-time";
        } else {
            return type;
        }
    }

    const onModalClose = () => {
        setShowAddOrEditBillableItemPricingModal(false);
    }

    const onItemPricingCreated = (itemPricing) => {
        itemPricing.item = _.find(billableItems, (i) => i.id === itemPricing.item_id);
        setSelectedMetricPricings(prevSelectedMetricPricings => {
            const newSelectedMetricPricings = [...prevSelectedMetricPricings];
            newSelectedMetricPricings.push({
                item_pricing: itemPricing,
                item: itemPricing.item,
            });
            return newSelectedMetricPricings;
        })
    }

    if (!props.show) {
        return;
    }

    const renderProductMetric = (pmp) => {
        if (pmp.item.type === "ONETIME_ITEM") {
            return <span>Quantity</span>;
        } else if (pmp.item.type === "LICENSE_ITEM") {
            return <span>Number of Licenses</span>;
        } else {
            return (
                <>
                    <span>{ pmp.metric.name }</span>
                    <span className="caption">{ getDescriptionForMetric(pmp.metric) }</span>
                </>
            )
        }
    }

    return (
        <>
            <BaseAddOrEditItemModal
                size="lg"
                {...props}
                show={props.show && !showAddOrEditBillableItemPricingModal}
                initialFormFields={initialFields}
                addItem={addItem}
                onFieldChange={onFieldChange}
                itemLabel={t('billable_product_pricing.pricing')}
                itemBaseUrl="/product_pricings"
            >
                <Row>
                    <BaseForm.Input colSpan="6" name="name" label={t('common.name')} type="text" required />
                    <BaseForm.Input colSpan="6" name="description" label={"Internal Description"} type="text" />
                    <BaseForm.Input colSpan="12" name="external_name" label={"Public Name"} type="text"
                                    description="This appears in customer invoices and contracts."/>
                    {
                        !isEditing &&
                            <BaseForm.Input colSpan="6" name="pp_type" label={t('billable_product_pricing.type')} type="select"
                                            options={recurringOptions} showSearch={false} disabled={isEditing}/>
                    }
                </Row>
                <Row className="mt-2">
                    <Col md="12">
                        <p className="body2">Price Details</p>
                    </Col>
                </Row>
                {
                    isEditing &&
                        <>
                            <p>
                                <span className="body1">Type:</span> { getDescriptionForType(props.itemToEdit.type) }
                            </p>
                            <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                    <tr>
                                        <MapleTable.TH>Pricing</MapleTable.TH>
                                        <MapleTable.TH>Metric/Quantity</MapleTable.TH>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(props.itemToEdit.product_metric_pricings, (pmp, i) =>
                                            <tr key={i}>
                                                <td>{ renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item, billableItems) }</td>
                                                <td>{ renderProductMetric(pmp, null) }</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                            <span className="body1 italic warning-color">Note: You can't edit the pricing details once created. If you don't want this pricing to be available any more, you can archive it and create a new product pricing.</span>
                        </>
                }
                {
                    !isEditing && recurring === "ONETIME_PRICING" &&
                        <Row>
                            <br/>
                            <BaseForm.Input colSpan="4" name="onetime_price" label={t('billable_item_pricing.price')} type="number" step="0.01" required min="0"/>
                            <BaseForm.Input colSpan="4" name="currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} showSearch={false} required />
                        </Row>
                }
                {
                    !isEditing && recurring === "ONETIME_PRICING_WITH_CREDITS" &&
                        <>
                        <Row>
                            <br/>
                            <BaseForm.Input colSpan="4" name="onetime_price" label={t('billable_item_pricing.price')} type="number" step="0.01" required min="0"/>
                            <BaseForm.Input colSpan="4" name="currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} showSearch={false} required/>
                        </Row>
                        <p className="mt-2 font-semibold text-sm">Credit Details</p>
                        <span className="text-sm">Enter the details about the credits. These credits will be applied to the customer when purchased.</span>
                        <Row className="mt-2">
                            <BaseForm.Input colSpan="4" name="credit_type" label={t('credits.type')} type="select" options={creditTypeOptions} showSearch={false} />
                            {
                                creditType === "AMOUNT" &&
                                <>
                                    <BaseForm.Input colSpan="4" name="credit_value" label={t('credits.amount')} type="number" step="0.01" min="0.01" required />
                                    <BaseForm.Input colSpan="4" name="credit_currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} required />
                                </>
                            }
                            {
                                creditType === "UNITS" &&
                                <>
                                    <BaseForm.Input 
                                        colSpan="4" 
                                        name="credit_units" 
                                        label={t('credits.units')} 
                                        type="number" 
                                        step="0.01" 
                                        required 
                                        disabled={!billableItemOptions.length}
                                        min="0.01"
                                    />
                                    <BaseForm.Input 
                                        colSpan="4" 
                                        name="credit_item_id" 
                                        label={t('credits.item')} 
                                        type="select"
                                        options={billableItemOptions} 
                                        required 
                                        showSearch={false} 
                                        disabled={!billableItemOptions.length}
                                    />
                                    {!billableItemOptions.length && (
                                        <span className="body1 error">
                                            In order to create a pricing with credit type units, we need to
                                            create a billable item first.{" "}
                                            <Link href={getCompanySpecificUrl("/billable_items")}>
                                                Get started here.
                                            </Link>
                                        </span>
                                    )}
                                </>
                            }
                        </Row>
                        </>
                }
                {
                    !isEditing && recurring === "SUBS_PRICING" && !loadingItemPricings &&
                        <>
                            <ItemPricingFields
                                ref={fieldsRef} setInitialFields={setInitialFields} initialFields={initialFields}
                                shouldSelectMetric
                                itemToEdit={props.pricingToEdit} onCreateBillableItem={props.onCreateBillableItem}
                            />
                        </>
                }
                {
                    error &&
                        <p className="form-error-message">{error}</p>
                }
            </BaseAddOrEditItemModal>
            <AddOrEditBillableItemPricingModal
                show={showAddOrEditBillableItemPricingModal}
                formClassName={"ml-4"}
                onClose={onModalClose}
                billableItems={billableItems}
                onAdded={onItemPricingCreated}
                onCreateBillableItem={props.onCreateBillableItem}
            />
        </>
    );
}

export default AddOrEditProductPricingModal;
