import '../../../App.scss';
import React, {useState} from 'react';
import Loader from '../../../components/Loader';
import BaseForm from '../../../components/BaseForm';
import {BaseContext, getSetupIncompleteIntegrationForIntegration, IntegrationContext} from '../../../helpers/common';
import { useEffect, useContext } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import {notifyEvent, serverFetch, serverPatch} from "../../../helpers/server";
import SubmitButton from "../../../components/common/buttons/SubmitButton";
import TaxCalcProductIDMappingComponent from "../../../components/TaxCalcProductIDMappingComponent";
import Label from "../../../components/Label";
import {getIntegrationInterfaceForKey} from "../../../helpers/integrations";
import AccountingTaxRatesMappingComponent from "../../../components/AccountingTaxRatesMappingComponent";
import IntegrationFieldMapComponent from "../../../components/IntegrationFieldMapComponent";
const _ = require('lodash');

function RequiredData(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { setShowNext, setShowProgress, setShowPrev } = useOutletContext();
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    const [firstRequiredData, setFirstRequiredData] = useState(null);
    const [allRequiredData, setAllRequiredData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const integrationInterface = getIntegrationInterfaceForKey(integration.key);

    useEffect(() => {
        setShowProgress(false);
        setShowNext(false);
        setShowPrev(false);
    }, []);

    useEffect(() => {
        checkIntegrationStatus();
    }, [integration]);

    const checkIntegrationStatus = () => {
        if (integration) {
            if (integration.status === "ACTIVE") {
                window.location.href = getIntegrationSpecificUrl("/settings");
            } else if (integration.status === "SETUP_INCOMPLETE") {
                serverFetch(getApiUrl(`/integrations/${integration.id}/get_required_data`), { skipCache: true }).then((res) => {
                    if (res) {
                        setAllRequiredData(res);
                        if (!_.isEmpty(res)) {
                            setFirstRequiredData(res[0]);
                        } else {
                            setFirstRequiredData(null);
                        }
                    }
                })
            }
        }
    }

    useEffect(() => {
        if (!firstRequiredData) {
            return;
        }
        setLoading(true);

        // Inline data input
        const inlineData = ["SELECT_ORG", "SELECT_ACCOUNTING_ACCOUNT", "MAP_PRODUCT_TAX_CODES"];
        if (!_.includes(inlineData, firstRequiredData)) {
            window.location.href = getIntegrationSpecificUrl("/settings");
        }

        if (firstRequiredData === "SELECT_ORG") {
            serverFetch(getApiUrl(`/integrations/${integration.id}/organizations`), { skipCache: true }).then((res) => {
                if (res) {
                    setOrganizations(res.organizations);
                    setLoading(false);
                }
            })
        } else if (firstRequiredData === "SELECT_ACCOUNTING_ACCOUNT" || firstRequiredData === "SELECT_ACCOUNTING_TRANSACTION_FEE_ACCOUNT") {
            serverFetch(getApiUrl(`/integrations/${integration.id}/accounting/accounts`), { skipCache: true }).then((res) => {
                if (res) {
                    setAccounts(res.accounts);
                    setLoading(false);
                }
            })
        } else if (firstRequiredData === "MAP_PRODUCT_TAX_CODES") {
            setLoading(false);
        } else if (firstRequiredData === "SELECT_ACCOUNTING_BANK_ACCOUNT" ) {
            serverFetch(getApiUrl(`/integrations/${integration.id}/accounting/bank_accounts`), { skipCache: true }).then((res) => {
                if (res) {
                    setBankAccounts(res.accounts);
                    setLoading(false);
                }
            })
        } else if (firstRequiredData === "MAP_ACCOUNTING_TAX_RATES") {
            setLoading(false);
        } else if (firstRequiredData === "FEATURE_FIELD_MAPPING") {
            setLoading(false);
        }
    }, [firstRequiredData]);

    const onUpdate = () => {
        notifyEvent('integration_settings');
        checkIntegrationStatus();
    }

    const onAccountingAccountSelect = (data) => {
        const updateData = {
            feature_data: {
                accounting_data: {
                    selected_account: data.account,
                }
            }
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            notifyEvent('integration_settings');
            checkIntegrationStatus();
        })
    }

    const onAccountingTransactionFeeAccountSelect = (data) => {
        const updateData = {
            feature_data: {
                accounting_data: {
                    transaction_fee_account: data.account,
                }
            }
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            notifyEvent('integration_settings');
            checkIntegrationStatus();
        })
    }

    const accountingAccountOptions = _.map(accounts, (acc) => {
        return {
            value: acc.id,
            label: acc.name
        }
    });

    const onAccountingBankAccountSelect = (data) => {
        const updateData = {
            feature_data: {
                accounting_data: {
                    selected_bank_account: data.selected_bank_account,
                }
            }
        }
        serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
            notifyEvent('integration_settings');
            checkIntegrationStatus();
        })
    }

    const bankAccountOptions = _.map(bankAccounts, (acc) => {
        return {
            value: acc.id,
            label: acc.name
        }
    });

    const onOrganizationSelect = (data) => {
        const organization = _.find(organizations, (org) => String(org.id) === String(data.organization));
        if (organization) {
            const updateData = {
                connection_data: {
                    org_id: organization.id,
                    org_name: organization.name
                }
            }
            serverPatch(getApiUrl(`/integrations/${integration.id}`), updateData).then(res => {
                notifyEvent('integration_settings');
                checkIntegrationStatus();
            })
        }
    }

    const organizationOptions = _.map(organizations, (org) => {
        return {
            value: org.id,
            label: org.name
        }
    });

    const onUpdateFieldMapping = () => {
        notifyEvent('integration_settings');
        checkIntegrationStatus();
    }

    return (
		<div className='flex flex-col justify-center items-center w-full h-full'>
			<Loader loading={loading}>
				<div className='w-full mt-4 max-w-2xl mx-auto'>
					<div className='flex flex-row gap-3 items-center'>
						<div className='grow-0 shrink-0'>
							<img src={integration.icon} alt='Integration Logo' className='h-20 w-20 rounded-md' />
						</div>
						<div className='flex flex-col gap-1 grow'>
							<h2>{integration.name}</h2>
						</div>
						<div className='self-center'>
							{integration.live_mode ? (
								<Label.Success>Live</Label.Success>
							) : (
								<Label.Warning>Test</Label.Warning>
							)}
						</div>
					</div>
					{firstRequiredData === 'SELECT_ORG' && (
						<div className='mt-4'>
							<p className='text-base font-semibold text-gray-700'>Select an organization</p>
							<p className='text-sm font-normal text-gray-500'>
								Your oauth connection allows us access to multiple organizations. Please select the
								organization you want to associate this account with.
							</p>
							<BaseForm className='mt-4' onSubmit={onOrganizationSelect}>
								<BaseForm.Input
									name='organization'
									label='Organization'
									type='select'
									options={organizationOptions}
									showSearch={false}
								/>
								<SubmitButton>Select</SubmitButton>
							</BaseForm>
						</div>
					)}
					{firstRequiredData === 'SELECT_ACCOUNTING_ACCOUNT' && (
						<div className='mt-4'>
							<p className='text-base font-semibold text-gray-700'>Select an account</p>
							<BaseForm className='mt-4' onSubmit={onAccountingAccountSelect}>
								<BaseForm.Input
									name='account'
									label='Account'
									type='select'
									options={accountingAccountOptions}
									showSearch={false}
								/>
								<SubmitButton>Select</SubmitButton>
							</BaseForm>
						</div>
					)}
					{firstRequiredData === 'SELECT_ACCOUNTING_TRANSACTION_FEE_ACCOUNT' && (
						<div className='mt-4'>
							<p className='text-base font-semibold text-gray-700'>
								Select an expense account which tracks your transaction charges (e.g. Stripe fees).
								<br />A Journal Entry will be created for these fees in the expense account
							</p>
							<BaseForm className='mt-4' onSubmit={onAccountingTransactionFeeAccountSelect}>
								<BaseForm.Input
									name='transaction_fee_account'
									label='Transaction Fee Account'
									type='select'
									options={accountingAccountOptions}
									showSearch={false}
								/>
								<SubmitButton>Select</SubmitButton>
							</BaseForm>
						</div>
					)}
					{firstRequiredData === 'SELECT_ACCOUNTING_BANK_ACCOUNT' && (
						<div className='mt-4'>
							<p className='text-base font-semibold text-gray-700'>Select a bank account</p>
							<BaseForm className='mt-4' onSubmit={onAccountingBankAccountSelect}>
								<BaseForm.Input
									name='selected_bank_account'
									label='Bank Account'
									type='select'
									options={bankAccountOptions}
									showSearch={false}
								/>
								<SubmitButton>Select</SubmitButton>
							</BaseForm>
						</div>
					)}
					{firstRequiredData === 'MAP_PRODUCT_TAX_CODES' && (
						<div className='max-w-2xl mx-auto mt-4'>
							<TaxCalcProductIDMappingComponent onUpdate={onUpdate} />
						</div>
					)}
					{firstRequiredData === 'MAP_ACCOUNTING_TAX_RATES' && <AccountingTaxRatesMappingComponent />}
					{firstRequiredData === 'FEATURE_FIELD_MAPPING' && (
						<div className='mt-4'>
							<p className='text-base font-semibold text-gray-700'>
								Map {integration.name} fields to Maple fields
							</p>
							<IntegrationFieldMapComponent
								className='mt-3'
								feature={'ACCOUNTING'}
								onUpdate={onUpdateFieldMapping}
								bulkEditing={true}
							/>
						</div>
					)}
				</div>
			</Loader>
		</div>
	);
}

export default RequiredData;
