import '../../../App.scss';
import '../../../js/forms.js';
import React, {useEffect, useMemo, useState, useCallback, useRef} from 'react';
import { createRoot } from 'react-dom/client';
import moment from "moment/moment";
import $ from "jquery";
import Button from '../buttons/Button.js';
import useCustomDateRanges from './useCustomDateRanges.js';
import TimeField from './TimeField.js';
const _ = require("lodash");

function DateRangePicker(props) {
    const { defaultValue, drop, onDatesChange, iconOnLeft, maxDate, includeTime, single, minDate, displayDateFormat, dateRangeLabelDefault, isDisabled, showDropdowns } = props;
    const [clearable, setClearable] = useState(true);
    const [dateRangeLabel, setDateRangeLabel] = useState(dateRangeLabelDefault ?? "All");
    const randomId = useMemo(() => Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5), []);
    const customRanges = useCustomDateRanges(props.customRanges);

    const presetRangeClicked = useRef(false);
    // issue where "Today"'s date ends at the start of the day. Workaround has been added to set the default end time. Investigate later why end time starts at the start of the day.
    const time = useRef({
        startHour: props.defaultValue?.start ? moment(props.defaultValue.start).hour() : 0,
        startMinute: props.defaultValue?.start ? moment(props.defaultValue.start).minute() : 0,
        startPeriod: props.defaultValue?.start ? moment(props.defaultValue.start).hour() > 12 ? 'pm' : 'am' : 'am',
        endHour: props.defaultValue?.end ? moment(props.defaultValue.end).hour() : 11,
        endMinute: props.defaultValue?.end ? moment(props.defaultValue.end).minute() : 59,
        endPeriod: props.defaultValue?.end ? moment(props.defaultValue.end).hour() > 12 ? 'pm' : 'am' : 'pm',
    });

    useEffect(() => {
        time.current = {
            startHour: props.defaultValue?.start ? moment(props.defaultValue.start).hour() : 0,
            startMinute: props.defaultValue?.start ? moment(props.defaultValue.start).minute() : 0,
            startPeriod: props.defaultValue?.start ? moment(props.defaultValue.start).hour() > 12 ? 'pm' : 'am' : 'am',
            endHour: props.defaultValue?.end ? moment(props.defaultValue.end).hour() : 11,
            endMinute: props.defaultValue?.end ? moment(props.defaultValue.end).minute() : 59,
            endPeriod: props.defaultValue?.end ? moment(props.defaultValue.end).hour() > 12 ? 'pm' : 'am' : 'pm',
        }
    }, [props.defaultValue?.start, props.defaultValue?.end]);

    const getDateRangePickerFromInstance = useCallback((calendarButtonElement) => {
        return calendarButtonElement.data('daterangepicker');  
    }, []);

    const handleTimeChange = useCallback((value, name) => {
        if (time.current && name in time.current) {
            time.current[name] = value;
        }
    }, [time]);

    const getCalendarButtonElement = useCallback(() => {
        const name = '#' + randomId + "-date-range-calendar"
        return $(name);
    }, [randomId]);

    const updateTime = useCallback((calendarButtonElement) => {
        const update = () => {
            const calendarButtonElement = getCalendarButtonElement();
            const dateRangePicker = getDateRangePickerFromInstance(calendarButtonElement);

            let s = null;
            if (dateRangePicker?.['startDate']) {
                s = dateRangePicker?.['startDate'];
                let addHours = 0;
                if (time.current.startHour !== 12 && time.current.startPeriod === 'pm') {
                    addHours = 12;
                } else if (time.current.startHour === 12 && time.current.startPeriod === 'am') {
                    addHours = -12;
                }
                if (!presetRangeClicked.current) {
                    const isSameTime = s.hour() === (time.current.startHour + addHours) && s.minute() === time.current.startMinute;
                    if (!includeTime) {
                        s.startOf('day');
                    } else if (!isSameTime) {
                        s.hour(time.current.startHour + addHours).minute(time.current.startMinute).seconds(0).milliseconds(0);
                    }
                } else {
                    s = customRanges?.[dateRangePicker?.chosenLabel][0];
                }
            }

            let e = null;
            if (dateRangePicker?.['endDate']) {
                e = dateRangePicker?.['endDate'];
                let addHours = 0;
                if (time.current.endHour !== 12 && time.current.endPeriod === 'pm') {
                    addHours = 12;
                } else if (time.current.endHour === 12 && time.current.endPeriod === 'am') {
                    addHours = -12;
                }
                if (!presetRangeClicked.current) {
                    const isSameTime = e.hour() === (time.current.endHour + addHours) && e.minute() === time.current.endMinute;
                    if (!includeTime) {
                        e.endOf('day');
                    } else if (!isSameTime) {
                        e.hour(time.current.endHour + addHours).minute(time.current.endMinute).seconds(0).milliseconds(0);
                    }
                } else {
                    e = customRanges?.[dateRangePicker?.chosenLabel][1];
                }
            }

            if (s || e) {
                const dateFormat = displayDateFormat || 'MMM D';
                if (dateRangePicker.chosenLabel === undefined) {
                    if (single) {
                        setDateRangeLabel(s.format(dateFormat));
                    } else {
                        setDateRangeLabel(s.format(dateFormat) + " - " + e.format(dateFormat));
                    }
                }
                onDatesChange(s, e, dateRangePicker.chosenLabel);
            }
        }
        calendarButtonElement.on('apply.daterangepicker', () => {
            update();
        });
        calendarButtonElement.on('show.daterangepicker', () => {
            presetRangeClicked.current = false;
        });
    }, [time, getDateRangePickerFromInstance, getCalendarButtonElement, customRanges, onDatesChange, single, displayDateFormat, includeTime]);

    useEffect(() => {
        if (!_.isNil(props.clearable)) {
            setClearable(props.clearable);
        }
    }, [props.clearable]);

    useEffect(() => {
        if (props.defaultValue) {
            const start = props.defaultValue.start && moment(props.defaultValue.start);
            const end = props.defaultValue.end && moment(props.defaultValue.end);
            if (single) {
                if (start) {
                    let newLabel = start.format(displayDateFormat ?? 'MMM D');
                    setDateRangeLabel(newLabel);
                }
            } else {
                if (start && end) {
                    let newLabel = dateRangeLabelDefault ?? 'All';
                    _.each(customRanges, (range, label) => {
                        if (range[0].isSame(start, 'day') && range[1].isSame(end, 'day')) {
                            newLabel = label;
                        }
                    })
                    if (newLabel === (dateRangeLabelDefault ?? 'All')) {
                        newLabel = start.format('MMM D') + " - " + end.format('MMM D')
                    }
                    setDateRangeLabel(newLabel);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue]);

    const cleanUpDatePicker = useCallback(() => {
        const picker = $(`#${randomId}-daterangepicker`);
        picker.remove();
    }, [randomId]);

    const setDatePickerId = useCallback((calendarButtonElement) => {
        const dateRangePicker = getDateRangePickerFromInstance(calendarButtonElement);
        const pickerId = dateRangePicker.container.attr('id');
        if (pickerId || _.isNil(randomId)) return;

        dateRangePicker.container.attr('id', `${randomId}-daterangepicker`);
    }, [randomId, getDateRangePickerFromInstance]);

    const setDatePickerPosition = useCallback((calendarButtonElement) => {
        if (drop === 'auto') {
            const calendarButtonElementHeight = calendarButtonElement.height();

            const adjustPosition = (elementHeight) => {
                const picker = $(`#${randomId}-daterangepicker`);
                const offset = calendarButtonElement.offset();
                const windowHeight = $(window).height();
    
                // calculate available space
                const spaceBelow = windowHeight - offset.top - calendarButtonElementHeight;
                const spaceAbove = offset.top - calendarButtonElementHeight;

                if (spaceAbove >= elementHeight && spaceBelow <= elementHeight) {
                    // put date picker above
                    const extraPadding = elementHeight >= spaceAbove ? elementHeight - spaceAbove : 0;
                    picker.css({
                        top: offset.top - elementHeight - 20 + extraPadding + 'px',
                        bottom: 'auto',
                    });
                } else {
                    // put date picker below
                    const extraPadding = elementHeight >= spaceBelow ? 20 + elementHeight - spaceBelow : 0;
                    picker.css({
                        top: offset.top + 30 - extraPadding + 'px',
                        bottom: 'auto',
                    });
                    return;
                }
            }

            let calendarTriggered = false;
            let previousCalendarHeight = 0;
            calendarButtonElement.on('showCalendar.daterangepicker', (_e, picker) => {
                if (!picker) {
                    adjustPosition(previousCalendarHeight);
                    return;
                }
                const calendarElementHeight = picker.container[0].offsetHeight;
                adjustPosition(calendarElementHeight);
                previousCalendarHeight = calendarElementHeight;
                calendarTriggered = true;
            });
            calendarButtonElement.on('show.daterangepicker', (_e, picker) => {
                const calendarElementHeight = picker.container[0].offsetHeight;
                previousCalendarHeight = calendarElementHeight;
                if (!calendarTriggered) {
                    adjustPosition(calendarElementHeight);
                } else {
                    calendarButtonElement.trigger('showCalendar.daterangepicker');
                }
            });
            calendarButtonElement.on('hide.daterangepicker', () => {
                calendarTriggered = false;
            });
        }
    }, [drop, randomId]);

    const getElementToAppend = useCallback((elementToRender) => {
        const element = document.createElement('div');
        element.id = `${randomId}-appended-element-container`;
        const root = createRoot(element);
        root.render(elementToRender);
        return element;
    }, [randomId]);

    /**
     * Create and append the time field to the DOM
     * 
     * @param calendar The calendar to append the time field to. "left" or "right".
     * @param calendarButtonElement The calendar button element.
     * @param element The DOM element to append
     * @returns void
     */
    const appendTimeField = useCallback((calendar, calendarButtonElement, element) => {
        const dateRangePicker = getDateRangePickerFromInstance(calendarButtonElement);
        const cal = dateRangePicker.container.find(`.drp-calendar.${calendar}`);

        // remove the default time field
        cal.find('.calendar-time').css({
            display: 'none',
        });

        const timeFieldId = `${randomId}-${calendar}-time-field`;
        let timeFieldElement = dateRangePicker.container.find(`#${timeFieldId}`).get(0);
        if (!timeFieldElement) {
            cal.append(`<div id="${timeFieldId}"></div>`);
            timeFieldElement = dateRangePicker.container.find(`#${timeFieldId}`).get(0);
        }

        if (timeFieldElement && !timeFieldElement.hasChildNodes()) {
            // append the created DOM element
            timeFieldElement.appendChild(
                calendar === 'left' 
                    ? getElementToAppend(
                        <TimeField
                            name='start'
                            defaultHour={time.current.startHour}
                            defaultMinute={time.current.startMinute}
                            defaultPeriod={time.current.startPeriod}
                            onChangeHour={handleTimeChange}
                            onChangeMinute={handleTimeChange}
                            onChangePeriod={handleTimeChange}
                        />
                    ) : getElementToAppend(
                        <TimeField
                            name='end'
                            defaultHour={time.current.endHour}
                            defaultMinute={time.current.endMinute}
                            defaultPeriod={time.current.endPeriod}
                            onChangeHour={handleTimeChange}
                            onChangeMinute={handleTimeChange}
                            onChangePeriod={handleTimeChange}
                        />
                    )
            );
        }
    }, [getDateRangePickerFromInstance, getElementToAppend, handleTimeChange, time, randomId]);

    const renderTimeField = useCallback((calendarButtonElement) => {
        // create the DOM element
        appendTimeField('left', calendarButtonElement);
        appendTimeField('right', calendarButtonElement);
    }, [appendTimeField]);

    const updateCalendarStyles = useCallback((calendarButtonElement) => {
        const dateRangePicker = getDateRangePickerFromInstance(calendarButtonElement);
        const leftCal = dateRangePicker.container.find(`.drp-calendar.left`).get(0);
        if (!leftCal) return;

        const updateDayStyles = (dateRangePicker) => {
            const startDateData = dateRangePicker.container.find('td.start-date:not(.off):not(.ends)');
            const endDateData = dateRangePicker.container.find('td.end-date:not(.off):not(.ends)');
            const startDateOffCalData = dateRangePicker.container.find('td.start-date.off.ends');
            const endDateOffCalData = dateRangePicker.container.find('td.end-date.off.ends');
            const inRangeData = dateRangePicker.container.find('td.in-range.available:not(.off):not(.ends):not(.start-date):not(.end-date)');
            const availableData = dateRangePicker.container.find('td.available');
            const notAvailableData = dateRangePicker.container.find('td.off.disabled');
            const applyButton = dateRangePicker.container.find('.applyBtn');
            const cancelButton = dateRangePicker.container.find('.cancelBtn');
            const selectedDates = dateRangePicker.container.find('.drp-selected');

            startDateOffCalData.addClass('hover:!bg-[#eee] hover:!text-dark-gray');
            endDateOffCalData.addClass('hover:!bg-[#eee] hover:!text-dark-gray');
            inRangeData.addClass('!bg-gray1');
            startDateData.addClass('!bg-primary-updated !text-white !rounded-full');
            endDateData.addClass('!bg-primary-updated !text-white !rounded-full');
            availableData.addClass('date-day');
            notAvailableData.addClass('date-day');
            applyButton.addClass('!px-[14px] !py-2 !text-sm');
            cancelButton.addClass('!px-[14px] !py-2 !text-sm');
            selectedDates.addClass('!text-sm');
            applyButton.removeClass('btn-sm');
            cancelButton.removeClass('btn-sm !px-[14px] !py-2');
        };
        const updateTableHeaderStyles = (dateRangePicker) => {
            const month = dateRangePicker.container.find('.month');
            const headerRows = dateRangePicker.container.find('th');
            const headerWithSelect = dateRangePicker.container.find('.month:has(.monthselect)');
            const monthSelect = dateRangePicker.container.find('.monthselect');
            const yearSelect = dateRangePicker.container.find('.yearselect');

            month.addClass('!text-sm');
            headerRows.addClass('date-day');
            headerWithSelect.attr('style', 'height: 44px !important');
            monthSelect.addClass('!rounded-md border-gray3 !pl-2 !w-[48%]');
            yearSelect.addClass('!rounded-md border-gray3 !pl-2 !w-[48%]');
        };
        const updateCalendarPaddings = (dateRangePicker) => {
            const leftCal = dateRangePicker.container.find(`.drp-calendar.left`); 
            const calTable = leftCal.find('.calendar-table');
            
            leftCal.addClass('!pl-3 !pr-2');
            calTable.addClass('!pr-0');
        };

        const updateVisibility = () => {
            const picker = $(`#${randomId}-daterangepicker`);
            if (isDisabled) {
                picker.css({
                    display: 'none',
                });
            }
        };

        const observeCalendarChanges = () => {
            const cal = dateRangePicker.container.find(`.drp-calendar.left`).get(0);
            if (!cal) return;

            const observer = new MutationObserver((mutationsList) => {
                const freshInstance = getCalendarButtonElement();
                const dateRangePicker = getDateRangePickerFromInstance(freshInstance);
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        updateDayStyles(dateRangePicker);
                        updateTableHeaderStyles(dateRangePicker);
                        updateCalendarPaddings(dateRangePicker);
                        updateVisibility();
                    }
                }
            });
            observer.observe(cal, { childList: true, subtree: true });

            return observer;
        };

        calendarButtonElement.on('showCalendar.daterangepicker', () => {
            const freshInstance = getCalendarButtonElement();
            const dateRangePicker = getDateRangePickerFromInstance(freshInstance);
            updateDayStyles(dateRangePicker);
            updateTableHeaderStyles(dateRangePicker);
            updateCalendarPaddings(dateRangePicker);

            observeCalendarChanges();
        });
        calendarButtonElement.on('show.daterangepicker', () => {
            updateVisibility();
        });
    }, [getDateRangePickerFromInstance, getCalendarButtonElement, randomId, isDisabled]);

    const initializeDateRangePicker = useCallback(() => {
        if (_.isNil(randomId)) return;
        
        const calendarButtonElement = getCalendarButtonElement();

        const startDate = (defaultValue && defaultValue.start) ? moment(defaultValue.start): undefined;
        const endDate = (defaultValue && defaultValue.end) ? moment(defaultValue.end): undefined;

        calendarButtonElement.daterangepicker({
            opens: iconOnLeft ? "right": "left",
            autoApply: true,
            startDate: startDate,
            singleDatePicker: single,
            endDate: endDate,
            minDate: minDate,
            maxDate: maxDate,
            timePicker: includeTime,
            ranges: customRanges,
            autoUpdateInput: false,
            showDropdowns: showDropdowns,
        }, function(start, end, label) {
            let s = start;
            let e = end;
            const dateFormat = displayDateFormat || 'MMM D';
            if (label === 'Custom Range' || label === undefined) {
                if (single) {
                    setDateRangeLabel(start.format(dateFormat));
                } else {
                    setDateRangeLabel(start.format(dateFormat) + " - " + end.format(dateFormat));
                }
            } else {
                setDateRangeLabel(label);
                s = customRanges[label][0];
                e = customRanges[label][1];
                presetRangeClicked.current = true;
            }
            if (onDatesChange) {
                onDatesChange(s, e, label);
            }
        });

        setDatePickerId(calendarButtonElement);

        includeTime && renderTimeField(calendarButtonElement);
        updateCalendarStyles(calendarButtonElement);
        updateTime(calendarButtonElement);

        setDatePickerPosition(calendarButtonElement);
    }, [customRanges, onDatesChange, iconOnLeft, maxDate, defaultValue, randomId, includeTime, single, minDate, displayDateFormat, getCalendarButtonElement, setDatePickerId, renderTimeField, updateCalendarStyles, updateTime, setDatePickerPosition]);

    useEffect(() => {
        initializeDateRangePicker();

        return () => {
            cleanUpDatePicker();
        };
    }, [initializeDateRangePicker, cleanUpDatePicker]);

    return (
        <div className="flex flex-row gap-2 px-1 w-full truncate">
            <div id={randomId + "-date-range-calendar"} className="flex flex-row gap-2 flex-shrink-0 items-center grow">
                {
                    props.iconOnLeft && <div className="calendar-icon"/>
                }
                <div className="grow">
                    <span className="body1 gray4">{ dateRangeLabel }</span>
                </div>
                {
                    !props.iconOnLeft && <div className="calendar-icon"/>
                }
            </div>
            {
                props.iconOnLeft && clearable &&
                <Button
                    variant="text" className="thin" size="sm"
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDateRangeLabel(dateRangeLabelDefault ?? "All")
                        if (props.onDatesChange) {
                            props.onDatesChange(null, null, dateRangeLabelDefault ?? "All");
                        }
                    }}>
                    <i className="fa fa-x gray3-color"/>
                </Button>
            }
        </div>
    );
}

export default DateRangePicker;
