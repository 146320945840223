import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import { serverPost } from '../../helpers/server';
import { BaseContext, currencyFormatFromPrice } from '../../helpers/common';
import InfiniteScroll from 'react-infinite-scroll-component';
import SortableTableHeader from '../../components/SortableTableHeader';
import moment from 'moment/moment';
import Columns from "../../components/Columns";
import SimpleModal from '../../components/modals/SimpleModal';
import CommissionEventLogs from './CommissionEventLogs';
import Label from "../../components/Label";
import Button from "../../components/common/buttons/Button";
import Link from "../../components/Link";
const _ = require('lodash');

function CommissionDeals(props) {
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(true);
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [hasMore, setHasMore] = useState(true);
    const [selectedDeal, setSelectedDeal] = useState(null);
    const [showEventsModal, setShowEventsModal] = useState(false);
    const [dealValueMap, setDealValueMap] = useState({});
    const { t } = useTranslation();

    const onSearch = () => {
        const params = {
            query: {
                report_period_ids: {
                    condition: "OR",
                    values: props.reportIDs
                }
            },
        };

        serverPost(getApiUrl('/commission/deal/find'), params).then((res) => {
            if (res) {
                const results = res || [];

                results.sort((a, b) => {
                    return  moment(b.most_recent_event_time) - moment(a.most_recent_event_time)
                })
                setDeals(results)
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        onSearch();
    }, [props.reportIDs]);

    const handleDealClick = (deal) => {
        setSelectedDeal(deal);
        setShowEventsModal(true);
    };

    const getLabelForCommissionDealStatus = (status) => {
        if (status === 'PAID') {
            return <Label.Success>Closed</Label.Success>
        } else if (status === 'CLAWED_BACK') {
            return <Label.Danger>Clawed Back</Label.Danger>
        }
    }

    return (
		<ContentContainer className={props.isComponent && 'bg-white'}>
			<Section loading={loading}>
				<MapleTable>
					<MapleTable.Content>
						<thead>
							<tr>
								<MapleTable.TH>Contract</MapleTable.TH>
								<MapleTable.TH>Updated Time</MapleTable.TH>
								<MapleTable.TH className='text-end'>Net Commissions This Report</MapleTable.TH>
								<MapleTable.TH className='text-end'>Quota Impact</MapleTable.TH>
								<MapleTable.TH>Sales Member</MapleTable.TH>
                                <MapleTable.TH></MapleTable.TH>
							</tr>
						</thead>
						<tbody className='divide-y divide-gray-200'>
							{deals.map((deal, i) => (
								<MapleTable.TR
									key={i}
									onClick={() => handleDealClick(deal)}
									className='cursor-pointer hover:bg-gray-50'
								>
									<td>{deal.proposal.title}</td>
									<td>{moment(deal.most_recent_event_time).format('MMM D, YYYY hh:mm')}</td>
									<td className='text-end'>{currencyFormatFromPrice(deal.filtered_paid_amount)}</td>
									<td className='text-end'>{currencyFormatFromPrice(deal.quota_impact)}</td>
									<td>
										<Columns.CompanyUserAvatar companyUser={deal.sales_user.company_user} />
									</td>
                                    {deal.status === 'CLAWED_BACK' && (
                                        <td>{getLabelForCommissionDealStatus(deal.status)}</td>
                                    )}
								</MapleTable.TR>
							))}
						</tbody>
					</MapleTable.Content>
				</MapleTable>
			</Section>
			<SimpleModal
				show={showEventsModal}
				onClose={() => {
					setShowEventsModal(false);
					setSelectedDeal(null);
				}}
				title={
					selectedDeal && (
						<span>
							Commission Events (
							<Link href={getCompanySpecificUrl(`/contract/view/${selectedDeal.proposal_id}`)}>
								{selectedDeal.proposal.title}
							</Link>
							)
						</span>
					)
				}
				size='xl'
				body={
					selectedDeal && (
						<div>
							<CommissionEventLogs isComponent reportIDs={props.reportIDs} dealId={selectedDeal.id} />
						</div>
					)
				}
				buttonTitle={'Dismiss'}
			/>
		</ContentContainer>
	);
}

export default CommissionDeals;