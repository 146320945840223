import BaseSideModal from "./BaseSideModal";
import {serverPost} from "../../helpers/server";
import Notification from "../Notification";
import React, {useContext, useEffect, useState} from "react";
import {BaseContext, getInvoicePaymentTermOptions} from "../../helpers/common";
import SubmitButton from "../common/buttons/SubmitButton";
import BaseForm from "../BaseForm";
import _ from "lodash";
import {Row} from "react-bootstrap";

function ChangeContractRequestPaymentModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [invoicePaymentTerms, setInvoicePaymentTerms] = useState(null);

    useEffect(() => {
        setInvoicePaymentTerms(null);
    }, [props.show]);

    const changeRequestPayment = async (data) => {
        const postData = {
            request_payment: false,
            invoice_due_date_from_creation: data.invoice_due_date_from_creation,
        }

        if (!_.isNil(data.invoice_payment_terms)) {
            if (data.invoice_payment_terms !== "custom") {
                postData.invoice_due_date_from_creation = parseInt(data.invoice_payment_terms);
            }
        }

        const res = await serverPost(getApiUrl(`/proposals/${props.proposalID}/change_request_payment`), postData)
        if (res) {
            Notification.Success("Successfully modified contract")
            if (props.onExecute) {
                props.onExecute()
            }
            if (props.onClose) {
                props.onClose();
            }
        } else {
            Notification.Danger("Could not change contract")
        }
    }

    const onFieldChange = (name, value) => {
        if (name === 'invoice_payment_terms') {
            setInvoicePaymentTerms(value)
        }
    }

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form onSubmit={changeRequestPayment} onFieldChange={onFieldChange}>
                <BaseSideModal.Header title={"Skip Payment Requirement"}/>
                <BaseSideModal.Body>
                    <div className={'text-sm'}>
                        <span>This change will move the contract forward without requiring the addition of a payment method.<br/><br/> The customer will be invoiced after the contract is executed.</span><br/><br/>
                        <span>Please select the invoice payment terms.</span>
                    </div><br/>
                    <Row>
                        <BaseForm.Input type="select" colSpan="6" name="invoice_payment_terms" label="Invoice Payment Terms" options={getInvoicePaymentTermOptions()} showSearch={false} required />
                        {
                            invoicePaymentTerms === "custom" &&
                            <BaseForm.Input type="number" colSpan="6" name="invoice_due_date_from_creation" label="Due Date (in days)" step="1" min="0" required />
                        }
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Confirm</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    )
}

export default ChangeContractRequestPaymentModal