import BaseSideModal from "./BaseSideModal";
import {Row} from "react-bootstrap";
import BaseForm from "../BaseForm";
import SubmitButton from "../common/buttons/SubmitButton";

function UploadContractAttachmentModal(props) {

    return (
        <BaseSideModal {...props} >
            <BaseSideModal.Form onSubmit={props.onSubmit}>
                <BaseSideModal.Header title={"Upload Document"}/>
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input colSpan={12} name="file" label="Document" type="file" fileType="contractAttachment" required accept={"image/*,application/pdf"} hidePreview={true}/>
                        <span className={"text-xs"}><strong>Note: </strong>Documents can only be attached to draft contracts. Documents cannot be attached to or removed from finalized contracts.</span>
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Upload</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default UploadContractAttachmentModal;