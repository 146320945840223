import '../App.scss';
import '../css/modals.scss';
import React from 'react';
import { useContext } from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
    BaseContext,
} from '../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from "./BaseContainer";
import ContentContainer from "./ContentContainer";
import ContentBox from "./ContentBox";
import BaseProductPricingCreateOrUpdate from "./BaseProductPricingCreateOrUpdate";
const _ = require('lodash');

function ProductPricingCreateOrUpdate(props) {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    let productId = searchParams.get('product');
    const { getCompanySpecificUrl } = useContext(BaseContext);

    const onProductPricingCreated = (productPricing) => {
        navigate(getCompanySpecificUrl(`/product/${productPricing.product_id}`))
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <ContentBox>
                    <ContentBox.Body>
                        <div className="text-xl mb-4">Create a Product Pricing</div>
                        <BaseProductPricingCreateOrUpdate
                            {...props}
                            productID={productId}
                            selectProduct={false}
                            onProductPricingCreated={onProductPricingCreated}
                        />
                    </ContentBox.Body>
                </ContentBox>
            </ContentContainer>
        </BaseContainer>
    );
}

export default ProductPricingCreateOrUpdate;
