import { useEffect } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import Button from './buttons/Button';
import classNames from 'classnames';

const Toggle = (props) => {
	// TODO: change onClick to onChange for accuracy.
	useEffect(() => {
		props.onClick && props.onClick(props.value, null);
	}, []);

	return (
		<ButtonGroup className={classNames(`flex flex-row border border-gray3 rounded-lg divide-x divide-x-gray3`, props.className)}>
			{props.toggleValues.map((value) => (
				<div key={value.value} className='overflow-hidden flex-1 first:rounded-l-[7px] last:rounded-r-[7px]'>
					<Button
						onClick={(e) => {
							props.onClick && props.onClick(value.value, e);
						}}
						className={classNames(
							'!text-gray5 bg-white !rounded-none !font-medium w-full',
							{ '!bg-primary1 !text-primary-updated-dark': props.value === value.value },
							props.value === value.value ? 'hover:!bg-primary1' : 'hover:!bg-gray0',
							props.buttonClassName
						)}
						hasIconOnly={props.removeButtonSpacing}
					>
						{value.label}
					</Button>
				</div>
			))}
		</ButtonGroup>
	);
};

export default Toggle;
