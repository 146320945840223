import $ from "jquery";
import moment from 'moment';
import React, { createContext } from 'react';
import Label from '../components/Label';
import Link from "../components/Link";
const _ = require("lodash");

export function renderStatusLabel(invoice, getCompanySpecificUrl) {
    if (invoice.status === "DRAFT") {
        return (<Label.Info>Draft</Label.Info>);
    } else if (invoice.status === "PENDING") {
        if (moment(invoice.due_date).isBefore(moment())) {
            return (<Label.Danger>Overdue</Label.Danger>);
        } else {
            return (<Label.Info>Pending</Label.Info>);
        }
    } else if (invoice.status === "PAID") {
        return (<Label.Success>Paid</Label.Success>);
    } else if (invoice.status === "PAYMENT_PROCESSING") {
        return (<Label.Warning>Payment Processing</Label.Warning>);
    } else if (invoice.status === "VOID") {
        return (<Label.Info>Void</Label.Info>);
    } else if (invoice.status === "ROLLUP_PENDING") {
        return (
            <div className="flex flex-row gap-2">
                <Label.Info>Rollup Pending</Label.Info>
                <Link href={getCompanySpecificUrl(`/customer/${invoice.billed_customer_id}/next_rollup_invoice/${_.toLower(invoice.currency)}`)}>View estimate</Link>
            </div>
        );
    } else if (invoice.status === "ROLLUP_COMPLETE") {
        return (
            <div className="flex flex-row gap-2">
                <Label.Info>Rolled up</Label.Info>
                <Link href={getCompanySpecificUrl(`/invoice/${invoice.parent_invoice_uuid}`)}>View invoice</Link>
            </div>
        );
    }
    return (
        <Label.Info>{invoice.status}</Label.Info>
    )
}

export function getShareLinkUrl(invoice, getCompanySpecificUrl) {
    const relativeUrl = getCompanySpecificUrl(`/invoice/view?uuid=${invoice.uuid}`);
    const baseUrl = process.env.REACT_APP_BASE_WEB_URL || "https://app.maplebilling.com";
    return `${baseUrl}${relativeUrl}`
}

const creditNoteReasonOptions = [
    { value: null, label: "Select a reason" },
    { value: "ORDER_CHANGE", label: "Order Change" },
    { value: "DUPLICATE", label: "Duplicate" },
    { value: "FRAUDULENT", label: "Fraudulent" },
    { value: "UNSATISFACTORY", label: "Product Unsatisfactory" },
    { value: "OTHER", label: "Other" },
]
export function getCreditNoteReasonOptions() {
    return creditNoteReasonOptions;
}

export function getCreditNoteReasonDescription(reason) {
    const existingReason = _.find(creditNoteReasonOptions, (op) => op.value === reason);
    if (existingReason) {
        return existingReason.label;
    } else {
        return reason
    }
}

const creditNoteRefundTypeOptions = [
    { value: "CUSTOMER_CREDIT", label: "Credit customer's account" },
    { value: "ISSUE_NEW_REFUND", label: "Issue refund" },
    { value: "ATTACH_REFUND_TRANSACTION", label: "Attach to existing refund" },
]
export function getCreditNoteRefundTypeOptions() {
    return creditNoteRefundTypeOptions;
}

export function getCreditNoteRefundTypeDescription(reason) {
    const existingReason = _.find(creditNoteRefundTypeOptions, (op) => op.value === reason);
    if (existingReason) {
        return existingReason.label;
    } else {
        return reason
    }
}

export class InvoiceManager {
    constructor(invoiceDetails, isAdmin, isMapleUser) {
        this.invoiceDetails = invoiceDetails;
        this.isAdmin = isAdmin;
        this.isMapleUser = isMapleUser;
    }

    isImportedInvoice() {
        return this.invoiceDetails && !_.isNil(this.invoiceDetails.imported_from);
    }

    isGenerated() {
        return this.invoiceDetails.id !== "";
    }

    isPaid() {
        return !_.isEmpty(this.invoiceDetails.due) &&
            this.invoiceDetails.due.value_in_cents === 0;
    }

    shouldShowPaymentDueAmount() {
        return !_.isEmpty(this.invoiceDetails.due) &&
            this.isGenerated() &&
            this.invoiceDetails.due.value_in_cents > 0 &&
            !_.includes(["PAID", "VOID"], this.invoiceDetails.status);
    }

    isOverdue() {
        return this.isGenerated() &&
            !this.isPaid() &&
            this.invoiceDetails.due_date &&
            moment(this.invoiceDetails.due_date).isBefore(moment());
    }

    canEdit() {
        return this.isGenerated() &&
            this.isAdmin &&
            !this.isImportedInvoice() &&
            _.includes(["PENDING", "DRAFT"], this.invoiceDetails.status);
    }

    canIssueCreditNote() {
        if (_.isEmpty(this.invoiceDetails)) {
            return false;
        }
        let canIssue = this.isGenerated() &&
            this.isAdmin &&
            !this.isImportedInvoice() &&
            !_.includes(["DRAFT", "VOID"], this.invoiceDetails.status);
        if (canIssue) {
            // Check if there is any creditable amount left
            let totalCreditedValueInCents = 0
            _.each(this.invoiceDetails.pre_payment_credit_notes, n => {
                totalCreditedValueInCents += n.total.value_in_cents
            })
            _.each(this.invoiceDetails.post_payment_credit_notes, n => {
                totalCreditedValueInCents += n.total.value_in_cents
            })
            canIssue = (this.invoiceDetails.total.value_in_cents > totalCreditedValueInCents);
        }
        return canIssue;
    }

    canAdminAddPayment() {
        return this.isGenerated() &&
            this.isAdmin &&
            !this.isImportedInvoice() &&
            this.invoiceDetails.status === "PENDING"
    }

    canCustomerAddPayment() {
        return this.isGenerated() &&
            !this.isAdmin &&
            !this.isImportedInvoice() &&
            !this.isPaid() &&
            _.includes(["PENDING"], this.invoiceDetails.status);
    }

    canVoid() {
        return this.isGenerated() &&
            this.isAdmin &&
            !this.isImportedInvoice() &&
            !this.isPaid() &&
            _.includes(["PENDING", "DRAFT"], this.invoiceDetails.status) &&
            this.invoiceDetails.payments == null
    }

    canFinalize() {
        return this.isGenerated() &&
            this.isAdmin &&
            !this.isImportedInvoice() &&
            _.includes(["DRAFT"], this.invoiceDetails.status);
    }

    canViewReferences() {
        return this.isGenerated() && this.isMapleUser;
    }

    canMarkAsPaid() {
        return this.isGenerated() &&
            this.isAdmin &&
            !this.isImportedInvoice() &&
            !this.isPaid() &&
            _.includes(["PENDING"], this.invoiceDetails.status)
    }

    canShare() {
        return this.isGenerated();
    }

    canForceGenerate() {
        return !_.isNil(this.invoiceDetails.subscription_id) && !this.isGenerated() && !this.isImportedInvoice();
    }
}
