import '../App.scss';
import '../css/modals.scss';
import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
const _ = require('lodash');

const SquareSetupPaymentMethod = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data) {
            await onSubmit(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        },
        async getPaymentMethodData(data) {
            return getPaymentMethodData(data);
        },
    }));

    const resolveRef = useRef(null);

    const getPaymentMethodData = async (data) => {
        return new Promise(function(resolve, reject) {
            const payButton = document.getElementById("rswp-card-button");
            if (!payButton) {

            } else {
                try {
                    console.log("Before click")
                    payButton.click();
                    console.log("After click")
                } catch (error) {
                    console.log("I got an error " + error);
                }

                resolveRef.current = resolve;
            }
        });
    }

    const onFieldChange = (name, value) => {}

    const onSubmit = async (data) => {

    }

    if (!props.paymentIntent) {
        return;
    }

    return (
        <div className="pt-1">
            <PaymentForm
                /**
                 * Identifies the calling form with a verified application ID generated from
                 * the Square Application Dashboard.
                 */
                applicationId={props.paymentIntent.square.account_id}
                /**
                 * Invoked when payment form receives the result of a tokenize generation
                 * request. The result will be a valid credit card or wallet token, or an error.
                 */
                cardTokenizeResponseReceived={(token, buyer) => {
                    console.log("The result is " + token + ", " + buyer);
                    if (resolveRef.current) {
                        resolveRef.current({
                            paymentMethodData: {
                                integration_id: props.paymentIntent.integration_id,
                                payment_provider_key: "square",
                                square: {
                                    token: token.token,
                                    verification_token: buyer.token,
                                    card: {
                                        brand: token.details.card.brand,
                                        exp_month: token.details.card.expMonth,
                                        exp_year: token.details.card.expYear,
                                        last_4: token.details.card.last4,
                                    }
                                },
                            },
                            error: null
                        });
                    }
                }}
                /**
                 * This function enable the Strong Customer Authentication (SCA) flow
                 *
                 * We strongly recommend use this function to verify the buyer and reduce
                 * the chance of fraudulent transactions.
                 */
                createVerificationDetails={() => ({
                    amount: '1.00',
                    /* collected from the buyer */
                    billingContact: {
                        addressLines: ['123 Main Street', 'Apartment 1'],
                        familyName: 'Doe',
                        givenName: 'John',
                        countryCode: 'GB',
                        city: 'London',
                    },
                    currencyCode: 'GBP',
                    intent: 'STORE',
                })}
                /**
                 * Identifies the location of the merchant that is taking the payment.
                 * Obtained from the Square Application Dashboard - Locations tab.
                 */
                locationId={props.paymentIntent.square.location_id}
            >
                <CreditCard
                    buttonProps={{
                        className: "hide"
                    }}
                />
            </PaymentForm>
        </div>
    )
})

export default SquareSetupPaymentMethod;
