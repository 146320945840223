import { useMemo } from 'react';
import FileQuestion02 from '@untitled-ui/icons-react/build/esm/FileQuestion02';
import CustomerContractFooter from "./CustomerContractFooter";

const CustomerContractNotFound = ({ message }) => {
    const expiredMessage = useMemo(() => {
        const defaultMessaged = "Oops, we can't find the contract you are looking for. Please reach out to the person who sent you the contract.";
        return message || defaultMessaged
    }, [message]);
    return (
        <div className="contract-wrapper">
            <div className="flex flex-col flex-grow justify-center items-center gap-8">
                <FileQuestion02 width={104} height={104} className="text-red-base"/>
                <div className="flex flex-col justify-center items-center gap-3">
                    <h3 className="text-dark-gray text-3xl font-semibold">Contract not found</h3>
                    <div className="text-gray6 max-w-[400px] text-center text-sm">{expiredMessage}</div>
                </div>
            </div>
            <CustomerContractFooter />
        </div>
        
    );
};

export default CustomerContractNotFound;