import '../App.scss';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { renderChildren } from "../helpers/common";
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

function Link(props) {
    const navigate = useNavigate();

    const onClick = (event) => {
        let openInNewTab = false;
        if (event.metaKey || event.ctrlKey || props.newTab) {
            openInNewTab = true;
        }
        event.preventDefault();
        event.stopPropagation();

        const link = props.href || props.to;
        if (props.onClick) {
            props.onClick(event);
        } else if (link) {
            if (openInNewTab) {
                const url = props.isExternalLink ? link : window.location.origin + link;
                window.open(url, '_blank', 'noopener,noreferrer');
            } else {
                navigate(link)
            }
        }
    }

    const pprops = {...props};
    delete pprops['isExternalLink']
    return props.isExternalLink || props.useAnchorTag 
        ? (
            <a
                {...pprops}
                href={props.href} 
                className={classnames("text-primary-updated hover:text-primary-updated font-normal transition", props.className)}
            >{ renderChildren(props) }</a>
        ) : (
            <RouterLink 
                {...props} 
                href={props.href} 
                to={props.href || props.to} 
                onClick={onClick}
                className={classnames("text-primary-updated hover:text-primary-updated font-normal transition", props.className)}
            >{ renderChildren(props) }</RouterLink>
        );
}

export default Link;