import { useContext, useEffect, useState } from "react";
import BaseSideModal from "./BaseSideModal";
import { Row } from "react-bootstrap";
import BaseForm from "../BaseForm";
import SubmitButton from "../common/buttons/SubmitButton";
import { serverFetch, serverPatch } from "../../helpers/server";
import { useTranslation } from "react-i18next";
import { BaseContext, removeHtmlTags } from "../../helpers/common";
import Link from "../Link";
import Notification from "../Notification";

function UpdateSubscriptionPaymentInstructionsModal({ subscription, onClose, ...restProps }) {
    const { t } = useTranslation("common");
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);

    const [initialFields, setInitialFields] = useState({});
    const [subscriptionPaymentSettingEnabled, setSubscriptionPaymentSettingEnabled] = useState(false);
    const [companyPaymentInstructionSetting, setCompanyPaymentInstructionSetting] = useState({});

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setCompanyPaymentInstructionSetting(res.invoice_payment_instructions_config);
        });
    }, []);

    useEffect(() => {
        const newInitialFields = {
            invoice_payment_instructions_enabled: subscription.invoice_payment_instructions != null,
            invoice_payment_instructions: subscription.invoice_payment_instructions,
        };
        setSubscriptionPaymentSettingEnabled(newInitialFields.invoice_payment_instructions_enabled);
        setInitialFields(newInitialFields);
    }, [subscription]);

    const onFieldChange = (field, value) => {
        if (field === "invoice_payment_instructions_enabled") {
            setSubscriptionPaymentSettingEnabled(value);
        }
    };

    const onSubmit = async (data) => {
        let newInvoicePaymentInstructions = null
        if (subscriptionPaymentSettingEnabled) {
            if (!removeHtmlTags(data.invoice_payment_instructions).trim().length) {
                newInvoicePaymentInstructions = "";
            } else {
                newInvoicePaymentInstructions = data.invoice_payment_instructions;
            }
        }
        const updateData = {
            invoice_payment_instructions: newInvoicePaymentInstructions,
        };
        await serverPatch(getApiUrl(`/subscriptions/${subscription.id}`), updateData).then((res) => {
            if (res) {
                onClose(true);
                Notification.Success("Successfully updated subscription payment instructions");
            }
        });
    };

    return (
        <BaseSideModal onClose={onClose} {...restProps}>
            <BaseSideModal.Form initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={onSubmit}>
                <BaseSideModal.Header title="Update Payment Instructions for Subscription" />
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input
                            type="switch"
                            label="Customize Payment Instructions"
                            name="invoice_payment_instructions_enabled"
                            required
                            description={
                                <span>
                                    These instructions will override the payment instructions set at the company-level for all invoices related to this subscription. <br />
                                    <em>Note: Any changes made to the payment instructions will only apply to new invoices created for this subscription.</em>
                                </span>
                            }
                        />
                        {subscriptionPaymentSettingEnabled && (
                            <BaseForm.Input name="invoice_payment_instructions" errorLabel="Payment instructions content" type="editor" height="300" />
                        )}
                    </Row>
                    {!subscriptionPaymentSettingEnabled && (
                        <Row>
                            <span className="body2 mt-3">Company-Level Payment Instructions:</span>
                            {companyPaymentInstructionSetting && companyPaymentInstructionSetting.enabled ? (
                                <>
                                    <div className="body1 mt-2" dangerouslySetInnerHTML={{ __html: companyPaymentInstructionSetting.content }} />
                                </>
                            ) : (
                                <span className="body1">
                                    Not set. <Link href={getCompanySpecificUrl(`/settings/invoices`)}>Click here</Link> to set payment instructions for all invoices.
                                </span>
                            )}
                        </Row>
                    )}
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Update</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default UpdateSubscriptionPaymentInstructionsModal;
