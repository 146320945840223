import '../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {BaseContext, currencyFormatFromPrice, downloadBlob, getCurrencyOptions} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import ContentBox from "../../components/ContentBox";
import MapleTable from "../../components/MapleTable";
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumb";
import {serverPost} from "../../helpers/server";
import {useSearchParams} from "react-router-dom";
import Columns from "../../components/Columns";
import BaseForm from "../../components/BaseForm";
import DateRangePicker from "../../components/common/daterangepicker/DateRangePicker";
import SubmitButton from "../../components/common/buttons/SubmitButton";
import InfiniteScroll from "react-infinite-scroll-component";
import {renderJournalAccount} from "../../helpers/journalEntries";
const _ = require('lodash');

function JournalEntries() {
    const [ searchParams ] = useSearchParams();
    let customer_id = searchParams.get('customer_id');
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [selectedDateRange, setSelectedDateRange] = useState({
        start: moment().startOf('year'),
        end: moment().endOf('year'),
    });
    const [journalEntries, setJournalEntries] = useState([]);
    const [initialFields, setInitialFields] = useState({});
    const [meta, setMeta] = useState({});
    const [sort, setSort] = useState("createdAtDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);

    useEffect(() => {
        setPageTitle(`Financial Reports`);
    }, []);

    useEffect(() => {
        onSearch(true);
    }, [])

    const onSearch = (restart = true) => {
        const limit = 100;
        const reportData = {
            query: {
                customer_id: customer_id,
                entry_date: {
                    GTE: selectedDateRange.start,
                    LTE: selectedDateRange.end
                }
            },
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            sort_key: "entryTimeAsc"
        }
        serverPost(getApiUrl("/journal_entries/find"), reportData).then(res => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setJournalEntries(results);
                    setMeta(res.meta);
                } else {
                    setJournalEntries(_.concat(journalEntries, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        })
    }

    const breadcrumbItems = [
        { name: 'Financial Reports', href: getCompanySpecificUrl(`/reports`), current: false },
        { name: "Journal Entries", current: true }
    ]

    const dateFormat = "MM/DD/YYYY";
    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb items={breadcrumbItems}/>
                <Section variant="page" title="Revenue Recognition Report">
                    <ContentBox>
                        <ContentBox.Body>
                            <BaseForm initialFormFields={initialFields} onSubmit={() => onSearch(true)}>
                                <div className="flex flex-row gap-2">
                                    <div className="grow">
                                        <div className="space-y-0 w-48">
                                            <div className="form-group inline">
                                                <div className="form-label">Time Period</div>
                                                <div className="form-input">
                                                    <DateRangePicker
                                                        iconOnLeft
                                                        clearable={false}
                                                        defaultValue={selectedDateRange}
                                                        name={"date_range"}
                                                        onDatesChange={(start, end) => {
                                                            setSelectedDateRange({
                                                                start: start,
                                                                end: end
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-end">
                                        <SubmitButton>Run</SubmitButton>
                                    </div>
                                </div>
                            </BaseForm>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <InfiniteScroll
                    dataLength={journalEntries.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section loading={loading} className="mt-4" title="Journal entries">
                        <MapleTable className="min-w-full divide-y divide-gray-300">
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <MapleTable.TH>Customer</MapleTable.TH>
                                    <MapleTable.TH>Time</MapleTable.TH>
                                    <MapleTable.TH>Product</MapleTable.TH>
                                    <MapleTable.TH>Account</MapleTable.TH>
                                    <MapleTable.TH className="text-end">Debit</MapleTable.TH>
                                    <MapleTable.TH className="text-end">Credit</MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(journalEntries, (entry, i) =>
                                        <>
                                            {
                                                entry.destination_journal_account &&
                                                <tr key={i+"debit"}>
                                                    <td><Columns.CustomerName customer={entry.customer} /></td>
                                                    <td>{ moment(entry.entry_time).utc().format(dateFormat) }</td>
                                                    <td>{ entry.product && entry.product.name }</td>
                                                    <td>{ entry.destination_journal_account && renderJournalAccount(entry.destination_journal_account) }</td>
                                                    <td className="text-end">{ currencyFormatFromPrice(entry.amount) }</td>
                                                    <td className="text-end text-gray-400">-</td>
                                                </tr>
                                            }
                                            {
                                                entry.source_journal_account &&
                                                <tr key={i+"credit"}>
                                                    <td><Columns.CustomerName customer={entry.customer} /></td>
                                                    <td>{ moment(entry.entry_time).utc().format(dateFormat) }</td>
                                                    <td>{ entry.product && entry.product.name }</td>
                                                    <td>{ entry.source_journal_account && renderJournalAccount(entry.source_journal_account) }</td>
                                                    <td className="text-end text-gray-400">-</td>
                                                    <td className="text-end">{ currencyFormatFromPrice(entry.amount) }</td>
                                                </tr>
                                            }
                                        </>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}
// { currencyFormatFromPrice(row["breakdown"][c]["amount"]) }

export default JournalEntries;
