import React, {createRef, useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { BaseContext } from '../../helpers/common';
import { Row } from 'react-bootstrap';
import Notification from '../../components/Notification';
import { serverPatch, serverFetch } from '../../helpers/server';
import moment, {now} from 'moment';
import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import Section from "../../components/Section";
import ContentBox from "../../components/ContentBox";
import BaseForm from "../../components/BaseForm";
import SimpleModal from "../../components/modals/SimpleModal";

function CommissionSettings() {
    const { t } = useTranslation('common');
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(true);
    const [initialFormFields, setInitialFormFields] = useState({});
    const [showAnchorDateConfirmModal, setShowAnchorDateConfirmModal] = useState(false);
    const formRef = createRef();

    useEffect(() => {
        setPageTitle(`Commission Settings`);
        fetchSettings();
    }, []);

    const fetchSettings = () => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            if (res) {
                setSettings(res);
                setLoading(false);
            }
        });
    };

    const periodOptions = [
        { value: 'MONTHLY', label: 'Monthly' },
        { value: 'QUARTERLY', label: 'Quarterly' },
        { value: 'YEARLY', label: 'Yearly' },
    ];

    useEffect(() => {
        if (!settings.commission_config) {
            return
        }
        const initialFields = {
            quota_period: settings.commission_config.commission_quota_period_config.period_type,
            quota_anchor_date: moment(settings.commission_config.commission_quota_period_config.anchor_date),
            report_period: settings.commission_config.commission_report_period_config.period_type,
            report_anchor_date: moment(settings.commission_config.commission_report_period_config.anchor_date),
        }
        setInitialFormFields(initialFields);
    }, [settings]);

    const updateSettings = async (data) => {
        const payload = {
            commission_config: {
                commission_quota_period_config: {
                    period_type: data.quota_period,
                    anchor_date: moment(data.quota_anchor_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                    anchor_date_location: Intl.DateTimeFormat().resolvedOptions().timeZone
                },
                commission_report_period_config: {
                    period_type: data.report_period,
                    anchor_date: moment(data.report_anchor_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                    anchor_date_location: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
            }
        };

        const result = await serverPatch(getApiUrl("/settings"), payload);

        if (result) {
            setSettings(result);
            Notification.Success("Successfully updated commission settings");
        } else {
            Notification.Danger("Error saving commission settings");
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const isAnchorDateInFuture = async (data) => {
        if (moment(data.quota_anchor_date).isAfter(moment()) || moment(data.report_anchor_date).isAfter(moment())) {
            setShowAnchorDateConfirmModal(true);
        } else {
            await updateSettings(data);
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Commission Settings" variant={'page'}>
                    <ContentBox>
                        <ContentBox.Body>
                            <BaseForm onSubmit={isAnchorDateInFuture} initialFormFields={initialFormFields} ref={formRef}>
                                <div className="space-y-6">
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-900 mb-4">Quota Period Settings</h3>
                                        <Row>
                                            <BaseForm.Input
                                                colSpan="6"
                                                name="quota_period"
                                                label="Default Quota Period"
                                                type="select"
                                                options={periodOptions}
                                                showSearch={false}
                                                required
                                                description="Default quota period for sales team members"
                                            />
                                            <BaseForm.Input
                                                colSpan="6"
                                                name="quota_anchor_date"
                                                label="Quota Period Anchor Date"
                                                type="date"
                                                required
                                                description={<div>
                                                    <span>Start date for calculating quota periods</span>
                                                    <span className={'text-xs'}> (Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone})</span>
                                                </div>}
                                            />
                                        </Row>
                                    </div>

                                    <div>
                                        <h3 className="text-lg font-medium text-gray-900 mb-4">Report Period Settings</h3>
                                        <Row>
                                            <BaseForm.Input
                                                colSpan="6"
                                                name="report_period"
                                                label="Default Report Period"
                                                type="select"
                                                options={periodOptions}
                                                showSearch={false}
                                                required
                                                description="Default commission report period for sales team members"
                                            />
                                            <BaseForm.Input
                                                colSpan="6"
                                                name="report_anchor_date"
                                                label="Report Period Anchor Date"
                                                type="date"
                                                required
                                                description={<div>
                                                    <span>Start date for calculating report periods</span>
                                                    <span className={'text-xs'}> (Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone})</span>
                                                </div>}
                                            />
                                        </Row>
                                    </div>

                                    <Row>
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary">
                                                Save Settings
                                            </button>
                                        </div>
                                    </Row>
                                </div>
                            </BaseForm>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
            </ContentContainer>
            <SimpleModal
                show={showAnchorDateConfirmModal}
                onClose={() => setShowAnchorDateConfirmModal(false)}
                onConfirm={async () => {
                    if (formRef.current) {
                        await updateSettings(formRef.current.getFormData())
                    }
                }}
                title={"Anchor Date in Future"}
                body={"Your selected anchor date(s) is in the future. This means that no report/quota periods will begin until your selected date."}
            />
        </BaseContainer>
    );
}

export default CommissionSettings;