import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import React, { useContext, useState, useEffect } from 'react';
import {BaseContext, currencyFormatFromPrice, cyrb53} from '../../helpers/common';
import {serverPost} from '../../helpers/server';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from "../Loader";
import moment from "moment/moment";
import Button from "../common/buttons/Button";
const _ = require("lodash");

function ApplyCreditToInvoice(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [openInvoices, setOpenInvoices] = useState([]);

    useEffect(() => {
        if (props.show) {
            fetchOpenInvoices()
        }
    }, [props.show])

    const fetchOpenInvoices = () => {
        const params = {
            sort_key: "createdAtDesc",
            pagination: {
                from_key: null,
                limit: 50
            },
            query: {
                currency: props.credit.amount.currency,
                customer_id: props.credit.customer_id
            },
            include_meta: false
        }
        serverPost(getApiUrl("/invoices/find"), params).then((res) => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                setOpenInvoices(results);
            }
        });
    }

    const onSubmit = (data, errorHandler) => {
        const applyData = {
            credit_id: props.credit.id,
            invoice_uuids: [data.invoice_uuid]
        }
        serverPost(getApiUrl(`/credits/${props.credit.id}/apply_to_open_invoices`), applyData, {}, errorHandler).then((res) => {
            if (res) {
                if (props.onClose) {
                    props.onClose(true)
                }
            }
        });
    }

    const openInvoiceOptions = _.map(openInvoices, (invoice) => {
        return {
            value: invoice.uuid,
            label: `${invoice.number} - ${moment(invoice.invoice_date).format("D MMMM, YYYY")} - ${currencyFormatFromPrice(invoice.due)}`
        }
    });

    if (!props.show) {
        return;
    }

    return (
        <BaseSideModal {...props} >
            <BaseSideModal.Form onSubmit={onSubmit} initialFormFields={props.customer}>
                <BaseSideModal.Header title={"Apply Credit to Open Invoice"}/>
                <BaseSideModal.Body>
                    <div className="flex flex-col text-sm mb-4">
                        <div><span className="font-semibold">Name:</span> {props.credit.name}</div>
                        <div><span className="font-semibold">Remaining Amount:</span> {currencyFormatFromPrice(props.credit.amount)}</div>
                    </div>
                    <Loader loading={loading}>
                        {
                            openInvoices.length > 0 ?
                                <Row>
                                    <BaseForm.Input
                                        colSpan="12" name="invoice_uuid" label="Invoice" required
                                        type="select" options={openInvoiceOptions} showSearch={false}
                                    />
                                </Row>
                            : <div>
                                <p className="text-sm">There are no open invoices that match the currency of this credit.</p>
                            </div>
                        }
                    </Loader>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    {
                        openInvoices.length > 0 ?
                            <SubmitButton>Apply</SubmitButton>
                        : <Button onClick={() => props.onClose()}>Close</Button>
                    }
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default ApplyCreditToInvoice;
