import { canRevertContractToDraft, isContractExpired } from '../../helpers/contracts';
import { convertVariablesIntoHtml } from '../../helpers/common';

// components
import Button from '../common/buttons/Button';
import ConfirmationButton from '../common/buttons/ConfirmationButton';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import { useRef, useState } from 'react';

const ContractPreviewModal = (props) => {
	const { status, content, counter_signatories, signatories, title, content_template } = props.contractDetails;

	const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
	const [shouldClosePreview, setShouldClosePreview] = useState(true);
	const contractEditingRef = useRef(null);

	const handleConfirmationModalConfirm = async (e) => {
		if (contractEditingRef.current) {
			await contractEditingRef.current.submitForm();
		}
		handleConfirmationModalDiscard(e);
	};

	const handleConfirmationModalDiscard = (e) => {
		setIsConfirmationModalOpen(false);
		setIsSecondModalOpen(false);
		props.setIsEditingTemplate(false);
		if (contractEditingRef.current) {
			contractEditingRef.current.onResetFields({
				content_template: content_template,
			});
		}
		if (shouldClosePreview) {
			closePreview(e);
		}
	};

	const handleOnClose = (e) => {
		if (props.isEditingTemplate) {
			setIsConfirmationModalOpen(true);
			setIsSecondModalOpen(true);
			setShouldClosePreview(true);
			return false;
		} else {
			closePreview(e);
		}
	};

	const closePreview = (event) => {
		if (props.onClose) {
			props.onClose(event);
		}
		props.setIsEditingTemplate(false);
	};

	const renderPrimaryButton = () => {
		if (props.isEditingTemplate) {
			return <SubmitButton>Update</SubmitButton>;
		}

		if (!canRevertContractToDraft(status)) {
			return <Button onClick={() => props.setIsEditingTemplate(true)}>Edit</Button>;
		}

		return (
			<Button onClick={props.onClose}>
				Close
			</Button>
		);
	};

	const renderSecondaryButton = () => {
		if (props.isEditingTemplate) {
			return (
				<Button variant='text-outline' onClick={() => {
					if (props.isEditingTemplate) {
						setIsConfirmationModalOpen(true);
						setIsSecondModalOpen(true);
						setShouldClosePreview(false);
					} else {
						setShouldClosePreview(false);
					}
				}}>
					Back
				</Button>
			);
		}

		if (canRevertContractToDraft(status)) {
			return (
				<ConfirmationButton
					variant='text-outline'
					title={'Move to Draft?'}
					body={
						<div>
							{props.hasAnyoneSigned && (
								<div>
									<span>Are you sure you want to move the contract back to draft?</span>
									<br />
									<br />
									<span>
										Some signatories have already signed. Their signatures will be invalidated, and
										they will be requested to sign the document again.
									</span>
								</div>
							)}
							{!props.hasAnyoneSigned && (
								<span>
									Are you sure you want to move the contract back to draft? Doing so will make the
									contract links unavailable until it is finalized again.
								</span>
							)}
						</div>
					}
					confirmationText={
						props.hasAnyoneSigned && !isContractExpired(props.contractDetails) ? 'CONFIRM REVERT' : null
					}
					onConfirm={async (event) => {
						await props.onClickMoveToDraftAndEdit(true);
						setIsSecondModalOpen(false);
					}}
					modalCentered
					onModalOpen={() => setIsSecondModalOpen(true)}
					onModalClose={() => setIsSecondModalOpen(false)}
				>
					Move to Draft & Edit
				</ConfirmationButton>
			);
		}

		return (
			<Button variant="text-outline" onClick={closePreview}>
				Close
			</Button>
		);
	};

	const renderConfirmationModal = () => (
		<BaseModal centered={true} show={isConfirmationModalOpen} onClose={() => {
			setIsConfirmationModalOpen(false);
			setIsSecondModalOpen(false);
			setShouldClosePreview(true);
		}}>
			<BaseModal.Header>
				<BaseModal.Title>Save Before Leaving?</BaseModal.Title>
			</BaseModal.Header>
			<BaseModal.Body>
				<div className='body1'>Do you want to save before proceeding?</div>
			</BaseModal.Body>
			<BaseModal.Footer>
				<div className='flex w-full justify-between'>
					<Button variant='text-outline' onClick={handleConfirmationModalDiscard}>
						Discard
					</Button>
					<Button onClick={handleConfirmationModalConfirm}>
						Save Changes
					</Button>
				</div>
			</BaseModal.Footer>
		</BaseModal>
	);

	return (
		<BaseModal size='xl' show={props.show} onClose={handleOnClose} className={isSecondModalOpen ? "modal-dimmed" : ""} contentClassName="!mt-10" dialogClassName="modal-custom-full">
			<BaseForm ref={contractEditingRef} initialFormFields={props.contractDetails} onSubmit={props.onClickUpdate}>
				<BaseModal.Header>
					<BaseModal.Title>{title}</BaseModal.Title>
					<BaseModal.Subtitle>
						{props.isEditingTemplate
							? 'Please feel free to update the content as you choose. Note that, any changes to the content will not be reflected in the template it is adopted from.'
							: 'Here is the preview of the contents of the contract.'}
					</BaseModal.Subtitle>
				</BaseModal.Header>
				<BaseModal.Body className="bg-[#F5F5F5] p-3 flex justify-center overflow-auto">
					<div className='contract-content min-h-[300px] h-min !w-full max-w-[980px]'>
						{props.isEditingTemplate ? (
							<div className='py-3 px-3 w-full fr-editor-container bg-white flex flex-col'>
								<BaseForm.Input colSpan='12' type='text' name='title' label='Title' />
								<BaseForm.Input
									colSpan='12'
									type='editor2'
									name='content_template'
									height='450'
									tokens={props.templateFields}
									updated={props.updated}
								/>
							</div>
						) : (
							<div className="h-[70vh] py-5 w-full flex justify-center">
								<div
									className='w-[980px] h-min mx-4 mb-4 bg-white p-[24px] contract-preview-container'
									dangerouslySetInnerHTML={{
										__html: convertVariablesIntoHtml(content, counter_signatories, signatories),
									}}
								/>
							</div>
						)}
					</div>
				</BaseModal.Body>
				<BaseModal.Footer>
					<div className='flex justify-between flex-grow'>
						{renderSecondaryButton()}
						{renderPrimaryButton()}
					</div>
				</BaseModal.Footer>
				{renderConfirmationModal()}
			</BaseForm>
		</BaseModal>
	);
};

export default ContractPreviewModal;
