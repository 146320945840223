import '../App.scss';
import '../css/forms.scss';
import '../js/forms.js';
import classnames from 'classnames';
import React, {useState, useEffect, useMemo, useRef} from 'react';
import { Dropdown, Form, FormControl } from 'react-bootstrap';
import Button from './common/buttons/Button';
import { debounce } from 'lodash';
import { createPortal } from 'react-dom';
const _ = require("lodash");

function MultiSelectDropdown(props) {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const idField = props.idField || 'id';
    const labelField = props.labelField || 'label';
    const prefix = useMemo(() => {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    }, [])

    useEffect(() => {
        if (!props.loadOptions) {
            setItems(props.items || []);
        }
    }, [props.items]);

    useEffect(() => {
        setSelectedItems(props.selectedItems || [])
        if (props.loadOptions) {
            loadInitialOptions()
        }
    }, [props.type]);

    useEffect(() => {
        setSelectedItems(props.selectedItems || []);
    }, [props.selectedItems]);

    const loadInitialOptions = () => {
        if (props.loadOptions) {
            setIsLoading(true);
            props.loadOptions('').then((res) => {
                setItems(res);
            });
            setIsLoading(false);
        }
    };

    const debouncedSearch = useMemo(
        () =>
            debounce(async (searchQuery) => {
                if (props.loadOptions) {
                    setIsLoading(true);
                    try {
                        const results = await props.loadOptions(searchQuery);
                        setItems(results);
                    } catch (error) {
                        console.error('Error loading options:', error);
                    } finally {
                        setIsLoading(false);
                    }
                }
            }, 300),
        [props.loadOptions]
    );

    const onSelectAll = (event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const newSelectedItems = _.map(items, (p, i) => p[idField]);
        setSelectedItems(newSelectedItems);
        if (props.onItemsChange) {
            props.onItemsChange(newSelectedItems);
        }
    }

    const onSelectNone = (event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        const newSelectedItems = [];
        setSelectedItems(newSelectedItems);
        if (props.onItemsChange) {
            props.onItemsChange(newSelectedItems);
        }
    }

    const updateSelectedItems = (event, value, isChecked) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        let newSelectedItems = [...selectedItems];
        if (isChecked) {
            newSelectedItems.push(value);
        } else {
            const index = newSelectedItems.indexOf(value);
            if (index > -1) {
                newSelectedItems.splice(index, 1);
            }
        }
        setSelectedItems(newSelectedItems);
        if (props.onItemsChange) {
            props.onItemsChange(newSelectedItems);
        }
        return false;
    }

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div ref={ref} style={style} className={classnames(className, "bg-white shadow-lg rounded-lg border border-gray-200 p-2 min-w-[200px]" )} aria-labelledby={labeledBy}>
                    {
                        (props.showSearch || props.loadOptions) &&
                        <FormControl autoFocus className="mb-2 px-2 py-1 w-full border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"  placeholder="Start typing"
                                     onChange={(e) => {
                                         setSearchValue(e.target.value)
                                         if (props.loadOptions) {
                                             debouncedSearch(e.target.value);
                                         }
                                     }}
                                     value={searchValue}
                        />
                    }
                    <div className="flex items-center gap-2 mb-2 pb-1 border-b border-gray-200">
                        <span className="text-gray-600 text-sm">Select:</span>
                        <Button variant="text-primary" className="text-sm font-medium hover:text-primary-dark px-1 py-0.5" onClick={(event) => onSelectAll(event)}>All</Button>
                        <Button variant="text-primary" className="text-sm font-medium hover:text-primary-dark px-1 py-0.5" onClick={(event) => onSelectNone(event)}>None</Button>
                    </div>
                    <ul className="list-unstyled space-y-0.5 max-h-[200px] overflow-y-auto">
                        {
                            React.Children.toArray(children).filter(
                                (child) =>
                                    !searchValue || child.props.value.toLowerCase().includes(searchValue.toLowerCase()),)
                        }
                    </ul>
                </div>
            );
        },
    );
    const showLabel = !(props.hideLabel || _.isNil(props.label));

    const renderDropdown = () => {
        return (
            <Dropdown.Menu as={CustomMenu} className="single" disabled={props.disabled}>
                {
                    items.map((item, key) => {
                        if (!item) {
                            return null;
                        } else if (item.divider) {
                            return <hr key={key}/>
                        } else {
                            return (
                                <Dropdown.Item key={key} eventKey={item[idField]} value={item[labelField]}
                                            active={false} className={classnames("flex items-center gap-2 px-2 py-1 rounded hover:bg-gray-50 cursor-pointer", item.className)}
                                            disabled={item.disabled} onClick={(event) => updateSelectedItems(event, item[idField], !selectedItems.includes(item[idField]))}>
                                    <input className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary" type="checkbox" value={item[idField]} id={prefix + item[idField]} name="select[]" checked={selectedItems.includes(item[idField])} onChange={ (event) => updateSelectedItems(event, item[idField], event.target.checked) }/>
                                    {
                                        item.icon &&
                                        <>
                                            <i className={"fa " + item.icon} style={item.style}/>
                                            <span> </span>
                                        </>
                                    }
                                    <span className={classnames(item.className)}>{item[labelField]}</span>
                                    {
                                        item.disabled && item.disabledDescription &&
                                        <>
                                            <br/>
                                            <span className={classnames(item.className, "caption")}>{item.disabledDescription}</span>
                                        </>
                                    }
                                </Dropdown.Item>
                            );
                        }
                    })
                }
            </Dropdown.Menu>
    );
    };

    return (
        <Dropdown align={props.align || "start"}
                  drop={props.drop || 'down'}
                  className={classnames(props.className, (showLabel ? "inline": ""), "single")} disabled={props.disabled}>
            <Dropdown.Toggle variant="text" id={"dropdown-"+prefix} disabled={props.disabled}
                             data-bs-auto-close="outside"
                             className={classnames(props.hideCaret ? "no-caret": "")}>
                {
                    showLabel &&
                    <Form.Label>{props.label}</Form.Label>
                }
                {
                    props.toggleLabel ||
                        <div className="simple-label">{selectedItems.length} of {items.length}&nbsp;Selected</div>
                }
            </Dropdown.Toggle>
            {props.popover ? createPortal(renderDropdown(), document.body) : renderDropdown()}
        </Dropdown>
    );
}

export default MultiSelectDropdown;
