import {useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {
    BaseContext,
    currencyFormatFromPrice,
    getLabelForCommissionReportStatus,
    renderContractStatusLabel
} from "../../helpers/common";
import {useTranslation} from "react-i18next";
import Link from "../../components/Link";
import moment from "moment";
import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import Section from "../../components/Section";
import KeyValueDisplay2 from "../../components/KeyValueDisplay2";
import CommissionEventLogs from "./CommissionEventLogs";
import {serverPost} from "../../helpers/server";
import CommissionReports from "./CommissionReports";
import Notification from '../../components/Notification';
import {Button} from "react-bootstrap";
import ContentBox from "../../components/ContentBox";
import SimpleModal from "../../components/modals/SimpleModal";
import Loader from "../../components/Loader";
import BaseForm from "../../components/BaseForm";
import Toggle from "../../components/common/Toggle";
import Share01 from "@untitled-ui/icons-react/build/esm/Share01";
import BaseOverlayTrigger from "../../components/BaseOverlayTrigger";
import _ from "lodash";
import CommissionDeals from "./CommissionDeals";
import classNames from "classnames";

function CommissionAggregatedReport(props) {
    const { id } = useParams();
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { t } = useTranslation();
    const [details, setDetails] = useState({});
    const [sectionTitle, setSectionTitle] = useState('');
    const [showConfirmApproveModal, setShowConfirmApproveModal] = useState(false);
    const [viewMode, setViewMode] = useState('by_user');
    const [searchParams] = useSearchParams();
    const endDate = searchParams.get('endDate');


    useEffect(() => {
        setPageTitle('Commission Report Details');
        loadReport()
    }, []);

    const toggleValues = [
        { value: 'by_user', label: 'By Member' },
        { value: 'by_deal', label: 'By Deal' }
    ];

    const handleApproveAll = () => {
        const params = {
            report_ids: report.report_period_ids
        };
        serverPost(getApiUrl('/commission/reports/approve'), params).then((res) => {
            if (res) {
                setShowConfirmApproveModal(false)
                Notification.Success("Successfully approved reports")
                loadReport();
            } else {
                Notification.Danger("Error approving reports")
            }
        });
    };

    const loadReport = () => {
        const params = {
            query: {
                end_date: {
                    eq: endDate
                }
            },
        };
        serverPost(getApiUrl('/commission/aggregated_reports/find'), params).then((res) => {
            if (res) {
                const rep = res[0] || {}
                setReport(rep)

                const data = {
                    'Start Date': moment(rep.start_date).format('MMM D, YYYY'),
                    'End Date': moment(rep.end_date).format('MMM D, YYYY'),
                    'Total Commissions Earned': currencyFormatFromPrice(rep.commission_achieved),
                    'Total Quota Achieved': currencyFormatFromPrice(rep.quota_achieved),
                    '# of Sales Members': `${rep.report_period_ids.length}`,
                };
                setDetails(data);

                const title = `Commission Report (${moment(rep.start_date).format('MMM D, YYYY')} - ${moment(rep.end_date).format('MMM D, YYYY')})`;
                setSectionTitle(title);
            }
            setLoading(false);
        })
    }

    return (
		<BaseContainer>
			<Loader loading={loading}>
				<ContentContainer>
					<Section
						title={sectionTitle}
						variant={'page'}
						titleRight={
							<div className='text-nowrap'>
								{report && getLabelForCommissionReportStatus(report.status)}
							</div>
						}
						right={report && _.includes(['AWAITING_APPROVAL, PARTIALLY_APPROVED'], report.status) &&
							<div className='flex justify-between leading-8'>
								<div className='flex gap-[12px]'>
									<Button variant={'primary'} onClick={() => setShowConfirmApproveModal(true)}>
										Approve
									</Button>
								</div>
							</div>
						}
					/>
					<Section>
						<KeyValueDisplay2 title={"Overview"} items={details} />
					</Section>
					{/*<br/>*/}
					{/*{report &&*/}
					{/*    <CommissionReports endDate={endDate} />*/}
					{/*}*/}
					<ContentBox>
						<ContentBox.Title>Breakdown</ContentBox.Title>
						<ContentBox.Body>
							<div className={'flex flex-row'}>
                                    <Toggle
                                        value={viewMode}
                                        toggleValues={toggleValues}
                                        onClick={(value) => setViewMode(value)}
                                    />
                                <div className='grow'/>
                                <BaseOverlayTrigger content={'Export'}>
                                    <Button variant='text-outline' hasIconOnly>
                                        <Share01 width={20} height={20}/>
                                    </Button>
                                </BaseOverlayTrigger>
                            </div>
                            {report && viewMode === 'by_user' ? (
                                <CommissionReports endDate={endDate} isComponent/>
                            ) : (
                                <CommissionDeals reportIDs={report?.report_period_ids} isComponent />
							)}
						</ContentBox.Body>
					</ContentBox>
				</ContentContainer>
			</Loader>
			<SimpleModal
				show={showConfirmApproveModal}
				onClose={() => setShowConfirmApproveModal(false)}
				title='Confirm Approval'
				buttonTitle='Approve'
				buttonVariant='primary'
				onConfirm={handleApproveAll}
				body={
					<div>
						<span>Are you sure you want to approve this report?</span>
						<br />
						<br />
						<span>
							<strong>Note:</strong> This will approve all unapproved sub-reports for this period.
						</span>
					</div>
				}
			/>
		</BaseContainer>
	);
}

export default CommissionAggregatedReport;