import { useEffect, useRef } from "react";

export const useZoomToFitContainer = ({ baseWidth, isReady }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (!isReady) return;
        const updateZoom = () => {
            const container = containerRef.current;
            if (!container) return;

            const parentWidth = container.parentElement?.offsetWidth ?? window.innerWidth;
            const zoom = Math.min(parentWidth / baseWidth, 1);
            // console.log("parentWidth", parentWidth);
            // console.log("parentWidth/baseWidth", zoom);

            container.setAttribute("style", `zoom:${zoom}`);
        }

        updateZoom();
        window.addEventListener("resize", updateZoom);

        return () => window.removeEventListener("resize", updateZoom);
    }, [baseWidth, isReady]);

    return isReady ? containerRef : null;
};