import '../App.scss';
import React, { useContext } from 'react';
import Header from './layout/header/Header';
import SuperLeftNav from './SuperLeftNav';
import classnames from 'classnames';
import { Container } from 'react-bootstrap';
import { AppContext } from '../helpers/common';

function SuperContainer(props) {
	const { isNavCollapsed } = useContext(AppContext);
	return (
		<Container fluid className={classnames('page-wrapper', props.containerClassName)}>
			<SuperLeftNav />
			<div className={classnames(
				'right-wrapper updated-nav',
				{
					"right-wrapper-nav-collapsed": isNavCollapsed
				},
			)}>
				<Header isSuper={true} />
				<div className='content-wrapper'>{props.children}</div>
			</div>
		</Container>
	);
}

export default SuperContainer;
