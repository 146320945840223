import { useCallback } from "react";
import { Alert as RBAlert } from "react-bootstrap";
import AlertTriangle from '@untitled-ui/icons-react/build/esm/AlertTriangle';
import Button from "./buttons/Button";
import Check from "@untitled-ui/icons-react/build/esm/Check";

const Alert = ({ children, ...props }) => {
    const renderIcon = useCallback(() => {
        switch (props.variant) {
            case 'danger':
                return (
                    <span className="p-1 bg-[#FEE4E2] rounded-md">
                        <AlertTriangle className="text-red-base" width={24} height={24} />
                    </span>
                );
            case 'success':
                return (
                    <span className="p-1 bg-[#b9f4d1] rounded-md">
                        <Check className="text-[#067647]" width={24} height={24} />
                    </span>
                );
            case 'warning':
                return (
                    <span className="p-1 bg-[#FBF4DD] rounded-md">
                        <AlertTriangle className="text-yellow-400" width={24} height={24} />
                    </span>
                );
            default:
                return null;
        }
    }, [props.variant]);

    return (
        <RBAlert {...props}>
            <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-3">
                    {renderIcon()}
                    <div className="text-dark-gray text-base font-semibold">
                        {children}
                    </div>
                </div>
                {props.showActionButton &&
                    <Button variant={props.buttonVariant || 'primary'} onClick={props.onActionButtonClick}>{props.actionButtonLabel}</Button>
                }
            </div>
        </RBAlert>
    );
};

export default Alert;