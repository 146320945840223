import {useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {BaseContext, currencyFormatFromPrice} from "../../helpers/common";
import {useTranslation} from "react-i18next";
import {serverFetch} from "../../helpers/server";
import Link from "../../components/Link";
import moment from "moment";
import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import Section from "../../components/Section";
import KeyValueDisplay2 from "../../components/KeyValueDisplay2";
import CommissionEventLogs from "./CommissionEventLogs";
import ContentBox from "../../components/ContentBox";
import BaseOverlayTrigger from "../../components/BaseOverlayTrigger";
import {Button} from "react-bootstrap";
import Share01 from "@untitled-ui/icons-react/build/esm/Share01";

function CommissionQuotaPeriod(props) {
    const { id } = useParams();
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { t } = useTranslation();
    const [details, setDetails] = useState({});
    const [sectionTitle, setSectionTitle] = useState('');

    useEffect(() => {
        setPageTitle('Quota Period Details');
        loadQuotaPeriod();
    }, [id]);

    const loadQuotaPeriod = () => {
        serverFetch(getApiUrl(`/commission/quota_period/${id}`)).then((res) => {
            if (res) {
                setReport(res);
                const data = {
                    'Sales User': (
                        <Link href={getCompanySpecificUrl(`/commissions/sales_user/${res.sales_user_id}`)}>
                            {res.sales_user.company_user.user.name}
                        </Link>
                    ),
                    'Start Date': moment(res.start_date).format('MMM D, YYYY'),
                    'End Date': moment(res.end_date).format('MMM D, YYYY'),
                    'Commissions Earned': currencyFormatFromPrice(res.commission_achieved),
                    'Quota Achieved': currencyFormatFromPrice(res.quota_achieved) + " / " + currencyFormatFromPrice(res.quota_value),
                };
                setDetails(data);

                const title = `Quota Period - ${res.sales_user.company_user.user.name} (${moment(res.start_date).format('MMM D, YYYY')} - ${moment(res.end_date).format('MMM D, YYYY')})`;
                setSectionTitle(title);
            }
            setLoading(false);
        });
    };

    return (
        <BaseContainer>
            <ContentContainer title={sectionTitle}>
                <Section loading={loading}>
                    <div className='mt-1'>
                        <KeyValueDisplay2 title={'Overview'} items={details} />
                    </div>
                </Section>
                <br/>
                {/*<ContentBox>*/}
                {/*    <ContentBox.Title>Event Logs</ContentBox.Title>*/}
                {/*    <ContentBox.Body>*/}
                {/*        <div className='flex flex-row'>*/}
                {/*            <div className='grow'/>*/}
                {/*            <BaseOverlayTrigger content={'Export'}>*/}
                {/*                <Button variant='text-outline' hasIconOnly>*/}
                {/*                    <Share01 width={20} height={20} />*/}
                {/*                </Button>*/}
                {/*            </BaseOverlayTrigger>*/}
                {/*        </div>*/}
                {/*        <CommissionEventLogs isComponent />*/}
                {/*    </ContentBox.Body>*/}
                {/*</ContentBox>*/}
            </ContentContainer>
        </BaseContainer>
    );
}

export default CommissionQuotaPeriod;