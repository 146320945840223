
export function renderJournalAccount(account, c) {
    const accountNameMap = {
        "ACCOUNTS_RECEIVABLE": "Accounts Receivable",
        "DEFERRED_REVENUE": "Deferred Revenue",
        "REVENUE": "Revenue",
        "UNBILLED_REVENUE": "Unbilled Revenue",
    }
    if (accountNameMap[account.account_type]) {
        return accountNameMap[account.account_type];
    } else {
        return account.account_type;
    }
}
