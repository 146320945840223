import '../../App.scss';
import React from 'react';
import Button from '../common/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { renderChildren, renderComponents } from '../../helpers/common';
import classnames from 'classnames';
import Loader from '../Loader';
import DropdownMenu from '../DropdownMenu';
import ContentBox from '../ContentBox';
const _ = require('lodash');

function BoxedSection(props) {
	const navigate = useNavigate();

	const handleClick = (action) => {
		if (action.onClick) {
			action.onClick();
		} else if (action.link) {
			navigate(action.link);
		}
	};

    const renderTitleRight = () => {
        return (
            <div className="leading-9">
                {props.right && renderComponents(props.right)}
                {props.actions && !props.isActionsDropdown && (
                    <>
                        {_.map(
                            _.filter(props.actions, (a) => !!a),
                            (action, i) => (
                                <Button
                                    key={i}
                                    variant={action.variant}
                                    onClick={() => handleClick(action)}
                                    disabled={action.disabled}
                                    style={action.style}
                                    color={action.color}
                                >
                                    {action.icon && (
                                        <span>
                                            <i className={classnames('fa', action.icon)} />
                                            &nbsp;
                                        </span>
                                    )}
                                    {action.label}
                                </Button>
                            ),
                        )}
                    </>
                )}
                {props.actions && props.isActionsDropdown && (
                    <DropdownMenu
                        className='p-2'
                        items={props.actions.options}
                        onClick={props.actions.onClick}
                        hideCaret
                    >
                        <div className='flex flex-row gap-1 items-center'>
                            <Button variant={'primary'}>
                                {props.actions.icon && (
                                    <span>
                                        <i className={classnames('fa', props.actions.icon)} />
                                        &nbsp;
                                    </span>
                                )}
                                {props.actions.label}
                            </Button>
                        </div>
                    </DropdownMenu>
                )}
            </div>
        );
    };

    const renderBoxedSection = () => {
        return (
            <ContentBox>
                <ContentBox.Title className="!items-start" right={renderTitleRight()} subtitle={
                    props.subtitle && (
                        <div className='text-sm font-normal text-gray-700'>
                            {props.subtitle}
                        </div>
                    )
                }>
                    <div className="leading-9">{props.title}</div>
                </ContentBox.Title>
                <ContentBox.Body contentBoxHasTitle>
                    <Loader loading={props.loading}>{renderChildren(props)}</Loader>
                </ContentBox.Body>
            </ContentBox>
        );
    };

	return (
		<div className={props.className}>
			{renderBoxedSection()}
		</div>
	);
}

export default BoxedSection;

BoxedSection.defaultProps = {
	loading: false,
};
