import '../App.scss';
import React, { useState, useEffect } from 'react';
import BaseOverlayTrigger from './BaseOverlayTrigger';
import classnames from 'classnames';
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import Button from './common/buttons/Button';
const _ = require("lodash");

function CopyableComponent(props) {
    const [value, setValue] = useState(null);
    const [copyableValue, setCopyableValue] = useState(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setValue(props.value || "");
        setCopyableValue(props.copyableValue || props.value || "");
    }, [props.value, props.copyableValue]);

    const onCopy = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        await navigator.clipboard.writeText(copyableValue);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, [2000])
    }

    const onToggle = (show) => {
        if (!show) {
            setTimeout(() => {
                setCopied(false);
            }, [200])
        }
    }

    const getContent = () => {
        if (copied) {
            return <span><i className="fa fa-circle-check fa-c-success"/>&nbsp;Copied</span>
        } else {
            return <span><i className="fa fa-file"/>&nbsp;Copy to Clipboard</span>
        }
    }

    return (
        <div className={classnames("cursor-pointer inline-block", props.className)} onClick={(event) => onCopy(event)}>
            <BaseOverlayTrigger 
                content={getContent()}
                onToggle={onToggle}
                popperConfig={{...(props.isButton ? {
                    modifiers: [{ name: "offset", options: { offset: [0, 5] } }]
                } : {})}}
            >
                {!props.isButton ? (
                    <div className="flex flex-row gap-1 items-center text-subtitle break-all">
                        <DocumentDuplicateIcon className="h-4 w-4 grow-0 shrink-0"/>
                        <span className={classnames("text-xs", { "text-primary-updated hover:text-primary-updated-dark": props.branded })}>{ value }</span>
                    </div>
                ) : (
                    <Button variant="outline-primary">
                        <span className="flex items-center gap-1">
                            <DocumentDuplicateIcon className="h-5 w-5 grow-0 shrink-0"/>
                            {value}
                        </span>
                    </Button>
                )}
            </BaseOverlayTrigger>
        </div>
    );
}

export default CopyableComponent;
