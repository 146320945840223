import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import { useContext, useState, useEffect } from 'react';
import { BaseContext, getCurrencyOptions } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function RefundPaymentModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});
    const [payment, setPayment] = useState({});

    useEffect(() => {
        setInitialFields({
            amount: props.payment && props.payment.total_amount && props.payment.total_amount.value_in_cents/100,
            max_amount: props.payment && props.payment.total_amount && props.payment.total_amount.value_in_cents/100,
            currency: props.payment && props.payment.total_amount && props.payment.total_amount.currency
        })
        setPayment(props.payment)
    }, [props.payment])

    const onSubmit = async (data, errorHandler) => {
        const refundData = {
            reason: data.reason,
            note: data.internal_note,
            amount: {
                value_in_cents: Math.round(parseFloat(data.amount) * 100),
                currency: data.currency
            }
        }
        const res = await serverPost(getApiUrl(`/payments/transactions/${props.payment.id}/refund`), refundData, {}, errorHandler);
        if (res) {
            props.onClose(true);
        }
    }

    const reasonOptions = [
        { value: "requested_by_customer", label: "Requested By Customer" },
        { value: "duplicate", label: "Duplicate" },
        { value: "fraudulent", label: "Fraudulent" },
    ]

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onSubmit={onSubmit}>
                <BaseSideModal.Header title="Refund"/>
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input colSpan={12} name="reason" label="Type" type="select" options={reasonOptions} required showSearch={false}/>
                        <BaseForm.Input colSpan={6} name="amount" label="Amount" type="number" step="0.01" min="0.01"
                            max={initialFields.max_amount} validations={{ required: true, min: 0.01, max: initialFields.max_amount}} />
                        <BaseForm.Input colSpan={6} name="currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} required disabled />
                        <BaseForm.Input colSpan={12} name="internal_note" label={t('common.internal_note')} type="textarea" height="125px"/>
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <div className="d-flex flex-row align-items-end">
                        <SubmitButton>Refund</SubmitButton>
                    </div>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default RefundPaymentModal;
