import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import BaseSideModal from './BaseSideModal';
import { useState, useEffect } from 'react';
import {Table, Row} from 'react-bootstrap';
import Button from '../common/buttons/Button';
import DeleteButton from "../DeleteButton";
const _ = require('lodash');

function EditScheduleModal(props) {
    const [initialFields, setInitialFields] = useState({});
    const [numberOfItems, setNumberOfItems] = useState(0);
    const [actions, setActions] = useState([]);
    const [recurIndefinitely, setRecurIndefinitely] = useState(false);
    const [error, setError] = useState(null);
    const [recurringActions, setRecurringActions] = useState([]);

    useEffect(() => {
        const sitems = _.cloneDeep([...(props.defaultItems || [])]);
        _.each(sitems, (item) => {
            item.days = item.interval_from_date/86400
        })
        let filteredItems = sitems;
        const recurringItem = _.find(sitems, (item) => item.infinite_recurring)
        const hasRecurring = !_.isNil(recurringItem);
        if (hasRecurring) {
            filteredItems = _.filter(filteredItems, (item) => !item.infinite_recurring);
        }
        setInitialFields({
            recur_indefinitely: hasRecurring,
            recur_days: recurringItem ? recurringItem.interval_from_date/86400: 3,
            recur_action_type: recurringItem ? recurringItem.action_type: "notification",
            items: sitems
        })
        setNumberOfItems(filteredItems.length)
        setRecurIndefinitely(hasRecurring);
    }, [props.defaultItems, props.show]);

    useEffect(() => {
        const options = [
            _.includes(props.actions, "payment_retry") && { value: "payment_retry", label: "Retry payment, with email" },
            _.includes(props.actions, "notification") && { value: "notification", label: "Email customer" },
            _.includes(props.actions, "cancel_subscription") && { value: "cancel_subscription", label: "Cancel subscription" },
            _.includes(props.actions, "unpaid_subscription") && { value: "unpaid_subscription", label: "Mark subscription as unpaid" },
            _.includes(props.actions, "admin_notification") && { value: "admin_notification", label: "Notify account owner" },
        ];
        setActions(_.filter(options, op => op));
        setRecurringActions(_.filter(options, op => op && op.value !== "cancel_subscription" && op.value !== "unpaid_subscription"));
    }, [props.actions]);

    const onMoveUp = (index) => {
        setInitialFields(prevFields => {
            const newFields = _.cloneDeep({...prevFields});
            const item = newFields.items[index];
            newFields.items[index] = newFields.items[index-1];
            newFields.items[index-1] = item;
            return newFields;
        })
        setError(null);
    }

    const onMoveDown = (index) => {
        setInitialFields(prevFields => {
            const newFields = _.cloneDeep({...prevFields});
            const item = newFields.items[index];
            newFields.items[index] = newFields.items[index+1];
            newFields.items[index+1] = item;
            return newFields;
        })
        setError(null);
    }

    const onAddNew = () => {
        setInitialFields(prevFields => {
            const newFields = {...prevFields};
            if (newFields.items && newFields.items.length > 0) {
                const lastItem = newFields.items[newFields.items.length-1];
                let lastDay = lastItem.days;
                if (lastItem.infinite_recurring && newFields.items.length > 1) {
                    // The last item is infinitely recurring, look at the item before and add as the second last item.
                    lastDay = newFields.items[newFields.items.length-2].days
                    newFields.items.splice(newFields.items.length-1, 0, { days: lastDay+1 })
                } else {
                    newFields.items = [...newFields.items, { days: lastDay+1 }]
                }
            } else {
                newFields.items = [{ days: 1 }]
            }
            return newFields;
        })
        setNumberOfItems(prevNum => prevNum + 1);
        setError(null);
    }

    const onDeleteRow = (index) => {
        setInitialFields(prevFields => {
            const newFields = _.cloneDeep({...prevFields});
            newFields.items.splice(index, 1);
            return newFields;
        })
        setNumberOfItems(prevNum => prevNum - 1);
        setError(null);
    }

    const onFieldChange = (name, value) => {
        setError(null);
        if (name === "recur_indefinitely") {
            setRecurIndefinitely(value);
        }
    }

    const onSubmit = (data) => {
        let processedData = [];
        let hasEnded = false;
        let hasEventsPastEnding = false;
        let lastNumber = -1000000;
        let wrongOrder = false;
        let referenceDate = props.isContractSchedule ? "proposal.finalized_at" : "invoice.due_at"

        _.each(data.items, (d, i) => {
            if (hasEnded) {
                hasEventsPastEnding = true;
            } else if (_.includes(["cancel_subscription", "unpaid_subscription"], d.action_type)) {
                hasEnded = true
            }

            if (parseInt(d.days) <= lastNumber) {
                wrongOrder = true;
            }
            lastNumber = parseInt(d.days);

            processedData.push({
                sort_key: parseInt(i),
                interval_reference_date_type: referenceDate,
                interval_from_date: parseInt(d.days)*86400,
                action_type: d.action_type
            })
        })
        if (hasEventsPastEnding || (data.recur_indefinitely && hasEnded)) {
            setError("Marking subscription as cancelled or unpaid has to be the last action.");
            return;
        }
        if (wrongOrder) {
            setError("The days in the schedule has to be in an ascending order.")
            return;
        }
        if (data.recur_indefinitely) {
            processedData.push({
                sort_key: numberOfItems,
                infinite_recurring: true,
                interval_reference_date_type: referenceDate,
                interval_from_date: parseInt(data.recur_days)*86400,
                action_type: data.recur_action_type
            })
        }
        if (props.onScheduleUpdated) {
            props.onScheduleUpdated(processedData);
        }
    }

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Form initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={onSubmit}>
                <BaseSideModal.Header title={"Custom Schedule"} />
                <BaseSideModal.Body>
                    <Table borderless>
                        <thead>
                            <tr className="thin">
                                <th className="thin-horizontal">Day</th>
                                <th>Action</th>
                                <th colSpan="3"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(_.range(numberOfItems), (i) =>
                                    <tr className="thin" key={i}>
                                        <td className={"max-w-[75px] thin-horizontal"}>
                                            <BaseForm.Input
                                                type="number" name={`items.${i}.days`} step="1" label="Day" hideLabel
                                                validations={{ required: true }} />
                                        </td>
                                        <td>
                                            <BaseForm.Input type="select" options={actions} name={`items.${i}.action_type`}
                                                            hideLabel required showSearch={false} />
                                        </td>
                                        <td className="thin-horizontal">
                                            {
                                                i !== 0 &&
                                                    <Button variant="text" size="sm"><i className="fa fa-arrow-up"
                                                        onClick={() => onMoveUp(i)}/>
                                                    </Button>
                                            }
                                        </td>
                                        <td className="thin-horizontal">
                                            {
                                                i !== (numberOfItems - 1) &&
                                                <Button variant="text" size="sm"><i className="fa fa-arrow-down"
                                                                                    onClick={() => onMoveDown(i)}/>
                                                </Button>
                                            }
                                        </td>
                                        <td className="thin-horizontal">
                                            <DeleteButton size="sm" onDelete={() => onDeleteRow(i)} />
                                        </td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td colSpan="5" className="thin-horizontal">
                                    <Button variant="outline-primary" className="btn-complete" onClick={onAddNew}>
                                        <i className="fa fa-plus"/>Add New
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="12" className="thin thin-horizontal">
                                    <BaseForm.Input
                                        colSpan={12} name="recur_indefinitely" value="Continue reminders" type="check"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    {
                        recurIndefinitely &&
                            <Row>
                                <BaseForm.Input
                                    colSpan="4"
                                    type="number" name="recur_days" step="1" label="Repeat Every (in days)"
                                    validations={{ required: true }} />
                                <BaseForm.Input
                                    colSpan="8" label="Action" menuPlacement="top"
                                    type="select" options={recurringActions} name={`recur_action_type`}
                                    required showSearch={false} />
                            </Row>
                    }
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <div className="flex flex-row gap-2">
                        {
                            error && <span className="form-error-message align-self-center">{ error }</span>
                        }
                        <SubmitButton disabled={!_.isNil(error)}>Save</SubmitButton>
                    </div>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default EditScheduleModal;
