import React from 'react';
import BaseModal from './BaseModal';
import {Row, Col, Button} from 'react-bootstrap';

function BaseTemplateModal({ show, onClose, title, templates, onTemplateSelect }) {
    return (
		<BaseModal show={show} onClose={onClose} size='lg'>
			<BaseModal.Header>
				<BaseModal.Title>{title}</BaseModal.Title>
			</BaseModal.Header>
			<BaseModal.Body>
				<div className='grid grid-cols-1 gap-6 sm:grid-cols-2'>
					{templates.map(
						(template) =>
							template.value !== 'custom' && (
								<div
									key={template.value}
									className='m-3 relative flex p-6 bg-white border border-gray-200 rounded-lg cursor-pointer'
									onClick={() => onTemplateSelect(template)}
								>
									<div className='flex-grow'>
										<h3 className='text-lg font-semibold text-gray-900'>{template.label}</h3>
										<p className='mt-2 text-sm text-gray-500'>{template.description}</p>
									</div>
									<div className='ml-4 flex-shrink-0 w-16'>
										<img
											src={template.image}
											alt={template.label}
											className='w-full h-full object-contain'
										/>
									</div>
								</div>
							),
					)}
				</div>
			</BaseModal.Body>
			<BaseModal.Footer>
				<Button variant={'primary'} onClick={onClose}>
					Start from scratch
				</Button>
			</BaseModal.Footer>
		</BaseModal>
	);
}

export default BaseTemplateModal;