import '../../App.scss';
import '../../css/modals.scss';
import React, { useContext, useMemo } from 'react';
import BaseForm from '../BaseForm';
import BaseModal from './BaseTailwindModal';
import SubmitButton from '../common/buttons/SubmitButton';
import { getUrl, serverPost } from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getCountryOptions, getCurrencyOptions, UserContext } from '../../helpers/common';

function AddOrEditCompanyModal(props) {
    const { t } = useTranslation('common');
    const { isSuperUser } = useContext(UserContext);
    const initialFields = useMemo(() => {
        return {
            'preferred_currency': "USD"
        }
    }, []);

    const onSubmit = (data, errorHandler) => {
        data.email = data.email.toLowerCase();
        data.slug = data.slug.toLowerCase();
        serverPost(getUrl('/companies'), data, {}, errorHandler).then((res) => {
            if (res) {
                props.onClose(true);
            }
        });
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header title={t('companies.register_company_title')} />
            <BaseForm initialFormFields={initialFields} onSubmit={onSubmit}>
                <BaseModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                        <BaseForm.Input colSpan="12" name="email" label={t('common.email')} type="text"
                                        transformations={["lowercase", "trim"]} validations={{ required: true, validEmail: true }} />
                        <BaseForm.Input colSpan="12" name="slug" label={t('common.slug')} type="text" validations={{ required: true, slugCheck: { getUrl: getUrl } }} />
						{

                            isSuperUser && (
                                <BaseForm.Input colSpan="12" name="customer_id" label={"Maple Customer ID"} type="text" />
                            )
                        }
                        <BaseForm.Input colSpan="12" name="address.country" label={t('common.country')} type="select" options={getCountryOptions()} showSearch={false} />
                        <BaseForm.Input colSpan="12" name="preferred_currency" type="select" label={"Preferred Currency"}
                                        description={"Preferred currency will be used for metric calculations. It does not affect the currency in which the actual invoices will be processed."}
                                        options={getCurrencyOptions()}/>
                        {
                            isSuperUser && (
                                <BaseForm.Input colSpan="12" name="is_development" label={"Is Development"} type="switch" />
                            )
                        }
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <SubmitButton variant="primary">{t('companies.register_company.register')}</SubmitButton>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default AddOrEditCompanyModal;
