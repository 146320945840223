import BaseSideModal from "./BaseSideModal";
import React, {useContext, useEffect, useState} from "react";
import BaseForm from "../BaseForm";
import {serverPatch, serverPost} from "../../helpers/server";
import Notification from "../Notification";
import {BaseContext} from "../../helpers/common";
import {Col, Row} from "react-bootstrap";
import SubmitButton from "../common/buttons/SubmitButton";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import Loader from "../Loader";

function ContractChangeTemplateModal(props) {
    const {getApiUrl} = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        if (props.show) {
            serverPost(getApiUrl("/proposals/templates/find"), {}).then((res) => {
                setLoading(false);
                setTemplates(res);
            });
        }
    }, [props.show]);

    const changeTemplate = async (data) => {
        const updateData = {}
        const template = _.find(templates, t => t.id === data.template_id);
        if (template) {
            updateData.template_id = template.id;
        }
        const res = await serverPatch(getApiUrl(`/proposals/${props.proposal_id}`), updateData);
        if (res) {
            Notification.Success("Successfully updated contract")
        }
        if (props.onExecute) {
            props.onExecute(false, ["template_id", "signatories", "counter_signatories", "custom_variables"]);
        }
        if (props.onClose) {
            props.onClose();
        }
    }

    const templateOptions = _.map(templates, (template) => {
        return {
            value: template.id,
            label: template.name
        }
    })

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form onSubmit={changeTemplate}>
                <BaseSideModal.Header title={"Change contract template?"}/>
                <BaseSideModal.Body>
                    <Loader loading={loading}>
                        <div>
                            <span className="text-sm">Are you sure you want to change the starting template for the contract?</span><br/><br/>
                            <span className="text-sm">You will lose all changes made to the contract document and we will be unable to recover it.</span><br/><br/>
                        </div>
                        <Row>
                            <BaseForm.Input
                                colSpan="12" name="template_id" label={"Template"} type="select" options={templateOptions}
                                showSearch={false}/>
                        </Row>
                    </Loader>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Change</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    )
}

export default ContractChangeTemplateModal;