import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function AddOrEditProductModal(props) {
    const { t } = useTranslation('common');

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemLabel={t('products.product')}
            itemBaseUrl="/products"
        >
            <Row>
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input
                    colSpan="12" name="external_name" label="Public Name" type="text"
                    description="This appears in customer invoices and contracts." />
                <BaseForm.Input colSpan="12" name="description" label={"Internal Description"} type="textarea" height="125px" />
                <BaseForm.Input colSpan="12" name="image_url" label={t('common.logo')} type="file" fileType="invoiceLogo" />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditProductModal;
