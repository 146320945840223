import '../App.scss';
import React, { useState, useEffect } from 'react';
import { getPropsForMetric, currencyFormatFromPrice } from '../helpers/common';
import classnames from 'classnames';
import ContentBox from './ContentBox';
import ArrowUpRight from '@untitled-ui/icons-react/build/esm/ArrowUpRight';
import ArrowDownRight from '@untitled-ui/icons-react/build/esm/ArrowDownRight';
const _ = require('lodash');

function TopBarSummary(props) {
	const [entries, setEntries] = useState([]);

	useEffect(() => {
		setEntries(props.entries);
	}, [props.entries]);

	const renderValue = (value, props) => {
		if (props.type === 'currency') {
			return currencyFormatFromPrice(value);
		} else if (props.type === 'percent') {
			return value.toFixed(2) + '%';
		} else if (props.type === 'integer') {
			return Math.round(parseFloat(value));
		} else {
			return value.toFixed(2);
		}
	};

	const renderCardEntry = (entry, i) => {
		return (
			<ContentBox key={i} noBottomMargin overflowHidden>
				<ContentBox.Body className='!px-[20px] py-3 relative flex flex-col overflow-hidden bg-white'>
					{renderCardContent(entry)}
				</ContentBox.Body>
			</ContentBox>
		);
	};

	const renderCardContent = (entry) => {
		const props = getPropsForMetric(entry.name);
		return (
			<>
				<dt className='text-sm font-normal text-subtitle'>{props.title}</dt>
				<dd className='mt-2 grow flex items-start justify-between md:block lg:flex'>
					<div className='flex items-baseline gap-x-2 text-2xl font-semibold text-dark-gray flex-wrap flex-col'>
						<div className=''>{!_.isNil(entry.value) && renderValue(entry.value, props)}</div>
						{!_.isNil(entry.previous_value) && (
							<span className='text-sm font-normal text-subtitle'>
								from {renderValue(entry.previous_value, props)}
							</span>
						)}
					</div>
					{!_.isNil(entry.change) && (
						<div className="flex leading-[26px] items-center h-8">
							<div className="inline-flex items-baseline rounded-md pl-[6px] pr-2 py-0.5 text-xs font-medium text-gray6 md:mt-2 lg:mt-0 border border-gray3 gap-1">
								{entry.change >= 0 ? (
									<ArrowUpRight
										width={16}
										height={16}
										strokeWidth={1.5}
										className={classnames(
											'flex-shrink-0 self-center',
											entry.change_type === 'increase' ? 'text-[#17B26A]' : 'text-[#F04438]',
										)}
									/>
								) : (
									<ArrowDownRight
										width={16}
										height={16}
										strokeWidth={1.5}
										className={classnames(
											'flex-shrink-0 self-center',
											entry.change_type === 'increase' ? 'text-[#17B26A]' : 'text-[#F04438]',
										)}
									/>
								)}

								<span className='sr-only'>
									{' '}
									{entry.change_type === 'increase' ? 'Increased' : 'Decreased'} by{' '}
								</span>
								{entry.change.toFixed(2) + '%'}
							</div>
						</div>
					)}
				</dd>
			</>
		);
	};

	return (
		<div className={props.className}>
			<h3 className='body1 gray3 leading-6'>Last 30 days</h3>
			<dl className='mt-2 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3'>
				{entries.map((entry, i) => renderCardEntry(entry, i))}
			</dl>
		</div>
	);
}

export default TopBarSummary;
