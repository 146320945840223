import { createRef, useEffect, useMemo, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import BaseForm, { getValue } from "../../../components/BaseForm";
import ContentBox from "../../../components/ContentBox";
import { useOutletContext } from "react-router-dom";
import _ from "lodash";
import DeleteButton from "../../../components/DeleteButton";
import Button from "../../../components/common/buttons/Button";
import SubmitButton from "../../../components/common/buttons/SubmitButton";
import Trash02 from "@untitled-ui/icons-react/build/esm/Trash02";

const paymentAdviceOptions = [
    { value: "ALWAYS", label: "Always show"},
    { value: "ONLY_UNPAID", label: "Only on unpaid invoice"},
    { value: "NONE", label: "Don't show"},
];

const InvoiceSettingsDisplay = () => {
    const { settings, updateSettings, updatePaymentInstructions, updateAdditionalFields } = useOutletContext();
    const [paymentInstructionsEnabled, setPaymentInstructionsEnabled] = useState(false);
    const [showAdditionalFieldsForm, setShowAdditionalFieldsForm] = useState(false);
    const displaySettingsRef = createRef();

    const initialFields = useMemo(() => {
        return {
            ..._.cloneDeep(settings),
        }
    }, [settings]);

    useEffect(() => {
        setPaymentInstructionsEnabled(settings?.invoice_payment_instructions_config?.enabled);
    }, [settings]);

    const onFieldChange = async (name, value) => {
        if (name === "invoice_payment_instructions_config.enabled") {
            setPaymentInstructionsEnabled(value);
        } else if (_.includes(["invoice_options.concise_display", "invoice_options.hide_line_item_breakdown",
            "invoice_payment_instructions_config.enabled", "invoice_options.allow_partial_payments",
            "invoice_payment_instructions_config.content"], name)) {
            if (displaySettingsRef.current) {
                displaySettingsRef.current.submitForm();
            }
        } else if (_.includes(["invoice_options.payment_advice_display_type"], name)) {
            let oldValue = getValue(settings, name, null);
            if (oldValue !== value) {
                if (displaySettingsRef.current) {
                    displaySettingsRef.current.submitForm();
                }
            }
        }
    };

    const deleteAdditionalField = (index) => {
        const newFields = _.filter(settings.invoice_additional_display_fields, (f, i) => String(i) !== String(index));
        const data = {
            invoice_additional_display_fields: newFields
        }
        updateSettings(data);
    };

    const onShowAdditionalFieldsForm = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setShowAdditionalFieldsForm(true);
    };

	return (
        <>
            <ContentBox>
                <ContentBox.Title>Display Settings</ContentBox.Title>
                <ContentBox.Body>
                    <BaseForm ref={displaySettingsRef} initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={updatePaymentInstructions}>
                        <Row>
                            <Col md="8">
                                <BaseForm.Input
                                    type="switch"
                                    label="Hide Empty Line Items In Invoices"
                                    name="invoice_options.concise_display"
                                    required
                                    tooltip={
                                        <span>
                                            In order to make the invoice display very concise, you can hide the empty line items.
                                        </span>
                                    }
                                />
                                <BaseForm.Input
                                    type="switch"
                                    label="Disable Expanding Invoice Line Items In Invoices"
                                    name="invoice_options.hide_line_item_breakdown"
                                    required
                                    tooltip={
                                        <span>
                                            Decide whether to allow for an option to open up each invoice line item row, to provide more details.
                                        </span>
                                    }
                                />
                                <BaseForm.Input
                                    type="select" label="Show Payment Advice in PDF"
                                    name="invoice_options.payment_advice_display_type" showSearch={false}
                                    tooltip={
                                        <span>
                                            Decide whether a payment advice slip should be included at the bottom of the invoice.
                                        </span>
                                    }
                                    options={paymentAdviceOptions}
                                    formClassName="mt-3 mb-3"
                                />
                                <BaseForm.Input
                                    type="switch"
                                    label="Display Payment Instructions on Invoices"
                                    name="invoice_payment_instructions_config.enabled"
                                    required
                                    tooltip={
                                        <span>
                                            Display payment details such as bank and wire transfer information on each invoice sent to your customers <br />{" "}
                                            <em>Note: Any changes made to the payment instructions will only apply to new invoices</em>
                                        </span>
                                    }
                                />
                                {paymentInstructionsEnabled && (
                                    <>
                                        <BaseForm.Input
                                            name="invoice_payment_instructions_config.content"
                                            errorLabel="Payment instructions content"
                                            type="editor"
                                            height="300"
                                            required
                                        />
                                        <br/>
                                    </>
                                )}
                                <BaseForm.Input
                                    type="switch"
                                    label="Allow Partial Payments for Invoices"
                                    name="invoice_options.allow_partial_payments"
                                    required
                                    tooltip={
                                        <span>
                                            Choose if you would like customers to have the option to partial pay an invoice. Regardless of this, the option of marking invoices as paid manually, will always allow partial payments.
                                        </span>
                                    }
                                />
                            </Col>
                        </Row>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Title
                    subtitle="Add any additional information you would like to include in the invoice PDFs such as Tax IDs and contact information."
                >
                    Additional Information
                </ContentBox.Title>
                <ContentBox.Body>
                    <BaseForm initialFormFields={initialFields} onSubmit={updateAdditionalFields}>
                        <Row>
                            <Col lg="9">
                                <Table borderless className="no-background">
                                    <tbody>
                                    {
                                        _.map(settings.invoice_additional_display_fields, (df, i) =>
                                            <tr className="thin-horizontal" key={i}>
                                                <td width="40%">
                                                    <BaseForm.Input type="text" name={`invoice_additional_display_fields.${i}.name`} label="Name" hideLabel disabled/>
                                                </td>
                                                <td width="40%">
                                                    <BaseForm.Input type="text" name={`invoice_additional_display_fields.${i}.value`} label="Value" hideLabel disabled/>
                                                </td>
                                                <td><DeleteButton onDelete={() => deleteAdditionalField(i)} overlayContent="Delete"></DeleteButton></td>
                                            </tr>
                                        )
                                    }
                                    {
                                        showAdditionalFieldsForm ?
                                            <>
                                                <tr className="thin-horizontal caption">
                                                    <td width="40%">
                                                        <BaseForm.Input type="text" name={`invoice_additional_display_fields.${settings.invoice_additional_display_fields.length}.name`} placeholder="Name" label="Name" hideLabel required />
                                                    </td>
                                                    <td width="40%">
                                                        <BaseForm.Input type="text" name={`invoice_additional_display_fields.${settings.invoice_additional_display_fields.length}.value`} placeholder="Value" label="Value" hideLabel required />
                                                    </td>
                                                    <td>
                                                        <Button variant="text-danger" onClick={() => setShowAdditionalFieldsForm(false)} overlayContent="Delete" ><Trash02 width={20} height={20} /></Button>
                                                    </td>
                                                </tr>
                                                <tr className="thin-horizontal">
                                                    <td colSpan="3">
                                                        <SubmitButton>Save</SubmitButton>
                                                    </td>
                                                </tr>
                                            </>
                                        : <tr className="thin-horizontal">
                                                <td colSpan="3">
                                                    <span className="text-primary-updated hover:text-primary-updated-dark cursor-pointer" onClick={onShowAdditionalFieldsForm}><i className="fa fa-plus"/> Add Field</span>
                                                </td>
                                            </tr>
                                    }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
        </>
    );
};

export default InvoiceSettingsDisplay;
