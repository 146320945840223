import React, { useMemo, useCallback, useState } from 'react';
import Button from '../../common/buttons/Button';

const FilterMultiSelect = ({ selectedFilters, onFieldChange, filter }) => {
	const [isShowingMore, setIsShowingMore] = useState(false);
	const value = useMemo(() => selectedFilters[filter.name] ?? null, [selectedFilters, filter.name]);
	
	const groupedOptions = useMemo(() => {
		return filter.options.reduce((acc, option) => {
			const group = acc[option.group] || [];
			group.push(option);
			acc[option.group] = group;
			return acc;
		}, {});
	}, [filter.options]);

	const onCheckboxChange = (name, value, checked) => {
		let newValue = selectedFilters[name] || [];
		if (checked) {
			newValue = [...newValue, value];
		} else {
			newValue = newValue.filter((v) => v !== value);
		}
		onFieldChange(name, newValue);
	};

	const onCheckboxChangeAll = (name, group) => {
		let newValue = selectedFilters[name] || [];
		const options = groupedOptions[group].map(option => option.value);

		const difference = options.filter((option) => !newValue.includes(option));
		if (difference.length > 0) {
			newValue = [...newValue, ...difference];
		} else {
			newValue = newValue.filter((v) => !options.includes(v));
		}
		onFieldChange(name, newValue);
	};

	const getDefaultChecked = useCallback(
		(option) => {
			return value && value.includes(option.value);
		},
		[value],
	);

	const getIsGroupChecked = useCallback(
		(group) => {
			return Object.keys(groupedOptions).some((key) => key === group && groupedOptions[key].every(getDefaultChecked));
		},
		[groupedOptions, getDefaultChecked],
	);

	return (
		<div className='space-y-3'>
			{Object.keys(groupedOptions).map((group) => {
				const LIMIT = 6;
				const hasManyOptions = groupedOptions[group].length > LIMIT;
				const filteredOptions = !isShowingMore ? groupedOptions[group].slice(0, LIMIT) : groupedOptions[group];
				return (
					<React.Fragment key={group}>
						{group !== 'undefined' && (
							<>
								<div className='filter-multiselect-group flex itmes-center text-gray5 leading-[18px] [&:first-child]:!mt-[8px] !mb-[12px]'>
									<input
										id={group}
										name={group}
										type='checkbox'
										checked={getIsGroupChecked(group)}
										value={getIsGroupChecked(group) || ''}
										onChange={(event) => onCheckboxChangeAll(filter.name, group, event.target.checked)}
										className='border-gray3 text-primary-updated rounded'
									/>
									<label htmlFor={group} className="ml-2 text-xs">
										{group}
									</label>
								</div>
							</>
						)}
						<div className="filter-multiselect-items space-y-3">
							{filteredOptions.map((option, i) => (
								<div key={i} className='flex items-center'>
									<input
										id={option.value}
										name={option.value}
										type='checkbox'
										checked={value && value.includes(option.value)}
										value={option.value || ''}
										onChange={(event) => onCheckboxChange(filter.name, option.value, event.target.checked)}
										className='border-gray3 text-primary-updated rounded'
									/>
									<label
										htmlFor={option.value}
										className='ml-2 block text-sm font-normal leading-[18px] text-dark-gray'
									>
										{option.renderLabel ? option.renderLabel(option.label) : option.label}
									</label>
								</div>
							))}
							{hasManyOptions && (
								<Button variant="text-primary" onClick={() => setIsShowingMore(!isShowingMore)} className="px-0 !text-xs">
									{!isShowingMore ? "Show more" : "Show less"}
								</Button>
							)}
						</div>
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default FilterMultiSelect;
