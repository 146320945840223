import '../../App.scss';
import '../../css/modals.scss';
import React, {useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function AddOrEditContractVariableModal(props) {
    const { t } = useTranslation('common');
    const [initialFormFields, setInitialFormFields] = useState({});
    const [keyChanged, setKeyChanged] = useState(false);
    const [type, setType] = useState("BOOL");

    useEffect(() => {
        if (!props.show) {
            setInitialFormFields({});
            setKeyChanged(false);
        } else {
            setType(props.itemToEdit ? props.itemToEdit.type: "BOOL")
        }
    }, [props.show, props.itemToEdit])

    const onFieldChange = (name, value) => {
        if (name === "name") {
            setInitialFormFields(prevFields => {
                const newFields = {...prevFields};
                if (!keyChanged) {
                    newFields.key = value.trim().replaceAll(' ', '-').toLowerCase();
                }
                newFields.name = value;
                return newFields;
            })
        } else if (name === "key") {
            setInitialFormFields(prevFields => {
                const newFields = {...prevFields};
                newFields.key = value;
                return newFields;
            })
            setKeyChanged(true);
        } else if (name === "type") {
            setType(value);
            setInitialFormFields(prevFields => {
                const newFields = {...prevFields};
                newFields.type = value;
                return newFields;
            })
        }
    }

    const processFields = (fields) => {
        fields.provider = "COMPANY";
        return fields;
    }

    const typeOptions = [
        { value: "string", label: "Text" },
        { value: "enum", label: "Choice" },
        { value: "date", label: "Date" },
        { value: "datetime", label: "Date/Time" },
    ]

    const isEditing = !_.isNil(props.itemToEdit);
    return (
        <BaseAddOrEditItemModal
            {...props}
            onFieldChange={onFieldChange}
            processAddFields={processFields}
            processUpdateFields={processFields}
            initialFormFields={initialFormFields}
            itemLabel={"Custom Variable"}
            itemBaseUrl="/proposals/variables"
        >
            <Row>
                <BaseForm.Input
                    colSpan="12" name="type" label="Type" type="select" options={typeOptions} required
                    disabled={isEditing} showSearch={false}
                />
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="12" name="key" label={"Key"} type="text" required disabled={isEditing} />
                {
                    type === "enum" &&
                        <BaseForm.Input
                            colSpan="12" name="possible_values" label={"Choices"} type="selector"
                            isMulti={true} canCreate={true} formatCreateLabel={(inputValue) => `Add choice - ${inputValue}`}
                            isValidNewOption={(option) => _.trim(option).length !== 0 } />
                }
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditContractVariableModal;
