import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Link from '../../components/Link';
import { serverFetch, serverPatch } from '../../helpers/server';
import {
    BaseContext, getNameForProviderType, renderDescriptionForRecognitionSchedule, UserContext,
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import ArchiveButton from '../../components/common/buttons/ArchiveButton';
import ContentContainer from '../../components/ContentContainer';
import ChangeProductModal from "../../components/modals/ChangeProductModal";
import SelectEntitlements from "../../components/SelectEntitlements";
import BundlePricingTable from "../../components/BundlePricingTable";
import Loader from "../../components/Loader";
import ErrorComponent from "../../components/ErrorComponent";
import {getExternalProductPricingReferenceURL} from "../../helpers/integrations";
import Tags from "../../components/Tags";
import EditProductPricingModal from "../../components/modals/EditProductPricingModal";
import MapleTable from "../../components/MapleTable";
import CopyableComponent from "../../components/CopyableComponent";
import Button from "../../components/common/buttons/Button";
import EditRecognitionScheduleModal from "../../components/modals/EditRecognitionScheduleModal";
import BoxedSection from '../../components/layout/BoxedSection';
import DropdownMenu from "../../components/DropdownMenu";
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import ArchiveModal from "../../components/modals/ArchiveModal";
import UnarchiveModal from "../../components/modals/UnarchiveModal";
import Label from "../../components/Label";
import DotsVertical from '@untitled-ui/icons-react/build/esm/DotsVertical';
const _ = require('lodash');

function ProductPricing(props) {
    const { t } = useTranslation('common');
    const { userInfo, isMapleUser } = useContext(UserContext);
    const { pricingId } = useParams();
    const { getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [loadingPrice, setLoadingPrice] = useState(true);
    const [pricing, setPricing] = useState({});
    const [details, setDetails] = useState({});
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [isEditingEntitlements, setIsEditingEntitlements] = useState(false);
    const [showEditProductPricingModal, setShowEditProductPricingModal] = useState(false);
    const [showChangeProductModal, setShowChangeProductModal] = useState(false);
    const [showEditRecognitionScheduleModal, setShowEditRecognitionScheduleModal] = useState(false);
    const [showPricingArchiveModal, setShowPricingArchiveModal] = useState(false);
    const [showPricingUnarchiveModal, setShowPricingUnarchiveModal] = useState(false);
    const [itemPricingToEdit, setItemPricingToEdit] = useState(null);

    const hasWritePermission = hasAccess("pricing", userInfo, "write");

    useEffect(() => {
        setPageTitle(`Pricing - ${pricing.name}`);
    }, [pricing]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/product_pricings/${pricingId}`), { skipCache }).then((res) => {
            if (res) {
                setPricing(res);
            }
            setLoadingPrice(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const data = {
            'Product': !_.isEmpty(pricing) && <Link to={getCompanySpecificUrl(`/product/${pricing.product_id}`)}>{pricing.product.name}</Link>,
            'Name': pricing.name,
            'Public Name': pricing.external_name,
            'Internal Description': pricing.description,
            'Tags': <Tags tags={pricing.tags} onUpdate={updateTags} tagsUrl={getApiUrl(`/product_pricings/tags`)} alignRight />
        };
        if (!_.isNil(pricing.imported_from)) {
            const externalReferenceUrl = getExternalProductPricingReferenceURL(pricing);
            if (externalReferenceUrl) {
                data[`${getNameForProviderType(pricing.imported_from)} Reference`] =
                    <a href={externalReferenceUrl} target="_blank">{ pricing.import_ref }</a>;
            }
        }
        setDetails(data)
    }, [pricing]);

    const onModalClose = () => {
        setShowEditProductPricingModal(false);
        setShowChangeProductModal(false);
        setShowEditRecognitionScheduleModal(false);
        setShowPricingArchiveModal(false);
        setShowPricingUnarchiveModal(false);
        fetchData(true);
    }

    const updateTags = async (data) => {
        const result = await serverPatch(getApiUrl(`/product_pricings/${pricingId}`), data)
        if (result) {
            fetchData(true);
        }
        return result;
    }

    const editPricing = () => {
        setShowEditProductPricingModal(true);
    }

    const onArchiveProductPricing = (pricing) => {
        serverPatch(getApiUrl(`/product_pricings/${pricing.id}`), { state: "ARCHIVED" }).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const onUnarchiveProductPricing = (pricing) => {
        serverPatch(getApiUrl(`/product_pricings/${pricing.id}`), { state: "ACTIVE" }).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const editMetadata = () => {
        setIsEditingMetadata(true);
    }

    const saveMetadata = (newMetadata) => {
        const processedData = {
            metadata: newMetadata
        };

        serverPatch(getApiUrl(`/product_pricings/${pricingId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingMetadata(false);
        });
    }

    const editEntitlements = () => {
        setIsEditingEntitlements(true);
    }

    const saveEntitlements = (newEntitlements) => {
        const processedData = {
            entitlements: newEntitlements
        };

        serverPatch(getApiUrl(`/product_pricings/${pricingId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingEntitlements(false);
        });
    }

    const editRecognitionSchedule = (itemPricing) => {
        setShowEditRecognitionScheduleModal(true);
        setItemPricingToEdit(itemPricing);
    }

    const renderPricingStatus = () => {
        let statusDescription = null;
        if (pricing.state === "ACTIVE") {
            statusDescription = <Label.Success className="d-flex align-items-center">Active</Label.Success>
        } else if (pricing.state === "ARCHIVED") {
            statusDescription = <Label.Info className="d-flex align-items-center">Archived</Label.Info>
        }
        return statusDescription;
    }

    const onActionSelected = (action) => {
        if (action === "edit") {
            editPricing();
        } else if (action === "archive") {
            setShowPricingArchiveModal(true);
        } else if (action === "unarchive") {
            setShowPricingUnarchiveModal(true);
        }
    }

    const renderActions = () => {
        if (!hasWritePermission) {
            return [];
        }

        const actionOptions = [
            { id: "edit", label: "Edit" },
            { divider: true },
            pricing.state !== "ARCHIVED" && { id: "archive", label: "Archive" },
            pricing.state === "ARCHIVED" && { id: "unarchive", label: "Unarchive" },
        ];
        const actionOptionsFiltered = actionOptions.slice(2);
        return (!isMapleUser ? (
            <DropdownMenu className="p-2" items={actionOptions} onClick={onActionSelected}>
                <div className="flex flex-row gap-1 items-center">
                    <WrenchScrewdriverIcon className="h-4 w-4"/>
                    <span>Actions</span>
                </div>
            </DropdownMenu>
        ) : (
            <div className="flex gap-[12px] items-center">
                <Button onClick={() => onActionSelected("edit")}>Edit</Button>
                {actionOptionsFiltered.length > 0 && <DropdownMenu items={actionOptionsFiltered} onClick={onActionSelected} hideCaret>
                    <Button variant="text-outline" hasIconOnly>
                        <DotsVertical width={20} height={20} />
                    </Button>
                </DropdownMenu>}
            </div>
        ))
    }

    return (
        <BaseContainer>
            <Loader loading={loadingPrice}>
            {
                _.isEmpty(pricing) ?
                    <ErrorComponent
                        title={"Product Price Not Found"}
                        description={"Oops, we can't find the price you are looking for."}
                        primaryAction={{ href:getCompanySpecificUrl("/products"), label:"Show all products" }}
                        hideSupport
                    />
                : <>
                    <ContentContainer variant="page" title={
                        <div className="d-flex flex-row gap-3">
                            <span>{ pricing.name }</span>
                            <div className="d-flex align-items-center gap-3">
                                { renderPricingStatus() }
                            </div>
                        </div>
                    } customActions={renderActions()} enableStickyHeader={isMapleUser}>
                        <KeyValueDisplay2 title="Details" items={details} />
                        <BoxedSection title="Entitlements" className="mt-4" actions={!isEditingEntitlements && [
                            {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editEntitlements}
                        ]}>
                            <div className="max-w-3xl">
                                <SelectEntitlements
                                    entitlements={pricing.computed_entitlements}
                                    isEditing={isEditingEntitlements}
                                    onUpdateEntitlements={saveEntitlements}
                                    onCancelEditing={() => setIsEditingEntitlements(false)}
                                    removeTableSpacing
                                />
                            </div>
                        </BoxedSection>
                        <BoxedSection title="Metadata" className="mt-4" actions={[
                            {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editMetadata}
                        ]} subtitle="These are fields that are stored on the product pricing for internal reference.">
                            {
                                (!isEditingMetadata && (_.isNil(pricing.metadata) || _.isEmpty(pricing.metadata))) ?
                                    <p className="text-gray-500">No metadata</p>
                                    : <KeyValueDisplay items={pricing.metadata} isEditing={isEditingMetadata}
                                                       onUpdateKeyValues={saveMetadata}
                                                       onCancelEditing={() => setIsEditingMetadata(false)}/>
                            }
                        </BoxedSection>
                        <BoxedSection title="Pricing Details" className="mt-4">
                            <BundlePricingTable product_pricing_ids={pricing.id && [pricing.id]} showProductMetricPricingID removeTableSpacing />
                        </BoxedSection>
                        {
                            isMapleUser &&
                            <BoxedSection title="Recognition Schedule" className="mt-4">
                                <MapleTable>
                                    <MapleTable.Content removeMargin removePadding>
                                        <thead>
                                        <tr>
                                            <MapleTable.TH>Description</MapleTable.TH>
                                            <MapleTable.TH>Recognition Schedule</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            !_.isEmpty(pricing.product_metric_pricings) && _.map(pricing.product_metric_pricings, (pmp, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        <div className="flex flex-col">
                                                            <span className="body2">{pricing.product.name} - {pricing.name}</span>
                                                            <CopyableComponent className="mt-2" value={ pricing.id }/>
                                                        </div>
                                                    </td>
                                                    <td>{ renderDescriptionForRecognitionSchedule(pmp.item_pricing) }</td>
                                                    <td>
                                                        <div className="flex flex-row justify-end">
                                                            <Button variant="text-primary" size="sm" onClick={() => editRecognitionSchedule(pmp.item_pricing)} overlayContent="Edit" className="flex items-center">
                                                                <i className="fa fa-edit text-base" />
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                            </BoxedSection>
                        }
                    </ContentContainer>
                    <EditProductPricingModal show={showEditProductPricingModal} onClose={onModalClose} itemToEdit={pricing} />
                    <ChangeProductModal show={showChangeProductModal} onClose={onModalClose} pricing={pricing} />
                    <ArchiveModal show={showPricingArchiveModal} onConfirm={() => onArchiveProductPricing(pricing)} onClose={onModalClose} />
                    <UnarchiveModal show={showPricingUnarchiveModal} onConfirm={() => onUnarchiveProductPricing(pricing)} onClose={onModalClose} />
                    <EditRecognitionScheduleModal
                        show={showEditRecognitionScheduleModal} onClose={onModalClose} itemPricing={itemPricingToEdit}
                    />
                </>
            }
            </Loader>
        </BaseContainer>
    );
}

export default ProductPricing;
