import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React, {useContext, useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseSideModal from './BaseSideModal';
import SubmitButton from '../common/buttons/SubmitButton';
import Notification from '../Notification';
import { BaseContext } from '../../helpers/common';
import {serverPatch, serverPost} from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function UpdateCompanyUserModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [initialFields, setInitialFields] = useState(null);
    const [disableUpdateRole, setDisableUpdateRole] = useState(true);

    useEffect(() => {
        if (props.companyUser) {
            setInitialFields({...props.companyUser})
            if (props.companyUser.role !== "default_admin" || props.numberOfFullAdmins > 1 || props.companyUser.pending) {
                setDisableUpdateRole(false)
            } else {
                setDisableUpdateRole(true)
            }
        } else {
            setInitialFields({});
        }
    }, [props.companyUser]);

    const updateTeamMember = async (data, errorHandler) => {
        await serverPatch(getApiUrl(`/users/${props.companyUser.id}`), data, {}, errorHandler).then(res => {
            if (res) {
                props.onClose();
                Notification.Success("Updated successfully");
            }
        });
    }

    const roleOptions = [
        { value: "default_admin", label: "Full Admin" },
        { value: "default_sales_rw", label: "Sales Admin" },
    ]

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onSubmit={updateTeamMember}>
                <BaseSideModal.Header title={"Update User"}/>
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="12" name="title" label={t('Title')} type="text" />
                        <BaseForm.Input colSpan="12" name="role" label={t('common.role')} type="select" options={roleOptions} showSearch={false} disabled={disableUpdateRole} />
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">Update</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default UpdateCompanyUserModal;
