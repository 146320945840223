import '../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import {serverPost, serverDelete, serverFetch} from '../../helpers/server';
import {
    BaseContext, currencyFormatFromPrice, getChangeContractChangeTimeLabel, renderContractStatusLabel, UserContext
} from '../../helpers/common';
import Columns from '../../components/Columns';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import DeleteButton from '../../components/DeleteButton';
import MapleTable from '../../components/MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import SortableTableHeader from "../../components/SortableTableHeader";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import EmptyState from "../../components/EmptyState";
import TopBarSummary from "../../components/TopBarSummary";
import BaseCSVExportModal from "../../components/modals/BaseCSVExportModal";
import {getContractExportFields} from "../../helpers/exportFields";
import Label from "../../components/Label";
const _ = require('lodash');

const renderGreenLabel = (label) => <div className="text-xs px-[6px] py-[2px] rounded-[6px] border border-[#ABEFC6] bg-[#ECFDF3] text-[#067647]">{label}</div>
const renderYellowLabel = (label) => <div className="text-xs px-[6px] py-[2px] rounded-[6px] border border-[#FEDF89] bg-[#FFFAEB] text-[#B54708]">{label}</div>
const renderRedLabel = (label) => <div className="text-xs px-[6px] py-[2px] rounded-[6px] border border-[#FECDCA] bg-[#FEF3F2] text-[#B42318]">{label}</div>
const renderGreyLabel = (label) => <div className="text-xs px-[6px] py-[2px] rounded-[6px] border border-[#E9EAEB] bg-[#FAFAFA] text-[#414651]">{label}</div>
const renderBlueLabel = (label) => <div className="text-xs px-[6px] py-[2px] rounded-[6px] border border-[#E5E7EB] bg-[#EBF5FF] text-[#1A56DB]">{label}</div>

const STATUS_OPTIONS = [
    { value: "EXECUTED", label: "Executed", renderLabel: renderGreenLabel, group: "Finalized" },
    { value: "PENDING_SIGNATURES", label: "Pending Signatures", renderLabel: renderYellowLabel, group: "In progress" },
    { value: "PENDING_PAYMENT", label: "Pending Payment Method", renderLabel: renderYellowLabel, group: "In progress" },
    { value: "NEEDS_APPROVAL", label: "Waiting for Approval", renderLabel: renderYellowLabel, group: "In progress" },
    { value: "AWAITING_MANUAL_ACTION", label: "Awaiting Manual Confirmation", renderLabel: renderBlueLabel, group: "In progress" },
    { value: "DRAFT", label: "Draft", renderLabel: renderGreyLabel, group: "Not proceeded" },
    { value: "DECLINED", label: "Declined", renderLabel: renderRedLabel, group: "Not proceeded" }
]

const PAYMENT_MECHANISM_OPTIONS = [
    { value: null, label: "All" },
    { value: true, label: "Automatic" },
    { value: false, label: "Manual Invoice" },
]

function Contracts() {
    const navigate = useNavigate();
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const { userInfo } = useContext(UserContext);
    const [contracts, setContracts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [loading, setLoading] = useState(true);
    const [initialFields, setInitialFields] = useState({ sort: "createdAtDesc" })
    const [query, setQuery] = useState(null)
    const [meta, setMeta] = useState(null)
    const [filters, setFilters] = useState([]);
    const [showExportModal, setShowExportModal] = useState(false);

    const defaultSelectedFilters = useMemo(() => {
        return {};
    }, []);
    const cachedSelectedFilters = useMemo(() => {
        const cachedString = localStorage.getItem(company.id + "_filter_cache_contracts");
        if (cachedString) {
            return JSON.parse(cachedString);
        }
    }, [])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);
    const [contractsMetricsSummary, setContractsMetricsSummary] = useState(null)

    const hasContractsWritePermission = hasAccess("proposal", userInfo, "write");

    useEffect(() => {
        setPageTitle(`Contracts`);
    }, []);

    useEffect(() => {
        serverPost(getApiUrl(`/reports/contracts_metrics_summary`), {}).then(res => {
            setContractsMetricsSummary(res)
        })
    }, [])

    useEffect(() => {
        setFilters([
            { title: "Status", type: "multi-select", name: "statuses", options: STATUS_OPTIONS},
            { title: "Payment Method", type: "select", name: "auto_charges", options: PAYMENT_MECHANISM_OPTIONS },
            { title: "Finalized Date", type: "date", name: "finalized_date" },
            { title: "Signed Date", type: "date", name: "signed_date" },
            { title: "Completed Date", type: "date", name: "completed_date" }
        ])
    }, [])

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                ...selectedFilters,
                statuses: _.isEmpty(selectedFilters.statuses) || selectedFilters.statuses.length === STATUS_OPTIONS.length ? null: selectedFilters.statuses,
                type: "CONTRACT"
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/proposals/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                _.each(results, (r) => {
                    r.isExpired = r.status === "EXPIRED";
                })
                if (restart) {
                    setContracts(results);
                } else {
                    setContracts(_.concat(contracts, results));
                }
                if (res.meta) {
                    setMeta(res.meta);
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [sort, selectedFilters]);

    const renderExpirationDate = (contract) => {
        if (_.isNil(contract.expiration_date)) {
            return null;
        }
        if (contract.status !== "PENDING_SIGNATURES") {
            return null;
        }

        let isExpired = moment(contract.expiration_date).isBefore(moment());
        if (isExpired) {
            return (
                <div className="caption danger-color">Expired</div>
            )
        } else {
            return (
                <div className="caption">Expires { moment(contract.expiration_date).fromNow() }</div>
            )
        }
    }

    const onParamsChange = (data) => {
        setSelectedFilters(data);
        localStorage.setItem(company.id + "_filter_cache_contracts", JSON.stringify(data));
    }

    const deleteContract = async (contract) => {
        await serverDelete(getApiUrl(`/proposals/${contract.id}`)).then(res => {
            onSearch(true);
        })
    }

    const getQueryParams = () => {
        const query = {
            ...selectedFilters,
            search: selectedFilters.search,
            type: "CONTRACT",
            statuses: _.isEmpty(selectedFilters.statuses) || selectedFilters.statuses.length === STATUS_OPTIONS.length ? null: selectedFilters.statuses,
            auto_charges: selectedFilters.auto_charges,
        }
        if (!_.isNil(selectedFilters.owner_id)) {
            if (selectedFilters.owner_id === "unassigned") {
                query.owner_id = null
            } else {
                query.owner_id = selectedFilters.owner_id
            }
        }
        return query;
    }

    const renderReminderSteps = (contract) => {
        if (!contract.campaigns || contract.campaigns.length === 0) {
            return "--";
        }

        const activeCampaign = contract.campaigns.find(c => c.status === "ACTIVE");
        if (!activeCampaign) {
            const sortedCampaigns = contract.campaigns.toSorted((a, b) => {
                return b.updated_at - a.updated_at
            })
            if (sortedCampaigns[0].status === "COMPLETED") {
                return "Completed"
            } else if (sortedCampaigns[0].status === "STOPPED") {
                return "Stopped"
            }
            return "--";
        }

        return `${activeCampaign.current_step-1}/${activeCampaign.total_steps}`;
    }

    const isEmpty = contracts.length === 0 && !hasMore && _.isEmpty(selectedFilters) && !loading;
    const showTopMetricsSummary = !_.isEmpty(contractsMetricsSummary);
    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={contracts.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="Contracts"
                             variant="page"
                             loading={loading}
                             actions={!isEmpty && !loading && hasContractsWritePermission && [{
                                variant: "primary",
                                icon: "fa-plus",
                                label: "Create Contract",
                                link: getCompanySpecificUrl("/contract/create")
                             }]}>
                        {
                            showTopMetricsSummary &&
                            <TopBarSummary className="mt-1 mb-8" entries={contractsMetricsSummary}/>
                        }
                        {
                            isEmpty ?
                                <EmptyState
                                    className={ showTopMetricsSummary ? "mt-8": "" }
                                    icon={DocumentPlusIcon}
                                    title={"No contracts."}
                                    subtitle={"Get started by creating a new one."}
                                    buttonLabel={"New Contract"}
                                    onClick={() => navigate(getCompanySpecificUrl("/contract/create"))}
                                />
                            : <MapleTable className={ showTopMetricsSummary ? "mt-8": "" }>
                                    <MapleTableHeaderWithActions
                                        showSearch={true}
                                        searchPlaceholder="Search Contracts"
                                        showFilters={true}
                                        showExport={true}
                                        onExport={() => setShowExportModal(true)}
                                        meta={meta}
                                        onParamsChange={onParamsChange}
                                        filters={filters}
                                        defaultSelectedFilters={defaultSelectedFilters}
                                        cachedSelectedFilters={cachedSelectedFilters}
                                    />
                                    <MapleTable.Content>
                                        <thead>
                                            <tr>
                                                <MapleTable.TH>Customer</MapleTable.TH>
                                                <MapleTable.TH className="d-none d-md-table-cell">Title</MapleTable.TH>
                                                <MapleTable.TH>Status</MapleTable.TH>
                                                <MapleTable.TH className="text-end">Value</MapleTable.TH>
                                                <SortableTableHeader
                                                    onSortChange={setSort} sortKeyUp="createdAtAsc" sortKeyDown="createdAtDesc"
                                                    currentSort={sort} className="whitespace-nowrap flex-nowrap d-none d-xl-table-cell">
                                                    Created At
                                                </SortableTableHeader>
                                                <MapleTable.TH className="no-stretch d-none d-md-table-cell">Owner</MapleTable.TH>
                                                <MapleTable.TH>Reminders</MapleTable.TH>
                                                <MapleTable.TH></MapleTable.TH>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            _.map(contracts, (row, i) =>
                                                <MapleTable.TR key={i} className="cursor-pointer"
                                                               href={getCompanySpecificUrl(`/contract/view/${row.id}`)}>
                                                    <td>
                                                        <Columns.CustomerName customer={row.customer}/>
                                                    </td>
                                                    <td className="d-none d-md-table-cell">
                                                        <div className="flex flex-col">
                                                            <span>{row.title}</span>
                                                            { getChangeContractChangeTimeLabel(row) }
                                                        </div>
                                                    </td>
                                                    <td className="break-words">{renderContractStatusLabel(row)}</td>
                                                    <td className="text-end">
                                                        <span>{currencyFormatFromPrice(row.tcv)}</span><br/>
                                                        <span
                                                            className="caption italic">{currencyFormatFromPrice(row.acv)}/yr</span><br/>
                                                    </td>
                                                    <td className="d-none d-xl-table-cell">
                                                        <span>{moment(row.created_at).format("MMM D, YYYY")}</span>
                                                        {renderExpirationDate(row)}
                                                    </td>
                                                    <td className="w-px whitespace-nowrap d-none d-md-table-cell">
                                                        {
                                                            row.owner &&
                                                            <Columns.CompanyUserAvatar companyUser={row.owner}/>
                                                        }
                                                    </td>
                                                    <td className="text-center d-none d-md-table-cell">
                                                        {renderReminderSteps(row)}
                                                    </td>
                                                    <td onClick={(e) => e.stopPropagation()} className="!pl-0 !pr-2">
                                                        {
                                                            row.status !== "EXECUTED" && row.status !== "COMPLETE" &&
                                                            <DeleteButton size={"sm"}
                                                                          body={row.status !== "DRAFT" && <div>
                                                                              <span>Are you sure you want to delete this contract?</span><br/><br/>
                                                                              <span>Any existing signatures will be invalidated, and all contract documents will no longer be accessible.</span>
                                                                          </div>}
                                                                          confirmationText={row.status !== "DRAFT" && "CONFIRM DELETE"}
                                                                          onDelete={() => deleteContract(row)}
                                                                          overlayContent="Delete" />
                                                        }
                                                    </td>
                                                </MapleTable.TR>
                                            )
                                        }
                                        {
                                            hasMore &&
                                            <tr>
                                                <td colSpan="10" className="text-center">
                                                    <div className="spinner-border text-secondary"/>
                                                    </td>
                                                </tr>
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                        }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
            <BaseCSVExportModal
                show={showExportModal}
                onClose={setShowExportModal}
                sort={sort}
                useQuery={true}
                query={getQueryParams()}
                title={"Contracts CSV Export"}
                fields={getContractExportFields()}
                filenamePrefix="Contracts-CSV"
                cacheKey={company.id + "_csv_contracts"}
                exportUrl={'/proposals/export'} />
        </BaseContainer>
    );
}

export default Contracts;
