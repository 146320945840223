import '../App.scss';
import React from 'react';
import { renderChildren } from '../helpers/common';
import ConfirmationButton from './common/buttons/ConfirmationButton';
import Trash02 from '@untitled-ui/icons-react/build/esm/Trash02';
const _ = require('lodash');

function DeleteButton(props) {
	const getIconSize = (size) => {
		switch (size) {
			case 'sm':
				return 18;
			default:
				return 20;
		}
	};

	return (
		<ConfirmationButton
			size={props.size}
			onConfirm={props.onDelete}
			variant={props.variant || 'text-danger'}
			title={props.title || 'Confirm Delete'}
			body={props.body || 'Are you sure you want to delete?'}
			confirmationText={props.confirmationText}
			buttonClassName={props.buttonClassName}
			className={props.className}
			buttonTitle={props.buttonTitle}
            overlayContent={props.overlayContent}
		>
			{!_.isEmpty(props.children) ? (
				renderChildren(props)
			) : <Trash02 width={getIconSize(props.size)} height={getIconSize(props.size)} />}
			
		</ConfirmationButton>
	);
}

export default DeleteButton;
