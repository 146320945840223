import React, {useContext, useEffect, useState} from "react";
import {BaseContext, getCustomerNameOrEmail, renderContractStatusLabel, UserContext} from "../../../helpers/common";
import _ from "lodash";
import {Outlet, useNavigate, useSearchParams} from "react-router-dom";
import {serverDelete, serverFetch, serverPatch, serverPost} from "../../../helpers/server";
import Notification from '../../../components/Notification';
import customer from "../Customer";
import BaseContainer from "../../../components/BaseContainer";
import Section from "../../../components/Section";
import InvoiceSettingsNotifications from "./InvoiceSettingsNotifications";
import Label from "../../../components/Label";
import {WrenchScrewdriverIcon} from "@heroicons/react/20/solid";
import DropdownMenu from "../../../components/DropdownMenu";
import CopyableComponent from "../../../components/CopyableComponent";
import Link from "../../../components/Link";
import {BuildingOfficeIcon} from "@heroicons/react/24/outline";
import KeyValueDisplay2 from "../../../components/KeyValueDisplay2";
import ContentContainer from "../../../components/ContentContainer";

function InvoiceNotificationOverrides() {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { isMapleUser } = !_.isNil(userContext) ? userContext : { isMapleUser: false };
    const [settings, setSettings] = useState({});
    const [customer, setCustomer] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [ searchParams ] = useSearchParams();
    const customerId = searchParams.get('customer');
    const subscriptionId = searchParams.get('subscription');
    const [sectionTitle, setSectionTitle] = useState('');
    const [subscriptionOverrides, setSubscriptionOverrides] = useState(null);
    const [customerOverrides, setCustomerOverrides] = useState(null);
    const [currentOverrides, setCurrentOverrides] = useState(null);
    const [overrideId, setOverrideId] = useState(null);
    const [overrideType, setOverrideType] = useState(null);

    useEffect(() => {
        setPageTitle(`Customize Invoice Notifications`);
    }, []);

    useEffect(() => {
        if (subscriptionId) {
            setSectionTitle(`Subscription Invoice Notifications`);
        } else if (customerId) {
            setSectionTitle(`Customer Invoice Notifications`);
        }

    }, [subscriptionId, customer]);

    useEffect(() => {
        let type = ""
        if (subscriptionId) {
            setOverrideId(subscriptionId);
            type = "SUBSCRIPTION"
            setOverrideType("SUBSCRIPTION");
        } else if (customerId) {
            setOverrideId(customerId);
            type = "CUSTOMER"
            setOverrideType("CUSTOMER");
        }

        if (!subscriptionId && !customerId) {
            navigate(getCompanySpecificUrl('/settings'))
        }
        fetchData(type)

    }, [subscriptionId, customerId]);

    const updateSettings = async (data) => {
        if (subscriptionId) {
            data['subscription_id'] = subscriptionId;
        }
        data['type'] = overrideType
        data['customer_id'] = customerId;

        if (data.cc_email_addresses) {
            data.cc_email_addresses = _.filter(_.map(data.cc_email_addresses.split(","), (e) => e.trim()), e => e);
        } else if (data.cc_email_addresses === "") {
            data.cc_email_addresses = []
        }
        const result = await serverPost(getApiUrl("/settings_override"), data);
        if (result) {
            Notification.Success("Successfully updated settings");
            fetchData(overrideType);
        } else {
            Notification.Danger("Could not update settings")
        }
    }

    const fetchData = (type) => {
        const data = {
            customer_id: customerId,
            subscription_id: subscriptionId
        }
        serverPost(getApiUrl("/resolved_settings"), data).then((res) => {
            if (res) {
                setSettings(res);
            }
        });
        setCurrentOverrides(null);
        setSubscriptionOverrides(null);
        if (subscriptionId) {
            serverFetch(getApiUrl(`/settings_override/${subscriptionId}`)).then(res => {
                if (res) {
                    setSubscriptionOverrides(res);
                    if (type === "SUBSCRIPTION") {
                        setCurrentOverrides(res);
                    }
                }
            })
        }
        setCustomerOverrides(null);
        if (customerId) {
            serverFetch(getApiUrl(`/settings_override/${customerId}`)).then(res => {
                if (res) {
                    setCustomerOverrides(res);
                    if (type === "CUSTOMER") {
                        setCurrentOverrides(res);
                    }
                }
            })
        }

        fetchCustomer(customerId)
        fetchSubscription(subscriptionId)
    }

    const fetchCustomer = (customerId) => {
        if (customerId) {
            serverFetch(getApiUrl(`/customers/${customerId}`)).then((res) => {
                if (res) {
                    setCustomer(res);
                }
            })
        }
    }

    const fetchSubscription = (subscriptionId) => {
        if (subscriptionId) {
            serverFetch(getApiUrl(`/subscriptions/${subscriptionId}`)).then((res) => {
                if (res) {
                    setSubscription(res);
                }
            })
        }
    }

    const actionOptions = _.compact([
        currentOverrides && {id: "delete_override", label: "Remove Customization"},
        subscriptionId && customerOverrides && {id: "view_customer_overrides", label: "View Settings for Customer"},
    ])

    const onActionSelected = (type) => {
        if (type === "delete_override") {
            serverDelete(getApiUrl(`/settings_override/${overrideId}`)).then((res) => {
                if (res) {
                    Notification.Success("Successfully removed customization");
                    fetchData()
                }
            })
        } else if (type === 'view_customer_overrides') {
            navigate(getCompanySpecificUrl(`/settings/overrides?customer=${customerId}`))
        }
    }

    const getStatusLabel = () => {
        if (currentOverrides) {
            return <Label.Warning>Custom Notifications Set</Label.Warning>
        } else if (subscriptionId && customerOverrides) {
            return <Label.Warning>Settings Inherited From Customer</Label.Warning>
        }
    }

    const renderDetailsCard = () => {
        const details = {}
        if (!customer && !subscription) {
            return
        }
        if (overrideType === "SUBSCRIPTION") {
            details['ID'] = <CopyableComponent value={subscriptionId}></CopyableComponent>
            details['Customer'] = <Link href={getCompanySpecificUrl(`/customer/${subscription.customer.id}`)}>{getCustomerNameOrEmail(subscription.customer)}</Link>
            details['Email'] = <CopyableComponent value={subscription.customer.email}></CopyableComponent>

            if (subscription.parent_billed) {
                details['Bill To'] = <Link href={getCompanySpecificUrl(`/customer/${subscription.customer.parent_customer_id}`)}>{getCustomerNameOrEmail(subscription.customer.parent_customer)}</Link>
            }
        } else if (overrideType === "CUSTOMER") {
            details['ID'] = <CopyableComponent value={customerId}></CopyableComponent>
            if (customer.parent_customer_id) {
                details['Parent'] = <div className="justify-end flex flex-row gap-1 items-center">
                    <BuildingOfficeIcon className={"h-5 w-5"}/>
                    <Link href={getCompanySpecificUrl(`/customer/${customer.parent_customer_id}`)}>{getCustomerNameOrEmail(customer.parent_customer)}</Link>
                </div>
            }
            details['Name'] = customer.name
            details['Email'] = customer.email
        }

        return <KeyValueDisplay2 title="Details" items={details}/>
    }

    return (
		<BaseContainer>
			<ContentContainer
				variant={'page'}
				title={
					<div className='d-flex flex-row gap-3'>
						<span>{sectionTitle}</span>
						<div className='d-flex align-items-center gap-3'>{getStatusLabel()}</div>
					</div>
				}
                customActions={actionOptions.length > 0 &&
                    <DropdownMenu className='py-2 px-1' items={actionOptions} onClick={onActionSelected}>
                        <div className='flex flex-row gap-1 items-center'>
                            <WrenchScrewdriverIcon className='h-4 w-4' />
                            <span>Actions</span>
                        </div>
                    </DropdownMenu>
                }>
				{renderDetailsCard()}
				<InvoiceSettingsNotifications settings={settings} updateSettings={updateSettings} isOverride />
			</ContentContainer>
		</BaseContainer>
	);
}

export default InvoiceNotificationOverrides;