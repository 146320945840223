import '../../App.scss';
import '../../css/modals.scss';
import React, {createRef, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import BaseSideModal from "./BaseSideModal";
import BaseProductPricingCreateOrUpdate from "../BaseProductPricingCreateOrUpdate";
import Button from '../common/buttons/Button';
import { BaseContext } from '../../helpers/common';
import ContentBox from '../ContentBox';
import BaseForm from '../BaseForm';
import { serverPost } from '../../helpers/server';
import Alert from '../common/Alert';
import CheckCircle from '@untitled-ui/icons-react/build/esm/CheckCircle';
const _ = require('lodash');

function AddOrEditProductPricingModal2(props) {
    const { company, getApiUrl } = useContext(BaseContext);
    const [products, setProducts] = useState([]);
    const [productId, setProductId] = useState();
    const [error, setError] = useState(null);
    const [isProductCardCollapsed, setIsProductCardCollapsed] = useState(false);
    const [isPricingCardCollapsed, setIsPricingCardCollapsed] = useState(true);
    const formRef = createRef();

    const fetchProducts = useCallback((skipCache=false) => {
        if (!props.updated) return;
        
        const pParams = {
            company_id: company.id,
            pagination: {
                limit: 100
            },
            query: {
                state: "ACTIVE"
            }
        }
        serverPost(getApiUrl("/products/find"), pParams,{ skipCache }).then((res) => {
            if (res) {
                const results = res.results || [];
                setProducts(results);
            }
        });
    }, [company, getApiUrl, props.updated]);

    useEffect(() => {
        fetchProducts(true);
    }, [fetchProducts]);

    const productOptions = useMemo(() => products.map((p) => {
        return { value: p.id, label: p.name }
    }), [products]);

    const onFieldChange = (key, value) => {
        if (key === "product_id") {
            setProductId(value);
        }
    };

    useEffect(() => {
        setIsPricingCardCollapsed(false);
    }, [productId]);

    useEffect(() => {
        if (!props.show) {
            setError(null);
        }
    }, [props.show]);

    const onSubmit = async () => {
        if (props.updated) {
            if (!productId) {
                setError("empty-product");
                return;
            }
            const isValid = await formRef.current?.isValid();
            if (!isValid) {
                setError("pricing-validation");
                return;
            }
        }

        if (formRef.current) {
            await formRef.current.submitForm();
        }
    }

    const getErrorMessage = (error) => {
        switch (error) {
            case "empty-product":
                return "Please select a product before configuring the pricing.";
            case "pricing-validation":
                return "Please select a pricing."; 
            default:
                return "Something went wrong. Please try again.";
        }
    };

    const onProductPricingCreated = (productPricing) => {
        if (props.onProductPricingCreated) {
            props.onProductPricingCreated(productPricing);
        }
        if (props.onClose) {
            props.onClose();
        }
    }

    const renderModalBody = () => {
        return props.updated ? (
            <>
                <ContentBox isCollapsible collapsed={isProductCardCollapsed} setCollapsed={setIsProductCardCollapsed} hasError={error === "empty-product"}>
                    <ContentBox.Title size="lg">
                        <div>
                            <div className="flex items-center gap-2">
                                <span>Product</span>
                                {isProductCardCollapsed && productId && <CheckCircle className="inline text-[#079455]" width={18} height={18} />}
                            </div>
                            {isProductCardCollapsed && productId && (
                                <div>
                                    <span className="font-medium text-gray6 text-sm">{_.find(products, (p) => p.id === productId)?.name}</span>
                                </div>
                            )}
                        </div>
                    </ContentBox.Title>
                    <ContentBox.Body>
                        <BaseForm onFieldChange={onFieldChange}>
                            <BaseForm.Input name="product_id" label="Product Name" placeholder="Select an existing product" type="select" options={productOptions} showSearch={productOptions.length > 5} required />
                        </BaseForm>
                    </ContentBox.Body>
                </ContentBox>
                <ContentBox isCollapsible collapsed={isPricingCardCollapsed} setCollapsed={setIsPricingCardCollapsed} hasError={error === "pricing-validation"}>
                    <ContentBox.Title
                        size="lg"
                        onExpand={(stopExpand) => {
                            if (!productId) {
                                stopExpand();
                                setError("empty-product");
                            }
                        }}
                    >
                        Pricing
                    </ContentBox.Title>
                    <ContentBox.Body>
                        <BaseProductPricingCreateOrUpdate
                            ref={formRef}
                            productID={productId}
                            hideCreateButton
                            allowOnetimeOnly={props.allowOnetimeOnly}
                            onProductPricingCreated={onProductPricingCreated}
                        />
                    </ContentBox.Body>
                </ContentBox>
                {error && (
                    <Alert variant="danger">{getErrorMessage(error)}</Alert>
                )}
            </>
        ) : (
            <BaseProductPricingCreateOrUpdate
                ref={formRef}
                hideCreateButton
                selectProduct
                allowOnetimeOnly={props.allowOnetimeOnly}
                onProductPricingCreated={onProductPricingCreated}
            />
        );
    }

    if (!props.show) {
        return;
    }

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Content className={props.formClassName}>
                <BaseSideModal.Header title="Create Product Pricing" />
                <BaseSideModal.Body>
                    {renderModalBody()}
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Button variant="primary" onClick={onSubmit}>Create</Button>
                </BaseSideModal.Footer>
            </BaseSideModal.Content>
        </BaseSideModal>
    );
}

export default AddOrEditProductPricingModal2;
