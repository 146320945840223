import '../App.scss';
import React, { useState, useEffect, useContext } from 'react';
import {getAllBillableItems, serverFetch, serverPost} from '../helpers/server';
import {
    BaseContext, currencyFormatFromPrice, formatTerm,
    getDescriptionForDiscount,
    renderDescriptionForItemPricing,
    renderProductMetric,
} from '../helpers/common';
import MapleTable from "./MapleTable";
import Link from "./Link";
import UpdateSubscriptionMetricUsageModal from "./modals/UpdateSubscriptionMetricUsageModal";
import moment from 'moment';
import CopyableComponent from "./CopyableComponent";
import Notification from "./Notification";
import {getProductPricingIdsInPhase} from "../helpers/subscriptions";
import classnames from 'classnames';
const _ = require('lodash');

function BundlePricingTable(props) {
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [showUpdateUsageModal, setShowUpdateUsageModal] = useState(false);
    const [metricToUpdateUsage, setMetricToUpdateUsage] = useState(null);
    const [usageToUpdate, setUsageToUpdate] = useState(null);
    const [productPricings, setProductPricings] = useState([]);
    const [productPricingIds, setProductPricingIds] = useState([]);
    const [initialProductPricingIds, setInitialProductPricingIds] = useState([]);
    const [configMap, setConfigMap] = useState({});
    const [discountMap, setDiscountMap] = useState([]);
    const [productPricingsMap, setProductPricingsMap] = useState({});
    const [oneTimeBillables, setOneTimeBillables] = useState([]);
    const [showUsage, setShowUsage] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [billableItems, setBillableItems] = useState([]);
    const [metricUsageMap, setMetricUsageMap] = useState({});
    const [scheduledChanges, setScheduledChanges] = useState([]);

    useEffect(() => {
        setShowUsage(props.showUsage || false);
    }, [props.showUsage]);

    useEffect(() => {
        setSubscription(props.subscription || false);
    }, [props.subscription]);

    useEffect(() => {
        if (props.scheduledChanges) {
            const scs = [...props.scheduledChanges];
            _.each(scs, sc => {
                sc.product_pricing_ids = getProductPricingIdsInPhase(sc);
            })
            setScheduledChanges(scs);
        } else {
            setScheduledChanges([]);
        }
    }, [props.scheduledChanges])

    useEffect(() => {
        if (!_.isNil(props.product_pricing_ids)) {
            setProductPricingIds(props.product_pricing_ids);
        } else if (!_.isUndefined(props.bundlePricing) && !_.isNil(props.bundlePricing)) {
            setProductPricingIds(_.map(props.bundlePricing.bundle_product_pricings, (bpp) => bpp.product_pricing_id));
        }
        const initialConfig = _.keyBy(props.configItems, 'product_metric_pricing_id');
        _.each(props.oneTimeBillables, (otb) => {
            _.each(otb.product_pricing.product_metric_pricings, (pmp) => {
                initialConfig[pmp.id] = {
                    num_licenses: otb.aggregate
                };
            })
            initialConfig[otb.product_pricing_id] = {
                num_licenses: otb.aggregate
            }
        });
        setConfigMap(initialConfig);
    }, [props.bundlePricing, props.product_pricing_ids, props.oneTimeBillables, props.configItems])

    useEffect(() => {
        if (props.discounts && props.discounts.length > 0) {
            setDiscountMap(_.groupBy(props.discounts, 'item_pricing_id'));
        } else {
            setDiscountMap(null);
        }
    }, [props.discounts]);

    useEffect(() => {
        setOneTimeBillables(props.oneTimeBillables);
    }, [props.oneTimeBillables])

    useEffect(() => {
        let allPPIds = [];
        _.each(props.scheduledChanges, sc => {
            if (sc.bundle_product) {
                _.each(sc.bundle_product.bundle_product_pricings, bpp => {
                    allPPIds.push(bpp.product_pricing_id)
                })
            }
        })
    }, [props.scheduledChanges])

    useEffect(() => {
        async function inner() {
            const items = await getAllBillableItems(getApiUrl);
            if (items) {
                setBillableItems(items);
            }
        }
        inner();
    }, [])

    useEffect(() => {
        if (_.isEmpty(productPricingIds) && _.isEmpty(oneTimeBillables)) {
            return;
        }
        const onetimeIds = _.map(oneTimeBillables, (otb) => otb.product_pricing_id);
        let allPhasePPIds = [];
        _.each(scheduledChanges, sc => {
            allPhasePPIds = _.concat(allPhasePPIds, getProductPricingIdsInPhase(sc))
        })
        setInitialProductPricingIds([...productPricingIds, ...onetimeIds])
        const allProductPricingIds = [...productPricingIds, ...onetimeIds, ...allPhasePPIds];
        serverPost(getApiUrl(`/product_pricings/batch`), { ids: allProductPricingIds }).then((res) => {
            if (res) {
                setProductPricings(res);
                setProductPricingsMap(_.keyBy(res, 'id'))
            }
        });
    }, [productPricingIds, oneTimeBillables, getApiUrl, scheduledChanges]);

    useEffect(() => {
        if (!showUsage || _.isEmpty(productPricingsMap) || _.isNil(subscription) || _.isEmpty(subscription)) {
            return;
        }
        fetchMetricUsages();
    }, [productPricingsMap, showUsage])

    const fetchMetricUsages = (skipCache=false) => {
        serverFetch(getApiUrl(`/subscriptions/${subscription.id}/usages`), {skipCache}).then((res) => {
            if (res) {
                setMetricUsageMap(prevMap => {
                    const newMap = {...prevMap};
                    _.each(res.subscription_usages, r => {
                        newMap[`${r.metric.id}`] = _.find(r.usage, (u) => moment(u.period.start_date).isBefore(moment()) && moment(u.period.end_date).isAfter(moment()));
                    })
                    return newMap;
                })
            }
        });
    }

    const updateMetricUsage = (metric, usage) => {
        setMetricToUpdateUsage(metric);
        setUsageToUpdate(usage);
        setShowUpdateUsageModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowUpdateUsageModal(false);
        if (didUpdate) {
            Notification.Success("Updating usage for subscription... hang tight")
            setTimeout(() => {
                fetchMetricUsages(true);
            }, 1500)
            if (props.onUsageUpdated) {
                props.onUsageUpdated(true);
            }
        }
    }

    const renderProductPricing = (pricingId, i, cmap, dmap, shouldShowUsage) => {
        const pricing = _.find(productPricings, pp => pp.id === pricingId);
        if (!pricing) {
            return;
        }
        // const oneTimeIds = _.map(oneTimeBillables, (otb) => otb.product_pricing_id);
        // if (!_.includes(productPricingIds, pricing.id) && !_.includes(oneTimeIds, pricing.id)) {
        //     return;
        // }
        // const oneTimeConfig = _.find(oneTimeBillables, (otb) => otb.product_pricing_id === pricing.id);
        return (
            <React.Fragment key={i}>
                {
                    _.map(pricing.product_metric_pricings, (pmp, j) =>
                        <tr key={j} className="">
                            <td>
                                <span className="body1">
                                    {j === 0 &&
                                        <>
                                            {
                                                props.showPricingLinks1 ?
                                                    <Link href={getCompanySpecificUrl(`/pricing/${pricing.id}`)}>
                                                        <span
                                                            className="body2">{pricing.product.name}</span><span> - {pricing.name}</span>
                                                    </Link>
                                                : <div className="flex flex-col">
                                                    <span className="text-sm text-dark-gray font-medium">{pricing.product.name} - {pricing.name}</span>
                                                    <CopyableComponent className="mt-1 text-subtitle" value={ pricing.id }/>
                                                </div>
                                            }
                                        </>
                                    }
                                </span>
                            </td>
                            {
                                props.showProductMetricPricingID &&
                                    <td><CopyableComponent value={pmp.id}/></td>
                            }
                            <td>{ renderProductMetric(pmp, (_.has(cmap, pmp.id) ? cmap[pmp.id]: (_.has(cmap, pricing.id) ? cmap[pricing.id]: null))) }</td>
                            <td>
                                <span>{ renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item, billableItems) }</span>
                            </td>
                            {
                                !_.isEmpty(dmap) &&
                                <td>{dmap[pmp.item_pricing_id] ? getDescriptionForDiscount(dmap[pmp.item_pricing_id][0], getCompanySpecificUrl, props.hideDiscountAdditionalDetails, props.displayDiscountExpiration) : "-"}</td>
                            }
                            {
                                shouldShowUsage &&
                                    <td>
                                        <span className="text-dark-gray">{ metricUsageMap[`${pmp.metric_id}`] && metricUsageMap[`${pmp.metric_id}`].value.toLocaleString("en-US", { maximumFractionDigits: 4 }) }</span><br/>
                                        {
                                            pmp.metric.metering_rule.aggregator === "CUSTOMER_LAST_PERIOD" &&
                                                <Link onClick={() => updateMetricUsage(pmp.metric, metricUsageMap[pmp.metric_id])}>Update</Link>
                                        }
                                    </td>
                            }
                        </tr>
                    )
                }
            </React.Fragment>
        )
    }

    const renderChangeTimingDescription = (sc) => {
        let timingDescription = "";
        if (sc.timing != null) {
            if (sc.timing === "PERIOD_END") {
                timingDescription = "From next billing cycle,"
            } else if (sc.timing === "RENEWAL") {
                timingDescription = "At renewal,"
            } else if (sc.timing === "CUSTOM") {
                timingDescription = "At custom date,"
            } else if (sc.timing === "RELATIVE") {
                if (sc.relative_term) {
                    timingDescription = `After ${formatTerm(sc.relative_term)},`
                }
            }
        }
        return <span className="text-lg">{timingDescription}</span>
    }

    const hasNonOneTimePricings = !_.isEmpty(productPricingIds);

    const getPhaseOneTitleCopy = () => {
        let duration = "";
        if (scheduledChanges.length > 0 && scheduledChanges[0]?.relative_term) {
            duration = formatTerm(scheduledChanges[0].relative_term)
        }

        return `From execution date ${duration ? `+ ${duration}` : ""}`
    };

    const getPhaseTwoOrMoreTitleCopy = (scheduledChangeIndex, scheduledChange) => {
        if (scheduledChange.timing === "PERIOD_END") {
            return "From next billing cycle"
        } else if (scheduledChange.timing === "RENEWAL") {
            return "At renewal"
        } else if (scheduledChange.timing === "CUSTOM") {
            return "At custom date"
        }

        let duration = "∞";
        if (scheduledChange.term) {
            duration = formatTerm(scheduledChange.term);
        }
        return `When Phase ${scheduledChangeIndex + 1} is complete + ${duration}`
    };

    return (
        <div className={props.className}>
            {
                props.term && hasNonOneTimePricings && (
                    <>
                        <div className="mb-2 body1"><strong>Term:</strong> { formatTerm(props.term) }</div>
                        {props.trialTerm && <div className="mb-2 body1"><strong>Free Trial:</strong> { formatTerm(props.trialTerm) }</div>}
                        {props.showPlanTitle && <div className="text-base font-semibold">
                            <span>{(scheduledChanges && scheduledChanges.length > 0) ? "Phase 1:" : "Your plan:"}</span>&nbsp;
                            {getPhaseOneTitleCopy()}
                        </div>}
                    </>
                )
            }
            {
                props.minimumSpend && props.minimumSpend.value_in_cents > 0 &&
                    <div className="body1"><strong>Minimum Spend:</strong> { currencyFormatFromPrice(props.minimumSpend) }</div>
            }
            <MapleTable>
                <MapleTable.Content {...(props.removeTableSpacing ? { removePadding: true, removeMargin: true } : {})}>
                    <thead>
                        <tr>
                            <MapleTable.TH>Description</MapleTable.TH>
                            {
                                props.showProductMetricPricingID &&
                                    <MapleTable.TH className="min-w-[100px]">Metric Pricing ID</MapleTable.TH>
                            }
                            <MapleTable.TH>Metric/Quantity</MapleTable.TH>
                            <MapleTable.TH>Pricing</MapleTable.TH>
                            {
                                discountMap &&
                                <MapleTable.TH>Discount</MapleTable.TH>
                            }
                            {
                                showUsage &&
                                <MapleTable.TH>Usage</MapleTable.TH>
                            }
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {
                        !_.isEmpty(initialProductPricingIds) && _.map(initialProductPricingIds, (productPricingId, i) =>
                            renderProductPricing(productPricingId, i, configMap, discountMap, showUsage)
                        )
                    }
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
            {
                scheduledChanges && _.map(scheduledChanges, (sc, m) => {
                    let config = _.keyBy(sc.config_items, 'product_metric_pricing_id');
                    _.each(sc.onetime_items, (otb) => {
                        config[otb.product_pricing_id] = {
                            num_licenses: otb.aggregate
                        }
                    });
                    let dmap = _.groupBy(sc.discounts, 'item_pricing_id');
                    return (
                        <React.Fragment key={m}>
                            <div className="text-base font-semibold">
                                <span>Phase {m + 2}:</span>&nbsp;
                                {getPhaseTwoOrMoreTitleCopy(m, sc)}
                            </div>
                            {
                                sc.minimum_spend && sc.minimum_spend.value_in_cents > 0 &&
                                    <div className="body1"><strong>Minimum Spend:</strong> { currencyFormatFromPrice(sc.minimum_spend) }</div>
                            }
                            <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                    <tr>
                                        <MapleTable.TH>Description</MapleTable.TH>
                                        {
                                            props.showProductMetricPricingID &&
                                            <MapleTable.TH>Metric Pricing ID</MapleTable.TH>
                                        }
                                        <MapleTable.TH>Metric/Quantity</MapleTable.TH>
                                        <MapleTable.TH>Pricing</MapleTable.TH>
                                        {
                                            !_.isEmpty(dmap) &&
                                            <MapleTable.TH>Discount</MapleTable.TH>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        !_.isEmpty(sc.product_pricing_ids) && _.map(sc.product_pricing_ids, (productPricingId, i) =>
                                            renderProductPricing(productPricingId, i, config, dmap, false)
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        </React.Fragment>
                    )
                })
            }
            <UpdateSubscriptionMetricUsageModal
                show={showUpdateUsageModal}
                onClose={onModalClose}
                usage={usageToUpdate}
                metric={metricToUpdateUsage}
                subscription={subscription}/>
        </div>
    );
}

export default BundlePricingTable;
