import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import BaseSideModal from './BaseSideModal';
import { useState, useEffect, useContext, useMemo } from 'react';
import {BaseContext, currencyFormatFromPrice, getCurrencyOptions} from '../../helpers/common';
import {serverFetch, serverPost} from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {getSubscriptionCancelReasonOptions} from "../../helpers/subscriptions";
const _ = require('lodash');

function CancelSubscriptionModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState(null);

    const [timingType, setTimingType] = useState("IMMEDIATE");
    const [refundType, setRefundType] = useState(null);
    const [nextInvoiceDate, setNextInvoiceDate] = useState(null);
    const [renewalDate, setRenewalDate] = useState(null);
    const [maxRefund, setMaxRefund] = useState(null);
    const [remainingBalance, setRemainingBalance] = useState(null);
    const [cancelOptions, setCancelOptions] = useState({});
    const [cancelReason, setCancelReason] = useState(null);
    const [initialFields, setInitialFields] = useState({
        custom_end_date: moment().endOf('day'),
    });

    useEffect(() => {
        setSubscription(props.subscription)
        if (props.subscription) {
            if (props.subscription.next_invoice_date) {
                setNextInvoiceDate(moment(props.subscription.next_invoice_date));
            } else {
                setNextInvoiceDate(null);
            }

            if (props.subscription.renewal_date) {
                setRenewalDate(moment(props.subscription.renewal_date));
            } else {
                setRenewalDate(null);
            }
        } else {
            setNextInvoiceDate(null);
            setRenewalDate(null);
        }
    }, [props.subscription])

    useEffect(() => {
        if (subscription && props.show) {
            serverFetch(getApiUrl(`/subscriptions/${subscription.id}/cancel_options`), { skipCache: true }).then((res) => {
                if (res) {
                    setCancelOptions(res);
                    setRemainingBalance(res.remaining_balance);
                    const hasBalance = res.remaining_balance ? res.remaining_balance.value_in_cents > 0: false;
                    if (res.last_payment && res.last_payment.last_payment_amount) {
                        setMaxRefund(res.last_payment.last_payment_amount.value_in_cents/100);
                        setInitialFields(prevFields => {
                            const newFields = {...prevFields};
                            newFields.custom_refund_amount = res.last_payment.last_payment_amount.value_in_cents/100;
                            newFields.currency = res.last_payment.last_payment_amount.currency;
                            newFields.charge_remaining_balance = hasBalance;
                            return newFields;
                        });
                    } else {
                        setInitialFields({
                            currency: subscription.bundle_pricing.currency,
                            charge_remaining_balance: hasBalance
                        })
                    }
                } else {
                    setInitialFields({
                        currency: subscription.bundle_pricing.currency,
                        charge_remaining_balance: false
                    })
                }
            })
        } else {
            setCancelOptions({})
        }
    }, [props.show, subscription])

    const cancelSubscription = async (data, errorHandler) => {
        const cancelData = {
            timing: data.timing,
            custom_end_date: data.custom_end_date,
            refund_type: data.refund_type,
            reason: data.reason,
            charge_remaining_balance: data.charge_remaining_balance || false,
            customer_cancel_reason: data.customer_cancel_reason,
        };
        if (data.refund_type === "CUSTOM") {
            data.custom_refund_amount = {
                value_in_cents: data.custom_refund_amount * 100,
                currency: data.currency
            }
        }
        if (data.customer_cancel_reason === "other") {
            cancelData.customer_cancel_reason = data.customer_cancel_reason_other || data.customer_cancel_reason;
        }
        const res = await serverPost(getApiUrl(`/subscriptions/${subscription.id}/cancel`), cancelData, {}, errorHandler)
        if (res) {
            props.onClose(true);
        }
    }

    const onFieldChange = (name, value) => {
        if (name === "timing") {
            setTimingType(value);
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                if (value !== "IMMEDIATE") {
                    newFields.refund_type = "NONE";
                }
                newFields.timing = value;
                return newFields;
            });
            if (value !== "IMMEDIATE") {
                setRefundType("NONE");
            }
        } else if (name === "refund_type") {
            setRefundType(value);
            setInitialFields(prevFields => {
                const newFields = {...prevFields};
                newFields.refund_type = value;
                return newFields;
            });
        } else if (name === "customer_cancel_reason") {
            setCancelReason(value);
        }
    }

    const getNextInvoiceDate = () => {
        if (cancelOptions.period_end_date) {
            return ` - ${moment(cancelOptions.period_end_date).format("MMM D, YYYY h:mm:ssa")}`;
        } else {
            return "";
        }
    }

    const getRenewalDate = () => {
        if (_.isNil(subscription) || _.isNil(subscription.renewal_date)) {
            return ""
        } else {
            return ` - ${moment(subscription.renewal_date).format("MMM D, YYYY h:mm:ssa")}`;
        }
    }

    const datesSame = nextInvoiceDate && renewalDate && nextInvoiceDate.isSame(renewalDate);
    const timingOptions = [
        { value: "IMMEDIATE", label: "Immediately" },
        !_.isEmpty(cancelOptions) && { value: "PERIOD_END", label: `End of the current period${ getNextInvoiceDate() }` },
        !datesSame && !_.isEmpty(cancelOptions) && { value: "RENEWAL", label: `At renewal date${ getRenewalDate() }` },
        { value: "CUSTOM", label: "Custom Date" },
    ];

    const hasRemainingBalance = !_.isNil(remainingBalance) && remainingBalance.value_in_cents > 0;
    const hasPayments = !_.isEmpty(cancelOptions) && cancelOptions.last_payment;
    const allowRefunds = !_.isEmpty(cancelOptions) && timingType === "IMMEDIATE" && !hasRemainingBalance && hasPayments;
    const refundOptions = [
        { value: "NONE", label: "No Refund" },
        allowRefunds && !_.isEmpty(cancelOptions.last_payment) && { value: "LAST_PAYMENT", label: `Last Payment - ${ currencyFormatFromPrice(cancelOptions.last_payment.last_payment_amount) }` },
        allowRefunds && !_.isEmpty(cancelOptions.immediate_prorated_amount) && { value: "PRORATED", label: `Prorated - ${ currencyFormatFromPrice(cancelOptions.immediate_prorated_amount) }` },
        allowRefunds && { value: "CUSTOM", label: "Custom" },
    ];

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={cancelSubscription} >
                <BaseSideModal.Header title={t('subscriptions.cancel.title')}/>
                <BaseSideModal.Body>
                    <Row>
                    <BaseForm.Input type="select" name="timing" label="Cancel" options={timingOptions} showSearch={false}/>
                    {
                        timingType === "CUSTOM" &&
                            <>
                            <BaseForm.Input type="date" name="custom_end_date" label="Cancellation Date" minDate={moment()} includeTime={true} />
                            <span className="caption">Date/Time is based on your local timezone</span>
                            <br/>
                            </>
                    }
                        {
                            hasRemainingBalance && timingType !== "CUSTOM" &&
                                <>
                                    <span className="text-sm font-semibold gray4">Remaining Balance</span>
                                    <span className="text-sm">There is a balance of { currencyFormatFromPrice(remainingBalance) } on this account.</span>
                                    {
                                        timingType === "IMMEDIATE" ?
                                            <BaseForm.Input type="switch" name="charge_remaining_balance" label="Charge Now" />
                                        : <><span className="text-sm">The balance will be charged at the end of the current period.</span><br/><br/></>
                                    }

                                </>
                        }
                    <BaseForm.Input type="select" name="refund_type" label="Refund" options={refundOptions} showSearch={false} disabled={!allowRefunds} />
                    {
                        refundType === "CUSTOM" &&
                        <>
                            <BaseForm.Input colSpan={6} name="custom_refund_amount" label="Amount" type="number" step="0.01" min="0.01"
                                            max={maxRefund} validations={{ required: true, min: 0.01, max: maxRefund}} />
                            <BaseForm.Input colSpan={6} name="currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} required disabled />
                        </>
                    }
                    <BaseForm.Input
                        type="select" name="customer_cancel_reason" label="Cancel Reason (optional)" options={getSubscriptionCancelReasonOptions()}
                        showSearch={false}
                    />
                        {
                            cancelReason === "other" &&
                            <BaseForm.Input
                                type="text" name="customer_cancel_reason_other" label="Other Reason"
                            />
                        }
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="danger">{t('subscriptions.cancel.cancel')}</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default CancelSubscriptionModal;
