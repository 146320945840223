import '../App.scss';
import React, {useContext} from 'react';
import {serverPost} from '../helpers/server';
import {BaseContext, currencyFormatFromPrice} from '../helpers/common';
import BaseForm from './BaseForm';
import {useTranslation} from 'react-i18next';

const _ = require('lodash');

function DealInput(props) {
    const {t} = useTranslation('common');
    const {getApiUrl} = useContext(BaseContext);

    const loadDeals = async (query) => {
        const dealPromise = serverPost(getApiUrl('/integrations/deals/autocomplete'), {
            search_query: query || null,
            sort_key: "aToZ",
            pagination: {limit: 20}
        });
        const dealResult = await dealPromise;
        return dealResult.results || [];
    }

    const dealLabel = (deal) => {
        let label = deal.title;
        let subtitle = deal.stage;
        if (deal.currency && deal.value) {
            let value = {
                currency: deal.currency,
                value_in_cents: parseInt(deal.value)
            }
            subtitle = `${subtitle} - ${currencyFormatFromPrice(value)}`
        }
        return (
            <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-col grow">
                    <span className="body2 -mt-1">{label}</span>
                    <span className="text-gray-500 -mt-1">{subtitle}</span>
                </div>
                <div className="h-4 shrink-0">
                    {
                        deal.integration_icon &&
                        <img src={deal.integration_icon} alt="Integration Logo" className="h-4"/>
                    }
                </div>
            </div>
        )
    }

    const getOptionValue = (customer) => {
        if (customer.integration_id) {
            return customer.identifier;
        } else {
            return customer.id;
        }
    }

    return (
        <BaseForm.Input
            colSpan={props.colSpan || "6"} name={props.name || "deal"} label={props.label || "Deal"}
            type="selector" loadOptions={loadDeals} getOptionLabel={dealLabel} getOptionValue={getOptionValue}
            required={props.required} popover />
    )
}

export default DealInput;
