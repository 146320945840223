import '../App.scss';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import Button from './common/buttons/Button';
import { renderComponents } from '../helpers/common';
import classnames from 'classnames';
import BaseContainer, { BaseContainerContext } from './BaseContainer';
const _ = require('lodash');

function ContentContainer(props) {
	const context = useContext(BaseContainerContext);
    const [showStickyHeader, setShowStickyHeader] = useState(false);
	const headerRef = useRef();
	const shouldRenderHeader = useMemo(() => props.title || props.actions, [props.actions, props.title]);

	const renderHeader = (sticky = false) => {
		if (!shouldRenderHeader) return null;

		return (
			<div
				className={classnames('content-header d-flex flex-row gap-2', props.headerClassName)}
			>
				{props.logo && (
					<div className='logo w-[50px] h-[50px] align-self-center'>
						<Image src={props.logo} className="rounded-[4px] w-full h-full"/>
					</div>
				)}
				<div className='title align-self-center'>
					<h3
						className={classnames({
							'title-update': props.variant !== 'page',
							'title-update-page': props.variant === 'page' && !sticky,
							'text-dark-gray text-xl': props.variant === 'page' && sticky,
						})}
					>
						{props.title}
					</h3>
					{props.subtitle && <div className='mt-1 text-sm text-gray-700'>{props.subtitle}</div>}
				</div>
				<div className='flex-grow-1' />
				<div className='flex-grow-0 flex-shrink-0 align-self-center'>
					{props.actions && (
						<>
							{_.map(props.actions, (action, i) => (
								<Button key={i} variant={action.variant} onClick={action.onClick}>
									{action.icon && (
										<span>
											<i className={classnames('fa', action.icon)} />
											&nbsp;
										</span>
									)}
									{action.label}
								</Button>
							))}
						</>
					)}
					{props.customActions && <>{renderComponents(props.customActions)}</>}
				</div>
			</div>
		);
	};

	useEffect(() => {
		const addHeaderToBaseContainer = () => {
			if (!shouldRenderHeader || !props.enableStickyHeader || !context?.setHeader) return;
			context.setHeader(
				<BaseContainer.CustomHeader show={showStickyHeader}>
					{renderHeader(true)}
				</BaseContainer.CustomHeader>
			);
		}
		addHeaderToBaseContainer();

		return () => {
			if (context?.setHeader) {
				context.setHeader(null);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showStickyHeader, props.customActions, props.title]);

	useEffect(() => {
		if (!props.enableStickyHeader || !context?.setHeader) return;
        const showActionBarScrollHandler = () => {
            if (!headerRef.current) return;

            // if there's a value, we check the bottom of it if it is sitll in view
            const rect = headerRef.current.getBoundingClientRect();
            setShowStickyHeader(rect.bottom < -50);
        };
        const scrollContainer = document.getElementById("content-wrapper");
        scrollContainer.addEventListener("scroll", showActionBarScrollHandler);

        return () => {
            scrollContainer.removeEventListener("scroll", showActionBarScrollHandler);
        };
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	return (
		<div className={classnames('content-container', props.className)}>
			<div ref={headerRef}>
				{renderHeader()}
			</div>
			<div className={classnames('content-body', props.bodyClassName)}>{props.children}</div>
		</div>
	);
}

export default ContentContainer;
