import '../../App.scss';
import '../../css/modals.scss';
import BaseForm from '../BaseForm';
import SubmitButton from '../common/buttons/SubmitButton';
import BaseSideModal from './BaseSideModal';
import Notification from '../Notification';
import React, { useState, useEffect, useContext } from 'react';
import {
    BaseContext,
    getPaymentMethodDescription
} from '../../helpers/common';
import {serverFetch, serverPatch} from '../../helpers/server';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function UpdateSubscriptionPaymentMethodModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        if (!_.isEmpty(subscription) && props.show) {
            serverFetch(getApiUrl(`/customers/${subscription.customer_id}/payment_methods`), {}).then((res) => {
                if (res) {
                    setPaymentMethods(res.methods);
                }
            });
        }
    }, [props.show, subscription]);

    useEffect(() => {
        setSubscription(props.subscription)
    }, [props.subscription])

    const onSubmit = (data) => {
        const updateData = {
            payment_method_id: data.payment_method_id
        }
        serverPatch(getApiUrl(`/subscriptions/${subscription.id}`), updateData).then((res) => {
            if (res) {
                props.onClose(true);
                Notification.Success("Successfully updated")
            }
        })
    }

    const paymentMethodOptions = _.map(paymentMethods, (pm) => {
        return {
            value: pm.id,
            label: getPaymentMethodDescription(t, pm)
        }
    })
    paymentMethodOptions.unshift({
        value: null,
        label: "Use default customer payment method"
    })

    if (!props.show) {
        return;
    }
    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form initialFormFields={subscription} onSubmit={onSubmit} >
                <BaseSideModal.Header title={t('subscriptions.select_payment_method')}/>
                <BaseSideModal.Body>
                    <BaseForm.Input type="select" name="payment_method_id" label={"Payment Method"} options={paymentMethodOptions} showSearch={false}/>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>{t('common.update')}</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default UpdateSubscriptionPaymentMethodModal;
