import '../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import { serverFetch, serverPost } from '../../helpers/server';
import {
    currencyFormatFromPrice,
    BaseContext,
    UserContext,
    getFormattedAddressForAddress,
    getNameForProviderType,
} from '../../helpers/common';
import AddOrEditCustomerModal from '../../components/modals/AddOrEditCustomerModal';
import Columns from '../../components/Columns';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import SortableTableHeader from "../../components/SortableTableHeader";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
import { UsersIcon } from "@heroicons/react/20/solid";
import EmptyState from "../../components/EmptyState";
import TopBarSummary from "../../components/TopBarSummary";
import CustomerAddressValidationModal from "../../components/modals/CustomerAddressValidationModal";
import {getCustomerExportFields} from "../../helpers/exportFields";
import BaseCSVExportModal from "../../components/modals/BaseCSVExportModal";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import useGetMembers from "../../helpers/hooks/api/useGetMembers";
import classnames from 'classnames';
const _ = require('lodash');

function Customers() {
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [showAddOrEditCustomerModal, setShowAddOrEditCustomerModal] = useState(false);
    const [showCustomerAddressValidationModal, setShowCustomerAddressValidationModal] = useState(false);
    const [customerToEdit, setCustomerToEdit] = useState(null);
    const [showExportModal, setShowExportModal] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [settings, setSettings] = useState({});
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState("createdAtDesc");
    const [meta, setMeta] = useState({});
    const [filters, setFilters] = useState([]);
    const cachedSelectedFilters = useMemo(() => {
        const cachedString = localStorage.getItem(company.id + "_filter_cache_customers");
        if (cachedString) {
            return JSON.parse(cachedString);
        }
    }, [])
    const defaultFilters = useMemo(() => {
        return cachedSelectedFilters || { statuses: ["ACTIVE"] }
    }, [])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultFilters);
    const [customerMetricsSummary, setCustomerMetricsSummary] = useState(null)
    const { members: teamMembers, fetchMembers } = useGetMembers(false);

    const hasWritePermissions = hasAccess("customers", userInfo, "write");

    useEffect(() => {
        setPageTitle(`Customers`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })

        serverFetch(getApiUrl(`/reports/customer_metrics_summary`)).then(res => {
            setCustomerMetricsSummary(res)
        })

        fetchMembers()
    }, []);

    const getQueryParams = () => {
        const query = {
            search: selectedFilters.search,
            statuses: _.isEmpty(selectedFilters.statuses) ? null: selectedFilters.statuses,
            exclude_from_metrics: !_.isNil(selectedFilters.exclude_from_metrics) ? selectedFilters.exclude_from_metrics: null,
            managed_externally: !_.isNil(selectedFilters.managed_externally) ? selectedFilters.managed_externally: null,
            mrr: selectedFilters.mrr,
        }
        if (!_.isNil(selectedFilters.owner_id)) {
            if (selectedFilters.owner_id === "unassigned") {
                query.owner_id = null
            } else {
                query.owner_id = selectedFilters.owner_id
            }
        }
        return query;
    }

    const onSearch = (restart = true) => {
        const limit = 50;
        const query = getQueryParams();
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: query,
            include_meta: restart
        }
        serverPost(getApiUrl("/customers/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setCustomers(results);
                    setMeta(res.meta);
                } else {
                    setCustomers(_.concat(customers, results));
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [sort, selectedFilters]);

    useEffect(() => {
        const metricsExclusionOptions = [
            { value: null, label: "All" },
            { value: true, label: "Excluded from Metrics" },
            { value: false, label: "Included in Metrics" },
        ]

        const statusOptions = [
            { value: "ACTIVE", label: "Active" },
            { value: "ARCHIVED", label: "Archived" },
        ]

        const managedExternallyOptions = [
            { value: null, label: "All" },
            { value: true, label: "Managed Externally" },
            { value: false, label: "Managed in Maple" },
        ]

        const teamMemberOptions = _.map(teamMembers, (member) => {
            return {
                value: member.id,
                label: member.user.name || member.user.email
            }
        })
        teamMemberOptions.unshift({ value: "unassigned", label: "Unassigned" })
        teamMemberOptions.unshift({ value: null, label: "All" })

        setFilters([
            { title: "Status", type: "multi-select", name: "statuses", options: statusOptions},
            { title: "Metrics", type: "select", name: "exclude_from_metrics", options: metricsExclusionOptions },
            { title: "Management", type: "select", name: "managed_externally", options: managedExternallyOptions },
            { title: "Owner", type: "dropdown", name: "owner_id", options: teamMemberOptions },
            { title: "MRR", type: "amount", name: "mrr" },
        ])
    }, [teamMembers])

    const onAddCustomer = () => {
        setShowAddOrEditCustomerModal(true);
    }

    const onEditCustomer = (customer) => {
        setShowAddOrEditCustomerModal(true);
        setCustomerToEdit(customer);
    }

    const onModalClose = (didUpdate) => {
        setShowAddOrEditCustomerModal(false);
        setShowCustomerAddressValidationModal(false);
        setCustomerToEdit(null);
        setShowExportModal(false);
        if (didUpdate) {
            onSearch(true);
        }
    }

    const onParamsChange = (params) => {
        if (!_.isEqual({...selectedFilters, sort}, params)) {
            const newFilters = {...params};
            setSelectedFilters(newFilters);
            localStorage.setItem(company.id + "_filter_cache_customers", JSON.stringify(newFilters));
        }
    }

    const showOwnerColumn = _.some(customers, (c) => !_.isNil(c.owner))
    const columns = [
        "name",
        "mrr",
        "tax_address_check",
        "created_at",
        showOwnerColumn && "owner",
        "actions"
    ];

    const onAddressMissingClick = (event, customer) => {
        event.stopPropagation();
        event.preventDefault();
        onEditCustomer(customer);
    }

    const renderColumnHeader = (key, j) => {
        if (!key) {
            return;
        }
        if (key === "name") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="orgAToZ" sortKeyDown="orgZToA"
                currentSort={sort}>
                Name
            </SortableTableHeader>
        } else if (key === "mrr") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="mrrAsc" sortKeyDown="mrrDesc"
                currentSort={sort} innerClassName="justify-end">
                MRR
            </SortableTableHeader>
        } else if (key === "tax_address_check") {
            return <MapleTable.TH key={j} className="thin-horizontal"></MapleTable.TH>
        } else if (key === "created_at") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="createdAtAsc" sortKeyDown="createdAtDesc"
                currentSort={sort} innerClassName="w-px whitespace-nowrap">
                Since
            </SortableTableHeader>
        } else if (key === "actions") {
            return <MapleTable.TH key={j} className="d-none d-lg-table-cell w-px whitespace-nowrap"></MapleTable.TH>
        } else if (key === "owner") {
            return <MapleTable.TH key={j} className="d-none d-lg-table-cell w-px whitespace-nowrap">Owner</MapleTable.TH>
        }
    }

    const renderStatusImage = (customer) => {
        let imageUrl = null
        if (customer.imported_from === "HUBSPOT") {
            imageUrl = "/icons/hubspot.png"
        } else if (customer.imported_from === "STRIPE") {
            imageUrl = "/icons/stripe.png"
        }

        const providerName = getNameForProviderType(customer.imported_from);

        if (imageUrl && customer.managed_externally) {
            return <BaseOverlayTrigger content={`Customer is currently managed in ${providerName}`}>
                <div className="status-image">
                    <img alt="status" src={imageUrl} />
                </div>
            </BaseOverlayTrigger>
        }
    }

    const renderAddressCheckColumn = (row) => {
        if (!settings.tax_calc_enabled) {
            return null;
        }
        if (_.isEmpty(getFormattedAddressForAddress(row.address))) {
            return <div onClick={(event) => onAddressMissingClick(event, row)}><i className="fa fa-triangle-exclamation warning-color"/> Address Missing</div>
        }
    }

    const renderColumn = (key, row, j) => {
        if (!key) {
            return;
        }
        if (key === "name") {
            return <td key={j}>
                <Columns.CustomerName customer={row} />
            </td>
        } else if (key === "mrr") {
            return <td key={j} className="">
                <div className="flex flex-row gap-1 justify-end items-center">
                    {
                        row.exclude_from_metrics &&
                            <div>
                                <BaseOverlayTrigger content={"Customer is excluded from metrics calculation"}>
                                    <ExclamationTriangleIcon className="h-5 w-5"/>
                                </BaseOverlayTrigger>
                            </div>
                    }
                    { currencyFormatFromPrice(row.mrr) }
                </div>
            </td>
        } else if (key === "tax_address_check") {
            return <td key={j}>
                { renderAddressCheckColumn(row) }
            </td>
        } else if (key === "created_at") {
            return <td key={j} className="w-px whitespace-nowrap">
                { moment(row.created_at).format("MMM D, YYYY") }
            </td>
        } else if (key === "actions") {
            return <td key={j} className="d-none d-lg-table-cell w-px whitespace-nowrap !pl-0">
                <div className="flex flex-row gap-1 items-center">
                    {
                        renderStatusImage(row)
                    }
                </div>
            </td>
        } else if (key === "owner") {
            return <td key={j}>
                {
                    row.owner &&
                    <Columns.CompanyUserAvatar companyUser={row.owner} />
                }
            </td>
        }
    }

    const isEmpty = customers.length === 0 && !hasMore && _.isEmpty(selectedFilters);
    const showTopMetricsSummary = !_.isEmpty(customerMetricsSummary);
    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={customers.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="Customers"
                            variant="page"
                             loading={loading}
                             actions={hasWritePermissions && !isEmpty && !loading && [{
                                variant: "primary",
                                icon: "fa-plus",
                                label: "Create Customer",
                                onClick: onAddCustomer
                             }]}>
                        {
                            showTopMetricsSummary &&
                                <TopBarSummary className="mt-1 mb-8" entries={customerMetricsSummary}/>
                        }
                        {
                            isEmpty ?
                                <EmptyState
                                    className={ showTopMetricsSummary ? "mt-8": "" }
                                    icon={UsersIcon}
                                    title={"No customers."}
                                    subtitle={"Get started by creating a new one."}
                                    buttonLabel={"New Customer"}
                                    hasWritePermissions={hasWritePermissions}
                                    onClick={onAddCustomer}
                                />
                            : <MapleTable className={ showTopMetricsSummary ? "mt-8": "" }>
                                    <MapleTableHeaderWithActions
                                        showSearch={true}
                                        searchPlaceholder="Search Customers"
                                        showFilters={true}
                                        showExport={true}
                                        onExport={() => setShowExportModal(true)}
                                        filters={filters}
                                        defaultSelectedFilters={defaultFilters}
                                        meta={meta}
                                        onParamsChange={onParamsChange}
                                    />
                                    <MapleTable.Content>
                                        <thead>
                                            <tr>
                                                { _.map(columns, (c, j) => renderColumnHeader(c, j)) }
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            _.map(customers, (row, i) =>
                                                 <MapleTable.TR key={i} className={classnames("cursor-pointer", row.status === "ARCHIVED"? "bg-gray-50":"")} href={getCompanySpecificUrl(`/customer/${row.id}`)}>
                                                     { _.map(columns, (c, j) => renderColumn(c, row, j)) }
                                                 </MapleTable.TR>
                                            )
                                        }
                                        {
                                            hasMore &&
                                                <tr>
                                                    <td colSpan="10" className="text-center">
                                                        <div className="spinner-border text-secondary"/>
                                                    </td>
                                                </tr>
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                        }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
            <AddOrEditCustomerModal show={showAddOrEditCustomerModal} onClose={onModalClose} itemToEdit={customerToEdit}/>
            <CustomerAddressValidationModal show={showCustomerAddressValidationModal} onClose={onModalClose} customer={customerToEdit}/>
            <BaseCSVExportModal
                show={showExportModal}
                onClose={setShowExportModal}
                sort={sort}
                useQuery={true}
                query={getQueryParams()}
                title={"Customers CSV Export"}
                fields={getCustomerExportFields()}
                filenamePrefix="Customers-CSV"
                cacheKey={company.id + "_csv_customers"}
                exportUrl={'/customers/export'} />
        </BaseContainer>
    );
}

export default Customers;
